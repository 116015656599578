export const platformsOptions = [
  {value: 'big_biller', label: 'Big Biller'},
  {value: 'bullhorn', label: 'Bullhorn'},
  {value: 'crelate', label: 'Crelate'},
  {value: 'catsone', label: 'Catsone'},
  {value: 'jobdiva', label: 'JobDiva'},
  {value: 'linkedin', label: 'LinkedIn'},
  {value: 'loxo', label: 'Loxo'},
  {value: 'pc_recruiter', label: 'PC Recruiter'},
  {value: 'zoho', label: 'Zoho'},
  {value: 'other', label: 'Other'},
];

export const PLATFORMS = platformsOptions.reduce((acc, val) => {
  acc[val.value] = val.label;
  return acc;
}, {});
