import axios from '../config/axios';

export const applicantApi = {
  getApplicants: async (params) => await axios.get('/applicants', {params}),
  getApplicant: async (id, params) =>
    await axios.get(`/applicants/${id}`, {params}),
  createApplicant: async (payload) => axios.post('/applicants', payload),
  updateApplicant: async (id, payload) =>
    axios.patch(`/applicants/${id}`, payload),
  getApplicantPdf: async ({id, options}) =>
    await axios.get(`/applicants/${id}/download`, {
      ...options,
    }),
  checkExistingApplicantEmail: async (email) =>
    axios.get(`/applicants/by_email/${email}`),
};
