import AppCard from '@wieldy/components/AppCard';
import {AppFormActions, AppFormBody} from '@wieldy/components/AppFormWrapper';
import AppFormWrapper from '@wieldy/components/AppFormWrapper/AppFormWrapper';
import {getRequiredMessage} from '@wieldy/utils/helpers/MessageHelper';
import {Form, Rate, Typography} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

const RatingForm = ({
  title = 'Add New Supplier Review',
  subTitle,
  initialValues,
  onSubmit,
  fields,
  children,
}) => {
  const [overallRating, setOverallRating] = React.useState(0);
  const [form] = Form.useForm();

  const calculateOverallRating = React.useCallback(
    (_, formdata) => {
      let total = 0;
      fields.forEach((field) => {
        if (formdata[field.name] !== undefined) {
          total += formdata[field.name];
        }
      });
      setOverallRating(total / 5);
    },
    [fields],
  );

  React.useEffect(() => {
    if (typeof initialValues === 'object') {
      calculateOverallRating(null, initialValues);
    }
    form.setFieldsValue(initialValues);
  }, [calculateOverallRating, form, initialValues]);

  return (
    <AppCard
      title={title}
      bodyStyle={{paddingTop: 8}}
      headStyle={{borderBottom: 0}}>
      {subTitle && (
        <Typography.Paragraph
          className='text-secondary'
          style={{paddingTop: 0}}>
          {subTitle}
        </Typography.Paragraph>
      )}
      <AppFormWrapper>
        <Form
          form={form}
          layout='horizontal'
          colon={false}
          labelAlign='left'
          onFinish={onSubmit}
          onValuesChange={calculateOverallRating}
          labelCol={{
            md: {span: 8},
            lg: {span: 8},
          }}
          wrapperCol={{
            md: {span: 8},
            lg: {span: 8},
          }}>
          <AppFormBody>
            {fields.map((field) => (
              <Form.Item
                key={field.name}
                label={field.label}
                name={field.name}
                rules={[
                  {
                    required: true,
                    message: getRequiredMessage(`${field.label} rating`),
                  },
                ]}>
                <Rate allowClear={false} />
              </Form.Item>
            ))}

            <Form.Item
              label='Overall Rating'
              style={{
                marginTop: '40px',
              }}>
              <Rate disabled allowHalf={true} value={overallRating} />
            </Form.Item>
          </AppFormBody>
          <AppFormActions>{children}</AppFormActions>
        </Form>
      </AppFormWrapper>
    </AppCard>
  );
};

RatingForm.propTypes = {
  title: PropTypes.string,
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
  children: PropTypes.node,
  subTitle: PropTypes.string,
  fields: PropTypes.array.isRequired,
};

export default RatingForm;
