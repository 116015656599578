import AppSelect from '@wieldy/components/dataEntry/AppSelect';
import {Select} from 'antd';
import {useCurrentUser} from 'app/store/features/user/userSlice';
import moment from 'moment';
import propTypes from 'prop-types';
import React from 'react';

import {getOptions} from './';

const FilterReportDropdown = ({value, onChange}) => {
  const user = useCurrentUser();
  const options = getOptions(moment.unix(user?.created_at).format('MM/DD/YY'));
  const defaultValue = React.useMemo(() => {
    return value ?? 'current_year';
  }, [value]);

  return (
    <AppSelect
      size='small'
      className={'select-standard'}
      style={{minWidth: '125px'}}
      onChange={onChange}
      defaultValue={defaultValue}>
      {options.map((option) => (
        <Select.Option key={option.key} value={option.key}>
          {option.label}
        </Select.Option>
      ))}
    </AppSelect>
  );
};

FilterReportDropdown.propTypes = {
  value: propTypes.string,
  onChange: propTypes.func,
};

export default FilterReportDropdown;
