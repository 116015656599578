import {mergeApi} from '../api/mergeApi';
import {useReactMutation} from '../hooks/useReactQuery';

export const useMagicLink = () => {
  return useReactMutation(async () => {
    const response = await mergeApi.magicLink();
    return {
      linkToken: response.data.linkToken,
      message: 'Magic link created successfully',
    };
  });
};
export const useExchange = () => {
  return useReactMutation(async (key) => {
    const response = await mergeApi.exchangeToken(key);
    return {
      response: response?.data,
      message: 'Token Exchanged',
    };
  });
};
