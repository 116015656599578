export const IS_SUBJECT_LABEL = {
  united_states: 'Company is subject to income taxes in the United States',
  outside_of_united_states:
    'Company is not subject to income taxes in the United States',
};

export const IS_SUBJECT_LABEL_FOR_EDIT = {
  united_states: 'United States',
  outside_of_united_states: 'Outside of United States',
};
export const isSubjectOptions = [
  {value: 'united_states', label: IS_SUBJECT_LABEL.united_states},
  {
    value: 'outside_of_united_states',
    label: IS_SUBJECT_LABEL.outside_of_united_states,
  },
];

export const isSubjectOptionsForEdit = [
  {value: 'united_states', label: IS_SUBJECT_LABEL_FOR_EDIT.united_states},
  {
    value: 'outside_of_united_states',
    label: IS_SUBJECT_LABEL_FOR_EDIT.outside_of_united_states,
  },
];
