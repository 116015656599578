import {capitalizedString} from '@wieldy/utils/helpers/StringHelper';
import {Typography} from 'antd';
import {ALL_USER_TYPES} from 'app/constants';
import {getFullName, getPhoneNumber} from 'app/utils/helpers/DataFormatHelper';
import React from 'react';

export const getColumns = (viewUser) => {
  return [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'user',
      render: (_, record) => (
        <Typography.Link
          className={'font-semibold'}
          onClick={() => viewUser(record)}>
          {getFullName(record?.user)}
        </Typography.Link>
      ),
    },
    {
      title: 'Email Address',
      dataIndex: 'email',
      key: 'email',
      render: (_, item) => item?.user?.email,
    },
    {
      title: 'Phone Number',
      dataIndex: 'phone_number',
      key: 'phone_number',
      render: (_, item) => getPhoneNumber(item?.user?.phone_number),
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      render: (_, item) =>
        item?.user?.title ? capitalizedString(item?.user?.title) : '',
    },
    {
      title: 'User Type',
      dataIndex: 'role',
      key: 'role',
      render: (role) => (role ? ALL_USER_TYPES[role] : null),
      showSorterTooltip: false,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      showSorterTooltip: false,
      render: (status) => (status ? capitalizedString(status) : null),
    },
  ];
};
