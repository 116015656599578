import AppCard from '@wieldy/components/AppCard';
import {Descriptions, Typography} from 'antd';
import {
  BILLING_RATE_PERIODS,
  PRINT_SCANNER_ACCESS,
  RESOURCE_WORK_LOCATION,
  WORK_COMPUTER_OS_TYPES,
  WORK_COMPUTER_TYPES,
} from 'app/constants';
import {
  formatBillingRateRage,
  getYesOrNo,
} from 'app/utils/helpers/DataFormatHelper';
import React from 'react';
import {useSelector} from 'react-redux';

const BillingAndEnvironmentDetails = () => {
  const {billing} = useSelector(({resource}) => resource);
  const {billing_rate, billing_rate_min, billing_rate_max} = billing;
  const billingRate = formatBillingRateRage(
    billing_rate,
    billing_rate_min,
    billing_rate_max,
  );
  return (
    <AppCard style={{maxWidth: 990}}>
      <Typography.Title level={4}>Billing rates</Typography.Title>
      <Descriptions
        colon={false}
        labelStyle={{width: '50%'}}
        className='description-root mb-4'
        column={{xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1}}>
        <Descriptions.Item label='Billing Rate'>
          {billingRate}
        </Descriptions.Item>
        <Descriptions.Item label='Billing Rate Period'>
          {BILLING_RATE_PERIODS[billing.billing_rate_period]}
        </Descriptions.Item>
        <Descriptions.Item label='Open to Rate Negotiation'>
          {getYesOrNo(billing.billing_rate_negotiations)}
        </Descriptions.Item>
        <Descriptions.Item label='Open to Resource Buyout'>
          {getYesOrNo(billing.billing_rate_buy_out)}
        </Descriptions.Item>
      </Descriptions>
      <Typography.Title level={4}>Work Environment</Typography.Title>
      <Descriptions
        colon={false}
        labelStyle={{width: '50%'}}
        className='description-root mb-4'
        column={{xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1}}>
        <Descriptions.Item label='Work Location Type'>
          {RESOURCE_WORK_LOCATION[billing.work_location] || 'N/A'}
        </Descriptions.Item>
        <Descriptions.Item label='Conference Room Access'>
          {getYesOrNo(billing.work_conference_room_access)}
        </Descriptions.Item>
        <Descriptions.Item label='Work Computer'>
          {WORK_COMPUTER_TYPES[billing.work_computer_type]}
        </Descriptions.Item>
        <Descriptions.Item label='Printer / Scanner Access'>
          {PRINT_SCANNER_ACCESS[billing.work_printer_scanner]}
        </Descriptions.Item>
        <Descriptions.Item label='Work Computer OS'>
          {WORK_COMPUTER_OS_TYPES[billing.work_computer_os_type]}
        </Descriptions.Item>
        <Descriptions.Item label='Extra Monitor'>
          {getYesOrNo(billing.work_extra_monitor)}
        </Descriptions.Item>
      </Descriptions>
    </AppCard>
  );
};

export default BillingAndEnvironmentDetails;
