import AppBorderBox from '@wieldy/components/AppBorderBox';
import AppCard from '@wieldy/components/AppCard';
import {AppFormActions, AppFormBody} from '@wieldy/components/AppFormWrapper';
import AppFormWrapper from '@wieldy/components/AppFormWrapper/AppFormWrapper';
import AppDatePicker from '@wieldy/components/dataEntry/AppDatePicker';
import {getRequiredMessage} from '@wieldy/utils/helpers/MessageHelper';
import {Button, Form, Typography} from 'antd';
import {DATE_FORMATS} from 'app/config/app';
import useStep from 'app/hooks/useStep';
import {useGetInvoices} from 'app/services/invoicesService';
import {setEndDate} from 'app/store/features/invoice/invoiceSlice';
import {getItemsData} from 'app/utils/helpers/GetItemsData';
import moment from 'moment/moment';
import PropTypes from 'prop-types';
import React, {useCallback, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';

const SelectPeriod = ({isWeekly}) => {
  const dispatch = useDispatch();
  const {nextStep, currentStep} = useStep();
  const {endDate, engagement} = useSelector(({invoice}) => invoice);
  const name = isWeekly ? 'week_end_date' : 'month_end_date';

  const initialDate = useMemo(
    () =>
      endDate[name]
        ? {
            [name]: moment(endDate[name]),
          }
        : null,
    [endDate, name],
  );

  const {data, isLoading} = useGetInvoices(
    {engagement: engagement?.id},
    {enabled: !!engagement?.id},
  );
  const [invoices] = getItemsData('invoices', data);

  const disabledDates = invoices
    .filter((el) => el.end_date)
    .map((el) => moment(el.end_date).format('YYYY-MM-DD'));

  const onFormSubmit = (formData) => {
    const payload = {
      [name]: formData[name].format(DATE_FORMATS['/']),
    };
    dispatch(setEndDate(payload));
    nextStep();
  };

  const disabledMonthDate = useCallback(
    (current) => {
      const currentDate = moment(current);
      const endOfMonth = currentDate.endOf('month');

      const lastDayOfMonth = moment().endOf('month');
      return (
        !current.isSame(endOfMonth, 'day') ||
        current < moment(engagement?.engagementStart).startOf('months') ||
        disabledDates.includes(current.format('YYYY-MM-DD')) ||
        current > lastDayOfMonth
      );
    },
    [disabledDates, engagement?.engagementStart],
  );
  const disabledWeekDate = useCallback(
    (current) => {
      return (
        (current && current.day() !== 0) ||
        disabledDates.includes(current.format('YYYY-MM-DD')) ||
        current < moment(engagement?.engagementStart).startOf('week') ||
        current > moment().endOf('week')
      );
    },
    [disabledDates, engagement?.engagementStart],
  );
  const disabledDate = useMemo(
    () => (isWeekly ? disabledWeekDate : disabledMonthDate),
    [disabledMonthDate, disabledWeekDate, isWeekly],
  );

  const label = `${isWeekly ? 'Week' : 'Month'} End Date`;
  if (currentStep < 2) {
    return null;
  }
  if (currentStep > 2) {
    return (
      <AppBorderBox
        className='d-inline-flex px-6 mt-3'
        style={{backgroundColor: '#FFF'}}>
        <Typography.Title level={4} className={'mb-0'}>
          {endDate?.[name]}
        </Typography.Title>
      </AppBorderBox>
    );
  }
  return (
    <AppCard
      title={`Select ${label}`}
      headStyle={{border: 0}}
      bodyStyle={{paddingTop: 0}}>
      <AppFormWrapper>
        <Form
          layout='vertical'
          initialValues={initialDate}
          onFinish={onFormSubmit}
          autoComplete='off'>
          <AppFormBody style={{maxWidth: 280}}>
            <Form.Item
              name={name}
              label={label}
              rules={[
                {
                  required: true,
                  message: getRequiredMessage('Date'),
                },
              ]}
              required>
              <AppDatePicker
                disabled={isLoading}
                style={{width: '150px'}}
                disabledDate={disabledDate}
              />
            </Form.Item>
          </AppFormBody>
          <AppFormActions>
            <Button type='primary' htmlType='submit' className='mt-1'>
              Continue
            </Button>
          </AppFormActions>
        </Form>
      </AppFormWrapper>
    </AppCard>
  );
};

SelectPeriod.propTypes = {
  isWeekly: PropTypes.bool,
};

export default SelectPeriod;
