import AppGridContainer from '@wieldy/components/AppGridContainer';
import {useWindowSize} from '@wieldy/hooks/useWindowSize';
import {Col} from 'antd';
import {
  useGetTotalExpensesReport,
  useGetTotalHoursReport,
} from 'app/services/resourceService';
import AreaChartWidget from 'app/shared/charts/AreaChartWidget';
import {
  chartFromDate,
  getOptions,
  mergeExpensesChartToData,
  mergeTimeChartToData,
} from 'app/shared/forms/FilterReportDropdown';
import {useCurrentUser} from 'app/store/features/user/userSlice';
import moment from 'moment';
import React, {useMemo} from 'react';
import {CartesianGrid, XAxis, YAxis} from 'recharts';

const MainContentResource = () => {
  const {width} = useWindowSize();
  const user = useCurrentUser();
  const options = getOptions(moment.unix(user?.created_at).format('MM/DD/YY'));
  // total hours
  const totalHoursDate =
    options[options.findIndex(({key}) => key === 'last_30')].date;
  const {isLoading: totalHoursLoading, data: totalHours} =
    useGetTotalHoursReport({
      from_date: totalHoursDate,
    });
  const chartTotalHours = chartFromDate(totalHoursDate);
  if (totalHours) {
    mergeTimeChartToData(chartTotalHours, totalHours.items);
  }
  // Total Expenses
  const totalExpensesDate =
    options[options.findIndex(({key}) => key === 'last_30')].date;
  const {isLoading: totalExpensesLoading, data: totalExpenses} =
    useGetTotalExpensesReport({
      from_date: totalExpensesDate,
    });
  const chartTotalExpenses = chartFromDate(totalExpensesDate);
  if (totalExpenses) {
    mergeExpensesChartToData(chartTotalExpenses, totalExpenses.items);
  }

  const graphHeight = useMemo(() => {
    let height = 220;
    if (width >= 1600) {
      height = 228;
    } else if (width >= 992) {
      height = 284;
    }
    return height;
  }, [width]);

  return (
    <AppGridContainer>
      <Col xs={24}>
        <AreaChartWidget
          title='Total Hours'
          dataSource={chartTotalHours}
          loading={totalHoursLoading}
          height={graphHeight}
          filter={false}>
          <CartesianGrid vertical={false} />
          <YAxis allowDecimals={false} tickLine={false} axisLine={false} />
          <XAxis dataKey={'dateLabel'} minTickGap={16} tickSize={8} />
        </AreaChartWidget>
      </Col>
      <Col xs={24}>
        <AreaChartWidget
          title='Total Expenses'
          dataSource={chartTotalExpenses}
          loading={totalExpensesLoading}
          height={graphHeight}
          filter={false}
          isDollars>
          <CartesianGrid vertical={false} />
          <YAxis
            allowDecimals={false}
            tickLine={false}
            axisLine={false}
            tickFormatter={(i) => `$${i}`}
          />
          <XAxis dataKey={'dateLabel'} minTickGap={16} tickSize={8} />
        </AreaChartWidget>
      </Col>
    </AppGridContainer>
  );
};

export default React.memo(MainContentResource);
