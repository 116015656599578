import {
  Applicants,
  CreateApplicant,
  EditApplicant,
  ViewApplicant,
} from 'app/pages/main/applicants';

export const supplier_ApplicantsRoutes = [
  {
    path: '/applicants/',
    element: <Applicants />,
  },
  {
    path: '/applicants/:category',
    element: <Applicants />,
  },
  {
    path: '/applicants/create-applicant',
    element: <CreateApplicant />,
  },
  {
    path: '/applicants/:id/view-applicant',
    element: <ViewApplicant />,
  },
  {
    path: '/applicants/:id/edit-applicant',
    element: <EditApplicant />,
  },
];
