import AppTag from '@wieldy/components/AppTag/AppTag';
import {Typography} from 'antd';
import UserMiniProfile from 'app/shared/components/UserMiniProfile';
import {convertHID} from 'app/utils/helpers/ConvertHID';
import PropTypes from 'prop-types';
import React from 'react';

import {topCounts} from '../constants';

const ViewClientHeader = ({client}) => {
  const topItems = React.useMemo(() => topCounts(client), [client]);
  const clientName = client?.name
    ? client?.name?.includes('CLI')
      ? `Client ${convertHID(client?.name, 'CLI-')}`
      : client.name
    : '';

  return (
    <div className='page-header-info'>
      <UserMiniProfile
        name={clientName}
        avatarUrl={client?.logo}
        location={client?.location}
        rating={client?.ratingTotal}
        reviews={client?.ratingCount}
        renderTitle={
          <Typography.Title
            level={3}
            className={'d-flex flex-wrap align-items-center mb-0'}>
            <span className='mr-2'> {clientName}</span>
            <AppTag
              color={client?.statusColor}
              shape='circle'
              label={client?.statusName}
              className='tracking-normal my-1'
            />
          </Typography.Title>
        }
        avatarProps={{shape: 'square'}}
      />
      <div className={'page-header-info-extra'}>
        <div className='list-info'>
          {topItems &&
            topItems?.map((counter, index) => (
              <div key={index} className='list-info-item'>
                <Typography.Text className='info-count'>
                  {counter?.count}
                </Typography.Text>
                <Typography.Text type={'secondary'}>
                  {counter?.label}
                </Typography.Text>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

ViewClientHeader.propTypes = {
  client: PropTypes.object,
};

export default React.memo(ViewClientHeader);
