import CheckCollapse from '@register-app/components/additional/check-collapse';
import DiversitySourcingPractices from '@register-app/components/additional/diversity-sourcing-practices';
import Label from '@register-app/components/additional/label';
import AppCheckbox from '@register-app/components/controles/checkbox';
import {
  companyDiversityStatuses,
  DIVERSITY_ENTITY,
} from '@register-app/constants/company-diversity-statuses';
import {Typography} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

const DiversityInformationFields = ({isSomeChecked, isHint = true}) => {
  return (
    <div>
      <div className='form-col'>
        <div className='pb-5 mb-5 d-flex flex-column align-items-start'>
          <Label className='mb-2' text='Company Diversity Status' />
          {companyDiversityStatuses.map((el) => (
            <CheckCollapse key={el} name={el} label={DIVERSITY_ENTITY[el]} />
          ))}
        </div>
        {isHint && (
          <Typography.Paragraph className='form-hint mt-10'>
            A Certified Diverse Business Enterprise is defined as a business
            that is at least 51% owned, operated, and controlled by an
            individual or group that reflects a diverse range of backgrounds and
            experiences
          </Typography.Paragraph>
        )}
      </div>
      <div className='mb-10' style={{maxWidth: '50%'}}>
        <AppCheckbox
          isRequired={isSomeChecked}
          name='confirm_info'
          label={`I hereby confirm that all the information provided above is accurate and truthful to the best of my knowledge and belief. I'm able to provide supporting documentation if required.`}
        />
      </div>
      <div className='form-col mb-5'>
        <DiversitySourcingPractices />
        {isHint && (
          <Typography.Paragraph className='form-hint'>
            As part of our registration process we will review and compare these
            responses against publicly available information. We reserve the
            right to ask for supporting diversity sourcing documentation.
          </Typography.Paragraph>
        )}
      </div>
    </div>
  );
};
DiversityInformationFields.propTypes = {
  isSomeChecked: PropTypes.bool,
  isHint: PropTypes.bool,
};

export default DiversityInformationFields;
