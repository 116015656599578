import PropTypes from 'prop-types';
import React from 'react';

import AppStepProvider from './AppStepProvider';

const AppStepsWrapper = ({
  onFinish,
  preview,
  isLoading = false,
  children,
  dataSource,
  currentStep,
  isUpdated = false,
}) => {
  return (
    <AppStepProvider
      onFinish={onFinish}
      preview={preview}
      isLoading={isLoading}
      stepData={dataSource || {}}
      current={currentStep}
      isUpdated={isUpdated}>
      {children}
    </AppStepProvider>
  );
};

AppStepsWrapper.propTypes = {
  onFinish: PropTypes.func,
  preview: PropTypes.node,
  isLoading: PropTypes.bool,
  children: PropTypes.node,
  currentStep: PropTypes.number,
  dataSource: PropTypes.any,
  isUpdated: PropTypes.bool,
};

export default AppStepsWrapper;
