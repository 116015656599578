import RequisitionModal from 'app/modals/RequisitionModal';
import Search from 'app/pages/common/Search/Search';
import {
  CreateRequisition,
  default as Requisitions,
  UpdateRequisition,
  ViewRequisition,
} from 'app/pages/main/requisitions';
import RequisitionCard from 'app/shared/requisitions/RequisitionCard';

export const supplier_RequisitionRoutes = [
  {
    path: 'requisitions',
    element: <Requisitions />,
  },
  {
    path: 'requisitions/:category',
    element: <Requisitions />,
  },
  {
    path: 'requisitions/search/:q?',
    element: (
      <Search
        entity={'requisitions'}
        card={(item, loading) => (
          <RequisitionCard
            requisition={new RequisitionModal(item)}
            isLoading={loading}
            showBookmark={false}
          />
        )}
      />
    ),
  },
  {
    path: 'requisitions/:id/view-requisition',
    element: <ViewRequisition />,
  },
];

export const client_RequisitionRoutes = [
  {
    path: 'requisitions',
    element: <Requisitions />,
  },
  {
    path: 'requisitions/new-requisition',
    element: <CreateRequisition />,
  },
  {
    path: 'requisitions/:category',
    element: <Requisitions />,
  },
  {
    path: 'requisitions/:id/view-requisition',
    element: <ViewRequisition />,
  },
  {
    path: 'requisitions/:id/edit-requisition',
    element: <UpdateRequisition />,
  },
];
