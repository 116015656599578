import {useWindowSize} from '@wieldy/hooks/useWindowSize';
import {Button, notification} from 'antd';
import {useDownloadPolicyFiles} from 'app/services/downloadPolicyFiles';
import React, {useCallback} from 'react';
import {Link} from 'react-router-dom';

import {PolicyFilesName, PolicyFilesPath} from '../../constants/policyNames';
import {downloadBlob} from '../utils/downloadBlob';

const CustomerAgreement = () => {
  const {width} = useWindowSize();
  const data = useDownloadPolicyFiles();

  const handleDownload = useCallback(() => {
    data.mutate(
      {
        name: PolicyFilesPath[PolicyFilesName.COOKIE_POLICY],
      },
      {
        onSuccess: (response) => {
          downloadBlob(
            response.file,
            PolicyFilesPath[PolicyFilesName.COOKIE_POLICY],
          );
        },
        onError: (error) => {
          notification.error({message: error?.message});
        },
      },
    );
  }, [data]);

  return (
    <div id='cookie-policy'>
      <article className='post'>
        <div className='post-wrapper'>
          <div className='post-header'>
            <div className='d-flex flex-row flex-between'>
              <h2 className='post-title text-center mb-0'>Cookie Policy</h2>
              {width > 768 && (
                <Button
                  type='primary'
                  shape='round'
                  className='post-header-button items-center d-none lg:flex'
                  onClick={handleDownload}
                  loading={data.isLoading}>
                  Download
                </Button>
              )}
            </div>
            <ul className='post-meta'>
              <li>Effective April 22, 2024</li>
            </ul>
            {width < 769 && (
              <Button
                type='primary'
                shape='round'
                className='post-header-button items-center d-none lg:flex w-full'
                loading={data.isLoading}
                onClick={handleDownload}>
                Download
              </Button>
            )}
          </div>
          <div className='post-content'>
            <p>
              This policy describes how Sourcer uses cookies and other related
              technologies (collectively referred to as “cookies”) when you
              interact with us on{' '}
              <a href='https://sourcer.com' target='_blank' rel='noreferrer'>
                https://sourcer.com
              </a>{' '}
              (the “Site”) as set forth in the Sourcer{' '}
              <Link to='/privacy-policy'>Privacy Policy</Link>.
            </p>

            <p>
              By visiting or using the Site, you agree that we can use the
              cookies described in this Cookie Policy. You can stop or update
              your cookie preferences by changing the settings in your browser
              (more information on how to do this is provided below) or
              adjusting the settings at the bottom of the homepage labeled
              “Cookie Settings”. We may modify this Agreement without notifying
              you, so please check back often for updates.
            </p>

            <h3>What Are Cookies?</h3>

            <p>
              Cookies are text files, containing small amounts of information,
              which are downloaded to your browsing device (such as a computer
              or smartphone) when you visit a website. Cookies can be recognized
              by the website that downloaded them, or by other websites that use
              the same cookies. First-party cookies are cookies that belong to
              Sourcer, or are placed on your device by Sourcer. Third-party
              cookies are cookies that another party places on your browsing
              device through our Site.
            </p>

            <h3>What Are Cookies Used For?</h3>

            <p>
              Cookies do lots of different jobs, like helping us understand how
              the Site is being used, letting you navigate between pages
              efficiently, remembering your preferences, and generally improving
              your browsing experience. Cookies can also help ensure marketing
              you see online is more relevant to you and your interests.
            </p>

            <h3>What Type of Cookies Does Sourcer Use?</h3>

            <p>
              To provide you with the best browsing experience, Sourcer uses the
              following types of cookies: Strictly Necessary, Performance,
              Functional, and Targeting Cookies. You can find out more about
              each cookie category in the sections below.
            </p>

            <h4>Strictly Necessary Cookies</h4>

            <p>
              These cookies are essential, as they enable you to move around the
              Site and use its features, such as accessing secure areas. Without
              these cookies, some services you have asked for such as payment
              submission can’t be provided. These cookies cannot be switched off
              in our systems, because they are necessary for Site functionality.
              While you can set your browser to block or alert you about these
              cookies, some or all parts of the Site may not function.
            </p>

            <h4>Performance Cookies</h4>

            <p>
              These cookies collect information about how you use the Site, for
              example which pages you go to most often and if you get error
              messages from certain pages. These cookies gather only aggregated
              or anonymous information that does not identify you.
            </p>

            <h4>Functionality Cookies</h4>

            <p>
              These cookies allow the Site to remember choices you make (such as
              your username or the region you’re in). For instance, the Site
              uses functional cookies to remember your language preference.
              These cookies can also be used to remember changes you’ve made to
              text size, font and other parts of pages that you can customize.
              They may also be used to provide services you’ve asked for such as
              watching a video or commenting on a blog. They may be set by us or
              by third party providers whose services we have added to our
              pages. If you do not allow these cookies then some or all of these
              services may not function properly.
            </p>

            <h4>Targeting Cookies</h4>

            <p>
              These cookies are used to deliver advertisements that are more
              relevant to you and your interests. They are also used to limit
              the number of times you see an advertisement as well as help
              measure the effectiveness of an advertising campaign. They
              remember that you have visited a website and this information may
              be shared with other organizations such as advertisers. This means
              after you have been to the Site you may see some advertisements
              about our services elsewhere on the Internet.
            </p>

            <h3>How Long Will Cookies Stay on My Browsing Device?</h3>

            <p>
              The length of time a cookie will stay on your browsing device
              depends on whether it is a “persistent” or “session” cookie.
              Session cookies will only stay on your device until you stop
              browsing. Persistent cookies stay on your browsing device until
              they expire or are deleted.
            </p>

            <h3>How To Control And Delete Cookies Through Your Browser?</h3>

            <p>
              The browser you are using to view the Site can enable, disable or
              delete cookies. To do this, follow the instructions provided by
              your browser (usually located within the “Help”, “Tools” or “Edit”
              functions). Please note that if you set your browser to disable
              cookies, you may not be able to access certain parts of the Site (
              e.g. to apply for a job or post a job. Other parts of the Site may
              also not work properly. You can find out more information about
              how to change your browser cookie settings at{' '}
              <a
                href='https://www.allaboutcookies.org'
                rel='nofollow noreferrer'
                target='_blank'>
                www.allaboutcookies.org
              </a>
              .
            </p>

            <p>
              Also, you may update your cookie preferences by clicking the
              “Cookie Settings” button at the bottom of the Site’s homepage.
            </p>

            <h3>Contacting Us</h3>

            <p>
              If you have any questions about this Cookie Policy, please contact
              us at{' '}
              <a
                href='https://sourcer.com/contact'
                target='_blank'
                rel='noreferrer'>
                https://sourcer.com/contact
              </a>{' '}
              or by mail addressed to Attn: Legal, 1111B S Governors Ave, Ste
              20165, Dover, DE 19904, USA.
            </p>
          </div>
        </div>
      </article>
    </div>
  );
};

export default CustomerAgreement;
