import AppGridContainer from '@wieldy/components/AppGridContainer';
import {useWindowSize} from '@wieldy/hooks/useWindowSize';
import {Col} from 'antd';
import {
  useGetActiveSuppliersReport,
  useGetOpenRequisitionsReport,
  useGetPotentialCandidatesReport,
} from 'app/services/clientService';
import AreaChartWidget from 'app/shared/charts/AreaChartWidget';
import {
  chartFromDate,
  getOptions,
  mergeActiveEngagementsToData,
  mergeActiveSuppliersChartToData,
  mergeChartToData,
} from 'app/shared/forms/FilterReportDropdown';
import {useCurrentUser} from 'app/store/features/user/userSlice';
import {formatSecondsToDate} from 'app/utils/helpers/DataFormatHelper';
import moment from 'moment';
import React, {useMemo} from 'react';
import {CartesianGrid, XAxis, YAxis} from 'recharts';

import Widgets from './Widgets';

const MainContentRecruiter = () => {
  const {width} = useWindowSize();
  const user = useCurrentUser();
  const options = getOptions(moment.unix(user?.created_at).format('MM/DD/YY'));
  // Open Requisitions
  const openRequisitionsDate =
    options[options.findIndex(({key}) => key === 'last_30')].date;
  const {isLoading: openRequisitionsLoading, data: openRequisitions} =
    useGetOpenRequisitionsReport({
      from_date: openRequisitionsDate,
    });
  const chartOpenRequisitions = chartFromDate(openRequisitionsDate);
  if (openRequisitions) {
    mergeChartToData(
      chartOpenRequisitions,
      openRequisitions.items.map((item) => ({
        start_date: formatSecondsToDate(item.created_at, 'MM/DD/YY'),
        end_date: formatSecondsToDate(item.closed_at, 'MM/DD/YY'),
      })),
    );
  }
  // Active Suppliers
  const activeSuppliersDate =
    options[options.findIndex(({key}) => key === 'last_30')].date;
  const {isLoading: activeSuppliersLoading, data: activeSuppliers} =
    useGetActiveSuppliersReport({
      from_date: activeSuppliersDate,
    });
  const chartActiveSuppliers = chartFromDate(activeSuppliersDate);
  if (activeSuppliers) {
    mergeActiveSuppliersChartToData(
      chartActiveSuppliers,
      activeSuppliers.items,
    );
  }

  // Potential Candidates
  const potentialCandidatesDate =
    options[options.findIndex(({key}) => key === 'last_30')].date;
  const {isLoading: isPotentialCandidatesLoading, data: potentialCandidates} =
    useGetPotentialCandidatesReport({from_date: potentialCandidatesDate});
  const chartPotentialCandidates = chartFromDate(potentialCandidatesDate);
  if (potentialCandidates) {
    mergeActiveEngagementsToData(
      chartPotentialCandidates,
      potentialCandidates.items.map((item) => ({
        start_date: item.status_active_date,
        end_date: item.status_active_end_date,
      })),
    );
  }

  const graphHeight = useMemo(() => {
    let height = 220;
    if (width >= 1600) {
      height = 228;
    } else if (width >= 992) {
      height = 284;
    }
    return height;
  }, [width]);

  return (
    <AppGridContainer>
      <Col xs={24}>
        <AppGridContainer>
          <Col xs={24} lg={12}>
            <Widgets />
          </Col>
          <Col xs={24} lg={12}>
            <AreaChartWidget
              title='Open Requisitions'
              dataSource={chartOpenRequisitions}
              loading={openRequisitionsLoading}
              height={graphHeight}
              filter={false}>
              <CartesianGrid vertical={false} />
              <YAxis allowDecimals={false} tickLine={false} axisLine={false} />
              <XAxis dataKey={'dateLabel'} minTickGap={16} tickSize={8} />
            </AreaChartWidget>
          </Col>
        </AppGridContainer>
      </Col>
      <Col xs={24}>
        <AppGridContainer>
          <Col xs={24} lg={12}>
            <AreaChartWidget
              title='Active Suppliers'
              dataSource={chartActiveSuppliers}
              loading={activeSuppliersLoading}
              height={graphHeight}
              filter={false}>
              <CartesianGrid vertical={false} />
              <YAxis allowDecimals={false} tickLine={false} axisLine={false} />
              <XAxis dataKey={'dateLabel'} minTickGap={16} tickSize={8} />
            </AreaChartWidget>
          </Col>
          <Col xs={24} lg={12}>
            <AreaChartWidget
              title='Potential Candidates'
              dataSource={chartPotentialCandidates}
              loading={isPotentialCandidatesLoading}
              height={graphHeight}
              filter={false}>
              <CartesianGrid vertical={false} />
              <YAxis allowDecimals={false} tickLine={false} axisLine={false} />
              <XAxis dataKey={'dateLabel'} minTickGap={16} tickSize={8} />
            </AreaChartWidget>
          </Col>
        </AppGridContainer>
      </Col>
    </AppGridContainer>
  );
};

export default React.memo(MainContentRecruiter);
