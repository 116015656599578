import {supplierTypes as SUPPLIER_TYPES} from 'app/constants/supplier-types';
import {array, boolean, number, object, string} from 'yup';

import {URL} from '../utils/regx';

const isAgency = (types) => {
  return (
    types?.includes(SUPPLIER_TYPES.recruitingAgency) ||
    types?.includes(SUPPLIER_TYPES.staffingAgency)
  );
};
export const companyInfoSchema = object({
  name: string().required('Name is required'),
  company_dba_name: string().nullable(),
  type: array()
    .of(string())
    .min(1, 'Select at least one supplier type')
    .required('Select at least one supplier type'),
  company_type: string().required('Company Type is required'),
  company_size: string().required('Company Size is required'),
  website: string()
    .matches(URL, 'Enter correct url!')
    .required('Please enter website'),
  linkedin: string()
    .matches(URL, 'Enter correct url!')
    .required('Please enter linkedin'),
  years_in_business: number()
    .min(0, 'Must be positive')
    .required('Field is required'),
  tax_jurisdiction: string().required('Field is required'),
  company_recruiting_services: object({
    permanent_hires: boolean().nullable(),
    permanent_contingency_search: boolean().nullable(),
    permanent_retained_search: boolean().nullable(),
    permanent_executive_search: boolean().nullable(),
    temporary_hires: boolean().nullable(),
    temp_w_2_employment: boolean().nullable(),
    temp_corp_to_corp: boolean().nullable(),
    temporary_to_permanent_hires: boolean().nullable(),
    recruitment_process_outsourcing: boolean().nullable(),
    other_hires: boolean().nullable(),
  }).when('type', {
    is: (supplierTypes) => isAgency(supplierTypes),
    then: (schema) =>
      schema.test(
        'at-least-one-checked',
        'At least one recruiting service must be selected',
        (values) => {
          return (
            !!values &&
            (values.permanent_hires ||
              values.temporary_hires ||
              values.temporary_to_permanent_hires ||
              values.recruitment_process_outsourcing ||
              values.other_hires ||
              values.permanent_contingency_search ||
              values.permanent_retained_search ||
              values.permanent_executive_search ||
              values.temp_w_2_employment ||
              values.temp_corp_to_corp)
          );
        },
      ),
    otherwise: (schema) => schema.notRequired(),
  }),
  placement_type: object({
    placement_contingent_placement_fee: number()
      .min(0, 'Must be positive')
      .nullable(),
    placement_retained_fee: number().min(0, 'Must be positive').nullable(),
    placement_temporary_hires: number().min(0, 'Must be positive').nullable(),
    placement_temp_to_perm: number().min(0, 'Must be positive').nullable(),
  }).when('type', {
    is: (types) => isAgency(types),
    otherwise: () => object(),
    then: (schema) =>
      schema.test(
        'total-sum',
        'The sum of all fields must be equal to 100',
        (values) => {
          const {
            placement_contingent_placement_fee,
            placement_retained_fee,
            placement_temporary_hires,
            placement_temp_to_perm,
          } = values || {};

          const total =
            (placement_contingent_placement_fee || 0) +
            (placement_retained_fee || 0) +
            (placement_temporary_hires || 0) +
            (placement_temp_to_perm || 0);
          return total === 100;
        },
      ),
  }),
  number_of_recruiters: number().when('type', {
    is: (supplierTypes) =>
      supplierTypes?.includes(SUPPLIER_TYPES.recruitingAgency) ||
      supplierTypes?.includes(SUPPLIER_TYPES.staffingAgency),
    then: (schema) => schema.required('Field is required'),
    otherwise: (schema) => schema.notRequired(),
  }),
  ats_used_by_company: string().nullable(),
  about: string().required('Field is required'),
});
