import {useQueryClient} from 'app/hooks/useReactQuery';

import {placementAPI} from '../api/placementApi';
import useReactQuery, {useReactMutation} from '../hooks/useReactQuery';

const useGetPlacement = (id, params = {}) => {
  return useReactQuery(['placement', id, params], async () => {
    const {data} = await placementAPI.getPlacement(id, params);
    return data;
  });
};

const useGetPlacements = (params = {}) => {
  return useReactQuery(['placement', params], async () => {
    const {data} = await placementAPI.getPlacements(params);
    return {
      placements: data?.data || [],
      total_count: data?.total_count || 0,
    };
  });
};

const useGetAllPlacements = (params = {}, statuses = []) => {
  return useReactQuery(
    ['placements', params],
    async () => {
      const allPromise = statuses.map((status) =>
        placementAPI.getPlacements({...params, ...status}),
      );
      const response = await Promise.all(allPromise);
      return response.map(({data, config}) => ({
        placements: data?.data || [],
        total_count: data?.total_count || 0,
        status: config.params.status,
      }));
    },
    {
      enabled: !!statuses.length,
    },
  );
};

const useCreatePlacement = () => {
  const client = useQueryClient();
  return useReactMutation(async (postdata) => {
    const {data} = await placementAPI.postPlacement(postdata);
    await client.invalidateQueries(['placement-list']);
    return {
      data,
      message: 'Placement created successfully',
    };
  });
};

const useUpdatePlacement = () => {
  const client = useQueryClient();
  return useReactMutation(async ({id_placement, ...payload}) => {
    const {data} = await placementAPI.updatePlacement(id_placement, payload);
    await client.invalidateQueries(['placement-list']);
    return {
      data,
      message: 'Placement updated successfully',
    };
  });
};

export {
  useCreatePlacement,
  useGetAllPlacements,
  useGetPlacement,
  useGetPlacements,
  useUpdatePlacement,
};
