import AppLabel from '@wieldy/components/AppLabel';
import AppLabelSkill from '@wieldy/components/AppLabel/AppLabelSkill';
import {Menu, Popover, Typography} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

const AppLabels = ({items, visible = 2}) => {
  if (!Array.isArray(items) || items.length === 0) {
    return null;
  }

  const lables = [];
  const popoverItems = [];

  items.forEach((item, index) => {
    if (visible > 0 && items.length !== visible) {
      if (index < visible) {
        lables.push(<AppLabel label={item} key={index} />);
      } else {
        popoverItems.push({
          label: item,
          key: index,
        });
        if (visible === index) {
          lables.push(
            <Popover
              content={<Menu items={popoverItems} />}
              key={index}
              placement='right'>
              <Typography.Link>
                <AppLabel label={`+${items.length - visible}`} />
              </Typography.Link>
            </Popover>,
          );
        }
      }
    } else {
      if (index === items.length - 1) {
        popoverItems.push({
          label: item,
          key: index,
        });
        lables.push(
          <AppLabelSkill key={index} item={item} tooltip={popoverItems} />,
        );
      } else {
        lables.push(<AppLabel label={item} key={index} />);
      }
    }
  });

  return <React.Fragment>{lables}</React.Fragment>;
};

AppLabels.propTypes = {
  items: PropTypes.array,
  visible: PropTypes.number,
};

export default React.memo(AppLabels);
