import {Descriptions, Typography} from 'antd';
import React from 'react';

const JobDetails = () => {
  return (
    <div className='mb-6'>
      <Typography.Title level={4} className={'mb-4'}>
        Job Details
      </Typography.Title>

      <Descriptions
        colon={false}
        labelStyle={{width: '50%'}}
        className='description-root mb-4'
        column={{xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1}}>
        <Descriptions.Item label='Worksite'>Client’s Office</Descriptions.Item>
        <Descriptions.Item label='Worksite Location'>
          San Francisco, California
        </Descriptions.Item>
        <Descriptions.Item label='Job Status'>Full Time</Descriptions.Item>
        <Descriptions.Item label='Travel Required'>
          Yes, less than 25%
        </Descriptions.Item>

        <Descriptions.Item label='FLSA Eligible'>Non-Extempt</Descriptions.Item>
        <Descriptions.Item label='Expenses Eligible'>No</Descriptions.Item>
        <Descriptions.Item label='Overtime Eligible'>Yes</Descriptions.Item>
        <Descriptions.Item label='Overtime Multiple'>2.0</Descriptions.Item>
        <Descriptions.Item label='Charge Tax'>No</Descriptions.Item>
        <Descriptions.Item label='Charge Tax on Labor'>No</Descriptions.Item>
        <Descriptions.Item label='Charge Tax on Expenses'>No</Descriptions.Item>
        <Descriptions.Item label='Country Tax'>0.00%</Descriptions.Item>
        <Descriptions.Item label='State Tax'>0.00%</Descriptions.Item>
        <Descriptions.Item label='City Tax'>0.00%</Descriptions.Item>
        <Descriptions.Item label='Payment Terms' span={1}>
          Net 15
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
};

export default JobDetails;
