import {useAuth0} from '@auth0/auth0-react';
import {invitationAPI} from 'app/api/invitationApi';
import {setHeaderAuthorizationToken} from 'app/config/axios';
import {setError} from 'app/store/features/user/userSlice';
import {useCallback} from 'react';
import {useDispatch} from 'react-redux';

import {fetchUser} from '../store/features/user/userThunks';

export const useAuth00 = () => {
  const auth0 = useAuth0();
  const dispatch = useDispatch();
  // Access and log specific query parameters
  const setAuth = useCallback(async () => {
    if (auth0.isLoading) return;
    if (auth0.error) {
      dispatch(setError(auth0.error));
      return;
    }
    if (auth0.isAuthenticated) {
      try {
        const token = await auth0.getAccessTokenSilently();
        setHeaderAuthorizationToken(token);

        const queryParams = new URLSearchParams(
          window.location.href.split('?')[1],
        );
        // Access and log specific query parameters
        const invitationTokenParams = queryParams.get('token');
        const isInvitation = window.location.pathname.includes('invitations');

        const invitationToken = localStorage.getItem('invitationToken');
        const invitationTarget = localStorage.getItem('invitationTarget');
        if (
          invitationTokenParams &&
          isInvitation &&
          !invitationToken &&
          !invitationTarget
        ) {
          const invitationTargetParams = window.location.pathname.replace(
            '/',
            '',
          );
          await invitationAPI.post({
            invitationToken: invitationTokenParams,
            invitationTarget: invitationTargetParams,
          });
        }

        if (invitationToken && invitationTarget) {
          await invitationAPI.post({invitationToken, invitationTarget});
          localStorage.removeItem('invitationToken');
          localStorage.removeItem('invitationTarget');
        }
        dispatch(fetchUser());
      } catch (err) {
        if (err.response && err.response.status === 401) {
          setHeaderAuthorizationToken(null);
          auth0.logout({
            returnTo: window.location.origin,
          });
        }
        dispatch(setError(err));
      }
    } else {
      const queryParams = new URLSearchParams(
        window.location.href.split('?')[1],
      );
      // Access and log specific query parameters
      const invitationToken = queryParams.get('token');
      const isInvitation = window.location.pathname.includes('invitations');

      if (invitationToken && isInvitation) {
        const invitationTarget = window.location.pathname.replace('/', '');
        localStorage.setItem('invitationToken', invitationToken);
        localStorage.setItem('invitationTarget', invitationTarget);
      }
      await auth0.loginWithRedirect({
        screen_hint: isInvitation ? 'signup' : 'login',
        redirectUri: isInvitation
          ? window.location.href
          : window.location.origin,
      });
    }
  }, [dispatch, auth0]);
  return {setAuth};
};
