import useStep from 'app/hooks/useStep';
import EmploymentDetails from 'app/pages/main/resources/EditResource/MainContent/ResourceSteps/Employment/EmploymentDetails';
import EmploymentForm from 'app/pages/main/resources/EditResource/MainContent/ResourceSteps/Employment/EmploymentForm';
import React from 'react';

const EmploymentStep = () => {
  const {nextStep, setStepData, stepData, currentStep} = useStep();
  return (
    <>
      {currentStep > 3 && stepData ? (
        <EmploymentDetails />
      ) : (
        currentStep === 3 && (
          <EmploymentForm onContinue={nextStep} onSave={setStepData} />
        )
      )}
    </>
  );
};

export default EmploymentStep;
