import ClientHelper from 'app/utils/ClientHelper';

export default {
  industries: {
    label: 'Industry',
    options: ClientHelper.getIndustries(),
  },
  company_size: {
    label: 'Company Size',
    options: ClientHelper.getEmployeeSize(),
  },
  engagement_status: {
    label: 'Engagement Status',
    options: ClientHelper.getEngagementStatus(),
  },
};
