import {Typography} from 'antd';
import {splitString} from 'app/utils/helpers/DataFormatHelper.js';
import PropTypes from 'prop-types';
import React from 'react';

import AppPaper from '../../AppCard/AppPaper';

const AppCounterBlock = ({counter, name}) => {
  return (
    <AppPaper>
      <Typography.Title level={5} className='text-color text-sm'>
        {counter}
      </Typography.Title>
      <Typography.Title
        level={5}
        ellipsis
        style={{textTransform: 'capitalize'}}
        className='text-color text-sm mb-0'>
        <span
          dangerouslySetInnerHTML={{
            __html: splitString(name, 10).join('<br>'),
          }}
        />
      </Typography.Title>
    </AppPaper>
  );
};

AppCounterBlock.propTypes = {
  counter: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
};

export default AppCounterBlock;
