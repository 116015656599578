import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  placement: {
    id: undefined,
    hid: undefined,
    applicantFullName: undefined,
    jobTitle: undefined,
  },
  invoiceActions: {
    title: '',
    type: '',
    id: '',
  },
};

export const invoicePlacementSlice = createSlice({
  name: 'invoice_placement',
  initialState,
  reducers: {
    setInvoicePlacement: (state, action) => {
      state.placement = action.payload;
    },
    resetInvoicePlacementActions: (state) => {
      state.invoiceActions = initialState.invoiceActions;
    },
    resetInvoicePlacementState: () => initialState,
  },
});

export const {
  setInvoicePlacement,
  resetInvoicePlacementActions,
  resetInvoicePlacementState,
} = invoicePlacementSlice.actions;

export default invoicePlacementSlice.reducer;
