import {INVOICE_STATUS} from '@wieldy/constants/AppDataConsts';
import {Typography} from 'antd';
import {DATE_FORMATS} from 'app/config/app';
import {
  formatAmount,
  formatSecondsToDate,
} from 'app/utils/helpers/DataFormatHelper';
//import {RiMore2Fill} from 'react-icons/ri';

export const getColumns = (onInvoiceView) => {
  return [
    {
      title: 'Invoice #',
      dataIndex: 'id',
      key: 'invoice',
      render: (id, {hid}) => (
        <Typography.Link onClick={onInvoiceView.bind(null, id)}>
          {hid}
        </Typography.Link>
      ),
    },
    {
      title: 'Invoice Date',
      dataIndex: 'created_at',
      key: 'invoiceDate',
      render: (date) => formatSecondsToDate(date, DATE_FORMATS.md),
    },
    {
      title: 'Total Amount',
      dataIndex: 'total',
      key: 'amount',
      render: (amount) => formatAmount(amount),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => INVOICE_STATUS[status],
    },
  ];
};
