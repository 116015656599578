import {useMergeLink} from '@mergeapi/react-merge-link';
import PropTypes from 'prop-types';
import {useCallback, useEffect} from 'react';

import {useExchange} from '../../../services/mergeService';

const MergeLink = ({link}) => {
  const {mutate: onExchange} = useExchange();

  const onSuccess = useCallback(
    (public_token) => {
      console.log(public_token);
      onExchange(public_token);
    },
    [onExchange],
  );

  const {open} = useMergeLink({
    linkToken: link, // Replace ADD_GENERATED_LINK_TOKEN with the token retrieved from your backend (Step 1)
    onSuccess,
    // tenantConfig: {
    // apiBaseURL: "https://api-eu.merge.dev" /* OR your specified single tenant API base URL */
    // },
  });

  useEffect(() => {
    if (link) {
      open();
    }
  }, [link, open]);
  return null;
};

MergeLink.propTypes = {
  link: PropTypes.string.isRequired,
};

export default MergeLink;
