import AppAmountView from '@wieldy/components/helperComponents/AppAmountView';
import {formatDate} from 'app/utils/helpers/DataFormatHelper';

export const getColumns = () => {
  return [
    {
      title: 'Date of spend',
      dataIndex: 'date_of_spend',
      key: 'date_of_spend',
      render: (date) => formatDate(date),
    },
    {
      title: 'Purpose',
      dataIndex: 'purpose',
      key: 'purpose',
      align: 'left',
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      render: (amount) => <AppAmountView amount={amount} />,
    },
    {
      title: 'Expense Notes',
      dataIndex: 'notes',
      key: 'notes',
      align: 'left',
    },
  ];
};
