import AppCard from '@wieldy/components/AppCard';
import {Typography} from 'antd';
import {COUNTRIES, US_STATES} from 'app/constants';
import PropTypes from 'prop-types';

const PrimaryAddressView = ({details}) => {
  return (
    <AppCard style={{maxWidth: 820}}>
      <div className={'mb-5'}>
        <Typography.Title level={4}>Country</Typography.Title>
        <Typography.Paragraph>
          {COUNTRIES[details?.country]}
        </Typography.Paragraph>
      </div>
      <div className={'mb-5'}>
        <Typography.Title level={4}>Address Line 1</Typography.Title>
        <Typography.Paragraph>{details?.line_1}</Typography.Paragraph>
      </div>
      <div className={'mb-5'}>
        <Typography.Title level={4}>Address Line 2</Typography.Title>
        <Typography.Paragraph>{details?.line_2}</Typography.Paragraph>
      </div>
      <div className={'mb-5'}>
        <Typography.Title level={4}>City</Typography.Title>
        <Typography.Paragraph>{details?.city}</Typography.Paragraph>
      </div>
      <div className={'mb-5'}>
        <Typography.Title level={4}>State / Province</Typography.Title>
        <Typography.Paragraph>
          {COUNTRIES[details?.country] === COUNTRIES.united_states
            ? US_STATES[details?.state]
            : details?.state}
        </Typography.Paragraph>
      </div>
      <div className={'mb-5'}>
        <Typography.Title level={4}>Postal / Zip Code</Typography.Title>
        <Typography.Paragraph>{details?.postal_code}</Typography.Paragraph>
      </div>
    </AppCard>
  );
};

PrimaryAddressView.propTypes = {
  details: PropTypes.object,
};

export default PrimaryAddressView;
