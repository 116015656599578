import {
  RiBuildingLine,
  RiCalendarCheckLine,
  RiCheckboxCircleLine,
  RiGroupLine,
} from 'react-icons/ri';

const icons = {
  employee_size: <RiGroupLine fontSize={24} className={'text-secondary'} />,
  service: <RiBuildingLine fontSize={24} className={'text-secondary'} />,
  calendar: <RiCalendarCheckLine fontSize={24} className={'text-secondary'} />,
  status: <RiCheckboxCircleLine fontSize={24} className={'text-secondary'} />,
};

export const topCounts = (supplier) => {
  return [
    {
      label: 'Active Clients',
      count: supplier?.activeClients,
    },
    {
      label: 'Completed Engagements',
      count: supplier?.completedEngagements,
    },
    {
      label: 'Available Resources',
      count: supplier?.availableResources,
    },
  ];
};

export const getCardItems = (supplier) => {
  return {
    top: topCounts(supplier),
    bottom: [
      {
        label: 'Inquiries',
        count: supplier?.inquiries,
      },
      {
        label: 'Submissions',
        count: supplier?.submissions,
      },
      {
        label: 'Engagements',
        count: supplier?.engagements,
      },
    ],
    content: [
      {
        icon: icons.employee_size,
        content: supplier?.companySize,
      },
      {
        icon: icons.service,
        content: supplier?.serviceFocus,
      },
      {
        icon: icons.calendar,
        content: supplier?.joinedAt,
      },
      {
        icon: icons.status,
        content: supplier?.statusName,
      },
    ],
  };
};
