import AppBorderBox from '@wieldy/components/AppBorderBox';
import AppUserProfile from '@wieldy/components/AppUserProfile';
import {Typography} from 'antd';
import {insert} from 'app/utils/helpers/Insert';
import PropTypes from 'prop-types';
import React, {useMemo} from 'react';
import {
  RiExchangeDollarLine,
  RiMapPinLine,
  RiProfileLine,
} from 'react-icons/ri';
import {useNavigate} from 'react-router-dom';

const ResourceCardDetail = ({resource, isEngagement = false}) => {
  const navigate = useNavigate();
  const items = useMemo(() => {
    const array = [
      {
        icon: <RiProfileLine className={'text-color'} fontSize={18} />,
        content: resource?.title,
      },
      {
        icon: <RiMapPinLine className={'text-color'} fontSize={18} />,
        content: resource?.location,
      },
    ];

    if (isEngagement) {
      return array;
    }

    return insert(array, 1, {
      icon: <RiExchangeDollarLine className={'text-color'} fontSize={18} />,
      content: resource?.billingRate,
    });
  }, [
    isEngagement,
    resource?.billingRate,
    resource?.location,
    resource?.title,
  ]);

  return (
    <React.Fragment>
      <Typography.Title type={'secondary'} level={5} className={'title mb-4'}>
        Resource <span className='title-divider' style={{maxWidth: 60}} />
      </Typography.Title>
      <AppBorderBox className='mb-6'>
        <AppUserProfile
          className={'card-meta-root-lg'}
          avatarUrl={
            resource.avatar ||
            resource?.user?.avatar_url ||
            resource?.resource?.avatar_url
          }
          avatarProps={{size: 80, lineHeight: '72px'}}
          title={resource.name}
          isCompany={false}
          titleProps={{
            onClick: () => navigate(`/resources/${resource.id}/view-resource`),
          }}
          items={items}
        />
      </AppBorderBox>
    </React.Fragment>
  );
};

ResourceCardDetail.propTypes = {
  resource: PropTypes.object,
  isEngagement: PropTypes.bool,
};

export default ResourceCardDetail;
