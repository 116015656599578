import Dashboard from 'app/pages/main/dashboard';
import {
  CreateClient,
  CreateSupplier,
  default as AccountManagerDashboard,
} from 'app/pages/manager/dashboard';
import Page from 'app/shared/Page';

export const supplier_DashboardRoutes = [
  {
    path: '/',
    element: <Page component={Dashboard} />,
  },
  {
    path: '/dashboard',
    element: <Page component={Dashboard} />,
  },
];

export const client_DashboardRoutes = [
  {
    path: '/',
    element: <Page component={Dashboard} />,
  },
  {
    path: '/dashboard',
    element: <Page component={Dashboard} />,
  },
];

export const manager_DashboardRoutes = [
  {
    path: '/',
    element: <AccountManagerDashboard />,
  },
  {
    path: '/accounts',
    element: <AccountManagerDashboard />,
  },
  {
    path: '/accounts/add-new-client',
    element: <CreateClient />,
  },
  {
    path: '/accounts/add-new-supplier',
    element: <CreateSupplier />,
  },
  {
    path: '/accounts/:category',
    element: <AccountManagerDashboard />,
  },
];
