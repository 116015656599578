import AppLoader from '@wieldy/components/AppLoader';
import {Button, Modal} from 'antd';
import {PERMISSIONS} from 'app/config/auth';
import {USER_CREDENTIALS} from 'app/constants/user-creds';
import {useAccountType} from 'app/hooks/useAccountType';
import {useHasPermission} from 'app/hooks/useHasPermission';
import UserModal from 'app/modals/UserModal';
import {
  useDeleteClientUser,
  useGetClientUser,
} from 'app/services/clientService';
import {
  useDeleteSupplierUser,
  useGetSupplierUser,
} from 'app/services/supplierService';
import {Dashboard} from 'app/shared/user';
import React, {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';

import MainContent from './MainContent';

const ViewUser = () => {
  const {id} = useParams();
  const {user: authUser} = useSelector(({user}) => user);
  const {isSupplier} = useAccountType();
  const navigate = useNavigate();
  const {isLoading, data} = (
    isSupplier ? useGetSupplierUser : useGetClientUser
  )(id);
  const mutation = (isSupplier ? useDeleteSupplierUser : useDeleteClientUser)(
    id,
  );

  const permission = useMemo(() => {
    switch (data?.role) {
      case USER_CREDENTIALS.USER_ROLES.owner:
        return 'no';
      case USER_CREDENTIALS.USER_ROLES.admin:
        return isSupplier
          ? PERMISSIONS.CAN_DELETE_SUPPLIER_MEMBER_ADMINS
          : PERMISSIONS.CAN_DELETE_CLIENT_MEMBER_ADMINS;
      case USER_CREDENTIALS.USER_ROLES.hiring_manager:
        return PERMISSIONS.CAN_DELETE_CLIENT_MEMBER_MANAGERS;
      case USER_CREDENTIALS.USER_ROLES.resource_manager:
        return PERMISSIONS.CAN_DELETE_SUPPLIER_MEMBER_MANAGERS;
      case USER_CREDENTIALS.USER_ROLES.resource:
        return PERMISSIONS.CAN_DELETE_RESOURCE;
      default:
        return 'no';
    }
  }, [data?.role, isSupplier]);

  const canDeleteUser = useHasPermission(permission);

  React.useEffect(() => {
    if (data?.user?.id === authUser?.id) {
      navigate('/my-account');
    }
  }, [authUser?.id, data, navigate]);

  if (isLoading) {
    return <AppLoader />;
  }
  const user = new UserModal({
    ...data?.user,
    userRole: data?.role,
    userStatus: data?.status,
  });

  return (
    <React.Fragment>
      <Dashboard
        title={'User Profile'}
        user={{...user, resource: data?.resource}}
        back={true}
        showTabs={true}
        extra={
          canDeleteUser && (
            <Button
              onClick={() =>
                Modal.confirm({
                  title: 'Delete this user?',
                  onOk: () => {
                    mutation.mutate(null, {
                      onSuccess: () => {
                        navigate('/administration/users');
                      },
                    });
                  },
                  okText: 'Delete',
                })
              }
              loading={mutation.isLoading}
              type='primary'
              shape='round'
              ghost>
              Delete User
            </Button>
          )
        }>
        <MainContent user={user} formProps={data} />
      </Dashboard>
    </React.Fragment>
  );
};

export default ViewUser;
