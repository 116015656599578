import {useWindowSize} from '@wieldy/hooks/useWindowSize';
import {Button, notification} from 'antd';
import {useDownloadPolicyFiles} from 'app/services/downloadPolicyFiles';
import React, {useCallback} from 'react';
import {Link} from 'react-router-dom';

import {PolicyFilesName, PolicyFilesPath} from '../../constants/policyNames';
import {downloadBlob} from '../utils/downloadBlob';

const TermsOfUse = () => {
  const {width} = useWindowSize();
  const data = useDownloadPolicyFiles();

  const handleDownload = useCallback(() => {
    data.mutate(
      {
        name: PolicyFilesPath[PolicyFilesName.TERMS_OF_USE],
      },
      {
        onSuccess: (response) => {
          downloadBlob(
            response.file,
            PolicyFilesPath[PolicyFilesName.TERMS_OF_USE],
          );
        },
        onError: (error) => {
          notification.error({message: error?.message});
        },
      },
    );
  }, [data]);

  return (
    <div id='terms-of-use'>
      <article className='post'>
        <div className='post-wrapper'>
          <div className='post-header'>
            <div className='d-flex flex-row flex-between'>
              <h2 className='post-title text-center'>Terms of Use</h2>
              {width > 768 && (
                <Button
                  type='primary'
                  shape='round'
                  className='post-header-button items-center d-none lg:flex'
                  loading={data.isLoading}
                  onClick={handleDownload}>
                  Download
                </Button>
              )}
            </div>
            <ul className='post-meta'>
              <li>Effective April 22, 2024</li>
            </ul>
            {width < 769 && (
              <Button
                type='primary'
                shape='round'
                className='post-header-button items-center d-none lg:flex w-full'
                loading={data.isLoading}
                onClick={handleDownload}>
                Download
              </Button>
            )}
          </div>
          <div className='post-content'>
            <p>
              This document explains the rules that keep our marketplace
              running.
            </p>

            <p>
              We call these rules our Terms of Use. They apply to sourcer.com
              and all the websites and apps we own or run. (And by we, we mean
              Sourcer and our affiliates, which we may also refer to as us.)
            </p>

            <p>
              These terms explain how we expect you to behave when you’re using
              Sourcer – whether you’re a registered user or unregistered site
              visitor on our site.
            </p>

            <p>
              Please read these rules carefully: by using our site, you’re
              agreeing to follow them.
            </p>

            <h3>1. About licenses and third party content</h3>

            <p>
              Here we’ve included the conditions for using our site, which we do
              our best to keep running smoothly (1.1). That means we have the
              right to stop people from using our site and services if needed
              (1.2).
            </p>

            <p>
              You can’t use our intellectual property (1.3), but you can post
              your own content to Sourcer. You’re responsible for this content
              (1.4), and equally, we’re not responsible for content you come
              across from other users (1.5). If you think someone is using
              something you’ve copyrighted, just let us know (1.6).
            </p>

            <h4>1.1 We let you use our site and services</h4>

            <p>
              Technically, we’re giving you a ‘limited license’ to the site.
              Here’s what that means.
            </p>

            <p>
              We’re happy for you to access our website and services (known as
              the services). You’re free to have this access (or limited
              license) as long as you follow these terms of use and all of our
              other Terms of Service as they apply to you.
            </p>

            <p>
              We’ll do our best to make sure our services are safe and working
              as they should, but we can’t guarantee you’ll have access
              continuously. In fact, we might even stop providing certain
              features or the services completely, and don’t have to give notice
              if we do.
            </p>

            <h4>1.2 We can stop letting you use our services</h4>

            <p>We can take away your right to use our services at any time.</p>

            <p>
              If you violate our Terms of Use or other parts of our{' '}
              <Link to='/terms-of-service'>Terms of Service</Link>, we can take
              away your access to Sourcer. Officially, this is called
              terminating your license, and if it happens, we’ll tell you and
              you must stop using our services immediately.
            </p>

            <h4>1.3 We keep the rights to our intellectual property</h4>

            <p>
              Using our services doesn’t mean you can use any of our trademarks
              or other intellectual property, like copyrights and patents. We
              keep all of our rights to our intellectual property, even though
              we let you use our services.
            </p>

            <p>
              Our logos and names are our trademarks and registered in certain
              jurisdictions. Any other product or company names, logos or
              similar marks and symbols you see on Sourcer may be trademarked by
              our partners or other users like you.
            </p>

            <h4>
              1.4 You can use Sourcer to share your content with the world
            </h4>

            <h4>1.4.1 You’re responsible for what you post</h4>

            <p>
              You’re responsible for how you use our site and anything you post
              on it. If someone makes a claim against us because of anything you
              put on the site, you agree to compensate us for our legal fees and
              expenses (the lawyers call this, ‘indemnification’).
            </p>

            <p>
              When you post content on (or through) our site or give us content
              for posting, you agree that you’re completely responsible for that
              content and we’re not. You also agree to only post or give us
              content that:
            </p>

            <ul
              style={{
                listStylePosition: 'outside',
                marginLeft: '3em',
              }}>
              <li>you have the right to post</li>
              <li>is legal</li>
              <li>
                doesn’t violate anyone’s rights, including intellectual property
                rights.
              </li>
            </ul>

            <p>
              You acknowledge and agree that whoever posts content is
              responsible for any harm caused to anyone by that content – not
              Sourcer – and that you’ll compensate and defend us, our partners,
              employees and representatives against any costs or legal or
              government action we have because of your content.
            </p>

            <h5>1.4.2 Other people have some rights to what you post</h5>

            <p>
              By posting content on the site, you give other people some rights
              to that content.
            </p>

            <p>
              Whenever you post content on our site, you give us and our
              affiliates a permanent right (called an ‘irrevocable and
              non-exclusive worldwide license’) to use, edit and share that
              content – across the world and without paying royalties. If your
              name, voice and image appear in content you post, we also might
              use those on the site or in our day-to-day business. For example,
              if you’re a supplier, we might share your profile with clients we
              think could be a good match.
            </p>

            <p>
              You also give each user and site visitor the right to access and
              use your content through the site. They also have the right to
              use, copy and share your content – as long as they do it through
              the site, and follow both our{' '}
              <Link to='/terms-of-service'>Terms of Service</Link> and the law.
            </p>

            <p>
              We might show ads near your content and information, without
              compensating you. Depending on choices you make in your profile,
              we might also include your name or photo when promoting one of our
              features.
            </p>

            <h4>1.4.3 We’re open to your ideas</h4>

            <p>
              We’d love to hear your thoughts on improving Sourcer. Here’s what
              happens when you share them.
            </p>

            <p>
              You can send us comments and suggestions about our services and
              ways to improve them. If you do, you’re agreeing your ideas are
              free and unsolicited, and you don’t expect or ask anything in
              return, unless we’ve specifically asked you for your ideas and
              offered something in return (we like to keep our word).
            </p>

            <p>
              You agree we’re free to use, change and share the idea as we like,
              without being obligated to to give you anything for it. And if you
              do send us an idea, you also agree that this doesn’t affect our
              right to use similar or related ideas – including those we already
              have or get from others.
            </p>

            <h4>1.5 Third parties post on Sourcer, too</h4>

            <p>
              Anyone else who uses our site is responsible for what they post or
              link on Sourcer.
            </p>

            <p>
              We’re not responsible for the accuracy or reliability of any
              content shared by other people on our site, unless they’re
              officially working for us when they share or post the content. Any
              content represents the views of the person sharing it, not
              Sourcer.
            </p>

            <p>
              Our site might also contain links or other access to third-party
              websites and applications. These sites and applications are owned
              and run by other parties, not Sourcer. If we use a link or
              application that goes to a third-party website, it doesn’t mean
              that we endorse it and you agree that you use it without our
              endorsement.
            </p>

            <h4>1.6 You can make a copyright complaint</h4>

            <p>
              If you think content on our site infringes your rights, you can
              ask to have it removed.
            </p>

            <p>
              We’re committed to following U.S. copyright and related laws and
              need site visitors and users to follow them as well. That means
              you can’t use our site to store or share anything that infringes
              anyone’s intellectual property rights, including their rights
              under U.S. copyright law.
            </p>

            <p>
              If you own copyrighted work and think your rights under U.S.
              copyright law have been infringed by anything on our site, the
              Digital Millennium Copyright Act means you can ask us to take it
              down. To start the process, please contact us at:{' '}
              <a href='mailto:legalnotices@sourcer.com'>
                legalnotices@sourcer.com
              </a>
              .
            </p>

            <h3>2. What you’re allowed to do on Sourcer</h3>

            <p>
              You can only use our services for work and to learn from the
              information we share.
            </p>

            <p>
              Our site and services were made to be used for business, not for
              personal or consumer use. We run our marketplace to help companies
              find each other, build working relationships, and make and receive
              payments for that work.
            </p>

            <p>
              You can also use some of our services to get information we think
              might be interesting and useful for our site visitors and users –
              like our Sourcer blog. While we do our best to make sure that this
              information is timely and accurate, there might sometimes be
              mistakes. We don’t make any guarantees about information posted on
              our site, so never use it as tax or legal advice. And you should
              always double-check the information for yourself.
            </p>

            <h3>3. What you’re not allowed to do on Sourcer</h3>

            <p>
              Certain uses of the site are not allowed. Here we go into much
              more depth about those things, including:
            </p>

            <ul
              style={{
                listStylePosition: 'outside',
                marginLeft: '3em',
              }}>
              <li>posting unacceptable content (3.1)</li>
              <li>acting in a misleading or fraudulent way (3.2)</li>
              <li>treating others unfairly (3.3)</li>
              <li>abusing our feedback system (3.4)</li>
              <li>other uses that aren’t allowed (3.5)</li>
            </ul>

            <p>
              In short, you’re not allowed to use our services to do (or
              encourage others to do) anything that is illegal, fraudulent or
              harmful. If you don’t see something on one of the lists below, you
              shouldn’t assume it’s allowed. When in doubt, contact us to check.
            </p>

            <h4>3.1 Posting unacceptable content</h4>

            <p>You can’t offer, share, support or try to find anything that:</p>

            <ul
              style={{
                listStylePosition: 'outside',
                marginLeft: '3em',
              }}>
              <li>is illegal or defamatory</li>
              <li>
                is violent, discriminatory or harassing, either generally or
                towards a specific person or group (or encourages others to be),
                including anyone who is part of a legally protected group
              </li>
              <li>
                is sexually explicit or related to sex work or escort services
              </li>
              <li>is in any way related to child exploitation</li>
              <li>
                would infringe on any intellectual property rights, including
                copyrights
              </li>
              <li>
                would violate our Terms of Service, another website’s terms of
                service, or any similar contract
              </li>
              <li>
                would go against professional or academic standards or policies
                – including improperly submitting someone else’s work as your
                own, or by ghost-writing essays, tests, or certifications
              </li>
              <li>
                involves purchasing or requesting a fake review or is connected
                in any way to making or sharing misleading content which is
                intended to deceive others.
              </li>
            </ul>

            <h4>3.2 Acting in a misleading or fraudulent way</h4>

            <p>
              On Sourcer, you can’t do anything that’s dishonest or meant to
              fool others.
            </p>

            <p>
              You can’t misrepresent your company, your resources’ experience,
              skills or professional qualifications, or that of others. This
              includes:
            </p>

            <ul
              style={{
                listStylePosition: 'outside',
                marginLeft: '3em',
              }}>
              <li>
                lying about your resources’ experience, skills or professional
                qualifications
              </li>
              <li>
                using generative AI or other tools to substantially bolster
                inquiries from clients or work product if such use is restricted
                by your client or violates any third-party&apos;s rights
              </li>
              <li>
                passing off any part of another company’s profile or identity as
                your own
              </li>
              <li>
                impersonating or falsely attributing statements to any person or
                entity, including a Sourcer representative
              </li>
              <li>
                falsely claiming or implying you’re connected to a person or
                organization (including Sourcer) – for example, you can’t say
                you work with a particular company when you don’t, and suppliers
                can’t use a resource’s profile if they’ve stopped working
                together.
              </li>
            </ul>

            <p>
              Similarly, you must always be honest about who’s doing the work.
              That means you can’t:
            </p>

            <ul
              style={{
                listStylePosition: 'outside',
                marginLeft: '3em',
              }}>
              <li>
                allow someone else to use your account, which misleads other
                users or
              </li>
              <li>
                falsely claim one resource will do a job when another will
                actually do it – including submitting a profile of the resource
                who can’t or won’t do the work.
              </li>
            </ul>

            <p>
              We’re particularly invested in avoiding fraud and
              misrepresentations when it comes to payments. This means:
            </p>

            <p>
              Suppliers can’t fraudulently charge a client in any way, including
              by:
            </p>

            <ul
              style={{
                listStylePosition: 'outside',
                marginLeft: '3em',
              }}>
              <li>
                reporting or billing time the engaged resource haven’t actually
                worked
              </li>
              <li>
                reporting time worked by someone else and claiming the engaged
                resource did the work
              </li>
              <li>
                demanding payments without the intention of or without actually
                providing services in exchange for the payment.
              </li>
            </ul>

            <p>
              Clients can’t engage in fraud related to payments, including by:{' '}
            </p>

            <ul
              style={{
                listStylePosition: 'outside',
                marginLeft: '3em',
              }}>
              <li>
                posting requisitions with payment terms that are objectively
                unreasonable or disproportionate to the scope of services
                requested
              </li>
              <li>
                demanding services without the intention of or without actually
                providing payment in exchange for the services.
              </li>
            </ul>

            <h4>3.3 Treating others unfairly</h4>

            <p>Everyone should be treated fairly and legally on Sourcer.</p>

            <p>You can’t use Sourcer to:</p>

            <ul
              style={{
                listStylePosition: 'outside',
                marginLeft: '3em',
              }}>
              <li>
                express an unlawful preference in a requisition, inquiry or
                submission
              </li>
              <li>unlawfully discriminate against someone</li>
              <li>incite or encourage violence</li>
              <li>
                post personal identifying information or other sensitive,
                private data about another person
              </li>
              <li>
                spam other companies with inquiries or submissions. This
                includes posting the same requisition several times at once and
                contacting people you connected with on Sourcer outside of
                Sourcer without their permission
              </li>
              <li>
                make or demand bribes or payments for anything other than the
                work
              </li>
              <li>
                ask for or demand free work – you can’t ask suppliers to submit
                work for little or no payment as part of a proposal bid or
                competition
              </li>
              <li>
                request a fee in order to submit an inquiry or submission.
              </li>
            </ul>

            <h4>3.4 Abusing our feedback system</h4>

            <p>You must use our feedback system honestly and fairly. </p>

            <p>That means you can’t:</p>

            <ul
              style={{
                listStylePosition: 'outside',
                marginLeft: '3em',
              }}>
              <li>
                withhold payment or work until you’ve been given positive
                feedback
              </li>
              <li>
                swap payment (or anything of value) for feedback, including with
                third parties
              </li>
              <li>coerce another company by threatening negative feedback</li>
              <li>
                use the system to share unrelated views (like about politics or
                religion)
              </li>
              <li>
                offer or accept fake services to improve your feedback or rating
                score, which is called feedback building
              </li>
            </ul>

            <h4>3.5 Other uses that aren’t allowed</h4>

            <p>
              Sourcer relies on technology and trust – here’s how we maintain
              those things.
            </p>

            <ul
              style={{
                listStylePosition: 'outside',
                marginLeft: '3em',
              }}>
              <li>
                You can’t copy, share or give away your account. You can’t have
                multiple accounts and you can’t sell, trade or give your account
                to anyone else without our permission.
              </li>
              <li>
                You can’t promote other organizations – including advertising
                any other websites, products or services. You also can’t use our
                site to recruit suppliers or clients to join another agency,
                website or company, unless you pay us a fee to do so.
              </li>
              <li>
                You can’t interfere with our technology or tamper with our site
                or services. That means you can’t:
                <br />
                <br />
                <ul
                  style={{
                    listStylePosition: 'outside',
                    marginLeft: '3em',
                  }}>
                  <li>
                    bypass any security features we’ve put in place to restrict
                    how you use the site – you’re not allowed to try and get
                    around restrictions on copying content
                  </li>
                  <li>
                    interfere with or compromise our systems, server security,
                    or transmissions
                  </li>
                  <li>
                    use a robot, spider, scraper, or similar mechanisms on our
                    site without written permission
                  </li>
                  <li>
                    copy, distribute, or otherwise use any information you found
                    on Sourcer, if whether directly or through third parties
                    (like search engines), without our consent (no scraping
                    allowed)
                  </li>
                  <li>
                    collect or use identifiable information, including account
                    names
                  </li>
                  <li>
                    overwhelm the site with an unreasonable or large amount of
                    information
                  </li>
                  <li>
                    introduce any malware or any other code or viruses that
                    could harm us, our customers, or our services
                  </li>
                  <li>
                    access our services through any technology other than our
                    interface
                  </li>
                  <li>
                    frame or link to the services without our written permission
                  </li>
                  <li>
                    use our services to build a similar service, identify or
                    poach our users or publish any performance or benchmark
                    analysis relating to the site
                  </li>
                  <li>
                    reverse engineer, decipher, modify, or take source code from
                    our site that is not open source without our written
                    permission.
                  </li>
                </ul>
              </li>
            </ul>

            <h3>4. Enforcing our terms of use</h3>

            <p>
              If you break any of these rules, we can suspend your account and
              stop you from using Sourcer (4.1). If you see someone else
              breaking these rules, please let us know (4.2).
            </p>

            <h4>4.1 We enforce these rules</h4>

            <p>
              We have the right to look into any potential violations of these
              terms of use, and might decide to pause, change or take away any
              content on our site when we do.
            </p>

            <p>
              We can’t guarantee that we’ll take action against every potential
              violation, but just because we don’t take action against one
              breach doesn’t waive our right to take action against any future
              breaches, whether they’re related to the first breach or not.
            </p>

            <p>
              If we do suspect rule-breaking, we can stop you using our site at
              any time. If we disable or close your account, you won’t be able
              to use any of our services, but these things will stay in place:
            </p>

            <ul
              style={{
                listStylePosition: 'outside',
                marginLeft: '3em',
              }}>
              <li>our rights to use and share your feedback</li>
              <li>
                our users’ and visitors&apos; rights to share your content
                (1.4.2)
              </li>
              <li>
                your agreement to all the rules laid out in section 3 on this
                page.
              </li>
            </ul>

            <h4>4.2 Tell us if you see someone breaking these rules</h4>

            <p>
              What to do if you become aware of a violation of our Terms of Use.
            </p>

            <p>
              If you believe anyone is breaking any of our terms of use, please
              let our customer service team know.
            </p>

            <p>
              If we follow up on the breach, you agree to help with our
              investigation and take reasonable steps to help us fix the
              problem.{' '}
            </p>

            <h3>5. Definitions</h3>

            <p>
              Here, we explain some of the terms we’ve used in our Terms of Use.
              Any other terms in italics should be defined when they’re
              mentioned, throughout the{' '}
              <Link to='/terms-of-service'>Terms of Service</Link>.
            </p>

            <p>
              An affiliate is anyone or anything that in any way manages, is
              managed by, or shares management with us.
            </p>

            <p>
              A customer is a company using our site to source talent services
              from a supplier.
            </p>

            <p>
              A supplier is a company using our site to offer their services to
              customers.
            </p>

            <p>
              Supplier services refer to the work supplier’s resources offer to
              their customers.
            </p>

            <p>
              A means of direct contact is information that would let someone
              get in touch with you directly (or find the information to do
              that) so you can bypass our site. For example, phone numbers,
              email and physical addresses, social media accounts, and personal
              websites with contact information are means of direct contact.
            </p>

            <p>
              Site services are all services, applications and products – apart
              from supplier services – that people can access through Sourcer.
            </p>

            <p>
              Content is what users post to Sourcer themselves, like comments,
              profiles, feedback, images, or other information. It includes
              anything posted by you even if elements of the content were
              originally generated by generative AI or other tools, or in
              response to questions posed to you by Sourcer or other users or
              Sourcer.
            </p>
          </div>
        </div>
      </article>
    </div>
  );
};

export default TermsOfUse;
