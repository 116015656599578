import {useWindowSize} from '@wieldy/hooks/useWindowSize';
import {Button, notification} from 'antd';
import {useDownloadPolicyFiles} from 'app/services/downloadPolicyFiles';
import React, {useCallback} from 'react';

import {PolicyFilesName, PolicyFilesPath} from '../../constants/policyNames';
import {downloadBlob} from '../utils/downloadBlob';

const Global = () => {
  const {width} = useWindowSize();
  const data = useDownloadPolicyFiles();

  const handleDownload = useCallback(() => {
    data.mutate(
      {
        name: PolicyFilesPath[PolicyFilesName.GLOBAL_DATA],
      },
      {
        onSuccess: (response) => {
          downloadBlob(
            response.file,
            PolicyFilesPath[PolicyFilesName.GLOBAL_DATA],
          );
        },
        onError: (error) => {
          notification.error({message: error?.message});
        },
      },
    );
  }, [data]);
  return (
    <div id='global-data-processing-agreement'>
      <article className='post'>
        <div className='post-wrapper'>
          <div className='post-header'>
            <div className='d-flex flex-row flex-between'>
              <h2 className='post-title text-center mb-0'>
                Global Data Processing Agreement
              </h2>
              {width > 768 && (
                <Button
                  type='primary'
                  shape='round'
                  className='post-header-button items-center d-none lg:flex'
                  loading={data.isLoading}
                  onClick={handleDownload}>
                  Download
                </Button>
              )}
            </div>
            <ul className='post-meta'>
              <li>Effective April 22, 2024</li>
            </ul>
            {width < 769 && (
              <Button
                type='primary'
                shape='round'
                className='post-header-button items-center d-none lg:flex w-full'
                loading={data.isLoading}
                onClick={handleDownload}>
                Download
              </Button>
            )}
          </div>
          <div className='post-content'>
            <p>
              The Client agreeing to these terms (“Customer”), and Sourcer, Inc.
              or any other entity that directly or indirectly controls, is
              controlled by, or is under common control with Sourcer, Inc. (as
              applicable, “Sourcer”) (each, a “party” and collectively, the
              “parties”), have entered into an agreement under which Sourcer has
              agreed to provide a marketplace where Clients and Suppliers can
              identify each other and advertise, buy, and sell Supplier Services
              online, with such other services, if any, described in the
              agreement (the “Service”) to Customer (as amended from time to
              time, the “Agreement”). Unless otherwise agreed to in writing by
              you and Sourcer, to the extent Sourcer processes any EU personal
              data for you as a controller (as defined by the General Data
              Protection Regulation (EU) 2016/679) in your role as a Customer as
              defined in this Global Data Processing Agreement (the “DPA”), this
              DPA applies. This DPA, including its appendices, supplements the
              Agreement. To the extent of any conflict or inconsistency between
              this DPA and the remaining terms of the Agreement, this DPA will
              govern.
            </p>

            <h3>1. Introduction</h3>

            <p>
              This DPA reflects the parties’ agreement with respect to the
              processing and security of Customer Data under the Agreement.
            </p>

            <h3>2. Definitions</h3>

            <p>
              2.1 The terms “personal data”, “data subject”, “processing”,
              “controller”, “processor” and “supervisory authority” have the
              meanings given in the GDPR, and the terms “data importer” and
              “data exporter” have the meanings given in the Standard
              Contractual Clauses, in each case irrespective of whether the
              European Data Protection Legislation or Non-European Data
              Protection Legislation applies.
            </p>

            <p>2.2 Unless stated otherwise:</p>

            <ul
              style={{
                listStylePosition: 'outside',
                marginLeft: '3em',
              }}>
              <li>
                “Affiliate” means any entity that controls or is under common
                control with a specified entity.
              </li>
              <li>
                “Agreed Liability Cap” means the maximum monetary or
                payment-based amount at which a party’s liability is capped
                under the Agreement.
              </li>
              <li>
                “Confidential Information” means any information or materials
                (regardless of form or manner of disclosure) that are disclosed
                by or on behalf of one party to the other party that (i) are
                marked or communicated as being confidential at or within a
                reasonable time following such disclosure; or (ii) should be
                reasonably known to be confidential due to their nature or the
                circumstances of their disclosure. The term “Confidential
                Information” does not include any information or materials that:
                (a) are or become generally known or available to the public
                through no breach of this Agreement or other wrongful act or
                omission by the receiving party; (b) were already known by the
                receiving party without any restriction; (c) are acquired by the
                receiving party without restriction from a third party who has
                the right to make such disclosure; or (d) are independently
                developed by or on behalf of the receiving party without
                reference to any Confidential Information.
              </li>
              <li>
                “Customer Account Data” means personal data that relates to
                Customer’s relationship with Sourcer, including the names and/or
                contact information of individuals authorized by Customer to
                access Customer’s Sourcer account and billing information of
                individuals that Customer has associated with its Sourcer
                account.
              </li>
              <li>
                “Customer Personal Data” means the personal data contained
                within the Customer Data.
              </li>
              <li>
                “Customer Data” means the data entered into the Service by or on
                behalf of any End User, but excludes Customer Account Data.
              </li>
              <li>
                “End User” means an authorized user of the Service under
                Customer’s account.
              </li>
              <li>
                “Data Incident” means a breach of Sourcer’s security leading to
                the accidental or unlawful destruction, loss, alteration,
                unauthorized disclosure of, or access to, Customer Data on
                systems managed by or otherwise controlled by Sourcer. “Data
                Incidents” will not include unsuccessful attempts or activities
                that do not compromise the security of Customer Data, including
                unsuccessful log-in attempts, pings, port scans, denial of
                service attacks, and other network attacks on firewalls or
                networked systems.
              </li>
              <li>
                “EEA” means the European Economic Area, Switzerland, and/or the
                United Kingdom.
              </li>
              <li>
                “European Data Protection Legislation” means, as applicable: (a)
                the GDPR and its respective national implementing legislations;
                and/or (b) the GDPR as it forms part of the law of England and
                Wales, Scotland and Northern Ireland by virtue of section 3 of
                the European Union (Withdrawal) Act 2018 (the “UK GDPR”).
              </li>
              <li>
                “GDPR” means Regulation (EU) 2016/679 of the European Parliament
                and of the Council of 27 April 2016 on the protection of natural
                persons with regard to the processing of personal data and on
                the free movement of such data, and repealing Directive
                95/46/EC.
              </li>
              <li>
                “EU SCCs” means the EU Standard Contractual Clauses approved by
                the European Commission in decision 2021/914 located at{' '}
                <a
                  href='https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj'
                  rel='nofollow noreferrer'
                  target='_blank'>
                  https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj
                </a>
                .
              </li>
              <li>
                “Non-European Data Protection Legislation” means, as applicable,
                the data protection or privacy laws, regulations, and other
                legal requirements other than the European Data Protection
                Legislation.
              </li>
              <li>
                “Notification Email Address” means the contact email address
                that you provided to Sourcer for the purpose of receiving
                notices from Sourcer.
              </li>
              <li>
                “Security Measures” has the meaning given in Section 7.1.1
                (Sourcer’s Security Measures).
              </li>
              <li>
                “Subprocessors” means third parties authorized under this DPA to
                have logical access to and process Customer Data in order to
                provide parts of the Service. For clarity, freelancers that
                clients engage via Sourcer are not Subprocessors under this DPA.
              </li>
              <li>
                “Term” means the period from the DPA’s effective date until the
                end of Sourcer’s provision of the Service, including, if
                applicable, any period during which provision of the Service may
                be suspended and any post- termination period during which
                Sourcer may continue providing the Service for transitional
                purposes.
              </li>
              <li>
                “United Kingdom International Data Transfer Agreement or
                Addendum” (“UK IDTA”) means either, as applicable, (a) the
                International Data Transfer Agreement when used under the UK
                GDPR, or (b) the International Data Transfer Addendum to the EU
                SCCs issued by the Commissioner under s119A(1) of the Data
                Protection Act 2018, version A1.0, in force from March 21, 2022.
              </li>
            </ul>

            <h3>3. Duration of this DPA</h3>

            <p>
              This DPA will remain in effect until, and automatically expire
              upon, deletion of all Customer Data by Sourcer as described in
              this DPA.
            </p>

            <h3>4. Data Protection Legislation</h3>

            <p>
              4.1 <u>Application of European Legislation</u>. The parties
              acknowledge that the European Data Protection Legislation will
              apply to the processing of Customer Personal Data to the extent
              provided under the European Data Protection Legislation.
            </p>

            <p>
              4.2 <u>Application of Non-European Legislation</u>. The parties
              acknowledge that Non-European Data Protection Legislation may also
              apply to the processing of Customer Personal Data.
            </p>

            <h3>5. Processing of Data</h3>

            <p>
              5.1 <u>Roles and Regulatory Compliance; Authorization</u>.
            </p>

            <p>
              5.1.1 <u>Processor and Controller Responsibilities</u>. If the
              European Data Protection Legislation applies to the processing of
              Customer Personal Data, the parties acknowledge and agree that:
            </p>

            <ul
              style={{
                listStyle: 'none',
                listStylePosition: 'outside',
                marginLeft: '3em',
              }}>
              <li>
                5.1.1.1 Customer is a controller (or processor, as applicable),
                of the Customer Personal Data under European Data Protection
                Legislation;
              </li>
              <li>
                5.1.1.2 Sourcer is a processor (or subprocessor, as applicable)
                of the Customer Personal Data under the European Data Protection
                Legislation; and
              </li>
              <li>
                5.1.1.3 each party will comply with the obligations applicable
                to it under the European Data Protection Legislation with
                respect to the processing of that Customer Personal Data.
              </li>
            </ul>

            <p>
              5.1.2 <u>Responsibilities under Non-European Legislation</u>. If
              Non-European Data Protection Legislation applies to either party’s
              processing of Customer Personal Data, the parties acknowledge and
              agree that the relevant party will comply with any obligations
              applicable to it under that legislation with respect to the
              processing of that Customer Personal Data.
            </p>

            <p>
              5.1.3 <u>Authorization by Third Party Controller</u>. If Customer
              is a processor, Customer warrants to Sourcer that Customer’s
              instructions (defined below) and actions with respect to that
              Customer Personal Data, including its appointment of Sourcer as
              another processor, have been authorized by the relevant controller
              to the extent required by applicable law.
            </p>

            <p>
              5.2 <u>Scope of Processing</u>.
            </p>

            <p>
              5.2.1 The subject matter and details of the processing are
              described in Appendix 1.
            </p>

            <p>
              5.2.2 <u>Customer’s Instructions</u>. By entering into this DPA,
              Customer instructs Sourcer to process Customer Personal Data only
              in accordance with applicable law: (a) to provide the Service; (b)
              as further specified through Customer’s use of the Service; (c) as
              documented in the Agreement, including this DPA; and (d) as
              further documented in any other written instructions given by
              Customer and acknowledged by Sourcer as constituting instructions
              for purposes of this DPA (each and collectively, “Customer’s
              Instructions”) and only for the foregoing purposes and not for the
              benefit of any other third party. Sourcer may condition the
              acknowledgement described in (d) on the payment of additional fees
              or the acceptance of additional terms.
            </p>

            <p>
              5.2.3 <u>Sourcer’s Compliance with Instructions</u>. With respect
              to Customer Personal Data subject to European Data Protection
              Legislation, Sourcer will comply with the instructions described
              in Section 5.2.2 (Customer’s Instructions) (including with regard
              to data transfers) unless EU or EU Member State law to which
              Sourcer is subject requires other processing of Customer Personal
              Data by Sourcer, in which case Sourcer will inform Customer
              (unless that law prohibits Sourcer from doing so on important
              grounds of public interest) via the Notification Email Address.
            </p>

            <h3>6. Data Deletion</h3>

            <p>
              6.1 <u>Deletion by Customer</u>. Sourcer will enable Customer to
              delete Customer Data during the Term in a manner consistent with
              the functionality of the Service. If Customer uses the Service to
              delete any Customer Data during the Term and that Customer Data
              cannot be recovered by Customer, this use will constitute an
              instruction to Sourcer to delete the relevant Customer Data from
              Sourcer’s systems in accordance with applicable law. Sourcer will
              comply with this instruction as soon as reasonably practicable,
              unless applicable law requires storage. Nothing herein requires
              Sourcer to delete Customer Data from files created for security,
              backup, and business continuity purposes sooner than required by
              Sourcer’s existing data retention processes.
            </p>

            <p>
              6.2 <u>Deletion on Termination</u>. On expiry of the Term,
              Customer instructs Sourcer to delete all Customer Data (including
              existing copies) from Sourcer’s systems in accordance with
              applicable law. Sourcer will comply with this instruction as soon
              as reasonably practicable, unless applicable law requires storage.
              Without prejudice to Section 9.1 (Access; Rectification;
              Restricted Processing; Portability), Customer acknowledges and
              agrees that Customer will be responsible for exporting, before the
              Term expires, any Customer Data it wishes to retain afterwards. If
              the EU or the UK SCCs are applicable to Sourcer’s processing of
              Customer Personal Data, the parties agree that the certification
              of deletion referenced in Clauses 8.5 and 16(d) of the EU and the
              UK SCCs shall be provided only upon Customer’s written request.
              Nothing herein requires Sourcer to delete Customer Data from files
              created for security, backup, and business continuity purposes
              sooner than required by Sourcer’s existing data retention
              processes.
            </p>

            <h3>7. Data Security</h3>

            <p>
              7.1 <u>Sourcer’s Security Measures, Controls and Assistance</u>.
            </p>

            <p>
              7.1.1 <u>Sourcer’s Security Measures</u>. Sourcer will implement
              and maintain technical and organizational measures designed to
              protect Customer Data against accidental or unlawful destruction,
              loss, alteration, unauthorized disclosure or access as described
              in Appendix 2 (the “Security Measures”). As described in Appendix
              2, the Security Measures include measures to encrypt personal
              data; to help ensure ongoing confidentiality, integrity,
              availability and resilience of Sourcer’s systems and services; to
              help restore timely access to personal data following an incident;
              and for regular testing of effectiveness. Sourcer may update or
              modify the Security Measures from time to time provided that such
              updates and modifications do not degrade the overall security of
              the Service.
            </p>

            <p>
              7.1.2 <u>Security Compliance by Sourcer Staff</u>. Sourcer will
              take appropriate steps to ensure compliance with the Security
              Measures by its staff to the extent applicable to their scope of
              performance, including ensuring that all such persons it
              authorizes to process Customer Personal Data have committed
              themselves to confidentiality or are under an appropriate
              statutory obligation of confidentiality.
            </p>

            <p>
              7.1.3 <u>Sourcer’s Security Assistance</u>. Customer agrees that
              Sourcer will (taking into account the nature of the processing of
              Customer Personal Data and the information available to Sourcer)
              assist Customer in ensuring compliance with any of Customer’s
              obligations in respect of security of personal data and personal
              data breaches, including if applicable Customer’s obligations
              pursuant to Articles 32 to 34 (inclusive) of the GDPR, by:
            </p>

            <ul
              style={{
                listStyle: 'none',
                listStylePosition: 'outside',
                marginLeft: '3em',
              }}>
              <li>
                7.1.3.1 implementing and maintaining the Security Measures in
                accordance with Section 7.1.1 (Sourcer’s Security Measures);
              </li>
              <li>
                7.1.3.2 complying with the terms of Section 7.2 (Data
                Incidents); and
              </li>
              <li>
                7.1.3.2 providing Customer with the information contained in the
                Agreement including this DPA.
              </li>
            </ul>

            <p>
              7.2 <u>Data Incidents</u>.
            </p>

            <p>
              7.2.1 <u>Incident Notification</u>. If Sourcer becomes aware of a
              Data Incident, Sourcer will: (a) notify Customer of the Data
              Incident promptly and without undue delay after becoming aware of
              the Data Incident; and (b) promptly take reasonable steps to
              minimize harm and secure Customer Data.
            </p>

            <p>
              7.2.2 <u>Details of Data Incident</u>. Notifications made pursuant
              to this section will describe, to the extent practicable, details
              of the Data Incident, including steps taken to mitigate the
              potential risks and any steps Sourcer recommends Customer take to
              address the Data Incident.
            </p>

            <p>
              7.2.3 <u>Delivery of Notification</u>. Notification(s) of any Data
              Incident(s) will be delivered to the Notification Email Address
              or, at Sourcer’s discretion, by direct communication (for example,
              by phone call or an in-person meeting). Customer is solely
              responsible for ensuring that the Notification Email Address is
              current and valid.
            </p>

            <p>
              7.2.4 <u>No Assessment of Customer Data by Sourcer</u>. Sourcer
              will not assess the contents of Customer Data in order to identify
              information subject to any specific legal requirements. Customer
              is solely responsible for complying with legal requirements for
              incident notification applicable to Customer and fulfilling any
              third party notification obligations related to any Data
              Incident(s).
            </p>

            <p>
              7.2.5 <u>No Acknowledgement of Fault by Sourcer</u>. Sourcer’s
              notification of or response to a Data Incident under this Section
              7.2 (Data Incidents) is not an acknowledgement by Sourcer of any
              fault or liability with respect to the Data Incident.
            </p>

            <p>
              7.3 <u>Customer’s Security Responsibilities and Assessment</u>.
            </p>

            <p>
              7.3.1 <u>Customer’s Security Responsibilities</u>. Customer agrees
              that, without prejudice to Sourcer’s obligations under Section 7.1
              (Sourcer’s Security Measures, Controls and Assistance) and Section
              7.2 (Data Incidents):
            </p>

            <ul
              style={{
                listStyle: 'none',
                listStylePosition: 'outside',
                marginLeft: '3em',
              }}>
              <li>
                7.3.1.1 Customer is solely responsible for its use of the
                Service, including:
                <br />
                <br />
                <ul
                  style={{
                    listStyle: 'none',
                    listStylePosition: 'outside',
                    marginLeft: '3em',
                  }}>
                  <li>
                    7.3.1.1.1 making appropriate use of the Service to ensure a
                    level of security appropriate to the risk in respect of the
                    Customer Data;
                  </li>
                  <li>
                    7.3.1.1.2 securing the account authentication credentials,
                    systems and devices Customer uses to access the Service;
                  </li>
                  <li>7.3.1.1.3 backing up its Customer Data; and</li>
                </ul>
              </li>
              <li>
                7.3.1.2 Sourcer has no obligation to protect Customer Data that
                Customer elects to store or transfer outside of the Service.
              </li>
            </ul>

            <p>
              7.3.2 <u>Customer’s Security Assessment</u>.
            </p>

            <p>
              7.3.2.1 Customer is solely responsible for reviewing Sourcer’s
              security processes and evaluating for itself whether the Service,
              the Security Measures, and Sourcer’s commitments under this
              Section 7 (Data Security) will meet Customer’s needs, including
              with respect to any security obligations of Customer under the
              European Data Protection Legislation or Non-European Data
              Protection Legislation, as applicable.
            </p>

            <p>
              7.3.2.2 Customer acknowledges and agrees that (taking into account
              the state of the art, the costs of implementation and the nature,
              scope, context and purposes of the processing of Customer Personal
              Data as well as the risks to individuals) the Security Measures
              implemented and maintained by Sourcer as set out in Section 7.1.1
              (Sourcer’s Security Measures) provide a level of security
              appropriate to the risk in respect of the Customer Data.
            </p>

            <p>
              7.4 <u>Reviews and Audits of Compliance</u>.
            </p>

            <p>
              7.4.1 <u>Customer’s Audit Rights</u>.
            </p>

            <ul
              style={{
                listStyle: 'none',
                listStylePosition: 'outside',
                marginLeft: '3em',
              }}>
              <li>
                7.4.1.1 If the European Data Protection Legislation applies to
                the processing of Customer Personal Data, Sourcer will allow
                Customer or an independent auditor appointed by Customer to
                conduct audits (including inspections) to verify Sourcer’s
                compliance with its obligations under this DPA in accordance
                with Section 7.4.2 (Additional Business Terms for Reviews and
                Audits). Sourcer will contribute to such audits as described in
                this Section 7.4 (Reviews and Audits of Compliance).
              </li>
              <li>
                7.4.1.2 If the Standard Contractual Clauses as described in
                Section 10 (International Data Transfers) are applicable to
                Sourcer’s processing of Customer Personal Data, without
                prejudice to any audit rights of a supervisory authority under
                such Standard Contract Clauses, the parties agree that Customer
                or an independent auditor appointed by Customer may conduct
                audits as described in Clauses 8.9(c) and (d) of the EU and the
                UK SCCs in accordance with Section 7.4.2 (Additional Business
                Terms for Reviews and Audits).
              </li>
            </ul>

            <p>
              7.4.2 <u>Additional Business Terms for Reviews and Audits</u>.
            </p>

            <ul
              style={{
                listStyle: 'none',
                listStylePosition: 'outside',
                marginLeft: '3em',
              }}>
              <li>
                7.4.2.1 If the European Data Protection Legislation applies to
                the processing of Customer Personal Data, Customer may exercise
                its right to audit Sourcer under Sections 7.4.1(a) or 7.4.1(b):
                (1) where there has been a Data Incident within the previous six
                (6) months or there is reasonable suspicion of a Data Incident
                within the previous six (6) months or (2) where Customer will
                pay all reasonable costs and expenses incurred by Sourcer in
                making itself available for an audit. Any third party who will
                be involved with or have access to the audit information must be
                mutually agreed to by Customer and Sourcer and must execute a
                written confidentiality agreement acceptable to Sourcer before
                conducting the audit.
              </li>
              <li>
                7.4.2.2 To request an audit under Section 7.4.1(a) or 7.4.1(b),
                Customer must submit a detailed audit plan to Sourcer’s Privacy
                Contact as described in Section 12 (Privacy Contact; Processing
                Records) at least thirty (30) days in advance of the proposed
                audit date, describing the proposed scope, duration, and start
                time of the audit. The scope may not exceed a review of
                Sourcer’s compliance with the Standard Contractual Clauses or
                its compliance with the European Data Protection Legislation, in
                each case with respect to the Customer Data. The audit must be
                conducted during regular business hours at the applicable
                facility, subject to Sourcer policies, and may not interfere
                with Sourcer business activities.
              </li>
              <li>
                7.4.2.3 Following receipt by Sourcer of a request for an audit
                under Section 7.4.1(a) or 7.4.1(b), Sourcer and Customer will
                discuss and agree in advance on: (i) the reasonable date(s) of
                and security and confidentiality controls applicable to any
                review of documentation; and (ii) the reasonable start date,
                scope and duration of and security and confidentiality controls
                applicable to any audit under Section 7.4.1(a) or 7.4.1(b).
              </li>
              <li>
                7.4.2.4 Customer will be responsible for any fees it incurs,
                including any fees charged by any auditor appointed by Customer
                to execute any such audit.
              </li>
              <li>
                7.4.2.5 Customer will provide Sourcer any audit reports
                generated in connection with any audit under this section,
                unless prohibited by law. Customer may use the audit reports
                only to meet its regulatory audit requirements and to confirm
                compliance with the requirements of the Standard Contractual
                Clauses or European Data Protection Legislation. The audit
                reports, and all information and records observed or otherwise
                collected in the course of the audit, are Confidential
                Information of Sourcer under the terms of the Agreement.
              </li>
              <li>
                7.4.2.6 Sourcer may object in writing to an auditor appointed by
                Customer if the auditor is, in Sourcer’s reasonable opinion, not
                suitably qualified or independent, a competitor of Sourcer, or
                otherwise unsuitable. Any such objection by Sourcer will require
                Customer to appoint another auditor or conduct the audit itself.
              </li>
              <li>
                7.4.2.7 Nothing in this DPA will require Sourcer either to
                disclose to Customer or its auditor, or to allow Customer or its
                auditor to access:
                <br />
                <br />
                <ul
                  style={{
                    listStyle: 'none',
                    listStylePosition: 'outside',
                    marginLeft: '3em',
                  }}>
                  <li>7.4.2.7.1 any data of any other customer of Sourcer;</li>
                  <li>
                    7.4.2.7.2 Sourcer’s internal accounting or financial
                    information;
                  </li>
                  <li>7.4.2.7.3 any trade secret of Sourcer;</li>
                  <li>
                    7.4.2.7.4 any information that, in Sourcer&apos;s reasonable
                    opinion, could: (A) compromise the security of Sourcer
                    systems or premises; or (B) cause Sourcer to breach its
                    obligations under applicable law or its security and/or
                    privacy obligations to Customer or any third party; or
                  </li>
                  <li>
                    7.4.2.7.5 any information that Customer or its third party
                    auditor seeks to access for any reason other than the good
                    faith fulfillment of Customer’s obligations under the
                    Standard Contractual Clauses or European Data Protection
                    Legislation.
                  </li>
                </ul>
              </li>
            </ul>

            <p>
              7.4.3 <u>No Modification of Standard Contractual Clauses</u>.
              Nothing in this Section 7.4 (Reviews and Audits of Compliance)
              varies or modifies any rights or obligations of Customer or
              Sourcer under any Standard Contractual Clauses entered into as
              described in Section 10 (International Data Transfers).
            </p>

            <h3>8. Impact Assessments and Consultations</h3>

            <p>
              Customer agrees that Sourcer will (taking into account the nature
              of the processing and the information available to Sourcer) assist
              Customer in ensuring compliance with any obligations of Customer
              in respect of data protection impact assessments and prior
              consultation, including if applicable Customer’s obligations
              pursuant to Articles 35 and 36 of the GDPR, by providing the
              information contained in the Agreement including this DPA.
            </p>

            <h3>9. Data Subject Rights; Data Export</h3>

            <p>
              9.1{' '}
              <u>Access; Rectification; Restricted Processing; Portability</u>.
              During the Term, Sourcer will, in a manner consistent with the
              functionality of the Service, enable Customer to access, rectify
              and restrict processing of Customer Data, including via the
              deletion functionality provided by Sourcer as described in Section
              6.1 (Deletion by Customer), and to export Customer Data.
            </p>

            <p>
              9.2 <u>Data Subject Requests</u>.
            </p>

            <p>
              9.2.1 <u>Customer’s Responsibility for Requests</u>. During the
              Term, if Sourcer receives any request from a data subject under
              European Data Protection Legislation in relation to Customer
              Personal Data, Sourcer will advise the data subject to submit
              their request to Customer, and Customer will be responsible for
              responding to any such request including, where necessary, by
              using the functionality of the Service.
            </p>

            <p>
              9.2.2 <u>Sourcer’s Data Subject Request Assistance</u>. Customer
              agrees that Sourcer will (taking into account the nature of the
              processing of Customer Personal Data) reasonably assist Customer
              in fulfilling an obligation to respond to requests by data
              subjects described in Section 9.2.1 (Customer’s Responsibility for
              Requests), including, if applicable, Customer’s obligation to
              respond to requests for exercising the data subject’s rights laid
              down in Chapter III of the GDPR, by complying with the commitments
              set out in Section 9.1 (Access; Rectification; Restricted
              Processing; Portability) and Section 9.2.1 (Customer’s
              Responsibility for Requests).
            </p>

            <h3>10. International Data Transfers</h3>

            <p>
              10.1 <u>Data Storage and Processing Facilities</u>. Sourcer may,
              subject to this Section 10 (International Data Transfers), store
              and process the relevant Customer Data anywhere Sourcer or its
              Subprocessors maintain facilities.
            </p>

            <p>
              10.2 <u>Data Transfers under the EU SCCs</u>. The EU SCCs are
              incorporated into this DPA and apply where the application of the
              EU SCCs, as between the parties, is required under applicable
              European Data Protection Legislation for the transfer of personal
              data. The EU SCCs shall be deemed completed as follows:
            </p>

            <ul
              style={{
                listStyle: 'none',
                listStylePosition: 'outside',
                marginLeft: '3em',
              }}>
              <li>
                10.2.1 Where Customer acts as a controller and Sourcer acts as
                Customer’s processor with respect to Customer Personal Data
                subject to the EU SCCs, Module 2 applies.
              </li>
              <li>
                10.2.2. Where Customer acts as a processor and Sourcer acts as
                Customer’s Subprocessor with respect to Customer Personal Data
                subject to the EU SCCs, Module 3 applies.
              </li>
              <li>
                10.2.3 Clause 7 (the optional docking clause) is not included.
              </li>
              <li>
                10.2.4 Under Clause 9 (Use of sub-processors), the parties
                select Option 2 (General written authorization).
              </li>
              <li>
                10.2.5 Under Clause 11 (Redress), the optional language will not
                apply.
              </li>
              <li>
                10.2.6 Under Clause 17 (Governing law), the parties choose
                Option 1 and select the law of Ireland.
              </li>
              <li>
                10.2.7 Under Clause 18 (Choice of forum and jurisdiction), the
                parties select the courts of Ireland.
              </li>
              <li>
                10.2.8 Annexes I, II, and III of the EU SCCs are set forth in
                Appendix 1 below.
              </li>
            </ul>

            <p>
              10.3 <u>Data Transfers under the IDTA</u>. When used as an
              addendum to the EU SCCs and the UK IDTA is otherwise required
              under applicable European Data Protection Law for the transfer of
              Customer Personal Data, the UK IDTA addendum shall incorporate the
              selections above and be deemed further completed as follows:
            </p>

            <ul
              style={{
                listStyle: 'none',
                listStylePosition: 'outside',
                marginLeft: '3em',
              }}>
              <li>
                10.3.1 Table 1: the parties’ details shall be the parties and
                their affiliates to the extent any of them is involved in such
                transfer, including those set forth in Appendix 1, and the Key
                Contact shall be the contacts set forth in Appendix 1.
              </li>
              <li>
                10.3.2 Table 2: The referenced Approved EU SCCs shall be the EU
                SCCs incorporated into this DPA.
              </li>
              <li>
                10.3.3 Table 3: Annex 1A, 1B, and II shall be set forth in
                Appendix 1.
              </li>
              <li>
                10.3.4 Table 4: Either party may end the EU SCCs as set out in
                Section 19 of the EU SCCs.
              </li>
            </ul>

            <p>
              10.4 <u>Data Transfers from Switzerland</u>. Where the EU SCCs are
              required under Swiss data protection law applicable to the
              transfer of Customer Personal Data, the following additional
              provisions will apply:
            </p>

            <ul
              style={{
                listStyle: 'none',
                listStylePosition: 'outside',
                marginLeft: '3em',
              }}>
              <li>
                10.4.1 References to the GDPR in the EU SCCs are to be
                understood as references to the Swiss Federal Act on Data
                Protection (“FADP”) insofar as the data transfers are subject
                exclusively to the FADP and not to the GDPR.
              </li>
              <li>
                10.4.2 The term “member state” in the EU SCCs shall not be
                interpreted in such a way as to exclude data subjects in
                Switzerland from the possibility of suing for their rights in
                their place of habitual residence (Switzerland) in accordance
                with Clause 18(c) of the EU SCCs.
              </li>
              <li>
                10.4.3 References to personal data in the EU SCCs also refer to
                data about identifiable legal entities until the entry into
                force of revisions to the FADP that eliminate this broader
                scope.
              </li>
              <li>
                10.4.4 Under Annex I(C) of the EU SCCs: where the transfer is
                subject exclusively to the FADP and not the GDPR, the
                supervisory authority is the Swiss Federal Data Protection and
                Information Commissioner, and where the transfer is subject to
                both the FADP and the GDPR, the supervisory authority is the
                Swiss Federal Data Protection and Information Commissioner
                insofar as the transfer is governed by the FADP, and the
                supervisory authority is as set forth in the EU SCCs insofar as
                the transfer is governed by the GDPR.
              </li>
            </ul>

            <h3>11. Subprocessors</h3>

            <p>
              11.1 <u>Consent to Subprocessor Engagement</u>. Customer
              specifically authorizes the engagement of Sourcer’s Affiliates as
              Subprocessors. In addition, Customer generally authorizes the
              engagement of any other third parties as Subprocessors (“Third
              Party Subprocessors”). If the Standard Contractual Clauses as
              described in Section 10 (International Data Transfers) are
              applicable to Sourcer’s processing of Customer Personal Data, the
              above authorizations will constitute Customer’s prior written
              consent to the subcontracting by Sourcer of the processing of
              Customer Personal Data if such consent is required under the
              Standard Contractual Clauses.
            </p>

            <p>
              11.2 <u>Information about Subprocessors</u>.
            </p>

            <p>
              11.2.1 Information about Subprocessors is available upon request
              by emailing privacyrequests@sourcer.com (as may be updated by
              Sourcer from time to time in accordance with this DPA).
              Subprocessor information will be provided only upon request and is
              the Confidential Information of Sourcer under this Agreement and
              must be treated with the level of confidentiality afforded to
              Confidential Information hereunder.
            </p>

            <p>
              11.3 <u>Requirements for Subprocessor Engagement</u>. When
              engaging any Subprocessor, Sourcer will:
            </p>

            <p>a. ensure via a written contract that:</p>

            <ul
              style={{
                listStyle: 'none',
                listStylePosition: 'outside',
                marginLeft: '3em',
              }}>
              <li>
                i. subcontracted to it, and does so in accordance with the
                Agreement (including this DPA) and any Standard Contractual
                Clauses entered into or Alternative Transfer Solution adopted by
                Sourcer as described in Section 10 (International Data
                Transfers); and
              </li>
              <li>
                ii. if the GDPR applies to the processing of Customer Personal
                Data, the data protection obligations set out in Article 28(3)
                of the GDPR, as described in this DPA, are imposed on the
                Subprocessor; and{' '}
              </li>
            </ul>

            <p>
              b. remain liable for all obligations subcontracted to, and all
              related acts and omissions of, the Subprocessor.
            </p>

            <p>
              11.4 <u>Opportunity to Object to Subprocessor Changes</u>.
            </p>

            <p>
              11.4.1 Sourcer may add or remove Subprocessors from time to time.
              Sourcer will inform Customer of new Subprocessors via a
              subscription mechanism described in the list of Subprocessors as
              described above. If Customer objects to a change, it will provide
              Sourcer with notice of its objection to gdpr-dsar@sourcer.com
              including reasonable detail supporting Customer’s concerns within
              sixty days of receiving notice of a change from Sourcer or, if
              Customer has not subscribed to receive such notice, within sixty
              days of Sourcer publishing the change. Sourcer will then use
              commercially reasonable efforts to review and respond to
              Customer’s objection within thirty days of receipt of Customer’s
              objection. If Sourcer does not respond to a Customer objection as
              described above, or cannot reasonably accommodate Customer’s
              objection, Customer may terminate the Agreement by providing
              written notice to Sourcer. This termination right is Customer’s
              sole and exclusive remedy if Customer objects to any new
              Subprocessor.
            </p>

            <h3>12. Privacy Contact; Processing Records</h3>

            <p>
              12.1 <u>Sourcer’s Privacy Contact</u>. Privacy inquiries related
              to this DPA can be submitted to privacyrequests@sourcer.com
              (and/or via such other means as Sourcer may provide from time to
              time).
            </p>

            <p>
              12.2 <u>Sourcer’s Processing Records</u>. Customer acknowledges
              that Sourcer is required under the GDPR to: (a) collect and
              maintain records of certain information, including the name and
              contact details of each processor and/or controller on behalf of
              which Sourcer is acting and, where applicable, of such processor’s
              or controller&apos;s local representative and data protection
              officer; and (b) make such information available to the
              supervisory authorities. Accordingly, if the GDPR applies to the
              processing of Customer Personal Data, Customer will, where
              requested, provide such information to Sourcer via the Service or
              other means provided by Sourcer, and will use the Service or such
              other means to ensure that all information provided is kept
              accurate and up-to-date.
            </p>

            <h3>13. Liability</h3>

            <p>
              13.1 <u>Liability Cap</u>. For clarity, the total combined
              liability of either party and its Affiliates towards the other
              party and its Affiliates under or in connection with the Agreement
              (such as under the DPA or the Standard Contractual Clauses) will
              be limited to the Agreed Liability Cap for the relevant party,
              subject to Section 13.2 (Liability Cap Exclusions).
            </p>

            <p>
              13.2 <u>Liability Cap Exclusions</u>. Nothing in Section 13.1
              (Liability Cap) will affect the remaining terms of the Agreement
              relating to liability (including any specific exclusions from any
              limitation of liability).
            </p>

            <h3>14. Miscellaneous</h3>

            <p>
              Notwithstanding anything to the contrary in the Agreement, where
              Sourcer, Inc. is not a party to the Agreement, Sourcer, Inc. will
              be a third-party beneficiary of Section 7.4 (Reviews and Audits of
              Compliance), Section 11.1 (Consent to Subprocessor Engagement) and
              Section 13 (Liability) of this DPA.
            </p>

            <br />
            <br />
            <h3>
              <center>
                Appendix 1:
                <br />
                Subject Matter and Details of the Data Processing
              </center>
            </h3>
            <br />

            <h4>Subject Matter</h4>

            <p>Sourcer’s provision of the Service to Customer.</p>

            <h4>Duration of the Processing</h4>

            <p>
              The Term plus the period from the expiry of the Term until
              deletion of all Customer Data by Sourcer in accordance with the
              DPA.
            </p>

            <h4>Nature and Purpose of the Processing</h4>

            <p>
              Sourcer will process Customer Personal Data for the purposes of
              providing the Service to Customer in accordance with the DPA.
            </p>

            <h4>Categories of Data</h4>

            <p>
              Data relating to End Users or other individuals provided to
              Sourcer via the Service, by (or at the direction of) Customer or
              by End Users. The open nature of the Service does not impose a
              technical restriction on the categories of data Customer may
              provide. The personal data transferred may include: name,
              username, password, email address, telephone and fax number, title
              and other business information, general information about interest
              in and use of Sourcer services; and demographic information.
            </p>

            <h4>Data Subjects</h4>

            <p>
              Data subjects include End Users and the individuals about whom
              data is provided to Sourcer via the Service by (or at the
              direction of) Customer or by End Users.
            </p>

            <br />
            <br />
            <h3>
              <center>
                Appendix 2:
                <br />
                Security Measures
              </center>
            </h3>
            <br />

            <p>
              Sourcer will implement and maintain the Security Measures set out
              in this Appendix 2. Sourcer may update or modify such Security
              Measures from time to time provided that such updates and
              modifications do not result in the degradation of the overall
              security of the Service. Sourcer will:
            </p>

            <ul
              style={{
                listStylePosition: 'outside',
                marginLeft: '3em',
              }}>
              <li>
                Conduct information security risk assessments at least annually
                and whenever there is a material change in the organization’s
                business or technology practices that may impact the privacy,
                confidentiality, security, integrity or availability of Customer
                Personal Data.
              </li>
              <li>
                Regularly and periodically train personnel who have access to
                Customer Personal Data or relevant Sourcer Systems.
              </li>
              <li>
                Maintain secure user authentication protocols, secure access
                control methods, and firewall protection for Sourcer Systems
                that Process Customer Personal Data.
              </li>
              <li>
                Maintain policies and procedures to detect, monitor, document
                and respond to actual or reasonably suspected Information
                Security Incidents.
              </li>
              <li>
                Implement and maintain tools that detect, prevent, remove and
                remedy malicious code designed to perform an unauthorized
                function on or permit unauthorized access to Sourcer Systems.
              </li>
              <li>Implement and maintain up-to-date firewalls.</li>
              <li>
                Implement and use cryptographic modules to protect Customer
                Personal Data in transit and, when commercially reasonable, at
                rest.
              </li>
              <li>
                Maintain reasonable restrictions on physical access to Customer
                Personal Data and relevant Sourcer Systems.
              </li>
            </ul>

            <br />
            <br />
            <h3>
              <center>
                Appendix 3:
                <br />
                Annex I of the EU SCCs
              </center>
            </h3>
            <br />

            <h4>A. LIST OF PARTIES</h4>

            <p>
              <strong>Data exporter(s):</strong>
            </p>

            <p>
              Name: <i>Customer</i>
            </p>

            <p>
              Activities relevant to the data transferred under these Clauses:{' '}
              <i>Obtaining the Services from Data Importer</i>
            </p>

            <p>
              Role (controller/processor):{' '}
              <i>Controller or Processor, as applicable</i>
            </p>

            <p>
              <strong>Data importer(s):</strong>
            </p>

            <p>
              Name: <i>Sourcer, Inc.</i>
            </p>

            <p>
              Address:{' '}
              <i>1111B S Governors Ave, Ste 20165, Dover, DE 19904 USA</i>
            </p>

            <p>
              Contact person’s name, position and contact details:{' '}
              <i>Privacy Counsel, legalnotices@sourcer.com</i>
            </p>

            <p>
              Activities relevant to the data transferred under these Clauses:{' '}
              <i>Providing the Services to Data Exporter</i>.{' '}
            </p>

            <p>
              Role (controller/processor): <i>Processor</i>
            </p>

            <h4>В. DESCRIPTION OF TRANSFER</h4>

            <p>
              <strong>
                Categories of data subjects whose personal data is transferred
              </strong>
            </p>

            <p>
              Data subjects include End Users and the individuals about whom
              data is provided to Sourcer via the Service by (or at the
              direction of) Customer or by End Users.
            </p>

            <p>
              <strong>Categories of personal data transferred</strong>
            </p>

            <p>
              Data relating to End Users or other individuals provided to
              Sourcer via the Service, by (or at the direction of) Customer or
              by End Users. The open nature of the Service does not impose a
              technical restriction on the categories of data Customer may
              provide. The personal data transferred may include: name,
              username, password, email address, telephone and fax number, title
              and other business information, general information about interest
              in and use of Sourcer services; and demographic information.
            </p>

            <p>
              <strong>
                Sensitive data transferred (if applicable) and applied
                restrictions or safeguards that fully take into consideration
                the nature of the data and the risks involved, such as for
                instance strict purpose limitation, access restrictions
                (including access only for staff having followed specialized
                training), keeping a record of access to the data, restrictions
                for onward transfers or additional security measures.
              </strong>
            </p>

            <p>None anticipated.</p>

            <p>
              <strong>
                The frequency of the transfer (e.g. whether the data is
                transferred on a one-off or continuous basis).
              </strong>
            </p>

            <p>
              Continuously, for the length of the Agreement between the parties.
            </p>

            <p>
              <strong>Nature of the processing</strong>
            </p>

            <p>
              Sourcer will process Customer Personal Data to provide the Service
              to Customer in accordance with the DPA.
            </p>

            <p>
              <strong>
                Purpose(s) of the data transfer and further processing
              </strong>
            </p>

            <p>
              Sourcer will process Customer Personal Data for the purposes of
              providing the Service to Customer in accordance with the DPA.
            </p>

            <p>
              <strong>
                The period for which the personal data will be retained, or, if
                that is not possible, the criteria used to determine that period
              </strong>
            </p>

            <p>
              The Term plus the period from the expiry of the Term until
              deletion of all Customer Data by Sourcer in accordance with the
              DPA.
            </p>

            <p>
              <strong>
                For transfers to (sub-) processors, also specify subject matter,
                nature and duration of the processing
              </strong>
            </p>

            <p>
              Sourcer’s subprocessors will process personal data to assist
              Sourcer in providing the Services pursuant to the Agreement, for
              as long as needed for Sourcer to provide the Services.
            </p>

            <h4>C. COMPETENT SUPERVISORY AUTHORITY</h4>

            <p>The Irish Data Protection Commission.</p>

            <br />
            <br />
            <h3>
              <center>
                Annex II of the EU SCCs
                <br />
                TECHNICAL AND ORGANIZATIONAL MEASURES INCLUDING TECHNICAL AND
                ORGANIZATIONAL MEASURES TO ENSURE THE SECURITY OF THE DATA
              </center>
            </h3>
            <br />

            <p>See Appendix 2 to the DPA.</p>
          </div>
        </div>
      </article>
    </div>
  );
};

export default Global;
