import AppCard from '@wieldy/components/AppCard';
// import AppTag from '@wieldy/components/AppTag/AppTag';
import {Button, List, notification} from 'antd';
import * as noteService from 'app/services/noteService';
import {getFullName} from 'app/utils/helpers/DataFormatHelper';
import PropTypes from 'prop-types';
import React from 'react';
import {useSelector} from 'react-redux';

import NoteForm from './NoteForm';
import NoteItem from './NoteItem';

const Notes = ({
  entity,
  primaryKey,
  primaryId,
  title = 'Internal Notes',
  notesResponse,
  notesTotal,
}) => {
  const {user: authUser} = useSelector(({user}) => user);

  const mutation = noteService.useCreateNote({entity, primaryKey, primaryId});

  const {isLoading, data, hasNextPage, fetchNextPage, isFetchingNextPage} =
    notesResponse;

  const handleOnCreateNote = React.useCallback(
    (formdata, form) => {
      if (formdata.message) {
        mutation.mutate(formdata.message, {
          onSuccess: () => {
            form.resetFields();
            notification.success({message: 'Note created successfully'});
          },
        });
      }
    },
    [mutation],
  );

  return (
    <AppCard
      id={'notes-card'}
      title={
        <React.Fragment>
          {title}
          {/*{notesTotal > 0 && <AppTag className={'ml-1'}>{notesTotal}</AppTag>}*/}
        </React.Fragment>
      }
      headStyle={{borderBottom: 0, paddingTop: 8}}
      bodyStyle={{paddingTop: 8}}>
      {!isLoading && (
        <NoteForm onSubmit={handleOnCreateNote}>
          <Button type='primary' loading={mutation.isLoading} htmlType='submit'>
            Save Note
          </Button>
        </NoteForm>
      )}

      {notesTotal > 0 && (
        <List
          loading={isLoading}
          className='mb-8'
          itemLayout='vertical'
          dataSource={data?.pages ? data.pages.reverse() : []}
          renderItem={(notes) =>
            notes?.items.map((note) => (
              <NoteItem
                key={note.id}
                note={note}
                editable={note?.user?.id === authUser?.id}
                title={getFullName(note?.user)}
                entity={entity}
                primaryId={primaryId}
              />
            ))
          }
          loadMore={
            hasNextPage && (
              <div
                style={{
                  textAlign: 'center',
                  marginTop: 12,
                  height: 32,
                  lineHeight: '32px',
                }}>
                <Button onClick={() => fetchNextPage()}>
                  {isFetchingNextPage ? 'Loading...' : 'Load more'}
                </Button>
              </div>
            )
          }
        />
      )}
    </AppCard>
  );
};

Notes.propTypes = {
  entity: PropTypes.string,
  primaryKey: PropTypes.string,
  primaryId: PropTypes.string,
  title: PropTypes.string,
  notesResponse: PropTypes.object,
  notesTotal: PropTypes.number,
};

export default React.memo(Notes);
