import AppCard from '@wieldy/components/AppCard';
import {AppFormActions, AppFormBody} from '@wieldy/components/AppFormWrapper';
import AppFormWrapper from '@wieldy/components/AppFormWrapper/AppFormWrapper';
import {getRequiredMessage} from '@wieldy/utils/helpers/MessageHelper';
import {Button, Form, Select} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

const EngagementStepForm = ({onContinue, onChange, options}) => {
  const onFormSubmit = () => {
    onContinue();
  };
  return (
    <AppCard
      title='Engagement'
      headStyle={{border: 0}}
      bodyStyle={{paddingTop: 0}}>
      <AppFormWrapper>
        <Form layout='vertical' onFinish={onFormSubmit} autoComplete='off'>
          <AppFormBody style={{maxWidth: 420}}>
            <Form.Item
              name='engagement'
              label='Engagement'
              rules={[
                {
                  required: true,
                  message: getRequiredMessage('Engagement'),
                },
              ]}>
              <Select
                onChange={onChange}
                placeholder='Select Engagement'
                style={{width: '100%'}}
                options={options}
              />
            </Form.Item>
          </AppFormBody>
          <AppFormActions>
            <Button type='primary' htmlType='submit' className='mt-1'>
              Continue
            </Button>
          </AppFormActions>
        </Form>
      </AppFormWrapper>
    </AppCard>
  );
};

EngagementStepForm.propTypes = {
  onContinue: PropTypes.func,
  options: PropTypes.array,
  onChange: PropTypes.func,
};

export default EngagementStepForm;
