import ClientModal from 'app/modals/ClientModal';
import Search from 'app/pages/common/Search/Search';
import {Clients, ViewClient} from 'app/pages/main/clients';
import {ClientCard} from 'app/shared/client';

export const supplier_ClientRoutes = [
  {
    path: '/clients',
    element: <Clients />,
  },
  {
    path: '/clients/:category',
    element: <Clients />,
  },
  {
    path: '/clients/search/:q?',
    element: (
      <Search
        entity={'clients'}
        card={(item, loading) => (
          <ClientCard
            client={new ClientModal(item)}
            loading={loading}
            showBookmark={false}
          />
        )}
      />
    ),
  },
  {
    path: '/clients/:id/:tab',
    element: <ViewClient />,
  },
];
