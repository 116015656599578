import axios from 'app/config/axios';
import {useMutation} from 'react-query';

export const useGetSkillTags = () => {
  const params = {
    limit: 10,
    page: 0,
    sort: ['+name'],
  };
  return useMutation(async (keyword) => {
    const {data} = await axios.get('skill_tags', {
      params: {...params, q: keyword},
    });
    return data?.data ? data.data : [];
  });
};
