import AppDynamicTabs from '@wieldy/components/AppTabs/AppDynamicTabs';
import AppPageContainer from '@wieldy/components/pageComponents/AppPageContainer';
import AppPageHeader from '@wieldy/components/pageComponents/AppPageHeader';
import {CATEGORIES} from 'app/constants/index.js';
import All from 'app/pages/main/suppliers/Suppliers/MainContent/All.js';
import Browse from 'app/pages/main/suppliers/Suppliers/MainContent/Browse.js';
import PageQueryProvider from 'app/providers/PageQueryProvider/PageQueryProviderNew';
import {filterConfig} from 'app/shared/supplier/FilterSuppliers';
import React, {useMemo} from 'react';
import {useParams} from 'react-router-dom';

import {pagePrimaryTabs, pageQueries, pageSecondaryTabs} from './constants';

const Suppliers = () => {
  const {category} = useParams();

  const isAll = useMemo(() => category === CATEGORIES.ALL, [category]);

  const activeKey = React.useMemo(() => {
    return category ?? CATEGORIES.ALL;
  }, [category]);

  const currentPageParams = pageQueries[category];
  const {title} = currentPageParams;

  return (
    <PageQueryProvider
      category={activeKey}
      pageParams={pageQueries}
      filterConfig={filterConfig}>
      <AppPageContainer
        header={
          <AppPageHeader
            className={'has-tabs-secondary'}
            title={title}
            category='Suppliers'
            tabs={
              <AppDynamicTabs
                tabsData={pagePrimaryTabs}
                activeKey={activeKey}
              />
            }
            extra={
              <AppDynamicTabs
                className='tabs-secondary'
                tabsData={pageSecondaryTabs}
                activeKey={activeKey}
              />
            }
          />
        }>
        {isAll ? <All /> : <Browse />}
      </AppPageContainer>
    </PageQueryProvider>
  );
};

export default Suppliers;
