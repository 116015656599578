import AppLoader from '@wieldy/components/AppLoader';
import AppDynamicTabs from '@wieldy/components/AppTabs/AppDynamicTabs';
import AppPageContainer from '@wieldy/components/pageComponents/AppPageContainer';
import AppPageHeader from '@wieldy/components/pageComponents/AppPageHeader';
import {Button, Tooltip} from 'antd';
import {useNotes} from 'app/hooks/useNotes';
import ClientModal from 'app/modals/ClientModal';
import {
  useAddBookmarkToClient,
  useGetClient,
} from 'app/services/supplierService';
import {ViewClientHeader} from 'app/shared/client';
import clsx from 'clsx';
import React, {useCallback, useState} from 'react';
import {RiStarLine, RiStarSFill} from 'react-icons/ri';
import {useParams} from 'react-router-dom';

import NotesBtnIcon from '../../../../../assets/icons/NotesBtn';
import {getPrimaryTabs, viewClientQuery} from './constants';
import MainContent from './MainContent';

const ViewClient = () => {
  const {id} = useParams();
  const {isLoading, data} = useGetClient(id, viewClientQuery);

  const mutation = useAddBookmarkToClient(true);
  const client = new ClientModal(data);
  const [tabView, setTabView] = useState({
    isInquiries: false,
    isSubmissions: false,
    isEngagements: false,
    isRequisition: false,
  });

  const {isInquiries, isSubmissions, isEngagements, isRequisition} = tabView;

  const handleSetTabView = useCallback((type, value) => {
    setTabView((prev) => ({...prev, [type]: value}));
  }, []);

  const handleOnBookmark = React.useCallback(() => {
    mutation.mutate({
      id: client?.id,
      isBookmarked: client?.bookmarked,
    });
  }, [mutation, client?.id, client?.bookmarked]);

  const {
    handleShowNotes,
    isNotes,
    isShowNotes,
    total,
    response: notesResponse,
  } = useNotes('client_notes', 'client', id);

  if (isLoading) {
    return <AppLoader />;
  }

  return (
    <AppPageContainer
      header={
        <AppPageHeader
          backHeader
          backHeaderActions={
            <Button
              type='text'
              shape='circle'
              loading={mutation.isLoading}
              onClick={handleOnBookmark}
              className={clsx({bookmarked: client?.bookmarked})}
              icon={
                client?.bookmarked ? (
                  <RiStarSFill className='text-xxl' />
                ) : (
                  <RiStarLine className='text-xxl' />
                )
              }
            />
          }
          header='inner'
          category='Client'
          tabs={
            <AppDynamicTabs
              tabsData={getPrimaryTabs(
                isRequisition,
                isInquiries,
                isSubmissions,
                isEngagements,
                isNotes,
              )}
              scroll={true}
            />
          }
          extra={
            isNotes || (
              <Tooltip
                overlayStyle={{fontSize: '12px'}}
                title={'Add Internal Note'}>
                <Button
                  type='primary'
                  shape='circle'
                  onClick={handleShowNotes}
                  icon={<NotesBtnIcon />}
                />
              </Tooltip>
            )
          }>
          <ViewClientHeader client={client} />
        </AppPageHeader>
      }>
      <MainContent
        client={client}
        handleSetTabView={handleSetTabView}
        tabView={tabView}
        isShowNotes={isShowNotes}
        notesResponse={notesResponse}
        notesTotal={total}
      />
    </AppPageContainer>
  );
};

export default ViewClient;
