import '@wieldy/styles/components/date-picker.less';

import {DatePicker} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

const AppDatePicker = ({
  disabledDate,
  format = 'MM-DD-YYYY',
  placeholder = 'MM-DD-YYYY',
  ...props
}) => {
  return (
    <DatePicker
      disabledDate={disabledDate}
      format={format}
      placeholder={placeholder}
      autoComplete='off'
      {...props}
    />
  );
};

AppDatePicker.propTypes = {
  onChange: PropTypes.func,
  format: PropTypes.string,
  placeholder: PropTypes.string,
  disabledDate: PropTypes.func,
};

export default AppDatePicker;
