import AppCard from '@wieldy/components/AppCard';
import AppPageContainer from '@wieldy/components/pageComponents/AppPageContainer';
import {Button, Form} from 'antd';
import React from 'react';

import PhoneInput from './PhoneInput';

const Demo = () => {
  return (
    <AppPageContainer>
      <AppCard
        title='General Settings'
        headStyle={{
          borderBottom: 'none',
        }}
        bodyStyle={{paddingTop: 8}}>
        <Form onFinish={() => {}}>
          <Form.Item
            name={'phone_number'}
            rules={[
              {
                required: true,
              },
            ]}>
            <PhoneInput placeholder={'Test'} />
          </Form.Item>
          <Button htmlType={'submit'} type={'primary'}>
            Save
          </Button>
        </Form>
      </AppCard>
    </AppPageContainer>
  );
};

export default Demo;
