import {Descriptions, Typography} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import {useNavigate} from 'react-router-dom';

const Candidate = ({applicant, candidateId}) => {
  const navigate = useNavigate();
  const onLink = () => {
    navigate(`/candidates/${candidateId}/view-candidate`);
  };
  return (
    <React.Fragment>
      <Typography.Title level={4} className={'mb-4'}>
        Candidate
      </Typography.Title>
      <Descriptions
        colon={false}
        labelStyle={{width: '50%'}}
        className='description-root mb-4'
        column={{xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1}}>
        <Descriptions.Item label='Candidate Profile'>
          <Typography.Link onClick={onLink} className={'font-semibold'}>
            {applicant.name}
          </Typography.Link>
        </Descriptions.Item>
        <Descriptions.Item label='Title'>{applicant.title}</Descriptions.Item>
        <Descriptions.Item label='Location'>
          {applicant.location}
        </Descriptions.Item>
        <Descriptions.Item label='Local Time Zone'>
          {applicant.timezone}
        </Descriptions.Item>
      </Descriptions>
    </React.Fragment>
  );
};

Candidate.propTypes = {
  applicant: PropTypes.object,
  candidateId: PropTypes.string,
};

export default Candidate;
