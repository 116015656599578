import AppLoader from '@wieldy/components/AppLoader';
import AppPageContainer from '@wieldy/components/pageComponents/AppPageContainer';
import AppPageHeader from '@wieldy/components/pageComponents/AppPageHeader';
import {AppStepsWrapper} from 'app/providers/AppStepProvider';
import {Preview} from 'app/shared/requisitions/RequisitionSteps';
import {
  resetResource,
  setInitial,
} from 'app/store/features/resource/resourceSlice';
import {getFullName} from 'app/utils/helpers/DataFormatHelper';
import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';

import {useGetApplicant} from '../../../../services/applicantService';
import ApplicantStepper from '../ApplicantStepper';

const EditApplicant = () => {
  const {id} = useParams();
  const dispatch = useDispatch();
  const {isLoading, data} = useGetApplicant(id, {include: ['skill_tags']});

  useEffect(() => {
    if (data) {
      dispatch(setInitial(data));
    }
  }, [dispatch, data]);

  useEffect(() => {
    return () => {
      dispatch(resetResource());
    };
  }, [dispatch]);

  if (isLoading) {
    return <AppLoader />;
  }

  return (
    <AppStepsWrapper
      isLoading={isLoading}
      preview={<Preview />}
      isUpdated={true}>
      <AppPageContainer
        header={
          <AppPageHeader
            backHeader
            category={'Applicant'}
            title={getFullName(data, false)}
          />
        }>
        <ApplicantStepper />
      </AppPageContainer>
    </AppStepsWrapper>
  );
};

export default EditApplicant;
