import AppLoader from '@wieldy/components/AppLoader';
import AppPageContainer from '@wieldy/components/pageComponents/AppPageContainer';
import AppPageHeader from '@wieldy/components/pageComponents/AppPageHeader';
import ClientReviewModal from 'app/modals/ClientReviewModal';
import {useGetClient} from 'app/services/supplierService';
import React from 'react';
import {useParams} from 'react-router-dom';

import MainContent from './MainContent';

const AddEditClientReview = () => {
  const {id} = useParams();
  const {isFetching, data} = useGetClient(id, {
    include: ['client_review', 'review_author'],
  });

  if (isFetching) {
    return <AppLoader />;
  }

  const clientReview = new ClientReviewModal(data);

  return (
    <AppPageContainer
      header={
        <AppPageHeader
          backHeader
          category='Administration / Reviews'
          title={clientReview?.name}
        />
      }>
      <MainContent review={clientReview} />
    </AppPageContainer>
  );
};

export default AddEditClientReview;
