import React from 'react';

import useStep from '../../../../../../../hooks/useStep';
import SummaryDetails from './SummaryDetails';
import SummaryForm from './SummaryForm';

const SummaryStep = () => {
  const {nextStep, setStepData, stepData, currentStep} = useStep();
  return (
    <>
      {currentStep > 1 && stepData ? (
        <SummaryDetails />
      ) : (
        currentStep === 1 && (
          <SummaryForm onContinue={nextStep} onSave={setStepData} />
        )
      )}
    </>
  );
};

export default SummaryStep;
