import AppLoader from '@wieldy/components/AppLoader';
import useAppRoutes from '@wieldy/hooks/useAppRoutes';
import {useAccountType} from 'app/hooks/useAccountType';
import {useFirstRender} from 'app/hooks/useFirstRender';
import {useRole} from 'app/hooks/useRole';
import routes from 'app/routes';
import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';

const AppRoutes = () => {
  const {isSupplier, isClient, isMarketplace} = useAccountType();
  const {isResource} = useRole();
  const [userRoutes, setUserRoutes] = useState([
    {path: '*', element: <AppLoader />},
  ]);
  const location = useLocation();
  const navigate = useNavigate();

  const isFirstRender = useFirstRender();

  useEffect(() => {
    if (isResource) {
      setUserRoutes(routes.resource);
    } else if (isClient) {
      setUserRoutes(routes.client);
    } else if (isSupplier && !isResource) {
      setUserRoutes(routes.supplier);
    } else if (isMarketplace && !isSupplier && !isClient) {
      setUserRoutes(routes.admin);
    }
  }, [isClient, isMarketplace, isResource, isSupplier]);

  useEffect(() => {
    const unixPaths = [
      ...new Set(
        userRoutes.map(({path}) => {
          if (path.startsWith('/')) {
            return path.slice(1).split('/')[0];
          }
          return path.split('/')[0];
        }),
      ),
    ];
    const path = location.pathname.slice(1).split('/')[0];

    if (!unixPaths.includes(path) && !isFirstRender) {
      if ((isSupplier || isClient) && !isResource) {
        navigate('/dashboard', {replace: true});
      }
      if (isResource) {
        navigate('/timecards', {replace: true});
      }
      if (isMarketplace) {
        navigate('/', {replace: true});
      }
    }
  }, [
    isClient,
    isFirstRender,
    isMarketplace,
    isResource,
    isSupplier,
    location.pathname,
    navigate,
    userRoutes,
  ]);

  const appRoutes = useAppRoutes(userRoutes, false);

  return <React.Fragment>{appRoutes}</React.Fragment>;
};

export default AppRoutes;
