import AppGridView from '@wieldy/components/AppGridView';
import AppLoader from '@wieldy/components/AppLoader';
import AppPageSection from '@wieldy/components/pageComponents/AppPageSection';
import {Empty} from 'antd';
import usePageQuery from 'app/hooks/usePageQuery';
import PlacementsModal from 'app/modals/PlacementsModal';
import {useGetAllPlacements} from 'app/services/placementService';
import ViewAllButton from 'app/shared/buttons/ViewAllButton';
import PlacementCard from 'app/shared/cards/PlacementCard';
import PropTypes from 'prop-types';
import React from 'react';

import {useAccountType} from '../../../../hooks/useAccountType';
import {statuses, STATUSES_TEXT} from '../data';

const All = ({userQuery}) => {
  const {currentPageParams} = usePageQuery();
  const {isSupplier} = useAccountType();
  const {queryParams} = currentPageParams;
  const {data: allData, isLoading} = useGetAllPlacements(
    {
      include: [...queryParams.include],
      expand: [...queryParams.expand, 'candidate'],
      ...userQuery,
    },
    statuses,
  );

  if (isLoading) {
    return <AppLoader />;
  }

  if (allData?.every(({placements}) => !placements.length)) {
    return <Empty description='No Placements' />;
  }

  return (
    !!allData?.length &&
    allData.map(
      ({placements, total_count, status}) =>
        !!placements.length && (
          <AppPageSection
            key={status}
            title={`${STATUSES_TEXT[status]} Placements`}
            count={total_count}
            extra={
              total_count > 6 && (
                <ViewAllButton category={status} path='placements' />
              )
            }>
            <AppGridView
              isAllPages={true}
              data={placements.slice(0, 6)}
              renderItem={(placement) => (
                <PlacementCard
                  key={placement.id}
                  placement={new PlacementsModal(placement, isSupplier)}
                />
              )}
            />
          </AppPageSection>
        ),
    )
  );
};

All.propTypes = {
  userQuery: PropTypes.object,
};

All.defaultProps = {
  userQuery: {},
};

export default All;
