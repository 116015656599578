import {CATEGORIES} from 'app/constants';
import {RiBookmark3Fill, RiSearchLine} from 'react-icons/ri';

export const statuses = [
  CATEGORIES.POTENTIAL,
  CATEGORIES.PENDING,
  CATEGORIES.ACTIVE,
  CATEGORIES.FORMER,
];
export const primaryTabs = [
  {key: 'all', title: 'All', route: '/clients/all'},
  {
    key: 'potential',
    title: 'Potential',
    route: '/clients/potential',
  },
  {key: 'pending', title: 'Pending', route: '/clients/pending'},
  {key: 'active', title: 'Active', route: '/clients/active'},
  {key: 'former', title: 'Former', route: '/clients/former'},
];

export const secondaryTabs = [
  {
    key: 'bookmarked',
    title: 'Bookmarked',
    icon: <RiBookmark3Fill />,
    route: '/clients/bookmarked',
  },
  {
    key: 'browse',
    title: 'Browse Clients',
    icon: <RiSearchLine />,
    route: '/clients/browse',
  },
];

export const clientQueryParams = {
  include: [
    'bookmarked',
    'bookmarked_on',
    'relation_status',
    'engaged_resources',
    'open_requisitions',
    'active_suppliers',
    'inquiries',
    'submissions',
    'engagements',
    'total_count',
  ],
};

export const pageQueries = {
  all: {
    queryParams: {},
    title: 'All Clients',
  },
  potential: {
    queryParams: {
      ...clientQueryParams,
      relation_status: 'potential',
    },
    title: 'Potential Clients',
    rowTitle: 'Potential Clients',
    label: 'Status changed',
    empty: 'No Potential Clients',
  },
  pending: {
    queryParams: {
      ...clientQueryParams,
      relation_status: 'pending',
    },
    title: 'Pending Clients',
    rowTitle: 'Pending Clients',
    label: 'Status changed',
    empty: 'No Pending Clients',
  },
  active: {
    queryParams: {
      ...clientQueryParams,
      relation_status: 'active',
    },
    title: 'Active Clients',
    rowTitle: 'Active Clients',
    label: 'Status changed',
    empty: 'No Active Clients',
  },
  former: {
    queryParams: {
      ...clientQueryParams,
      relation_status: 'former',
    },
    title: 'Former Clients',
    rowTitle: 'Former Clients',
    label: 'Status changed',
    empty: 'No Former Clients',
  },
  bookmarked: {
    queryParams: {
      ...clientQueryParams,
      bookmarked: true,
    },
    title: 'Bookmarked Clients',
    rowTitle: 'Bookmarked Clients',
    label: 'Bookmarked',
    empty: 'No Bookmarked Clients',
  },
  browse: {
    queryParams: {
      ...clientQueryParams,
    },
    title: 'Browse Clients',
    rowTitle: 'Clients',
    label: 'Created',
    empty: 'No Clients',
  },
};
