import {Form} from 'antd';
import cn from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import {Controller, useFormContext} from 'react-hook-form';

import CompanyMemberDropdown from '../../../app/shared/components/dropdowns/CompanyMemberDropdown';

const CompanyMemberController = ({
  label,
  name,
  classes,
  options,
  isRequired,
  ...props
}) => {
  const {control} = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({field, fieldState}) => {
        return (
          <Form.Item
            required={isRequired}
            label={label}
            validateStatus={fieldState.error ? 'error' : ''}
            help={fieldState.error ? fieldState.error.message : ''}>
            <CompanyMemberDropdown
              {...field}
              status={fieldState.error && 'error'}
              className={cn('min-h-10', classes?.select)}
              {...props}
              options={options}
            />
          </Form.Item>
        );
      }}
    />
  );
};

CompanyMemberController.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  isRequired: PropTypes.bool,
  options: PropTypes.array,
  classes: PropTypes.object,
};

export default CompanyMemberController;
