export default {
  utc: 'Universal Coordinated Time (UTC)',
  ect: 'European Central Time (UTC+1:00)',
  eet: 'Eastern European Time (UTC+2:00)',
  art: '(Arabic) Egypt Standard Time (UTC+2:00)',
  eat: 'Eastern African Time (UTC+3:00)',
  met: 'Middle East Time (UTC+3:30)',
  net: 'Near East Time (UTC+4:00)',
  plt: 'Pakistan Lahore Time (UTC+5:00)',
  ist: 'India Standard Time (UTC+5:30)',
  bst: 'Bangladesh Standard Time (UTC+6:00)',
  vst: 'Vietnam Standard Time (UTC+7:00)',
  ctt: 'China Taiwan Time (UTC+8:00)',
  jst: 'Japan Standard Time (UTC+9:00)',
  act: 'Australia Central Time (UTC+9:30)',
  aet: 'Australia Eastern Time (UTC+10:00)',
  sst: 'Solomon Standard Time (UTC+11:00)',
  nst: 'New Zealand Standard Time (UTC+12:00)',
  mit: 'Midway Islands Time (UTC-11:00)',
  hst: 'Hawaii Standard Time (UTC-10:00)',
  ast: 'Alaska Standard Time (UTC-9:00)',
  pst: 'Phoenix Standard Time (UTC-7:00)',
  pnt: 'Pacific Standard Time (UTC-8:00)',
  mst: 'Mountain Standard Time (UTC-7:00)',
  cst: 'Central Standard Time (UTC-6:00)',
  est: 'Eastern Standard Time (UTC-5:00)',
  prt: 'Puerto Rico and US Virgin Islands Time (UTC-4:00)',
  cnt: 'Canada Newfoundland Time (UTC-3:30)',
  agt: 'Argentina Standard Time (UTC-3:00)',
  bet: 'Brazil Eastern Time (UTC-3:00)',
  cat: 'Central African Time (UTC-1:00)',
};
