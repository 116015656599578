import {FaRegHandshake} from 'react-icons/fa';
import {
  RiBriefcaseFill,
  RiCalendarTodoFill,
  RiCoinLine,
  RiCoinsLine,
  RiFileTransferLine,
  RiMapPinLine,
  RiQuestionnaireLine,
  RiTeamLine,
  RiUserLine,
} from 'react-icons/ri';

const ICONS_LIST = {
  timecard: RiCalendarTodoFill,
  expense: RiCoinsLine,
  submission: RiFileTransferLine,
  inquiry: RiQuestionnaireLine,
  engagement: FaRegHandshake,
  user: RiMapPinLine,
  resource: RiTeamLine,
  requisition: RiBriefcaseFill,
  invoice: RiCoinLine,
  profile: RiUserLine,
};

const getNotificationData = (entity, entity_id = '') => {
  switch (entity) {
    case 'user':
      if (!entity_id) {
        return {
          path: `/administration/users`,
          buttonName: 'View users',
        };
      }
      return {
        path: `/administration/users/${entity_id}/view-user`,
        buttonName: 'View user',
      };
    case 'requisition':
      if (!entity_id) {
        return {
          path: `/requisitions/all`,
          buttonName: 'View requisitions',
        };
      }
      return {
        path: `/requisitions/${entity_id}/view-requisition`,
        buttonName: `View requisition`,
      };
    case 'invoice':
      if (!entity_id) {
        return {
          path: `/financials`,
          buttonName: 'View invoices',
        };
      }
      return {
        path: `/financials/${entity_id}/view-invoice`,
        buttonName: `View invoice`,
      };
    case 'inquiry':
      if (!entity_id) {
        return {
          path: `/inquiries`,
          buttonName: 'View inquiries',
        };
      }
      return {
        path: `/inquiries/${entity_id}/view-inquiry`,
        buttonName: `View inquiry`,
      };
    default:
      if (!entity_id) {
        return {
          path: `/${entity}s`,
          buttonName: `View ${entity}s`,
        };
      }
      return {
        path: `/${entity}s/${entity_id}/view-${entity}`,
        buttonName: `View ${entity}`,
      };
  }
};

export {getNotificationData, ICONS_LIST};
