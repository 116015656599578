import {Button, List, Typography} from 'antd';
import {formatUnixDate, getFullName} from 'app/utils/helpers/DataFormatHelper';
import PropTypes from 'prop-types';
import React, {useCallback, useState} from 'react';
import {RiDeleteBin6Fill, RiEditFill} from 'react-icons/ri';

import AddNoteForm from '../../UserNotes/AddNoteForm';

const NoteCard = ({note, editable, onDelete, onUpdate, pageIndex}) => {
  const [isEdit, setIsEdit] = useState(false);

  const handleOnUpdate = useCallback(
    (formData) => {
      if (onUpdate) {
        onUpdate(formData, note, pageIndex);
      }
      setIsEdit(false);
    },
    [note, onUpdate, pageIndex],
  );

  return (
    <List.Item className={'app-border-box px-6 mb-4'}>
      <List.Item.Meta
        description={
          <div className={'d-flex align-items-start'}>
            <div className={'flex-grow-1'}>
              <Typography.Title level={4} className={'mb-0'}>
                {getFullName(note?.user)}
              </Typography.Title>
              <Typography.Paragraph type='secondary' className={'text-xs mb-0'}>
                {formatUnixDate(note.created_at, 'MMM DD, YYYY hh:MM:ss')}
              </Typography.Paragraph>
            </div>

            {editable && (
              <div className={'ml-2'}>
                <Button
                  type='text'
                  shape='circle'
                  icon={<RiEditFill className={'text-lg text-secondary'} />}
                  onClick={() => setIsEdit(true)}
                />
                <Button
                  type='text'
                  shape='circle'
                  icon={
                    <RiDeleteBin6Fill className={'text-lg text-secondary'} />
                  }
                  onClick={() => onDelete(note, pageIndex)}
                />
              </div>
            )}
          </div>
        }
      />
      {!isEdit && <Typography.Paragraph>{note.text}</Typography.Paragraph>}
      {isEdit && (
        <AddNoteForm
          btnText={'Update'}
          onCancel={() => setIsEdit(false)}
          message={note?.text}
          onSubmit={handleOnUpdate}
        />
      )}
    </List.Item>
  );
};

NoteCard.propTypes = {
  note: PropTypes.object,
  editable: PropTypes.bool,
  onDelete: PropTypes.func,
  onUpdate: PropTypes.func,
  pageIndex: PropTypes.number,
};

export default NoteCard;
