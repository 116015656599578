import {Badge} from 'antd';
import React, {useEffect, useState} from 'react';
import {MdOutlineMail} from 'react-icons/md';
import {useChatContext} from 'stream-chat-react';

import {useUser} from '../../../../store/features/user/userSlice';

const Messages = () => {
  const {client} = useChatContext();
  const {user} = useUser();
  const [isUnreadMessage, setIsUnreadMessage] = useState(false);

  useEffect(() => {
    if (!client || !user) return;

    const setUnreadMessageFlag = (event) => {
      if (
        event.type === 'message.new' &&
        event.total_unread_count === 0 &&
        event.user &&
        event.user.id !== user.id
      ) {
        setIsUnreadMessage(true);
        return;
      }

      if ('total_unread_count' in event) {
        setIsUnreadMessage(!!event.total_unread_count);
      }
    };

    client.on(setUnreadMessageFlag);

    return () => {
      client.off(setUnreadMessageFlag);
    };
  }, [client, user]);

  return (
    <Badge dot={isUnreadMessage} status='warning'>
      <MdOutlineMail className={'cursor-pointer text-xxl'} />
    </Badge>
  );
};

export default React.memo(Messages);
