import AppCard from '@wieldy/components/AppCard';
import AppTable from '@wieldy/components/AppTable';
import {Empty} from 'antd';
import React from 'react';
import {useSelector} from 'react-redux';

import {getColumns} from './columns';

const SkillsDetails = () => {
  const {skills: generalSkills} = useSelector(({resource}) => resource);
  const existSkills = generalSkills.filter((element) => element.tag && element);

  const skills = existSkills.map(
    ({years_experience, expertise_level, last_used, id, tag, tempId}) => ({
      years_experience,
      expertise_level,
      last_used,
      id,
      tempId,
      name: tag.name,
    }),
  );

  return (
    <AppCard style={{maxWidth: 990}}>
      <AppTable
        locale={{
          emptyText: <Empty description='No Data' />,
        }}
        scroll={{x: 'scroll'}}
        className={'column-valign-top'}
        columns={getColumns()}
        dataSource={skills}
        pagination={false}
      />
    </AppCard>
  );
};

export default SkillsDetails;
