import {Typography} from 'antd';
import {getUserName} from 'app/utils/helpers/ConvertHID';

export const getColumns = (navigate) => {
  return [
    {
      title: 'Resource Name',
      dataIndex: 'resourceName',
      key: 'expand_resource.first_name',
      showSorterTooltip: false,
      render: (name, item) => (
        <Typography.Link
          className='font-semibold'
          onClick={() =>
            navigate(`/resources/${item?.resourceId}/view-resource`)
          }>
          {getUserName(name, 'RES-', 'Resource')}
        </Typography.Link>
      ),
      sorter: true,
    },
    {
      title: 'Supplier Name',
      dataIndex: 'supplierName',
      key: 'expand_supplier.name',
      showSorterTooltip: false,
      render: (name, item) => (
        <Typography.Link
          className='font-semibold'
          onClick={() =>
            navigate(`/suppliers/${item?.supplierId}/view-supplier`)
          }>
          {name}
        </Typography.Link>
      ),
      sorter: true,
    },
    {
      title: 'Engagement Job Title',
      dataIndex: 'jobTitle',
      key: 'jobTitle',
    },
    {
      title: 'Engagement ID',
      dataIndex: 'hid',
      key: 'hid',
      render: (id, item) => (
        <Typography.Link
          className='font-semibold'
          onClick={() => navigate(`/engagements/${item?.id}/view-engagement`)}>
          {id}
        </Typography.Link>
      ),
    },
    {
      title: 'Start Date',
      dataIndex: 'startDate',
      key: 'startDate',
    },
    {
      title: 'End Date',
      dataIndex: 'endDate',
      key: 'endDate',
    },
    {
      title: 'Hiring Manager',
      dataIndex: 'hiringManagerName',
      key: 'expand_hiring_manager.first_name',
      sorter: true,
      showSorterTooltip: false,
    },
    {
      title: 'Resource Rating',
      dataIndex: 'resourceReview',
      key: 'resource_review.review_average',
      showSorterTooltip: false,
      render: (rating, item) => {
        if (item?.resourceReview?.review_average > 0) {
          return (
            <Typography.Link
              onClick={() =>
                navigate('/administration/resource/' + item?.id + '/review')
              }
              className='font-semibold'>
              {item?.resourceReview?.review_average}
            </Typography.Link>
          );
        }
      },

      sorter: true,
    },
  ];
};
