import {Space} from 'antd';
import Certifications from 'app/shared/resource/Certifications';
import Courses from 'app/shared/resource/Courses';
import Education from 'app/shared/resource/Education';
import Languages from 'app/shared/resource/Languages';
import PropTypes from 'prop-types';
import React from 'react';

const OtherContent = ({resource}) => {
  const {educations, certifications, courses, languages} = resource;
  return (
    <Space direction='vertical' size='large' style={{display: 'flex'}}>
      <Education educations={educations} isCard={false} />
      <Certifications certifications={certifications} isCard={false} />
      <Courses courses={courses} isCard={false} />
      <Languages languages={languages} isCard={false} />
    </Space>
  );
};

OtherContent.propTypes = {
  resource: PropTypes.object,
};

export default OtherContent;
