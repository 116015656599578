import {Row} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

const AppGridContainer = ({children, ...rest}) => {
  return (
    <Row gutter={[30, 30]} {...rest}>
      {children}
    </Row>
  );
};

AppGridContainer.propTypes = {
  children: PropTypes.node,
};

export default AppGridContainer;
