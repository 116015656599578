import axios from '../config/axios';

export const engagementAPI = {
  getEngagements: async (params) => axios.get('engagements', {params}),
  postEngagement: async (data) => axios.post('engagements', data),
  updateEngagement: async (id, data) => axios.patch(`engagements/${id}`, data),
  postEngagementTask: async (data) => axios.post('engagement_tasks', data),
  postEngagementRates: async (data) => axios.post('engagement_rates', data),
  updateEngagementTask: async (id, data) =>
    axios.patch(`engagement_tasks/${id}`, data),
  updateEngagementRates: async (id, data) =>
    axios.patch(`engagement_rates/${id}`, data),
};
