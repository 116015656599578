import AppCard from '@wieldy/components/AppCard';
import AppFormWrapper, {
  AppFormActions,
  AppFormBody,
} from '@wieldy/components/AppFormWrapper';
import AppInput from '@wieldy/components/dataEntry/AppInput';
import AppSelect from '@wieldy/components/dataEntry/AppSelect';
import {
  getInputPlaceHolder,
  getRequiredMessage,
  getSelectPlaceHolder,
} from '@wieldy/utils/helpers/MessageHelper';
import {Button, Form} from 'antd';
import {COUNTRIES} from 'app/constants';
import LocationHelper from 'app/utils/LocationHelper';
import PropTypes from 'prop-types';
import React from 'react';

const PrimaryAddressForm = ({onContinue, details, setState}) => {
  const [country, setCountry] = React.useState();
  const handleOnSubmit = (formData) => {
    setState({primary_address: formData});
    if (onContinue) {
      onContinue();
    }
  };

  return (
    <AppCard>
      <AppFormWrapper>
        <Form
          layout='vertical'
          autoComplete='off'
          onFinish={handleOnSubmit}
          initialValues={details}>
          <AppFormBody>
            <Form.Item
              name='country'
              label='Country'
              rules={[
                {
                  required: true,
                  message: getRequiredMessage('Country'),
                },
              ]}>
              <AppSelect
                showSearch
                placeholder={getSelectPlaceHolder('country')}
                style={{minWidth: '280px', width: 'auto', maxWidth: '100%'}}
                options={LocationHelper.getCountries()}
                onChange={setCountry}
              />
            </Form.Item>

            <Form.Item
              name='line_1'
              label='Address Line 1'
              rules={[
                {
                  required: true,
                  message: getRequiredMessage('Address line 1'),
                },
              ]}>
              <AppInput
                placeholder={getInputPlaceHolder('address line 1')}
                style={{minWidth: '280px', width: 'auto', maxWidth: '100%'}}
              />
            </Form.Item>

            <Form.Item name='line_2' label='Address Line 2'>
              <AppInput
                placeholder={getInputPlaceHolder('address line 2')}
                style={{minWidth: '280px', width: 'auto', maxWidth: '100%'}}
              />
            </Form.Item>

            <Form.Item
              name='city'
              label='City'
              rules={[
                {
                  required: true,
                  message: getRequiredMessage('City'),
                },
              ]}>
              <AppInput
                placeholder={getInputPlaceHolder('city')}
                style={{minWidth: '280px', width: 'auto', maxWidth: '100%'}}
              />
            </Form.Item>

            <Form.Item
              name='state'
              label='State / Province'
              rules={[
                {
                  required:
                    country && COUNTRIES[country] === COUNTRIES.united_states,
                  message: getRequiredMessage('State / Province'),
                },
              ]}>
              <AppSelect
                showSearch
                placeholder={getSelectPlaceHolder('state/province')}
                style={{minWidth: '280px', width: 'auto', maxWidth: '100%'}}
                options={LocationHelper.getUsStates()}
                disabled={
                  !(country && COUNTRIES[country] === COUNTRIES.united_states)
                }
              />
            </Form.Item>

            <Form.Item
              name='postal_code'
              label='Postal / Zip Code'
              rules={[
                {
                  required: true,
                  message: getRequiredMessage('Postal / Zip Code'),
                },
              ]}>
              <AppInput
                placeholder={getInputPlaceHolder('postal/zip code')}
                style={{minWidth: '280px', width: 'auto', maxWidth: '100%'}}
              />
            </Form.Item>
          </AppFormBody>

          <AppFormActions>
            <Button type='primary' htmlType='submit'>
              Continue
            </Button>
          </AppFormActions>
        </Form>
      </AppFormWrapper>
    </AppCard>
  );
};

PrimaryAddressForm.propTypes = {
  details: PropTypes.object,
  setState: PropTypes.func,
  onContinue: PropTypes.func,
};

export default PrimaryAddressForm;
