import {getListPagination} from '@wieldy/utils/helpers/ListHelper';
import {Empty, List, Typography} from 'antd';
import {useReduceInfinityResponse} from 'app/hooks/useReduceInfinityResponse.js';
import {useGetNotes} from 'app/services/noteService.js';
import NoteCard from 'app/shared/cards/NoteCard';
import PropTypes from 'prop-types';
import React, {useState} from 'react';

const CandidateNotes = ({candidate, enabled}) => {
  const [currentPage, setCurrentPage] = useState(7);

  const {data} = useGetNotes(
    {
      entity: '/resource_notes',
      primaryId: candidate.id,
      primaryKey: 'resource',
    },
    20,
    {enabled},
  );
  const [notes, totalNotes] = useReduceInfinityResponse(data, 'items');
  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  if (!totalNotes) {
    return null;
  }

  return (
    <div className='mb-4'>
      <Typography.Title level={4} className={'mb-3'}>
        {!!totalNotes && 'Notes'}
      </Typography.Title>
      <List
        locale={{
          emptyText: <Empty description='No notes' />,
        }}
        className='mb-4'
        itemLayout='vertical'
        pagination={getListPagination(
          currentPage,
          totalNotes,
          onPageChange,
          20,
        )}
        dataSource={notes}
        renderItem={(note) => <NoteCard key={note.id} note={note} />}
      />
    </div>
  );
};

CandidateNotes.propTypes = {
  candidate: PropTypes.object,
  enabled: PropTypes.bool,
};

CandidateNotes.defaultProps = {
  enabled: true,
};

export default CandidateNotes;
