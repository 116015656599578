import AppCard from '@wieldy/components/AppCard';
import AppHeaderPagination from '@wieldy/components/AppPagination/AppHeaderPagination';
import AppTable from '@wieldy/components/AppTable';
import AppTag from '@wieldy/components/AppTag/AppTag';
import {getTablePagination} from '@wieldy/utils/helpers/TableHelper';
import {PAGINATION} from 'app/config/app';
import {useTableSort} from 'app/hooks/useTableSort';
import ResourceReviewModal from 'app/modals/ResourceReviewModal';
import {useGetResourceReviews} from 'app/services/clientService';
import React, {useCallback} from 'react';
import {useNavigate} from 'react-router-dom';

import {getColumns} from './columns';

const ResourceReviews = () => {
  const [currentPage, setCurrentPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(PAGINATION.TABLE_ITEM_LIMIT);
  const navigate = useNavigate();

  const {sort, handleSort} = useTableSort({
    ['resource.first_name']: null,
  });

  const onChangeTable = useCallback(
    (a, b, sorter) => {
      if (sorter.columnKey && sorter?.order) {
        handleSort(sorter.columnKey, sorter?.order);
      }
    },
    [handleSort],
  );

  const {isLoading, data} = useGetResourceReviews({
    status: 'completed',
    page: currentPage - 1,
    limit: pageSize,
    sort,
  });

  const handleChangePagination = React.useCallback((pageNumber, pageSize) => {
    setCurrentPage(pageNumber);
    if (pageSize) setPageSize(pageSize);
  }, []);

  const bulkActions = React.useCallback(
    (item) => {
      return [
        {
          title: item?.resourceReview?.id ? 'Manage Review' : 'Add New Review',
          onClick: () =>
            navigate('/administration/resource/' + item.id + '/review'),
        },
      ];
    },
    [navigate],
  );

  return (
    <AppCard
      title={
        <React.Fragment>
          Resource Reviews{' '}
          {data?.totalCount > 0 && (
            <AppTag className={'ml-1'}>{data.totalCount}</AppTag>
          )}
        </React.Fragment>
      }
      headStyle={{borderBottom: 0}}
      bodyStyle={{paddingTop: 8}}
      extra={
        <React.Fragment>
          {data?.totalCount > 0 && (
            <AppHeaderPagination
              total={data?.totalCount}
              current={currentPage}
              pageSize={pageSize}
              onChange={handleChangePagination}
            />
          )}
        </React.Fragment>
      }>
      <AppTable
        bulkActions={bulkActions}
        loading={isLoading}
        columns={getColumns(navigate)}
        onChange={onChangeTable}
        dataSource={
          data?.items
            ? data?.items.map((item) => new ResourceReviewModal(item))
            : []
        }
        style={{background: 'none'}}
        pagination={getTablePagination(
          pageSize,
          currentPage,
          data?.totalCount,
          handleChangePagination,
          handleChangePagination,
        )}
      />
    </AppCard>
  );
};

export default ResourceReviews;
