import AppPageContainer from '@wieldy/components/pageComponents/AppPageContainer';
import AppPageHeader from '@wieldy/components/pageComponents/AppPageHeader';
import React from 'react';

import MainContent from './MainContent';

const CreateClient = () => {
  return (
    <AppPageContainer
      header={
        <AppPageHeader title='Add New Client Account' category='Accounts' />
      }>
      <MainContent />
    </AppPageContainer>
  );
};

export default React.memo(CreateClient);
