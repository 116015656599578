import axios from 'app/config/axios';
import {useMutation} from 'react-query';

const formatData = (item) => {
  const obj = {
    id: item?.id,
    first_name: item?.first_name ?? '',
    last_name: item?.last_name ?? '',
    title: item?.title,
    email: item?.email,
  };
  obj.name = obj.first_name + ' ' + obj.last_name;
  return obj;
};

const getMembers = (endpoint) => {
  return async (queryParams) => {
    const params = {
      limit: 100,
      expand: ['user'],
      ...queryParams,
    };
    const {data} = await axios.get(endpoint, {params});
    return Array.isArray(data?.data)
      ? data?.data.map((item) => formatData(item?.user))
      : [];
  };
};

const getResources = () => {
  return async (queryParams) => {
    const params = {
      limit: 100,
      ...queryParams,
    };
    const {data} = await axios.get('resources', {params});
    return Array.isArray(data?.data)
      ? data?.data.map((item) => formatData(item))
      : [];
  };
};

const variants = {
  client: () => getMembers('client_members'),
  supplier: () => getMembers('supplier_members'),
  marketplaceAll: () => getMembers('marketplace_members/all'),
  resource: getResources,
};

export const useCompanyUsers = (variant) => {
  const mutateFunc = variants[variant];
  if (!mutateFunc) {
    throw new Error('Please select correct variant');
  }
  return useMutation(mutateFunc());
};
