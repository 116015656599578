import AppTag from '@wieldy/components/AppTag';
import {Col, Descriptions, Row, Typography} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

const WorksiteTimings = ({requisition}) => {
  const renderLocation = () => {
    if (requisition.jobLocation) {
      return requisition.jobLocation;
    }
    if (Array.isArray(requisition?.jobCountry)) {
      return requisition?.jobCountry.map((country) => (
        <Col key={country}>
          <AppTag
            key={country}
            className={'border-none'}
            shape={'square'}
            label={country}
          />
        </Col>
      ));
    }
    return 'Any country';
  };
  return (
    <div className='mb-5'>
      <Typography.Title level={4} className='mb-5'>
        Worksite and Timeline
      </Typography.Title>
      <Descriptions
        colon={false}
        labelStyle={{width: '50%'}}
        className='description-root'
        column={{xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1}}>
        <Descriptions.Item label='Work Model'>
          {requisition.jobWorkSite}
        </Descriptions.Item>
        <Descriptions.Item label='Job Location'>
          <Row wrap='wrap' gutter={[5, 5]}>
            {renderLocation()}
          </Row>
        </Descriptions.Item>
        <Descriptions.Item label='Travel Required'>
          {requisition.travelRequirements}
        </Descriptions.Item>
        {!requisition.isDirectHire && (
          <Descriptions.Item label='Duration'>
            {requisition.jobDuration || 'TBD'}
          </Descriptions.Item>
        )}

        <Descriptions.Item
          label={requisition.isDirectHire ? 'Job Start Date' : 'Start Date'}>
          {requisition.startDate}
        </Descriptions.Item>
        {!requisition.isDirectHire && (
          <Descriptions.Item label='End Date'>
            {requisition.endDate}
          </Descriptions.Item>
        )}
      </Descriptions>
    </div>
  );
};

WorksiteTimings.propTypes = {
  requisition: PropTypes.object,
};

export default React.memo(WorksiteTimings);
