import {Descriptions, Typography} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

const WorkEnvironment = ({resource}) => {
  return (
    <div className='mb-6'>
      <Typography.Title level={4} className={'mb-3'}>
        Work Environment
      </Typography.Title>
      <Descriptions
        colon={false}
        labelStyle={{width: '50%'}}
        className='description-root'
        column={{xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1}}>
        <Descriptions.Item label='Work Location Type'>
          {resource.work_location}
        </Descriptions.Item>
        <Descriptions.Item label='Conference Room Access'>
          {resource.work_conference_room_access}
        </Descriptions.Item>
        <Descriptions.Item label='Work Computer'>
          {resource.work_computer_type}
        </Descriptions.Item>
        <Descriptions.Item label='Printer / Scanner Access'>
          {resource.work_printer_scanner}
        </Descriptions.Item>
        <Descriptions.Item label='Work Computer OS'>
          {resource.work_computer_os_type}
        </Descriptions.Item>
        <Descriptions.Item label='Extra Monitor'>
          {resource.work_extra_monitor}
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
};

WorkEnvironment.propTypes = {
  resource: PropTypes.object,
};

export default WorkEnvironment;
