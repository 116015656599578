import {calcAmountByPercent} from './CalcAmountByPercent';
import {formatBillingRateRage} from './DataFormatHelper';

export const calcRecruitmentFee = ({base, min, max, fee, isFixed}) => {
  if (isFixed) return formatBillingRateRage(fee);

  if (base) {
    return formatBillingRateRage(calcAmountByPercent(base, fee));
  }

  const minAmount = calcAmountByPercent(min, fee);
  const maxAmount = calcAmountByPercent(max, fee);

  return formatBillingRateRage(base, minAmount, maxAmount);
};
