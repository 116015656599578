import {yupResolver} from '@hookform/resolvers/yup';
import {billingAddressSchema} from '@register-app/schemas/billing-address.schema';
import AppCard from '@wieldy/components/AppCard';
import {
  getInputPlaceHolder,
  getSelectPlaceHolder,
} from '@wieldy/utils/helpers/MessageHelper';
import {Button, Form} from 'antd';
import LocationHelper from 'app/utils/LocationHelper';
import PropTypes from 'prop-types';
import React from 'react';
import {FormProvider, useForm} from 'react-hook-form';

import AppInput from '../controles/input';
import AppSelect from '../controles/select';

const BillingAddressForm = ({onContinue, details, setState}) => {
  const methods = useForm({
    defaultValues: details,
    resolver: yupResolver(billingAddressSchema),
  });
  const {handleSubmit, watch} = methods;

  const country = watch('country');
  const onSubmit = (formData) => {
    setState({billing_address: formData});
    if (onContinue) {
      onContinue();
    }
  };

  return (
    <AppCard>
      <FormProvider {...methods}>
        <Form
          layout='vertical'
          style={{maxWidth: 280}}
          onFinish={handleSubmit(onSubmit)}>
          <AppInput
            isRequired
            name='company_legal_name'
            label='Company Legal Name'
            placeholder={getInputPlaceHolder('company legal name')}
          />
          <AppInput
            isRequired
            name='contact'
            label='Billing Contact'
            placeholder={getInputPlaceHolder('name')}
          />

          <AppInput
            isRequired
            name='email_address'
            label='Billing Email Address'
            placeholder={getInputPlaceHolder('email address')}
          />

          <AppInput
            type='phone'
            name='phone_number'
            label='Billing Phone Number'
            placeholder={getInputPlaceHolder('phone number')}
          />

          <AppSelect
            isRequired
            showSearch
            name='country'
            label='Country'
            placeholder={getSelectPlaceHolder('country')}
            options={LocationHelper.getCountries()}
          />

          <AppInput
            isRequired
            name='line_1'
            label='Address Line 1'
            placeholder={getInputPlaceHolder('address line 1')}
          />

          <AppInput
            name='line_2'
            label='Address Line 2'
            placeholder={getInputPlaceHolder('address line 2')}
          />
          <AppInput
            isRequired
            name='city'
            label='City'
            placeholder={getInputPlaceHolder('city')}
          />

          <AppSelect
            name='state'
            label='State / Province'
            showSearch
            isRequired={country === 'united_states'}
            placeholder={getSelectPlaceHolder('state/province')}
            options={LocationHelper.getUsStates()}
            disabled={country !== 'united_states'}
          />

          <AppInput
            isRequired
            name='postal_code'
            label='Postal / Zip Code'
            placeholder={getInputPlaceHolder('postal/zip code')}
          />

          <Button type='primary' htmlType='submit'>
            Continue
          </Button>
        </Form>
      </FormProvider>
    </AppCard>
  );
};

BillingAddressForm.propTypes = {
  details: PropTypes.object,
  onContinue: PropTypes.func,
  setState: PropTypes.func,
};

export default React.memo(BillingAddressForm);
