import axios from '../config/axios';

export const requisitionInvitationAPI = {
  sendDeleteSupplierRequisitionInvitation: async (data) =>
    await axios.post('supplier_requisition_invitations/manage_bunch', data),
  getSupplierRequisitionInvitation: async (reqId) =>
    await axios.get('supplier_requisition_invitations', {
      params: {requisition: reqId},
    }),
  acceptDeclineSupplierRequisitionInvitation: async ({id, accepted}) =>
    await axios.patch(`supplier_requisition_invitations/${id}`, {
      accepted,
    }),
};
