import {
  CreateBlankTimecard,
  CreateTimecard,
  default as Timecards,
  EditTimeCard,
  ViewTimecard,
} from 'app/pages/main/timecards';

export const supplier_TimecardRoutes = [
  {
    path: 'timecards',
    element: <Timecards />,
  },
  {
    path: '/timecards/add-timecard',
    element: <CreateTimecard />,
  },
  {
    path: '/timecards/add-blank-timecard',
    element: <CreateBlankTimecard />,
  },
  {
    path: '/timecards/edit-timecard/:id',
    element: <EditTimeCard />,
  },
  {
    path: 'timecards/:category',
    element: <Timecards />,
  },
  {
    path: 'timecards/:id/:tab',
    element: <ViewTimecard />,
  },
];

export const client_TimecardRoutes = [
  {
    path: 'timecards',
    element: <Timecards />,
  },
  {
    path: 'timecards/:category',
    element: <Timecards />,
  },
  {
    path: 'timecards/:id/:tab',
    element: <ViewTimecard />,
  },
];
