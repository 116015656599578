import '@register-app/styles/form-styles.less';

import AccountOwnerForm from '@register-app/components/forms/account-manager-form';
import BillingAddressForm from '@register-app/components/forms/billing-address-form';
import CompanyInfoForm from '@register-app/components/forms/company-info-form';
import DiversityInformationForm from '@register-app/components/forms/diversity-information-form';
import PrimaryAddressForm from '@register-app/components/forms/primary-address-form';
import ServiceRegionsForm from '@register-app/components/forms/service-regions-form';
import BillingAddressView from '@register-app/components/views/billing-address-view';
import CompanyInfoView from '@register-app/components/views/company-info-view';
import ContactDetailsView from '@register-app/components/views/contact-details-view';
import DiversityInformationView from '@register-app/components/views/diversity-information-view';
import PrimaryAddressView from '@register-app/components/views/primary-address-view';
import ServiceRegionsView from '@register-app/components/views/service-regions-view';
import CreateAccount from 'app/pages/manager/dashboard/CreateClientSupplierCommon/CreateAccount';
import {StepTitle} from 'app/shared/components/AppSteps';

import {AccountManagerView} from '../../../app/pages/manager/dashboard/CreateClientSupplierCommon/AccountManager';
import AccountManagerForm from '../../../app/pages/manager/dashboard/CreateClientSupplierCommon/AccountManager/AccountManagerForm';
import {
  MarketPlaceForm,
  MarketPlaceView,
} from '../../../app/pages/manager/dashboard/CreateSupplier/MainContent/MarketPlaceFees';

export const getStepItems = (handleOnFinish) => {
  return [
    {
      title: (props) => (
        <StepTitle
          isEdit={props.current > 0}
          onEditClick={props.goToStep(0)}
          title='Company Information'
          {...props}
        />
      ),
      subTitle: 'Provide basic details about this supplier',
      preview: ({state}) => <CompanyInfoView details={state?.company_info} />,
      description: ({goToStep, state, setState}) => (
        <CompanyInfoForm
          onContinue={goToStep(1)}
          details={state?.company_info}
          setState={setState}
        />
      ),
    },
    {
      title: (props) => (
        <StepTitle
          isEdit={props.current > 1}
          onEditClick={props.goToStep(1)}
          title='Diversity Information'
          {...props}
        />
      ),
      subTitle: 'Provide your company diversity status and sourcing practices',
      preview: ({state}) => (
        <DiversityInformationView details={state?.diversity_information} />
      ),
      description: ({goToStep, state, setState}) => (
        <DiversityInformationForm
          onContinue={goToStep(2)}
          details={state?.diversity_information}
          setState={setState}
        />
      ),
    },
    {
      title: (props) => (
        <StepTitle
          isEdit={props.current > 2}
          onEditClick={props.goToStep(2)}
          title='Specializations and Service Regions'
          {...props}
        />
      ),
      subTitle: "Provide your company's specializations and service regions",
      preview: ({state}) => (
        <ServiceRegionsView details={state?.service_regions} />
      ),
      description: ({goToStep, state, setState}) => (
        <ServiceRegionsForm
          onContinue={goToStep(3)}
          details={state?.service_regions}
          supplierType={state?.company_info?.type}
          setState={setState}
        />
      ),
    },
    {
      title: (props) => (
        <StepTitle
          isEdit={props.current > 3}
          onEditClick={props.goToStep(3)}
          title={'Primary Address'}
          {...props}
        />
      ),
      subTitle: "Provide supplier's primary business address",
      preview: ({state}) => (
        <PrimaryAddressView details={state?.primary_address} />
      ),
      description: ({goToStep, state, setState}) => (
        <PrimaryAddressForm
          onContinue={goToStep(4)}
          details={state?.primary_address}
          setState={setState}
        />
      ),
    },
    {
      title: (props) => (
        <StepTitle
          isEdit={props.current > 4}
          onEditClick={props.goToStep(4)}
          title={'Billing Address'}
          {...props}
        />
      ),
      subTitle: "Provide supplier's billing address",
      preview: ({state}) => (
        <BillingAddressView details={state?.billing_address} />
      ),
      description: ({goToStep, state, setState}) => (
        <BillingAddressForm
          onContinue={goToStep(5)}
          details={state?.billing_address}
          setState={setState}
        />
      ),
    },
    {
      title: (props) => (
        <StepTitle
          isEdit={props.current > 5}
          onEditClick={props.goToStep(5)}
          title={'Account Owner'}
          {...props}
        />
      ),
      subTitle: "Provide your company's Account Owner information",
      preview: ({state}) => (
        <ContactDetailsView details={state?.account_owner} />
      ),
      description: ({goToStep, state, setState}) => (
        <AccountOwnerForm
          onContinue={goToStep(6)}
          account_manager={state?.account_owner}
          setState={setState}
        />
      ),
    },
    {
      title: (props) => (
        <StepTitle
          isEdit={props.current > 6}
          onEditClick={props.goToStep(6)}
          title={'Account Manager'}
          {...props}
        />
      ),
      subTitle: 'Assign Account Manager to manage this supplier',
      preview: ({state}) => (
        <AccountManagerView account_manager={state?.account_manager} />
      ),
      description: ({goToStep, state, setState}) => (
        <AccountManagerForm
          onContinue={goToStep(7)}
          account_manager={state?.account_manager}
          setState={setState}
        />
      ),
    },
    {
      title: (props) => (
        <StepTitle
          isEdit={props.current > 7}
          onEditClick={props.goToStep(7)}
          title={'Marketplace Fees'}
          {...props}
        />
      ),
      subTitle:
        'List managed service provider fees to be paid by this supplier',
      preview: ({state}) => (
        <MarketPlaceView details={state?.marketplace_fees || []} />
      ),
      description: ({goToStep, state, setState}) => (
        <MarketPlaceForm
          onContinue={goToStep(9)}
          details={state?.marketplace_fees}
          setState={setState}
        />
      ),
    },
    {
      title: (props) => <StepTitle title={'Create'} {...props} />,
      subTitle: 'Create new supplier account',
      description: (
        <CreateAccount onFinish={handleOnFinish} accountType='Supplier' />
      ),
    },
  ];
};
