import AppCard from '@wieldy/components/AppCard';
import AppFormWrapper, {
  AppFormActions,
  AppFormBody,
} from '@wieldy/components/AppFormWrapper';
import AppInput from '@wieldy/components/dataEntry/AppInput';
import AppInputNumber from '@wieldy/components/dataEntry/AppInputNumber';
import AppSelect from '@wieldy/components/dataEntry/AppSelect';
import {
  getInputPlaceHolder,
  getRequiredMessage,
  getSelectPlaceHolder,
} from '@wieldy/utils/helpers/MessageHelper';
import {Button, Form, Select} from 'antd';
import {PAYMENT_TERMS} from 'app/constants';
import useStep from 'app/hooks/useStep';
import {setEngagementJobDetails} from 'app/store/features/engagement/engagementSlice';
import CommonHelper from 'app/utils/CommonHelper';
import {preventNegativeNumber} from 'app/utils/helpers/preventNegativeNumber';
import RequisitionHelper from 'app/utils/RequisitionHelper';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import LocationHelper from '../../../../../../utils/LocationHelper';

const JobDetailsForm = () => {
  const [country, setCountry] = useState(null);
  const {nextStep} = useStep();
  const dispatch = useDispatch();
  const {jobDetails: details} = useSelector(({engagement}) => engagement);
  const handleOnSubmit = (formData) => {
    dispatch(setEngagementJobDetails(formData));
    if (nextStep) {
      nextStep();
    }
  };

  const [form] = Form.useForm();

  const isTax = Form.useWatch('charge_tax', form);
  const isOvertime = Form.useWatch('job_overtime_eligible', form);

  return (
    <AppCard>
      <AppFormWrapper>
        <Form
          form={form}
          layout='vertical'
          initialValues={details}
          autoComplete='off'
          onFinish={handleOnSubmit}>
          <AppFormBody>
            <Form.Item
              name='job_worksite'
              label='Work Model'
              rules={[
                {
                  required: true,
                  message: getRequiredMessage('Work Model'),
                },
              ]}>
              <AppSelect
                placeholder={getSelectPlaceHolder('Work model')}
                style={{minWidth: '280px', width: 'auto', maxWidth: '100%'}}
                options={RequisitionHelper.worksites()}
              />
            </Form.Item>
            <div style={{display: 'flex', gap: 10}}>
              <Form.Item
                name='job_country'
                label='Country'
                style={{maxWidth: 280, width: '100%'}}
                rules={[
                  {
                    required: true,
                    message: getRequiredMessage('Job Location'),
                  },
                ]}>
                <AppSelect
                  onChange={(_, val) => setCountry(val?.value)}
                  showSearch
                  placeholder={getSelectPlaceHolder('job location')}
                  options={RequisitionHelper.countries()}
                />
              </Form.Item>
              {country === 'united_states' && (
                <Form.Item
                  style={{maxWidth: 280, width: '100%'}}
                  name='us_state'
                  label='State'
                  rules={[
                    {
                      required: true,
                      message: getRequiredMessage('State'),
                    },
                  ]}>
                  <AppSelect
                    showSearch
                    placeholder={getSelectPlaceHolder('state')}
                    options={LocationHelper.getUsStates()}
                  />
                </Form.Item>
              )}
              <Form.Item
                style={{maxWidth: 280, width: '100%'}}
                name='city'
                label='City'
                rules={[
                  {
                    required: true,
                    message: getRequiredMessage('City'),
                  },
                ]}>
                <AppInput placeholder={getInputPlaceHolder('city')} />
              </Form.Item>
            </div>

            <Form.Item
              name='part_time'
              label='Part Time'
              rules={[
                {
                  required: true,
                  message: getRequiredMessage('Part time'),
                },
              ]}>
              <AppSelect
                placeholder={getSelectPlaceHolder('part time')}
                style={{minWidth: '320px', width: 'auto', maxWidth: '100%'}}
                options={CommonHelper.yesNoOptions()}
              />
            </Form.Item>

            <Form.Item
              name='job_travel_requirements'
              label='Travel Required'
              rules={[
                {
                  required: true,
                  message: getRequiredMessage('Travel Required'),
                },
              ]}>
              <AppSelect
                placeholder={getSelectPlaceHolder('Travel Required')}
                style={{minWidth: '320px', width: 'auto', maxWidth: '100%'}}
                options={RequisitionHelper.travelRequirements()}
              />
            </Form.Item>

            <Form.Item
              name='job_expenses_eligible'
              label='Expenses Eligible'
              rules={[
                {
                  required: true,
                  message: getRequiredMessage('Expenses eligible'),
                },
              ]}>
              <AppSelect
                placeholder='Is expenses eligible'
                style={{minWidth: '320px', width: 'auto', maxWidth: '100%'}}
                options={CommonHelper.yesNoOptions()}
              />
            </Form.Item>
            <Form.Item
              name='job_flsa_eligible'
              label='FLSA Eligible'
              rules={[
                {
                  required: false,
                  message: getRequiredMessage('FLSA eligible'),
                },
              ]}>
              <AppSelect
                placeholder='Is FLSA eligible'
                style={{minWidth: '320px', width: 'auto', maxWidth: '100%'}}
                options={CommonHelper.yesNoOptions({
                  yes: 'Exempt',
                  no: 'Non-Exempt',
                })}
              />
            </Form.Item>

            <Form.Item
              name='job_overtime_eligible'
              label='Overtime Eligible'
              rules={[
                {
                  required: true,
                  message: getRequiredMessage('Overtime eligible'),
                },
              ]}>
              <AppSelect
                placeholder='Is overtime eligible'
                style={{minWidth: '320px', width: 'auto', maxWidth: '100%'}}
                options={CommonHelper.yesNoOptions()}
              />
            </Form.Item>

            <Form.Item
              name='job_overtime_multiple'
              label='Overtime Multiple'
              rules={[
                {
                  required: isOvertime,
                  message: getRequiredMessage('Overtime multiple'),
                },
              ]}>
              <AppInputNumber
                type='number'
                min={0}
                pattern='[0-9]+([\.,][0-9]+)?'
                step='any'
                controls={false}
                onKeyDown={preventNegativeNumber}
                placeholder={getInputPlaceHolder('overtime multiple')}
                style={{minWidth: '320px', width: 'auto', maxWidth: '100%'}}
              />
            </Form.Item>

            <Form.Item
              name='job_payment_term'
              label='Payment Terms'
              rules={[
                {
                  required: true,
                  message: getRequiredMessage('Payment terms'),
                },
              ]}>
              <AppSelect
                placeholder={getSelectPlaceHolder('payment terms')}
                style={{minWidth: '320px', width: 'auto', maxWidth: '100%'}}>
                {Object.entries(PAYMENT_TERMS).map(([key, value]) => (
                  <Select.Option key={key} value={key}>
                    {value}
                  </Select.Option>
                ))}
              </AppSelect>
            </Form.Item>

            <Form.Item
              name='tax_engagement'
              label='Charge Tax'
              rules={[
                {
                  required: true,
                  message: getRequiredMessage('Charge tax'),
                },
              ]}>
              <AppSelect
                placeholder={'Should taxes apply'}
                style={{minWidth: '320px', width: 'auto', maxWidth: '100%'}}
                options={CommonHelper.yesNoOptions()}
              />
            </Form.Item>

            <Form.Item
              name='tax_labor'
              label='Tax Labor'
              rules={[
                {
                  required: !!isTax,
                  message: getRequiredMessage('Tax'),
                },
              ]}>
              <AppSelect
                placeholder='Should taxes apply to labor'
                style={{minWidth: '320px', width: 'auto', maxWidth: '100%'}}
                options={CommonHelper.yesNoOptions()}
              />
            </Form.Item>

            <Form.Item
              name='tax_expenses'
              label='Tax Expenses'
              rules={[
                {
                  required: !!isTax,
                  message: getRequiredMessage('Tax'),
                },
              ]}>
              <AppSelect
                placeholder={'Should taxes apply to expenses'}
                style={{minWidth: '320px', width: 'auto', maxWidth: '100%'}}
                options={CommonHelper.yesNoOptions()}
              />
            </Form.Item>

            <Form.Item
              name='tax_country'
              label='Country Tax'
              rules={[
                {
                  required: !!isTax,
                  message: getRequiredMessage('Tax'),
                },
              ]}>
              <AppInputNumber
                suffix='%'
                type='number'
                min={0}
                pattern='[0-9]+([\.,][0-9]+)?'
                step='any'
                controls={false}
                onKeyDown={preventNegativeNumber}
                placeholder={getInputPlaceHolder('country tax')}
                style={{minWidth: '200px', width: 'auto', maxWidth: '100%'}}
              />
            </Form.Item>

            <Form.Item
              name='tax_state'
              label='State Tax'
              rules={[
                {
                  required: !!isTax,
                  message: getRequiredMessage('Tax'),
                },
              ]}>
              <AppInputNumber
                suffix='%'
                type='number'
                min={0}
                pattern='[0-9]+([\.,][0-9]+)?'
                step='any'
                controls={false}
                onKeyDown={preventNegativeNumber}
                placeholder={getInputPlaceHolder('state tax')}
                style={{minWidth: '200px', width: 'auto', maxWidth: '100%'}}
              />
            </Form.Item>

            <Form.Item
              name='tax_city'
              label='City Tax'
              rules={[
                {
                  required: !!isTax,
                  message: getRequiredMessage('Tax'),
                },
              ]}>
              <AppInputNumber
                type='number'
                min={0}
                pattern='[0-9]+([\.,][0-9]+)?'
                step='any'
                controls={false}
                onKeyDown={preventNegativeNumber}
                suffix='%'
                placeholder={getInputPlaceHolder('city tax')}
                style={{minWidth: '200px', width: 'auto', maxWidth: '100%'}}
              />
            </Form.Item>
          </AppFormBody>
          <AppFormActions>
            <Button type='primary' htmlType='submit'>
              Continue
            </Button>
          </AppFormActions>
        </Form>
      </AppFormWrapper>
    </AppCard>
  );
};

JobDetailsForm.propTypes = {
  details: PropTypes.object,
  onContinue: PropTypes.func,
  setState: PropTypes.func,
};

export default JobDetailsForm;
