import {Button} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import {RiFilter2Line} from 'react-icons/ri';

const FilterButton = ({enabled, ...restProps}) => {
  return (
    <Button
      disabled={!enabled}
      type='primary'
      size='small'
      className='border-2 mr-2'
      ghost
      {...restProps}>
      <RiFilter2Line fontSize={16} className='mr-2' />
      <span style={{fontSize: '11px'}}>Filter</span>
    </Button>
  );
};

FilterButton.propTypes = {
  enabled: PropTypes.bool,
};
export default FilterButton;
