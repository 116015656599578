import AppFilterWrapper from '@wieldy/components/AppFilterWrapper';
import AppGridView from '@wieldy/components/AppGridView';
import AppLoader from '@wieldy/components/AppLoader';
import AppPagination from '@wieldy/components/AppPagination';
import AppHeaderPagination from '@wieldy/components/AppPagination/AppHeaderPagination';
import AppPageSection from '@wieldy/components/pageComponents/AppPageSection';
import {Empty} from 'antd';
import {CATEGORIES} from 'app/constants/index.js';
import {useBrowse} from 'app/hooks/useBrowse.js';
import usePageQuery from 'app/hooks/usePageQuery';
import ResourceModal from 'app/modals/ResourceModal';
import CandidateCard from 'app/shared/cards/CandidateCard';
import {FilterButton} from 'app/shared/components';
import FilterResults from 'app/shared/components/FIlter/FilterResults';
import {getItemsData} from 'app/utils/helpers/GetItemsData.js';
import PropTypes from 'prop-types';
import React from 'react';
import {useParams} from 'react-router-dom';

import {useGetApplicants} from '../../../../services/applicantService';
import FilterCandidates from '../../../../shared/candidate/FilterCandidates/FilterCandidates';

const Browse = () => {
  const {category} = useParams();

  const isBookmarked = category === CATEGORIES.BOOKMARKED;
  const {
    onToggleFilter,
    pageSize,
    currentPage,
    sortBy,
    onShowSizeChange,
    onChangePage,
    onSortBy,
    openFilter,
  } = useBrowse({
    initPageSize: 15,
    initSort: isBookmarked ? '-bookmarked_on' : '-available_on',
  });

  const {
    handleSetFilter,
    resetFilter,
    filterState,
    filterQuery,
    queryInit,
    currentPageParams,
  } = usePageQuery();

  const {rowTitle, empty, label} = currentPageParams;

  const handleOnApplyFilter = React.useCallback(
    (form) => {
      handleSetFilter(form);
      onToggleFilter(false);
    },
    [handleSetFilter, onToggleFilter],
  );

  const {data, isLoading} = useGetApplicants({
    limit: pageSize,
    page: currentPage - 1,
    sort: [sortBy],
    ...queryInit,
    ...filterQuery,
  });

  const [resources, total_count] = getItemsData('applicants', data);
  const isEmpty = !resources?.length;
  const isFilter = Object.values(filterState).some((filter) => !!filter);

  if (isLoading) {
    return <AppLoader />;
  }

  if (isEmpty && !isFilter) {
    return <Empty description={empty} />;
  }

  return (
    <AppPageSection
      title={rowTitle}
      count={total_count}
      extra={
        <React.Fragment>
          <FilterButton onClick={onToggleFilter} enabled />
          <AppHeaderPagination
            total={total_count}
            current={currentPage}
            pageSize={pageSize}
            onChange={onChangePage}
            onShowSizeChange={onShowSizeChange}
            showSizeChanger
            showSortBy
            sortByOptions={[
              {label: 'Alphabetically', value: '+first_name'},
              {
                label: `${label} date (earliest first)`,
                value: isBookmarked ? '+bookmarked_on' : '+available_on',
              },
              {
                label: `${label} date (latest first)`,
                value: isBookmarked ? '-bookmarked_on' : '-available_on',
              },
            ]}
            sortBy={sortBy}
            onSortByChange={onSortBy}
          />
        </React.Fragment>
      }>
      <AppFilterWrapper
        title='Filter Applicants'
        initialValues={filterState}
        open={openFilter}
        onApply={handleOnApplyFilter}
        onReset={resetFilter}
        onClose={() => onToggleFilter(false)}>
        <FilterCandidates />
      </AppFilterWrapper>
      <FilterResults />
      <AppGridView
        data={resources}
        emptyTitle={empty}
        renderItem={(resource, index) => (
          <CandidateCard
            key={index}
            candidate={new ResourceModal(resource)}
            isApplicant
            showBookmark
          />
        )}
      />

      <AppPagination
        total={total_count}
        current={currentPage}
        pageSize={pageSize}
        onChange={onChangePage}
        onShowSizeChange={onShowSizeChange}
      />
    </AppPageSection>
  );
};

Browse.propTypes = {
  label: PropTypes.string,
  empty: PropTypes.string,
  rowTitle: PropTypes.string,
};

export default Browse;
