import {ChatAutoComplete, useMessageInputContext} from 'stream-chat-react';

import {EmojiPicker} from './EmojiPicker';
import {SendButtonIcon} from './SendButtonIcon';

export const ThreadMessageInput = () => {
  const messageInput = useMessageInputContext();

  return (
    <div className='thread-message-input__wrapper'>
      <div className='thread-message-input__input'>
        <ChatAutoComplete
          onChange={messageInput.handleChange}
          placeholder='Reply'
        />
        <EmojiPicker />

        <button
          className='thread-message-input__send-button'
          disabled={!messageInput.text.length}
          onClick={messageInput.handleSubmit}>
          <SendButtonIcon />
        </button>
      </div>
    </div>
  );
};
