import axios from '../config/axios';

export const expenseAPI = {
  getExpenses: async (params) => await axios.get('expenses', {params}),
  postExpense: async (payload) => await axios.post('/expenses', payload),
  updateExpense: async (payload, id) =>
    await axios.patch(`expenses/${id}`, payload),
  deleteExpense: async (id) => await axios.delete(`expenses/${id}`),
  getExpensePdf: async ({id, options}) =>
    await axios.get(`expenses/${id}/download`, {
      ...options,
    }),
};
