import AppGridContainer from '@wieldy/components/AppGridContainer';
import {Button, Col} from 'antd';
import {useRole} from 'app/hooks/useRole';
import UserModal from 'app/modals/UserModal';
import {useUpdateUserProfile} from 'app/services/userService';
import Dashboard, {
  EditProfileForm,
  ResetPassword,
  UserProfile,
} from 'app/shared/user';
import {updateUserProfile} from 'app/store/features/user/userSlice';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';

const MyAccount = () => {
  const [isEdit, setIsEdit] = React.useState(false);
  const {isResource} = useRole();
  const {user, userRole, userStatus, currentAccount} = useSelector(
    ({user}) => user,
  );
  const dispatch = useDispatch();
  const profileAvatar = isResource && currentAccount?.resource?.avatar_url;

  const mutation = useUpdateUserProfile();

  const handleUpdateProfile = React.useCallback(
    (formData) => {
      const {
        disable_time_and_expense_notifications,
        disable_email_notifications,
        phone_number,
        ...payload
      } = formData;
      mutation.mutate(
        {
          ...payload,
          phone_number:
            phone_number.indexOf('+') > -1
              ? phone_number.replace('+', '')
              : phone_number,
          notifications: {
            disable_time_and_expense_notifications:
              disable_time_and_expense_notifications ?? false,
            disable_email_notifications: disable_email_notifications ?? false,
          },
        },
        {
          onSuccess: ({data}) => {
            dispatch(updateUserProfile(data));
            setIsEdit(false);
          },
        },
      );
    },
    [dispatch, mutation],
  );
  const userModal = new UserModal({
    ...user,
    userRole,
    userStatus,
    avatar_url: user?.avatar_url || profileAvatar,
  });

  return (
    <Dashboard user={userModal} title='My Profile'>
      <AppGridContainer>
        <Col xs={24} lg={15} xxl={16}>
          {isEdit ? (
            <EditProfileForm
              initialValues={{
                ...user,
                avatar_url: user?.avatar_url || profileAvatar,
                phone_number: user?.phone_number
                  ? '+' + user?.phone_number
                  : null,
              }}
              loading={mutation.isLoading}
              onCancel={() => setIsEdit(false)}
              onSubmit={handleUpdateProfile}
            />
          ) : (
            <UserProfile user={userModal}>
              <Button type='primary' onClick={() => setIsEdit(true)}>
                Edit My Profile
              </Button>
            </UserProfile>
          )}
        </Col>
        <Col xs={24} lg={9} xxl={8}>
          <ResetPassword />
        </Col>
      </AppGridContainer>
    </Dashboard>
  );
};

export default MyAccount;
