import {Auth0Provider} from '@auth0/auth0-react';
import {AUTH0_CONFIG} from 'app/config/auth';
import React from 'react';
import {createRoot} from 'react-dom/client';

import App from './app/App';

const container = document.getElementById('root');

const root = createRoot(container);

root.render(
  <Auth0Provider
    cacheLocation={'localstorage'}
    domain={AUTH0_CONFIG.DOMAIN}
    clientId={AUTH0_CONFIG.CLIENT_ID}
    redirectUri={AUTH0_CONFIG.REDIRECT_URI}
    audience={AUTH0_CONFIG.AUDIENCE}>
    <App />
  </Auth0Provider>,
);
