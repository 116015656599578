import {UserOutlined} from '@ant-design/icons';
import {Avatar} from 'antd';
import {useAccountType} from 'app/hooks/useAccountType';
import sourcerLogo from 'assets/images/logo.png';
import React from 'react';
import {useSelector} from 'react-redux';

const MessagingChannelListHeader = React.memo(() => {
  const {isMarketplace} = useAccountType();
  const {user: authUser} = useSelector(({user}) => user);

  const username = isMarketplace
    ? 'Sourcer'
    : `${authUser?.first_name} ${authUser?.last_name}`;

  return (
    <div className='messaging__channel-list__header'>
      <Avatar
        size={42}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        icon={<UserOutlined fontSize={30} />}
        src={isMarketplace ? sourcerLogo : authUser.avatar_url}
        alt='avatar'
      />
      <div className={`messaging__channel-list__header__name`}>{username}</div>
    </div>
  );
});

export default React.memo(MessagingChannelListHeader);
