import {useParams} from 'react-router-dom';

import ClientsTable from './ClientsTable';
import SuppliersTable from './SuppliersTable';

const MainContent = () => {
  const {category} = useParams();

  if (category === 'suppliers') {
    return <SuppliersTable />;
  }
  return <ClientsTable />;
};

export default MainContent;
