export const USER_ROLES = {
  SUPPLIER: 'supplier',
  CLIENT: 'client',
  MANAGER: 'manager',
  ADMIN: 'admin',
  OWNER: 'owner',
  RESOURCE: 'resource',
};

export const PERMISSIONS = {
  ADMINISTRATION: {
    CAN_VIEW_USERS: [USER_ROLES.ADMIN, USER_ROLES.OWNER],
    CAN_ADD_USERS: [USER_ROLES.ADMIN, USER_ROLES.OWNER],
  },
  CAN_UPDATE_CLIENT_MEMBER: 'client-members:update',
  CAN_DELETE_CLIENT_MEMBER: 'client-members:delete',
  CAN_UPDATE_SUPPLIER_MEMBER: 'supplier-members:update',
  CAN_DELETE_SUPPLIER_MEMBER: 'supplier-members:delete',
  ////////////////////////
  CAN_UPDATE_CLIENT_MEMBER_ADMINS: 'client-members:update-admins',
  CAN_UPDATE_CLIENT_MEMBER_MANAGERS: 'client-members:update-managers',
  CAN_DELETE_CLIENT_MEMBER_ADMINS: 'client-members:delete-admins',
  CAN_DELETE_CLIENT_MEMBER_MANAGERS: 'client-members:delete-managers',
  CAN_UPDATE_SUPPLIER_MEMBER_ADMINS: 'supplier-members:update-admins',
  CAN_UPDATE_SUPPLIER_MEMBER_MANAGERS: 'supplier-members:update-managers',
  CAN_DELETE_SUPPLIER_MEMBER_ADMINS: 'supplier-members:delete-admins',
  CAN_DELETE_SUPPLIER_MEMBER_MANAGERS: 'supplier-members:delete-managers',
  /////////////////////////////
  CAN_UPDATE_MARKETPLACE_MEMBER: 'marketplace-members:update-admins',
  CAN_DELETE_MARKETPLACE_MEMBER: 'marketplace-members:delete-admins',
  /////////////////////////
  CAN_DELETE_RESOURCE: 'supplier-members:delete-resources',
  CAN_UPDATE_RESOURCE: 'supplier-members:update-resources',
};

// auth0 config
export const AUTH0_CONFIG = {
  DOMAIN: process.env.REACT_APP_AUTH0_DOMAIN ?? 'accounts.dev.sourcer.com',
  CLIENT_ID:
    process.env.REACT_APP_AUTH0_CLIENT_ID ?? 'Bb8kpCHHf7gbpPZXh2hp718RICL0fXzy',
  REDIRECT_URI: window.location.origin,
  AUDIENCE:
    process.env.REACT_APP_AUTH0_AUDIENCE ?? 'https://api.dev.sourcer.com/v1',
};

export const DEFAULT_ID =
  process.env.REACT_APP_DEV_USER_ID ?? 'usr_t9EpOmr7Ole9KHfc';
export const DEFAULT_AUTH =
  process.env.REACT_APP_AUTH_ENABLED === 'false' ? 'auth0' : 'authId';
export const IS_AUTH_0 = process.env.REACT_APP_AUTH_ENABLED === 'false';
