import AppLoader from '@wieldy/components/AppLoader';
import PropTypes from 'prop-types';
import {
  Chat,
  enTranslations,
  Streami18n,
  useCreateChatClient,
} from 'stream-chat-react';

import {chatApiKey} from '../../config/chat';
import {useUser} from '../../store/features/user/userSlice';

export const AppMessages = ({children}) => {
  const {user, chatToken} = useUser();

  const i18nInstance = new Streami18n({
    language: 'en',
    translationsForLanguage: {
      ...enTranslations,
    },
  });

  const client = useCreateChatClient({
    apiKey: chatApiKey,
    tokenOrProvider: chatToken,
    userData: {id: user?.id || ''},
  });

  if (!client) return <AppLoader />;

  return (
    <Chat i18nInstance={i18nInstance} client={client} theme='team messaging'>
      {children}
    </Chat>
  );
};

AppMessages.propTypes = {
  children: PropTypes.node,
};
