import {invoiceAPI} from '../api/invoiceApi';
import useReactQuery, {
  useQueryClient,
  useReactMutation,
} from '../hooks/useReactQuery';

export const useCreateInvoice = () => {
  const client = useQueryClient();
  return useReactMutation(async (payload) => {
    const {data} = await invoiceAPI.postInvoice(payload);
    await client.invalidateQueries();
    return {
      data,
      message: 'Invoice created successfully',
    };
  });
};

export const useCreatePlacementInvoice = () => {
  const client = useQueryClient();
  return useReactMutation(async (payload) => {
    const {data} = await invoiceAPI.postPlacementInvoice(payload);
    await client.invalidateQueries();
    return {
      data,
      message: 'Invoice created successfully',
    };
  });
};

export const useSendInvoice = () => {
  const client = useQueryClient();
  return useReactMutation(async ({id, payload}) => {
    const {data} = await invoiceAPI.sentInvoice(id, payload);
    await client.invalidateQueries();
    return {
      data,
      message: 'Invoice sent successfully',
    };
  });
};

export const useUpdateInvoice = () => {
  const client = useQueryClient();
  return useReactMutation(async (payload) => {
    const {id, ...body} = payload;
    const {data} = await invoiceAPI.updateInvoice(id, body);
    await client.invalidateQueries(['invoices']);
    return data;
  });
};

export const useDeleteInvoice = () => {
  const client = useQueryClient();
  return useReactMutation(async (id) => {
    const {data} = await invoiceAPI.deleteInvoice(id);
    await client.invalidateQueries(['invoices']);
    return data;
  });
};

export const useDownloadInvoice = (id, userParams) => {
  let headers;
  if (!userParams) {
    headers = {};
  } else {
    const header =
      'supplier' in userParams ? 'sourcer-supplier' : 'sourcer-client';
    const value =
      'supplier' in userParams ? userParams['supplier'] : userParams['client'];
    headers = {
      [header]: value,
    };
  }

  return useReactMutation(async (params) => {
    const {data} = await invoiceAPI.downloadInvoice(id, headers, params);
    return data;
  });
};

/** Get invoices */
export const useGetInvoices = (queryParams = {}, options = {}) => {
  const params = {
    expand: ['updated_by', 'engagement', 'placement'],
    include: ['total_count', 'hiring_manager'],
    ...queryParams,
  };
  return useReactQuery(
    ['invoices', params],
    async () => {
      const {data} = await invoiceAPI.getInvoices(params);
      return {
        items: data?.data ?? [],
        total_count: data?.total_count ?? 0,
      };
    },
    {
      ...options,
      keepPreviousData: true,
    },
  );
};
