import {
  RiBuildingLine,
  RiCalendarCheckLine,
  RiCheckboxCircleLine,
  RiGroupLine,
} from 'react-icons/ri';

const icons = {
  employee_size: <RiGroupLine fontSize={24} className={'text-secondary'} />,
  service: <RiBuildingLine fontSize={24} className={'text-secondary'} />,
  calendar: <RiCalendarCheckLine fontSize={24} className={'text-secondary'} />,
  status: <RiCheckboxCircleLine fontSize={24} className={'text-secondary'} />,
};

export const topCounts = (client) => {
  return [
    {
      label: 'Active Suppliers',
      count: client?.activeSuppliers,
    },
    {
      label: 'Deployed Resources',
      count: client?.deployedResouces,
    },
    {
      label: 'Open Requisitions',
      count: client?.openRequisitions,
    },
  ];
};

export const getClientCardItems = (client, state) => {
  return {
    top: topCounts(client),
    bottom: [
      {
        label: client?.inquiries === 1 ? 'Inquiry' : 'Inquiries',
        count: client?.inquiries,
        path: 'inquiries',
        state,
      },
      {
        label: client?.submissions === 1 ? 'Submission' : 'Submissions',
        count: client?.submissions,
        path: 'submissions',
        state,
      },
      {
        label: client?.engagements === 1 ? 'Engagement' : 'Engagements',
        count: client?.engagements,
        path: 'engagements',
        state,
      },
    ],
    content: [
      {
        icon: icons.employee_size,
        content: client?.companySize,
      },
      {
        icon: icons.service,
        content: client?.industry,
      },
      {
        icon: icons.calendar,
        content: client?.joinedAt,
      },
      {
        icon: icons.status,
        content: client?.statusName,
      },
    ],
  };
};
