import React from 'react';

const AppStepContext = React.createContext({
  currentStep: 0,
  isPreview: false,
  stepData: {},
  isLoading: false,
  isUpdated: false,
  nextStep: () => {},
    goToStep: (step) => {}, //eslint-disable-line
    setIsPreview: (previewMode) => {}, //eslint-disable-line
    setStepData: (key, data) => {}, //eslint-disable-line
  finishSteps: () => {},
    resetSteps: (dataReset = false ) => {} //eslint-disable-line
});

export default AppStepContext;
