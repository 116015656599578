import AppGridView from '@wieldy/components/AppGridView';
import AppLoader from '@wieldy/components/AppLoader';
import AppPagination from '@wieldy/components/AppPagination';
import AppHeaderPagination from '@wieldy/components/AppPagination/AppHeaderPagination';
import AppPageSection from '@wieldy/components/pageComponents/AppPageSection';
import {Empty} from 'antd';
import {USER_INCLUDES} from 'app/constants';
import {useAccountType} from 'app/hooks/useAccountType';
import {useBrowse} from 'app/hooks/useBrowse';
import InquiryModal from 'app/modals/InquiryModal';
import {useGetInquiries} from 'app/services/inquiryService';
import InquirySubmissionCard from 'app/shared/cards/InquirySubmissionCard';
import {getItemsData} from 'app/utils/helpers/GetItemsData';
import PropTypes from 'prop-types';
import React, {useMemo} from 'react';
import {useParams} from 'react-router-dom';

import {mapFilterToCategory, pageParams} from '../constants';

const Browse = ({userQuery}) => {
  const {category} = useParams();
  const {isClient} = useAccountType();
  const initSort = useMemo(
    () => mapFilterToCategory[category][0].value,
    [category],
  );
  const {
    onChangePage,
    pageSize,
    currentPage,
    sortBy,
    onShowSizeChange,
    onSortBy,
    isEnabled,
  } = useBrowse({
    initPageSize: 15,
    initSort,
  });

  const {rowTitle, empty, queryParams} = useMemo(() => {
    const activeTab = category in pageParams ? category : 'all';
    return pageParams[activeTab];
  }, [category]);

  const include = useMemo(
    () =>
      isClient
        ? USER_INCLUDES.supplierIncludeDetails
        : USER_INCLUDES.clientIncludeDetails,
    [isClient],
  );

  const {data, isLoading} = useGetInquiries(
    {
      ...queryParams,
      ...userQuery,
      include: [...queryParams.include, ...include],
      limit: pageSize,
      sort: [sortBy],
    },
    {enabled: isEnabled},
  );

  const [inquiries, inquiriesTotalCount] = getItemsData('inquiries', data);
  const isEmpty = !inquiries.length;

  if (isLoading) {
    return <AppLoader />;
  }

  if (isEmpty) {
    return <Empty description={empty} />;
  }

  return (
    <React.Fragment>
      <AppPageSection
        title={rowTitle}
        count={inquiriesTotalCount}
        extra={
          <>
            <AppHeaderPagination
              hideStartBorder
              total={inquiriesTotalCount}
              current={currentPage}
              pageSize={pageSize}
              onChange={onChangePage}
              onShowSizeChange={onShowSizeChange}
              showSizeChanger
              showSortBy
              sortByOptions={[
                // {label: 'Alphabetically', value: '+name'},
                ...mapFilterToCategory[category],
              ]}
              sortBy={sortBy}
              onSortByChange={onSortBy}
            />
          </>
        }>
        <AppGridView
          emptyTitle={empty}
          data={inquiries}
          renderItem={(inquiry) => (
            <InquirySubmissionCard
              key={inquiry.id}
              item={new InquiryModal(inquiry, true)}
            />
          )}
        />

        <AppPagination
          total={inquiriesTotalCount}
          current={currentPage}
          pageSize={pageSize}
          onChange={onChangePage}
          onShowSizeChange={onShowSizeChange}
        />
      </AppPageSection>
    </React.Fragment>
  );
};

Browse.propTypes = {
  userQuery: PropTypes.object,
};

Browse.defaultProps = {
  userQuery: {},
};

export default Browse;
