export const validator = ({field}, value, callback) => {
  switch (field) {
    case 'year':
    case 'last_used':
      if (value && value > 2024) {
        callback('Max value is 2024');
      } else if (value && value < 1970) {
        callback('Min value is 1970');
      } else {
        callback();
      }
      break;
    case 'years_experience':
      if (value && value > 60) {
        callback('Max amount is 60 years');
      } else {
        callback();
      }
      break;
    default:
      callback();
  }
};
