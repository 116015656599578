import {useMutation} from 'react-query';

import {expenseAPI} from '../api/expenseApi';
import axios from '../config/axios';
import useReactQuery, {useReactMutation} from '../hooks/useReactQuery';

const useGetExpenses = (queryParams = {}) =>
  useReactQuery(['expenses', queryParams], async () => {
    const {data} = await expenseAPI.getExpenses(queryParams);
    return {
      expenses: data?.data,
      total_count: data?.total_count,
    };
  });

const useDownloadExpenses = () => {
  return useMutation({
    mutationFn: async ({id, params}) => {
      const data = await expenseAPI.getExpensePdf({
        id,
        options: {
          responseType: 'blob',
          headers: {
            'Content-Type': 'application/pdf',
          },
          params,
        },
      });

      return data;
    },
  });
};

const useGetExpenseList = ({filter, ...queryParams} = {}) => {
  const params = {
    'created_at[gte]': filter?.gte,
    'created_at[lte]': filter?.lte,
    include: ['total_count', 'resource', 'total_amount', 'invoice_author'],
    expand: [
      'engagement',
      'invoice',
      'submitted_by',
      'approved_by',
      'rejected_by',
    ],
    ...queryParams,
  };
  return useReactQuery(
    ['expenseList', params, filter],
    async () => {
      const {data} = await axios.get('expenses', {params});
      return {
        items: data?.data ? data?.data : [],
        totalCount: data?.total_count ?? 0,
      };
    },
    {
      keepPreviousData: true,
    },
  );
};

const useGetExpenses2 = (queryParams = {}, options = {}) => {
  const params = {
    ...queryParams,
  };
  return useReactQuery(
    ['expenses', params],
    async () => {
      const {data} = await axios.get('/expenses', {params});
      return {
        items: data?.data ?? [],
        total_count: data?.total_count ?? 0,
      };
    },
    options,
  );
};

const useGetExpenseDetail = (id) => {
  const params = {
    include: [
      'resource',
      'net_total',
      'requisition',
      'total_amount',
      'invoice_author',
      // 'requisition', 'pay_units', 'net_total', 'invoice'
    ],
    expand: [
      'supplier',
      'created_by',
      'approved_by',
      'submitted_by',
      'rejected_by',
      'engagement',
      'invoice',
    ],
  };
  const itemParams = {
    expense: id,
  };

  return useReactQuery(['expense', id], async () => {
    const {data: expense} = await axios.get(`/expenses/${id}`, {params});
    const {data: items} = await axios.get(
      `/expense_items`,
      {
        params: itemParams,
      },
      {
        keepPreviousData: true,
      },
    );
    return {expense, items: items.data};
  });
};

const useUpdateExpense = (id) => {
  return useReactMutation(async (payload) => {
    const {data} = await axios.patch(`/expenses/${id}`, payload);
    return data;
  });
};

export {
  useDownloadExpenses,
  useGetExpenseDetail,
  useGetExpenseList,
  useGetExpenses,
  useGetExpenses2,
  useUpdateExpense,
};
