import AppCard from '@wieldy/components/AppCard';
import AppFormWrapper, {
  AppFormActions,
  AppFormBody,
} from '@wieldy/components/AppFormWrapper';
import AppInput from '@wieldy/components/dataEntry/AppInput';
import {
  getInputPlaceHolder,
  getRequiredMessage,
} from '@wieldy/utils/helpers/MessageHelper';
import {Button, Form} from 'antd';
import PhoneInput from 'app/shared/components/PhoneInput';
import {validatePhoneNumber} from 'app/utils/helpers/AppHelper';
import PropTypes from 'prop-types';
import React from 'react';

const AccountOwnerForm = ({onContinue, details, setState}) => {
  const handleOnSubmit = (formData) => {
    setState({account_owner: formData});
    if (onContinue) {
      onContinue();
    }
  };

  return (
    <AppCard>
      <AppFormWrapper>
        <Form
          layout='vertical'
          autoComplete='off'
          onFinish={handleOnSubmit}
          initialValues={details}>
          <AppFormBody>
            <Form.Item
              name='first_name'
              label='First Name'
              rules={[
                {
                  required: true,
                  message: getRequiredMessage('First name'),
                },
              ]}>
              <AppInput
                placeholder={getInputPlaceHolder('first name')}
                style={{minWidth: '280px', width: 'auto', maxWidth: '100%'}}
              />
            </Form.Item>

            <Form.Item
              name='last_name'
              label='Last Name'
              rules={[
                {
                  required: true,
                  message: getRequiredMessage('Last name'),
                },
              ]}>
              <AppInput
                placeholder={getInputPlaceHolder('last name')}
                style={{minWidth: '280px', width: 'auto', maxWidth: '100%'}}
              />
            </Form.Item>

            <Form.Item
              name='title'
              label='Title'
              rules={[
                {
                  required: true,
                  message: getRequiredMessage('Title'),
                },
              ]}>
              <AppInput
                placeholder={getInputPlaceHolder('title')}
                style={{minWidth: '280px', width: 'auto', maxWidth: '100%'}}
              />
            </Form.Item>

            <Form.Item
              name='email'
              label='Email Address'
              rules={[
                {
                  required: true,
                  message: getRequiredMessage('Email address'),
                },
                {
                  type: 'email',
                  message: 'Enter correct email address',
                },
              ]}>
              <AppInput
                placeholder={getInputPlaceHolder('email address')}
                style={{minWidth: '280px', width: 'auto', maxWidth: '100%'}}
              />
            </Form.Item>

            <Form.Item
              name='phone_number'
              label='Phone Number'
              rules={[
                {
                  validator: validatePhoneNumber,
                },
              ]}>
              <PhoneInput
                style={{minWidth: '280px', width: '280px', maxWidth: '100%'}}
              />
            </Form.Item>
          </AppFormBody>
          <AppFormActions>
            <Button type='primary' htmlType='submit'>
              Continue
            </Button>
          </AppFormActions>
        </Form>
      </AppFormWrapper>
    </AppCard>
  );
};

AccountOwnerForm.propTypes = {
  details: PropTypes.object,
  onContinue: PropTypes.func,
  setState: PropTypes.func,
};

export default AccountOwnerForm;
