import AppCard from '@wieldy/components/AppCard';
import {Button, notification, Typography} from 'antd';
import {useAccountType} from 'app/hooks/useAccountType';
import {useUpdateClient} from 'app/services/clientService';
import {useUpdateSupplier} from 'app/services/supplierService';
import PrimaryAddressInfo, {
  PrimaryAddressForm,
} from 'app/shared/company/PrimaryAddress';
import {
  updateCurrentUser,
  useCurrentUser,
} from 'app/store/features/user/userSlice';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';

const PrimaryAddress = () => {
  const dispatch = useDispatch();
  const [showEditForm, setShowEditForm] = React.useState(false);
  const {isSupplier} = useAccountType();
  const {companyModal} = useSelector(({user}) => user);
  const companyProfile = useCurrentUser();
  const mutation = (isSupplier ? useUpdateSupplier : useUpdateClient)();

  const handleUpdateAddress = (formData) => {
    const idName = isSupplier ? 'supplierId' : 'clientId';
    mutation.mutate(
      {
        payload: {
          city: formData?.city,
          country: formData?.country,
          us_state: formData?.state ?? null,
          primary_address: {
            line_1: formData?.line_1,
            line_2: formData?.line_2 ?? null,
            postal_code: formData?.postal_code,
          },
        },
        [idName]: companyModal.id,
      },
      {
        onSuccess: (data) => {
          dispatch(updateCurrentUser(data));
          setShowEditForm(false);
          notification.success({
            message: 'Primary address updated successfully',
          });
        },
      },
    );
  };

  const handleOnCancel = () => {
    setShowEditForm((prev) => !prev);
  };
  return (
    <AppCard>
      <Typography.Title level={3} className={'mb-3'}>
        {'Primary Address'}
      </Typography.Title>
      {showEditForm ? (
        <PrimaryAddressForm
          isLoading={mutation.isLoading}
          onSubmit={handleUpdateAddress}
          onCancel={handleOnCancel}
          initialValues={{
            ...(companyProfile?.primary_address
              ? companyProfile?.primary_address
              : {}),
            city: companyProfile?.city,
            state: companyProfile?.us_state,
            country: companyProfile?.country,
          }}
        />
      ) : (
        <PrimaryAddressInfo address={companyModal?.primaryAddress}>
          <Button type='primary' onClick={handleOnCancel}>
            Edit Primary Address
          </Button>
        </PrimaryAddressInfo>
      )}
    </AppCard>
  );
};

export default PrimaryAddress;
