import {useMutation} from 'react-query';

import {getPolicy} from './policyService';

export const useDownloadPolicyFiles = () => {
  return useMutation({
    mutationFn: ({name}) =>
      getPolicy({
        name,
        options: {
          responseType: 'blob',
          headers: {
            'Content-Type': 'application/pdf',
          },
        },
      }),
  });
};
