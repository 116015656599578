import {CreateInquiry, Inquiries, ViewInquiry} from 'app/pages/main/inquiries';

export const supplier_InquiryRoutes = [
  {
    path: 'inquiries',
    element: <Inquiries />,
  },
  {
    path: 'inquiries/:category',
    element: <Inquiries />,
  },
  {
    path: 'inquiries/:id/view-inquiry',
    element: <ViewInquiry />,
  },
];

export const client_InquiryRoutes = [
  {
    path: 'inquiries',
    element: <Inquiries />,
  },
  {
    path: 'inquiries/:category',
    element: <Inquiries />,
  },
  {
    path: 'inquiries/new-inquiry/:requisitionId?',
    element: <CreateInquiry />,
  },
  {
    path: 'inquiries/edit-inquiry/:inquiryId/:inquiryStatus',
    element: <CreateInquiry isEdit={true} />,
  },
  {
    path: 'inquiries/:id/view-inquiry',
    element: <ViewInquiry />,
  },
];
