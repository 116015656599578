import {Select} from 'antd';
import PropTypes from 'prop-types';
import React, {forwardRef, useState} from 'react';
import {RiArrowDropDownFill, RiSearchLine} from 'react-icons/ri';

const AppSelect = forwardRef(
  ({showSearch, loading, suffixIcon, ...props}, ref) => {
    const [isFocused, setFocus] = useState(false);

    return (
      <Select
        ref={ref}
        loading={loading}
        showSearch={showSearch}
        suffixIcon={
          suffixIcon ||
          (showSearch && isFocused ? (
            <RiSearchLine />
          ) : (
            <RiArrowDropDownFill fontSize={24} style={{marginRight: '-6px'}} />
          ))
        }
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
        {...props}
      />
    );
  },
);

AppSelect.propTypes = {
  onSelect: PropTypes.func,
  onChange: PropTypes.func,
  showSearch: PropTypes.bool,
  onSearch: PropTypes.func,
  value: PropTypes.any,
  placeholder: PropTypes.string,
  loading: PropTypes.bool,
  suffixIcon: PropTypes.node,
};

export default AppSelect;
