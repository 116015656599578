import {useCallback, useMemo, useState} from 'react';

export const useTableSort = (initial = {}) => {
  const [sortOrder, setSortOrder] = useState(initial);

  const handleSort = useCallback((field, value = null) => {
    setSortOrder(() => {
      return {[field]: value};
    });
  }, []);

  const sort = useMemo(
    () =>
      Object.entries(sortOrder)
        .filter(([, value]) => !!value)
        .map(([key, value]) => {
          return `${value === 'ascend' ? '' : '-'}${key}`;
        }),
    [sortOrder],
  );

  return {sort, handleSort};
};
