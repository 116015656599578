import AppGridContainer from '@wieldy/components/AppGridContainer';
import {Button, Col, Modal, Space} from 'antd';
import {
  useDeleteSupplierReview,
  useUpdateSupplierReview,
} from 'app/services/clientService';
import {RatingForm} from 'app/shared/company';
import PropTypes from 'prop-types';
import React from 'react';
import {useNavigate} from 'react-router-dom';

import {ratingFields} from '../constants';

const UpdateReview = ({review}) => {
  const navigate = useNavigate();
  const update = useUpdateSupplierReview(review?.supplierReview?.id);
  const deleteMutation = useDeleteSupplierReview(review?.supplierReview?.id);

  return (
    <RatingForm
      title='Manage Supplier Review'
      subTitle={`Last updated ${review?.updatedAt} ${
        review?.reviewBy ? 'by ' + review?.reviewBy : ''
      }`}
      fields={ratingFields}
      initialValues={review?.supplierReview}
      onSubmit={(formdata) =>
        update.mutate(
          {
            payload: formdata,
            supplier: review?.id,
          },
          {
            onSuccess: () => {
              navigate('/administration/reviews');
            },
          },
        )
      }>
      <AppGridContainer>
        <Col sm={12} md={18} lg={18}>
          <Space size={16}>
            <Button type='primary' htmlType='submit' loading={update.isLoading}>
              Update Supplier Review
            </Button>
            <Button type='primary' onClick={() => navigate(-1)} ghost>
              Cancel
            </Button>
          </Space>
        </Col>
        <Col sm={12} md={6} lg={6} className='text-right'>
          <Button
            loading={deleteMutation.isLoading}
            onClick={() =>
              Modal.confirm({
                title: 'Delete this supplier review?',
                okText: 'Delete',
                onOk: () =>
                  deleteMutation.mutate(
                    {supplier: review?.id},
                    {
                      onSuccess: () => {
                        navigate('/administration/reviews');
                      },
                    },
                  ),
              })
            }>
            Delete Supplier Review
          </Button>
        </Col>
      </AppGridContainer>
    </RatingForm>
  );
};

UpdateReview.propTypes = {
  review: PropTypes.object,
};

export default UpdateReview;
