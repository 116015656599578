import {getSelectPlaceHolder} from '@wieldy/utils/helpers/MessageHelper';
import {Button, Modal, Select, Typography} from 'antd';
import PropTypes from 'prop-types';
import React, {useMemo, useState} from 'react';

const CancelPopup = ({handleReject, isModalOpen, toggleOpen, loading}) => {
  const [comment, setComment] = useState('');

  const status = useMemo(() => {
    switch (comment) {
      case 'Requisition Fully Filled':
      case 'Requisition Partially Filled':
        return 'filled';
      case 'Unable to Fill':
      case 'No Longer Needed':
        return 'closed';
      default:
        return 'closed';
    }
  }, [comment]);

  const handleChange = ({value}) => {
    setComment(value);
  };

  return (
    <Modal
      title={`Close Requisition`}
      open={isModalOpen}
      width={800}
      onCancel={() => toggleOpen(false)}
      footer={[
        <Button
          type='primary'
          key='submit'
          loading={loading}
          onClick={() => handleReject(comment, status)}>
          Close Requisition
        </Button>,
        <Button
          type='primary'
          ghost
          loading={loading}
          key='cancel'
          onClick={() => toggleOpen(false)}>
          Cancel
        </Button>,
      ]}>
      <>
        <Typography.Paragraph>
          If you would like to close this requisition, please provide a reason
          for closing and then click Close Requisition button.
        </Typography.Paragraph>
        <Typography.Paragraph>
          If you&apos;d like to reconsider this action, click Cancel.
        </Typography.Paragraph>
        <Select
          labelInValue='Reason for Cancel'
          placeholder={getSelectPlaceHolder('reason')}
          style={{width: 300}}
          onChange={handleChange}
          options={[
            {
              value: 'Requisition Fully Filled',
              label: 'Requisition Fully Filled',
            },
            {
              value: 'Requisition Partially Filled',
              label: 'Requisition Partially Filled',
            },
            {value: `Unable to Fill`, label: 'Unable to Fill'},
            {value: `No Longer Needed`, label: 'No Longer Needed'},
          ]}
        />
      </>
    </Modal>
  );
};

CancelPopup.propTypes = {
  handleReject: PropTypes.func,
  isModalOpen: PropTypes.bool,
  toggleOpen: PropTypes.func,
  loading: PropTypes.bool,
};

export default CancelPopup;
