import AppLoader from '@wieldy/components/AppLoader';
import AppDynamicTabs from '@wieldy/components/AppTabs/AppDynamicTabs';
import AppTag from '@wieldy/components/AppTag';
import AppPageContainer from '@wieldy/components/pageComponents/AppPageContainer';
import AppPageHeader from '@wieldy/components/pageComponents/AppPageHeader';
import {INQUIRY_STATUS} from '@wieldy/constants/AppDataConsts';
import {Button, Divider, notification, Tooltip} from 'antd';
import {messagesAPI} from 'app/api/messages';
import {USER_INCLUDES} from 'app/constants';
import MESSAGE_QUERIES from 'app/constants/query-params/message-queries';
import {useAccountType} from 'app/hooks/useAccountType';
import {useNotes} from 'app/hooks/useNotes';
import InquiryModal from 'app/modals/InquiryModal';
import RequisitionModal from 'app/modals/RequisitionModal';
import {useGetInquiry, useUpdateInquiry} from 'app/services/inquiryService';
import {useCreateInquiryChannel} from 'app/services/messages';
import {useGetRequisition} from 'app/services/requisitionService';
import {getUserName} from 'app/utils/helpers/ConvertHID';
import NotesBtnIcon from 'assets/icons/NotesBtn';
import React, {useEffect, useMemo, useState} from 'react';
import {RiMessageLine} from 'react-icons/ri';
import {useNavigate, useParams} from 'react-router-dom';

import {getViewTabsData} from '../constants';
import MainContent from './MainContent';

const ViewInquiry = () => {
  const navigate = useNavigate();
  const {id} = useParams();
  const {isSupplier, isClient, isMarketplace} = useAccountType();
  const [isShowNote, setIsShowNote] = useState(false);
  const [channel, setChannel] = useState(null);

  const {mutate: mutationUpdate} = useUpdateInquiry(id);

  const includes = useMemo(
    () =>
      isClient
        ? USER_INCLUDES.supplierIncludeDetails
        : USER_INCLUDES.clientIncludeDetails,
    [isClient],
  );

  const {data, isLoading} = useGetInquiry(id, {
    expand: ['client', 'supplier'],
    include: ['candidates', 'relation_status', ...includes],
  });

  const inquiry = useMemo(() => new InquiryModal(data), [data]);

  useEffect(() => {
    const fetchChannel = async () => {
      const channelId = `${inquiry.requisition}_${inquiry.client.id}_${inquiry.supplier.id}`;
      try {
        const result = await messagesAPI.isChannel(channelId);
        setChannel(result);
        // eslint-disable-next-line no-empty
      } catch (_err) {}
    };

    isClient && inquiry?.id && fetchChannel();
  }, [
    inquiry,
    inquiry.client.id,
    inquiry.requisition,
    inquiry.supplier.id,
    isClient,
  ]);

  const {data: requisitionData} = useGetRequisition(inquiry.requisition, {
    include: [
      'skill_tags',
      'requisition_common_submissions',
      'requisition_common_inquiries',
      'requisition_common_engagements',
      'requisition_common_placements',
    ],
    ...(isClient && {target_supplier: inquiry.supplier.id}),
  });

  const createInquiryChannel = useCreateInquiryChannel();

  const requisition = new RequisitionModal(requisitionData);

  const {isNotes, handleShowNotes, response, isShowNotes, total} = useNotes(
    'inquiry_notes',
    'inquiry',
    id,
  );

  const tabsData = getViewTabsData(isNotes);
  const inquiryTitle = isSupplier ? 'Inquiry From' : 'Inquiry For';

  const companyName = getUserName(
    inquiry[isSupplier ? 'client' : 'supplier'].name,
    isSupplier ? 'CLI-' : 'SUP-',
    isSupplier ? 'Client' : 'Supplier',
  );

  const handleSendMessage = () => {
    if (isMarketplace) {
      navigate('/messages');
    } else {
      createInquiryChannel.mutate(inquiry.id, {
        onSuccess: ({data}) => {
          const channelId = data.id;
          navigate(`/messages?${MESSAGE_QUERIES.channelId}=${channelId}`);
        },
      });
    }
  };

  const handleEditNote = (form, isCreate = false) => {
    const payload = {
      accompanying_message: form,
    };
    mutationUpdate(payload, {
      onSuccess: () => {
        notification.success({
          message: form
            ? isCreate
              ? 'Note to supplier(s) created successfully'
              : 'Note to supplier(s) updated successfully'
            : 'Note to supplier(s) deleted successfully',
        });
        setIsShowNote(false);
      },
      onError: (error) => {
        notification.error({message: error?.message});
      },
    });
  };

  const handleShowEdit = () => {
    setIsShowNote(!isShowNote);
  };

  const showCreateChannelButton = useMemo(() => {
    if (isMarketplace) return false;
    if (isSupplier) return true;
    if (isClient) {
      if (
        inquiry?.status === INQUIRY_STATUS.in_review ||
        inquiry?.status === INQUIRY_STATUS.responded
      ) {
        return true;
      }
      return !!(inquiry?.status === INQUIRY_STATUS.sent && channel);
    }
  }, [isMarketplace, isSupplier, isClient, inquiry?.status, channel]);

  if (isLoading) {
    return <AppLoader />;
  }

  return (
    <AppPageContainer
      header={
        <AppPageHeader
          backHeader
          header='inner'
          category={`${inquiryTitle} ${companyName}`}
          title={requisition?.jobTitle}
          count={
            <AppTag
              className={'tracking-normal'}
              shape={'circle'}
              color={inquiry?.tagColor}
              label={inquiry?.status}
            />
          }
          tabs={<AppDynamicTabs tabsData={tabsData} scroll />}
          extra={
            <>
              {showCreateChannelButton && (
                <>
                  <Tooltip
                    overlayStyle={{fontSize: '12px'}}
                    title={'Send message'}>
                    <Button
                      type='primary'
                      shape='circle'
                      onClick={handleSendMessage}
                      icon={<RiMessageLine fontSize={16} />}
                    />
                  </Tooltip>
                </>
              )}
              {showCreateChannelButton && !isNotes && (
                <Divider type='vertical' style={{height: 28}} />
              )}
              {isNotes || (
                <>
                  <Tooltip
                    overlayStyle={{fontSize: '12px'}}
                    title={'Add Internal Note'}>
                    <Button
                      type='primary'
                      shape='circle'
                      onClick={handleShowNotes}
                      icon={<NotesBtnIcon />}
                    />
                  </Tooltip>
                </>
              )}
            </>
          }
        />
      }>
      <MainContent
        handleShowEdit={handleShowEdit}
        inquiry={inquiry}
        isShowNote={isShowNote}
        requisition={requisition}
        isShowNotes={isShowNotes}
        notesTotal={total}
        notesResponse={response}
        handleEditNote={handleEditNote}
      />
    </AppPageContainer>
  );
};

export default ViewInquiry;
