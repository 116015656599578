import {convertHID} from 'app/utils/helpers/ConvertHID';
import {
  formatMinutes,
  formatTimeType,
} from 'app/utils/helpers/DataFormatHelper';

export const getColumns = () => {
  return [
    {
      title: 'Work Date',
      dataIndex: 'work_date',
      key: 'workDate',
      sorter: (a, b) => new Date(a.work_date) - new Date(b.work_date),
      defaultSortOrder: 'ascend',
      showSorterTooltip: false,
    },
    {
      title: 'Hours',
      dataIndex: 'minutes',
      key: 'hours',
      render: (hours) => formatMinutes(hours),
    },
    {
      title: 'Time Type',
      dataIndex: 'type',
      key: 'timeType',
      render: (timeType) => formatTimeType(timeType),
    },
    {
      title: 'Task ID',
      dataIndex: 'engagement_task',
      key: 'taskId',
      render: (_, {engagement_task}) => (
        <span>{convertHID(engagement_task.hid)}</span>
      ),
    },
    {
      title: 'Task Description',
      dataIndex: 'engagement_task',
      key: 'taskDescription',
      align: 'left',
      render: (task) => task.description,
    },
    {
      title: 'Task Notes',
      dataIndex: 'notes',
      key: 'taskNotes',
      align: 'left',
    },
  ];
};
