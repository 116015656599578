import RegionMultipleField from '@register-app/components/additional/region-multiple-field';
import AppSelect from '@register-app/components/controles/select';
import {INDUSTRY_FOCUS} from '@register-app/constants/industries-data';
import {JOB_FUNCTIONS} from '@register-app/constants/job-functions';
import {SERVICE_FOCUS} from '@register-app/constants/service-focus';
import {getSelectPlaceHolder} from '@wieldy/utils/helpers/MessageHelper';
import cn from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import {useFormContext} from 'react-hook-form';

import LocationHelper from '../../../app/utils/LocationHelper';

const ServiceRegionsFields = ({schemaArgs, isAdminPage}) => {
  const {isBoth, isService, isJob} = schemaArgs;
  const {watch} = useFormContext();

  const isCountry = watch('service_region_countries')?.includes(
    'united_states',
  );

  const industryFocus = Object.entries(INDUSTRY_FOCUS);
  const jobFunctions = Object.entries(JOB_FUNCTIONS);
  const servicesFocus = Object.entries(SERVICE_FOCUS);
  return (
    <div>
      <div
        className={cn('form-col mb-5', isAdminPage && isBoth && 'columns-3')}>
        {(isJob || isBoth) && (
          <RegionMultipleField
            name='specialization_job_functions'
            data={jobFunctions}
            label='Job Functions'
          />
        )}
        {(isService || isBoth) && (
          <RegionMultipleField
            name='specialization_service_focuses'
            data={servicesFocus}
            label='Service Focus'
          />
        )}
        <RegionMultipleField
          name='specialization_industry_focuses'
          data={industryFocus}
          label='Industry Focus'
        />
      </div>
      <div className='form-col columns-3'>
        <AppSelect
          name='service_region_countries'
          mode='multiple'
          isRequired
          label='Service Regions'
          options={LocationHelper.getCountries()}
          placeholder={getSelectPlaceHolder('country')}
          showSearch={true}
        />
        {isCountry && (
          <AppSelect
            label='Service Areas in the United States'
            name='service_region_us_states'
            mode='multiple'
            options={LocationHelper.getUsStates()}
            placeholder={getSelectPlaceHolder('state')}
            showSearch={true}
          />
        )}
      </div>
    </div>
  );
};

ServiceRegionsFields.propTypes = {
  schemaArgs: PropTypes.object,
  isAdminPage: PropTypes.bool,
};
export default ServiceRegionsFields;
