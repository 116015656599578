import data from '@emoji-mart/data';
import {init, SearchIndex} from 'emoji-mart';
import PropTypes from 'prop-types';
import {Channel, SimpleReactionsList} from 'stream-chat-react';

import {EmptyChannel} from '../EmptyChannel/EmptyChannel';
import {ThreadHeader} from '../TeamChannelHeader/ThreadHeader';
import {TeamMessage} from '../TeamMessage/TeamMessage';
import {TeamMessageInput} from '../TeamMessageInput/TeamMessageInput';
import {TeamTypingIndicator} from '../TeamTypingIndicator/TeamTypingIndicator';
import {ChannelInner} from './ChannelInner';

const LoadingIndicator = () => null;

init({data});

export const ChannelContainer = ({toggleMobile}) => {
  return (
    <div className='channel__container'>
      <Channel
        EmptyStateIndicator={EmptyChannel}
        LoadingIndicator={LoadingIndicator}
        Input={TeamMessageInput}
        Message={TeamMessage}
        ReactionsList={SimpleReactionsList}
        ThreadHeader={ThreadHeader}
        TypingIndicator={TeamTypingIndicator}
        emojiSearchIndex={SearchIndex}>
        <ChannelInner toggleMobile={toggleMobile} />
      </Channel>
    </div>
  );
};

ChannelContainer.propTypes = {
  toggleMobile: PropTypes.func,
};
