import AppCard from '@wieldy/components/AppCard';
import AppCountersList from '@wieldy/components/AppCountersList';
import AppCounterBlock from '@wieldy/components/AppCountersList/AppCounterBlock';
import AppLabels from '@wieldy/components/AppLabels/AppLabels';
import AppRevealCard from '@wieldy/components/AppRevealCard';
import AppTag from '@wieldy/components/AppTag/AppTag';
import {Col, List, Row, Typography} from 'antd';
import {APPLICANT_TYPE, BASE_SALARY_PERIOD, JOB_STATUSES} from 'app/constants';
import {useAccountType} from 'app/hooks/useAccountType';
import SupplierModal from 'app/modals/SupplierModal';
import {useAddBookmarkToResource} from 'app/services/resourceService';
import {getUserName} from 'app/utils/helpers/ConvertHID';
import PropTypes from 'prop-types';
import React, {useEffect, useMemo, useState} from 'react';
import {AiOutlineFileDone} from 'react-icons/ai';
import {RiCalendarEventLine, RiProfileLine} from 'react-icons/ri';
import {useNavigate} from 'react-router-dom';

import {formatBillingRateRage} from '../../../utils/helpers/DataFormatHelper';
import Bookmark from '../../components/Bookmark';
import {SupplierCard} from '../../supplier';
import RevealCardFooter from '../partials/RevealCardFooter';
import RequisitionCardDetail from '../RequisitionCardDetail/RequisitionCardDetail';
import AvailabilityBox from './AvailabilityBox';
import CandidateCardHeader from './CandidateCardHeader';

const CandidateCardContent = ({
  candidate,
  children,
  onClick,
  isResource,
  isApplicant,
  isCandidate,
  isClient,
}) => {
  const colSpan = isApplicant ? 12 : 8;

  const workStatus = useMemo(() => {
    if (isApplicant) {
      return `${APPLICANT_TYPE[candidate?.type]} | ${candidate?.work_status}`;
    }

    const currentWorkStatus =
      JOB_STATUSES[candidate?.work_status] || candidate?.work_status || '';

    if (!isResource) {
      return currentWorkStatus
        ? `Temporary | ${currentWorkStatus}`
        : 'Temporary';
    }

    return currentWorkStatus;
  }, [isApplicant, candidate, isResource]);

  const amount = useMemo(() => {
    return candidate.isPermanent
      ? formatBillingRateRage(
          candidate.base_salary,
          candidate.base_salary_min,
          candidate.base_salary_max,
          false,
        )
      : candidate.billingRate;
  }, [candidate]);

  return (
    <AppCard>
      <CandidateCardHeader candidate={candidate} onClick={onClick} />
      <AppCountersList className='mb-6'>
        <Col span={colSpan} className='text-center'>
          <AppCounterBlock
            name='Years of Experience'
            counter={candidate?.years_experience || 0}
          />
        </Col>
        {!isApplicant && (
          <Col span={colSpan} className='text-center'>
            <AppCounterBlock
              name='Past Assignments'
              counter={candidate?.completed_engagements || 0}
            />
          </Col>
        )}
        <Col span={colSpan} className='text-center'>
          <AppCounterBlock
            name={
              candidate.isPermanent
                ? `${
                    BASE_SALARY_PERIOD[candidate.base_salary_period]
                  } Base Salary`
                : 'Hourly Billing Rate'
            }
            counter={amount}
          />
        </Col>
      </AppCountersList>
      <List className='supplier-list mb-6' itemLayout='horizontal'>
        <List.Item className='py-1 border-none'>
          <List.Item.Meta
            avatar={
              <RiProfileLine fontSize={24} className={'text-secondary'} />
            }
            title={candidate?.title || ''}
          />
        </List.Item>
        <List.Item
          className='py-1 border-none'
          style={{marginBottom: candidate?.skills ? 0 : 67}}>
          <List.Item.Meta
            avatar={
              <RiCalendarEventLine fontSize={24} className={'text-secondary'} />
            }
            title={workStatus}
          />
        </List.Item>
        {candidate?.skills && (
          <List.Item className='py-1 border-none'>
            <List.Item.Meta
              avatar={
                <AiOutlineFileDone fontSize={24} className={'text-secondary'} />
              }
              title={
                <AppLabels
                  items={candidate.skills.map((item) => item?.skill)}
                  visible={2}
                />
              }
            />
          </List.Item>
        )}
      </List>
      {(isResource || isApplicant) && !isCandidate ? (
        <AvailabilityBox candidate={candidate} />
      ) : (
        <>
          <Row justify={'space-between'} align={'middle'} className='mb-6'>
            <Typography.Title level={5} type={'secondary'} className={'title'}>
              {isApplicant && !isCandidate ? 'Availability' : 'Status'}
              <span className='title-divider' style={{maxWidth: 60}} />
            </Typography.Title>
            {!!candidate?.availability && (
              <AppTag color='blue' shape={'circle'} className={'text-xs'}>
                {isApplicant && !isCandidate
                  ? candidate.availabilityName
                  : candidate?.status}
              </AppTag>
            )}
          </Row>
          {candidate?.requisition && (
            <RequisitionCardDetail
              requisition={candidate?.requisition}
              hideMarginBottom={!isClient}
            />
          )}
        </>
      )}
      {candidate?.bottomCounters && (
        <AppCountersList data={candidate.bottomCounters} className={'mb-0'} />
      )}
      {children}
    </AppCard>
  );
};

CandidateCardContent.propTypes = {
  candidate: PropTypes.object,
  children: PropTypes.node,
  onClick: PropTypes.func,
  isResource: PropTypes.bool,
  isCandidate: PropTypes.bool,
  isApplicant: PropTypes.bool,
  isClient: PropTypes.bool,
};

const CandidateCard = ({
  candidate,
  isResource,
  isApplicant,
  showBookmark,
  isCandidate,
}) => {
  const [revealed, setReveal] = useState(false);
  const {isClient} = useAccountType();
  const navigate = useNavigate();

  useEffect(() => {
    if (candidate) {
      setReveal(false);
    }
  }, [candidate]);

  const mutation = useAddBookmarkToResource();

  const handleOnBookmark = React.useCallback(() => {
    mutation.mutate({
      resource: candidate?.id,
      isBookmarked: candidate?.bookmarked,
    });
  }, [mutation, candidate?.id, candidate?.bookmarked]);

  const handleOnClick = () => {
    let redirect = `/candidates/${candidate?.id}/view-candidate`;
    if (isResource) {
      redirect = `/resources/${candidate?.id}/view-resource`;
    }
    if (isApplicant && !isCandidate) {
      redirect = `/applicants/${candidate?.resource.id}/view-applicant`;
    }

    navigate(redirect);
  };

  const {supplier, ...includes} = candidate;

  const name = getUserName(supplier?.name, 'SUP-', 'Supplier');

  if (isClient) {
    return (
      <AppRevealCard
        title='Supplier Details'
        revealed={revealed}
        setReveal={setReveal}
        onClose={() => setReveal(false)}
        revealComponent={
          <SupplierCard
            supplier={new SupplierModal(supplier)}
            includes={includes}
          />
        }>
        <CandidateCardContent
          candidate={candidate}
          onClick={handleOnClick}
          isClient={isClient}
          isCandidate={isCandidate}
          isApplicant={isApplicant}
          isResource={isResource}>
          {showBookmark && (
            <Bookmark
              isBookmarked={candidate?.bookmarked}
              onClick={handleOnBookmark}
              loading={mutation.isLoading}
            />
          )}
          <RevealCardFooter
            subTitle='Supplier'
            link={`/suppliers/${supplier.id}/view-supplier`}
            title={name}
            rating={supplier.rating_total}
            reviews={supplier.rating_count}
            onClick={() => setReveal(true)}
          />
        </CandidateCardContent>
      </AppRevealCard>
    );
  }

  return (
    <CandidateCardContent
      candidate={candidate}
      onClick={handleOnClick}
      isResource={isResource}
      isApplicant={isApplicant}
      isCandidate={isCandidate}
    />
  );
};

CandidateCard.propTypes = {
  candidate: PropTypes.object,
  isResource: PropTypes.bool,
  isApplicant: PropTypes.bool,
  isCandidate: PropTypes.bool,
  showBookmark: PropTypes.bool,
};

export default CandidateCard;
