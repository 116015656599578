import AppCard from '@wieldy/components/AppCard';
import AppFormWrapper, {
  AppFormActions,
} from '@wieldy/components/AppFormWrapper';
import AppTable from '@wieldy/components/AppTable';
import {Button, Form} from 'antd';
import PropTypes from 'prop-types';
import React, {useCallback, useState} from 'react';
import {useSelector} from 'react-redux';

import {
  addSkill,
  editSkill,
  removeSkill,
} from '../../../../../../store/features/resource/resourceSlice';
import {useSubmitTableForm} from '../../../../resources/hooks/useSubmitTableForm';
import {getColumns} from './columns';
import RowFields from './FormRow';

const SkillsForm = ({onContinue}) => {
  const {skills} = useSelector(({resource}) => resource);
  const existSkills = skills.filter((element) => element.tag && element);

  const components = {
    body: {
      row: RowFields,
    },
  };

  const {
    form,
    isEnableAddButton,
    isSomeValue,
    isEdit,
    bulkActions,
    handleSubmit,
    handleResetForm,
    data,
  } = useSubmitTableForm(
    ['years_experience', 'expertise_level', 'tagId'],
    existSkills,
    (el) => ({
      years_experience: el.years_experience,
      expertise_level: el.expertise_level,
      last_used: el.last_used,
      id: el.id,
      tempId: el.tempId,
      name: el.tag?.name,
      tagId: el.tag?.id,
    }),
    removeSkill,
  );

  const defOptions = existSkills.filter((tag) => ({
    name: tag.name,
    id: tag.id,
  }));

  const [options, setOptions] = useState([]);

  const handleSetOptions = useCallback((options) => {
    setOptions(options);
  }, []);

  const handleSubmitSkill = async (values) => {
    const {tagId, ...rest} = values;
    const element = options.find((el) => el.id === tagId);
    const commonData = {
      ...rest,
      tag: {
        name: element?.name || tagId?.label,
        id: element?.id || tagId?.value,
      },
    };
    handleSubmit(commonData, addSkill, editSkill);
  };

  const handleContinue = () => {
    const values = form.getFieldsValue();
    if (Object.values(values).every((el) => !!el)) {
      form.submit();
    }
    onContinue();
  };

  return (
    <AppCard>
      <AppFormWrapper>
        <RowFields skills={data} bulkActions={bulkActions} />
        <Form
          form={form}
          className='mt-2'
          layout='vertical'
          onFinish={handleSubmitSkill}
          autoComplete='off'>
          <AppTable
            locale={{
              emptyText: <div></div>,
            }}
            scroll={{x: 'scroll'}}
            bulkActions={bulkActions}
            className={'column-valign-top'}
            columns={getColumns()}
            dataSource={[...data, {id: -1}]}
            pagination={false}
            components={components}
            onRow={(record, rowIndex) => ({
              last: rowIndex === [...data, {id: -1}].length - 1,
              handleSetOptions,
              options,
              excludeOptions: defOptions,
            })}
          />
          <AppFormActions className='d-flex mt-4 mb-4'>
            <Button
              ghost
              type='primary'
              htmlType='submit'
              className='mr-2'
              disabled={!isEnableAddButton}>
              {isEdit ? 'Edit Skill' : 'Add Skill'}
            </Button>
            {isSomeValue && (
              <Button
                ghost
                className='ml-auto'
                type='primary'
                onClick={handleResetForm}
                htmlType='button'>
                Cancel
              </Button>
            )}
          </AppFormActions>
          <Button type='primary' htmlType='button' onClick={handleContinue}>
            Continue
          </Button>
        </Form>
      </AppFormWrapper>
    </AppCard>
  );
};

SkillsForm.propTypes = {
  onContinue: PropTypes.func,
  onSave: PropTypes.func,
};
export default SkillsForm;
