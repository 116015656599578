import AppBorderBox from '@wieldy/components/AppBorderBox';
import {Typography} from 'antd';
import {formatTableDate} from 'app/utils/helpers/DataFormatHelper.js';
import PropTypes from 'prop-types';
import React from 'react';
import {useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';

const WeekEndDetail = () => {
  const {pathname} = useLocation();
  const isExpensesPage = pathname.startsWith('/expenses');
  const select = isExpensesPage ? 'expenses' : 'timecards';
  const {end_date} = useSelector((state) => state[select].payload);
  const dateFormated = formatTableDate(end_date);
  return (
    <AppBorderBox
      className='d-inline-flex px-6 mt-3'
      style={{backgroundColor: '#FFF'}}>
      <Typography.Title level={4} className={'mb-0'}>
        {dateFormated}
      </Typography.Title>
    </AppBorderBox>
  );
};

WeekEndDetail.propTypes = {
  date: PropTypes.any,
};

export default WeekEndDetail;
