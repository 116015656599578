import AppCard from '@wieldy/components/AppCard';
import AppHeaderPagination from '@wieldy/components/AppPagination/AppHeaderPagination';
import AppTable from '@wieldy/components/AppTable';
import AppTag from '@wieldy/components/AppTag/AppTag';
import {getTablePagination} from '@wieldy/utils/helpers/TableHelper';
import {useTableSort} from 'app/hooks/useTableSort';
import {useGetEngagements} from 'app/services/engagementsService.js';
import {getColumns} from 'app/shared/components/Tables/Engagements/columns';
import PropTypes from 'prop-types';
import React, {useCallback, useEffect, useMemo, useState} from 'react';

const Engagements = ({
  defaultQueryParams,
  handleSetTabView,
  isShow,
  isPagination,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [sortBy, setSortBy] = useState('alpha');

  const {sort, handleSort} = useTableSort({
    ['start_date']: 'ascend',
  });

  const onChangeTable = useCallback(
    (a, b, sorter) => {
      if (sorter.columnKey && sorter?.order) {
        handleSort(sorter.columnKey, sorter?.order);
      }
    },
    [handleSort],
  );

  const {isLoading, data} = useGetEngagements({
    page: currentPage - 1,
    limit: pageSize,
    ...defaultQueryParams,
    sort,
    expand: [...defaultQueryParams.expand, 'requisition'],
  });

  const {engagements, total_count} = data || {engagements: [], total_count: 0};
  useEffect(() => {
    if (!isLoading && handleSetTabView) {
      handleSetTabView('isEngagements', !!engagements.length);
    }
  }, [engagements, isLoading, handleSetTabView]);

  const isShowPagination = useMemo(
    () => total_count > pageSize,
    [pageSize, total_count],
  );

  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const onShowSizeChange = (current, pageSize) => {
    setCurrentPage(current);
    setPageSize(pageSize);
  };
  if (!isShow) {
    return <></>;
  }

  return (
    <AppCard
      title={
        <React.Fragment>
          Engagements <AppTag className={'ml-1'}>{total_count}</AppTag>
        </React.Fragment>
      }
      headStyle={{borderBottom: 0}}
      bodyStyle={{paddingTop: 8}}
      extra={
        (isShowPagination || isPagination) && (
          <AppHeaderPagination
            total={total_count}
            current={currentPage}
            pageSize={pageSize}
            onChange={onPageChange}
            hideStartBorder
            sortByOptions={[
              {label: 'Alphabetically', value: 'alpha'},
              {label: 'Created Date', value: 'date'},
              {label: 'Created By', value: 'owner'},
            ]}
            sortBy={sortBy}
            onSortByChange={setSortBy}
          />
        )
      }>
      <AppTable
        onChange={onChangeTable}
        loading={isLoading}
        columns={getColumns()}
        dataSource={engagements}
        pagination={getTablePagination(
          pageSize,
          currentPage,
          total_count,
          onShowSizeChange,
          onPageChange,
        )}
      />
    </AppCard>
  );
};

Engagements.propTypes = {
  defaultQueryParams: PropTypes.object,
  handleSetTabView: PropTypes.func,
  isShow: PropTypes.bool,
  isPagination: PropTypes.bool,
};

Engagements.defaultProps = {
  defaultQueryParams: {},
  isShow: false,
  isPagination: false,
};

export default Engagements;
