import {RECRUITMENT_FEE_STRUCTURE_KEYS} from './requisition-direct-hire-fields';

const RECRUITMENT_FEE_OPTIONS = [
  {
    label: 'Percentage of Salary',
    value: RECRUITMENT_FEE_STRUCTURE_KEYS.percentage_of_salary,
  },
  {label: 'Fixed Fee', value: RECRUITMENT_FEE_STRUCTURE_KEYS.fixed_fee},
];

export default RECRUITMENT_FEE_OPTIONS;
