import AppCard from '@wieldy/components/AppCard';
import AppGridContainer from '@wieldy/components/AppGridContainer';
import {Col, message} from 'antd';
import propTypes from 'prop-types';

import ApproveRejectActions from './ApproveRejectActions';
import EntriesIndividualTable from './EntriesIndividualTable';
import Overview from './Overview';

const MainContent = ({expense, items}) => {
  const handleApprove = () => {
    message.success(`Expense ${expense?.id} approved successfully`);
  };

  const handleReject = (reason) => {
    setTimeout(() => {
      message.success(
        `Expense ${expense?.id} reject successfully: ${reason.label}`,
      );
    }, 500);
  };

  return (
    <AppGridContainer>
      <Col xs={24}>
        <AppCard
          title='Summary'
          headStyle={{borderBottom: 0}}
          bodyStyle={{paddingTop: 8}}>
          {!!expense && <Overview expense={expense} />}

          {expense?.status === 'pending' && (
            <ApproveRejectActions
              onApprove={handleApprove}
              onReject={handleReject}
            />
          )}
        </AppCard>
      </Col>
      <Col xs={24} id='individual-expense'>
        <AppCard
          title='Individual Expense Entries'
          headStyle={{borderBottom: 0}}
          bodyStyle={{paddingTop: 8}}>
          {!!items && <EntriesIndividualTable items={items} />}
        </AppCard>
      </Col>
    </AppGridContainer>
  );
};

MainContent.propTypes = {
  expense: propTypes.object,
  items: propTypes.array,
};

export default MainContent;
