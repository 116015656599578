import baseAxios from 'axios';

export const axios0 = baseAxios.create({
  baseURL: `https://${process.env.REACT_APP_AUTH0_DOMAIN}`,
  headers: {
    'Content-Type': 'application/json',
  },
});

const axios = baseAxios.create({
  baseURL: process.env.REACT_APP_BASE_URL ?? 'https://api.dev.sourcer.com/v1',
  headers: {
    'Content-Type': 'application/json',
  },
});

export const axiosHeaderParams = baseAxios.create({
  baseURL: process.env.REACT_APP_BASE_URL ?? 'https://api.dev.sourcer.com/v1',
  headers: {
    'Content-Type': 'application/json',
  },
});

export const axiosS3 = baseAxios.create({
  baseURL:
    process.env.REACT_APP_S3_URL ??
    'https://sourcer-staging-public-files.s3.amazonaws.com',
  headers: {
    'Content-Type': 'application/json',
  },
});

export const setRequestHeader = (key, value) => {
  if (key && value) {
    axios.defaults.headers.common[key] = value;
  }
};

export const resetRequestHeader = (key) => {
  if (key) {
    const headers = axios.defaults.headers.common;
    delete headers[key];
    axios.defaults.headers.common = headers;
  }
};

export const getRequestHeaders = () => {
  return axios.defaults.headers.common;
};

export const setRequestHeaders = (headers = {}) => {
  if (typeof headers !== 'object') {
    return false;
  }
  Object.keys(headers).forEach((headerKey) => {
    const headerValue = headers[headerKey];
    if (headerValue) axios.defaults.headers.common[headerKey] = headerValue;
  });
  return true;
};

export const setHeaderAuthorizationToken = (token) => {
  if (token) {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    axiosHeaderParams.defaults.headers.common['Authorization'] =
      'Bearer ' + token;
    axiosHeaderParams.defaults.headers.common['Authorization'] =
      'Bearer ' + token;
    localStorage.setItem('access-token', token);
  } else {
    delete axios.defaults.headers.common['Authorization'];
    delete axios.defaults.headers.common['Authorization'];
    delete axiosHeaderParams.defaults.headers.common['Authorization'];
    delete axiosHeaderParams.defaults.headers.common['Authorization'];
    localStorage.removeItem('access-token');
  }
};
export default axios;
