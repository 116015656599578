import React from 'react';

import useStep from '../../../../../../hooks/useStep';
import BillingAndEnvironmentDetails from './BillingAndEnvironmentDetails';
import BillingAndEnvironmentForm from './BillingAndEnvironmentForm';

const BillingAndEnvironment = () => {
  const {nextStep, setStepData, stepData, currentStep} = useStep();
  return (
    <>
      {currentStep > 2 && stepData ? (
        <BillingAndEnvironmentDetails />
      ) : (
        currentStep === 2 && (
          <BillingAndEnvironmentForm
            onContinue={nextStep}
            onSave={setStepData}
          />
        )
      )}
    </>
  );
};

export default BillingAndEnvironment;
