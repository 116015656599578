import PropTypes from 'prop-types';
import React from 'react';

import {PageContext} from './PageContext';

const PageContentProvider = ({children, ...restProps}) => {
  return (
    <PageContext.Provider value={restProps}>{children}</PageContext.Provider>
  );
};

PageContentProvider.propTypes = {
  children: PropTypes.node,
  role: PropTypes.oneOf(['supplier', 'client', 'manager']),
};

export default PageContentProvider;
