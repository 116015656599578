import {
  CreateExpense,
  default as Expenses,
  EditExpense,
  ViewExpense,
} from 'app/pages/main/expenses/index';

export const supplier_ExpenseRoutes = [
  {
    path: 'expenses',
    element: <Expenses />,
  },
  {
    path: '/expenses/add-expense',
    element: <CreateExpense />,
  },
  {
    path: '/expenses/edit-expense/:id',
    element: <EditExpense />,
  },
  {
    path: 'expenses/:category',
    element: <Expenses />,
  },
  {
    path: 'expenses/:id/:tab',
    element: <ViewExpense />,
  },
];

export const client_ExpenseRoutes = [
  {
    path: 'expenses',
    element: <Expenses />,
  },
  {
    path: 'expenses/:category',
    element: <Expenses />,
  },
  {
    path: 'expenses/:id/:tab',
    element: <ViewExpense />,
  },
];
