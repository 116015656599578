import AppFormWrapper, {
  AppFormActions,
} from '@wieldy/components/AppFormWrapper';
import {Button, Form} from 'antd';
import PropTypes from 'prop-types';
import React, {useEffect} from 'react';

import {
  addSupplemental,
  editSupplemental,
  removeSupplemental,
} from '../../../../../../../store/features/resource/resourceSlice';
import {useSubmitTableForm} from '../../../../../resources/hooks/useSubmitTableForm';
import SupplementalTable from '../Tables';
import TableWrapper from '../TableWrapper';

const TableContainer = ({
  payload,
  title,
  fields,
  tableType,
  getMapped = undefined,
  buttonText = '',
  Component,
  getForm = () => {},
}) => {
  const {
    data,
    bulkActions,
    isEnableAddButton,
    form,
    isEdit,
    handleResetForm,
    handleSubmit,
    isSomeValue,
  } = useSubmitTableForm(
    fields,
    payload,
    getMapped,
    removeSupplemental,
    tableType,
  );

  const handleSubmitForm = (values) => {
    handleSubmit(values, addSupplemental, editSupplemental);
  };

  useEffect(() => {
    getForm(form, tableType);
  }, [form, getForm, tableType]);

  return (
    <>
      <AppFormWrapper>
        <Form
          form={form}
          className='mt-2'
          layout='vertical'
          onFinish={handleSubmitForm}
          autoComplete='off'
          initialValues={{}}>
          <TableWrapper title={title}>
            <SupplementalTable
              data={[...data, {id: -1}]}
              tableType={tableType}
              bulkActions={bulkActions}
              onRow={(record, rowIndex) => ({
                last: rowIndex === [...data, {id: -1}].length - 1,
              })}
              components={{
                body: {
                  row: Component,
                },
              }}
            />
            <AppFormActions className='d-flex mt-4'>
              <Button
                ghost
                type='primary'
                htmlType='submit'
                disabled={!isEnableAddButton}>
                {isEdit ? 'Edit' : 'Add'} {buttonText}
              </Button>
              {isSomeValue && (
                <Button
                  ghost
                  type='primary'
                  onClick={handleResetForm}
                  htmlType='button'
                  className='ml-auto'>
                  Cancel
                </Button>
              )}
            </AppFormActions>
          </TableWrapper>
        </Form>
      </AppFormWrapper>
    </>
  );
};

TableContainer.propTypes = {
  Component: PropTypes.func.isRequired,
  payload: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  buttonText: PropTypes.string,
  tableType: PropTypes.oneOf([
    'educations',
    'courses',
    'certifications',
    'languages',
  ]).isRequired,
  getMapped: PropTypes.func,
  fields: PropTypes.arrayOf(PropTypes.string),
  addAction: PropTypes.func,
  editAction: PropTypes.func,
  getForm: PropTypes.func,
};

export default TableContainer;
