import AppGridView from '@wieldy/components/AppGridView';
import AppLoader from '@wieldy/components/AppLoader';
import AppPageSection from '@wieldy/components/pageComponents/AppPageSection';
import {capitalizedAllWords} from '@wieldy/utils/helpers/StringHelper';
import {Empty} from 'antd';
import {useGetAllApplicants} from 'app/services/applicantService';
import ViewAllButton from 'app/shared/buttons/ViewAllButton';
import CandidateCard from 'app/shared/cards/CandidateCard';
import React from 'react';

import ApplicantModal from '../../../../modals/ApplicantModal';
import {queryParams, statuses} from './constants';

const All = () => {
  const {isLoading, data: allData} = useGetAllApplicants(queryParams, statuses);

  if (isLoading) {
    return <AppLoader />;
  }

  if (allData?.every(({resources}) => !resources.length)) {
    return <Empty description='No Applicants' />;
  }

  return (
    !!allData?.length &&
    allData.map(
      ({resources, total_count, status}) =>
        !!resources.length && (
          <AppPageSection
            key={status}
            title={`${capitalizedAllWords(
              status.replace('_', ' '),
            )} Applicants`}
            count={total_count}
            extra={
              total_count > 6 && (
                <ViewAllButton category={status} path='applicants' />
              )
            }>
            <AppGridView
              isAllPages={true}
              data={resources.slice(0, 6)}
              renderItem={(resource) => (
                <CandidateCard
                  key={resource.id}
                  candidate={new ApplicantModal(resource)}
                  isApplicant
                />
              )}
            />
          </AppPageSection>
        ),
    )
  );
};

export default All;
