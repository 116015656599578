export default {
  VIEW: {
    include: [
      'active_clients',
      'completed_engagements',
      'available_resources',
      'inquiries',
      'submissions',
      'engagements',
      'relation_status',
      'bookmarked',
    ],
    expand: ['company_representative', 'account_manager'],
  },
};
