import AppCard from '@wieldy/components/AppCard';
import {Button, notification, Typography} from 'antd';
import {useAccountType} from 'app/hooks/useAccountType';
import {useUpdateClient} from 'app/services/clientService';
import {useUpdateSupplier} from 'app/services/supplierService';
import BillingAddressInfo, {
  BillingAddressForm,
} from 'app/shared/company/BillingAddress';
import {
  updateCurrentUser,
  useCurrentUser,
} from 'app/store/features/user/userSlice';
import React, {useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';

const getPhoneNumber = (companyProfile) => {
  const phoneNumber = companyProfile?.billing_address?.phone_number;
  if (!phoneNumber) return '';
  return phoneNumber?.startsWith('+') ? phoneNumber : `+${phoneNumber}`;
};

const BillingAddress = () => {
  const dispatch = useDispatch();
  const [showEditForm, setShowEditForm] = React.useState(false);
  const {isSupplier} = useAccountType();
  const mutation = (isSupplier ? useUpdateSupplier : useUpdateClient)();
  const {companyModal} = useSelector(({user}) => user);
  const companyProfile = useCurrentUser();
  const initialState = useMemo(
    () => ({
      ...companyProfile?.billing_address,
      phone_number: getPhoneNumber(companyProfile),
      invoicing_period: companyProfile?.invoicing_period,
    }),
    [companyProfile],
  );

  const handleUpdateAddress = (formData) => {
    const idName = isSupplier ? 'supplierId' : 'clientId';
    const {state, invoicing_period, ...restData} = formData;
    mutation.mutate(
      {
        payload: {
          billing_address: {
            ...restData,
            state: state ?? null,
          },
          invoicing_period,
        },
        [idName]: companyModal.id,
      },
      {
        onSuccess: (data) => {
          dispatch(updateCurrentUser(data));
          setShowEditForm(false);
          notification.success({
            message: 'Billing address updated successfully',
          });
        },
      },
    );
  };

  const handleOnCancel = () => {
    setShowEditForm((prev) => !prev);
  };

  return (
    <AppCard>
      <Typography.Title level={3} className={'mb-3'}>
        Billing Information
      </Typography.Title>
      {showEditForm ? (
        <BillingAddressForm
          isLoading={mutation.isLoading}
          onSubmit={handleUpdateAddress}
          onCancel={handleOnCancel}
          initialValues={initialState}
        />
      ) : (
        <BillingAddressInfo address={companyModal?.billingAddress}>
          <Button type='primary' onClick={handleOnCancel}>
            Edit Billing Information
          </Button>
        </BillingAddressInfo>
      )}
    </AppCard>
  );
};

export default BillingAddress;
