import {useCallback, useEffect, useState} from 'react';

export const useBrowse = ({
  initCurrentPage = 1,
  initPageSize = 10,
  initSort = '+first_name',
  isReset = null,
} = {}) => {
  const [currentPage, setCurrentPage] = useState(initCurrentPage);
  const [pageSize, setPageSize] = useState(initPageSize);
  const [openFilter, setOpenFilter] = useState(false);
  const [sortBy, setSortBy] = useState(initSort);

  const isEnabled = sortBy.slice(1).includes(initSort.slice(1));

  useEffect(() => {
    setCurrentPage(initCurrentPage);
  }, [initCurrentPage, isReset, pageSize]);

  useEffect(() => {
    setSortBy(initSort);
  }, [initSort]);

  const onSortBy = useCallback((value) => {
    setSortBy(value);
  }, []);

  const onChangePage = useCallback((pageNumber) => {
    setCurrentPage(pageNumber);
  }, []);

  const onShowSizeChange = useCallback((current, pageSize) => {
    setCurrentPage(current);
    setPageSize(pageSize);
  }, []);

  const onToggleFilter = useCallback((bool) => {
    if (typeof bool === 'boolean') {
      setOpenFilter(bool);
    } else {
      setOpenFilter((prev) => !prev);
    }
  }, []);

  return {
    currentPage,
    pageSize,
    openFilter,
    sortBy,
    onChangePage,
    onShowSizeChange,
    onToggleFilter,
    onSortBy,
    isEnabled,
  };
};
