export const BASE_SALARY_PERIOD = {
  annually: 'Annual',
};

export const RECRUITMENT_FEE_STRUCTURE = {
  percentage_of_salary: 'Percentage of salary',
  fixed_fee: 'Fixed fee',
};

export const RECRUITMENT_FEE_STRUCTURE_KEYS = {
  percentage_of_salary: 'percentage_of_salary',
  fixed_fee: 'fixed_fee',
};

export const GUARANTEE_PERIOD = {
  no_period: 'No guarantee period',
  '30_days': '30 days',
  '60_days': '60 days',
  '90_days': '90 days',
};

export const GUARANTEE_PERIOD_VALUES = {
  '30_days': 30,
  '60_days': 60,
  '90_days': 90,
};

export const GUARANTEE_PERIOD_ACTION = {
  candidate_replacement: 'Candidate replacement',
  fee_refund: 'Fee refund',
};
