import {
  getRequestHeaders,
  resetRequestHeader,
  setRequestHeader,
} from 'app/config/axios';
import {useAccountType} from 'app/hooks/useAccountType';
import {useEffect} from 'react';
import {useSelector} from 'react-redux';

export const useInvoiceHeaders = () => {
  const {isSupplier, isClient, isMarketplace} = useAccountType();
  const userRole = isSupplier ? 'supplier' : isClient ? 'client' : undefined;
  const {id} = useSelector(({user}) => user.user);
  const userRoleId = useSelector(
    ({user}) => user.currentAccount?.[userRole]?.id,
  );

  useEffect(() => {
    if (isMarketplace) {
      const headers = getRequestHeaders();
      'sourcer-client' in headers && resetRequestHeader('sourcer-client');
      'sourcer-supplier' in headers && resetRequestHeader('sourcer-supplier');
    }
  }, [isMarketplace]);

  useEffect(() => {
    // // Clean up the event listener when the component unmounts
    return () => {
      const local = localStorage.getItem('currentUser');
      const localHeader = local ? JSON.parse(local)[id] : null;
      if (localHeader) {
        isMarketplace && setRequestHeader(localHeader.header, localHeader.id);
      }
    };
  }, [id, isMarketplace]);

  return userRole && {[userRole]: isMarketplace ? userRoleId : undefined};
};
