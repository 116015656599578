import AppCard from '@wieldy/components/AppCard';
import {Typography} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import {useSelector} from 'react-redux';

import {
  GUARANTEE_PERIOD,
  GUARANTEE_PERIOD_ACTION,
  RECRUITMENT_FEE_STRUCTURE,
  RECRUITMENT_FEE_STRUCTURE_KEYS,
} from '../../../../../../constants';
import {useAccountType} from '../../../../../../hooks/useAccountType';
import {useRole} from '../../../../../../hooks/useRole';
import {calcAmountByPercent} from '../../../../../../utils/helpers/CalcAmountByPercent';
import {formatBillingRateRage} from '../../../../../../utils/helpers/DataFormatHelper';

const PlacementDetailsView = () => {
  const {details} = useSelector(({placement}) => placement);
  const {isMarketplace} = useAccountType();
  const {isMarketManager, isAdmin} = useRole();
  const isShowButton = isMarketplace && (isMarketManager || isAdmin);

  const isFixed =
    details.recruitment_fee_structure ===
    RECRUITMENT_FEE_STRUCTURE_KEYS.fixed_fee;

  return (
    <AppCard style={{maxWidth: 620}}>
      {isShowButton && (
        <>
          <div className={'mb-5'}>
            <Typography.Title level={4}>Hiring Manager</Typography.Title>
            <Typography.Paragraph>
              {details.hiring_manager
                ? `${details.hiring_manager?.first_name || ''} ${
                    details.hiring_manager?.last_name || ''
                  }`
                : 'N/A'}
            </Typography.Paragraph>
          </div>
          <div className={'mb-5'}>
            <Typography.Title level={4}>Supplier Contact</Typography.Title>
            <Typography.Paragraph>
              {details.resource_manager?.first_name +
                ' ' +
                details.resource_manager?.last_name}
            </Typography.Paragraph>
          </div>
          <div className={'mb-5'}>
            <Typography.Title level={4}>Account Manager</Typography.Title>
            <Typography.Paragraph>
              {details.account_manager?.first_name +
                ' ' +
                details.account_manager?.last_name}
            </Typography.Paragraph>
          </div>
        </>
      )}
      <div className={'mb-5'}>
        <Typography.Title level={4}>Base Salary Offer</Typography.Title>
        <Typography.Paragraph>
          {formatBillingRateRage(details?.base_salary, null, null)}
        </Typography.Paragraph>
      </div>
      <div className={'mb-5'}>
        <Typography.Title level={4}>Job Start Date</Typography.Title>
        <Typography.Paragraph>
          {details?.start_date && details.start_date.format('MM/DD/YYYY')}
        </Typography.Paragraph>
      </div>
      <div className={'mb-5'}>
        <Typography.Title level={4}>Guarantee Period</Typography.Title>
        <Typography.Paragraph>
          {GUARANTEE_PERIOD[details.guarantee_period]}
        </Typography.Paragraph>
      </div>
      <div className={'mb-5'}>
        <Typography.Title level={4}>Guarantee Action</Typography.Title>
        <Typography.Paragraph>
          {GUARANTEE_PERIOD_ACTION[details.guarantee_period_action]}
        </Typography.Paragraph>
      </div>
      <div className={'mb-5'}>
        <Typography.Title level={4}>Recruitment Fee Structure</Typography.Title>
        <Typography.Paragraph>
          {isFixed
            ? RECRUITMENT_FEE_STRUCTURE.fixed_fee
            : `${details.recruitment_fee}% of salary`}
        </Typography.Paragraph>
      </div>
      <div className={'mb-5'}>
        <Typography.Title level={4}>Recruitment Fee</Typography.Title>
        <Typography.Paragraph>
          {isFixed
            ? formatBillingRateRage(details?.recruitment_fee, null, null)
            : formatBillingRateRage(
                calcAmountByPercent(
                  details?.base_salary,
                  details?.recruitment_fee,
                ),
                null,
                null,
              )}
        </Typography.Paragraph>
      </div>
    </AppCard>
  );
};

PlacementDetailsView.propTypes = {
  details: PropTypes.object,
};

export default PlacementDetailsView;
