import propTypes from 'prop-types';
import {QueryClient, QueryClientProvider} from 'react-query';
import {ReactQueryDevtools} from 'react-query/devtools';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 0,
    },
  },
});

const AppQueryClientProvider = ({children, devTools = false}) => {
  return (
    <QueryClientProvider client={queryClient}>
      {children}
      {devTools && <ReactQueryDevtools />}
    </QueryClientProvider>
  );
};

AppQueryClientProvider.propTypes = {
  children: propTypes.node,
  devTools: propTypes.bool,
};

export default AppQueryClientProvider;
