import AppPageContainer from '@wieldy/components/pageComponents/AppPageContainer';
import AppPageHeader from '@wieldy/components/pageComponents/AppPageHeader';
import {useAccountType} from 'app/hooks/useAccountType';
import React from 'react';

import MainContentClient from './MainContentClient';
import MainContentSupplier from './MainContentSupplier';

const Dashboard = () => {
  const {isSupplier} = useAccountType();

  return (
    <AppPageContainer
      header={<AppPageHeader category='Analytics' title='Reports' />}>
      {isSupplier ? <MainContentSupplier /> : <MainContentClient />}
    </AppPageContainer>
  );
};

export default Dashboard;
