import {Descriptions, Typography} from 'antd';
import {useAccountType} from 'app/hooks/useAccountType';
import {useStatus} from 'app/hooks/useStatus';
import PropTypes from 'prop-types';
import React from 'react';

const AboutInquiry = ({inquiry}) => {
  const {isSupplier} = useAccountType();
  const {aboutDates, hid, status_key, candidates} = inquiry;
  const {isResponded, isWithdrawn} = useStatus(status_key);
  return (
    <div className='mb-6'>
      <Typography.Title level={3} className={'mb-5'}>
        Inquiry
      </Typography.Title>

      <Descriptions
        colon={false}
        labelStyle={{width: '45%'}}
        className='description-root mb-4'
        column={{xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1}}>
        <Descriptions.Item label='# of Candidates'>
          {candidates?.length}
        </Descriptions.Item>
        <Descriptions.Item label='Inquiry ID' span={1}>
          {hid}
        </Descriptions.Item>
        <Descriptions.Item
          label={isSupplier ? 'Received' : 'Inquired'}
          span={1}>
          {aboutDates?.inquired}
        </Descriptions.Item>
        {(isResponded || isWithdrawn) && (
          <Descriptions.Item label='Updated' span={1}>
            {aboutDates?.updated}
          </Descriptions.Item>
        )}
      </Descriptions>
    </div>
  );
};

AboutInquiry.propTypes = {
  inquiry: PropTypes.object,
};

export default AboutInquiry;
