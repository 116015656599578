import axios from '../config/axios';

export const submissionAPI = {
  getSubmissions: async (params) => await axios.get('submissions', {params}),
  getSubmission: async (id, params) =>
    await axios.get(`submissions/${id}`, {params}),
  createSubmission: async (params) => await axios.post('submissions', params),
  updateSubmission: async (id, params) =>
    await axios.patch(`submissions/${id}`, params),
};
