export const ratingFields = [
  {
    name: 'communication',
    label: 'Communication',
  },
  {
    name: 'responsiveness',
    label: 'Responsiveness',
  },
  {
    name: 'reliability_and_consistency',
    label: 'Reliability and Consistency',
  },
  {
    name: 'engagement_and_followup',
    label: 'Engagement and Followup',
  },
  {
    name: 'resource_management',
    label: 'Resource Management',
  },
];
