import AppTextArea from '@wieldy/components/dataEntry/AppTextArea';
import {Button, Form, Space} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

const AddNoteForm = ({onSubmit, onCancel, message, btnText = 'Save Note'}) => {
  const [form] = Form.useForm();

  const handleOnFinish = (formData) => {
    if (onSubmit) {
      onSubmit(formData);
      form.resetFields();
    }
  };

  return (
    <Form
      form={form}
      onFinish={handleOnFinish}
      autoComplete='off'
      className={'mb-6'}>
      <Form.Item
        name='text'
        rules={[
          {
            required: true,
            message: '',
          },
        ]}
        className={'mb-2'}
        initialValue={message}>
        <AppTextArea
          placeholder='Type your note here'
          maxLength={4096}
          showCount
        />
      </Form.Item>
      <Space>
        <Button size={'small'} type='primary' htmlType='submit'>
          {btnText}
        </Button>
        {onCancel && (
          <Button size={'small'} type='secondary' onClick={onCancel}>
            Cancel
          </Button>
        )}
      </Space>
    </Form>
  );
};

AddNoteForm.propTypes = {
  message: PropTypes.string,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  btnText: PropTypes.string,
};

export default AddNoteForm;
