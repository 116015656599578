import {useMutation} from 'react-query';

import {timecardAPI} from '../api/timecardApi';
import axios from '../config/axios';
import useReactQuery, {useReactMutation} from '../hooks/useReactQuery';

const useGetTimeCards = (page, limit, status, sort, filter, isResource) => {
  const params = {
    include: [
      'total_count',
      'resource',
      'pay_units',
      'net_total',
      'invoice_author',
    ],
    expand: [
      'engagement',
      'submitted_by',
      'approved_by',
      'rejected_by',
      'invoice',
    ],
    'created_at[gte]': filter?.gte,
    'created_at[lte]': filter?.lte,
    page,
    limit,
    status,
    sort,
  };

  return useReactQuery(
    ['time-cards', page, limit, status, sort, filter, isResource],
    async () => {
      const {data} = await axios.get('/timecards', {params});
      return {
        items: data?.data ?? [],
        totalCount: data?.['total_count'] ?? 0,
      };
    },
    {
      keepPreviousData: true,
    },
  );
};

const useGetTimecardList = (params) => {
  return useReactQuery(['timecards', params], async () => {
    const {data} = await timecardAPI.getTimeCards(params);
    return {
      timecards: data?.data ?? [],
      total_count: data?.['total_count'] ?? 0,
    };
  });
};

const useGetTimeCards2 = (queryParams = {}, options = {}) => {
  const params = {
    ...queryParams,
  };
  return useReactQuery(
    ['timecards', params],
    async () => {
      const {data} = await axios.get('/timecards', {params});
      return {
        items: data?.data ?? [],
        total_count: data?.total_count ?? 0,
      };
    },
    options,
  );
};

const useGetTimeCard = (id) => {
  const params = {
    include: [
      'resource',
      'requisition',
      'pay_units',
      'net_total',
      'invoice',
      'invoice_author',
    ],
    expand: [
      'supplier',
      'created_by',
      'approved_by',
      'submitted_by',
      'rejected_by',
      'engagement',
      'invoice',
      'client',
    ],
  };

  const paramsEntriesByType = {
    expand: ['engagement_task'],
    group_type: 'type',
    timecard: id,
  };
  const paramsEntries = {
    expand: ['engagement_task'],
    timecard: id,
  };

  return useReactQuery(['time-card', id], async () => {
    const {data: timecard} = await axios.get(`/timecards/${id}`, {params});

    const {data: entriesByType} = await axios.get('timecard_items/groups', {
      params: paramsEntriesByType,
    });
    const {data: entries} = await axios.get(`/timecard_items`, {
      params: paramsEntries,
    });
    return {
      timecard,
      entries: entries?.data,
      entriesByType: entriesByType.data,
    };
  });
};
const useUpdateTimeCard = (id) => {
  return useReactMutation(async (payload) => {
    const {data} = await axios.patch(`/timecards/${id}`, payload);
    return data;
  });
};
const useDownloadTimeCard = () => {
  return useMutation({
    mutationFn: async ({id, params}) => {
      const data = await timecardAPI.getTimeCardPdf({
        id,
        options: {
          responseType: 'blob',
          headers: {
            'Content-Type': 'application/pdf',
          },
          params,
        },
      });

      return data;
    },
  });
};

export {
  useDownloadTimeCard,
  useGetTimeCard,
  useGetTimecardList,
  useGetTimeCards,
  useGetTimeCards2,
  useUpdateTimeCard,
};
