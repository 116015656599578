import {useQueryClient} from 'react-query';

import {requisitionInvitationAPI} from '../api/requisitionInvitationApi';
import useReactQuery, {useReactMutation} from '../hooks/useReactQuery';

export const useSendDeleteSupplierRequisitionInvitation = () => {
  const client = useQueryClient();
  return useReactMutation(async (requestData) => {
    const {data} =
      await requisitionInvitationAPI.sendDeleteSupplierRequisitionInvitation(
        requestData,
      );

    await client.invalidateQueries(['suppliers']);

    return {
      data,
      message: 'Requisition invitation created successfully',
    };
  });
};

export const useGetSupplierRequisitionInvitation = (
  requisitionId,
  options = {},
) => {
  return useReactQuery(
    ['getSupplierRequisitionInvitation', requisitionId],
    async () => {
      const {data} =
        await requisitionInvitationAPI.getSupplierRequisitionInvitation(
          requisitionId,
        );
      return {
        requisitionInvitation: data?.data ?? [],
        total_count: data?.total_count ?? 0,
      };
    },
    options,
  );
};

export const useAcceptDeclineSupplierRequisitionInvitation = () => {
  const client = useQueryClient();
  return useReactMutation(async (requestData) => {
    const {data} =
      await requisitionInvitationAPI.acceptDeclineSupplierRequisitionInvitation(
        requestData,
      );

    await client.invalidateQueries(['getSupplierRequisitionInvitation']);

    return {
      data,
      message: `Requisition invitation ${
        requestData.accepted ? 'accepted' : 'declined'
      } successfully`,
    };
  });
};
