import './index.style.less';

import {CaretDownOutlined, UserOutlined} from '@ant-design/icons';
import {useAuth0} from '@auth0/auth0-react';
import AppUserInfo from '@wieldy/components/AppUserInfo';
import {capitalizedAllWords} from '@wieldy/utils/helpers/StringHelper';
import {Avatar, Divider, message, Popover, Select} from 'antd';
import {USER_CREDENTIALS} from 'app/constants/user-creds';
import {useAccountType} from 'app/hooks/useAccountType';
import {useRole} from 'app/hooks/useRole';
import {
  setAccountType,
  setUserAccount,
  useCurrentUser,
} from 'app/store/features/user/userSlice';
import {fetchUser} from 'app/store/features/user/userThunks';
import cn from 'clsx';
import React, {useCallback, useMemo, useState} from 'react';
import {AiOutlineUserSwitch} from 'react-icons/ai';
import {
  RiAccountBoxLine,
  RiAccountCircleFill,
  RiAdminFill,
  RiLoginCircleLine,
} from 'react-icons/ri';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';

const UserInfo = () => {
  const dispatch = useDispatch();
  const {
    user: authUser,
    accounts,
    accountType,
    currentAccount,
    marketplaceTempId,
  } = useSelector(({user}) => user);
  const {logout} = useAuth0();
  const [visible, setVisible] = useState(false);
  const navigate = useNavigate();
  const {isMarketplace} = useAccountType();
  const {isMarketManager} = useRole();
  const currentUser = useCurrentUser();
  const authId = marketplaceTempId || currentUser.id;
  const userAvatar =
    authUser?.avatar_url || currentAccount?.resource?.avatar_url;

  const disable =
    typeof currentAccount[accountType]?.accepted_terms_and_conditions ===
      'boolean' &&
    currentAccount[accountType]?.accepted_terms_and_conditions === false;

  const menuItems = useMemo(
    () =>
      accounts.map((el) => {
        const resourceRole = USER_CREDENTIALS.USER_ROLES.resource;
        const isResource = el.role === resourceRole && resourceRole in el.data;
        return {
          header: el.header,
          accountType: el.accountType,
          key: isResource ? el.data[el.role]?.id : el.data[el.accountType]?.id,
          value: isResource
            ? el.data[el.role]?.id
            : el.data[el.accountType]?.id,
          role: capitalizedAllWords(el.role.replace('_', ' ')),
          label: capitalizedAllWords(el.accountType.replace('_', ' ')),
        };
      }),
    [accounts],
  );
  const onProfileUser = useCallback(() => {
    setVisible(false);
    navigate('/my-account');
  }, [navigate]);

  const onLogoutUser = useCallback(() => {
    localStorage.removeItem('invitationToken');
    localStorage.removeItem('invitationTarget');
    logout({logoutParams: {returnTo: window.location.origin}});
  }, [logout]);

  const handleOnAccountsView = useCallback(
    (path) => {
      return () => {
        setVisible(false);
        dispatch(setAccountType(USER_CREDENTIALS.USER_TYPES.marketplace));
        navigate(path);
      };
    },
    [dispatch, navigate],
  );

  const handleSwitchUser = useCallback(
    async (key) => {
      setVisible(false);
      const selected = menuItems.find((item) => item.key === key);
      if (authId === selected.key) {
        return;
      }
      dispatch(
        setUserAccount({
          header: selected.header,
          id: selected.key,
          accountType: selected.accountType,
        }),
      );
      dispatch(fetchUser());
      await message.success(`Switch to ${selected.label} successfully.`);
    },
    [authId, dispatch, menuItems],
  );

  const userProfileView = useCallback(() => {
    return (
      <div className='user-popover'>
        <AppUserInfo user={{...authUser, avatar_url: userAvatar}} />
        <Divider style={{margin: '16px -16px 12px', width: 'auto'}} />
        <ul className='ant-list-items'>
          {isMarketplace && (
            <li
              className='ant-list-item'
              key='account_dashboard'
              onClick={handleOnAccountsView('/accounts')}>
              <div className='ant-list-item-meta-avatar'>
                <RiAccountCircleFill fontSize={24} />
              </div>
              <div className='ant-list-item-meta-content'>
                Accounts Dashboard
              </div>
            </li>
          )}
          {isMarketplace && !isMarketManager && (
            <li
              className='ant-list-item'
              key='administration'
              onClick={handleOnAccountsView('/administration/users')}>
              <div className='ant-list-item-meta-avatar'>
                <RiAdminFill fontSize={24} />
              </div>
              <div className='ant-list-item-meta-content'>Administration</div>
            </li>
          )}
          <li
            className={cn('ant-list-item', disable && 'disabled')}
            key='my_profile'
            onClick={!disable ? onProfileUser : undefined}>
            <div className='ant-list-item-meta-avatar'>
              <RiAccountBoxLine fontSize={24} />
            </div>
            <div className='ant-list-item-meta-content'>My Profile</div>
          </li>
          {!!menuItems && menuItems.length > 1 && (
            <li className='ant-list-item' key='switch_user'>
              <div className='ant-list-item-meta-avatar'>
                <AiOutlineUserSwitch fontSize={24} />
              </div>
              <div className='ant-list-item-meta-content'>
                <Select
                  placeholder='User'
                  value={authId}
                  style={{width: '100%'}}
                  onSelect={(value) => {
                    if (value !== authId) {
                      handleSwitchUser(value);
                    }
                  }}>
                  {menuItems.map((user) => (
                    <Select.Option key={user.key} value={user.key}>
                      <div>
                        <h4>{user.label}</h4>
                        <span>{user.role}</span>
                      </div>
                    </Select.Option>
                  ))}
                </Select>
              </div>
            </li>
          )}
          <li className='ant-list-item' key='logout' onClick={onLogoutUser}>
            <div className='ant-list-item-meta-avatar'>
              <RiLoginCircleLine fontSize={24} />
            </div>
            <div className='ant-list-item-meta-content'>Logout</div>
          </li>
        </ul>
      </div>
    );
  }, [
    authId,
    authUser,
    disable,
    handleOnAccountsView,
    handleSwitchUser,
    isMarketManager,
    isMarketplace,
    menuItems,
    onLogoutUser,
    onProfileUser,
    userAvatar,
  ]);

  return (
    <Popover
      placement='bottomRight'
      content={userProfileView}
      visible={visible}
      trigger='click'
      onVisibleChange={(visible) => setVisible(visible)}>
      <a
        className={'d-flex align-items-center'}
        onClick={(e) => {
          setVisible(true);

          e.preventDefault();
        }}>
        <Avatar
          size={42}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          icon={<UserOutlined fontSize={30} />}
          src={userAvatar}
          alt='avatar'
        />
        <CaretDownOutlined className='ml-2 text-secondary' />
      </a>
    </Popover>
  );
};

export default React.memo(UserInfo);
