import PropTypes from 'prop-types';
import React from 'react';

const ItemInfo = ({label, content}) => {
  return (
    <tr>
      <td width='40%' className={'text-secondary'}>
        {label}
      </td>
      <td>{content}</td>
    </tr>
  );
};

ItemInfo.propTypes = {
  label: PropTypes.string,
  content: PropTypes.node,
};

const PrimaryAddressInfo = ({children, address}) => {
  return (
    <React.Fragment>
      <table className='table' style={{margin: '0 -8px 12px'}}>
        <ItemInfo label='Address Line 1' content={address?.addressLine1} />
        <ItemInfo label='Address Line 2' content={address?.addressLine2} />
        <ItemInfo label='City' content={address?.city} />
        <ItemInfo label='State / Province' content={address?.state} />
        <ItemInfo label='Postal / Zip Code' content={address?.postalCode} />
        <ItemInfo label='Country' content={address?.country} />
      </table>
      {children}
    </React.Fragment>
  );
};

PrimaryAddressInfo.propTypes = {
  address: PropTypes.object,
  children: PropTypes.node,
};

export default PrimaryAddressInfo;
