import AppDatePicker from '@wieldy/components/dataEntry/AppDatePicker';
import AppInputNumber from '@wieldy/components/dataEntry/AppInputNumber';
import AppSelect from '@wieldy/components/dataEntry/AppSelect';
import {getSelectPlaceHolder} from '@wieldy/utils/helpers/MessageHelper';
import {Button, Dropdown, Form, Menu, Typography} from 'antd';
import {DATE_FORMATS} from 'app/config/app';
import CommonHelper from 'app/utils/CommonHelper';
import {formatDate, getYesOrNo} from 'app/utils/helpers/DataFormatHelper';
import React from 'react';
import {RiMore2Fill} from 'react-icons/ri';
import {useNavigate} from 'react-router-dom';

import {PERIODS} from '../../../../../../constants';
import {useAccountType} from '../../../../../../hooks/useAccountType';
import {preventNegativeNumber} from '../../../../../../utils/helpers/preventNegativeNumber';
import {existTableStatus} from '../../../constants';

export const getInquiryCandidateLabel = (status) => {
  switch (status) {
    case 'available':
      return 'Update to Not Available';
    case 'not_available':
      return 'Update to Available';
    default:
      return 'Withdraw Candidate';
  }
};

export const getSubmissionCandidateLabel = (status) => {
  switch (status) {
    case 'available':
      return 'Update to Not Available';
    case 'not_available':
      return 'Update to Available';
    default:
      return 'Withdraw this Candidate?';
  }
};

export const useGetColumns = (
  isEditTable,
  initialState,
  setInitialState,
  isShowActions,
  handleWithdrawCandidate,
  handleChangeCandidateStatus,
) => {
  const navigate = useNavigate();
  const {isSupplier, isClient} = useAccountType();

  const handleChangeState = (recordId, value) => {
    const newInitialState = initialState.map((el) => {
      if (el.id === recordId) {
        return {...el, editMode: value};
      } else {
        return el;
      }
    });
    setInitialState(newInitialState);
  };

  const handleSinglePageView = (id) => {
    navigate(`/candidates/${id}/view-candidate`);
  };

  const actions = {
    title: '',
    dataIndex: 'action',
    key: 'action',
    fixed: 'right',
    align: 'center',
    width: 64,
    render: (_, {status_key, id}) => {
      const title = isSupplier
        ? getInquiryCandidateLabel(status_key)
        : `Withdraw Candidate`;

      const isAvailableStatus =
        isClient &&
        status_key !== 'proposed' &&
        status_key !== 'not_engaged' &&
        status_key !== 'not_available';

      const titleStatus = isClient && 'Change Status';
      return (
        <Dropdown
          overlay={
            <Menu>
              {isAvailableStatus && (
                <Menu.Item
                  key='status'
                  title={titleStatus}
                  onClick={() => handleChangeCandidateStatus(id)}>
                  {titleStatus}
                </Menu.Item>
              )}
              <Menu.Item
                key='withdraw'
                title={title}
                onClick={() => handleWithdrawCandidate(id)}>
                {title}
              </Menu.Item>
            </Menu>
          }
          placement='bottomRight'
          disabled={
            (isSupplier && status_key === 'proposed') ||
            status_key === 'not_engaged' ||
            status_key === 'engaged'
          }
          trigger='click'>
          <Button
            type='text'
            shape='circle'
            size='small'
            icon={<RiMore2Fill className={'text-xl'} />}
          />
        </Dropdown>
      );
    },
  };

  const base = [
    {
      title: 'Name / Location',
      dataIndex: 'name',
      key: 'name',
      render: (_, item) => {
        return (
          <React.Fragment>
            <Typography.Title level={5} className={'text-sm mb-0'}>
              <Typography.Link onClick={() => handleSinglePageView(item.id)}>
                {item.name}
              </Typography.Link>
            </Typography.Title>
            <Typography.Text>{item.location}</Typography.Text>
          </React.Fragment>
        );
      },
    },
    {
      title: 'Available?',
      dataIndex: 'available',
      key: 'available',
      editable: true,
      render: (text, {id, status_key}) => {
        const available =
          status_key === 'proposed'
            ? ''
            : getYesOrNo(
                status_key !== 'not_available' && status_key !== 'not_engaged',
              );

        // const defaultValue =
        //   status_key === 'proposed'
        //     ? undefined
        //     : status_key === 'available' || status_key === 'engaged';
        if (isEditTable && status_key === 'proposed') {
          return (
            <Form.Item
              className='mb-0'
              name={`${id}-status`}
              rules={[{required: true, message: ''}]}>
              <AppSelect
                // defaultValue={defaultValue}
                onChange={(value) => handleChangeState(id, value)}
                style={{width: 100}}
                placeholder={getSelectPlaceHolder()}
                options={CommonHelper.yesNoOptions()}
              />
            </Form.Item>
          );
        } else {
          return <Typography.Text>{available}</Typography.Text>;
        }
      },
    },
    {
      title: 'Billing rate',
      dataIndex: 'billingRate',
      key: 'billing_rate',
      editable: true,
      render: (rate, {editMode, id, billing_rate_period}) => {
        if (editMode) {
          return (
            <Form.Item
              name={`${id}-billing_rate`}
              className='mb-0'
              rules={[{required: true, message: ''}]}>
              <AppInputNumber
                placeholder='Enter rate'
                prefix='$'
                type='number'
                pattern='[0-9]+([\.,][0-9]+)?'
                step='any'
                min={0}
                style={{width: 100}}
                controls={false}
                stringMode={true}
                onKeyPress={preventNegativeNumber}
              />
            </Form.Item>
          );
        } else {
          return (
            <Typography.Text style={{width: 100, display: 'inline-block'}}>
              {rate}
              {PERIODS[billing_rate_period]
                ? ` / ${PERIODS[billing_rate_period]}`
                : ''}
            </Typography.Text>
          );
        }
      },
    },
    {
      title: 'Available on',
      dataIndex: 'available_on',
      key: 'available_on',
      render: (value, {editMode, id}) => {
        if (editMode) {
          return (
            <Form.Item
              className='mb-0'
              name={`${id}-available_on`}
              rules={[{required: true, message: ''}]}>
              <AppDatePicker style={{width: 120}} format='MM/DD/YYYY' />
            </Form.Item>
          );
        } else {
          return (
            <Typography.Text style={{width: 120, display: 'inline-block'}}>
              {formatDate(value, DATE_FORMATS.md) || 'N/A'}
            </Typography.Text>
          );
        }
      },
    },
    {
      title: 'Inquired On',
      dataIndex: 'inquired_on',
      key: 'inquired_on',
      render: (value) => formatDate(value, DATE_FORMATS.md),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => (
        <Typography.Text>
          {isEditTable
            ? 'In Review'
            : existTableStatus[status.toLowerCase()] ?? status}
        </Typography.Text>
      ),
    },
  ];

  return isShowActions ? [...base, actions] : base;
};
