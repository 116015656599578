import AppGridView from '@wieldy/components/AppGridView';
import AppLoader from '@wieldy/components/AppLoader';
import AppPageSection from '@wieldy/components/pageComponents/AppPageSection';
import {capitalizedAllWords} from '@wieldy/utils/helpers/StringHelper';
import {Empty} from 'antd';
import {USER_INCLUDES} from 'app/constants';
import {useAccountType} from 'app/hooks/useAccountType';
import InquiryModal from 'app/modals/InquiryModal';
import {useGetAllInquiries} from 'app/services/inquiryService';
import ViewAllButton from 'app/shared/buttons/ViewAllButton';
import InquirySubmissionCard from 'app/shared/cards/InquirySubmissionCard';
import PropTypes from 'prop-types';
import React, {useMemo} from 'react';

import {queryParamsList, statuses} from '../constants';

const All = ({userQuery}) => {
  const {isClient} = useAccountType();

  const commonQuery = useMemo(() => {
    const includes = isClient
      ? USER_INCLUDES.supplierIncludeDetails
      : USER_INCLUDES.clientIncludeDetails;
    return {
      ...queryParamsList,
      ...userQuery,
      include: [...queryParamsList.include, ...includes],
    };
  }, [isClient, userQuery]);

  const {data: allData, isLoading} = useGetAllInquiries(commonQuery, statuses);

  if (isLoading) {
    return <AppLoader />;
  }

  if (allData?.every(({inquiries}) => !inquiries.length)) {
    return <Empty description='No Inquiries' />;
  }

  return (
    !!allData?.length &&
    allData.map(
      ({inquiries, total_count, status}) =>
        !!inquiries.length && (
          <AppPageSection
            key={status}
            title={`${capitalizedAllWords(status.replace('_', ' '))} Inquiries`}
            count={total_count}
            extra={
              total_count > 6 && (
                <ViewAllButton category={status} path='inquiries' />
              )
            }>
            <AppGridView
              isAllPages={true}
              data={inquiries.slice(0, 6)}
              renderItem={(inquiry) => (
                <InquirySubmissionCard
                  key={inquiry.id}
                  item={new InquiryModal(inquiry, true)}
                />
              )}
            />
          </AppPageSection>
        ),
    )
  );
};
All.propTypes = {
  userQuery: PropTypes.object,
};

All.defaultProps = {
  userQuery: {},
};

export default All;
