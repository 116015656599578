import {Tabs} from 'antd';
import PropTypes from 'prop-types';

const AppTabs = ({children, ...rest}) => {
  return <Tabs {...rest}>{children}</Tabs>;
};

AppTabs.propTypes = {
  children: PropTypes.node,
};
export default AppTabs;
