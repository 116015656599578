import {Button, Space} from 'antd';
import {useAddClientReview} from 'app/services/supplierService';
import {RatingForm} from 'app/shared/company';
import PropTypes from 'prop-types';
import React from 'react';
import {useNavigate} from 'react-router-dom';

import {ratingFields} from '../constants';

const CreateReview = ({review}) => {
  const navigate = useNavigate();
  const mutation = useAddClientReview();

  const handleOnSubmit = React.useCallback(
    (formdata) => {
      mutation.mutate(
        {
          ...formdata,
          client: review?.id,
        },
        {
          onSuccess: () => {
            navigate('/administration/reviews');
          },
        },
      );
    },
    [mutation, navigate, review?.id],
  );

  return (
    <RatingForm
      title={'Add New Client Review'}
      fields={ratingFields}
      onSubmit={handleOnSubmit}>
      <Space size={16}>
        <Button type='primary' htmlType='submit' loading={mutation.isLoading}>
          Submit New Client Review
        </Button>
        <Button type='primary' onClick={() => navigate(-1)} ghost>
          Cancel
        </Button>
      </Space>
    </RatingForm>
  );
};

CreateReview.propTypes = {
  review: PropTypes.object,
};

export default CreateReview;
