import AppBorderBox from '@wieldy/components/AppBorderBox';
import AppTag from '@wieldy/components/AppTag/AppTag';
import {Row, Typography} from 'antd';
import {RESOURCE_AVAILABILITY} from 'app/constants';
import {capitalizeFirstLetter} from 'app/utils/helpers/Capitalize.js';
import PropTypes from 'prop-types';
import React from 'react';

const AvailabilityBox = ({candidate}) => {
  return (
    <React.Fragment>
      <Row justify={'space-between'} align={'middle'} className='mb-6'>
        <Typography.Title level={5} type={'secondary'} className={'title'}>
          Availability
          <span className='title-divider' style={{maxWidth: 60}} />
        </Typography.Title>
        {!!candidate?.availability && (
          <AppTag color='blue' shape={'circle'} className={'text-xs'}>
            {RESOURCE_AVAILABILITY[candidate?.availability] ||
              capitalizeFirstLetter(candidate?.availability)}
          </AppTag>
        )}
      </Row>

      <AppBorderBox className='mb-6'>
        <table className='table' style={{margin: 0}}>
          <tbody>
            <tr>
              <td width='50%' className={'text-secondary'}>
                Available From
              </td>
              <td>{candidate?.available_on}</td>
            </tr>
            <tr>
              <td width='50%' className={'text-secondary'}>
                Available To
              </td>
              <td>{candidate?.available_to}</td>
            </tr>
          </tbody>
        </table>
      </AppBorderBox>
    </React.Fragment>
  );
};

AvailabilityBox.propTypes = {
  candidate: PropTypes.object,
};

export default React.memo(AvailabilityBox);
