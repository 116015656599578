import {capitalizedAllWords} from '@wieldy/utils/helpers/StringHelper';
import {Typography} from 'antd';
import {DATE_FORMATS} from 'app/config/app';
import {PERIODS} from 'app/constants';
import {
  formatBillingRateRage,
  formatDate,
  getAddress,
  getFullName,
} from 'app/utils/helpers/DataFormatHelper.js';
import React from 'react';
import {Link} from 'react-router-dom';

export const getColumns = () => {
  return [
    {
      title: 'Name / Location',
      dataIndex: 'resource',
      key: 'resource',
      render: (_, {first_name, last_name, id, country, city, us_state}) => {
        return (
          <Typography.Paragraph>
            <Typography.Paragraph className={'mb-0'}>
              <Link
                to={`/resources/${id}/view-resource`}
                component={Typography.Link}>
                {getFullName({first_name, last_name})}
              </Link>
            </Typography.Paragraph>
            <Typography.Text type={'secondary'} className={'text-xs'}>
              {getAddress({country, city, us_state})}
            </Typography.Text>
          </Typography.Paragraph>
        );
      },
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Years of Experience',
      dataIndex: 'years_experience',
      key: 'years_experience',
    },
    {
      title: 'Past Assignments',
      dataIndex: 'completed_engagements',
      key: 'completed_engagements',
    },
    {
      title: 'Billing Rate',
      dataIndex: 'billing_rate',
      key: 'billing_rate',
      width: 100,
      render: (
        billing_rate,
        {billing_rate_max, billing_rate_min, billing_rate_period},
      ) => (
        <Typography.Text>
          {formatBillingRateRage(
            billing_rate,
            billing_rate_min,
            billing_rate_max,
            false,
          )}
          {PERIODS[billing_rate_period]
            ? ` / ${PERIODS[billing_rate_period]}`
            : ''}
        </Typography.Text>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'availability',
      key: 'availability',
      render: (status) => {
        return (
          <Typography.Text>
            {capitalizedAllWords(status.replace('_', ' '))}
          </Typography.Text>
        );
      },
    },
    {
      title: 'Available On',
      dataIndex: 'available_on',
      key: 'available_on',
      render: (available_on) => (
        <Typography.Text>
          {formatDate(available_on, DATE_FORMATS.md) || 'N/A'}
        </Typography.Text>
      ),
    },
  ];
};
