import AppCard from '@wieldy/components/AppCard';
import {Typography} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

const Courses = ({courses, isCard = true}) => {
  const renderContent = () => (
    <table className='table' style={{margin: '0 -8px'}}>
      <tbody>
        {!!courses &&
          courses.map(({course, year}) => (
            <tr key={course}>
              <td className={'text-secondary'}>{course}</td>
              <td align='right'>{year}</td>
            </tr>
          ))}
      </tbody>
    </table>
  );

  if (!courses?.length) {
    return null;
  }

  if (isCard) {
    return (
      <AppCard
        title='Courses'
        headStyle={{borderBottom: 0}}
        bodyStyle={{paddingTop: 0}}>
        {renderContent()}
      </AppCard>
    );
  }

  return (
    <>
      <Typography.Title level={4}>Courses</Typography.Title>
      {renderContent()}
    </>
  );
};

Courses.propTypes = {
  courses: PropTypes.array,
  isCard: PropTypes.bool,
};

export default Courses;
