import {InputNumber} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

const AppInputNumber = (props) => {
  return <InputNumber {...props} />;
};

AppInputNumber.propTypes = {
  onChange: PropTypes.func,
  min: PropTypes.number,
  max: PropTypes.number,
  placeholder: PropTypes.string,
};

export default AppInputNumber;
