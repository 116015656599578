import PropTypes from 'prop-types';
import React, {useCallback, useEffect, useState} from 'react';

import StepperGeneral from './Steppers/StepperGeneral';

const MainContent = ({isGeneral, isEditMode}) => {
  const [currentStep, setCurrentStep] = useState(0);

  useEffect(() => {
    if (isEditMode) {
      setCurrentStep(2);
    }
  }, [isEditMode, isGeneral]);

  const onHandleChangeStep = useCallback((step) => {
    setCurrentStep(step);
  }, []);

  const goToNext = useCallback(() => {
    setCurrentStep(currentStep + 1);
  }, [currentStep]);

  return (
    <>
      <StepperGeneral
        currentStep={currentStep}
        setCurrentStep={onHandleChangeStep}
        goToNext={goToNext}
      />
      {/*{isGeneral ? (*/}
      {/*  <StepperGeneral*/}
      {/*    currentStep={currentStep}*/}
      {/*    setCurrentStep={onHandleChangeStep}*/}
      {/*    goToNext={goToNext}*/}
      {/*  />*/}
      {/*) : (*/}
      {/*  <StepperResource*/}
      {/*    currentStep={currentStep}*/}
      {/*    setCurrentStep={onHandleChangeStep}*/}
      {/*    goToNext={goToNext}*/}
      {/*  />*/}
      {/*)}*/}
    </>
  );
};

MainContent.propTypes = {
  isGeneral: PropTypes.bool,
  isEditMode: PropTypes.bool,
};

export default React.memo(MainContent);
