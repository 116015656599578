import AppCard from '@wieldy/components/AppCard';
import AppLabels from '@wieldy/components/AppLabels';
import AppTag from '@wieldy/components/AppTag/AppTag';
import AppTags from '@wieldy/components/AppTags';
import {Col, Descriptions, Row, Typography} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

import {supplierTypesNames} from '../../../constants/supplier-types';
import {InsuranceSupplierState} from '../../../pages/main/requisitions/ViewRequisition/constatns';

const OverviewCard = ({supplier}) => {
  return (
    <AppCard
      title='Overview'
      headStyle={{borderBottom: 0}}
      bodyStyle={{paddingTop: 8}}>
      <Descriptions
        colon={false}
        labelStyle={{width: '50%'}}
        className='description-root mb-4'
        column={{xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1}}>
        <Descriptions.Item label='Relationship Status'>
          {supplier?.relationStatus ?? 'N/A'}
        </Descriptions.Item>
        <Descriptions.Item label='Supplier Type'>
          <AppTags items={supplier?.type.map((t) => supplierTypesNames[t])} />
        </Descriptions.Item>
        <Descriptions.Item label='Service Focus'>
          {supplier?.serviceFocus}
        </Descriptions.Item>
        <Descriptions.Item label='Company Size'>
          {supplier?.companySize}
        </Descriptions.Item>
        <Descriptions.Item label='# of Deployed Resources'>
          {supplier?.engagements ?? 0}
        </Descriptions.Item>
        <Descriptions.Item label='Diversity Status'>
          <Row wrap='wrap' gutter={[10, 10]}>
            {supplier?.diversityStatus?.length
              ? supplier?.diversityStatus.map((status, index) => (
                  <Col key={index}>
                    <AppTag
                      shape={'square'}
                      className={'border-none'}
                      label={status}
                    />
                  </Col>
                ))
              : ''}
          </Row>
        </Descriptions.Item>
        <Descriptions.Item label='Industry Focus'>
          <Row>
            <AppLabels visible={2} items={supplier?.industryFocuses} />
          </Row>
        </Descriptions.Item>
        {supplier?.website && (
          <Descriptions.Item label='Website'>
            <Typography.Link
              href={supplier?.website}
              target='_blank'
              className={'font-semibold'}>
              {supplier.website.replace(/(^\w+:|^)\/\//, '')}
            </Typography.Link>
          </Descriptions.Item>
        )}
        {supplier?.linkedin && (
          <Descriptions.Item label='Company LinkedIn Page'>
            <Typography.Link
              href={supplier.linkedin}
              target='_blank'
              className={'font-semibold'}>
              {supplier?.linkedin.replace(/(^\w+:|^)\/\//, '')}
            </Typography.Link>
          </Descriptions.Item>
        )}
        <Descriptions.Item label='Joined'>
          {supplier?.createdAt}
        </Descriptions.Item>

        <Descriptions.Item label='Has Insurance'>
          {supplier?.has_insurance
            ? InsuranceSupplierState.yes
            : InsuranceSupplierState.no}
        </Descriptions.Item>
        <Descriptions.Item label='Company ID'>
          {supplier?.hid}
        </Descriptions.Item>

        <Descriptions.Item label='Updated' span={1}>
          {supplier?.updatedAt}
        </Descriptions.Item>
      </Descriptions>

      {supplier?.about && (
        <Typography.Paragraph>
          <Typography.Title level={4}>About</Typography.Title>
          <Typography.Paragraph>
            <div dangerouslySetInnerHTML={{__html: supplier?.about}} />
          </Typography.Paragraph>
        </Typography.Paragraph>
      )}
    </AppCard>
  );
};

OverviewCard.propTypes = {
  supplier: PropTypes.object,
};

export default React.memo(OverviewCard);
