import moment from 'moment/moment';
import {useCallback, useState} from 'react';

const init = {
  gte: undefined,
  lte: undefined,
};

export const useTableDateFilter = () => {
  const [filterDate, setFilterDate] = useState(init);

  const onChangeFilter = useCallback(
    (filterOption, range = null) => {
      if (filterOption) {
        const {key} = filterOption;
        if (key === 'date-range' && range) {
          setFilterDate({
            gte: range[0].unix(),
            lte: range[1].unix(),
          });
        } else if (key === '90') {
          setFilterDate({
            gte: moment().subtract(90, 'days').unix(),
            lte: undefined,
          });
        } else if (key === 'all') {
          setFilterDate(init);
        }
      }
    },
    [setFilterDate],
  );
  return [filterDate, onChangeFilter];
};
