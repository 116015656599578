import AppDynamicTabs from '@wieldy/components/AppTabs/AppDynamicTabs';
import AppPageContainer from '@wieldy/components/pageComponents/AppPageContainer';
import AppPageHeader from '@wieldy/components/pageComponents/AppPageHeader';
import {capitalizedAllWords} from '@wieldy/utils/helpers/StringHelper';
import {Button} from 'antd';
import {CATEGORIES} from 'app/constants/index.js';
import PageQueryProviderNew from 'app/providers/PageQueryProvider/PageQueryProviderNew';
import React, {useEffect, useMemo} from 'react';
import {useNavigate, useParams} from 'react-router-dom';

import {filterConfig} from '../../../shared/candidate/FilterCandidates';
import All from './MainContent/All';
import Browse from './MainContent/Browse';
import {applicantsTabs, pageQueriesApplicants} from './MainContent/constants';

const Applicants = () => {
  const {category} = useParams();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = React.useState();

  const handleAddNewApplicant = () => {
    navigate('/applicants/create-applicant');
  };

  const isAll = useMemo(() => category === CATEGORIES.ALL, [category]);

  useEffect(() => {
    if (!category) {
      navigate('/applicants/all');
    }
  }, [category, navigate]);

  const activeKey = React.useMemo(() => {
    return category ?? CATEGORIES.ALL;
  }, [category]);

  return (
    <PageQueryProviderNew
      category={category}
      pageParams={pageQueriesApplicants}
      filterConfig={filterConfig}>
      <AppPageContainer
        header={
          <AppPageHeader
            title={`${capitalizedAllWords(
              activeTab?.key.replace('_', ' '),
            )} Applicants`}
            category={'Applicants'}
            count={activeTab?.counts}
            tabs={
              <AppDynamicTabs
                tabsData={applicantsTabs}
                activeKey={activeKey}
                setActiveTab={setActiveTab}
              />
            }
            extra={
              <Button
                type='primary'
                shape='round'
                onClick={handleAddNewApplicant}>
                Create New Applicant
              </Button>
            }
          />
        }>
        {isAll ? <All /> : <Browse />}
      </AppPageContainer>
    </PageQueryProviderNew>
  );
};

export default Applicants;
