import AppDynamicTabs from '@wieldy/components/AppTabs/AppDynamicTabs';
import AppPageContainer from '@wieldy/components/pageComponents/AppPageContainer';
import AppPageHeader from '@wieldy/components/pageComponents/AppPageHeader';
import {Button} from 'antd';
import {CATEGORIES} from 'app/constants';
import {useAccountType} from 'app/hooks/useAccountType';
import All from 'app/pages/main/inquiries/MainContent/All';
import Browse from 'app/pages/main/inquiries/MainContent/Browse';
import {resetPageState} from 'app/store/features/common/commonSlice';
import React, {useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';

import {tabsData} from './constants';

const Inquiries = () => {
  const {category} = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {isClient} = useAccountType();
  const [activeTab, setActiveTab] = useState('all');

  const activeKey = React.useMemo(() => {
    return category ?? 'all';
  }, [category]);

  const isAll = useMemo(() => category === CATEGORIES.ALL, [category]);

  const userState = useSelector(({common}) => common.inquiries);
  const {name, ...rest} = userState || {name: null};
  const title = `${activeTab.title} Inquiries${
    name ? (isClient ? ' for ' : ' from ') + name : ''
  }`;

  useEffect(() => {
    return () => {
      dispatch(resetPageState('inquiries'));
    };
  }, [dispatch]);

  const onAddNew = () => {
    navigate('/inquiries/new-inquiry');
  };

  return (
    <AppPageContainer
      header={
        <AppPageHeader
          title={title}
          category='Inquiries'
          tabs={
            <AppDynamicTabs
              tabsData={tabsData}
              activeKey={activeKey}
              setActiveTab={setActiveTab}
            />
          }
          extra={
            isClient && (
              <Button type='primary' shape='round' onClick={onAddNew}>
                Create New Inquiry
              </Button>
            )
          }
        />
      }>
      {isAll ? <All userQuery={rest} /> : <Browse userQuery={rest} />}
    </AppPageContainer>
  );
};

export default Inquiries;
