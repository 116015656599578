import AppSteps from '@wieldy/components/AppSteps';
import AppStep from '@wieldy/components/AppSteps/AppStep';
import AppStepTitle from '@wieldy/components/AppSteps/AppStep/AppStepTitle';
import useStep from 'app/hooks/useStep';
import RequisitionModal from 'app/modals/RequisitionModal';
import CompensationCriteria from 'app/shared/requisitions/RequisitionSteps/CompensationCriteria';
import JobDescription from 'app/shared/requisitions/RequisitionSteps/JobDescription';
import SkillsResponsibilities from 'app/shared/requisitions/RequisitionSteps/SkillsResponsibilities';
import SubmitRequisition from 'app/shared/requisitions/RequisitionSteps/SubmitRequisition';
import WorksiteTimings from 'app/shared/requisitions/RequisitionSteps/WorksiteTimeline';
import React from 'react';

import RecruitmentTerms from './RecruitmentTerms';

const StepForms = () => {
  const {currentStep, stepData, goToStep} = useStep();
  const requisitionModal = new RequisitionModal(stepData);

  return (
    <AppSteps current={currentStep}>
      <AppStep
        title={
          <AppStepTitle
            title='Job Information'
            isEdit={currentStep > 1}
            onEditClick={() => goToStep(1)}
          />
        }
        step={1}
        description={<JobDescription requisitionModal={requisitionModal} />}
      />
      <AppStep
        title={
          <AppStepTitle
            title='Job Description'
            isEdit={currentStep > 2}
            onEditClick={() => goToStep(2)}
          />
        }
        step={2}
        description={
          <SkillsResponsibilities requisitionModal={requisitionModal} />
        }
      />
      <AppStep
        title={
          <AppStepTitle
            title='Worksite and Timeline'
            isEdit={currentStep > 3}
            onEditClick={() => goToStep(3)}
          />
        }
        step={3}
        description={<WorksiteTimings requisitionModal={requisitionModal} />}
      />
      <AppStep
        title={
          <AppStepTitle
            title='Compensation and Candidate Criteria'
            isEdit={currentStep > 4}
            onEditClick={() => goToStep(4)}
          />
        }
        step={4}
        description={
          <CompensationCriteria requisitionModal={requisitionModal} />
        }
      />
      {requisitionModal.isDirectHire &&
        !requisitionModal.isOnlyDirectSourcing && (
          <AppStep
            title={
              <AppStepTitle
                title='Recruitment Terms'
                isEdit={currentStep > 5}
                onEditClick={() => goToStep(5)}
              />
            }
            step={5}
            description={
              <RecruitmentTerms requisitionModal={requisitionModal} />
            }
          />
        )}

      <AppStep
        title='Submit'
        step={
          requisitionModal.isDirectHire &&
          !requisitionModal.isOnlyDirectSourcing
            ? 6
            : 5
        }
        description={<SubmitRequisition requisitionModal={requisitionModal} />}
      />
    </AppSteps>
  );
};

export default React.memo(StepForms);
