import AppTable from '@wieldy/components/AppTable';
import {getTablePagination} from '@wieldy/utils/helpers/TableHelper';
import propTypes from 'prop-types';
import React, {useState} from 'react';

import {getColumns} from './columns';

const EntriesIndividualTable = ({items}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const onShowSizeChange = (current, pageSize) => {
    setCurrentPage(current);
    setPageSize(pageSize);
  };

  return (
    <div className='individual-expense-entries'>
      <AppTable
        dataSource={items}
        columns={getColumns()}
        pagination={getTablePagination(
          pageSize,
          currentPage,
          items.length,
          onShowSizeChange,
          onPageChange,
        )}
      />
    </div>
  );
};

EntriesIndividualTable.propTypes = {
  items: propTypes.array,
};

export default EntriesIndividualTable;
