import AppCard from '@wieldy/components/AppCard';
import AppHeaderPagination from '@wieldy/components/AppPagination/AppHeaderPagination';
import AppTable from '@wieldy/components/AppTable';
import AppTag from '@wieldy/components/AppTag';
import {getTablePagination} from '@wieldy/utils/helpers/TableHelper';
import {useAccountType} from 'app/hooks/useAccountType';
import {useTableSort} from 'app/hooks/useTableSort';
import {useGetClientUsers} from 'app/services/clientService';
import {useGetSupplierUsers} from 'app/services/supplierService';
import React, {useCallback, useState} from 'react';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';

import {getColumns} from './columns';

const UsersTable = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const {user: authUser} = useSelector(({user}) => user);
  const {isSupplier} = useAccountType();
  const navigate = useNavigate();

  const {sort, handleSort} = useTableSort({
    ['created_at']: 'descend',
  });

  const onChangeTable = useCallback(
    (a, b, sorter) => {
      if (sorter.columnKey && sorter?.order) {
        handleSort(sorter.columnKey, sorter?.order);
      }
    },
    [handleSort],
  );

  const {isLoading, data} = (
    isSupplier ? useGetSupplierUsers : useGetClientUsers
  )({
    page: currentPage - 1,
    limit: pageSize,
    sort,
  });

  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const onShowSizeChange = (current, pageSize) => {
    setCurrentPage(current);
    setPageSize(pageSize);
  };

  const viewUser = useCallback(
    (item) => {
      if (item?.user?.id === authUser.id) {
        navigate(`/my-account`);
        return;
      }
      navigate(`/administration/users/${item.id}/view-user`);
    },
    [authUser.id, navigate],
  );

  const bulkActions = React.useCallback(
    (item) => {
      return [
        {
          title: 'View User',
          onClick: () => viewUser(item),
        },
      ];
    },
    [viewUser],
  );

  return (
    <AppCard
      title={
        <React.Fragment>
          Users{' '}
          {data?.totalCount > 0 && (
            <AppTag className='ml-1'>{data?.totalCount}</AppTag>
          )}
        </React.Fragment>
      }
      headStyle={{borderBottom: 0}}
      bodyStyle={{paddingTop: 8}}
      extra={
        <AppHeaderPagination
          total={data?.totalCount}
          current={currentPage}
          pageSize={pageSize}
          onChange={onPageChange}
        />
      }>
      <AppTable
        onChange={onChangeTable}
        loading={isLoading}
        bulkActions={bulkActions}
        columns={getColumns(viewUser)}
        dataSource={data?.items}
        style={{background: 'none'}}
        pagination={getTablePagination(
          pageSize,
          currentPage,
          data?.totalCount,
          onShowSizeChange,
          onPageChange,
        )}
      />
    </AppCard>
  );
};

export default React.memo(UsersTable);
