import AppPaper from '@wieldy/components/AppCard/AppPaper';
import {Col, Row, Typography} from 'antd';
import {setPageState} from 'app/store/features/common/commonSlice';
import PropTypes from 'prop-types';
import React from 'react';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';

const BottomCounterBlock = ({items = []}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  if (!Array.isArray(items) || !items.length) {
    return null;
  }

  const onClick = (path, state) => {
    dispatch(setPageState({page: path, content: state}));
    navigate(`/${path}/all`, {replace: false});
  };

  return (
    <AppPaper>
      <Row justify={'center'} gutter={20} className='cols-divider-vertical'>
        {items.map((item, index) => (
          <Col
            span={items.length === 2 ? 12 : 8}
            key={index}
            className='text-center'>
            {item?.count > 0 ? (
              <Typography.Title ellipsis level={5} className='mb-0'>
                <Typography.Link
                  onClick={() => onClick(item?.path, item?.state)}>
                  <span className={'block pb-1'}>{item?.count}</span>
                  <span className={'block ant-typography-ellipsis-single-line'}>
                    {item?.label}
                  </span>
                </Typography.Link>
              </Typography.Title>
            ) : (
              <Typography.Title
                ellipsis
                type={'secondary'}
                level={5}
                className='mb-0'>
                <span className={'block pb-1'}>{item?.count}</span>
                <span className={'block ant-typography-ellipsis-single-line'}>
                  {item?.label}
                </span>
              </Typography.Title>
            )}
          </Col>
        ))}
      </Row>
    </AppPaper>
  );
};

BottomCounterBlock.propTypes = {
  items: PropTypes.array,
  id: PropTypes.string,
};

export default BottomCounterBlock;
