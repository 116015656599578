import AppDynamicTabs from '@wieldy/components/AppTabs/AppDynamicTabs';
import AppPageContainer from '@wieldy/components/pageComponents/AppPageContainer';
import AppPageHeader from '@wieldy/components/pageComponents/AppPageHeader';
import {Button} from 'antd';
import {CATEGORIES} from 'app/constants/index.js';
import {useAccountType} from 'app/hooks/useAccountType';
import All from 'app/pages/main/submissions/MainContant/All.js';
import Browse from 'app/pages/main/submissions/MainContant/Browse.js';
import {resetPageState} from 'app/store/features/common/commonSlice';
import React, {useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';

import {tabsData} from './constants';

const Submissions = () => {
  const {category} = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {isSupplier} = useAccountType();
  const [activeTab, setActiveTab] = useState('all');
  const isAll = useMemo(() => category === CATEGORIES.ALL, [category]);
  const userState = useSelector(({common}) => common.submissions);
  const {name, ...rest} = userState || {name: null};

  const {supplier} = rest;
  const title = `${activeTab.title} Submissions${
    name ? (!supplier ? ' for ' : ' from ') + name : ''
  }`;

  useEffect(() => {
    return () => {
      dispatch(resetPageState('submissions'));
    };
  }, [dispatch]);

  const activeKey = React.useMemo(() => {
    return category ?? 'all';
  }, [category]);

  const onAddNew = () => {
    navigate('/submissions/new-submission');
  };

  return (
    <AppPageContainer
      header={
        <AppPageHeader
          title={title}
          category='Submissions'
          tabs={
            <AppDynamicTabs
              tabsData={tabsData}
              activeKey={activeKey}
              setActiveTab={setActiveTab}
            />
          }
          extra={
            isSupplier && (
              <Button type='primary' shape='round' onClick={onAddNew}>
                Create New Submission
              </Button>
            )
          }
        />
      }>
      {isAll ? <All userQuery={rest} /> : <Browse userQuery={rest} />}
    </AppPageContainer>
  );
};

export default Submissions;
