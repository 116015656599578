import {Divider, Row, Typography} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import {useNavigate} from 'react-router-dom';

const PlacementCardHeader = ({placement}) => {
  const navigate = useNavigate();
  const handleLink = (path) => {
    return () => navigate(path);
  };

  return (
    <Row justify='space-between' wrap={false}>
      <div className={'d-flex flex-fill flex-column mb-3'}>
        <Typography.Title
          level={3}
          type='secondary'
          className='title-secondary'>
          Placement With {placement?.name}
        </Typography.Title>
        <Typography.Title level={4} className={'mt-1 mb-1'} ellipsis>
          <Typography.Link
            onClick={handleLink(`/placements/${placement?.id}/view-placement`)}>
            <span>{placement.applicant.name}</span> ::{' '}
            <span>{placement.requisition.jobTitle}</span>
          </Typography.Link>
        </Typography.Title>
        <Typography.Paragraph className={'text-sm'}>
          <Typography.Text type={'secondary'}>
            Created on {placement.created_at}
          </Typography.Text>
          <Divider type='vertical' />
          <Typography.Text type={'secondary'}>
            Updated {placement.updated_relative} ago
          </Typography.Text>
        </Typography.Paragraph>
      </div>
    </Row>
  );
};

PlacementCardHeader.propTypes = {
  placement: PropTypes.object,
};

export default PlacementCardHeader;
