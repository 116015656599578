import {message, notification} from 'antd';
import React, {useEffect} from 'react';

import {useInfoViewActionsContext, useInfoViewContext} from './InfoViewContext';
//import AppLoader from '../AppLoader';

const AppInfoView = () => {
  const {loading, error, displayMessage} = useInfoViewContext();
  const {clearInfoView} = useInfoViewActionsContext();

  useEffect(() => {
    if (error) {
      notification.error({message: error});
      clearInfoView();
    }
  }, [clearInfoView, error]);

  useEffect(() => {
    if (displayMessage) {
      clearInfoView();
      notification.success({message: displayMessage});
    }
  }, [clearInfoView, displayMessage]);

  useEffect(() => {
    if (loading) {
      message.info({
        content: 'Loading...',
        duration: 0,
        key: 'loadingMsg',
      });
    } else {
      message.destroy('loadingMsg');
    }
  }, [loading]);

  return null;
};

export default React.memo(AppInfoView);
