import AppCard from '@wieldy/components/AppCard';
import {Button, Typography} from 'antd';
import {useResetPassword} from 'app/services/userService';
import PropTypes from 'prop-types';
import React from 'react';

const ResetPassword = ({email}) => {
  const {isLoading, mutate} = useResetPassword();
  const onReset = () => {
    mutate(email);
  };
  return (
    <AppCard
      title='Reset Password'
      headStyle={{borderBottom: 0}}
      bodyStyle={{paddingTop: 0}}>
      <div className='mb-6'>
        <Typography.Paragraph>
          {"To reset a user's password, click Reset Password button below."}
        </Typography.Paragraph>

        <Typography.Paragraph>
          {
            "Once this user's password is reset, the system will send an email to the user notifying them of the change with a temporary password. The user will be required to reset their password at their next login."
          }
        </Typography.Paragraph>
      </div>
      <Button type='primary' onClick={onReset} loading={isLoading}>
        Reset Password
      </Button>
    </AppCard>
  );
};

ResetPassword.propTypes = {
  email: PropTypes.string,
};

export default React.memo(ResetPassword);
