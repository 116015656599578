import AppSteps from '@wieldy/components/AppSteps';
import AppStep from '@wieldy/components/AppSteps/AppStep';
import AppStepTitle from '@wieldy/components/AppSteps/AppStep/AppStepTitle';
import useStep from 'app/hooks/useStep';
import CandidateSelect from 'app/pages/main/placement/CreatePlacement/MainContent/Candidate';
import SelectRequisition from 'app/pages/main/placement/CreatePlacement/MainContent/Requisition/SelectRequisition';
import PropTypes from 'prop-types';
import React from 'react';

import ActivePlacement from './ActivePlacement';
import JobDetails from './JobDetails';
import PlacementDetails from './PlacementDetails';

const MainContent = ({onPreview, onFinish, isEdit}) => {
  const {currentStep, goToStep} = useStep();

  return (
    <AppSteps current={currentStep} initial={1}>
      <AppStep
        title={
          <AppStepTitle
            title='Requisition'
            isEdit={!isEdit && currentStep > 1}
            onEditClick={() => goToStep(1)}
          />
        }
        step={0}
        description={<SelectRequisition />}
      />
      <AppStep
        title={
          <AppStepTitle
            title='Candidate'
            isEdit={!isEdit && currentStep > 2}
            onEditClick={() => goToStep(2)}
          />
        }
        step={2}
        description={<CandidateSelect />}
      />
      <AppStep
        title={
          <AppStepTitle
            title='Placement Details'
            isEdit={currentStep > 3}
            onEditClick={() => goToStep(3)}
          />
        }
        step={3}
        description={<PlacementDetails />}
      />
      <AppStep
        title={
          <AppStepTitle
            title='Job Details'
            isEdit={currentStep > 4}
            onEditClick={() => goToStep(4)}
          />
        }
        step={4}
        description={<JobDetails />}
      />
      <AppStep
        title={
          <AppStepTitle title={isEdit ? 'Edit' : 'Create'} isEdit={false} />
        }
        step={5}
        description={
          <ActivePlacement
            isEdit={isEdit}
            onPreview={onPreview}
            onFinish={onFinish}
          />
        }
      />
    </AppSteps>
  );
};

MainContent.propTypes = {
  engagement: PropTypes.object,
  setEngagement: PropTypes.func,
  onPreview: PropTypes.func,
  onFinish: PropTypes.func,
  isEdit: PropTypes.bool,
};

export default MainContent;
