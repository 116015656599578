import AppSelect from '@wieldy/components/dataEntry/AppSelect';
import {Select} from 'antd';
import {debounce} from 'lodash';
import PropTypes from 'prop-types';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';

import {useCompanyUsers} from './constants';

const CompanyMemberDropdown = ({
  fetchDelay = 700,
  variant = 'client',
  queryParams = {},
  initialFetch = false,
  filterMembers = (m) => m,
  // eslint-disable-next-line no-unused-vars
  value,
  getValue = () => [],
  ...restProps
}) => {
  const [users, setUsers] = useState([]);

  const mutation = useCompanyUsers(variant);

  const fetchRef = useRef(0);

  if (variant === 'resource') {
    queryParams.user = '';
  }

  useEffect(() => {
    getValue(users);
  }, [getValue, users]);

  const handleGetInitialUsers = useCallback(() => {
    if (users.length > 0 || !initialFetch) return;

    mutation.mutate(queryParams, {
      onSuccess: (users) => {
        if (users) {
          setUsers(
            users
              .filter(filterMembers)
              .sort((a, b) => a.name.localeCompare(b.name)),
          );
        }
      },
    });
  }, [queryParams, filterMembers, mutation, users, initialFetch]);

  const handleOnSearch = useMemo(() => {
    return debounce((value) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setUsers([]);
      mutation.mutate(
        {
          ...queryParams,
          first_name: value,
        },
        {
          onSuccess: (users) => {
            if (fetchId !== fetchRef.current) {
              return;
            }
            setUsers(
              users
                .filter(filterMembers)
                .sort((a, b) => a.name.localeCompare(b.name)),
            );
          },
        },
      );
    }, fetchDelay);
  }, [fetchDelay, filterMembers, mutation, queryParams]);

  return (
    <AppSelect
      showSearch={true}
      onSearch={handleOnSearch}
      filterOption={false}
      notFoundContent={mutation.isLoading ? 'Fetching...' : null}
      showArrow={false}
      onClick={handleGetInitialUsers}
      {...restProps}>
      {users.map((el) => (
        <Select.Option
          key={el.id}
          value={el.id}
          firstName={el.first_name}
          lastName={el.last_name}>
          {el.first_name} {el.last_name}
        </Select.Option>
      ))}
    </AppSelect>
  );
};

CompanyMemberDropdown.propTypes = {
  fetchDelay: PropTypes.number,
  options: PropTypes.array,
  value: PropTypes.string,
  manager: PropTypes.object,
  variant: PropTypes.oneOf([
    'client',
    'supplier',
    'marketplace',
    'marketplaceAll',
  ]).isRequired,
  queryParams: PropTypes.object,
  initialFetch: PropTypes.bool,
  filterMembers: PropTypes.func,
  getValue: PropTypes.func,
};
export default CompanyMemberDropdown;
