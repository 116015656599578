import {CATEGORIES, USER_INCLUDES} from '../../../constants';
import {commonQueryParams} from '../engagements/data';

export const STATUSES_TEXT = {
  all: 'All',
  pending: 'Pending',
  completed: 'Completed',
  guarantee_period: 'Guarantee Period',
};

export const statuses = [
  {status: CATEGORIES.PENDING, sort: ['-created_at']},
  {status: CATEGORIES.GUARANTEE_PERIOD, sort: ['-guarantee_period']},
  {status: CATEGORIES.COMPLETED, sort: ['-status_guarantee_period_end_date']},
];
export const categories = ['all', ...statuses.map(({status}) => status)];

export const getPageTabsData = (isNotes) => {
  const tabsData = [
    {key: 'summary', title: 'Summary'},
    {key: 'client', title: 'Client'},
    {key: 'requisition', title: 'Requisition'},
    {key: 'spending-summary', title: 'Spending Summary'},
  ];

  isNotes && tabsData.push({key: 'notes', title: 'notes'});
  return tabsData;
};

export const tabsData = categories.map((key) => ({
  key,
  title: STATUSES_TEXT[key],
  route: `/placements/${key}`,
}));

const supplierQueryParams = {
  ...commonQueryParams,
  include: ['total_count', ...USER_INCLUDES.clientIncludeDetails],
  expand: ['client', 'resource', 'requisition'],
};

const clientQueryParams = {
  ...commonQueryParams,
  include: ['total_count', ...USER_INCLUDES.supplierIncludeDetails],
  expand: ['supplier', 'resource', 'requisition'],
};

export const getEngagementsQueryParams = (isSupplier) => {
  return isSupplier ? supplierQueryParams : clientQueryParams;
};
export const getPageParams = (isSupplier = false) => {
  const queryParams = getEngagementsQueryParams(isSupplier);
  return {
    all: {
      queryParams,
      title: 'All Placements',
    },
    pending: {
      queryParams: {
        ...queryParams,
        status: 'pending',
      },
      title: 'Pending Placements',
      label: 'Created',
      rowTitle: 'Pending Placements',
      empty: 'No Pending Placements',
    },
    guarantee_period: {
      queryParams: {
        ...queryParams,
        status: 'active',
      },
      title: 'Guarantee Period Placements',
      label: 'Guarantee Period',
      rowTitle: 'Guarantee Period Placements',
      empty: 'No Guarantee Period Placements',
    },
    completed: {
      queryParams: {
        ...queryParams,
        status: 'completed',
      },
      title: 'Completed Placements',
      label: 'Completed',
      rowTitle: 'Completed Placements',
      empty: 'No Completed Placements',
    },
  };
};

export const sortParams = {
  pending: [
    {label: 'Created date (newest first)', value: '-created_at'},
    {label: 'Created date (oldest first)', value: '+created_at'},
  ],
  guarantee_period: [
    {
      label: 'Activated date (newest first)',
      value: '-guarantee_period',
    },
    {
      label: 'Activated date (oldest first)',
      value: '+guarantee_period',
    },
  ],
  completed: [
    {
      label: 'Completed date (newest first)',
      value: '-status_guarantee_period_end_date',
    },
    {
      label: 'Completed date (oldest first)',
      value: '+status_guarantee_period_end_date',
    },
  ],
};
