import AppCard from '@wieldy/components/AppCard';
import AppTag from '@wieldy/components/AppTag';
import {Row, Typography} from 'antd';
import {useAddBookmarkToClient} from 'app/services/supplierService';
import {Blocks} from 'app/shared/components';
import Bookmark from 'app/shared/components/Bookmark';
import UserMiniProfile from 'app/shared/components/UserMiniProfile';
import {convertHID} from 'app/utils/helpers/ConvertHID';
import PropTypes from 'prop-types';
import React from 'react';
import {useNavigate} from 'react-router-dom';

import {getClientCardItems} from '../constants';

const ClientCard = ({loading = false, client, showBookmark = false}) => {
  const navigate = useNavigate();
  const mutation = useAddBookmarkToClient();
  const clientName = client?.name
    ? client?.name?.includes('CLI')
      ? `Client ${convertHID(client?.name, 'CLI-')}`
      : client.name
    : '';

  const handleOnBookmark = React.useCallback(() => {
    mutation.mutate({
      id: client?.id,
      isBookmarked: client?.bookmarked,
    });
  }, [mutation, client?.id, client?.bookmarked]);

  const rowItems = React.useMemo(
    () => getClientCardItems(client, {name: clientName, client: client?.id}),
    [client, clientName],
  );

  return (
    <AppCard loading={loading}>
      {showBookmark && (
        <Bookmark
          isBookmarked={client?.bookmarked}
          onClick={handleOnBookmark}
          loading={mutation.isLoading}
        />
      )}
      <Row justify={'space-between'} wrap={false} className='mt-3 mb-7'>
        <UserMiniProfile
          name={clientName}
          avatarUrl={client?.logo}
          showDummyAvatar={true}
          location={client?.location}
          rating={client?.ratingTotal}
          reviews={client?.ratingCount}
          onClick={() => navigate(`/clients/${client?.id}/view-client`)}
        />
      </Row>
      <Blocks.TopCounterBlock items={rowItems.top} />
      <Blocks.ListIconBlock items={rowItems.content} />
      <Row justify={'space-between'} align={'middle'} className='mb-6'>
        <Typography.Title level={5} type={'secondary'} className={'title'}>
          Relationship
          <span className='title-divider' style={{maxWidth: 60}} />
        </Typography.Title>
        <AppTag
          color={client?.relationStatus ? 'blue' : ''}
          shape={'circle'}
          className={'text-xs'}>
          {client?.relationStatus ?? 'N/A'}
        </AppTag>
      </Row>
      <Blocks.BottomCounterBlock items={rowItems.bottom} />
    </AppCard>
  );
};

ClientCard.propTypes = {
  loading: PropTypes.bool,
  client: PropTypes.object,
  showBookmark: PropTypes.bool,
};

export default ClientCard;
