export const validate = ({field}, value, callback) => {
  switch (field) {
    case 'amount': {
      const numberRegex = /^\d*(.\d{0,2})?$/;
      if (value && !numberRegex.test(value)) {
        callback('Amount is not a valid');
      } else {
        callback();
      }
      break;
    }
    case 'purpose': {
      if (value && value.length > 255) {
        callback('Max length is 255');
      } else {
        callback();
      }
      break;
    }
    case 'notes': {
      if (value && value.length > 4096) {
        callback('Max length is 4096');
      } else {
        callback();
      }
      break;
    }
    default: {
      callback();
    }
  }
};
