//import Login from './Login';
import MyAccount from './MyAccount';
import Notifications from './Notifications';

export * as Error from './errors';

export const User = {
  MyAccount,
  Notifications,
};
