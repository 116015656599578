import PropTypes from 'prop-types';
import React from 'react';

import AppLoader from '../AppLoader';

const AppSuspense = ({loadingProps, children}) => {
  return (
    <React.Suspense fallback={<AppLoader {...loadingProps} />}>
      {children}
    </React.Suspense>
  );
};

AppSuspense.propTypes = {
  loadingProps: PropTypes.object,
  children: PropTypes.node.isRequired,
};

AppSuspense.defaultProps = {
  loadingProps: {
    delay: 300,
  },
};

export default AppSuspense;
