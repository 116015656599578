import AppLoader from '@wieldy/components/AppLoader';
import AppPageContainer from '@wieldy/components/pageComponents/AppPageContainer';
import AppPageHeader from '@wieldy/components/pageComponents/AppPageHeader';
import {QUERY_CONSTANTS} from 'app/constants/index.js';
import {useAccountType} from 'app/hooks/useAccountType';
import {AppStepsWrapper} from 'app/providers/AppStepProvider';
import {useGetResourceDetail} from 'app/services/resourceService';
import {Preview} from 'app/shared/requisitions/RequisitionSteps';
import {
  resetResource,
  setInitial,
} from 'app/store/features/resource/resourceSlice';
import {getFullName} from 'app/utils/helpers/DataFormatHelper';
import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';

import ResourceStepper from './MainContent/ResourceStepper';

const EditResource = () => {
  const {id} = useParams();
  const dispatch = useDispatch();
  const {isClient} = useAccountType();
  const {isLoading, data} = useGetResourceDetail(
    id,
    {
      include: ['engagements', 'skill_tags'],
    },
    QUERY_CONSTANTS.SUPPLIER.VIEW,
    isClient,
    true,
  );

  const {resourceData} = data || {
    resourceData: null,
  };

  useEffect(() => {
    if (resourceData) {
      dispatch(setInitial(resourceData));
    }
  }, [dispatch, resourceData]);

  useEffect(() => {
    return () => {
      dispatch(resetResource());
    };
  }, [dispatch]);

  if (isLoading && !resourceData) {
    return <AppLoader />;
  }

  return (
    <AppStepsWrapper
      isLoading={isLoading}
      preview={<Preview />}
      isUpdated={true}>
      <AppPageContainer
        header={
          <AppPageHeader
            backHeader
            category={'Resource'}
            title={getFullName(resourceData)}
          />
        }>
        <ResourceStepper />
      </AppPageContainer>
    </AppStepsWrapper>
  );
};

export default EditResource;
