import {useGetClients, useGetSuppliers} from 'app/services/clientService';
import {useGetRequisitions} from 'app/services/requisitionService';
import {useGetResources} from 'app/services/resourceService';
import {useMemo} from 'react';

export const useSearchFetch = (entity) => {
  return useMemo(() => {
    switch (entity) {
      case 'clients':
        return useGetClients;
      case 'requisitions':
        return useGetRequisitions;
      case 'suppliers':
        return useGetSuppliers;
      case 'resources':
        return useGetResources;
    }
  }, [entity]);
};
