import {Typography} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

import TimeCardTable from '../TimeCardTable';

const LogTimecard = ({isShowDetails, isShowForm}) => {
  return (
    <React.Fragment>
      {isShowDetails ? (
        <Typography.Title level={2}>Not Available</Typography.Title>
      ) : (
        isShowForm && <TimeCardTable />
      )}
    </React.Fragment>
  );
};

LogTimecard.propTypes = {
  isShowDetails: PropTypes.bool,
  isShowForm: PropTypes.bool,
};

export default LogTimecard;
