import AppCard from '@wieldy/components/AppCard';
import {AppFormActions, AppFormBody} from '@wieldy/components/AppFormWrapper';
import AppFormWrapper from '@wieldy/components/AppFormWrapper/AppFormWrapper';
import AppLoader from '@wieldy/components/AppLoader';
import AppInputNumber from '@wieldy/components/dataEntry/AppInputNumber';
import AppSelect from '@wieldy/components/dataEntry/AppSelect';
import {
  getRequiredMessage,
  getSelectPlaceHolder,
} from '@wieldy/utils/helpers/MessageHelper';
import {Button, Col, Form, Radio, Row, Typography} from 'antd';
import {useForm} from 'antd/es/form/Form';
import {setBilling} from 'app/store/features/resource/resourceSlice';
import {allowPositiveExactNumber} from 'app/utils/helpers/preventNegativeNumber';
import ResourceHelper from 'app/utils/ResourceHelper';
import PropTypes from 'prop-types';
import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

const radioOptions = [
  {label: 'Billing Rate', value: 'exact'},
  {label: 'Billing Rate Range', value: 'range'},
];

const BillingAndEnvironmentForm = ({onContinue}) => {
  const dispatch = useDispatch();
  const [form] = useForm();
  const {billing, isInit} = useSelector(({resource}) => resource);
  const handleSubmit = (values) => {
    const billingFields = values.billing_rate
      ? {
          billing_rate: values.billing_rate,
          billing_rate_min: null,
          billing_rate_max: null,
        }
      : {
          billing_rate: null,
          billing_rate_min: values.billing_rate_min,
          billing_rate_max: values.billing_rate_max,
        };

    dispatch(setBilling({...values, ...billingFields}));
    onContinue();
  };

  useEffect(() => {
    if (billing.billing_rate_min || billing.billing_rate_max) {
      setBillingType('range');
    } else {
      setBillingType('exact');
    }
  }, [billing]);

  const [billingType, setBillingType] = useState('exact');
  const handleChangeBillingType = useCallback(
    (e) => {
      setBillingType(e.target.value);
      if (e.target.value === 'range') {
        form.setFieldValue('billing_rate', undefined);
      } else {
        form.setFieldValue('billing_rate_min', undefined);
        form.setFieldValue('billing_rate_max', undefined);
      }
    },
    [form],
  );

  const isRange = billingType === 'range';

  const validate = (_, value, callback) => {
    const numberRegex = /^\d*(.\d{0,2})?$/;
    if (value && !numberRegex.test(value)) {
      callback('Billing Rate is not a valid');
    } else {
      callback();
    }
  };

  if (!isInit) {
    return <AppLoader />;
  }

  return (
    <AppCard>
      <AppFormWrapper>
        <Form
          form={form}
          layout='vertical'
          onFinish={handleSubmit}
          autoComplete='off'
          initialValues={billing}>
          <AppFormBody>
            <Typography.Title level={4}>Billing Rates</Typography.Title>
            <Radio.Group
              options={radioOptions}
              onChange={handleChangeBillingType}
              value={billingType}
            />
            <Row align='bottom'>
              {isRange || (
                <Col className='mr-2'>
                  <Form.Item
                    name='billing_rate'
                    rules={[
                      {
                        required: true,
                        message: getRequiredMessage('Rate'),
                      },
                      {validator: validate},
                    ]}>
                    <AppInputNumber
                      placeholder='Enter exact rate'
                      prefix='$'
                      addonAfter='USD'
                      type='number'
                      pattern='[0-9]+([\.,][0-9]+)?'
                      step='any'
                      style={{width: '200px'}}
                      controls={false}
                    />
                  </Form.Item>
                </Col>
              )}
              {isRange && (
                <>
                  <Col className='mr-2'>
                    <Form.Item
                      name='billing_rate_min'
                      rules={[
                        {
                          required: true,
                          message: getRequiredMessage('Rate'),
                        },
                      ]}>
                      <AppInputNumber
                        onKeyDown={allowPositiveExactNumber}
                        placeholder='From rate'
                        prefix='$'
                        addonAfter='USD'
                        type='number'
                        pattern='[0-9]+([\.,][0-9]+)?'
                        step='any'
                        style={{width: '200px'}}
                        controls={false}
                      />
                    </Form.Item>
                  </Col>
                  <Col className='mr-2'>
                    <Form.Item
                      name='billing_rate_max'
                      rules={[
                        {
                          required: true,
                          message: getRequiredMessage('Rate'),
                        },
                      ]}>
                      <AppInputNumber
                        onKeyDown={allowPositiveExactNumber}
                        placeholder='To rate'
                        prefix='$'
                        addonAfter='USD'
                        type='number'
                        pattern='[0-9]+([\.,][0-9]+)?'
                        step='any'
                        style={{width: '200px'}}
                        controls={false}
                      />
                    </Form.Item>
                  </Col>
                </>
              )}
            </Row>
            <div style={{maxWidth: 280, width: '100%'}}>
              <Form.Item
                name='billing_rate_period'
                label='Billing Rate Period'
                rules={[
                  {
                    required: true,
                    message: getRequiredMessage('Billing Rate Period'),
                  },
                ]}>
                <AppSelect
                  placeholder={getSelectPlaceHolder('period')}
                  options={ResourceHelper.billingRatePeriods()}
                />
              </Form.Item>
              <Form.Item
                name='billing_rate_negotiations'
                label='Open to Rate Negotiation'
                rules={[
                  {
                    required: false,
                    message: getRequiredMessage('Open to Rate Negotiation'),
                  },
                ]}>
                <AppSelect
                  placeholder='Is open to negotiation'
                  options={ResourceHelper.yesNoOptions()}
                />
              </Form.Item>
              <Form.Item
                name='billing_rate_buy_out'
                label='Open to Resource Buyout'
                rules={[
                  {
                    required: false,
                    message: getRequiredMessage('Open to Resource Buyout'),
                  },
                ]}>
                <AppSelect
                  placeholder='Is open to buyout'
                  options={ResourceHelper.yesNoOptions()}
                />
              </Form.Item>
            </div>
            <Typography.Title level={4}>Work Environment</Typography.Title>
            <div style={{maxWidth: 280, width: '100%'}}>
              <Form.Item
                name='work_location'
                label='Work Location Type'
                rules={[
                  {
                    required: false,
                    message: getRequiredMessage('Work Location Type'),
                  },
                ]}>
                <AppSelect
                  placeholder={getSelectPlaceHolder('location type')}
                  options={ResourceHelper.workLocations()}
                />
              </Form.Item>
              <Form.Item
                name='work_computer_type'
                label='Work Computer'
                rules={[
                  {
                    required: false,
                    message: getRequiredMessage('Work Computer'),
                  },
                ]}>
                <AppSelect
                  placeholder={getSelectPlaceHolder('option')}
                  options={ResourceHelper.workComputerTypes()}
                />
              </Form.Item>
              <Form.Item
                name='work_computer_os_type'
                label='Work Computer OS'
                rules={[
                  {
                    required: false,
                    message: getRequiredMessage('Work Computer OS'),
                  },
                ]}>
                <AppSelect
                  placeholder={getSelectPlaceHolder('option')}
                  options={ResourceHelper.wokComputerOsTypes()}
                />
              </Form.Item>
              <Form.Item
                name='work_conference_room_access'
                label='Conference Room Access'
                rules={[
                  {
                    required: false,
                    message: getRequiredMessage('Conference Room Access'),
                  },
                ]}>
                <AppSelect
                  placeholder={getSelectPlaceHolder('option')}
                  options={ResourceHelper.yesNoOptions()}
                />
              </Form.Item>
              <Form.Item
                name='work_printer_scanner'
                label='Printer / Scanner Access'
                rules={[
                  {
                    required: false,
                    message: getRequiredMessage('Printer / Scanner Access'),
                  },
                ]}>
                <AppSelect
                  placeholder={getSelectPlaceHolder('option')}
                  options={ResourceHelper.printerScannerAccess()}
                />
              </Form.Item>
              <Form.Item
                name='work_extra_monitor'
                label='Extra Monitor'
                rules={[
                  {
                    required: false,
                    message: getRequiredMessage('Extra Monitor'),
                  },
                ]}>
                <AppSelect
                  placeholder={getSelectPlaceHolder('option')}
                  options={ResourceHelper.yesNoOptions()}
                />
              </Form.Item>
            </div>
          </AppFormBody>
          <AppFormActions>
            <Button type='primary' htmlType='submit'>
              Continue
            </Button>
          </AppFormActions>
        </Form>
      </AppFormWrapper>
    </AppCard>
  );
};

BillingAndEnvironmentForm.propTypes = {
  onContinue: PropTypes.func,
  onSave: PropTypes.func,
};

export default BillingAndEnvironmentForm;
