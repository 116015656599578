import {convertHID} from 'app/utils/helpers/ConvertHID';
import {getFullName} from 'app/utils/helpers/DataFormatHelper';

export const getName = (item) => {
  if (item.name === item.hid) {
    if (item.hid.includes('SUP')) {
      return `Supplier ${convertHID(item?.name, 'SUP-')}`;
    }
  }
  return item.name || getFullName(item);
};
