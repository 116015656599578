import AppBorderBox from '@wieldy/components/AppBorderBox';
import AppCard from '@wieldy/components/AppCard';
import {Rate, Typography} from 'antd';
import RatingItem from 'app/shared/components/RatingItem';
import PropTypes from 'prop-types';
import React from 'react';

const RatingCard = ({ratings, count, total}) => {
  return (
    <AppCard
      title='Supplier Rating'
      headStyle={{borderBottom: 0}}
      bodyStyle={{paddingTop: 8}}>
      <AppBorderBox>
        <div className='text-center'>
          <Typography.Title level={4}>Overall Rating</Typography.Title>
          <div className='rate-root d-flex align-items-center justify-content-center'>
            <Rate
              disabled
              value={total}
              allowHalf={true}
              count={5}
              style={{lineHeight: '18px'}}
            />
            <Typography.Text
              className='text-sm ml-2'
              style={{marginTop: '3px'}}>
              {total} out of 5
            </Typography.Text>
            <Typography.Text
              type={'secondary'}
              className='font-semibold text-xs ml-2'
              style={{marginTop: '3px'}}>
              ({count})
            </Typography.Text>
          </div>
        </div>
      </AppBorderBox>
      <div className='mt-3'>
        <RatingItem rating={ratings?.communication} label='Communication' />
        <RatingItem rating={ratings?.responsiveness} label='Responsiveness' />
        <RatingItem
          rating={ratings?.reliability_and_consistency}
          label='Reliability and Consistency'
        />
        <RatingItem
          rating={ratings?.engagement_and_followup}
          label='Engagement and Followup'
        />
        <RatingItem
          rating={ratings?.resource_management}
          label='Resource Management'
        />
      </div>
    </AppCard>
  );
};

RatingCard.propTypes = {
  ratings: PropTypes.object,
  count: PropTypes.number,
  total: PropTypes.number,
};

export default React.memo(RatingCard);
