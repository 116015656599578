import ShowRegion from '@register-app/components/additional/show-region';
import {INDUSTRY_FOCUS} from '@register-app/constants/industries-data';
import {JOB_FUNCTIONS} from '@register-app/constants/job-functions';
import {SERVICE_FOCUS} from '@register-app/constants/service-focus';
import AppCard from '@wieldy/components/AppCard';
import AppTag from '@wieldy/components/AppTag';
import {Typography} from 'antd';
import {COUNTRIES, US_STATES} from 'app/constants';
import PropTypes from 'prop-types';
import React from 'react';

const getDate = (data, placeholder) => {
  if (!data) {
    return null;
  }
  const entries = Object.entries(data);
  return entries
    .filter(([, {value}]) => !!value)
    .map(([key, data]) => {
      return {
        ...data,
        value: placeholder[key]?.value,
      };
    });
};
const ServiceRegionsView = ({details}) => {
  const industries = getDate(
    details?.specialization_industry_focuses,
    INDUSTRY_FOCUS,
  );
  const jobFunctions = getDate(
    details?.specialization_job_functions,
    JOB_FUNCTIONS,
  );
  const serviceRegions = getDate(
    details?.specialization_service_focuses,
    SERVICE_FOCUS,
  );

  return (
    <AppCard style={{maxWidth: 820}} className='form-service-view'>
      {!!industries?.length && (
        <div className='mb-5'>
          <Typography.Title level={4}>Industry Focus</Typography.Title>
          {industries.map(({value, items}) => (
            <ShowRegion key={value} name={value} items={items} />
          ))}
        </div>
      )}
      {!!jobFunctions?.length && (
        <div className='mb-5'>
          <Typography.Title level={4}>Job Functions</Typography.Title>
          {jobFunctions.map(({value, items}) => (
            <ShowRegion key={value} name={value} items={items} />
          ))}
        </div>
      )}
      {!!serviceRegions?.length && (
        <div className='mb-5'>
          <Typography.Title level={4}>Service Focus</Typography.Title>
          {serviceRegions.map(({value, items}) => (
            <ShowRegion key={value} name={value} items={items} />
          ))}
        </div>
      )}
      {!!details?.service_region_countries?.length && (
        <div className='d-flex flex-column mb-5'>
          <Typography.Title level={4}>Service Regions</Typography.Title>
          <div className='d-flex gap-2'>
            {details?.service_region_countries.map((el) => (
              <AppTag
                key={el}
                className={'border-none'}
                shape={'square'}
                label={COUNTRIES[el]}
              />
            ))}
          </div>
        </div>
      )}
      {!!details?.service_region_us_states?.length && (
        <div className='d-flex flex-column'>
          <Typography.Title level={4}>
            Service Areas in the United States
          </Typography.Title>
          <div className='d-flex gap-2'>
            {details?.service_region_us_states.map((el) => (
              <AppTag
                key={el}
                className={'border-none'}
                shape={'square'}
                label={US_STATES[el]}
              />
            ))}
          </div>
        </div>
      )}
    </AppCard>
  );
};

ServiceRegionsView.propTypes = {
  details: PropTypes.object,
};

export default ServiceRegionsView;
