export const DURATION = {
  '1_week': '1 week',
  '2_weeks': '2 weeks',
  '3_weeks': '3 weeks',
  '4_weeks': '4 weeks',
  '1_month': '1 month',
  '2_months': '2 months',
  '3_months': '3 months',
  '4_months': '4 months',
  '5_months': '5 months',
  '6_months': '6 months',
  '7_months': '7 months',
  '8_months': '8 months',
  '9_months': '9 months',
  '10_months': '10 months',
  '11_months': '11 months',
  '1_year': '1 year',
  over_1_year: 'Over 1 year',
};
