export const baseTabsData = [
  {
    key: 'submitted',
    title: 'Pending Approval',
    route: '/expenses/submitted',
  },
  {key: 'approved', title: 'Approved', route: '/expenses/approved'},
  {key: 'rejected', title: 'Rejected', route: '/expenses/rejected'},
];

export const supplierTabsData = [
  {key: 'new', title: 'Not Submitted', route: '/expenses/new'},
];
