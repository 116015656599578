import PropTypes from 'prop-types';
import React from 'react';

const AppAmountView = ({amount, decimalDigits, operator}) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: decimalDigits,
  });

  if (!amount) {
    return formatter.format(0);
  }

  return (
    <React.Fragment>
      {formatter.format(+amount)}
      {operator && <span className={'ml-1'}>{operator}</span>}
    </React.Fragment>
  );
};

AppAmountView.propTypes = {
  amount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  decimalDigits: PropTypes.number,
  operator: PropTypes.string,
};

AppAmountView.defaultProps = {
  decimalDigits: 2,
};

export default AppAmountView;
