export default {
  alabama: 'Alabama',
  alaska: 'Alaska',
  american_samoa: 'American Samoa',
  arizona: 'Arizona',
  arkansas: 'Arkansas',
  california: 'California',
  colorado: 'Colorado',
  connecticut: 'Connecticut',
  delaware: 'Delaware',
  district_of_columbia: 'District of Columbia',
  florida: 'Florida',
  georgia: 'Georgia',
  guam: 'Guam',
  hawaii: 'Hawaii',
  idaho: 'Idaho',
  illinois: 'Illinois',
  indiana: 'Indiana',
  iowa: 'Iowa',
  kansas: 'Kansas',
  kentucky: 'Kentucky',
  louisiana: 'Louisiana',
  maine: 'Maine',
  maryland: 'Maryland',
  massachusetts: 'Massachusetts',
  michigan: 'Michigan',
  minnesota: 'Minnesota',
  mississippi: 'Mississippi',
  missouri: 'Missouri',
  montana: 'Montana',
  nebraska: 'Nebraska',
  nevada: 'Nevada',
  new_hampshire: 'New Hampshire',
  mew_jersey: 'New Jersey',
  new_mexico: 'New Mexico',
  new_york: 'New York',
  north_carolina: 'North Carolina',
  north_dakota: 'North Dakota',
  northern_mariana_islands: 'Northern Mariana Islands',
  ohio: 'Ohio',
  oklahoma: 'Oklahoma',
  oregon: 'Oregon',
  pennsylvania: 'Pennsylvania',
  puerto_rico: 'Puerto Rico',
  rhode_island: 'Rhode Island',
  south_carolina: 'South Carolina',
  south_dakota: 'South Dakota',
  tennessee: 'Tennessee',
  texas: 'Texas',
  utah: 'Utah',
  vermont: 'Vermont',
  virginia: 'Virginia',
  washington: 'Washington',
  west_virginia: 'West Virginia',
  wisconsin: 'Wisconsin',
  wyoming: 'Wyoming',
  us_virgin_islands: 'U.S. Virgin Islands',
};
