import AppCard from '@wieldy/components/AppCard';
import AppLoader from '@wieldy/components/AppLoader';
import {Button, Empty, List, Typography} from 'antd';
import {useGetInfinityNotifications} from 'app/services/notificatonService';
import React, {useMemo} from 'react';
import {RiNotification3Line} from 'react-icons/ri';

import NotificationItem from './NotificationItem';

const MainContent = () => {
  const {
    data,
    isFetching,
    isLoading,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
  } = useGetInfinityNotifications({
    limit: 8,
    sort: ['-created_at'],
    include: ['total_count'],
  });

  const [, notifications] = useMemo(() => {
    return [
      data?.pages[0].total_count || 0,
      data?.pages?.reduce((acc, page) => acc.concat(page.data), []) || [],
    ];
  }, [data]);

  const loadMore = hasNextPage ? (
    <div
      style={{
        textAlign: 'center',
        padding: '20px',
      }}>
      <Button
        loading={isFetchingNextPage}
        onClick={fetchNextPage}
        type='primary'>
        Load More
      </Button>
    </div>
  ) : null;

  if (isLoading) {
    return <AppLoader />;
  }

  return (
    <div style={{maxWidth: 920, margin: '0 auto'}}>
      <AppCard
        title={
          <div className={'d-flex align-items-center'}>
            <RiNotification3Line fontSize={20} className='mr-4' />
            <Typography.Text>All Notifications</Typography.Text>
          </div>
        }
        bodyStyle={{padding: 0, paddingTop: 1}}>
        <List
          locale={{
            emptyText: (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description='No Notifications'
              />
            ),
          }}
          className={'all-notification'}
          itemLayout='horizontal'
          loadMore={loadMore}
          dataSource={notifications}
          renderItem={(item) => (
            <NotificationItem notification={item} loading={isFetching} />
          )}
        />
      </AppCard>
    </div>
  );
};

export default MainContent;
