import useReactQuery, {useMutation} from 'app/hooks/useReactQuery';
import axios from 'axios';

/** Upload file to aws signed url */
export const useUploadImageToAws = () => {
  return useMutation(async ({uploadUrl, file}) => {
    const response = await axios.put(uploadUrl, file, {
      headers: {
        'Content-Type': file.type,
      },
    });
    return response;
  });
};

export const useUploadFile = (getUploadUrlRequest) => {
  return useMutation(async (file) => {
    const uploadUrlPayload = await getUploadUrlRequest({
      content_type: file.type,
      content_length: file.size,
    });

    await axios.put(uploadUrlPayload.data.upload_url, file, {
      headers: {
        'Content-Type': file.type,
      },
    });

    return uploadUrlPayload.data;
  });
};

export const useGetBlob = (url) => {
  return useReactQuery(
    ['getBlob', url],
    async () => {
      const response = await axios.get(url, {responseType: 'blob'});

      return response.data;
    },
    {enabled: !!url},
  );
};
