import PropTypes from 'prop-types';
import React from 'react';

const AppPaper = ({children, style}) => {
  return (
    <div className='paper' style={style}>
      <div className='paper-body'>{children}</div>
    </div>
  );
};

AppPaper.propTypes = {
  children: PropTypes.node,
  style: PropTypes.object,
};

export default AppPaper;
