import {Col, Descriptions, Row} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

const Summary = ({engagement}) => {
  return (
    <Row gutter={30} justify={'space-between'} className='description-summary'>
      <Col xs={24} md={12}>
        <Descriptions colon={false} labelStyle={{width: '50%'}} column={1}>
          <Descriptions.Item label='Total Reported Hours'>
            {engagement.total_reported_hours}
          </Descriptions.Item>
          <Descriptions.Item label='Adjusted Hours'>
            {engagement.invoice_ajusted_hours}
          </Descriptions.Item>
          <Descriptions.Item label='Invoiced Hours'>
            {engagement.invoice_hours}
          </Descriptions.Item>
          <Descriptions.Item label='Invoiced Time'>
            {engagement.invoice_total_time}
          </Descriptions.Item>
        </Descriptions>
      </Col>
      <Col xs={24} md={12}>
        <Descriptions
          colon={false}
          labelStyle={{width: '50%'}}
          className='description-summary'
          column={1}>
          <Descriptions.Item label='Total Reported Expenses'>
            {engagement.invoice_total_reported_expenses}
          </Descriptions.Item>
          <Descriptions.Item label='Adjusted Expenses'>
            {engagement.invoice_adjusted_expenses}
          </Descriptions.Item>
          <Descriptions.Item label='Invoiced Expenses'>
            {engagement.invoice_expenses}
          </Descriptions.Item>
        </Descriptions>
      </Col>
    </Row>
  );
};
Summary.propTypes = {
  engagement: PropTypes.object,
};
export default Summary;
