import {Descriptions, Typography} from 'antd';
import {useAccountType} from 'app/hooks/useAccountType';
import {useStatus} from 'app/hooks/useStatus';
import PropTypes from 'prop-types';
import React from 'react';

const Overview = ({submission}) => {
  const {isSupplier} = useAccountType();
  const {hid, aboutDates, status_key, candidates} = submission;
  const {isResponded, isWithdrawn} = useStatus(status_key);
  return (
    <div className='mb-6'>
      <Typography.Title level={3} className={'mb-5'}>
        Submission
      </Typography.Title>
      <Descriptions
        colon={false}
        labelStyle={{width: '50%'}}
        className='description-root mb-4'
        column={{xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1}}>
        {!!candidates?.length && (
          <Descriptions.Item label='# of Candidates' span={1}>
            {candidates?.length}
          </Descriptions.Item>
        )}
        <Descriptions.Item label='Submission ID'>{hid}</Descriptions.Item>
        <Descriptions.Item
          label={isSupplier ? 'Submitted' : 'Received'}
          span={1}>
          {aboutDates.received}
        </Descriptions.Item>
        {(isResponded || isWithdrawn) && (
          <Descriptions.Item label='Updated' span={1}>
            {aboutDates.updated}
          </Descriptions.Item>
        )}
      </Descriptions>
    </div>
  );
};

Overview.propTypes = {
  submission: PropTypes.object,
};

export default Overview;
