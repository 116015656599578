import AppLoader from '@wieldy/components/AppLoader';
import AppPageContainer from '@wieldy/components/pageComponents/AppPageContainer';
import AppPageHeader from '@wieldy/components/pageComponents/AppPageHeader';
import {useAccountType} from 'app/hooks/useAccountType';
import {useEngagement} from 'app/hooks/useEngagement';
import {useRole} from 'app/hooks/useRole';
import NotEngagement from 'app/shared/components/NotEngagement';
import {resetExpensesState} from 'app/store/features/expenses/expensesSlice';
import React, {useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';

import MainContent from './MainContent';

const CreateExpense = () => {
  const dispatch = useDispatch();
  const {isClient} = useAccountType();
  const navigate = useNavigate();
  const {temp} = useSelector(({expenses}) => expenses);
  const {titles} = temp;

  useEffect(() => {
    if (isClient) {
      navigate('/expenses');
    }
  }, [isClient, navigate]);

  useEffect(() => {
    return () => {
      dispatch(resetExpensesState());
    };
  }, [dispatch]);

  const {data, isLoading} = useEngagement();
  const engagements = data || [];

  const activeEngagements = engagements.filter(
    (el) => !!el.job_expenses_eligible,
  );
  const isEmpty = !engagements?.length;
  const isActiveEmpty = !activeEngagements?.length;

  const {isResource} = useRole();

  const noEngagement = useMemo(
    () => ({
      title: 'Expense cannot be created',
      subtitle: isResource
        ? 'You are not currently assigned to an active engagement'
        : 'There are no resources in your team who are currently assigned to an active engagement',
    }),
    [isResource],
  );

  const noActiveEngagement = useMemo(
    () => ({
      title: 'Expense cannot be created',
      subtitle: isResource
        ? 'You are not currently assigned to an engagement that allows expense reports to be submitted'
        : 'There are no resources in your team who are currently assigned to an engagement that allows expense reports to be submitted',
    }),
    [isResource],
  );

  const renderContent = () => {
    if (isEmpty) {
      return <NotEngagement {...noEngagement} />;
    }
    if (!isEmpty && isActiveEmpty) {
      return <NotEngagement {...noActiveEngagement} />;
    }

    return <MainContent />;
  };

  return (
    <AppPageContainer
      header={
        <AppPageHeader
          backHeader={isEmpty}
          header='main'
          category='Expense'
          title={titles.company}
          subTitle={titles.resource}
        />
      }>
      {isLoading ? <AppLoader /> : renderContent()}
    </AppPageContainer>
  );
};

export default CreateExpense;
