import AppSelect from '@wieldy/components/dataEntry/AppSelect';
import {getSelectPlaceHolder} from '@wieldy/utils/helpers/MessageHelper';
import {Form} from 'antd';
import ResourceHelper from 'app/utils/ResourceHelper';
import PropTypes from 'prop-types';
import React from 'react';
import {useSelector} from 'react-redux';

const LanguagesForm = (props) => {
  const {last, children} = props;
  const {supplemental} = useSelector(({resource}) => resource);
  const {languages} = supplemental;

  if (last) {
    return (
      <tr {...props}>
        <td className='ant-table-cell'>
          <Form.Item style={{maxWidth: 240}} name='language'>
            <AppSelect
              showSearch={true}
              options={ResourceHelper.languages(languages)}
              placeholder={getSelectPlaceHolder('language')}
              controls={false}
            />
          </Form.Item>
        </td>
        <td className='ant-table-cell'>
          <Form.Item name='level' style={{textAlign: 'left'}}>
            <AppSelect
              options={ResourceHelper.languageLevel()}
              placeholder={getSelectPlaceHolder('level')}
              controls={false}
            />
          </Form.Item>
        </td>
        <td
          className='ant-table-cell ant-table-cell-fix-right ant-table-cell-fix-right-first'
          style={{textAlign: 'left', position: 'sticky', right: 0}}
        />
      </tr>
    );
  }
  return <tr {...props}>{children}</tr>;
};

LanguagesForm.propTypes = {
  last: PropTypes.bool,
  children: PropTypes.node,
};
export default LanguagesForm;
