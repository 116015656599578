import './index.style.less';
import './styles/index.scss';

import AppPageContainer from '@wieldy/components/pageComponents/AppPageContainer';
import {useAccountType} from 'app/hooks/useAccountType';
import {useChatContext} from 'stream-chat-react';

import ClientSupplierChat from './containers/ClientSupplierChat';
import MarketplaceChat from './containers/MarketplaceChat';

const Messages = () => {
  const accountType = useAccountType();

  const {client} = useChatContext();

  if (!client) return null;

  return (
    <div className='app__wrapper str-chat'>
      <AppPageContainer contentClass='chatWrapper'>
        {accountType.isMarketplace ? (
          <MarketplaceChat />
        ) : (
          <ClientSupplierChat />
        )}
      </AppPageContainer>
    </div>
  );
};

export default Messages;
