import {Typography} from 'antd';
import {DATE_FORMATS} from 'app/config/app';
import {PERIODS, RESOURCE_AVAILABILITY} from 'app/constants';
import {capitalizeFirstLetter} from 'app/utils/helpers/Capitalize';
import {formatDate, getAddress} from 'app/utils/helpers/DataFormatHelper';
import React from 'react';
import {Link} from 'react-router-dom';

export const getColumns = () => {
  return [
    {
      title: 'Name/Location',
      dataIndex: 'id',
      key: 'id',
      render: (_, {name, id, country, city, us_state}) => {
        return (
          <Typography.Paragraph>
            <Typography.Paragraph className={'mb-0'}>
              <Link
                to={`/resources/${id}/view-resource`}
                component={Typography.Link}>
                {name}
              </Link>
            </Typography.Paragraph>
            <Typography.Text type={'secondary'} className={'text-xs'}>
              {getAddress({country, city, us_state})}
            </Typography.Text>
          </Typography.Paragraph>
        );
      },
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Billing Rate',
      dataIndex: 'billingRate',
      key: 'billingRate',
      render: (billingRate, {billingRatePeriod}) => (
        <Typography.Text>
          {billingRate}
          {PERIODS[billingRatePeriod] ? ` / ${PERIODS[billingRatePeriod]}` : ''}
        </Typography.Text>
      ),
    },
    {
      title: 'Resource Status',
      dataIndex: 'availability',
      key: 'availability',
      render: (status) => {
        return (
          <Typography.Text>
            {RESOURCE_AVAILABILITY[status] || capitalizeFirstLetter(status)}
          </Typography.Text>
        );
      },
    },
    {
      title: 'Available On',
      dataIndex: 'available_on',
      key: 'available_on',
      render: (available_on) => (
        <Typography.Text>
          {formatDate(available_on, DATE_FORMATS.md) || 'N/A'}
        </Typography.Text>
      ),
    },
  ];
};
