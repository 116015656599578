import PropTypes from 'prop-types';
import React from 'react';

import BackHeader from './headers/BackHeader';
import InnerPageHeader from './headers/InnerPageHeader';
import MainPageHeader from './headers/MainPageHeader';

const HEADER_COMPONENTS = {
  main: MainPageHeader,
  inner: InnerPageHeader,
};

const AppPageHeader = ({
  header,
  backHeader,
  backHeaderActions,
  ...restProps
}) => {
  const HeaderComponent = HEADER_COMPONENTS[header];
  return (
    <React.Fragment>
      <HeaderComponent
        backHeader={backHeader && <BackHeader actions={backHeaderActions} />}
        {...restProps}
      />
    </React.Fragment>
  );
};

AppPageHeader.propTypes = {
  header: PropTypes.oneOf(['main', 'inner']),
  variant: PropTypes.oneOf(['default', 'primary']),
  title: PropTypes.string,
  subTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  category: PropTypes.string,
  count: PropTypes.oneOfType([PropTypes.number, PropTypes.node]),
  tabs: PropTypes.node,
  extra: PropTypes.node,
  backHeader: PropTypes.bool,
  topContent: PropTypes.node,
  backHeaderActions: PropTypes.node,
};

AppPageHeader.defaultProps = {
  header: 'main',
  variant: 'default',
};

export default AppPageHeader;
