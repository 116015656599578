import AppTag from '@wieldy/components/AppTag';
import AppPageContainer from '@wieldy/components/pageComponents/AppPageContainer';
import AppPageHeader from '@wieldy/components/pageComponents/AppPageHeader';
import {Button, Divider, Row, Typography} from 'antd';
import PropTypes from 'prop-types';
import {RiArrowLeftRightLine} from 'react-icons/ri';

import MainContent from './MainContent';

const Preview = ({onSubmit, onCancel}) => {
  return (
    <AppPageContainer
      header={
        <AppPageHeader
          category='Placement'
          header='inner'
          topContent={
            <div className='d-flex justify-content-center mb-8'>
              <div className='alert-default'>
                <Typography.Paragraph className='my-2 text-lg mr-4'>
                  This is a preview of the placement you are about to create.
                </Typography.Paragraph>
                <Button
                  type='primary'
                  shape='round'
                  className='mr-4'
                  onClick={onSubmit}>
                  Submit
                </Button>
                <Button shape='round' onClick={onCancel}>
                  Make Changes
                </Button>
              </div>
            </div>
          }
          ghost>
          <Row className='page-header-paper text-uppercase mb-5' align='middle'>
            <div className={'paper paper-sm'}>
              <div className={'paper-body'}>
                <Typography.Title
                  level={4}
                  type='secondary'
                  className='text-xs mb-1'>
                  Supplier
                </Typography.Title>
                <Typography.Title level={5} className={'text-sm my-0'}>
                  <Typography.Link>LOW COST CODERS INC</Typography.Link>
                </Typography.Title>
              </div>
            </div>
            <RiArrowLeftRightLine fontSize={16} className='mx-4' />
            <div className={'paper paper-sm'}>
              <div className={'paper-body'}>
                <Typography.Title
                  level={4}
                  type='secondary'
                  className='text-xs mb-1'>
                  Client
                </Typography.Title>
                <Typography.Title level={5} className={'text-sm my-0'}>
                  <Typography.Link>ELECTROSHOCK INC.</Typography.Link>
                </Typography.Title>
              </div>
            </div>
          </Row>
          <Typography.Title
            level={5}
            type='secondary'
            className='text-uppercase text-xs tracking-normal mb-3'>
            resource
          </Typography.Title>
          <Typography.Title
            level={2}
            className={'d-flex flex-wrap align-items-center'}>
            Lara Keller
            <Divider type='vertical' style={{height: 20}} />
            <Typography.Text type='secondary' className='text-md mr-2'>
              Java Technical Architect
            </Typography.Text>
            <AppTag
              label='Active'
              shape={'circle'}
              color='success'
              className={'tracking-normal'}
            />
          </Typography.Title>
        </AppPageHeader>
      }>
      <MainContent />
    </AppPageContainer>
  );
};

Preview.propTypes = {
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  placement: PropTypes.object,
};

export default Preview;
