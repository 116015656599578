import AppCard from '@wieldy/components/AppCard';
import {Descriptions, Typography} from 'antd';
import React from 'react';
import {useSelector} from 'react-redux';

import {
  BASE_SALARY_PERIOD,
  BILLING_RATE_PERIODS,
} from '../../../../../../constants';
import {
  formatBillingRateRage,
  getYesOrNo,
} from '../../../../../../utils/helpers/DataFormatHelper';

const BillingAndEnvironmentDetails = () => {
  const {billing, summary} = useSelector(({resource}) => resource);
  const {
    billing_rate,
    billing_rate_min,
    billing_rate_max,
    base_salary,
    base_salary_min,
    base_salary_max,
  } = billing;
  const billingRate = summary.isPermanent
    ? formatBillingRateRage(base_salary, base_salary_min, base_salary_max)
    : formatBillingRateRage(billing_rate, billing_rate_min, billing_rate_max);

  return (
    <AppCard style={{maxWidth: 990}}>
      <Typography.Title level={4}>
        {summary.isPermanent ? 'Salary Expectations' : 'Billing rates'}
      </Typography.Title>
      <Descriptions
        colon={false}
        labelStyle={{width: '50%'}}
        className='description-root mb-4'
        column={{xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1}}>
        <Descriptions.Item label='Billing Rate'>
          {billingRate}
        </Descriptions.Item>
        {summary.isPermanent ? (
          <Descriptions.Item label='Base Salary Period'>
            {BASE_SALARY_PERIOD[billing.base_salary_period]}
          </Descriptions.Item>
        ) : (
          <Descriptions.Item label='Billing Rate Period'>
            {BILLING_RATE_PERIODS[billing.billing_rate_period]}
          </Descriptions.Item>
        )}

        <Descriptions.Item
          label={
            summary.isPermanent
              ? 'Open to Salary Negotiation'
              : 'Open to Rate Negotiation'
          }>
          {getYesOrNo(billing.billing_rate_negotiations)}
        </Descriptions.Item>
        {summary.isPermanent && <Descriptions.Item>{}</Descriptions.Item>}
        {!summary.isPermanent && (
          <Descriptions.Item label='Open to Resource Buyout'>
            {getYesOrNo(billing.billing_rate_buy_out)}
          </Descriptions.Item>
        )}
      </Descriptions>
    </AppCard>
  );
};

export default BillingAndEnvironmentDetails;
