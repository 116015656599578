import AppTable from '@wieldy/components/AppTable';
import {useGetColumns} from 'app/pages/main/financials/ViewInvoice/MainContent/common/columns';
import PropTypes from 'prop-types';
import React from 'react';

const CommonTable = ({invoice}) => {
  const isEngagement = !!invoice?.engagement;
  const columns = useGetColumns(isEngagement);

  return (
    <AppTable
      style={{maxWidth: 650}}
      columns={columns}
      dataSource={[invoice]}
      pagination={false}
    />
  );
};

CommonTable.propTypes = {
  invoice: PropTypes.object,
};

export default CommonTable;
