import {Typography} from 'antd';
import {capitalizeFirstLetter} from 'app/utils/helpers/Capitalize.js';
import React from 'react';

export const getColumns = (navigate) => {
  const handleSinglePageView = (id) => {
    navigate(`/requisitions/${id}/view-requisition`);
  };

  return [
    {
      title: 'Job Title',
      dataIndex: 'jobTitle',
      key: 'jobTitle',
      render: (title, item) => (
        <Typography.Paragraph>
          <Typography.Paragraph className={'mb-0'}>
            <Typography.Link onClick={() => handleSinglePageView(item.id)}>
              {title}
            </Typography.Link>
          </Typography.Paragraph>
          <Typography.Text type={'secondary'} className={'text-xs'}>
            {item?.hid}
          </Typography.Text>
        </Typography.Paragraph>
      ),
    },
    {
      title: 'Filled / Openings',
      dataIndex: 'id',
      key: 'id',
      render: (_, {filled, filled_openings, openings}) =>
        (filled || filled_openings || 0) + '/' + (openings || 0),
    },
    {
      title: 'Job Location',
      dataIndex: 'jobWorkSite',
      key: 'jobWorkSite',
    },
    {
      title: 'Start Date',
      dataIndex: 'startDate',
      key: 'start_date',
      sorter: true,
      showSorterTooltip: false,
      defaultSortOrder: 'ascend',
    },
    {
      title: 'End Date',
      dataIndex: 'endDate',
      key: 'end_date',
      sorter: true,
      showSorterTooltip: false,
    },
    {
      title: 'Duration',
      dataIndex: 'jobDuration',
      key: 'jobDuration',
    },
    {
      title: 'Requisition Status',
      dataIndex: 'status',
      key: 'status',
      render: (value) => (
        <Typography.Text>{capitalizeFirstLetter(value)}</Typography.Text>
      ),
    },
  ];
};
