const processInlineStyles = (text) => {
  let processedText = text;
  processedText = processedText.replace(
    /(\*\*|__)(.*?)\1/g,
    '<strong>$2</strong>',
  );
  processedText = processedText.replace(/(\*|_)(.*?)\1/g, '<em>$2</em>');
  processedText = processedText.replace(/(\*\*|__)(.*?)\1/g, '<i>$2</i>');
  return processedText;
};

export const convertToHtml = (editorData) => {
  if (!editorData) return null;

  let html = '';

  if (typeof editorData === 'object') {
    editorData.forEach((block) => {
      switch (block.type) {
        case 'paragraph':
          html += `<p>${processInlineStyles(block.data.text)}</p>`;
          break;
        case 'header':
          html += `<h${block.data.level}>${processInlineStyles(
            block.data.text,
          )}</h${block.data.level}>`;
          break;
        case 'list':
          html += `<ul>`;
          block.data.items.forEach((item) => {
            html += `<li>${processInlineStyles(item)}</li>`;
          });
          html += `</ul>`;
          break;
        case 'checklist':
          html += `<ul>`;
          block.data.items.forEach((item) => {
            const checked = item.checked ? 'checked' : '';
            html += `<li><input type="checkbox" ${checked} disabled>${processInlineStyles(
              item.text,
            )}</li>`;
          });
          html += `</ul>`;
          break;
        default:
          break;
      }
    });
  } else {
    html = editorData;
  }
  return html;
};
