import {Typography} from 'antd';
import useStep from 'app/hooks/useStep';
import React from 'react';
import {useSelector} from 'react-redux';

import PageQueryProvider from '../../../../../../providers/PageQueryProvider/PageQueryProviderNew';
import {filterConfig} from '../../../../../../shared/supplier/FilterSuppliers';
import ChooseSupplierContainer from './ChooseContainer';
import SelectedList from './SelectedList';

const ChooseSuppliers = () => {
  const {currentStep} = useStep();
  const {requisition} = useSelector(({inquiry}) => inquiry);

  return (
    <React.Fragment>
      {currentStep === 1 && (
        <Typography.Paragraph>
          Select either single or multiple supplier(s)
        </Typography.Paragraph>
      )}
      {currentStep > 2 && <SelectedList />}
      {currentStep === 2 && (
        <PageQueryProvider
          initialSimpleQueries={{
            target_requisition: requisition?.id,
            receive_inquiry: true,
            'available_resources[0][gte]': 1,
            include: [
              'total_count',
              'relation_status',
              'active_clients',
              'completed_engagements',
              'available_resources',
            ],
            limit: 8,
          }}
          filterConfig={filterConfig}>
          <ChooseSupplierContainer />
        </PageQueryProvider>
      )}
    </React.Fragment>
  );
};
export default React.memo(ChooseSuppliers);
