import AppFilterWrapper from '@wieldy/components/AppFilterWrapper';
import AppGridView from '@wieldy/components/AppGridView';
import AppLoader from '@wieldy/components/AppLoader';
import AppPagination from '@wieldy/components/AppPagination';
import AppHeaderPagination from '@wieldy/components/AppPagination/AppHeaderPagination';
import AppPageSection from '@wieldy/components/pageComponents/AppPageSection';
import {Empty} from 'antd';
import {useBrowse} from 'app/hooks/useBrowse.js';
import usePageQuery from 'app/hooks/usePageQuery';
import CandidateModal from 'app/modals/CandidateModal';
import {useGetCandidates} from 'app/services/candidateService';
import FilterCandidates from 'app/shared/candidate/FilterCandidates';
import CandidateCard from 'app/shared/cards/CandidateCard';
import {FilterButton} from 'app/shared/components';
import FilterResults from 'app/shared/components/FIlter/FilterResults';
import {getItemsData} from 'app/utils/helpers/GetItemsData';
import React, {useCallback} from 'react';

const Browse = () => {
  const {
    pageSize,
    currentPage,
    openFilter,
    onChangePage,
    onShowSizeChange,
    onToggleFilter,
    sortBy,
    onSortBy,
  } = useBrowse({initSort: '-status_changed_at', initPageSize: 15});

  const {
    handleSetFilter,
    resetFilter,
    filterState,
    filterQuery,
    queryInit,
    currentPageParams,
  } = usePageQuery();

  const {rowTitle, empty} = currentPageParams;

  const handleOnApplyFilter = useCallback(
    (form) => {
      handleSetFilter(form);
      onToggleFilter(false);
    },
    [handleSetFilter, onToggleFilter],
  );

  const {data, isLoading} = useGetCandidates({
    ...queryInit,
    ...filterQuery,
    expand: [...queryInit.expand, 'applicant'],
    include: [...queryInit.include, 'skill_tags', 'completed_engagements'],
    limit: pageSize,
    page: currentPage - 1,
    sort: [sortBy],
  });

  const [candidates, candidatesTotal] = getItemsData('candidates', data);

  const isEmpty = !candidates?.length;
  const isFilter = Object.values(filterState).some((filter) => !!filter);

  if (isLoading) {
    return <AppLoader />;
  }

  if (isEmpty && !isFilter) {
    return <Empty description={empty} />;
  }

  return (
    <React.Fragment>
      <AppPageSection
        title={rowTitle}
        count={candidatesTotal}
        extra={
          <>
            <FilterButton onClick={onToggleFilter} enabled />
            <AppHeaderPagination
              total={candidatesTotal}
              current={currentPage}
              pageSize={pageSize}
              onChange={onChangePage}
              onShowSizeChange={onShowSizeChange}
              showSizeChanger
              showSortBy
              sortByOptions={[
                {label: 'Alphabetically', value: '+expand_resource.first_name'},
                {
                  label: `Status changed date (newest first)`,
                  value: '-status_changed_at',
                },
                {
                  label: `Status changed date (oldest first)`,
                  value: '+status_changed_at',
                },
              ]}
              sortBy={sortBy}
              onSortByChange={onSortBy}
            />
          </>
        }>
        <AppFilterWrapper
          title='Filter Candidates'
          open={openFilter}
          initialValues={filterState}
          onApply={handleOnApplyFilter}
          onReset={resetFilter}
          onClose={() => onToggleFilter(false)}>
          <FilterCandidates />
        </AppFilterWrapper>
        <FilterResults />
        <AppGridView
          emptyTitle={empty}
          data={candidates}
          renderItem={(candidate, index) => (
            <CandidateCard
              key={index}
              isApplicant={!!candidate.applicant}
              isCandidate
              candidate={new CandidateModal(candidate)}
            />
          )}
        />

        <AppPagination
          total={candidatesTotal}
          current={currentPage}
          pageSize={pageSize}
          onChange={onChangePage}
          onShowSizeChange={onShowSizeChange}
        />
      </AppPageSection>
    </React.Fragment>
  );
};

export default Browse;
