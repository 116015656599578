import {
  CreateSubmission,
  Submissions,
  ViewSubmission,
} from 'app/pages/main/submissions';

export const supplier_SubmissionRoutes = [
  {
    path: 'submissions',
    element: <Submissions />,
  },
  {
    path: 'submissions/new-submission/:requisitionId?',
    element: <CreateSubmission />,
  },
  {
    path: 'submissions/edit-submission/:submissionId/:submissionStatus',
    element: <CreateSubmission isEdit={true} />,
  },
  {
    path: 'submissions/:category',
    element: <Submissions />,
  },
  {
    path: 'submissions/:id/view-submission',
    element: <ViewSubmission />,
  },
];

export const client_SubmissionRoutes = [
  {
    path: 'submissions',
    element: <Submissions />,
  },
  {
    path: 'submissions/:category',
    element: <Submissions />,
  },
  {
    path: 'submissions/:id/view-submission',
    element: <ViewSubmission />,
  },
];
