import {useWindowSize} from '@wieldy/hooks/useWindowSize';
import {Button, notification} from 'antd';
import {useDownloadPolicyFiles} from 'app/services/downloadPolicyFiles';
import React, {useCallback} from 'react';
import {Link} from 'react-router-dom';

import {PolicyFilesName, PolicyFilesPath} from '../../constants/policyNames';
import {downloadBlob} from '../utils/downloadBlob';

const PrivacyPolicy = () => {
  const {width} = useWindowSize();
  const data = useDownloadPolicyFiles();

  const handleDownload = useCallback(() => {
    data.mutate(
      {
        name: PolicyFilesPath[PolicyFilesName.PRIVACY_POLICY],
      },
      {
        onSuccess: (response) => {
          downloadBlob(
            response.file,
            PolicyFilesPath[PolicyFilesName.PRIVACY_POLICY],
          );
        },
        onError: (error) => {
          notification.error({message: error?.message});
        },
      },
    );
  }, [data]);

  return (
    <div id='privacy-policy'>
      <article className='post'>
        <div className='post-wrapper'>
          <div className='post-header'>
            <div className='d-flex flex-row flex-between'>
              <h2 className='post-title text-center'>Privacy Policy</h2>
              {width > 768 && (
                <Button
                  type='primary'
                  shape='round'
                  className='post-header-button items-center d-none lg:flex'
                  loading={data.isLoading}
                  onClick={handleDownload}>
                  Download
                </Button>
              )}
            </div>
            <ul className='post-meta'>
              <li>Effective April 22, 2024</li>
            </ul>
            {width < 769 && (
              <Button
                type='primary'
                shape='round'
                className='post-header-button items-center d-none lg:flex w-full'
                loading={data.isLoading}
                onClick={handleDownload}>
                Download
              </Button>
            )}
          </div>
          <div className='post-content'>
            <p>
              This Privacy Policy explains how and why Sourcer collects, uses,
              and shares personal information when you interact with or use our
              Site or Service. It also includes any information Sourcer collects
              offline in connection with the Service, which we may combine with
              information from the Site and Service. By reading this Privacy
              Policy, you will understand your privacy rights and choices.
            </p>

            <p>
              When we say “Sourcer”, we mean Sourcer, Inc., and any of its
              affiliates. When we say “Site”, we mean{' '}
              <a href='https://sourcer.com' target='_blank' rel='noreferrer'>
                sourcer.com
              </a>
              , and when we say “Service”, we mean the Site plus any websites,
              features, applications, widgets, or online services owned or
              controlled by Sourcer and that post a link to this Privacy Policy.
            </p>

            <p>
              As part of the Service, Sourcer provides a marketplace which
              results in platform information pertaining to different parties to
              an interaction. Users of the Service may be Customers, Suppliers,
              or Site Visitors (as each is defined in the{' '}
              <Link to='/terms-of-use'>Terms of Use</Link>). This Privacy Policy
              applies to Sourcer’s processing of personal information of Users
              where Sourcer determines the purposes and means of processing. It
              does not apply to processing of information by Users themselves,
              who may be controllers of the personal information they access
              through the Service. For information about how Users process your
              personal information, please contact them directly.
            </p>

            <p>
              Accessibility: This Privacy Policy uses industry-standard
              technologies and was developed in connection with the World Wide
              Web Consortium&apos;s Web Content Accessibility Guidelines,
              version 2.1. If you wish to print this policy, please do so from
              your web browser or by saving the page as a PDF.
            </p>

            <h3>1. Information Collection</h3>

            <h4>a. Information You Provide to Us</h4>

            <p>
              When you use the Service, you may provide us with information
              about you. This may include your name and contact information,
              financial information to make or receive payment for services
              obtained through the Sourcer platform, or information to help us
              calculate sales taxes. When you use the Service, we may also
              collect information related to your use of the Service and
              aggregate this with information about other users. This helps us
              improve our Services for you. Suppliers may also provide us with
              information about resources associated with the Supplier.
            </p>

            <p>
              Depending upon our relationship with you, we may collect the
              following categories and types of personal information from and
              about you:
            </p>
            <table width='100%' border={1} cellPadding={10}>
              <thead>
                <tr>
                  <th>
                    <strong>
                      Categories of Personal Information We Collect
                    </strong>
                  </th>
                  <th>
                    <strong>Examples of Personal Information Collected</strong>
                  </th>
                  <th>
                    <strong>
                      Categories of Sources of Personal Information
                    </strong>
                  </th>
                  <th>
                    <strong>
                      Business Purpose for Collection of Personal Information
                    </strong>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Identifiers</td>
                  <td>
                    Name, Social Media Account Information, Profile Data, IP
                    Address
                  </td>
                  <td>
                    Directly from You or Your use of the Service; Cookies and
                    Other Tracking Technologies; Third Parties (such as
                    affiliates, agents, service providers and other users)
                  </td>
                  <td>
                    Developing, Improving, and Providing the Service,
                    Identification, Communications, Marketing, Analytics,
                    Security, Legal, Compliance and Regulatory Obligations
                  </td>
                </tr>
                <tr>
                  <td>Contact / Account Profile Information</td>
                  <td>
                    Email Address, Home Address, Billing Address, Phone Number
                  </td>
                  <td>
                    Directly from You or Your use of the Service; Third Parties
                    (such as affiliates, agents, service providers and other
                    users)
                  </td>
                  <td>
                    Developing, Improving, and Providing the Service,
                    Identification, Communicating with You, Marketing,
                    Analytics, Security, Legal, Compliance and Regulatory
                    Obligations
                  </td>
                </tr>
                <tr>
                  <td>
                    Sensitive Personal Information / Government Issued
                    Identification Numbers
                  </td>
                  <td>
                    VAT Identification Number, Tax Identification Number,
                    Immigration Status, Citizenship Information
                  </td>
                  <td>Directly from You</td>
                  <td>
                    Improving and Providing the Service, Verifying Your Identity
                    and Detecting Fraud, Identity Theft, or Other Misuse of Your
                    Account, Legal, Compliance and Regulatory Obligations
                  </td>
                </tr>
                <tr>
                  <td>Commercial Information</td>
                  <td>
                    Transaction Data including services offered, considered, or
                    purchased
                  </td>
                  <td>
                    Directly from You or Your use of the Service; Third Parties
                    (such as affiliates, agents, service providers and other
                    users)
                  </td>
                  <td>
                    Developing, Improving, and Providing the Service,
                    Identification, Communications, Marketing, Analytics,
                    Security, Legal, Compliance and Regulatory Obligations
                  </td>
                </tr>
                <tr>
                  <td>Financial Data/Payment Information</td>
                  <td>Credit card or other financial account information</td>
                  <td>
                    Directly from You or Your use of the Service; Third Parties
                    (such as affiliates, agents, service providers and other
                    users)
                  </td>
                  <td>
                    Improving and Providing the Service, Identification,
                    Communications, Security, Legal, Compliance and Regulatory
                    Obligations
                  </td>
                </tr>
                <tr>
                  <td>
                    Internet or Other Network or Device Activities Including
                    Information from Cookies
                  </td>
                  <td>
                    Unique device and app identifiers, browsing history or other
                    usage data, the browser and operating system you are using,
                    the URL or advertisement that referred you to the Service,
                    the search terms you entered into a search engine that led
                    you to the Service, areas within the Service that you
                    visited, which links you clicked on, which pages or content
                    you viewed and for how long, other similar information and
                    statistics about your interactions, such as content response
                    times, download errors and length of visits to certain pages
                    and other information commonly shared when browsers
                    communicate with websites
                  </td>
                  <td>
                    Directly from You or Your use of the Service; Cookies and
                    Other Tracking Technologies; Third Parties (such as
                    affiliates, agents, service providers and other users)
                  </td>
                  <td>
                    Developing, Improving, and Providing the Service,
                    Identification, Communications, Marketing, Analytics,
                    Security, Legal, Compliance and Regulatory Obligations
                  </td>
                </tr>
                <tr>
                  <td>Approximate Geolocation Information</td>
                  <td>Your approximate location</td>
                  <td>
                    Directly from You or Your use of the Service; Cookies and
                    Other Tracking Technologies; Third Parties (such as
                    affiliates, agents, service providers and other users)
                  </td>
                  <td>
                    Developing, Improving, and Providing the Service,
                    Identification, Communications, Marketing, Analytics,
                    Security, Legal, Compliance and Regulatory Obligations
                  </td>
                </tr>
                <tr>
                  <td>Sensory Information</td>
                  <td>
                    Audio recordings if you call our customer service, video
                    recording (if you provide permission)
                  </td>
                  <td>
                    Directly from You or Your use of the Service; Service
                    Providers; Third Parties (such as affiliates)
                  </td>
                  <td>
                    Providing and Improving the Service, Identification,
                    Communications, Marketing, Security, Legal, Compliance and
                    Regulatory Obligations
                  </td>
                </tr>
                <tr>
                  <td>Platform Communications</td>
                  <td>
                    Communication Information (e.g., your name, contact
                    information, and, with your consent, the contents of any
                    messages you send){' '}
                  </td>
                  <td>
                    Directly from You, Your use of the Service, and Users with
                    whom you communicate
                  </td>
                  <td>
                    Developing, Improving, and Providing and Improving the
                    Service, Security, Legal, Compliance and Regulatory
                    Obligations
                  </td>
                </tr>
                <tr>
                  <td>Professional Information</td>
                  <td>
                    Previous place(s) of employment, position(s), work history,
                    earnings, resume
                  </td>
                  <td>
                    Directly from You or Your use of the Service; Service
                    Providers; Third Parties (such as other users){' '}
                  </td>
                  <td>
                    Developing, Improving, and Providing the Service, Marketing,
                    Analytics, Security, Legal, Compliance and Regulatory
                    Obligations
                  </td>
                </tr>
                <tr>
                  <td>
                    Other information that identifies or can be reasonably
                    associated with you{' '}
                  </td>
                  <td>
                    User-generated content, (e.g., community posts, feedback,
                    ratings and job postings), photographs, examples of your
                    work, information on work previously performed via the
                    Service and outside the Service, skills, tests taken, test
                    scores, hourly pay rates and earnings information
                  </td>
                  <td>
                    Directly from You or Your use of the Service; Service
                    Providers; Third Parties (such as affiliates, agents, and
                    other users)
                  </td>
                  <td>
                    Developing, Improving, and Providing the Service,
                    Identification, Communications, Marketing, Analytics,
                    Security, Legal, Compliance and Regulatory Obligations
                  </td>
                </tr>
              </tbody>
            </table>
            <br />
            <br />

            <h4>
              b. Non-Identifying Information and De-Identified Information
            </h4>

            <ul
              style={{
                listStylePosition: 'outside',
                marginLeft: '3em',
              }}>
              <li>
                Non-Identifying Information/Usernames: We also may collect other
                information that does not identify you directly, such as zip
                codes, demographic data, information about your use of the
                Service, and general project-related data (“Non-Identifying
                Information”). We may combine information collected from Sourcer
                users, whether they are registered or not (“Sourcer Users”). In
                some cases, we may render Personal Information (generally, email
                address) into a form of Non-Identifying Information referred to
                in this Privacy Policy as “Hashed Information.” This is
                typically accomplished using a mathematical process (commonly
                known as a hash function) to convert information into a code.
                While the code does not identify you directly, it may be used by
                Sourcer’s partners to connect your activity and interests.
              </li>
              <li>
                Combination of Personal and Non-Identifying Information: We may
                combine your Personal Information with Non-Identifying
                Information, but Sourcer will treat the combined information as
                Personal Information.
              </li>
              <li>
                De-Identified Information: We may also de-identify or aggregate
                information and convert it into non-personal information so that
                it can no longer reasonably be used to identify you
                (“De-Identified Information”). We may use De-Identified
                Information for any of the purposes described in the “We Use
                Information We Collect” section below. We will maintain and use
                De-Identified Information in de-identified form and will not
                attempt to reidentify the information, except to confirm our
                de-identification processes or unless required by law.
              </li>
            </ul>

            <h4>c. Information Collected Automatically</h4>

            <p>
              Like other online companies, we receive technical information when
              you use our Services. We use these technologies to analyze how
              people use the Service, to improve how our Site functions, to save
              your log-in information for future sessions, and to serve you with
              advertisements that may interest you.
            </p>

            <p>
              Sourcer and its partners use cookies or similar technologies to
              analyze trends, administer the website, track users’ movement
              around the website, the desktop app, and the mobile app, and to
              gather demographic information about our user base as a whole. The
              technology used to collect information automatically from Sourcer
              Users may include cookies, web beacons, and embedded scripts. In
              addition, we and our marketing partners, affiliates, analytics,
              and service providers may use a variety of other technologies
              (such as tags) that collect similar information for security and
              fraud detection purposes and we may use third parties to perform
              these services on our behalf.
            </p>

            <p>
              For further information on cookies and how they are used for the
              Service, please visit our
              <Link to='/cookie-policy'>Cookie Policy</Link>.
            </p>

            <h4>
              d. Analytics Providers, Ad Servers and Similar Third Parties
            </h4>

            <p>
              We may work with advertising agencies and vendors who use
              technology to help us understand how people use our Site. These
              vendors may use technologies to serve you advertisements that may
              interest you. You can choose to opt out of receiving certain
              interest-based advertising.
            </p>

            <p>
              Sourcer works with (or may in the future work with) ad networks,
              ad agencies, analytics service providers and other vendors to
              provide us with information regarding traffic on the Service,
              including pages viewed and the actions taken when visiting the
              Service; to serve our advertisements on other websites, within
              mobile apps and elsewhere online; and to provide us with
              information regarding the use of the Service and the effectiveness
              of our advertisements. Our service providers may collect certain
              information about your visits to and activity on the Service as
              well as other websites or services, they may set and access their
              own tracking technologies on your device (including cookies and
              web beacons), and may use that information to show you targeted
              advertisements. Some of these parties may collect Personal
              Information when you visit the Service or other online websites
              and services. We may also share certain Non-Identifying
              Information with these parties, including Hashed Information, in
              connection with the services they provide to us. If you wish to
              opt out of interest-based advertising from participating
              companies, click{' '}
              <a
                href='http://optout.aboutads.info/'
                rel='nofollow noreferrer'
                target='_blank'>
                here
              </a>{' '}
              (or if located in the European Union, click{' '}
              <a
                href='http://www.youronlinechoices.eu/'
                rel='nofollow noreferrer'
                target='_blank'>
                here
              </a>
              ). You must opt out on each device and each browser where you want
              your choice to apply. If you choose to opt out, please note you
              will continue to receive advertisements, but they may be less
              relevant to you.
            </p>

            <p>
              While we may use a variety of service providers to perform
              advertising services, some of these companies are members of the
              Network Advertising Initiative (“NAI”) or the Digital Advertising
              Alliance (“DAA”) Self-Regulatory Program for Online Behavioral
              Advertising. You may want to visit{' '}
              <a
                href='http://www.networkadvertising.org/managing/opt_out.asp'
                rel='nofollow noreferrer'
                target='_blank'>
                http://www.networkadvertising.org/managing/opt_out.asp
              </a>
              , which provides information regarding targeted advertising and
              the “opt-out” procedures of NAI members. You may also want to
              visit{' '}
              <a
                href='http://www.aboutads.info/choices/'
                rel='nofollow noreferrer'
                target='_blank'>
                http://www.aboutads.info/choices/
              </a>
              , which provides information regarding targeted advertising and
              offers an “opt-out” by participating companies in the DAA
              Self-Regulatory Program.
            </p>

            <h4>e. Do Not Track Signals and GPC</h4>

            <p>
              Please note that your browser setting may allow you to
              automatically transmit a “Do Not Track” signal to websites and
              online services you visit. Sourcer does not generally alter its
              practices when it receives a “Do Not Track” signal from a
              visitor’s browser. To find out more about “Do Not Track,” please
              visit{' '}
              <a
                href='http://www.allaboutdnt.com'
                rel='nofollow noreferrer'
                target='_blank'>
                http://www.allaboutdnt.com
              </a>
              .
            </p>

            <p>
              Global Privacy Control (GPC) is a technical specification that you
              can use to inform websites of your privacy preferences regarding
              ad trackers. If you do choose to set up GPC, it may impact the
              functionality of the Site, but we honor the GPC signal on a
              per-browser basis automatically in your cookie preferences.
            </p>

            <h4>f. Children</h4>

            <p>
              The Service is general audience and intended for users 18 and
              older. We do not knowingly collect Personal Information from
              anyone younger than age 18. If we become aware that a child
              younger than 18 has provided us with Personal Information, we will
              use commercially reasonable efforts to delete such information
              from our files. If you are the parent or legal guardian of a child
              younger than age 18 and believe that Sourcer has collected
              Personal Information from your child, please contact us at:{' '}
              <a href='mailto:legalnotices@sourcer.com'>
                legalnotices@sourcer.com
              </a>
              .
            </p>

            <h3>2. Use of Information</h3>

            <p>
              We use information collected through the Service to provide and
              improve the Service, develop new Services and products, process
              your requests, prevent fraud, provide you with information and
              advertising that may interest you, comply with the law, and as
              otherwise permitted with your consent.
            </p>

            <h4>a. We Use Information We Collect:</h4>

            <ul
              style={{
                listStylePosition: 'outside',
                marginLeft: '3em',
              }}>
              <li>
                To provide and improve the Service, complete your transactions,
                address your inquiries, process your registration, verify the
                information you provide is valid, and for compliance and
                internal business purposes.
              </li>
              <li>
                To contact you with administrative communications and Sourcer
                newsletters, marketing or promotional materials (on behalf of
                Sourcer or third parties) and other information that may be of
                interest to you. If you decide at any time that you no longer
                wish to receive such communications from us, please follow the
                instructions in the Your Choices and Rights section, below.
              </li>
              <li>
                To tailor content we display to you and offers we may present to
                you, both on the Service and elsewhere online.
              </li>
              <li>
                To administer and develop our business relationship with you
                and, if applicable, the corporation or other legal entity you
                represent.
              </li>
              <li>
                To enforce and comply with the law, including to conduct an
                investigation, to protect the property and rights of Sourcer or
                a third party, to protect the safety of the public or any
                person, or to prevent or stop activity we may consider to be, or
                to pose a risk of being, illegal, fraudulent, unethical or
                legally actionable activity. We may also use Device Identifiers
                to identify Sourcer Users.
              </li>
              <li>
                For the purposes disclosed at the time you provide your
                information, with your consent, and as further described in this
                Privacy Policy.
              </li>
              <li>
                To Honor Our Contractual Commitments to You. Much of our
                processing of Personal Information is to meet our contractual
                obligations to our investors, or to take steps at Users&apos;
                request in anticipation of entering into a contract with them.
              </li>
              <li>
                For Our Legitimate Interests. In many cases, we handle Personal
                Information on the grounds that it furthers our legitimate
                interests in commercial activities, such as the following, in
                ways that are not overridden by the interests or fundamental
                rights and freedoms of the affected individuals:
                <br />
                <br />
                <ul
                  style={{
                    listStylePosition: 'outside',
                    marginLeft: '3em',
                  }}>
                  <li>Providing our Site and Service.</li>
                  <li>
                    Detecting security incidents, protecting against malicious,
                    deceptive, fraudulent or illegal activity, and prosecuting
                    those responsible for that activity.
                  </li>
                  <li>Measuring interest and engagement in our Services.</li>
                  <li>
                    Short-term, transient use, such as contextual customization
                    of ads.
                  </li>
                  <li>Improving, upgrading or enhancing our Services.</li>
                  <li>Developing new products and services.</li>
                  <li>Ensuring internal quality control and safety.</li>
                  <li>Authenticating and verifying individual identities.</li>
                  <li>
                    Debugging to identify and repair errors with our Services.
                  </li>
                  <li>
                    Auditing relating to interactions, transactions and other
                    compliance activities.
                  </li>
                  <li>Enforcing our agreements and policies.</li>
                  <li>Analyzing and improving our business.</li>
                  <li>
                    Communications, including marketing and responding to your
                    inquiries about our services.
                  </li>
                  <li>
                    Addressing information security needs and protecting our
                    Users, Sourcer, and others.
                  </li>
                  <li>Managing legal issues.</li>
                </ul>
              </li>
              <li>
                To Comply with Legal Obligations. We need to use and disclose
                Personal Information in certain ways to comply with our legal
                obligations.
              </li>
            </ul>

            <h3>3. Data Retention</h3>

            <p>
              Unless you request that we delete certain information, we will
              only retain your personal information for as long as is necessary
              to fulfill the purposes for which it was collected, including for
              the purposes of satisfying any legal, regulatory, accounting, or
              reporting requirements, as well as ongoing fraud prevention,
              backup, and business continuity purposes.
            </p>

            <h3>4. Information Sharing and Disclosure</h3>

            <p>
              We do not sell your Personal Information for monetary
              consideration, and we do not share your Personal Information with
              third parties for those third parties’ marketing purposes unless
              we first provide you with the opportunity to opt-in to or opt-out
              of such sharing. However, we may use technologies on our Site for
              the purposes of advertising or marketing to you and understanding
              how you interact with our ads. This may be considered a “sale” or
              “sharing” of personal information for targeted advertising under
              applicable data protection laws. We may also share information we
              have collected about you, including Personal Information, as
              disclosed at the time you provide your information, with your
              consent, as otherwise described in this Privacy Policy, or for the
              following business or commercial purposes:
            </p>

            <table width='100%' border={1} cellPadding={10}>
              <tbody>
                <tr>
                  <td>Sourcer Users</td>
                  <td>
                    For Suppliers who have entered into an engagement with a
                    Customer, we may share their information with Customer. For
                    Suppliers who choose to submit a submission via the Service,
                    we may share their information with the applicable
                    Customer(s).
                    <br />
                    <br />
                    For Customers who have entered into an engagement, we may
                    share your information in order to complete the transaction
                    or to facilitate the resolution of a claim or dispute. The
                    Supplier(s) receiving your information is not allowed to use
                    it for purposes unrelated to the transaction, such as to
                    contact you for marketing purposes, unless you have
                    expressly consented to it.
                  </td>
                </tr>
                <tr>
                  <td>Sourcer Users</td>
                  <td>
                    {`We may employ service providers and individuals to
                      facilitate our Service, to provide the Service on our
                      behalf, to perform Service-related services (e.g., without
                      limitation, maintenance services, database management, web
                      analytics and online advertising, payment processing, fraud
                      detection and improvement of Sourcer’s features) or to
                      assist us in analyzing how our Service is used. These
                      Service providers may include analytics companies,
                      advertising partners, payment processors, identity
                      verification companies, security companies, generative AI
                      partners, or other merchants. These third parties may have
                      access to your Personal Information in order to perform
                      these tasks on our behalf.`}
                  </td>
                </tr>
                <tr>
                  <td>Generative AI Partners</td>
                  <td>
                    We enhance certain features of our Service by integrating
                    trusted generative AI service providers. We may share the
                    information you provide while using these features with
                    these generative AI service providers, as well as additional
                    information necessary to utilize the feature.
                    <br />
                    <br />
                    Where Sourcer uses this information to power features, we
                    leverage certain treatments of the data appropriate for the
                    feature, including but not limited to, first removing
                    personal information and only using public data.
                    <br />
                    <br />
                    Where a feature involves a generative AI service provider
                    and requires the use of identifying information you submit
                    to the feature, you may choose to either not use that
                    feature or not opt-in to generative AI features more
                    broadly.
                    <br />
                    <br />
                    {`The Service includes a two-sided marketplace, which results
                    in platform information pertaining to both parties on either
                    side of an interaction. Thus, if a Customer or Supplier
                    chooses to use our generative AI features, the platform
                    information associated with that user’s account – including
                    information described above – may be used to power those
                    features, subject to limitations based on the other party’s
                    choices.`}
                  </td>
                </tr>
                <tr>
                  <td>Legal and Investigative Purposes</td>
                  <td>
                    Sourcer will share information with government agencies as
                    required by law including (without limitation) in response
                    to lawful requests by public authorities to meet national
                    security or law enforcement requirements and in connection
                    with reporting earnings. We cooperate with government and
                    law enforcement officials and private parties to enforce and
                    comply with the law.
                    <br />
                    <br />
                    We will disclose information about you to government or law
                    enforcement officials or private parties as we, in our sole
                    discretion, believe necessary or appropriate to respond to
                    claims and legal process (including but not limited to
                    subpoenas), or, at the request of governmental authorities
                    or other third parties conducting an investigation where we
                    determine in our sole discretion the disclosure is necessary
                    to (a) protect the property and rights of Sourcer or a third
                    party, (b) protect the safety of the public or any person,
                    or (c) prevent or stop activity we may consider to be, or
                    pose a risk of being, illegal, fraudulent, unethical or
                    legally actionable activity.
                  </td>
                </tr>
                <tr>
                  <td>Internal and Business Transfers</td>
                  <td>
                    Sourcer may share information, including Personal
                    Information, with any current or future subsidiaries or
                    affiliates, primarily for business and operational purposes,
                    including activities such as IT management, for them to
                    provide services to you, or support and supplement the
                    Services we provide. We may sell, transfer, or otherwise
                    share some or all of our assets, including your Personal
                    Information, in connection with a merger, acquisition,
                    reorganization or sale of assets (including, in each case,
                    as part of the due-diligence process with any potential
                    acquiring entity) or in the event of bankruptcy.
                  </td>
                </tr>
                <tr>
                  <td>Sweepstakes, Contests, and Promotions</td>
                  <td>
                    We may offer sweepstakes, contests, and other promotions
                    (any, a “Promotion”) that may require registration.
                  </td>
                </tr>
                <tr>
                  <td>
                    Non-Identifying Information and De-Identified Information
                  </td>
                  <td>
                    We may share aggregated Non-Identifying Information and we
                    may otherwise disclose Non-Identifying Information
                    (including, without limitation, Hashed Information) or
                    De-Identified Information to third parties.
                  </td>
                </tr>
              </tbody>
            </table>
            <br />
            <br />
            <table width='100%' border={1} cellPadding={10}>
              <thead>
                <tr>
                  <th>
                    <strong>
                      Categories of Personal Information We Have Shared in the
                      Preceding 12 Months
                    </strong>
                  </th>
                  <th>
                    <strong>
                      Categories of Third Parties with whom We Share Personal
                      Information
                    </strong>
                  </th>
                  <th>
                    <strong>
                      Whether This Category is Used for Targeted Advertising
                    </strong>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Identifiers</td>
                  <td>
                    Analytics Companies, Identity Verification Companies,
                    Advertising Partners, Payment Processors, Other Merchants,
                    Sourcer Users, Government Agencies (as required by law)
                  </td>
                  <td>Yes</td>
                </tr>
                <tr>
                  <td>Contact Information</td>
                  <td>
                    Analytics Companies, Advertising Partners, Payment
                    Processors, Other Merchants, Sourcer Users, Government
                    Agencies (as required by law)
                  </td>
                  <td>Yes</td>
                </tr>
                <tr>
                  <td>
                    Sensitive Personal Information / Government Issued
                    Identification Numbers
                  </td>
                  <td>
                    Identity Verification Companies, Security Companies, Other
                    Merchants, Sourcer Users, Government Agencies (as required
                    by law)
                  </td>
                  <td>No</td>
                </tr>
                <tr>
                  <td>Commercial Information</td>
                  <td>
                    Payment Processors, Security Companies, Analytics Companies,
                    Other Merchants, Sourcer Users, Government Agencies (as
                    required by law)
                  </td>
                  <td>No</td>
                </tr>
                <tr>
                  <td>Financial Data/Payment Information</td>
                  <td>
                    Payment Processors, Security Companies, Other Merchants,
                    Sourcer Users, Government Agencies (as required by law)
                  </td>
                  <td>No</td>
                </tr>
                <tr>
                  <td>
                    Internet or Other Network or Device Activities Including
                    Information from Cookies
                  </td>
                  <td>
                    Analytics Companies, Advertising Partners, Other Merchants,
                    Government Agencies (as required by law)
                  </td>
                  <td>Yes</td>
                </tr>
                <tr>
                  <td>Approximate Geolocation Information</td>
                  <td>
                    Analytics Companies, Advertising Partners, Other Merchants,
                    Sourcer Users, Government Agencies (as required by law)
                  </td>
                  <td>Yes</td>
                </tr>
                <tr>
                  <td>Sensory Information</td>
                  <td>
                    Other Merchants, Sourcer Users, Government Agencies (as
                    required by law)
                  </td>
                  <td>No</td>
                </tr>
              </tbody>
            </table>
            <br />
            <h3>5. Your Choices and Rights</h3>

            <p>
              You may have certain choices and rights associated with your
              personal information, including opting out of targeted advertising
              or other disclosures to third parties. Residents of certain
              locations may have the right to have an authorized agent submit
              requests on your behalf. You or your authorized agent may request
              that Sourcer honor these rights by contacting us as outlined in
              the “Contact Us” section below.
            </p>

            <p>
              Non-Discrimination: You will not receive any discriminatory
              treatment by us for the exercise of your privacy rights.
            </p>

            <p>
              Verifying Your Request: Only you, or a person that you authorize
              to act on your behalf, may make a request related to your personal
              information. In the case of access and deletion, your request must
              be verifiable before we can fulfill such request. Verifying your
              request will require you to provide sufficient information for us
              to reasonably verify that you are the person about whom we
              collected personal information or a person authorized to act on
              your behalf. We will only use the personal information that you
              have provided in a verifiable request in order to verify your
              request. We cannot respond to your request or provide you with
              personal information if we cannot verify your identity or
              authority. Please note that we may charge a reasonable fee or
              refuse to act on a request if such request is excessive,
              repetitive or manifestly unfounded.
            </p>

            <h4>
              For Individuals Located in the European Economic Area (EEA),
              United Kingdom (UK) or Switzerland:
              <br />
              <br />
            </h4>

            <p>
              You have a number of rights under applicable data protection laws
              in relation to your personal information. Under certain
              circumstances, you have the right to:
            </p>

            <ul
              style={{
                listStylePosition: 'outside',
                marginLeft: '3em',
              }}>
              <li>
                Have access to your personal information by submitting a request
                to us;
              </li>
              <li>Have your personal information deleted;</li>
              <li>Have your personal information corrected if it is wrong;</li>
              <li>
                Have the processing of your personal information restricted;
              </li>
              <li>
                Object to further processing of your personal information,
                including to object to marketing from us;
              </li>
              <li>Make a data portability request;</li>
              <li>Withdraw any consent you have provided to us;</li>
              <li>
                Restrict any automatic processing of your personal information;
                and
              </li>
              <li>Complain to the appropriate Supervisory Authority.</li>
            </ul>

            <p>
              To exercise any of these rights, please contact us as outlined in
              the “Contact Us” section below.
            </p>

            <h4>Notice for California Residents</h4>

            <p>
              “Shine the Light” and “Eraser” Laws: Residents of the State of
              California may request a list of all third parties to which we
              have disclosed certain information during the preceding year for
              those third parties’ direct marketing purposes.
            </p>

            <p>
              California Consumer Privacy Act (CCPA)/California Privacy Rights
              Act (CPRA): The CCPA, as amended by the CPRA, provides California
              residents and/or their authorized agents with specific rights
              regarding the collection and processing of their personal
              information.
            </p>

            <p>
              <i>Your Right to Know:</i> California residents have the right to
              request that we disclose the following information to you about
              our collection and use of your personal information over the past
              twelve (12) months. We may ask you to provide certain information
              to identify yourself so that we may compare it with our records in
              order to verify your request. Upon verification, we will disclose
              to you:
            </p>

            <ol
              style={{
                listStylePosition: 'outside',
                marginLeft: '3em',
              }}>
              <li>
                The categories of personal information we have collected about
                you.
              </li>
              <li>
                The categories of sources for the personal information we have
                collected about you.
              </li>
              <li>
                The specific pieces of personal information we have collected
                about you.
              </li>
              <li>
                Our business or commercial purpose for collecting or “selling”
                your personal information as defined by the CCPA.
              </li>
              <li>
                The categories of third parties to whom we have sold or shared
                your personal information, if any, and the categories of
                personal information that we have shared with each third-party
                recipient.
              </li>
            </ol>

            <p>
              <i>
                Your Right to Opt-Out of “Sale” or “Sharing” of Personal
                Information:
              </i>{' '}
              California residents have the right to opt-out of the “sale” or
              “sharing” of their personal information as defined by the CCPA by
              contacting us using the information in the “Contact Us” section
              below.
            </p>

            <p>
              Please note that we do not knowingly “sell” the personal
              information of any individuals under the age of 18.
            </p>

            <p>
              Where we are “sharing” your personal information with third
              parties for the purposes of cross-context behavioral advertising
              or profiling, you may opt-out of such sharing at any time by
              submitting a request as directed on the homepage of our website or
              by contacting us using the information in the “Contact Us” section
              below.
            </p>

            <p>
              <i>Your Right to Limit Use of Sensitive Personal Information:</i>{' '}
              California residents may have the right to request that businesses
              limit the use of any sensitive personal information to those uses
              which are necessary to perform the Services or for other
              specifically-enumerated business purposes under the CCPA, as
              amended by the CPRA. Please note that we do not use sensitive
              personal information other than as necessary to perform the
              Services or as specifically permitted under the CCPA.{' '}
            </p>

            <p>
              <i>Your Right to Delete:</i> California residents have the right
              to request that we delete any of the personal information
              collected from you and retained by us, subject to certain
              exceptions. We may ask you to provide certain information to
              identify yourself so that we may compare it with our records in
              order to verify your request. Once your request is verified and we
              have determined that we are required to delete the requested
              personal information in accordance with the CCPA, we will delete,
              and direct our service providers to delete your personal
              information from their records. Your request to delete personal
              information that we have collected may be denied if we conclude it
              is necessary for us to retain such personal information under one
              or more of the exceptions listed in the CCPA.
            </p>

            <p>
              <i>Your Right to Correct:</i> Under the CCPA, as amended by the
              CPRA, California residents have the right to request that we
              correct any inaccurate personal information we maintain about you,
              taking into account the nature of the personal information and the
              purposes for which we are processing such personal information. We
              will use commercially reasonable efforts to correct such
              inaccurate personal information about you.
            </p>

            <p>
              <i>Non-Discrimination:</i> You will not receive any discriminatory
              treatment by us for the exercise of your privacy rights conferred
              by the CCPA.
            </p>

            <h5>Notice for Nevada Residents</h5>

            <p>
              Under Nevada law, certain Nevada residents may opt out of the sale
              of “personally identifiable information” for monetary
              consideration to a person for that person to license or sell such
              information to additional persons. “Personally identifiable
              information” includes first and last name, address, email address,
              phone number, Social Security Number, or an identifier that allows
              a specific person to be contacted either physically or online.
            </p>

            <p>
              We do not engage in such activity; however, if you are a Nevada
              resident who has purchased or leased goods or services from us,
              you may submit a request to opt out of any potential future sales
              under Nevada law by emailing privacyrequests@sourcer.com. Please
              note we will take reasonable steps to verify your identity and the
              authenticity of the request. Once verified, we will maintain your
              request in the event our practices change.
            </p>

            <h5>Notice for Residents of Certain Other States</h5>

            <p>
              The laws of your state of residence (“Applicable State Law”) may
              provide you with certain rights, including the following:
            </p>

            <p>
              <i>Your Right to Confirm and Access:</i> You have the right to
              confirm whether we are processing personal information about you
              and access the personal information we process about you.
            </p>

            <p>
              <i>Your Right to Portability:</i> You have the right to obtain a
              copy of the personal information we maintain and process about you
              in a portable and, to the extent technically feasible,
              readily-usable format.
            </p>

            <p>
              <i>Your Right to Delete:</i> You have the right to request that we
              delete the personal information we maintain or process about you.
            </p>

            <p>
              <i>Your Right to Correct:</i> You have the right to request that
              we correct inaccuracies in the personal information we maintain or
              process about you, taking into consideration the nature and
              purpose of such processing.
            </p>

            <p>
              <i>Your Rights to Opt-Out:</i> You have the right to opt-out of
              certain types of processing of personal information, including:
            </p>

            <ul
              style={{
                listStylePosition: 'outside',
                marginLeft: '3em',
              }}>
              <li>
                Opt-Out of the “sale” of personal information as defined by
                Applicable State Law;
              </li>
              <li>Opt-Out of targeted advertising by us;</li>
              <li>
                Opt-Out of automated profiling for the purposes of making
                decisions that produce legal or similarly significant effects.
              </li>
            </ul>

            <p>
              Please note, as explained above, we do not “sell” personal
              information as that word is traditionally defined. However, we do
              share personal information with third parties to provide you with
              personalized advertising from us and to better understand how you
              interact with our Services. Through the use of cookies, we may
              also make available certain personal information to third parties
              for targeted advertising.
            </p>

            <h4>Appeals Process and Other Concerns</h4>

            <p>
              Certain information may be exempt from the rights described above
              under applicable law. If we deny your request in whole or in part,
              you may have the right to appeal the decision. In such
              circumstances, we will provide you with information regarding the
              appeals process. Depending on your location, you may also email{' '}
              <a href='mailto:legalnotices@sourcer.com'>
                legalnotices@sourcer.com
              </a>{' '}
              with the subject “Data Privacy Request Appeal” to provide us with
              details about why you are appealing the decision.
            </p>

            <p>
              If you have an unresolved privacy or data use concern that we have
              not addressed to your satisfaction, please contact our U.S.-based
              third-party dispute resolution provider free at{' '}
              <a
                href='https://feedback-form.truste.com/watchdog/request'
                rel='nofollow noreferrer'
                target='_blank'>
                https://feedback-form.truste.com/watchdog/request
              </a>
              .
            </p>

            <h3>6. Security</h3>

            <p>
              We take a number of steps to protect your data, but no security is
              guaranteed.
            </p>

            <p>
              Sourcer takes reasonable steps to help protect and secure the
              information it collects and stores about Sourcer Users. We
              maintain reasonable administrative, technical, and physical
              safeguards designed to protect personal information that we
              receive against accidental, unlawful, or unauthorized destruction,
              loss, alteration, access, disclosure or use.{' '}
            </p>

            <h3>7. Cross-Border Data Transfers</h3>

            <p>
              Because we are a U.S. company, we process and store your
              information in the United States and our service providers may
              process and store it elsewhere.
            </p>

            <p>
              Sourcer may transfer your personal information to a third party
              that is located in a jurisdiction other than the one from which we
              collected your personal information, including to countries that
              have not been deemed to have an adequate level of protection for
              the rights and freedoms of data subjects. If we do transfer your
              personal information to another jurisdiction, we will do so
              following due diligence and provided that the data recipient is
              subject to contractual agreements imposing obligations on it to
              ensure appropriate technical and organizational are implemented
              and maintained at all times to prevent the unauthorized and
              unlawful processing of personal information, and the accidental
              loss or destruction of, or damage to, personal information,
              consistent with our obligations under applicable data protection
              laws.
            </p>

            <p>
              We will use appropriate legal transfer mechanisms, including
              Standard Contractual Clauses, where required by law.In addition,
              Sourcer is self-certified under the EU-U.S. and Swiss-U.S. Data
              Privacy Framework, as described below. Personal information from
              EU and Swiss residents will be treated in accordance with the Data
              Privacy Framework Principles. To exercise any legal right to see
              copies of the data transfer mechanism documents that Sourcer uses
              to transfer data to third parties, please contact us.
            </p>

            <h4>Data Privacy Framework Notice</h4>

            <p>
              Sourcer, Inc. has certified that its U.S. operations adhere to the
              EU-U.S. and Swiss-U.S. Privacy Shield Frameworks (“Privacy
              Shield”) with respect to the personal Information that they
              receive in reliance on the Privacy Shield. In July 2023, all
              members of the Privacy Shield, including Sourcer, automatically
              became members of the Data Privacy Framework (“DPF”). Our DPF
              certification is available at{' '}
              <a
                href='https://www.dataprivacyframework.gov/s/participant-search'
                rel='nofollow noreferrer'
                target='_blank'>
                https://www.dataprivacyframework.gov/s/participant-search
              </a>
              . To learn more about the DPF program, please visit{' '}
              <a
                href='https://www.dataprivacyframework.gov/s/'
                rel='nofollow noreferrer'
                target='_blank'>
                https://www.dataprivacyframework.gov/s/
              </a>
              .
            </p>

            <p>
              When Sourcer or one of its affiliates receives Personal
              Information under the DPF and then transfers it to a third party
              service provider acting as an agent on their behalf, Sourcer or
              its affiliate may have certain responsibility under the DPF if
              both (i) the agent processes the information in a manner
              inconsistent with the DPF and (ii) Sourcer or its affiliate is
              responsible for the event giving rise to the damage.
            </p>

            <p>
              Covered European residents should contact Sourcer at the contact
              information below regarding Sourcer’s or its affiliates&apos;
              compliance with the DPF. Sourcer will attempt to answer your
              questions and satisfy your concerns in a timely and complete
              manner as soon as possible. If, after discussing the matter with
              Sourcer, your issue or complaint is not resolved, Sourcer and the
              above-named affiliates have agreed to participate in the DPF
              independent dispute resolution mechanisms listed below, free of
              charge to you. PLEASE CONTACT SOURCER FIRST.
            </p>

            <p>
              For other Personal Information Sourcer or its affiliates receive
              under the DPF, Sourcer and its affiliates have committed to refer
              unresolved privacy complaints under the EU-U.S. and Swiss-U.S.
              Data Privacy Framework Principles to an independent dispute
              resolution mechanism, JAMS Data Privacy Framework Dispute
              Resolution, operated by JAMS. If you do not receive timely
              acknowledgment of your complaint, or if your complaint is not
              satisfactorily addressed, please visit{' '}
              <a
                href='https://www.jamsadr.com/dpf-dispute-resolution'
                rel='nofollow noreferrer'
                target='_blank'>
                https://www.jamsadr.com/dpf-dispute-resolution
              </a>{' '}
              for more information and to file a complaint.
            </p>

            <p>
              If your complaint still is not resolved through these channels,
              under limited circumstances, an additional binding arbitration
              option may be available before a DPF panel, as described at{' '}
              <a
                href='https://www.dataprivacyframework.gov/s/'
                rel='nofollow noreferrer'
                target='_blank'>
                https://www.dataprivacyframework.gov/s/
              </a>
              . Every individual also has a right to lodge a complaint with the
              relevant supervisory authority.
            </p>

            <h3>8. Links to Other Sites</h3>

            <p>
              Our Service contains links to other websites. If you choose to
              click on a third-party link, you will be directed to that third
              party’s website. The fact that we link to a website is not an
              endorsement, authorization or representation of our affiliation
              with that third party, nor is it an endorsement of their privacy
              or information security policies or practices. We do not exercise
              control over third party websites. These other websites may place
              their own cookies or other files on your computer, collect data or
              solicit Personal Information from you. We encourage you to read
              the privacy policies or statements of the other websites you
              visit.
            </p>

            <h3>9. Changes to This Policy</h3>

            <p>
              We may change this Privacy Policy. If we make substantial changes,
              we will provide notice.
            </p>

            <p>
              This Privacy Policy is effective as of the date stated at the top
              of this page. Sourcer may update this Privacy Policy at any time
              and any changes will be effective upon posting. By accessing or
              using the Service after we notify you of such changes to this
              Privacy Policy, you are deemed to have accepted such changes.
              Please refer back to this Privacy Policy on a regular basis.
            </p>

            <h3>10. Contact Us</h3>

            <p>
              To request that Sourcer honor any of the rights described in this
              Policy contact us as outlined below.
            </p>

            <p>
              If you have any questions about this Privacy Policy, please
              contact us at legalnotices@sourcer.com, or by mail addressed to
              Sourcer, Attn: Legal, 1111B S Governors Ave, Ste 20165, Dover, DE
              19904 USA. You may also contact us by phone at 415-574-0205.
            </p>
          </div>
        </div>
      </article>
    </div>
  );
};

export default PrivacyPolicy;
