export const PAGINATION_SIMPLIFY_WIDTH = 768;

/**
 * Set user layout panel depending on user role
 *
 * @type {{SUPPLIER: string, CLIENT: string, MANAGER: string}}
 */

export const USER_ROLES = {
  SUPPLIER: 'supplier',
  RESOURCE: 'resource',
  CLIENT: 'client',
  MANAGER: 'admin',
};
