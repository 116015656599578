import AppGridView from '@wieldy/components/AppGridView';
import AppLoader from '@wieldy/components/AppLoader';
import AppPagination from '@wieldy/components/AppPagination';
import AppHeaderPagination from '@wieldy/components/AppPagination/AppHeaderPagination';
import AppPageSection from '@wieldy/components/pageComponents/AppPageSection';
import {Empty} from 'antd';
import {USER_INCLUDES} from 'app/constants';
import {useAccountType} from 'app/hooks/useAccountType';
import {useBrowse} from 'app/hooks/useBrowse.js';
import SubmissionModal from 'app/modals/SubmissionModal';
import {useGetSubmissions} from 'app/services/submissionService';
import InquirySubmissionCard from 'app/shared/cards/InquirySubmissionCard';
import {getItemsData} from 'app/utils/helpers/GetItemsData';
import PropTypes from 'prop-types';
import React, {useMemo} from 'react';
import {useParams} from 'react-router-dom';

import {mapFilterToCategory, pageParams} from '../constants';

const Browse = ({userQuery}) => {
  const {category} = useParams();
  const {isClient} = useAccountType();
  const initSort = useMemo(
    () => mapFilterToCategory[category][0].value,
    [category],
  );
  const {
    pageSize,
    onShowSizeChange,
    onChangePage,
    currentPage,
    sortBy,
    onSortBy,
    isEnabled,
  } = useBrowse({
    initPageSize: 15,
    initSort,
  });

  const {rowTitle, empty, queryParams} = useMemo(() => {
    const activeTab = category in pageParams ? category : 'all';
    return pageParams[activeTab];
  }, [category]);

  const include = useMemo(
    () =>
      isClient
        ? USER_INCLUDES.supplierIncludeDetails
        : USER_INCLUDES.clientIncludeDetails,
    [isClient],
  );

  const {data, isLoading} = useGetSubmissions(
    {
      ...queryParams,
      ...userQuery,
      include: [...queryParams.include, ...include],
      limit: pageSize,
      sort: [sortBy],
    },
    {
      enabled: isEnabled,
    },
  );
  const [submissions, submissionsTotalCount] = getItemsData(
    'submissions',
    data,
  );
  const isEmpty = !submissions.length;

  if (isLoading) {
    return <AppLoader />;
  }

  if (isEmpty) {
    return <Empty description={empty} />;
  }

  return (
    <React.Fragment>
      <AppPageSection
        title={rowTitle}
        count={submissionsTotalCount}
        extra={
          <>
            <AppHeaderPagination
              total={submissionsTotalCount}
              current={currentPage}
              pageSize={pageSize}
              onChange={onChangePage}
              onShowSizeChange={onShowSizeChange}
              showSizeChanger={true}
              hideStartBorder={true}
              showSortBy
              sortByOptions={mapFilterToCategory[category]}
              sortBy={sortBy}
              onSortByChange={onSortBy}
            />
          </>
        }>
        <AppGridView
          emptyTitle={empty}
          data={submissions}
          renderItem={(submission) => (
            <InquirySubmissionCard
              key={submission.id}
              item={new SubmissionModal(submission)}
              type='submission'
            />
          )}
        />

        <AppPagination
          total={submissionsTotalCount}
          current={currentPage}
          pageSize={pageSize}
          onChange={onChangePage}
          onShowSizeChange={onShowSizeChange}
        />
      </AppPageSection>
    </React.Fragment>
  );
};

Browse.propTypes = {
  userQuery: PropTypes.object,
};

Browse.defaultProps = {
  userQuery: {},
};

export default Browse;
