import {Divider, Row, Typography} from 'antd';
import {useAccountType} from 'app/hooks/useAccountType.js';
import {getUserName} from 'app/utils/helpers/ConvertHID';
import PropTypes from 'prop-types';
import React from 'react';
import {useNavigate} from 'react-router-dom';

const InquirySubmissionCardHeader = ({item, isSubmission}) => {
  const navigate = useNavigate();
  const {isClient, isSupplier} = useAccountType();

  const onTitleClick = () => {
    if (isSubmission) {
      navigate(`/submissions/${item?.id}/view-submission`);
    } else {
      navigate(`/inquiries/${item?.id}/view-inquiry`);
    }
  };

  const inquiryTitle = isSupplier ? 'Inquiry From' : 'Inquiry For';
  const submissionTitle = isClient ? 'Submission From' : 'Submission For';
  const companyName = item[isSupplier ? 'client' : 'supplier']?.name;
  const title = `${isSubmission ? submissionTitle : inquiryTitle} ${getUserName(
    companyName,
    isSupplier ? 'CLI-' : 'SUP-',
    isSupplier ? 'Client' : 'Supplier',
  )}`;

  return (
    <Row justify='space-between' wrap={false}>
      <div className={'d-flex flex-column flex-fill mb-3'}>
        <Typography.Title
          level={3}
          type='secondary'
          className='title-secondary'>
          {title}
        </Typography.Title>
        <Typography.Title
          level={4}
          ellipsis
          className={'mt-1 mb-1'}
          onClick={onTitleClick}>
          <Typography.Link>{item?.name}</Typography.Link>
        </Typography.Title>
        <Typography.Paragraph className={'text-sm'}>
          <Typography.Text type={'secondary'}>
            {isSubmission ? 'Submitted' : 'Inquired'} on {item.created_at}
          </Typography.Text>
          <Divider type='vertical' />
          <Typography.Text type={'secondary'}>
            Updated {item.updated_at} ago
          </Typography.Text>
        </Typography.Paragraph>
      </div>
    </Row>
  );
};

InquirySubmissionCardHeader.propTypes = {
  item: PropTypes.object,
  isSubmission: PropTypes.bool,
};

export default InquirySubmissionCardHeader;
