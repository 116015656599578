import SupplierHelper from 'app/utils/SupplierHelper';

export default {
  status: {
    label: 'Engagement Status',
    options: SupplierHelper.getEngagementStatus(),
  },
  filter_status: {
    label: 'Engagement Status',
    options: SupplierHelper.getFilterEngagementStatus(),
  },
  company_size: {
    label: 'Company Size',
    options: SupplierHelper.getEmployeeSize(),
  },
  service_focus: {
    label: 'Service Focus',
    options: SupplierHelper.serviceFocuses(),
  },
  industry: {
    label: 'Industry Focus',
    options: SupplierHelper.getIndustries(),
  },
  relation_status: {
    label: 'Supplier Relationship Status',
    options: SupplierHelper.getRelationshipStatus(),
  },
  diversity_status: {
    label: 'Diversity Status',
    options: SupplierHelper.getDiversityStatus(),
  },
  active_clients: {
    label: ' # of Active Clients',
    options: SupplierHelper.getActiveClients(),
    hasQuery: true,
  },
  created_at: {
    label: 'Membership Duration',
    options: SupplierHelper.getMembershipDuration(),
    hasQuery: true,
  },
  country: {
    label: 'Based In',
    options: SupplierHelper.getCountries(),
  },
  completed_engagements: {
    label: 'Completed Engagements',
    options: SupplierHelper.completedEngagements(),
    hasQuery: true,
  },
  rating_total: {
    label: 'Performance Rating',
    options: SupplierHelper.getPerformanceRating(),
    hasQuery: true,
  },
  available_resources: {
    label: '# of Available Resources',
    options: SupplierHelper.availableResouces(),
    hasQuery: true,
  },
  deployed_resources: {
    label: '# of Deployed Resources',
    options: SupplierHelper.getDeployedResouces(),
    hasQuery: true,
  },
};
