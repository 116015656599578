export default {
  civil_court_check: 'Civil Court Check',
  credit_background_check: 'Credit Background Check',
  criminal_history_check: 'Criminal History Check',
  driving_record: 'Driving Record',
  drug_screening: 'Drug Screening',
  e_verify_background_checks: 'E-Verify Background Check',
  education_verification: 'Education Verification',
  fingerprint_background_checks: 'Fingerprint Background Check',
  healthcare_sanctions_check: 'Healthcare Sanctions Check',
  identity_verification: 'Identity Verification',
  international_background_check: 'International Background Check',
  prior_employment_verification: 'Prior Employment Verification',
  professional_license_and_certificate_confirmation:
    'Professional License & Certificate Confirmation',
  professional_reference_check: 'Professional Reference Check',
  sexual_offender_registry_check: 'Sexual Offender Registry Check',
  social_media_and_internet_check: 'Social Media & Internet Check',
  social_security_number_trace: 'Social Security Number Trace',
};
