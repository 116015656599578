export const convertHID = (id, prefix = 'ET-') => {
  if (!id) {
    return '';
  }
  return id.replace(prefix, '');
};

export const getUserName = (name, prefix, type) => {
  if (!name) {
    return '';
  }
  if (typeof name === 'number') {
    return name;
  }
  return name.includes(prefix) ? `${type} ${convertHID(name, prefix)}` : name;
};
