import AppLoader from '@wieldy/components/AppLoader';
import AppPageContainer from '@wieldy/components/pageComponents/AppPageContainer';
import AppPageHeader from '@wieldy/components/pageComponents/AppPageHeader';
import {useAccountType} from 'app/hooks/useAccountType';
import {useEngagement} from 'app/hooks/useEngagement';
import {useRole} from 'app/hooks/useRole';
import NotEngagement from 'app/shared/components/NotEngagement';
import {resetTimeCardState} from 'app/store/features/timecards/timecardsSlice';
import PropTypes from 'prop-types';
import React, {useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';

import MainContent from './MainContent';

const CreateTimecard = ({isEditMode = false}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {isResource} = useRole();
  const {isClient} = useAccountType();
  const {temp} = useSelector(({timecards}) => timecards);
  const {titles} = temp;

  useEffect(() => {
    if (isClient) {
      navigate('/timecards');
    }
  }, [isClient, navigate]);

  const {data, isLoading} = useEngagement();

  const isEmpty = !data?.length;

  useEffect(() => {
    return () => {
      dispatch(resetTimeCardState());
    };
  }, [dispatch]);

  const notEngagement = useMemo(
    () => ({
      title: 'Timecard cannot be created',
      subtitle: isResource
        ? 'You are not currently assigned to an active engagement'
        : 'There are no resources in your team who are currently assigned to an active engagement',
    }),
    [isResource],
  );

  return (
    <AppPageContainer
      header={
        <AppPageHeader
          backHeader={isEmpty}
          header='main'
          category='Timecard'
          title={titles.company}
          subTitle={titles.resource}
        />
      }>
      {isLoading ? (
        <AppLoader />
      ) : isEmpty ? (
        <NotEngagement {...notEngagement} />
      ) : (
        <MainContent isEditMode={isEditMode} isGeneral={!isResource} />
      )}
    </AppPageContainer>
  );
};

CreateTimecard.propTypes = {
  isEditMode: PropTypes.bool,
};

export default CreateTimecard;
