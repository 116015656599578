import AppAmountView from '@wieldy/components/helperComponents/AppAmountView';
import {Col, Divider, Row, Typography} from 'antd';
import {useAccountType} from 'app/hooks/useAccountType';
import PropTypes from 'prop-types';
import React from 'react';

const TotalSummary = ({invoice}) => {
  const {isClient} = useAccountType();
  const total =
    invoice[isClient ? 'client_total_billable' : 'supplier_total_billable'];

  return (
    <React.Fragment>
      <Row gutter={30} className={'flex-row-reverse mt-2'}>
        <Col xs={24} md={12} style={{maxWidth: 320}}>
          <table
            className={'table table-xs text-sm font-semibold'}
            style={{padding: '0 8px'}}>
            <tr>
              <td>
                <Typography.Text>Grand Total</Typography.Text>
              </td>
              <td align={'right'}>
                <Typography.Text>
                  <AppAmountView amount={total} />
                </Typography.Text>
              </td>
            </tr>
            <tr>
              <td>
                <Typography.Text>Payments/Credits</Typography.Text>
              </td>
              <td align={'right'}>
                <Typography.Text>
                  <AppAmountView amount={0} />
                </Typography.Text>
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <Divider style={{width: 'auto', margin: '8px -16px'}} />
              </td>
            </tr>
            <tr>
              <td>
                <Typography.Text>Balance Due</Typography.Text>
              </td>
              <td align={'right'}>
                <Typography.Text>
                  <AppAmountView amount={total} />
                </Typography.Text>
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <Divider style={{width: 'auto', margin: '8px -16px'}} />
              </td>
            </tr>
          </table>
        </Col>
      </Row>
    </React.Fragment>
  );
};

TotalSummary.propTypes = {
  invoice: PropTypes.object,
};

export default TotalSummary;
