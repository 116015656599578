import {axiosS3} from '../config/axios';

const path = '/policy_pdfs';
export const policyAPI = {
  downloadPolicy: async (name, options = {}) => {
    return await axiosS3.get(`${path}/${name}`, {
      ...options,
    });
  },
};
