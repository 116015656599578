import AppLoader from '@wieldy/components/AppLoader';
import {useGetExpenseDetail} from 'app/services/expenseService';
import {
  setEditModeExpenses,
  setEndDateExpenses,
  setEngagementExpenses,
  setItemsExpenses,
} from 'app/store/features/expenses/expensesSlice';
import React, {useEffect, useMemo} from 'react';
import {useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';

import CreateExpense from '../CreateExpense';

const EditExpense = () => {
  let {id} = useParams();
  const dispatch = useDispatch();
  const {isLoading, data} = useGetExpenseDetail(id);
  const {items, expense} = data || {items: null, expense: null};

  const clearItems = useMemo(
    () =>
      items
        ? items.map(({amount, date_of_spend, id, notes, purpose}) => ({
            amount,
            date_of_spend,
            id,
            notes,
            purpose,
          }))
        : [],
    [items],
  );

  useEffect(() => {
    dispatch(setEditModeExpenses());
    if (expense) {
      dispatch(setEngagementExpenses(expense.engagement.id));
      dispatch(setEndDateExpenses(expense.end_date));
    }
  }, [dispatch, expense]);

  useEffect(() => {
    if (clearItems) {
      dispatch(setItemsExpenses(clearItems));
    }
  }, [clearItems, dispatch]);

  if (isLoading) {
    return <AppLoader />;
  }

  return <CreateExpense />;
};

export default EditExpense;
