import PropTypes from 'prop-types';
import React from 'react';

const MenuItemContent = ({icon, title, isComingSoon}) => {
  return (
    <React.Fragment>
      {icon &&
        (React.isValidElement(icon) ? (
          <span className='ant-menu-item-icon'>{icon}</span>
        ) : (
          <icon className='ant-menu-item-icon' />
        ))}
      <span data-testid={title.toLowerCase + '-nav'}>
        {title}
        {isComingSoon && <span className='coming-soon'>Coming Soon</span>}
      </span>
    </React.Fragment>
  );
};

MenuItemContent.propTypes = {
  icon: PropTypes.node,
  title: PropTypes.string,
  isComingSoon: PropTypes.bool,
};

export default MenuItemContent;
