export const downloadBlob = (res, name, type) => {
  const blob = new Blob([res], {type});

  const link = document.createElement('a');
  link.download = name;
  link.href = window.URL.createObjectURL(blob);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
