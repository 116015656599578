import './index.style.less';

import MESSAGE_QUERIES from 'app/constants/query-params/message-queries';
import PropTypes from 'prop-types';
import {useEffect} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {useChatContext} from 'stream-chat-react';

const AppChannelList = ({
  LoadingIndicator,
  children,
  loading,
  loadedChannels,
}) => {
  const {setActiveChannel} = useChatContext();
  const location = useLocation();

  const navigate = useNavigate();

  const searchParams = new URLSearchParams(location.search);

  const channelId = searchParams.get(MESSAGE_QUERIES.channelId);

  useEffect(() => {
    if (loading) return;

    const activeChannel = loadedChannels.find(
      (channel) => channel.id === channelId,
    );

    if (activeChannel) {
      setActiveChannel(activeChannel);

      return;
    }
    if (loadedChannels[0]) {
      navigate(
        `/messages?${MESSAGE_QUERIES.channelId}=${loadedChannels[0].data.id}`,
        {replace: true},
      );
    }
  }, [loading, loadedChannels, channelId, navigate, setActiveChannel]);

  if (loading) {
    return <LoadingIndicator />;
  }

  return <div className='listWrapper'>{children}</div>;
};

AppChannelList.propTypes = {
  LoadingIndicator: PropTypes.object,
  children: PropTypes.node,
  loading: PropTypes.bool,
  loadedChannels: PropTypes.array,
};

export default AppChannelList;
