import MultipleValuePreview from '@register-app/components/layouts/multiple-values-preview';
import {companyTypesNames} from '@register-app/constants/company-type';
import AppCard from '@wieldy/components/AppCard';
import {Typography} from 'antd';
import {
  supplierTypes as SUPPLIER_TYPES,
  supplierTypesNames,
} from 'app/constants/supplier-types';
import Editor from 'app/shared/components/Editor/Editor';
import PropTypes from 'prop-types';
import React from 'react';

import {COMPANY_SIZE} from '../../../app/constants';
import {IS_SUBJECT_LABEL} from '../../constants/is-subject-options';
import {PLATFORMS} from '../../constants/platforms';
import MultipleCheckboxView from '../additional/multiple-checkbox-view';

const isAgency = (types) => {
  return (
    types?.includes(SUPPLIER_TYPES.recruitingAgency) ||
    types?.includes(SUPPLIER_TYPES.staffingAgency)
  );
};

const CompanyInfoView = ({details}) => {
  return (
    <AppCard style={{maxWidth: 820}}>
      <div className={'mb-5'}>
        <Typography.Title level={4}>Company Legal Name</Typography.Title>
        <Typography.Paragraph>{details?.name}</Typography.Paragraph>
      </div>
      <div className={'mb-5'}>
        <Typography.Title level={4}>
          Doing Business As (DBA) Name
        </Typography.Title>
        <Typography.Paragraph>{details?.company_dba_name}</Typography.Paragraph>
      </div>
      <div className={'mb-5'}>
        <Typography.Title level={4}>Supplier Type</Typography.Title>
        <Typography.Paragraph>
          <MultipleValuePreview
            type={details?.type}
            source={supplierTypesNames}
          />
        </Typography.Paragraph>
      </div>
      <div className={'mb-5'}>
        <Typography.Title level={4}>Company Type</Typography.Title>
        <Typography.Paragraph>
          {companyTypesNames[details?.company_type]}
        </Typography.Paragraph>
      </div>
      <div className={'mb-5'}>
        <Typography.Title level={4}>Company Size</Typography.Title>
        <Typography.Paragraph>
          {COMPANY_SIZE[details?.company_size]}
        </Typography.Paragraph>
      </div>
      <div className={'mb-5'}>
        <Typography.Title level={4}>Website</Typography.Title>
        <Typography.Paragraph>{details?.website}</Typography.Paragraph>
      </div>
      <div className={'mb-5'}>
        <Typography.Title level={4}>Linkedin Company Page</Typography.Title>
        <Typography.Paragraph>{details?.linkedin}</Typography.Paragraph>
      </div>
      <div className={'mb-5'}>
        <Typography.Title level={4}>Years in Business</Typography.Title>
        <Typography.Paragraph>
          {details?.years_in_business} years
        </Typography.Paragraph>
      </div>
      <div className={'mb-5'}>
        <Typography.Title level={4}>Company Tax Jurisdiction</Typography.Title>
        <Typography.Paragraph>
          {IS_SUBJECT_LABEL[details?.tax_jurisdiction]}
        </Typography.Paragraph>
      </div>
      {isAgency(details?.type) && (
        <>
          {/*START CHECKBOXES*/}
          <MultipleCheckboxView item={details?.company_recruiting_services} />
          {/*END CHECKBOXES*/}
          <div className={'mb-5'}>
            <Typography.Title level={4}>
              Company Placement Type Ratios
            </Typography.Title>
            <Typography.Paragraph>
              Contingent placement fee [
              {details?.placement_type?.placement_contingent_placement_fee || 0}
              %]
            </Typography.Paragraph>
            <Typography.Paragraph>
              Retained fee [
              {details?.placement_type?.placement_retained_fee || 0}%]
            </Typography.Paragraph>
            <Typography.Paragraph>
              Temporary [
              {details?.placement_type?.placement_temporary_hires || 0}%]
            </Typography.Paragraph>
            <Typography.Paragraph>
              Temp-to-hire [
              {details?.placement_type?.placement_temp_to_perm || 0}%]
            </Typography.Paragraph>
          </div>
          <div className={'mb-5'}>
            <Typography.Title level={4}>Number of Recruiters</Typography.Title>
            <Typography.Paragraph>
              {details?.number_of_recruiters}
            </Typography.Paragraph>
          </div>
          {!!details?.ats_used_by_company && (
            <div className={'mb-5'}>
              <Typography.Title level={4}>ATS Used by Company</Typography.Title>
              <Typography.Paragraph>
                {PLATFORMS[details?.ats_used_by_company]}
              </Typography.Paragraph>
            </div>
          )}
        </>
      )}

      <div className={'mb-5'}>
        <Typography.Title level={4}>About</Typography.Title>
        <Editor readOnly initValue={details?.about} />
      </div>
    </AppCard>
  );
};

CompanyInfoView.propTypes = {
  details: PropTypes.object,
};

export default CompanyInfoView;
