import AppDynamicTabs from '@wieldy/components/AppTabs/AppDynamicTabs';
import AppPageContainer from '@wieldy/components/pageComponents/AppPageContainer';
import AppPageHeader from '@wieldy/components/pageComponents/AppPageHeader';
import {Button} from 'antd';
import {useAccountType} from 'app/hooks/useAccountType';
import AppModalProvider from 'app/providers/AppModalProvider';
import React, {useCallback, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';

import {getTabs} from './constants';
import MainContent from './MainContent';

const Administration = () => {
  const navigate = useNavigate();
  const {category} = useParams();
  const {isSupplier} = useAccountType();
  const tabsData = React.useMemo(() => getTabs(isSupplier), [isSupplier]);
  const [activeTab, setActiveTab] = useState('company');

  const handleActiveTab = useCallback((tab) => setActiveTab(tab), []);

  const activeKey = React.useMemo(() => {
    return category ?? 'company';
  }, [category]);

  let actionButton = null;
  if (activeKey === 'invite-users') {
    actionButton = (
      <Button
        type='primary'
        shape={'round'}
        onClick={() => navigate('/administration/users/invite-user')}>
        Invite New User
      </Button>
    );
  }
  if (activeKey === 'resources') {
    actionButton = (
      <Button
        type='primary'
        shape={'round'}
        onClick={() => navigate('/administration/resource/add-resource')}>
        Add New Resource
      </Button>
    );
  }

  return (
    <AppModalProvider>
      <AppPageContainer
        header={
          <AppPageHeader
            title={activeTab?.title}
            category='Administration'
            tabs={
              <AppDynamicTabs
                tabsData={tabsData}
                activeKey={activeKey}
                setActiveTab={handleActiveTab}
              />
            }
            extra={actionButton}
          />
        }>
        <MainContent />
      </AppPageContainer>
    </AppModalProvider>
  );
};

export default Administration;
