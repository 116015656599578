import AppGridView from '@wieldy/components/AppGridView';
import AppPagination from '@wieldy/components/AppPagination';
import AppHeaderPagination from '@wieldy/components/AppPagination/AppHeaderPagination';
import AppPageContainer from '@wieldy/components/pageComponents/AppPageContainer';
import AppPageHeader from '@wieldy/components/pageComponents/AppPageHeader';
import AppPageSection from '@wieldy/components/pageComponents/AppPageSection';
import {capitalizedString} from '@wieldy/utils/helpers/StringHelper';
import {useBrowse} from 'app/hooks/useBrowse';
import {useSearchFetch} from 'app/hooks/useSearchFetch';
import {query} from 'app/pages/common/Search/data';
import {getItemsData} from 'app/utils/helpers/GetItemsData';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';

const SearchPage = ({entity, card}) => {
  const useFetch = useSearchFetch(entity);

  const {q} = useParams();
  const [value, setValue] = useState(q);
  const [searchValue, setSearchValue] = useState(q);

  useEffect(() => {
    if (q !== searchValue) {
      setValue(q);
      setSearchValue(q);
    }
  }, [q, searchValue, value]);

  const {onChangePage, pageSize, currentPage, onShowSizeChange} = useBrowse({
    initPageSize: 15,
    isReset: q,
  });

  const {isLoading, isFetching, data} = useFetch({
    include: ['total_count'],
    page: currentPage - 1,
    limit: pageSize,
    q: searchValue,
    ...query[entity],
  });

  const title = `Search Results: ${q}`;
  const [result, totalCount] = getItemsData(entity, data);

  return (
    <AppPageContainer
      header={
        <AppPageHeader
          className={'has-tabs-secondary'}
          title={`Search ${capitalizedString(entity)}`}
          category={entity}
        />
      }>
      <AppPageSection
        title={title}
        count={totalCount}
        extra={
          !!result.length && (
            <React.Fragment>
              <AppHeaderPagination
                hideDisplayBorder={true}
                total={totalCount}
                current={currentPage}
                pageSize={pageSize}
                onShowSizeChange={onShowSizeChange}
                onChange={onChangePage}
                showSizeChanger
              />
            </React.Fragment>
          )
        }>
        <AppGridView
          emptyTitle='No Results'
          loading={isLoading || isFetching}
          data={result}
          renderItem={(item) => card(item)}
        />
        <AppPagination
          total={totalCount}
          current={currentPage}
          pageSize={pageSize}
          onChange={onChangePage}
          showTotal={(total, range) => `${range[0]}-${range[1]} of ${total}`}
          onShowSizeChange={onShowSizeChange}
        />
      </AppPageSection>
    </AppPageContainer>
  );
};

SearchPage.propTypes = {
  entity: PropTypes.oneOf([
    'resources',
    'suppliers',
    'clients',
    'requisitions',
  ]),
  card: PropTypes.func,
};

export default SearchPage;
