import AppPageContainer from '@wieldy/components/pageComponents/AppPageContainer';
import AppPageHeader from '@wieldy/components/pageComponents/AppPageHeader';
import {useCreateMarketplaceInviteUser} from 'app/services/maketplaceService';
import {InviteUserForm} from 'app/shared/company';
import marketplaceHelper from 'app/utils/MarketplaceHelper';
import React from 'react';
import {useNavigate} from 'react-router-dom';

const InviteNewUser = () => {
  const navigate = useNavigate();
  const mutation = useCreateMarketplaceInviteUser();

  const handleOnSubmit = (formData) => {
    mutation.mutate(formData, {
      onSuccess: () => {
        navigate(-1);
      },
    });
  };

  return (
    <AppPageContainer
      header={
        <AppPageHeader
          title='Invite New User'
          category='Administration / Users'
          backHeader
        />
      }>
      <InviteUserForm
        onSubmit={handleOnSubmit}
        onCancel={() => navigate(-1)}
        userTypes={marketplaceHelper.getUserTypes()}
        loading={mutation.isLoading}
        role={'marketplace'}
      />
    </AppPageContainer>
  );
};

export default InviteNewUser;
