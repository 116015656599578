import {WarningOutlined} from '@ant-design/icons';
import {Button} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

class AppErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {hasError: false};
  }

  static getDerivedStateFromError(error) {
    console.log('error: ', error);
    // Update state so the next render will show the fallback UI.
    return {hasError: true};
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.log('errorInfo: ', errorInfo);
    // logErrorToMyService(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            padding: 30,
            flexDirection: 'column',
            justifyContent: 'center',
            flex: 1,
          }}>
          <WarningOutlined
            style={{fontSize: 70, marginBottom: 30, color: 'orange'}}
          />
          <div style={{fontSize: 30, marginTop: 4}}>
            Ah! Something went wrong.
          </div>
          <div style={{fontSize: 18, textAlign: 'center'}}>
            Brace yourself till we get the error fixed.
          </div>
          <div style={{fontSize: 18, textAlign: 'center'}}>
            You may also refresh the page or try again latter
          </div>

          <Button
            style={{marginTop: 30}}
            onClick={() => window.location.reload()}>
            Try Again
          </Button>
        </div>
      );
    } else {
      return this.props.children;
    }
  }
}

AppErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AppErrorBoundary;
