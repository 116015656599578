import {Descriptions, Typography} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

import {
  PAYMENT_TERMS,
  TRAVEL_REQUIREMENTS,
  WORKSITES,
} from '../../../../../../constants';
import {getYesOrNo} from '../../../../../../utils/helpers/DataFormatHelper';

const JobDetails = ({placement}) => {
  return (
    <div className='mb-6'>
      <Typography.Title level={4} className={'mb-4'}>
        Job Details
      </Typography.Title>

      <Descriptions
        colon={false}
        labelStyle={{width: '50%'}}
        className='description-root mb-4'
        column={{xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1}}>
        <Descriptions.Item label='Work Model'>
          {WORKSITES[placement.job_worksite]}
        </Descriptions.Item>
        <Descriptions.Item label='Job Location'>
          {placement.jobLocation}
        </Descriptions.Item>
        <Descriptions.Item label='Part Time'>
          {placement.partTime}
        </Descriptions.Item>
        <Descriptions.Item label='Travel Required'>
          {TRAVEL_REQUIREMENTS[placement.job_travel_requirements]}
        </Descriptions.Item>

        <Descriptions.Item label='FLSA Eligible'>
          {getYesOrNo(placement.job_flsa_eligible)}
        </Descriptions.Item>

        <Descriptions.Item label='Payment Terms'>
          {PAYMENT_TERMS[placement.job_payment_term]}
        </Descriptions.Item>

        {placement.tax_engagement !== 'No' && (
          <>
            <Descriptions.Item label='Charge Tax'>
              {getYesOrNo(placement.charge_tax)}
            </Descriptions.Item>
            <Descriptions.Item label='Country Tax'>
              {`${placement.tax_country}%`}
            </Descriptions.Item>
            <Descriptions.Item label='State Tax'>
              {`${placement.tax_state}%`}
            </Descriptions.Item>
            <Descriptions.Item label='City Tax' span={1}>
              {`${placement.tax_city}%`}
            </Descriptions.Item>
          </>
        )}
      </Descriptions>
    </div>
  );
};

JobDetails.propTypes = {
  placement: PropTypes.object,
};
export default JobDetails;
