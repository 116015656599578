import {Rate, Typography} from 'antd';
import React from 'react';

export const getColumns = () => [
  {
    title: 'Skill',
    dataIndex: 'name',
    key: 'name',
    render: (skill) => <Typography.Text>{skill}</Typography.Text>,
    width: 276,
  },
  {
    title: 'Level of Experience',
    dataIndex: 'expertise_level',
    key: 'expertise_level',
    align: 'left',
    width: 180,
    render: (rate) => <Rate disabled value={rate} />,
  },
  {
    title: 'Years of experience',
    dataIndex: 'years_experience',
    key: 'years_experience',
    align: 'left',
    render: (years) => <Typography.Text>{years}</Typography.Text>,
    width: 232,
  },
  {
    title: 'Last used',
    dataIndex: 'last_used',
    key: 'last_used',
    align: 'left',
    render: (date) => <Typography.Text>{date}</Typography.Text>,
    width: 232,
  },
];
