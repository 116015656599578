import React from 'react';

import useStep from '../../../../../../../hooks/useStep';
import SupplementalInfoDetails from './SupplementalInfoDetails';
import SupplementalInfoForm from './SupplementalInfoForm';

const SupplementalInfoStep = () => {
  const {nextStep, setStepData, stepData, currentStep} = useStep();
  return (
    <>
      {currentStep > 5 && stepData ? (
        <SupplementalInfoDetails />
      ) : (
        currentStep === 5 && (
          <SupplementalInfoForm onContinue={nextStep} onSave={setStepData} />
        )
      )}
    </>
  );
};

export default SupplementalInfoStep;
