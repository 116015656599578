import {Steps} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

const AppStep = (props) => {
  return <Steps.Step {...props} />;
};

AppStep.propTypes = {
  icon: PropTypes.node,
  title: PropTypes.node,
  subTitle: PropTypes.node,
  description: PropTypes.node,
};

export default AppStep;
