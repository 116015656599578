import AppCard from '@wieldy/components/AppCard';
import {Typography} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

const Certifications = ({certifications, isCard = true}) => {
  const renderContent = () => (
    <table className='table' style={{margin: '0 -8px'}}>
      <tbody>
        {!!certifications &&
          certifications.map((el) => (
            <tr key={el.certificate}>
              <td>
                <Typography.Text>{el.certificate}</Typography.Text>
                <br />
                <Typography.Text type='secondary'>{el.company}</Typography.Text>
              </td>
              <td align='right'>{el.year}</td>
            </tr>
          ))}
      </tbody>
    </table>
  );

  if (!certifications?.length) {
    return null;
  }

  if (isCard) {
    return (
      <AppCard
        title='Certifications'
        headStyle={{borderBottom: 0}}
        bodyStyle={{paddingTop: 0}}>
        {renderContent()}
      </AppCard>
    );
  }

  return (
    <>
      <Typography.Title level={4}>Certifications</Typography.Title>
      {renderContent()}
    </>
  );
};

Certifications.propTypes = {
  certifications: PropTypes.array,
  isCard: PropTypes.bool,
};

export default Certifications;
