export const COMPANY_TYPES = {
  publicCorporation: 'public_corporation',
  privateCorporation: 'private_corporation',
  limitedLiabilityCompany: 'limited_liability_company',
  partnership: 'partnership',
  other: 'other',
};

export const companyTypesNames = {
  [COMPANY_TYPES.publicCorporation]: 'Public corporation',
  [COMPANY_TYPES.privateCorporation]: 'Private corporation',
  [COMPANY_TYPES.limitedLiabilityCompany]: 'Limited liability company',
  [COMPANY_TYPES.partnership]: 'Partnership',
  [COMPANY_TYPES.other]: 'Other',
};

export const companyTypesOptions = Object.values(COMPANY_TYPES).map((value) => {
  return {
    key: value,
    value,
    label: companyTypesNames[value],
  };
});
