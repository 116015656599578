import AppCard from '@wieldy/components/AppCard';
import AppFormWrapper, {
  AppFormActions,
  AppFormBody,
} from '@wieldy/components/AppFormWrapper';
import AppInput from '@wieldy/components/dataEntry/AppInput';
import AppSelect from '@wieldy/components/dataEntry/AppSelect';
import {
  getRequiredMessage,
  getSelectPlaceHolder,
} from '@wieldy/utils/helpers/MessageHelper';
import {Button, Col, Form, Row, Space, Typography} from 'antd';
import {useAccountType} from 'app/hooks/useAccountType';
import {useUpdateClientUser} from 'app/services/clientService';
import {useUpdateSupplierUser} from 'app/services/supplierService';
import ClientHelper from 'app/utils/ClientHelper';
import GeneralHelper from 'app/utils/GeneralHelper';
import SupplierHelper from 'app/utils/SupplierHelper';
import PropTypes from 'prop-types';
import React, {useMemo} from 'react';
import {useSelector} from 'react-redux';

const EditUserForm = ({onCancel, onSave, formProps}) => {
  const [disabled, setDisabled] = React.useState(true);
  const {isSupplier} = useAccountType();
  const mutation = (isSupplier ? useUpdateSupplierUser : useUpdateClientUser)(
    formProps?.id,
    formProps?.user?.id,
  );
  const {userRole, supplier} = useSelector(({user}) => user.currentAccount);

  const userTypes = useMemo(() => {
    if (isSupplier) {
      const types = SupplierHelper.getUserTypes().filter((item) => {
        if (item.value !== 'agency_recruiter') return true;

        return SupplierHelper.hasAccessByType(supplier?.type);
      });

      return types.filter(
        (option) => (option.value === 'owner' && userRole !== 'owner') || true,
      );
    }

    return ClientHelper.getUserTypes().filter((item) => item.value !== 'owner');
  }, [isSupplier, supplier?.type, userRole]);

  const handleOnFinish = React.useCallback(
    (formdata) => {
      mutation.mutate(formdata, {
        onSuccess: () => {
          onSave();
        },
      });
    },
    [mutation, onSave],
  );

  return (
    <AppCard
      title='User Information'
      headStyle={{borderBottom: 0}}
      bodyStyle={{paddingTop: 8}}>
      <AppFormWrapper>
        <Form
          layout={'horizontal'}
          labelCol={{span: 10}}
          wrapperCol={{span: 14}}
          labelAlign={'left'}
          colon={false}
          initialValues={{
            ...formProps?.user,
            role: formProps?.role,
            status: formProps?.status,
          }}
          onFinish={handleOnFinish}
          onValuesChange={() => setDisabled(false)}>
          <AppFormBody>
            <Typography.Title level={4} className={'mb-3'}>
              Basic Information
            </Typography.Title>
            <Row gutter={[30, 15]}>
              <Col sm={12}>
                <Form.Item
                  label='First Name'
                  name={'first_name'}
                  rules={[
                    {
                      required: true,
                      message: getRequiredMessage('First name'),
                    },
                  ]}>
                  <AppInput />
                </Form.Item>
              </Col>
              <Col sm={12}>
                <Form.Item
                  label='Last Name'
                  name={'last_name'}
                  rules={[
                    {
                      required: true,
                      message: getRequiredMessage('Last name'),
                    },
                  ]}>
                  <AppInput />
                </Form.Item>
              </Col>
              <Col sm={12}>
                <Form.Item
                  label='Title'
                  name={'title'}
                  rules={[
                    {
                      required: true,
                      message: getRequiredMessage('Title'),
                    },
                  ]}>
                  <AppInput />
                </Form.Item>
              </Col>
              <Col sm={12}>
                <Form.Item
                  label='User Type'
                  name={'role'}
                  rules={[
                    {
                      required: true,
                      message: getRequiredMessage('User Type'),
                    },
                  ]}>
                  <AppSelect
                    placeholder={getSelectPlaceHolder('User Type')}
                    options={userTypes}
                  />
                </Form.Item>
              </Col>
              <Col sm={12}>
                <Form.Item
                  label='User Status'
                  name={'status'}
                  rules={[
                    {
                      required: true,
                      message: getRequiredMessage('User Status'),
                    },
                  ]}>
                  <AppSelect
                    placeholder={getSelectPlaceHolder('User Status')}
                    options={GeneralHelper.userStatus()}
                  />
                </Form.Item>
              </Col>
            </Row>
          </AppFormBody>
          <AppFormActions>
            <Space size={16}>
              <Button
                type={'primary'}
                htmlType={'submit'}
                disabled={disabled}
                loading={mutation.isLoading}>
                Save
              </Button>
              <Button type={'primary'} onClick={onCancel} ghost>
                Cancel
              </Button>
            </Space>
          </AppFormActions>
        </Form>
      </AppFormWrapper>
    </AppCard>
  );
};

EditUserForm.propTypes = {
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
  formProps: PropTypes.object,
};

export default EditUserForm;
