import {useEffect, useRef} from 'react';

export const useOutsideClick = (callback, additionalRefs = []) => {
  const ref = useRef();

  useEffect(() => {
    const handleClick = (event) => {
      const call = additionalRefs.some(
        (r) => r.current && r.current?.contains(event.target),
      );

      if (ref.current && !ref.current?.contains(event.target) && !call) {
        callback();
      }
    };

    document.addEventListener('click', handleClick, true);

    return () => {
      document.removeEventListener('click', handleClick, true);
    };
  }, [additionalRefs, callback, ref]);

  return ref;
};
