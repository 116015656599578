import {Descriptions, Typography} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

const NotificationSettings = ({user}) => {
  return (
    <div className='mb-6'>
      <Typography.Title level={4} className={'mb-6'}>
        Notification Settings
      </Typography.Title>
      <Descriptions
        colon={false}
        labelStyle={{width: '50%'}}
        className='description-root mb-4'
        column={1}>
        <Descriptions.Item label='Disable Email Notifications'>
          {user?.disableEmailNotifications}
        </Descriptions.Item>
        <Descriptions.Item label='Disable Time & Expense Notifications'>
          {user?.disableTimeExpenseNotifications}
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
};

NotificationSettings.propTypes = {
  user: PropTypes.object,
};

export default NotificationSettings;
