import {useRole} from 'app/hooks/useRole';
import React, {useCallback, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';

import StepperGeneral from './Steppers/StepperGeneral';

const MainContent = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const {isResource} = useRole();
  const {isEditMode} = useSelector(({expenses}) => expenses.temp);

  useEffect(() => {
    if (isEditMode) {
      setCurrentStep(2);
    }
  }, [isEditMode, isResource]);

  const onHandleChangeStep = useCallback((step) => {
    setCurrentStep(step);
  }, []);

  const goToNext = useCallback(() => {
    setCurrentStep(currentStep + 1);
  }, [currentStep]);
  return (
    <>
      <StepperGeneral
        currentStep={currentStep}
        setCurrentStep={onHandleChangeStep}
        goToNext={goToNext}
      />
    </>
  );
};
MainContent.propTypes = {};

export default React.memo(MainContent);
