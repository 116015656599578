import {useCallback, useEffect, useState} from 'react';

export const useMobileView = () => {
  const [isMobileNavVisible, setIsMobileNavVisible] = useState(false);
  useEffect(() => {
    const mobileChannelList = document.querySelector('#mobile-channel-list');
    if (isMobileNavVisible && mobileChannelList) {
      mobileChannelList.classList.add('show');
    } else if (!isMobileNavVisible && mobileChannelList) {
      mobileChannelList.classList.remove('show');
    }
  }, [isMobileNavVisible]);

  return useCallback(() => {
    setIsMobileNavVisible((isMobile) => !isMobile);
  }, []);
};
