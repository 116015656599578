import {Modal} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

import AppModalContext from './AppModalContext';

const AppModalProvider = ({children}) => {
  const [modalProps, setModelProps] = React.useState({
    visible: false,
    footer: null,
  });

  const open = React.useCallback((props = {}) => {
    setModelProps((prev) => ({
      ...prev,
      ...props,
      visible: true,
    }));
  }, []);

  const close = React.useCallback(() => {
    setModelProps((prev) => ({...prev, visible: false}));
  }, []);

  const modalContext = React.useMemo(() => {
    return {
      open,
      close,
    };
  }, [open, close]);

  const {content, ...resetProps} = modalProps;

  return (
    <AppModalContext.Provider value={modalContext}>
      <Modal {...resetProps} onCancel={close}>
        {content}
      </Modal>
      {children}
    </AppModalContext.Provider>
  );
};

AppModalProvider.propTypes = {
  children: PropTypes.node,
};

export default AppModalProvider;
