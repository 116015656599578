import {formatDate} from 'app/utils/helpers/DataFormatHelper';

export const timecardStatuses = {
  approved: 'Approved',
  pending: 'Pending Approval',
  submitted: 'Pending Approval',
  rejected: 'Rejected',
  new: 'Not Submitted',
};

export const timeCards = [
  {
    id: 'RPO-3930',
    endDate: '07/28/2021',
    payUnits: 40.0,
    netTotal: 4000.0,
    status: 'approved',
    createdBy: {
      firstName: 'Julia',
      lastName: 'Gonzalez',
    },
    createdAt: 'Aug 7, 2021 at 05:00pm',
    submitted: {
      submittedBy: {
        firstName: 'Julia',
        lastName: 'Gonzalez',
      },
      submittedAt: 'Aug 7, 2021 at 06:30pm',
    },
    approved: {
      approvedBy: {
        firstName: 'Dug',
        lastName: 'McClure',
      },
      approvedAt: 'Aug 8, 2021 at 9:29pm',
    },
    invoice: {
      id: 4554,
      invoiceNumber: 394829,
      invoiceBy: {
        firstName: 'Kathlyn',
        lastName: 'Zenema',
      },
      invoicedAt: 'Aug 11, 2021',
    },
    engagement: {
      id: 'RIE-9373',
    },
    supplier: {
      id: '4564',
      name: 'PizzaHut, LLC',
    },
    resource: {
      id: 'ROE-9087',
      firstName: 'Stella',
      lastName: 'Brown',
    },
    requisition: {
      title: 'Java Technical Architect',
    },
  },
  {
    id: 'RPO-3931',
    endDate: '07/28/2021',
    payUnits: 40.0,
    netTotal: 4000.0,
    status: 'approved',
    createdBy: {
      firstName: 'Julia',
      lastName: 'Gonzalez',
    },
    createdAt: 'Aug 7, 2021 at 05:00pm',
    submitted: {
      submittedBy: {
        firstName: 'Julia',
        lastName: 'Gonzalez',
      },
      submittedAt: 'Aug 7, 2021 at 06:30pm',
    },
    approved: {
      approvedBy: {
        firstName: 'Dug',
        lastName: 'McClure',
      },
      approvedAt: 'Aug 8, 2021 at 9:29pm',
    },
    invoice: {
      id: 4554,
      invoiceNumber: 394829,
      invoiceBy: {
        firstName: 'Kathlyn',
        lastName: 'Zenema',
      },
      invoicedAt: 'Aug 11, 2021',
    },
    engagement: {
      id: 'RIE-9373',
    },
    supplier: {
      id: '4564',
      name: 'PizzaHut, LLC',
    },
    resource: {
      id: 'ROE-9087',
      firstName: 'Stella',
      lastName: 'Brown',
    },
    requisition: {
      title: 'Java Technical Architect',
    },
  },
  {
    id: 'RPO-3932',
    endDate: '07/28/2021',
    payUnits: 40.0,
    netTotal: 4000.0,
    status: 'pending-approval',
    createdBy: {
      firstName: 'Julia',
      lastName: 'Gonzalez',
    },
    createdAt: 'Aug 7, 2021 at 05:00pm',
    submitted: {
      submittedBy: {
        firstName: 'Julia',
        lastName: 'Gonzalez',
      },
      submittedAt: 'Aug 7, 2021 at 06:30pm',
    },
    approved: {
      approvedBy: {
        firstName: 'Dug',
        lastName: 'McClure',
      },
      approvedAt: 'Aug 8, 2021 at 9:29pm',
    },
    engagement: {
      id: 'RIE-9373',
    },
    supplier: {
      id: '4564',
      name: 'PizzaHut, LLC',
    },
    resource: {
      id: 'ROE-9087',
      firstName: 'Stella',
      lastName: 'Brown',
    },
    requisition: {
      title: 'Java Technical Architect',
    },
  },
  {
    id: 'RPO-3933',
    endDate: '07/28/2021',
    payUnits: 40.0,
    netTotal: 4000.0,
    status: 'approved',
    createdBy: {
      firstName: 'Julia',
      lastName: 'Gonzalez',
    },
    createdAt: 'Aug 7, 2021 at 05:00pm',
    submitted: {
      submittedBy: {
        firstName: 'Julia',
        lastName: 'Gonzalez',
      },
      submittedAt: 'Aug 7, 2021 at 06:30pm',
    },
    approved: {
      approvedBy: {
        firstName: 'Dug',
        lastName: 'McClure',
      },
      approvedAt: 'Aug 8, 2021 at 9:29pm',
    },
    invoice: {
      id: 4554,
      invoiceNumber: 394829,
      invoiceBy: {
        firstName: 'Kathlyn',
        lastName: 'Zenema',
      },
      invoicedAt: 'Aug 11, 2021',
    },
    engagement: {
      id: 'RIE-9373',
    },
    supplier: {
      id: '4564',
      name: 'PizzaHut, LLC',
    },
    resource: {
      id: 'ROE-9087',
      firstName: 'Stella',
      lastName: 'Brown',
    },
    requisition: {
      title: 'Java Technical Architect',
    },
  },
  {
    id: 'RPO-3934',
    endDate: '07/28/2021',
    payUnits: 40.0,
    netTotal: 4000.0,
    status: 'approved',
    createdBy: {
      firstName: 'Julia',
      lastName: 'Gonzalez',
    },
    createdAt: 'Aug 7, 2021 at 05:00pm',
    submitted: {
      submittedBy: {
        firstName: 'Julia',
        lastName: 'Gonzalez',
      },
      submittedAt: 'Aug 7, 2021 at 06:30pm',
    },
    approved: {
      approvedBy: {
        firstName: 'Dug',
        lastName: 'McClure',
      },
      approvedAt: 'Aug 8, 2021 at 9:29pm',
    },
    invoice: {
      id: 4554,
      invoiceNumber: 394829,
      invoiceBy: {
        firstName: 'Kathlyn',
        lastName: 'Zenema',
      },
      invoicedAt: 'Aug 11, 2021',
    },
    engagement: {
      id: 'RIE-9373',
    },
    supplier: {
      id: '4564',
      name: 'PizzaHut, LLC',
    },
    resource: {
      id: 'ROE-9087',
      firstName: 'Stella',
      lastName: 'Brown',
    },
    requisition: {
      title: 'Java Technical Architect',
    },
  },
  {
    id: 'RPO-3935',
    endDate: '07/28/2021',
    payUnits: 40.0,
    netTotal: 4000.0,
    status: 'approved',
    createdBy: {
      firstName: 'Julia',
      lastName: 'Gonzalez',
    },
    createdAt: 'Aug 7, 2021 at 05:00pm',
    submitted: {
      submittedBy: {
        firstName: 'Julia',
        lastName: 'Gonzalez',
      },
      submittedAt: 'Aug 7, 2021 at 06:30pm',
    },
    approved: {
      approvedBy: {
        firstName: 'Dug',
        lastName: 'McClure',
      },
      approvedAt: 'Aug 8, 2021 at 9:29pm',
    },
    invoice: {
      id: 4554,
      invoiceNumber: 394829,
      invoiceBy: {
        firstName: 'Kathlyn',
        lastName: 'Zenema',
      },
      invoicedAt: 'Aug 11, 2021',
    },
    engagement: {
      id: 'RIE-9373',
    },
    supplier: {
      id: '4564',
      name: 'PizzaHut, LLC',
    },
    resource: {
      id: 'ROE-9087',
      firstName: 'Stella',
      lastName: 'Brown',
    },
    requisition: {
      title: 'Java Technical Architect',
    },
  },
  {
    id: 'RPO-3936',
    endDate: '07/28/2021',
    payUnits: 40.0,
    netTotal: 4000.0,
    status: 'approved',
    createdBy: {
      firstName: 'Julia',
      lastName: 'Gonzalez',
    },
    createdAt: 'Aug 7, 2021 at 05:00pm',
    submitted: {
      submittedBy: {
        firstName: 'Julia',
        lastName: 'Gonzalez',
      },
      submittedAt: 'Aug 7, 2021 at 06:30pm',
    },
    approved: {
      approvedBy: {
        firstName: 'Dug',
        lastName: 'McClure',
      },
      approvedAt: 'Aug 8, 2021 at 9:29pm',
    },
    invoice: {
      id: 4554,
      invoiceNumber: 394829,
      invoiceBy: {
        firstName: 'Kathlyn',
        lastName: 'Zenema',
      },
      invoicedAt: 'Aug 11, 2021',
    },
    engagement: {
      id: 'RIE-9373',
    },
    supplier: {
      id: '4564',
      name: 'PizzaHut, LLC',
    },
    resource: {
      id: 'ROE-9087',
      firstName: 'Stella',
      lastName: 'Brown',
    },
    requisition: {
      title: 'Java Technical Architect',
    },
  },
  {
    id: 'RPO-3937',
    endDate: '07/28/2021',
    payUnits: 40.0,
    netTotal: 4000.0,
    status: 'approved',
    createdBy: {
      firstName: 'Julia',
      lastName: 'Gonzalez',
    },
    createdAt: 'Aug 7, 2021 at 05:00pm',
    submitted: {
      submittedBy: {
        firstName: 'Julia',
        lastName: 'Gonzalez',
      },
      submittedAt: 'Aug 7, 2021 at 06:30pm',
    },
    approved: {
      approvedBy: {
        firstName: 'Dug',
        lastName: 'McClure',
      },
      approvedAt: 'Aug 8, 2021 at 9:29pm',
    },
    invoice: {
      id: 4554,
      invoiceNumber: 394829,
      invoiceBy: {
        firstName: 'Kathlyn',
        lastName: 'Zenema',
      },
      invoicedAt: 'Aug 11, 2021',
    },
    engagement: {
      id: 'RIE-9373',
    },
    supplier: {
      id: '4564',
      name: 'PizzaHut, LLC',
    },
    resource: {
      id: 'ROE-9087',
      firstName: 'Stella',
      lastName: 'Brown',
    },
    requisition: {
      title: 'Java Technical Architect',
    },
  },
];

export const timeTypes = [
  {
    slug: 'RTH',
    name: 'Regular Time Hours',
  },
  {
    slug: 'OT',
    name: 'Overtime',
  },
];

export const timeCardEntries = [
  {
    id: 421,
    timeCardID: 'RPO-3930',
    workDate: formatDate('07/28/2021', 'MM/DD/YY'),
    timeType: 'RTH',
    hours: 7.0,
    payUnits: 40,
    billingRate: 60,
    netTotal: 4000,
    task: {
      id: 22761,
      description: 'Guide style creation',
      note: '',
    },
  },
  {
    id: 422,
    timeCardID: 'RPO-3930',
    workDate: formatDate('07/21/2021', 'MM/DD/YY'),
    timeType: 'RTH',
    hours: 8.5,
    payUnits: 32,
    billingRate: 60,
    netTotal: 3200,
    task: {
      id: 22761,
      description: 'Guide style creation',
      note: '',
    },
  },
  {
    id: 423,
    timeCardID: 'RPO-3930',
    workDate: formatDate('07/21/2021', 'MM/DD/YY'),
    timeType: 'OT',
    hours: 9.0,
    payUnits: 32,
    billingRate: 60,
    netTotal: 3200,
    task: {
      id: 22762,
      description: 'Setup a design guide',
      note: 'Working on resolving issues',
    },
  },
  {
    id: 424,
    timeCardID: 'RPO-3930',
    workDate: formatDate('07/19/2021', 'MM/DD/YY'),
    timeType: 'RTH',
    hours: 8.0,
    payUnits: 32,
    billingRate: 60,
    netTotal: 3200,
    task: {
      id: 22761,
      description: 'Guide style creation',
      note: '',
    },
  },
  {
    id: 425,
    timeCardID: 'RPO-3930',
    workDate: formatDate('07/16/2021', 'MM/DD/YY'),
    timeType: 'RTH',
    hours: 3.0,
    payUnits: 32,
    billingRate: 60,
    netTotal: 3200,
    task: {
      id: 22763,
      description: 'Ready the prototype',
      note: 'daily stand-up and retrospective',
    },
  },
  {
    id: 426,
    timeCardID: 'RPO-3930',
    workDate: formatDate('07/12/2021', 'MM/DD/YY'),
    timeType: 'RTH',
    hours: 6.0,
    payUnits: 32,
    billingRate: 60,
    netTotal: 3200,
    task: {
      id: 22761,
      description: 'Guide style creation',
    },
  },
];
