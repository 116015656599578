import './index.style.less';

import {Button, notification} from 'antd';
import PropTypes from 'prop-types';
import {forwardRef, useCallback, useMemo} from 'react';
import {ErrorCode, useDropzone} from 'react-dropzone';

import DocxIcon from '../../../assets/icons/DocxIcon';
import PdfIcon from '../../../assets/icons/PdfIcon';

const FILE_TYPES = {
  pdf: 'application/pdf',
  doc: 'application/msword',
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
};

export const DEFAULT_ACCEPT = {
  [FILE_TYPES.pdf]: ['.pdf'],
  [FILE_TYPES.doc]: ['.doc'],
  [FILE_TYPES.docx]: ['.docx'],
};

const FILE_ICONS_MAP = {
  [FILE_TYPES.pdf]: PdfIcon,
  [FILE_TYPES.doc]: DocxIcon,
  [FILE_TYPES.docx]: DocxIcon,
};

const FILE_ERRORS = {
  [ErrorCode.FileInvalidType]: 'Only PDF / MS Word file formats are supported',
  [ErrorCode.FileTooLarge]: 'File size is too large [max size 1MB]',
};

const UploadFile = forwardRef(
  ({maxFiles = 1, value, defaultValue, accept = DEFAULT_ACCEPT, onChange}) => {
    const handleRemove = () => {
      onChange(null);
    };

    const onDropRejected = (rejectedFiles) => {
      const rejectedFile = rejectedFiles[0];

      notification.error({message: FILE_ERRORS[rejectedFile.errors[0].code]});
    };

    const onDrop = useCallback(
      (files) => {
        onChange(files[0]);
      },
      [onChange],
    );
    const {getInputProps, getRootProps, open} = useDropzone({
      maxFiles,
      accept,
      onDrop,
      maxSize: 1000000,
      onDropRejected,
    });

    const currentValue = useMemo(() => {
      if (typeof value === 'string') {
        return defaultValue;
      }

      return value;
    }, [value, defaultValue]);

    const renderPreview = () => {
      const FileIcon = FILE_ICONS_MAP[currentValue.type];
      return (
        <div className='upload-preview'>
          <FileIcon className='upload-preview__image' />
          <div className='upload-preview__actions'>
            <Button type='primary' onClick={open}>
              Update file
            </Button>
            <Button type='outline' onClick={handleRemove}>
              Remove
            </Button>
          </div>
        </div>
      );
    };

    const renderDropzone = () => {
      return (
        <div {...getRootProps({})}>
          <input {...getInputProps({})} />
          <Button type='primary'>Upload file</Button>
        </div>
      );
    };

    return currentValue ? renderPreview() : renderDropzone();
  },
);

UploadFile.propTypes = {
  maxFiles: PropTypes.number,
  value: PropTypes.object,
  defaultValue: PropTypes.object,
  accept: PropTypes.object,
  onChange: PropTypes.func,
};

export default UploadFile;
