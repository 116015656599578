import AppCard from '@wieldy/components/AppCard';
import AppRevealCard from '@wieldy/components/AppRevealCard';
import {Typography} from 'antd';
import {useReveal} from 'app/hooks/useReveal';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {MdPersonPin} from 'react-icons/md';

import {ClientCard} from '../../client';
import RevealCardFooter from '../partials/RevealCardFooter';
import RequisitionCardDetail from '../RequisitionCardDetail';
import CandidateCardDetail from './CandidateCardDetail';
import PlacementCardContent from './PlacementCardContent';
import PlacementCardHeader from './PlacementCardHeader';

const PlacementCard = ({placement}) => {
  const [revealed, setReveal] = useState(false);
  const {user, userName, userType, link} = useReveal(placement);
  useEffect(() => {
    if (placement) {
      setReveal(false);
    }
  }, [placement]);

  return (
    <AppRevealCard
      title={`${userType} Details`}
      revealed={revealed}
      setReveal={setReveal}
      onClose={() => setReveal(false)}
      revealComponent={<ClientCard client={user} />}>
      <AppCard
        className='card-header-small'
        title={
          <Typography.Text className='font-medium text-uppercase text-xs'>
            <MdPersonPin className='mx-2 text-xl' color='gray' />
            Placement #{placement?.hid}
          </Typography.Text>
        }
        extra={
          <Typography.Text className='text-xs font-semibold'>
            {placement?.statusName}
          </Typography.Text>
        }
        headStyle={{
          backgroundColor: placement?.isOpen ? '#D7FFD7' : '#DEDEDE',
          borderBottom: 'none',
        }}>
        <PlacementCardHeader placement={placement} />
        <PlacementCardContent placement={placement} />
        <CandidateCardDetail
          applicant={placement.applicant}
          candidateId={placement.candidate.id}
        />
        <RequisitionCardDetail requisition={placement?.requisition} />
        <RevealCardFooter
          link={link}
          title={userName}
          subTitle={userType}
          rating={user?.ratingTotal}
          reviews={user?.ratingCount}
          onClick={() => setReveal(true)}
        />
      </AppCard>
    </AppRevealCard>
  );
};

PlacementCard.propTypes = {
  placement: PropTypes.object,
};

export default PlacementCard;
