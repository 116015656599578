import {Space, Typography} from 'antd';
import {COUNTRIES, US_STATES} from 'app/constants';
import PropTypes from 'prop-types';
import React from 'react';

const Text = Typography.Text;

const isUSA = (address) => {
  const country = getCountry(address);
  return country === COUNTRIES.united_states;
};

const getCountry = (address) => COUNTRIES[address.country] || address.country;
const getState = (address) => {
  return isUSA(address) ? US_STATES[address.state] || address.state : '';
};

const AddressWidget = ({title, address}) => {
  return (
    <Space direction='vertical' className={'mb-4 mr-10'}>
      <Text type='secondary'>{title}</Text>
      <Text>{address.company_legal_name}</Text>
      <Text>
        {address.line_1} {address.line_2}
      </Text>
      <Text>
        {address.city}, {getState(address)} {address.postal_code}
      </Text>
      <Text>{getCountry(address)}</Text>
    </Space>
  );
};

AddressWidget.propTypes = {
  title: PropTypes.string,
  address: PropTypes.object,
};

export default React.memo(AddressWidget);
