import AppBorderBox from '@wieldy/components/AppBorderBox';
import AppCard from '@wieldy/components/AppCard';
import AppLoader from '@wieldy/components/AppLoader';
import AppSelect from '@wieldy/components/dataEntry/AppSelect';
import {getRequiredMessage} from '@wieldy/utils/helpers/MessageHelper';
import {Button, Form, Select, Typography} from 'antd';
import useStep from 'app/hooks/useStep';
import {useGetRequisitions} from 'app/services/requisitionService';
import {
  setInquiryRequisition,
  setInquiryStatusPage,
} from 'app/store/features/inquiry/inquirySlice';
import {getItemsData} from 'app/utils/helpers/GetItemsData';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';

import {
  CONSTRACT_OPTIONS_KEYS,
  WORK_TYPE_KEYS,
} from '../../../../../../constants/work-types';
import {useGetInquiries} from '../../../../../../services/inquiryService';
import {
  getQueryParams,
  TABS,
} from '../../../../suppliers/ViewSupplier/constants';

const SelectRequisition = () => {
  const dispatch = useDispatch();
  const {requisitionId} = useParams();
  const {currentStep, nextStep, goToStep} = useStep();
  const {requisition, isEditMode, targetResource, suppliers} = useSelector(
    ({inquiry}) => inquiry,
  );
  const inquiriesStatus = ['withdrawn', 'closed', 'engaged', 'declined'];

  const {data: supplierInquiry} = useGetInquiries({
    ...getQueryParams(TABS.INQUIRIES, suppliers[0]?.id),
  });

  const existInquiries =
    supplierInquiry?.inquiries.length &&
    supplierInquiry?.inquiries.filter(
      (inq) => !inquiriesStatus.includes(inq.status),
    );

  const {data, isLoading} = useGetRequisitions(
    {
      status: 'open',
      target_resource: targetResource,
      type: WORK_TYPE_KEYS.contract,
    },
    {
      enabled: !isEditMode,
    },
  );
  const [allRequisitions] = getItemsData('requisitions', data);
  const requisitions = allRequisitions.filter((el) =>
    el.accept_candidates_from?.includes(CONSTRACT_OPTIONS_KEYS.talent_vendors),
  );

  const req = requisitions?.find((el) => el.id === requisitionId);

  useEffect(() => {
    if (req) {
      const {id, job_title} = req;
      dispatch(
        setInquiryRequisition({
          id,
          job_title,
        }),
      );
    }
  }, [dispatch, req]);

  const onFormSubmit = (formData) => {
    const isExistInquiry =
      suppliers?.length &&
      !!existInquiries &&
      existInquiries?.find(
        (inq) => inq.requisition.id === formData?.requisition,
      );
    const item = requisitions.find((item) => item.id === formData?.requisition);
    const {id, job_title} = item;

    dispatch(
      setInquiryRequisition({
        id,
        job_title,
      }),
    );
    dispatch(
      setInquiryStatusPage({
        statusPage: isExistInquiry ? 'edit' : 'create',
        targetId: existInquiries[0]?.id,
        existingCandidates:
          existInquiries[0]?.candidates.map((can) => can.resource.id) || [],
      }),
    );

    if (targetResource) {
      goToStep(3);
    } else if (nextStep) nextStep();
  };

  if (isLoading) {
    return <AppLoader />;
  }

  return currentStep > 1 ? (
    <AppBorderBox
      className='d-inline-flex px-6 mt-3'
      style={{backgroundColor: '#FFF'}}>
      <Typography.Title level={4} className={'mb-0'}>
        {requisition.job_title}
      </Typography.Title>
    </AppBorderBox>
  ) : (
    <AppCard
      title='Select Requisition'
      headStyle={{border: 0}}
      bodyStyle={{paddingTop: 0}}>
      <Form
        layout='vertical'
        onFinish={onFormSubmit}
        autoComplete='off'
        initialValues={{requisition: requisition.id}}>
        <Form.Item
          name='requisition'
          label='Requisition'
          rules={[
            {
              required: true,
              message: getRequiredMessage('Requisition'),
            },
          ]}>
          <AppSelect
            showSearch
            loading={isLoading}
            placeholder='Select Requisition'
            style={{minWidth: '280px', width: 'auto', maxWidth: '100%'}}>
            {requisitions.map((req) => (
              <Select.Option key={req.id} value={req.id}>
                {`${req.job_title}::${req.hid}`}
              </Select.Option>
            ))}
          </AppSelect>
        </Form.Item>

        <Button type='primary' htmlType='submit'>
          Continue
        </Button>
      </Form>
    </AppCard>
  );
};

export default React.memo(SelectRequisition);
