import './index.style.less';

import {Button, Carousel} from 'antd';
import PropTypes from 'prop-types';
import React, {useRef} from 'react';
import {RiArrowLeftSLine, RiArrowRightSLine} from 'react-icons/ri';

const AppCardCarousel = ({data, renderRow, ...restProps}) => {
  const carouselRef = useRef();
  return (
    <div className='carousel-root'>
      <Button
        type='text'
        shape='circle'
        icon={<RiArrowLeftSLine />}
        onClick={() => carouselRef?.current?.prev()}
        className='carousel-control-prev'
      />
      <Carousel dots={false} {...restProps} ref={carouselRef}>
        {data.map((item, index) => (
          <div key={index}>{renderRow(item, index)}</div>
        ))}
      </Carousel>
      <Button
        type='text'
        shape='circle'
        icon={<RiArrowRightSLine />}
        onClick={() => carouselRef?.current?.next()}
        className='carousel-control-next'
      />
    </div>
  );
};

AppCardCarousel.propTypes = {
  data: PropTypes.array.isRequired,
  renderRow: PropTypes.func.isRequired,
};

export default AppCardCarousel;
