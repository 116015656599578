import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  engagements: null,
  placements: null,
  submissions: null,
  inquiries: null,
};

export const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setPageState: (state, action) => {
      state[action.payload.page] = action.payload.content;
    },
    resetPageState: (state, action) => {
      if (action.payload) {
        state[action.payload] = null;
      } else {
        return initialState;
      }
    },
  },
});

export const {setPageState, resetPageState} = commonSlice.actions;

export default commonSlice.reducer;
