import {clientQueryParams as clients} from 'app/pages/main/clients/Clients/constants';
import {requisitionClientQueries as requisitions} from 'app/pages/main/requisitions/data';
import {queryParams as resources} from 'app/pages/main/resources/MainContent/constants';
import {supplierQueryParams as suppliers} from 'app/pages/main/suppliers/Suppliers/constants';

export const query = {
  resources,
  requisitions,
  clients,
  suppliers,
};
