import AppDynamicTabs from '@wieldy/components/AppTabs/AppDynamicTabs';
import AppPageContainer from '@wieldy/components/pageComponents/AppPageContainer';
import AppPageHeader from '@wieldy/components/pageComponents/AppPageHeader';
import {Button} from 'antd';
import {CATEGORIES} from 'app/constants';
import {useAccountType} from 'app/hooks/useAccountType';
import {baseTabsData, supplierTabsData} from 'app/pages/main/expenses/data';
import ExpenseCardsTable from 'app/pages/main/expenses/MainContent/ExpenseCardsTable';
import React, {useEffect, useMemo, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';

const Expenses = () => {
  const {category} = useParams();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState();

  const {isSupplier, isClient} = useAccountType();
  const isShowButtons = isSupplier;

  useEffect(() => {
    if (!activeTab && !category) {
      !isSupplier ? navigate('/expenses/submitted') : navigate('/expenses/new');
    } else if (isClient && category === CATEGORIES.NEW) {
      navigate('/expenses/submitted');
    }
  }, [activeTab, isSupplier, category, isClient, navigate]);

  const activeKey = React.useMemo(() => {
    return category ?? CATEGORIES.SUBMITTED;
  }, [category]);

  const tabsData = useMemo(
    () => (isSupplier ? supplierTabsData.concat(baseTabsData) : baseTabsData),
    [isSupplier],
  );

  return (
    <AppPageContainer
      header={
        <AppPageHeader
          title={`${activeTab?.title}`}
          category='Expenses'
          tabs={
            <AppDynamicTabs
              tabsData={tabsData}
              activeKey={activeKey}
              setActiveTab={setActiveTab}
            />
          }
          extra={
            isShowButtons ? (
              <Button
                type='primary'
                shape='round'
                onClick={() => navigate('/expenses/add-expense')}>
                Add New Expense
              </Button>
            ) : null
          }
        />
      }>
      <ExpenseCardsTable title={activeTab?.title} status={activeKey} />
    </AppPageContainer>
  );
};

export default Expenses;
