import {
  CLIENT_ENGAGEMENT_STATUSES,
  CLIENT_RELATIONSHIP_STATUSES,
  CLIENT_USER_TYPES,
} from 'app/constants';

import CompanyHelper from './CompanyHelper';

class ClientHelper extends CompanyHelper {
  static getEngagementStatus() {
    return Object.keys(CLIENT_ENGAGEMENT_STATUSES).map((key) => {
      return {
        key: key,
        label: CLIENT_ENGAGEMENT_STATUSES[key],
        value: key,
      };
    });
  }

  static getUserTypes() {
    return Object.keys(CLIENT_USER_TYPES).map((typeKey) => {
      return {
        key: typeKey,
        label: CLIENT_USER_TYPES[typeKey],
        value: typeKey,
      };
    });
  }

  static getRelationshipStatus() {
    return Object.keys(CLIENT_RELATIONSHIP_STATUSES).map((key) => {
      return {
        key: key,
        value: key,
        label: CLIENT_RELATIONSHIP_STATUSES[key],
      };
    });
  }

  static getActiveSuppliers(fieldName = 'active_suppliers') {
    return [
      {
        key: '1-10',
        value: '1-10',
        label: '1-10 suppliers',
        query: {
          [`${fieldName}[gte]`]: 1,
          [`${fieldName}[lte]`]: 10,
        },
      },
      {
        key: '11-20',
        value: '11-20',
        label: '11-20 suppliers',
        query: {
          [`${fieldName}[gte]`]: 11,
          [`${fieldName}[lte]`]: 20,
        },
      },
      {
        key: '21-50',
        value: '21-50',
        label: '21-50 suppliers',
        query: {
          [`${fieldName}[gte]`]: 21,
          [`${fieldName}[lte]`]: 50,
        },
      },
      {
        key: '50+',
        value: '50+',
        label: '50+ suppliers',
        query: {
          [`${fieldName}[gt]`]: 50,
        },
      },
    ];
  }

  static getOpeningRequisitions(fieldName = 'open_requisitions') {
    return [
      {
        key: '1-10',
        value: '1-10',
        label: '1-10 requisitions',
        query: {
          [`${fieldName}[gte]`]: 1,
          [`${fieldName}[lte]`]: 10,
        },
      },
      {
        key: '11-20',
        value: '11-20',
        label: '11-20 requisitions',
        query: {
          [`${fieldName}[gte]`]: 11,
          [`${fieldName}[lte]`]: 20,
        },
      },
      {
        key: '21-50',
        value: '21-50',
        label: '21-50 requisitions',
        query: {
          [`${fieldName}[gte]`]: 21,
          [`${fieldName}[lte]`]: 50,
        },
      },
      {
        key: '1-5',
        value: '1-5',
        label: '50+ requisitions',
        query: {
          [`${fieldName}[gt]`]: 50,
        },
      },
    ];
  }
}

export default ClientHelper;
