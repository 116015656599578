import AppGridView from '@wieldy/components/AppGridView';
import AppLoader from '@wieldy/components/AppLoader';
import AppHeaderPagination from '@wieldy/components/AppPagination/AppHeaderPagination';
import AppPageSection from '@wieldy/components/pageComponents/AppPageSection';
import {Empty} from 'antd';
import {useAccountType} from 'app/hooks/useAccountType';
import {useBrowse} from 'app/hooks/useBrowse';
import usePageQuery from 'app/hooks/usePageQuery';
import EngagementModal from 'app/modals/EngagementModal';
import {sortParams} from 'app/pages/main/engagements/data';
import {useGetEngagements} from 'app/services/engagementsService';
import EngagementCard from 'app/shared/cards/EngagementCard';
import {getItemsData} from 'app/utils/helpers/GetItemsData';
import PropTypes from 'prop-types';
import React from 'react';
import {useParams} from 'react-router-dom';

const Browse = ({userQuery}) => {
  const {isSupplier} = useAccountType();
  const {category} = useParams();
  const sortValueParams = sortParams[category];
  const {
    pageSize,
    currentPage,
    onChangePage,
    onShowSizeChange,
    onSortBy,
    sortBy,
  } = useBrowse({initSort: sortValueParams[0].value, initPageSize: 15});

  const {filterQuery, queryInit, currentPageParams} = usePageQuery();

  const {title, empty} = currentPageParams;

  const {isLoading, data} = useGetEngagements({
    status: category,
    include: [...queryInit.include],
    expand: [...queryInit.expand, 'candidate', 'resource', 'resource_user'],
    ...filterQuery,
    ...userQuery,
    sort: [sortBy],
    limit: pageSize,
    page: currentPage - 1,
  });

  const [engagements, totalEngagements] = getItemsData('engagements', data);

  if (isLoading) {
    return <AppLoader />;
  }

  if (!engagements.length) {
    return <Empty description={empty} />;
  }

  return (
    <React.Fragment>
      <AppPageSection
        title={title}
        count={totalEngagements}
        extra={
          <AppHeaderPagination
            total={totalEngagements}
            current={currentPage}
            pageSize={pageSize}
            onChange={onChangePage}
            onShowSizeChange={onShowSizeChange}
            showSizeChanger
            hideStartBorder={true}
            showSortBy={true}
            sortByOptions={sortValueParams}
            sortBy={sortBy}
            onSortByChange={onSortBy}
          />
        }>
        <AppGridView
          emptyTitle={empty}
          data={engagements}
          renderItem={(engagement) => (
            <EngagementCard
              key={engagement.id}
              engagement={new EngagementModal(engagement, isSupplier)}
            />
          )}
        />
      </AppPageSection>
    </React.Fragment>
  );
};

Browse.propTypes = {
  userQuery: PropTypes.object,
};

Browse.defaultProps = {
  userQuery: {},
};

export default Browse;
