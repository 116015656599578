import {CATEGORIES, USER_INCLUDES} from 'app/constants';

export const statuses = [
  CATEGORIES.POTENTIAL,
  CATEGORIES.PENDING,
  CATEGORIES.FORMER,
];

export const clientQueryParams = {
  include: [
    'total_count',
    'skill_tags',
    ...USER_INCLUDES.supplierIncludeDetails,
  ],
  expand: ['supplier', 'resource', 'requisition', 'applicant'],
};

export const supplierQueryParams = {
  include: ['total_count', 'skill_tags'],
  expand: ['resource', 'requisition', 'applicant'],
};

export const tabsData = [
  {key: 'all', title: 'All', route: '/candidates/all'},
  {key: 'potential', title: 'Potential', route: '/candidates/potential'},
  {key: 'pending', title: 'Pending', route: '/candidates/pending'},
  {key: 'former', title: 'Former', route: '/candidates/former'},
];

export const getPageQueries = (isSupplier = false, isView = false) => {
  const queryParams = isSupplier ? supplierQueryParams : clientQueryParams;
  if (isView) {
    return queryParams;
  }
  return {
    all: {
      queryParams,
      title: 'All Candidates',
    },
    potential: {
      queryParams: {
        ...queryParams,
        status: 'potential',
      },
      title: 'Potential Candidates',
      label: 'Potential',
      rowTitle: 'Potential Candidates',
      empty: 'No Potential Candidates',
    },
    pending: {
      queryParams: {
        ...queryParams,
        status: 'pending',
      },
      title: 'Pending Candidates',
      label: 'Pending',
      rowTitle: 'Pending Candidates',
      empty: 'No Pending Candidates',
    },
    former: {
      queryParams: {
        ...queryParams,
        status: 'former',
      },
      title: 'Former Candidates',
      label: 'Former',
      rowTitle: 'Former Candidates',
      empty: 'No Former Candidates',
    },
  };
};
