import {Card} from 'antd';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

const AppCard = ({title, extra, children, className, heightFull, ...rest}) => {
  return (
    <Card
      className={clsx('app-card', {'full-height': heightFull}, className)}
      title={title}
      extra={extra ? extra : null}
      bordered={false}
      {...rest}>
      {children}
    </Card>
  );
};

export default AppCard;

AppCard.propTypes = {
  action: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  extra: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  children: PropTypes.node,
  cover: PropTypes.node,
  className: PropTypes.string,
  actions: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  heightFull: PropTypes.bool,
};

AppCard.defaultProps = {};
