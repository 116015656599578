import AppTable from '@wieldy/components/AppTable';
import {Modal} from 'antd';
import {
  deleteItems,
  setChangeTime,
  setInitialLogForm,
} from 'app/store/features/timecards/timecardsSlice';
import PropTypes from 'prop-types';
import React, {useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {getColumns} from './columns';

const TimeCardsConfirmModal = ({
  open,
  handleShow,
  handleLogForm,
  handleReset,
}) => {
  const {temp} = useSelector(({timecards}) => timecards);
  const {currentGroupedItems} = temp;
  const dispatch = useDispatch();

  const handleUpdate = useCallback(
    (item) => {
      dispatch(setInitialLogForm(item));
      dispatch(setChangeTime());
      handleLogForm(true);
      handleShow(false);
    },
    [dispatch, handleLogForm, handleShow],
  );

  const handleDelete = useCallback(
    (item) => {
      handleShow(false);
      dispatch(deleteItems(item));
    },
    [dispatch, handleShow],
  );

  const bulkActions = useCallback(
    (item) => {
      return [
        {
          title: 'Edit',
          onClick: () => handleUpdate(item),
        },
        {
          title: 'Delete',
          onClick: () => handleDelete(item),
        },
      ];
    },
    [handleDelete, handleUpdate],
  );

  return (
    <Modal
      bodyStyle={{
        padding: 0,
        borderRadius: 8,
        overflow: 'hidden',
      }}
      width={850}
      open={open}
      mask={true}
      onCancel={handleReset}
      footer={false}>
      <AppTable
        scroll={{x: 'max-content'}}
        bulkActions={bulkActions}
        columns={getColumns()}
        pagination={false}
        dataSource={currentGroupedItems}
      />
    </Modal>
  );
};

TimeCardsConfirmModal.propTypes = {
  open: PropTypes.bool,
  handleShow: PropTypes.func,
  handleLogForm: PropTypes.func,
  handleReset: PropTypes.func,
};

export default TimeCardsConfirmModal;
