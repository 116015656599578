import {Modal} from 'antd';
import {useCallback} from 'react';

import {useResetState} from './useResetState';

export const useRemove = (id, fn, label = 'timecard') => {
  const onReset = useResetState();
  const [modal, component] = Modal.useModal();

  const handleDelete = useCallback(async () => {
    await fn(id);
    onReset();
  }, [fn, id, onReset]);

  const onDelete = useCallback(() => {
    modal.confirm({
      title: `Delete this ${label}?`,
      onOk: async () => handleDelete(),
      okText: 'Delete',
    });
  }, [handleDelete, label, modal]);

  return [component, onDelete];
};
