import {Checkbox} from 'antd';
import cn from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import {Controller, useFormContext} from 'react-hook-form';

const AppFormCheckbox = ({name, label, isRequired, handleChange, ...props}) => {
  const {control} = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({field}) => {
        const {value, onChange, ...rest} = field;
        return (
          <Checkbox
            {...props}
            {...rest}
            checked={value}
            onChange={(e) => {
              handleChange && handleChange(e.target.checked);
              onChange(e.target.checked);
            }}>
            <span className={cn(isRequired && 'form-field-required')}>
              {label}
            </span>
          </Checkbox>
        );
      }}
    />
  );
};

AppFormCheckbox.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  isRequired: PropTypes.bool,
  handleChange: PropTypes.func,
};
export default AppFormCheckbox;
