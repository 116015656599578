import AppAmountView from '@wieldy/components/helperComponents/AppAmountView';
import {Table, Typography} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

const TotalTimeSummary = ({
  title = 'Total',
  tax,
  amount,
  billable,
  span = 3,
  msp_total,
  msp_fee,
  showMSP = false,
}) => {
  return (
    <React.Fragment>
      <Table.Summary fixed>
        <Table.Summary.Row>
          <Table.Summary.Cell colSpan={span} index={1} align='right'>
            <Typography.Text strong={true}>{title}</Typography.Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={2}>
            <Typography.Text strong={true}>
              <AppAmountView amount={amount} />
            </Typography.Text>
          </Table.Summary.Cell>
          {showMSP && (
            <>
              <Table.Summary.Cell index={3}>
                <Typography.Text strong={true}>
                  <AppAmountView amount={msp_fee} />
                </Typography.Text>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={4}>
                <Typography.Text strong={true}>
                  <AppAmountView amount={msp_total} />
                </Typography.Text>
              </Table.Summary.Cell>
            </>
          )}
          <Table.Summary.Cell index={5}>
            <Typography.Text strong={true}>
              <AppAmountView amount={tax} />
            </Typography.Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell align='right' index={6}>
            <Typography.Text strong={true}>
              <AppAmountView amount={billable} />
            </Typography.Text>
          </Table.Summary.Cell>
        </Table.Summary.Row>
      </Table.Summary>
    </React.Fragment>
  );
};

TotalTimeSummary.propTypes = {
  title: PropTypes.string,
  tax: PropTypes.number,
  amount: PropTypes.number,
  billable: PropTypes.number,
  span: PropTypes.number,
  showMSP: PropTypes.bool,
  msp_fee: PropTypes.number,
  msp_total: PropTypes.number,
};

export default TotalTimeSummary;
