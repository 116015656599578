import {
  default as Candidates,
  ViewCandidate,
} from 'app/pages/main/candidates/index';

export const supplier_CandidateRoutes = [
  {
    path: 'candidates',
    element: <Candidates />,
  },
  {
    path: 'candidates/:category',
    element: <Candidates />,
  },
  {
    path: 'candidates/:id/view-candidate',
    element: <ViewCandidate />,
  },
];

export const client_CandidateRoutes = [
  {
    path: '/candidates',
    element: <Candidates />,
  },
  {
    path: '/candidates/:category',
    element: <Candidates />,
  },
  {
    path: '/candidates/:id/view-candidate',
    element: <ViewCandidate />,
  },
];
