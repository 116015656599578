import useStep from 'app/hooks/useStep';
import React from 'react';

import SkillsDetails from './SkillsDetails';
import SkillsForm from './SkillsForm';

const SkillsStep = () => {
  const {nextStep, setStepData, stepData, currentStep} = useStep();
  return (
    <>
      {currentStep > 4 && stepData ? (
        <SkillsDetails />
      ) : (
        currentStep === 4 && (
          <SkillsForm onContinue={nextStep} onSave={setStepData} />
        )
      )}
    </>
  );
};

export default SkillsStep;
