import {clientAPI} from 'app/api/clientApi';
import axios from 'app/config/axios';
import useReactQuery, {
  useQueryClient,
  useReactMutation,
} from 'app/hooks/useReactQuery';
import CompanyUserModal from 'app/modals/CompanyUserModal';

import {baseAPI} from '../api/baseApi';
import {getCurrentClient} from './authService';

export const useGetAllClients = (params = {}, statuses = [], options = {}) => {
  return useReactQuery(
    ['clients', params],
    async () => {
      const allPromise = statuses.map((status) =>
        clientAPI.getClients({...params, relation_status: status}),
      );
      const response = await Promise.all(allPromise);
      return response.map(({data, config}) => ({
        clients: data?.data || [],
        total_count: data?.total_count || 0,
        status: config.params.relation_status,
      }));
    },
    {
      enabled: !!statuses.length,
      ...options,
    },
  );
};

/** Get clients */
export const useGetClients = (queryParams = {}, options = {}) => {
  const params = {
    ...queryParams,
  };
  return useReactQuery(
    ['clients', params],
    async () => {
      const {data} = await axios.get('clients', {params});
      return {
        clients: data?.data ? data?.data : [],
        total_count: data?.total_count ?? 0,
      };
    },
    {
      keepPreviousData: true,
      ...options,
    },
  );
};

/** Create client account */
export const useCreateClient = () => {
  const client = useQueryClient();
  return useReactMutation(async (postdata) => {
    const {data} = await axios.post('clients', postdata);
    await client.invalidateQueries();
    return {
      data,
      message: 'Client created successfully',
    };
  });
};

export const useSentClientInvite = () => {
  const client = useQueryClient();

  return useReactMutation(async ({account_owner, phone_number}) => {
    const {data} = await baseAPI.post('client_invitations', {
      ...account_owner,
      phone_number,
      role: 'owner',
    });
    await client.invalidateQueries();
    return {
      data,
      message: 'Invitation sent successfully',
    };
  });
};

/** Update current client account */
export const useUpdateClient = (fetchCurrentAccount = true) => {
  return useReactMutation(async ({clientId, payload}) => {
    const {data} = await axios.patch(`clients/${clientId}`, payload);
    // fetch include some additional update for include parameter
    if (fetchCurrentAccount) {
      const {client} = await getCurrentClient();
      return client;
    }
    return data;
  });
};

/** Get client users */
export const useGetClientUsers = (queryParams = {}) => {
  const params = {
    expand: ['user'],
    include: ['total_count'],
    ...queryParams,
  };
  return useReactQuery(['client_members', params], async () => {
    const {data} = await axios.get('client_members', {params});
    return {
      items: data?.data ? data?.data : [],
      totalCount: data?.total_count ?? 0,
    };
  });
};

/** Get single client user **/
export const useGetClientUser = (id, queryParams = {}) => {
  const params = {
    expand: ['user'],
    ...queryParams,
  };
  return useReactQuery(['client_members', id], async () => {
    const {data} = await axios.get('client_members/' + id, {params});
    return data;
  });
};

/** Update single client user **/
export const useUpdateClientUser = (id, userId) => {
  const client = useQueryClient();
  return useReactMutation(async (payload) => {
    const {title, first_name, last_name, role, status} = payload;
    await axios.patch('profile/' + userId, {title, first_name, last_name});
    const {data} = await axios.patch('client_members/' + id, {role, status});
    await client.invalidateQueries(['client_members', id]);
    return {
      message: 'User updated successfully',
      data,
    };
  });
};

/** Delete single client user **/
export const useDeleteClientUser = (id) => {
  return useReactMutation(async () => {
    const {data} = await axios.delete('client_members/' + id);
    return {
      message: 'User deleted successfully',
      data,
    };
  });
};

/** Get client invited users */
export const useGetClientInvitations = (queryParams = {}) => {
  const params = {
    include: ['total_count'],
    ...queryParams,
  };
  return useReactQuery(
    ['client_invitations', params],
    async ({queryKey}) => {
      const {data} = await axios.get('client_invitations', {
        params: queryKey[1],
      });
      return {
        items: data?.data
          ? data?.data.map((user) => new CompanyUserModal(user))
          : [],
        totalCount: data?.total_count ?? 0,
      };
    },
    {meta: params},
  );
};

/** Add new invitation */
export const useCreateInviteUser = () => {
  return useReactMutation(async (payload) => {
    const {data} = await axios.post('client_invitations', payload);
    return {
      data,
      message: 'Invitation sent successfully',
    };
  });
};

/** Get client suppliers */
export const useGetSuppliers = (params = {}) => {
  return useReactQuery(
    ['suppliers', params],
    async () => {
      const {data} = await axios.get('suppliers', {params});
      return {
        suppliers: data?.data ?? [],
        total_count: data?.total_count ?? 0,
      };
    },
    {
      keepPreviousData: true,
    },
  );
};

/** View supplier by client */
export const useGetSupplier = (id, params = {}) => {
  return useReactQuery(['suppliers', id], async () => {
    const {data} = await axios.get(`suppliers/${id}`, {params});
    return data;
  });
};

/** Add supplier bookmark via client */
export const useAddBookmarkToSupplier = (singlePage = false) => {
  const client = useQueryClient();
  return useReactMutation(async ({id, isBookmarked}) => {
    const payload = {supplier: id};
    let message;
    if (isBookmarked === false) {
      await axios.post('/supplier_bookmarks', payload);
      message = 'Supplier bookmarked successfully';
    } else {
      await axios.delete('/supplier_bookmarks', {data: payload});
      message = 'Supplier bookmark removed';
    }
    const queryKey = singlePage ? ['suppliers', id] : ['suppliers'];
    await client.invalidateQueries(queryKey);
    return {message};
  });
};

/** Add multi bulk action for administration section */
export const useClientAdministrationBulkActions = () => {
  const client = useQueryClient();
  return useReactMutation(async ({action, payload}) => {
    switch (action) {
      case 'deleteInvitation': {
        await axios.delete(`/client_invitations/${payload?.id}`);
        await client.invalidateQueries('client_invitations');
        return {
          message: `Invitation withdrawn successfully`,
        };
      }
      case 'resendInvitation': {
        await axios.patch(`/client_invitations/${payload?.id}`, {resent: true});
        await client.invalidateQueries('client_invitations');
        return {
          message: `Invitation resent successfully`,
        };
      }
    }
  });
};

/** Client review by supplier */
export const useGetSupplierReviews = (queryParams = {}) => {
  const params = {
    include: [
      'total_count',
      'relation_duration',
      'client_common_active_engagements',
      'client_common_total_engagements',
      'supplier_review',
      'relation_status',
    ],
    relation_statuses: ['active', 'former'],
    ...queryParams,
  };
  return useReactQuery(
    ['supplier_reviews', params],
    async () => {
      const {data} = await axios.get('suppliers', {params});
      return {
        items: data?.data ?? [],
        totalCount: data?.total_count ?? 0,
      };
    },
    {refetchOnMount: true},
  );
};

/** Add supplier review by client */
export const useAddSupplierReview = () => {
  return useReactMutation(async (payload) => {
    const {data} = axios.post('supplier_reviews', payload);
    return {
      message: 'Supplier review created successfully',
      data,
    };
  });
};

/** Update supplier review by client */
export const useUpdateSupplierReview = (reviewId) => {
  return useReactMutation(async ({payload}) => {
    const {data} = axios.patch('supplier_reviews/' + reviewId, payload);
    return {
      message: 'Supplier review updated successfully',
      data,
    };
  });
};

/** Delete supplier review by client */
export const useDeleteSupplierReview = (reviewId) => {
  return useReactMutation(async () => {
    const {data} = axios.delete('supplier_reviews/' + reviewId);
    return {
      message: 'Supplier review deleted successfully',
      data,
    };
  });
};

/** Client resource reviews */
export const useGetResourceReviews = (queryParams = {}) => {
  const params = {
    expand: ['resource', 'supplier', 'requisition', 'hiring_manager'],
    include: ['total_count', 'resource_review'],
    ...queryParams,
  };
  return useReactQuery(
    ['resource_reviews', params],
    async () => {
      const {data} = await axios.get('engagements', {params});
      return {
        items: data?.data ?? [],
        totalCount: data?.total_count ?? 0,
      };
    },
    {refetchOnMount: true},
  );
};

/** Add resource review by client */
export const useAddResourceReview = () => {
  return useReactMutation(async (payload) => {
    const {data} = axios.post('resource_reviews', payload);
    return {
      message: 'Resource review created successfully',
      data,
    };
  });
};

/** Update resource review by client */
export const useUpdateResourceReview = (reviewId) => {
  return useReactMutation(async ({payload}) => {
    const {data} = axios.patch('resource_reviews/' + reviewId, payload);
    return {
      message: 'Resource review updated successfully',
      data,
    };
  });
};

/** Delete resource review by client */
export const useDeleteResourceReview = (reviewId) => {
  return useReactMutation(async () => {
    const {data} = axios.delete('resource_reviews/' + reviewId);
    return {
      message: 'Resource review deleted successfully',
      data,
    };
  });
};

/** Get client invoices */
export const useGetInvoices = (queryParams = {}) => {
  const params = {
    expand: ['updated_by', 'engagement'],
    include: ['total_count'],
    ...queryParams,
  };
  return useReactQuery(
    ['invoices', params],
    async () => {
      const {data} = await axios.get('invoices', {params});
      return {
        items: data?.data ?? [],
        total_count: data?.total_count ?? 0,
      };
    },
    {
      keepPreviousData: true,
    },
  );
};

/** Get client invoice */
export const useGetInvoice = (invoiceId) => {
  const params = {
    expand: ['updated_by', 'engagement', 'client', 'supplier', 'placement'],
    include: ['resource', 'hiring_manager', 'applicant'],
  };
  return useReactQuery(
    ['invoices', invoiceId],
    async () => {
      const {data} = await axios.get(`invoices/${invoiceId}`, {params});
      return data;
    },
    {
      keepPreviousData: true,
    },
  );
};

/** Get client invoice timecard lines */
export const useGetInvoiceTimecardLines = (invoiceId, queryParams = {}) => {
  const params = {
    invoice: invoiceId,
    expand: ['timecard'],
    ...queryParams,
  };
  return useReactQuery(
    ['invoice_timecard_lines', invoiceId],
    async () => {
      const {data} = await axios.get('invoice_timecard_lines', {params});
      return {
        items: data?.data ?? [],
      };
    },
    {
      keepPreviousData: true,
    },
  );
};

/** Get client invoice expense lines */
export const useGetInvoiceExpenseLines = (invoiceId, queryParams = {}) => {
  const params = {
    invoice: invoiceId,
    expand: ['expense_item'],
    ...queryParams,
  };
  return useReactQuery(
    ['invoice_expense_lines', invoiceId],
    async () => {
      const {data} = await axios.get('invoice_expense_lines', {params});
      return {
        items: data?.data ?? [],
      };
    },
    {
      keepPreviousData: true,
    },
  );
};

/** Get client report */
export const useGetReport = (queryParams = {}) => {
  const params = {
    ...queryParams,
  };
  return useReactQuery(
    ['/reports/client_report', params],
    async () => {
      const {data} = await axios.get('/reports/client_report', {params});
      return {
        ...data,
      };
    },
    {refetchOnMount: true},
  );
};

/** Get client Open Requisitions metric */
export const useGetOpenRequisitionsReport = (queryParams = {}) => {
  const params = {
    ...queryParams,
  };
  return useReactQuery(
    ['/reports/open_requisitions', params],
    async () => {
      const {data} = await axios.get('/reports/open_requisitions', {params});
      return {
        items: data?.data ? data?.data : [],
      };
    },
    {refetchOnMount: true},
  );
};

/** Get client Active Suppliers metric */
export const useGetActiveSuppliersReport = (queryParams = {}) => {
  const params = {
    ...queryParams,
  };
  return useReactQuery(
    ['/reports/active_account_relationships', params],
    async () => {
      const {data} = await axios.get('/reports/active_account_relationships', {
        params,
      });
      return {
        items: data?.data ? data?.data : [],
      };
    },
    {refetchOnMount: true},
  );
};

/** Get client Active Engagements metric */
export const useGetActiveEngagementsReport = (queryParams = {}) => {
  const params = {
    ...queryParams,
  };
  return useReactQuery(
    ['/reports/active_engagements', params],
    async () => {
      const {data} = await axios.get('/reports/active_engagements', {
        params,
      });
      return {
        items: data?.data ? data?.data : [],
      };
    },
    {refetchOnMount: true},
  );
};

/** Get supplier invoices metric */
export const useGetTotalEarningsReport = (queryParams = {}) => {
  const params = {
    ...queryParams,
  };
  return useReactQuery(
    ['/reports/paid_invoices', params],
    async () => {
      const {data} = await axios.get('/reports/paid_invoices', {
        params,
      });
      return {
        items: data?.data ? data?.data : [],
      };
    },
    {refetchOnMount: true},
  );
};

/** Get potential candidates metric */
export const useGetPotentialCandidatesReport = (queryParams = {}) => {
  const params = {
    ...queryParams,
  };
  return useReactQuery(
    ['/reports/potential_candidates', params],
    async () => {
      const {data} = await axios.get('/reports/potential_candidates', {
        params,
      });
      return {
        items: data?.data ? data?.data : [],
      };
    },
    {refetchOnMount: true},
  );
};

/** Update user avatar from resource side **/
export const useUpdateAvatarUser = (userId) => {
  return useReactMutation(async (payload) => {
    const {avatar_url} = payload;
    await axios.patch('profile/' + userId, {avatar_url});

    return {
      message: 'User updated successfully',
    };
  });
};
