import AppPageContainer from '@wieldy/components/pageComponents/AppPageContainer';
import AppPageHeader from '@wieldy/components/pageComponents/AppPageHeader';
import React from 'react';

import MainContent from './MainContent';

const Notifications = () => {
  return (
    <AppPageContainer
      header={
        <AppPageHeader category='Notifications' title='All Notifications' />
      }>
      <MainContent />
    </AppPageContainer>
  );
};

export default Notifications;
