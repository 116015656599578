import AppPageContainer from '@wieldy/components/pageComponents/AppPageContainer';
import {Button} from 'antd';
import React from 'react';

import {useMagicLink} from '../../../services/mergeService';
import MergeLink from './merge-link';

const Merge = () => {
  const {mutate, data, isLoading} = useMagicLink();
  const handleMerge = () => {
    mutate();
  };
  return (
    <AppPageContainer>
      <Button disabled={isLoading} onClick={handleMerge} type='primary'>
        Merge
      </Button>
      {data?.linkToken && <MergeLink link={data?.linkToken} />}
    </AppPageContainer>
  );
};

export default Merge;
