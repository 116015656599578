import {MANAGER_USER_TYPES} from 'app/constants';

import CompanyHelper from './CompanyHelper';

class MarketplaceHelper extends CompanyHelper {
  static getUserTypes() {
    return Object.keys(MANAGER_USER_TYPES).map((typeKey) => {
      return {
        key: typeKey,
        label: MANAGER_USER_TYPES[typeKey],
        value: typeKey,
      };
    });
  }
}

export default MarketplaceHelper;
