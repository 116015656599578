import {Button, Col, Dropdown, Form, Menu, Row, Space, Typography} from 'antd';
import {useAccountType} from 'app/hooks/useAccountType';
import Editor from 'app/shared/components/Editor/Editor';
import {
  resetSubmissionState,
  setSubmissionMessage,
} from 'app/store/features/submission/inquirySlice';
import {handleGetLength} from 'app/utils/helpers/GetStringLength';
import PropTypes from 'prop-types';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {RiMore2Fill} from 'react-icons/ri';
import {useDispatch} from 'react-redux';

const EditNote = ({
  status,
  message,
  handleEditNote,
  title,
  isShowForm,
  handleCancel,
}) => {
  const [isEdit, setIsEdit] = useState(false);
  const [acMessage, setAcMessage] = useState(null);

  const {isSupplier} = useAccountType();
  const isClosed = ['closed', 'engaged', 'withdrawn', 'declined'].includes(
    status,
  );
  const [form] = Form.useForm();
  const ref = useRef();
  const dispatch = useDispatch();

  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    return () => {
      dispatch(resetSubmissionState());
    };
  }, [dispatch]);

  const onFinish = useCallback(
    (formdata) => {
      const existData = !!handleGetLength(formdata.text);
      setIsEdit(false);
      setAcMessage(formdata.text);
      setDisabled(true);

      if (formdata?.text) {
        handleEditNote(
          existData ? formdata.text : null,
          acMessage ? '' : 'create',
        );
        dispatch(setSubmissionMessage(existData ? formdata.text : null));
      }
    },
    [acMessage, dispatch, handleEditNote],
  );

  const onChange = () => {
    setDisabled(false);
  };

  const handleChange = () => {
    setIsEdit(!isEdit);
  };

  const handleCancelForm = () => {
    setIsEdit(!isEdit);
    handleCancel();
  };

  const handleDelete = useCallback(() => {
    setAcMessage(null);
    handleEditNote(null);
  }, [handleEditNote]);

  useEffect(() => {
    if (message) {
      setAcMessage(message);
    }

    if (!message?.length && isShowForm) {
      setIsEdit(isShowForm);
    }
  }, [message, isShowForm]);

  if (!message && !isShowForm) return null;

  return (
    <React.Fragment>
      {!!acMessage && !isEdit && (
        <>
          {title && (
            <Row gutter={[10, 10]} className={'mb-4'}>
              <Col style={{alignContent: 'center'}} xs={22}>
                <Typography.Title level={5} className={'mb-0'}>
                  {title}
                </Typography.Title>
              </Col>
              {isSupplier && !isClosed && (
                <Col className='p-0'>
                  <Dropdown
                    overlay={
                      <Menu>
                        <Menu.Item key={'edit'} onClick={handleChange}>
                          Edit
                        </Menu.Item>
                        <Menu.Item key={'delete'} onClick={handleDelete}>
                          Delete
                        </Menu.Item>
                      </Menu>
                    }
                    placement='bottomRight'
                    trigger='click'>
                    <Button
                      type='text'
                      shape='circle'
                      size='small'
                      icon={<RiMore2Fill className={'text-xl'} />}
                    />
                  </Dropdown>
                </Col>
              )}
            </Row>
          )}
          <Typography.Paragraph className={'mb-6'} style={{color: '#7D7D7D'}}>
            <div dangerouslySetInnerHTML={{__html: acMessage}} />
          </Typography.Paragraph>
        </>
      )}

      {isEdit && (
        <div className={'mb-4'}>
          <Typography.Title level={5} className={'mb-5'}>
            {title}
          </Typography.Title>
          <Form
            layout='horizontal'
            form={form}
            onFinish={onFinish}
            autoComplete='off'
            className={'mb-2'}>
            <Form.Item
              name='text'
              rules={[
                {
                  required: false,
                  message: '',
                },
              ]}
              className={'w-full mb-0'}>
              <Editor
                isUseAntdform
                ref={ref}
                showCounter
                placeholder={'Type your note here'}
                initValue={acMessage}
                maxLength={2000}
                onChange={onChange}
              />
            </Form.Item>
            <Space size={'small'} className='mb-5'>
              <Button type='primary' htmlType='submit' disabled={disabled}>
                Save
              </Button>
              <Button type='primary' ghost onClick={handleCancelForm}>
                Cancel
              </Button>
            </Space>
          </Form>
        </div>
      )}
    </React.Fragment>
  );
};

EditNote.propTypes = {
  status: PropTypes.string,
  message: PropTypes.string,
  handleEditNote: PropTypes.func,
  title: PropTypes.string,
  isShowForm: PropTypes.bool,
  handleCancel: PropTypes.func,
};

export default EditNote;
