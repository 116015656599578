import AppBorderBox from '@wieldy/components/AppBorderBox';
import AppCard from '@wieldy/components/AppCard';
import AppLoader from '@wieldy/components/AppLoader';
import AppSelect from '@wieldy/components/dataEntry/AppSelect';
import {getRequiredMessage} from '@wieldy/utils/helpers/MessageHelper';
import {Button, Form, Select, Typography} from 'antd';
import {WORKSITES} from 'app/constants';
import {WORK_TYPE_KEYS} from 'app/constants/work-types';
import useStep from 'app/hooks/useStep';
import RequisitionModal from 'app/modals/RequisitionModal';
import {useGetRequisitions} from 'app/services/requisitionService';
import {
  resetPlacementState,
  setPlacementDetails,
  setPlacementJobDetails,
  setPlacementRequisition,
} from 'app/store/features/placement/placementSlice';
import {getItemsData} from 'app/utils/helpers/GetItemsData';
import moment from 'moment';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';

const SelectRequisition = () => {
  const dispatch = useDispatch();
  const {currentStep, nextStep} = useStep();
  const {requisition} = useSelector(({placement}) => placement);
  const {data, isLoading} = useGetRequisitions({
    status: 'open',
    expand: ['hiring_manager', 'account_manager'],
    type: WORK_TYPE_KEYS.direct_hire,
  });
  const [requisitions] = getItemsData('requisitions', data);
  const onFormSubmit = (formData) => {
    const requisition = requisitions.find(
      (item) => item.id === formData?.requisition,
    );
    const {
      id,
      start_date,
      travel_requirements,
      job_status,
      job_country,
      job_worksite,
      expenses_eligible,
      flsa_eligible,
      hiring_manager,
    } = requisition;

    if (requisition.id && requisition.id !== id) {
      dispatch(resetPlacementState());
    }

    dispatch(setPlacementRequisition(new RequisitionModal(requisition)));

    dispatch(
      setPlacementDetails({
        start_date: start_date ? moment.unix(start_date) : undefined,
        hiring_manager,
      }),
    );

    dispatch(
      setPlacementJobDetails({
        job_worksite: Object.keys(WORKSITES).includes(job_worksite)
          ? job_worksite
          : null,
        job_worksite_location:
          typeof job_country === 'string'
            ? job_country
            : Array.isArray(job_country) && job_country.length === 1
            ? job_country[0]
            : null,
        job_travel_requirements: travel_requirements,
        job_status,
        job_expenses_eligible: expenses_eligible,
        job_flsa_eligible: flsa_eligible,
      }),
    );

    if (nextStep) nextStep();
  };

  if (isLoading) {
    return <AppLoader />;
  }

  if (currentStep > 1) {
    return (
      <AppBorderBox
        className='d-inline-flex px-6 mt-3'
        style={{backgroundColor: '#FFF'}}>
        <Typography.Title level={4} className={'mb-0'}>
          {requisition.jobTitle}
        </Typography.Title>
      </AppBorderBox>
    );
  }

  return (
    <AppCard
      title='Select Requisition'
      headStyle={{border: 0}}
      bodyStyle={{paddingTop: 0}}>
      <Form
        layout='vertical'
        onFinish={onFormSubmit}
        autoComplete='off'
        initialValues={{requisition: requisition.id}}>
        <Form.Item
          name='requisition'
          label='Requisition'
          rules={[
            {
              required: true,
              message: getRequiredMessage('Requisition'),
            },
          ]}>
          <AppSelect
            showSearch
            loading={isLoading}
            placeholder='Select requisition'
            style={{minWidth: '340px', width: 'auto', maxWidth: '100%'}}>
            {requisitions.map((req) => (
              <Select.Option key={req.id} value={req.id}>
                {`${req.job_title} ::  ${req.hid}`}
              </Select.Option>
            ))}
          </AppSelect>
        </Form.Item>

        <Button type='primary' htmlType='submit'>
          Continue
        </Button>
      </Form>
    </AppCard>
  );
};

export default SelectRequisition;
