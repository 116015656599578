import AppTag from '@wieldy/components/AppTag/AppTag';
import AppPageContainer from '@wieldy/components/pageComponents/AppPageContainer';
import AppPageHeader from '@wieldy/components/pageComponents/AppPageHeader';
import {Button, Typography} from 'antd';
import useStep from 'app/hooks/useStep';
import RequisitionModal from 'app/modals/RequisitionModal';
import {RequisitionContentCard} from 'app/shared/requisitions/index';
import React from 'react';

const Preview = () => {
  const {setIsPreview, finishSteps, stepData} = useStep();
  const requisitionModal = new RequisitionModal(stepData);
  requisitionModal.isPreviewMode = true;
  return (
    <AppPageContainer
      header={
        <AppPageHeader
          category='Requisition'
          title={requisitionModal.jobTitle}
          count={
            <AppTag
              color='success'
              shape='circle'
              label='OPEN - ACCEPTING CANDIDATES'
              className='tracking-normal'
            />
          }
          ghost={true}
          topContent={
            <div className='d-flex justify-content-center mb-8'>
              <div className='alert-default'>
                <Typography.Paragraph className='my-2 text-lg mr-4'>
                  This is a preview of the requisition you are about to create.
                </Typography.Paragraph>
                <Button
                  type='primary'
                  shape='round'
                  className='mr-4'
                  onClick={finishSteps}>
                  Submit
                </Button>
                <Button shape='round' onClick={() => setIsPreview(false)}>
                  Make Changes
                </Button>
              </div>
            </div>
          }
        />
      }>
      <RequisitionContentCard requisition={requisitionModal} />
    </AppPageContainer>
  );
};

export default Preview;
