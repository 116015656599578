import {Table} from 'antd';
import {formatMinutes} from 'app/utils/helpers/DataFormatHelper';
import {getCurrentWeek} from 'app/utils/helpers/GetCurrentWeek';
import {sumMinutes} from 'app/utils/helpers/SumMinutes';
import {cloneDeep} from 'lodash';
import moment from 'moment';

export const getSummary = (pageData, date) => {
  const clearData = cloneDeep(pageData);
  const daysKeysAndValues = getCurrentWeek(date);
  const weekDays = daysKeysAndValues.map(([, d]) => d);

  const clearArray = clearData.map((el) => {
    delete el.id;
    delete el.tempId;
    delete el.engagement_task;
    return el;
  });

  const sumData = sumMinutes(clearArray);

  const totalOfAll = Object.values(sumData).reduce((acc, value) => {
    if (typeof value === 'number') {
      acc += value;
    }
    return acc;
  }, 0);

  const totalNodes = weekDays.map((el, index) => {
    const dateFormated = moment(el).format('YYYY-MM-DD');
    if (dateFormated in sumData) {
      return (
        <Table.Summary.Cell key={index} index={index + 1}>
          <span className='font-semibold'>
            {formatMinutes(sumData[dateFormated])}
          </span>
        </Table.Summary.Cell>
      );
    } else {
      return (
        <Table.Summary.Cell key={index} index={index + 1}>
          <span className='font-semibold'>0</span>
        </Table.Summary.Cell>
      );
    }
  });

  return (
    <Table.Summary fixed>
      <Table.Summary.Row>
        <Table.Summary.Cell index={0}>
          <span className='font-semibold'>Total</span>
        </Table.Summary.Cell>
        {totalNodes}
        <Table.Summary.Cell index={7}>
          <span className='font-semibold'>{formatMinutes(totalOfAll)}</span>
        </Table.Summary.Cell>
      </Table.Summary.Row>
    </Table.Summary>
  );
};
