import AppTable from '@wieldy/components/AppTable';
import {Typography} from 'antd';
import {useAccountType} from 'app/hooks/useAccountType';
import TotalTimeSummary from 'app/pages/main/financials/ViewInvoice/MainContent/TotalTimeSummary';
import {useGetInvoiceTimecardLines as clientUseGetInvoiceTimecardLines} from 'app/services/clientService';
import {useGetInvoiceTimecardLines as supplierUseGetInvoiceTimecardLines} from 'app/services/supplierService';
import PropTypes from 'prop-types';
import React, {useMemo} from 'react';
import {useParams} from 'react-router-dom';

import {useGetColumns} from './columns';

const TimeCards = ({invoice}) => {
  const {id} = useParams();
  const {isSupplier} = useAccountType();
  const {data: invoiceTimecardLines} = (
    isSupplier
      ? supplierUseGetInvoiceTimecardLines
      : clientUseGetInvoiceTimecardLines
  )(id);
  const total = useMemo(
    () => ({
      msp_fee: invoice?.supplier_timecards_msp_fee,
      msp_total: invoice?.supplier_timecards_subtotal,
      amount: invoice.timecards_total,
      tax: invoice.timecards_total_tax,
      billable: isSupplier
        ? invoice.supplier_timecards_total_billable
        : invoice.client_timecards_total_billable,
    }),
    [
      invoice.client_timecards_total_billable,
      invoice?.supplier_timecards_msp_fee,
      invoice?.supplier_timecards_subtotal,
      invoice.supplier_timecards_total_billable,
      invoice.timecards_total,
      invoice.timecards_total_tax,
      isSupplier,
    ],
  );

  const endTitle = `${
    invoice.period === 'monthly' ? 'Month' : 'Week'
  } End Date`;

  const columns = useGetColumns(endTitle, invoice?.end_date);

  return invoiceTimecardLines?.items?.length ? (
    <>
      <Typography.Title level={4} className={'mb-4'}>
        Time
      </Typography.Title>
      <AppTable
        columns={columns}
        dataSource={invoiceTimecardLines.items.map((invoiceTimecardLine) => ({
          ...invoiceTimecardLine,
          invoice,
        }))}
        pagination={false}
        summary={() => (
          <TotalTimeSummary
            span={4}
            tax={total.tax}
            amount={total.amount}
            billable={total.billable}
            showMSP={isSupplier}
            msp_fee={total?.msp_fee}
            msp_total={total.msp_total}
            title='Timecard Total:'
          />
        )}
      />
    </>
  ) : (
    <></>
  );
};

TimeCards.propTypes = {
  invoice: PropTypes.object,
};

export default TimeCards;
