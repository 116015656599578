import AppCard from '@wieldy/components/AppCard';
import {Typography} from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

import {
  DIVERSITY_ENTITY,
  DIVERSITY_PRACTICES,
  SPECIFIC_GROUP,
} from '../../constants/company-diversity-statuses';

export const getDate = (dates) => {
  const start = moment(dates[0]).format('ll');
  const end = moment(dates[1]).format('ll');

  return `${start}-${end}`;
};
const DiversityInformationView = ({details}) => {
  return (
    <AppCard style={{maxWidth: 820}}>
      {!!details?.diversity_statuses?.length && (
        <div className={'mb-5'}>
          <Typography.Title level={4}>Diversity Status</Typography.Title>
          {details?.diversity_statuses?.map((el) => (
            <Typography.Paragraph key={el.key}>{`${DIVERSITY_ENTITY[el.key]} [${
              el.granting_authority
            } | ${el.certificate_number} | ${getDate(
              el.dates_of_certification,
            )}]`}</Typography.Paragraph>
          ))}
        </div>
      )}

      <div>
        <Typography.Title level={4}>
          Diversity Sourcing Practices
        </Typography.Title>
        <Typography.Paragraph>
          {DIVERSITY_PRACTICES[details?.diversity_sourcing_practices.value]}
          {!!details?.diversity_sourcing_practices.description?.length &&
            ` [${details?.diversity_sourcing_practices.description.map(
              (el) => SPECIFIC_GROUP[el],
            )}]`}
        </Typography.Paragraph>
      </div>
    </AppCard>
  );
};

DiversityInformationView.propTypes = {
  details: PropTypes.object,
};

export default DiversityInformationView;
