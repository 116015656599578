import {Descriptions} from 'antd';
import {
  GUARANTEE_PERIOD,
  GUARANTEE_PERIOD_ACTION,
  RECRUITMENT_FEE_STRUCTURE,
} from 'app/constants';
import UserPopover from 'app/shared/components/UserPopover';
import {formatBillingRateRage} from 'app/utils/helpers/DataFormatHelper';
import PropTypes from 'prop-types';
import React from 'react';

const Overview = ({placement}) => {
  return (
    <div className='mb-6'>
      <Descriptions
        colon={false}
        labelStyle={{width: '50%'}}
        className='description-root mb-4'
        column={{xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1}}>
        <Descriptions.Item label='Hiring Manager'>
          {placement.hiring_manager ? (
            <UserPopover user={placement.getUser(placement.hiring_manager)} />
          ) : (
            'N/A'
          )}
        </Descriptions.Item>
        <Descriptions.Item label='Supplier Contact'>
          <UserPopover user={placement.getUser(placement.resource_manager)} />
        </Descriptions.Item>
        <Descriptions.Item label='Account Manager'>
          <UserPopover user={placement.getUser(placement.account_manager)} />
        </Descriptions.Item>
        <Descriptions.Item label='Job Start Date'>
          {placement.start_date}
        </Descriptions.Item>
        <Descriptions.Item label='Base Salary'>
          {formatBillingRateRage(placement?.base_salary, null, null)}
        </Descriptions.Item>
        <Descriptions.Item label='Recruitment Fee Structure'>
          {placement.isFixedFee
            ? RECRUITMENT_FEE_STRUCTURE.fixed_fee
            : `${placement.recruitment_fee}% of salary`}
        </Descriptions.Item>
        <Descriptions.Item label='Recruitment Fee'>
          {placement.recruitment_fee_amount}
        </Descriptions.Item>
        <Descriptions.Item label='Guarantee Period'>
          {GUARANTEE_PERIOD[placement.guarantee_period]}
        </Descriptions.Item>
        <Descriptions.Item label='Guarantee Action'>
          {GUARANTEE_PERIOD_ACTION[placement.guarantee_period_action]}
        </Descriptions.Item>
        <Descriptions.Item label='Guarantee Period End Date'>
          {placement.guarantee_end_date}
        </Descriptions.Item>
        <Descriptions.Item label='Placement ID'>
          {placement.hid}
        </Descriptions.Item>
        <Descriptions.Item label='Updated' span={1}>
          {placement.updated_at}
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
};

Overview.propTypes = {
  placement: PropTypes.object,
};

export default Overview;
