import axios from 'app/config/axios';
import CompanyUserModal from 'app/modals/CompanyUserModal';

import {baseAPI} from '../api/baseApi';
import {supplierAPI} from '../api/supplierApi';
import useReactQuery, {
  useInfiniteQuery,
  useQueryClient,
  useReactMutation,
} from '../hooks/useReactQuery';
import {getCurrentSupplier} from './authService';

export const useGetAllSuppliers = (params = {}, statuses = []) => {
  return useReactQuery(
    ['suppliers', params],
    async () => {
      const allPromise = statuses.map((status) =>
        supplierAPI.getSuppliers({...params, relation_status: status}),
      );
      const response = await Promise.all(allPromise);
      return response.map(({data, config}) => ({
        suppliers: data?.data || [],
        total_count: data?.total_count || 0,
        status: config.params.relation_status,
      }));
    },
    {
      enabled: !!statuses.length,
    },
  );
};

/** Get suppliers */
export const useGetSuppliers = (queryParams = {}) => {
  const params = {
    ...queryParams,
  };
  return useReactQuery(
    ['suppliers', params],
    async () => {
      const {data} = await axios.get('suppliers', {params});
      return {
        suppliers: data?.data ? data?.data : [],
        total_count: data?.total_count ?? 0,
      };
    },
    {
      keepPreviousData: true,
    },
  );
};

/** Create supplier account */
export const useCreateSupplier = () => {
  const client = useQueryClient();
  return useReactMutation(async (postdata) => {
    const {data} = await axios.post('suppliers', postdata);
    await client.invalidateQueries();
    return {
      data,
      message: 'Supplier created successfully',
    };
  });
};

export const useSentSupplierInvite = () => {
  const client = useQueryClient();

  return useReactMutation(async ({account_owner, phone_number}) => {
    const {data} = await baseAPI.post('supplier_invitations', {
      ...account_owner,
      phone_number,
      role: 'owner',
    });
    await client.invalidateQueries();
    return {
      data,
      message: 'Invitation sent successfully',
    };
  });
};

export const useGetInfinitySuppliers = (queryParams = {}) => {
  return useInfiniteQuery(
    ['suppliers-infinity', queryParams],
    async ({pageParam = 0}) => {
      const {data} = await supplierAPI.getSuppliers({
        page: pageParam,
        ...queryParams,
      });
      return data;
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        const totalCount = lastPage.total_count;
        const currentPageCount = allPages.length;
        const maxPageCount = totalCount / queryParams?.limit || 10;
        return currentPageCount < maxPageCount ? currentPageCount : undefined;
      },
    },
  );
};

/** update current supplier profile */
export const useUpdateSupplier = (fetchCurrentAccount = true) => {
  return useReactMutation(async ({supplierId, payload}) => {
    const {data} = await axios.patch(`suppliers/${supplierId}`, payload);
    if (fetchCurrentAccount) {
      const {supplier} = await getCurrentSupplier();
      return supplier;
    }
    return data;
  });
};

/** Get client invited users */
export const useGetSupplierInvitations = (queryParams = {}) => {
  const params = {
    include: ['total_count'],
    ...queryParams,
  };
  return useReactQuery(['supplier_invitations', params], async () => {
    const {data} = await axios.get('supplier_invitations', {params});
    return {
      items: data?.data
        ? data?.data.map((user) => new CompanyUserModal(user))
        : [],
      totalCount: data?.total_count ?? 0,
    };
  });
};

/** Add new invitation */
export const useCreateSupplierInviteUser = () => {
  return useReactMutation(async (payload) => {
    const {data} = await axios.post('supplier_invitations', payload);
    return {
      data,
      message: 'Invitation sent successfully',
    };
  });
};

/** Get supplier users */
export const useGetSupplierUsers = (queryParams = {}) => {
  const params = {
    expand: ['user'],
    include: ['total_count'],
    ...queryParams,
  };
  return useReactQuery(['supplier_members', params], async () => {
    const {data} = await axios.get('supplier_members', {params});
    return {
      items: data?.data ? data?.data : [],
      totalCount: data?.total_count ?? 0,
    };
  });
};

/** Get single supplier user **/
export const useGetSupplierUser = (id, queryParams = {}) => {
  const params = {
    expand: ['user'],
    include: ['resource'],
    ...queryParams,
  };
  return useReactQuery(['supplier_members', id], async () => {
    const {data} = await axios.get('supplier_members/' + id, {params});
    return data;
  });
};

/** Update single supplier user **/
export const useUpdateSupplierUser = (id, userId) => {
  const client = useQueryClient();
  return useReactMutation(async (payload) => {
    const {title, first_name, last_name, role, status} = payload;
    await axios.patch('profile/' + userId, {title, first_name, last_name});
    const {data} = await axios.patch('supplier_members/' + id, {role, status});
    await client.invalidateQueries(['supplier_members', id]);
    return {
      message: 'User updated successfully',
      data,
    };
  });
};

/** Delete single supplier user **/
export const useDeleteSupplierUser = (id) => {
  return useReactMutation(async () => {
    const {data} = await axios.delete('supplier_members/' + id);
    return {
      message: 'User deleted successfully',
      data,
    };
  });
};

/** Get supplier clients */
export const useGetClients = (params = {}) => {
  return useReactQuery(
    ['supplier/clients', params],
    async () => {
      const {data} = await axios.get('clients', {params});
      return {
        items: data?.data ?? [],
        total_count: data?.total_count ?? 0,
      };
    },
    {
      keepPreviousData: true,
    },
  );
};

/** View client by supplier */
export const useGetClient = (id, params = {}) => {
  return useReactQuery(['supplier/clients', id], async () => {
    const {data} = await axios.get(`clients/${id}`, {params});
    return data;
  });
};

/** Add client bookmark via supplier */
export const useAddBookmarkToClient = () => {
  const client = useQueryClient();
  return useReactMutation(async ({id, isBookmarked}) => {
    const payload = {client: id};
    let message;
    if (isBookmarked === false) {
      await axios.post('/client_bookmarks', payload);
      message = 'Client bookmarked successfully';
    } else {
      await axios.delete('/client_bookmarks', {data: payload});
      message = 'Client bookmark removed';
    }
    await client.invalidateQueries(['supplier/clients']);
    return {message};
  });
};

/** Add multi bulk action for administration section */
export const useSupplierAdministrationBulkActions = () => {
  const client = useQueryClient();
  return useReactMutation(async ({action, payload}) => {
    switch (action) {
      case 'deleteResource': {
        await axios.delete(`/resources/${payload?.id}`);
        await client.invalidateQueries('resources');
        return {
          message: 'Resource deleted successfully',
        };
      }
      case 'deleteInvitation': {
        await axios.delete(`/supplier_invitations/${payload?.id}`);
        await client.invalidateQueries('supplier_invitations');
        return {
          message: `Invitation withdrawn successfully`,
        };
      }
      case 'resendInvitation': {
        await axios.patch(`/supplier_invitations/${payload?.id}`, {
          resent: true,
        });
        await client.invalidateQueries('supplier_invitations');
        return {
          message: `Invitation resent successfully`,
        };
      }
      case 'change_resource': {
        const {resourceId, ...restData} = payload;
        const {data} = await axios.patch('resources/' + resourceId, restData);
        await client.invalidateQueries('resources');
        return {
          message: `Resource updated successfully`,
          data,
        };
      }
    }
  });
};

/** Supplier review by client */
export const useGetClientReviews = (queryParams = {}) => {
  const params = {
    include: [
      'total_count',
      'relation_duration',
      'supplier_common_active_engagements',
      'supplier_common_total_engagements',
      'client_review',
      'relation_status',
    ],
    relation_statuses: ['active', 'former'],
    ...queryParams,
  };
  return useReactQuery(
    ['client_reviews', params],
    async () => {
      const {data} = await axios.get('clients', {params});
      return {
        items: data?.data ?? [],
        totalCount: data?.total_count ?? 0,
      };
    },
    {refetchOnMount: true},
  );
};

/** Add client review by supplier */
export const useAddClientReview = () => {
  return useReactMutation(async (payload) => {
    const {data} = axios.post('client_reviews', payload);
    return {
      message: 'Client review created successfully',
      data,
    };
  });
};

/** Update client review by supplier */
export const useUpdateClientReview = (reviewId) => {
  return useReactMutation(async ({payload}) => {
    const {data} = axios.patch('client_reviews/' + reviewId, payload);
    return {
      message: 'Client review updated successfully',
      data,
    };
  });
};

/** Delete client review by supplier */
export const useDeleteClientReview = (reviewId) => {
  return useReactMutation(async () => {
    const {data} = axios.delete('client_reviews/' + reviewId);
    return {
      message: 'Client review deleted successfully',
      data,
    };
  });
};

/** Get supplier invoices */
export const useGetInvoices = (queryParams = {}) => {
  const params = {
    expand: ['updated_by', 'engagement'],
    include: ['total_count'],
    ...queryParams,
  };
  return useReactQuery(
    ['invoices', params],
    async () => {
      const {data} = await axios.get('invoices', {params});
      return {
        items: data?.data ?? [],
        total_count: data?.total_count ?? 0,
      };
    },
    {
      keepPreviousData: true,
    },
  );
};

/** Get supplier invoice */
export const useGetInvoice = (invoiceId, userParams = {}) => {
  const params = {
    expand: ['updated_by', 'engagement', 'client', 'supplier', 'placement'],
    include: ['resource', 'hiring_manager', 'applicant'],
    ...userParams,
  };
  return useReactQuery(
    ['invoices', invoiceId],
    async () => {
      const {data} = await axios.get(`invoices/${invoiceId}`, {params});
      return data;
    },
    {
      keepPreviousData: true,
    },
  );
};

/** Get supplier invoice timecard lines */
export const useGetInvoiceTimecardLines = (invoiceId, queryParams = {}) => {
  const params = {
    invoice: invoiceId,
    expand: ['timecard'],
    ...queryParams,
  };
  return useReactQuery(
    ['invoice_timecard_lines', invoiceId],
    async () => {
      const {data} = await axios.get('invoice_timecard_lines', {params});
      return {
        items: data?.data ?? [],
      };
    },
    {
      keepPreviousData: true,
    },
  );
};

/** Get supplier invoice expense lines */
export const useGetInvoiceExpenseLines = (invoiceId, queryParams = {}) => {
  const params = {
    invoice: invoiceId,
    expand: ['expense_item'],
    ...queryParams,
  };
  return useReactQuery(
    ['invoice_expense_lines', invoiceId],
    async () => {
      const {data} = await axios.get('invoice_expense_lines', {params});
      return {
        items: data?.data ?? [],
      };
    },
    {
      keepPreviousData: true,
    },
  );
};

/** Get supplier report */
export const useGetReport = (queryParams = {}) => {
  const params = {
    ...queryParams,
  };
  return useReactQuery(
    ['/reports/supplier_report', params],
    async () => {
      const {data} = await axios.get('/reports/supplier_report', {params});
      return {
        ...data,
      };
    },
    {refetchOnMount: true},
  );
};

/** Get supplier Available Resources metric */
export const useGetAvailableResourcesReport = (queryParams = {}) => {
  const params = {
    ...queryParams,
  };
  return useReactQuery(
    ['/reports/available_resources', params],
    async () => {
      const {data} = await axios.get('/reports/available_resources', {params});
      return {
        items: data?.data ? data?.data : [],
      };
    },
    {refetchOnMount: true},
  );
};

/** Get supplier Active Clients metric */
export const useGetActiveClientsReport = (queryParams = {}) => {
  const params = {
    ...queryParams,
  };
  return useReactQuery(
    ['/reports/active_account_relationships', params],
    async () => {
      const {data} = await axios.get('/reports/active_account_relationships', {
        params,
      });
      return {
        items: data?.data ? data?.data : [],
      };
    },
    {refetchOnMount: true},
  );
};

/** Get supplier Active Engagements metric */
export const useGetActiveEngagementsReport = (queryParams = {}) => {
  const params = {
    ...queryParams,
  };
  return useReactQuery(
    ['/reports/active_engagements', params],
    async () => {
      const {data} = await axios.get('/reports/active_engagements', {
        params,
      });
      return {
        items: data?.data ? data?.data : [],
      };
    },
    {refetchOnMount: true},
  );
};

/** Get supplier invoices metric */
export const useGetTotalEarningsReport = (queryParams = {}) => {
  const params = {
    ...queryParams,
  };
  return useReactQuery(
    ['/reports/paid_invoices', params],
    async () => {
      const {data} = await axios.get('/reports/paid_invoices', {
        params,
      });
      return {
        items: data?.data ? data?.data : [],
      };
    },
    {refetchOnMount: true},
  );
};
