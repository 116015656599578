import AppSteps from '@wieldy/components/AppSteps';
import AppStep from '@wieldy/components/AppSteps/AppStep';
import AppStepTitle from '@wieldy/components/AppSteps/AppStep/AppStepTitle';
import useStep from 'app/hooks/useStep';
import React from 'react';
import {useSelector} from 'react-redux';

import CreateNote from './AccompanyingMessage/CreateNote';
import ChooseCandidates from './ChooseCandidates';
import ChooseSuppliers from './ChooseSuppliers';
import PostInquiry from './PostInquiry';
import SelectRequisition from './SelectRequisition';

const MainContent = () => {
  const {currentStep, goToStep} = useStep();
  const {isEditMode} = useSelector(({inquiry}) => inquiry);

  return (
    <AppSteps current={currentStep}>
      <AppStep
        title={
          <AppStepTitle
            title='Requisition'
            isEdit={!isEditMode && currentStep > 1}
            onEditClick={() => goToStep(1)}
          />
        }
        step={1}
        description={<SelectRequisition />}
      />
      <AppStep
        step={2}
        title={
          <AppStepTitle
            title='Suppliers'
            isEdit={!isEditMode && currentStep > 2}
            onEditClick={() => goToStep(2)}
          />
        }
        description={<ChooseSuppliers />}
      />
      <AppStep
        step={3}
        title={
          <AppStepTitle
            title='Candidates'
            isEdit={currentStep > 3}
            onEditClick={() => goToStep(3)}
          />
        }
        description={<ChooseCandidates />}
      />
      <AppStep
        step={4}
        title={
          <AppStepTitle
            title='Note to Supplier(s)'
            isEdit={currentStep > 4}
            onEditClick={() => goToStep(4)}
          />
        }
        description={<CreateNote />}
      />
      <AppStep step={5} title='Submit' description={<PostInquiry />} />
    </AppSteps>
  );
};
export default React.memo(MainContent);
