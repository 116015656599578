import {Button, Space, Typography} from 'antd';
import useStep from 'app/hooks/useStep';
import {useCreatePlacementInvoice} from 'app/services/invoiceService';
import {resetInvoicePlacementState} from 'app/store/features/invoice/invoicePlacementSlice';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';

const SubmitInvoice = () => {
  const {currentStep} = useStep();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {placement} = useSelector(({invoicePlacement}) => invoicePlacement);
  const {mutate, isLoading: isLoading} = useCreatePlacementInvoice();

  const onCancelCreate = () => {
    dispatch(resetInvoicePlacementState());
    navigate('/financials');
  };

  const onSubmit = () => {
    const payload = {
      placement: placement.id,
    };

    mutate(payload, {
      onSuccess: () => {
        dispatch(resetInvoicePlacementState());
        navigate('/financials');
      },
    });
  };

  return (
    <div>
      <Typography.Paragraph>Post the invoice</Typography.Paragraph>
      {currentStep === 2 && (
        <Space>
          <Button type='primary' onClick={onSubmit} loading={isLoading}>
            Create Invoice
          </Button>
          <Button onClick={onCancelCreate} disabled={isLoading}>
            Cancel
          </Button>
        </Space>
      )}
    </div>
  );
};

export default SubmitInvoice;
