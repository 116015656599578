import AppFormWrapper, {
  AppFormActions,
  AppFormBody,
} from '@wieldy/components/AppFormWrapper';
import AppInput from '@wieldy/components/dataEntry/AppInput';
import AppSelect from '@wieldy/components/dataEntry/AppSelect';
import {
  getInputPlaceHolder,
  getRequiredMessage,
  getSelectPlaceHolder,
} from '@wieldy/utils/helpers/MessageHelper';
import {Button, Form, Space} from 'antd';
import {useAccountType} from 'app/hooks/useAccountType';
import PhoneInput from 'app/shared/components/PhoneInput';
import {validatePhoneNumber} from 'app/utils/helpers/AppHelper';
import LocationHelper from 'app/utils/LocationHelper';
import PropTypes from 'prop-types';
import React from 'react';

const BillingAddressForm = ({
  initialValues,
  onSubmit,
  isLoading = false,
  onCancel,
}) => {
  const [isUsCountry, setIsUsCountry] = React.useState(false);
  const [disabled, setDisabled] = React.useState(true);
  const {isClient} = useAccountType();

  const handleOnSelectCountry = React.useCallback((country) => {
    setIsUsCountry(country === 'united_states');
  }, []);

  React.useEffect(() => {
    if (initialValues?.country === 'united_states') {
      setIsUsCountry(true);
    }
  }, [initialValues?.country]);

  return (
    <AppFormWrapper>
      <Form
        layout={'vertical'}
        colon={false}
        initialValues={initialValues}
        onFinish={onSubmit}
        onFieldsChange={() => setDisabled(false)}>
        <AppFormBody>
          <Form.Item
            name={'company_legal_name'}
            label={'Company Legal Name'}
            rules={[
              {
                required: true,
                message: getRequiredMessage('Company legal name'),
              },
            ]}>
            <AppInput placeholder={getInputPlaceHolder('company legal name')} />
          </Form.Item>
          <Form.Item
            name={'contact'}
            label={'Billing Contact'}
            rules={[
              {
                required: true,
                message: getRequiredMessage('Billing contact'),
              },
            ]}>
            <AppInput
              placeholder={getInputPlaceHolder('billing contact name')}
            />
          </Form.Item>
          <Form.Item
            name={'email_address'}
            label={'Billing Email Address'}
            rules={[
              {
                required: true,
                message: getRequiredMessage('Billing email'),
              },
              {
                type: 'email',
                message: 'Enter correct email address',
              },
            ]}>
            <AppInput
              placeholder={getInputPlaceHolder('billing contact email address')}
            />
          </Form.Item>
          <Form.Item
            name={'phone_number'}
            label={'Billing Phone Number'}
            rules={[
              {
                required: false,
                message: getRequiredMessage('Billing phone'),
              },
              {
                validator: validatePhoneNumber,
              },
            ]}>
            <PhoneInput />
          </Form.Item>
          {isClient && (
            <Form.Item
              name='invoicing_period'
              label='Invoicing Period'
              rules={[
                {
                  required: true,
                  message: getRequiredMessage('Invoicing Period'),
                },
              ]}>
              <AppSelect
                showSearch
                defaultValue
                placeholder={getSelectPlaceHolder('period’')}
                style={{minWidth: '280px', width: 'auto', maxWidth: '100%'}}
                options={[
                  {value: 'weekly', label: 'Weekly'},
                  {value: 'monthly', label: 'Monthly'},
                ]}
              />
            </Form.Item>
          )}
          <Form.Item
            name={'line_1'}
            label={'Address Line 1'}
            rules={[
              {
                required: true,
                message: getRequiredMessage('Address line 1'),
              },
            ]}>
            <AppInput placeholder={getInputPlaceHolder('address line 1')} />
          </Form.Item>
          <Form.Item name={'line_2'} label={'Address Line 2'}>
            <AppInput placeholder={getInputPlaceHolder('address line 2')} />
          </Form.Item>
          <Form.Item
            name={'country'}
            label={'Country'}
            rules={[
              {
                required: true,
                message: getRequiredMessage('Country'),
              },
            ]}>
            <AppSelect
              options={LocationHelper.getCountries()}
              placeholder={getSelectPlaceHolder('country')}
              onSelect={handleOnSelectCountry}
              showSearch={true}
            />
          </Form.Item>
          {isUsCountry && (
            <Form.Item
              name={'state'}
              label={'State'}
              colon={false}
              rules={[
                {
                  required: true,
                  message: getRequiredMessage('State'),
                },
              ]}>
              <AppSelect
                options={LocationHelper.getUsStates()}
                showSearch={true}
              />
            </Form.Item>
          )}
          <Form.Item
            name={'city'}
            label={'City'}
            rules={[
              {
                required: true,
                message: getRequiredMessage('City'),
              },
            ]}>
            <AppInput placeholder={getInputPlaceHolder('city')} />
          </Form.Item>
          <Form.Item
            name={'postal_code'}
            label={'Postal / Zip Code'}
            rules={[
              {
                required: true,
                message: getRequiredMessage('Postal / Zip Code'),
              },
            ]}>
            <AppInput placeholder={getInputPlaceHolder('postal / zip code')} />
          </Form.Item>
        </AppFormBody>
        <AppFormActions>
          <Space size={16}>
            <Button
              type='primary'
              htmlType='submit'
              loading={isLoading}
              disabled={disabled}>
              Save
            </Button>

            <Button type='primary' ghost onClick={onCancel}>
              Cancel
            </Button>
          </Space>
        </AppFormActions>
      </Form>
    </AppFormWrapper>
  );
};

BillingAddressForm.propTypes = {
  initialValues: PropTypes.object,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default BillingAddressForm;
