import {Descriptions, Typography} from 'antd';
import React from 'react';

const Overview = () => {
  return (
    <div className='mb-6'>
      <Descriptions
        colon={false}
        labelStyle={{width: '50%'}}
        className='description-root mb-4'
        column={{xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1}}>
        <Descriptions.Item label='Hiring Manager'>
          <Typography.Link className={'font-semibold'}>
            Doug McClure
          </Typography.Link>
        </Descriptions.Item>
        <Descriptions.Item label='Type'>Contract</Descriptions.Item>
        <Descriptions.Item label='Account Manager'>
          <Typography.Link className={'font-semibold'}>
            Claude DeBussy
          </Typography.Link>
        </Descriptions.Item>
        <Descriptions.Item label='Supplier Contact'>
          <Typography.Link className={'font-semibold'}>
            Tom Ford
          </Typography.Link>
        </Descriptions.Item>

        <Descriptions.Item label='Start Date'>Jul 12, 2021</Descriptions.Item>
        <Descriptions.Item label='End Date'>Jun 30, 2022</Descriptions.Item>
      </Descriptions>
    </div>
  );
};

export default Overview;
