import {Divider, PageHeader, Typography} from 'antd';
import PropTypes from 'prop-types';
import React, {isValidElement} from 'react';

import AppTag from '../../../../AppTag';

const {Title} = Typography;

const InnerPageHeader = ({
  title,
  subTitle,
  count,
  category,
  tabs,
  extra,
  children,
  backHeader,
  topContent,
  actions,
  ...restProps
}) => {
  return (
    <PageHeader
      className={'page-header-offset'}
      footer={
        tabs && (
          <div className='tabs-container'>
            {tabs}
            {extra && <div className={'tabs-extra ml-auto'}>{extra}</div>}
          </div>
        )
      }
      {...restProps}>
      {topContent}
      {backHeader}
      {category && (
        <Title level={3} type='secondary' className='title-secondary mb-5'>
          {category}
        </Title>
      )}
      {title && (
        <Title level={2} className={'d-flex flex-wrap align-items-center'}>
          <span className={'mr-2'}>
            {title}
            {subTitle && (
              <React.Fragment>
                {Array.isArray(subTitle) ? (
                  subTitle.map((item, index) => (
                    <React.Fragment key={index}>
                      <Divider type='vertical' style={{height: 20}} />
                      <Typography.Text type='secondary'>{item}</Typography.Text>
                    </React.Fragment>
                  ))
                ) : (
                  <React.Fragment>
                    <Divider type='vertical' style={{height: 20}} />
                    <Typography.Text type='secondary'>
                      {subTitle}
                    </Typography.Text>
                  </React.Fragment>
                )}
              </React.Fragment>
            )}{' '}
          </span>
          {count && (isValidElement(count) ? count : <AppTag>{count}</AppTag>)}
          {actions && <div className='ml-auto'>{actions}</div>}
        </Title>
      )}

      {children}
    </PageHeader>
  );
};

InnerPageHeader.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  category: PropTypes.string,
  count: PropTypes.oneOfType([PropTypes.number, PropTypes.node]),
  variant: PropTypes.string,
  tabs: PropTypes.node,
  extra: PropTypes.node,
  children: PropTypes.node,
  backHeader: PropTypes.node,
  topContent: PropTypes.node,
  actions: PropTypes.node,
};

export default React.memo(InnerPageHeader);
