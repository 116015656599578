import {Drawer} from 'antd';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import {useInfoViewContext} from '../AppInfoView/InfoViewContext';
import AppLoader from '../AppLoader';

const AppDrawer = ({
  title,
  className,
  visible,
  onClose,
  showLoader,
  children,
  ...restProps
}) => {
  const {loading} = useInfoViewContext();

  return (
    <Drawer
      title={title}
      placement='right'
      className={clsx('app-ant-drawer', className)}
      onClose={onClose}
      visible={visible}
      {...restProps}>
      {children}
      {showLoader && loading && <AppLoader position='absolute' />}
    </Drawer>
  );
};

AppDrawer.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  className: PropTypes.string,
  showLoader: PropTypes.bool,
  children: PropTypes.node,
};

export default React.memo(AppDrawer);
