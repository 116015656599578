import './index.style.less';

import clsx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const AppPageContainer = ({header, contentClass, children}) => {
  return (
    <React.Fragment>
      {header}
      <div className={clsx('site-layout-content', contentClass)}>
        {children}
      </div>
    </React.Fragment>
  );
};

AppPageContainer.propTypes = {
  header: PropTypes.node,
  children: PropTypes.node,
  contentClass: PropTypes.string,
};

export default AppPageContainer;
