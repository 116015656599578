import AppPageContainer from '@wieldy/components/pageComponents/AppPageContainer';
import AppPageHeader from '@wieldy/components/pageComponents/AppPageHeader';
import React from 'react';

import MainContent from './MainContent';

const CreateSupplier = () => {
  return (
    <AppPageContainer
      header={
        <AppPageHeader title='Add New Supplier Account' category='Accounts' />
      }>
      <MainContent />
    </AppPageContainer>
  );
};

export default React.memo(CreateSupplier);
