import {useWatch} from 'antd/es/form/Form';
import React, {useEffect, useState} from 'react';

import {isJson} from '../utils/helpers/isJson';

export const useEditor = ({key, form, isEdit}) => {
  const text = useWatch(key, form);
  const [init, setInit] = useState([]);
  useEffect(() => {
    if (text) {
      const init = text
        ? isJson(text)
          ? JSON.parse(text)
          : [{type: 'paragraph', data: {text: text}}]
        : [];
      setInit(init);
    } else {
      setInit([]);
    }
  }, [text, form, isEdit]);
  const getEditorValue = React.useCallback(
    (blocks) => {
      if (blocks?.length) {
        form.setFieldValue(key, JSON.stringify(blocks));
      } else {
        form.setFieldValue(key, undefined);
      }
    },
    [form, key],
  );
  return {init, getEditorValue};
};
