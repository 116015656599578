import AppCard from '@wieldy/components/AppCard';
import AppFormWrapper, {AppFormBody} from '@wieldy/components/AppFormWrapper';
import AppPassword from '@wieldy/components/dataEntry/AppPassword';
import {getInputPlaceHolder} from '@wieldy/utils/helpers/MessageHelper';
import {Button, Form} from 'antd';
import {useUpdatePassword} from 'app/services/userService';
import React from 'react';

const ResetPassword = () => {
  const {mutate, isLoading} = useUpdatePassword();
  const [form] = Form.useForm();
  const onFormSubmit = ({new_password}) => {
    mutate(
      {new_password},
      {
        onSuccess: () => {
          form.resetFields();
        },
      },
    );
  };

  const validatePassword = (_, value) => {
    if (value && value.length < 6) {
      return Promise.reject('Password must be at least 6 characters');
    }
    return Promise.resolve();
  };

  const validateConfirmPassword = (_, value, passwordFieldValue) => {
    if (value !== passwordFieldValue) {
      return Promise.reject('Passwords do not match');
    }
    return Promise.resolve();
  };

  return (
    <AppCard
      title='Change Password'
      headStyle={{borderBottom: 0}}
      bodyStyle={{paddingTop: 0}}>
      <AppFormWrapper>
        <Form layout='vertical' onFinish={onFormSubmit} form={form}>
          <AppFormBody>
            <Form.Item
              name='new_password'
              label='New Password'
              hasFeedback
              rules={[
                {
                  required: true,
                  message: getInputPlaceHolder('New Password'),
                },
                {validator: validatePassword},
              ]}
              required>
              <AppPassword />
            </Form.Item>

            <Form.Item
              name='confirm_password'
              label='Confirm New Password'
              dependencies={['new_password']}
              rules={[
                {
                  required: true,
                  message: getInputPlaceHolder('Confirm Password'),
                },
                ({getFieldValue}) => ({
                  validator(_, value) {
                    return validateConfirmPassword(
                      _,
                      value,
                      getFieldValue('new_password'),
                    );
                  },
                }),
              ]}
              hasFeedback
              required>
              <AppPassword />
            </Form.Item>
            <Button type='primary' htmlType='submit' loading={isLoading}>
              Reset Password
            </Button>
          </AppFormBody>
        </Form>
      </AppFormWrapper>
    </AppCard>
  );
};

export default React.memo(ResetPassword);
