import './index.style.less';

import {Badge} from 'antd';
import MESSAGE_QUERIES from 'app/constants/query-params/message-queries';
import {useAccountType} from 'app/hooks/useAccountType';
import {groupChannelsByFields} from 'app/utils/helpers/ChatHelpers';
import sourcerLogo from 'assets/images/logo.png';
import clsx from 'classnames';
import PropTypes from 'prop-types';
import React, {useMemo} from 'react';
import {useNavigate} from 'react-router-dom';
import {useChatContext} from 'stream-chat-react';

import AvatarGroup from '../AvatarGroup/AvatarGroup';

const AppChannelListItem = ({channel, activeChannel, active, toggleMobile}) => {
  const accountType = useAccountType();
  const navigate = useNavigate();

  const {
    client: {activeChannels},
  } = useChatContext();

  const loadedChannels = Object.values(activeChannels);

  const groups = groupChannelsByFields(loadedChannels, ['supplier', 'client']);

  const handleChangeChannel = () => {
    toggleMobile();
    if (active) return;

    navigate(`/messages?${MESSAGE_QUERIES.channelId}=${channel.data.id}`);
  };

  const isMarketplaceChannelForClientSupplier =
    !accountType.isMarketplace && channel.data.marketplace_channel;

  const previewText = useMemo(() => {
    if (isMarketplaceChannelForClientSupplier) {
      return (
        <p className='mb-0 channel-preview__content-name'>
          {channel.data.name}
        </p>
      );
    }

    if (accountType.isMarketplace) {
      if (channel.data.client_name && channel.data.supplier_name) {
        return (
          <>
            {channel.data.client_name && (
              <p className='mb-0 channel-preview__content-name'>
                {channel.data.client_name}
              </p>
            )}
            {channel.data.supplier_name && (
              <p className='mb-0 channel-preview__content-name'>
                {channel.data.supplier_name}
              </p>
            )}
          </>
        );
      }

      return (
        <p className='mb-0 channel-preview__content-name'>
          {channel.data.supplier_name || channel.data.client_name}
        </p>
      );
    }

    return (
      <p className='mb-0 channel-preview__content-name'>
        {accountType.isClient
          ? channel.data.supplier_name
          : channel.data.client_name}
      </p>
    );
  }, [accountType, channel, isMarketplaceChannelForClientSupplier]);

  const isActiveChannelFromGroup = useMemo(() => {
    return groups.some(
      (groupChannel) =>
        activeChannel.data.supplier === groupChannel.data.supplier &&
        activeChannel.data.client === groupChannel.data.client &&
        channel.data.supplier === groupChannel.data.supplier &&
        channel.data.client === groupChannel.data.client,
    );
  }, [groups, activeChannel, channel]);

  const images = useMemo(() => {
    const {client_logo = null, supplier_logo = null} = channel.data;
    if (
      accountType.isMarketplace &&
      channel.data.supplier &&
      channel.data.client
    ) {
      return [client_logo, supplier_logo];
    }

    if (channel.data.marketplace_channel) {
      if (accountType.isMarketplace) {
        return [client_logo || supplier_logo];
      }
      return [sourcerLogo];
    }

    if (accountType.isClient) {
      return [supplier_logo];
    }

    if (accountType.isSupplier) {
      return [client_logo];
    }
  }, [channel.data, accountType]);

  return (
    <div
      className={clsx({
        staticChannel: isMarketplaceChannelForClientSupplier,
      })}>
      <div
        className={clsx('channel-preview__container', {
          selected: isActiveChannelFromGroup,
        })}
        onClick={handleChangeChannel}>
        <Badge
          offset={[0, 40]}
          dot={!!channel.state.unreadCount}
          status='warning'>
          <AvatarGroup images={images} />
        </Badge>
        <div className='channel-preview__content-wrapper'>{previewText}</div>
      </div>
    </div>
  );
};

AppChannelListItem.propTypes = {
  channel: PropTypes.object,
  activeChannel: PropTypes.object,
  active: PropTypes.bool,
  toggleMobile: PropTypes.func,
};

export default AppChannelListItem;
