import {APPLICANT_TYPE} from '../constants';
import ResourceHelper from './ResourceHelper';

class ApplicantHelper extends ResourceHelper {
  static applicationType() {
    return Object.keys(APPLICANT_TYPE).map((key) => {
      return {
        key: key,
        value: key,
        label: APPLICANT_TYPE[key],
      };
    });
  }
}

export default ApplicantHelper;
