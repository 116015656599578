import {DATE_FORMATS} from 'app/config/app';
import moment from 'moment';
import {isValidPhoneNumber} from 'react-phone-number-input';

const ALLOW_DATE_FORMATS = Object.keys(DATE_FORMATS);

export const getEmployeeSize = (employess) => {
  let employee_size;
  if (employess <= 10) {
    employee_size = '1-10';
  } else if (employess > 10 && employess <= 50) {
    employee_size = '11-50';
  } else if (employess > 50 && employess <= 200) {
    employee_size = '51-200';
  } else if (employess > 200 && employess <= 500) {
    employee_size = '201-500';
  } else if (employess > 500 && employess <= 1000) {
    employee_size = '501-1000';
  } else if (employess > 1000 && employess <= 5000) {
    employee_size = '1001-5000';
  } else if (employess > 5000 && employess <= 10000) {
    employee_size = '5001-10000';
  } else {
    employee_size = '10,001+';
  }
  return `${employee_size} employees`;
};

export const scrollToElement = (elementId, scrollOffset) => {
  const element = document.getElementById(elementId);
  if (element) {
    const elementPosition = element.getBoundingClientRect().top;
    const offsetPosition =
      elementPosition + window.scrollY - (scrollOffset || 80);
    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    });
  }
};

export const formatDate = (timestamp, format = DATE_FORMATS.md) => {
  if (ALLOW_DATE_FORMATS.includes(format)) {
    format = DATE_FORMATS[format];
  }
  if (typeof timestamp === 'number') {
    return moment.unix(timestamp).format(format);
  }
  if (timestamp instanceof moment) {
    return timestamp.format(format);
  }
  return null;
};

export const formatRelativeTime = (timestamp) => {
  if (!timestamp) {
    return '';
  }
  const date = moment.unix(timestamp);
  const duration = moment.duration(moment().diff(moment(date)));
  const years = duration.years();
  const months = duration.months();
  const days = duration.days();
  const hours = duration.hours();
  const minutes = duration.minutes();
  const seconds = duration.seconds();

  if (years > 0 && months > 0) {
    return `${years} yrs ${months} mos`;
  }
  if (months > 0) {
    return `${months} mos`;
  }
  if (days > 0) {
    return `${days} ${days === 1 ? 'day' : 'days'}`;
  }
  if (hours > 0) {
    return `${hours} ${hours === 1 ? 'hr' : 'hrs'}`;
  }
  if (minutes > 0) {
    return `${minutes} min`;
  }
  if (seconds > 0) {
    return `${seconds} sec`;
  }
  return moment(date).fromNow();
};

export const validatePhoneNumber = (rule, value) => {
  return new Promise((resolve, reject) => {
    if (value) {
      if (isValidPhoneNumber(value)) {
        return resolve();
      } else {
        return reject('Enter valid phone number');
      }
    } else {
      return resolve();
    }
  });
};
