import {useMemo} from 'react';

import {getUserName} from '../utils/helpers/ConvertHID';
import {useAccountType} from './useAccountType';

export const useReveal = (item) => {
  const {isClient} = useAccountType();
  return useMemo(
    () => ({
      user: isClient ? item?.supplier : item?.client,
      link: isClient
        ? `/suppliers/${item?.supplier?.id}/view-supplier`
        : `/clients/${item?.client?.id}/view-client`,
      userType: isClient ? 'Supplier' : 'Client',
      userName: getUserName(
        item?.name,
        isClient ? 'SUP-' : 'CLI-',
        isClient ? 'Supplier' : 'Client',
      ),
    }),
    [isClient, item],
  );
};
