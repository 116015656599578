import {Button} from 'antd';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {RiStarLine, RiStarSFill} from 'react-icons/ri';

const Bookmark = ({loading = false, isBookmarked = false, onClick}) => {
  return (
    <Button
      type='text'
      className={clsx({bookmarked: isBookmarked}, 'btn-ribbon')}
      onClick={onClick}
      disabled={loading}
      icon={
        isBookmarked ? (
          <RiStarSFill className='text-xl' />
        ) : (
          <RiStarLine className='text-xl' />
        )
      }
    />
  );
};
Bookmark.propTypes = {
  isBookmarked: PropTypes.bool,
  onClick: PropTypes.func,
  loading: PropTypes.bool,
};
export default Bookmark;
