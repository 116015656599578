export const supplierTypes = {
  talentVendor: 'talent_vendor',
  staffingAgency: 'staffing_agency',
  recruitingAgency: 'recruiting_agency',
};

export const supplierTypesNames = {
  [supplierTypes.talentVendor]: 'Talent vendor',
  [supplierTypes.staffingAgency]: 'Staffing agency',
  [supplierTypes.recruitingAgency]: 'Recruiting agency',
};

export const supplierTypesOptions = [
  {
    key: 'talent_vendor',
    label: supplierTypesNames['talent_vendor'],
    value: supplierTypes.talentVendor,
  },
  {
    key: 'staffing_agency',
    label: supplierTypesNames['staffing_agency'],
    value: supplierTypes.staffingAgency,
  },
  {
    key: 'recruiting_agency',
    label: supplierTypesNames['recruiting_agency'],
    value: supplierTypes.recruitingAgency,
  },
];
