import {yupResolver} from '@hookform/resolvers/yup';
import AppCard from '@wieldy/components/AppCard';
import {getInputPlaceHolder} from '@wieldy/utils/helpers/MessageHelper';
import {Button, Form, Typography} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import {FormProvider, useForm} from 'react-hook-form';

import {AccountOwner} from '../../schemas/contact-info.schema';
import AppInput from '../controles/input';

const AccountManagerForm = ({onContinue, details, setState}) => {
  const methods = useForm({
    defaultValues: details,
    resolver: yupResolver(AccountOwner),
  });
  const {handleSubmit} = methods;
  const onSubmit = (formData) => {
    setState({account_owner: formData});
    if (onContinue) {
      onContinue();
    }
  };

  return (
    <AppCard>
      <FormProvider {...methods}>
        <Form layout='vertical' onFinish={handleSubmit(onSubmit)}>
          <div className='form-col'>
            <div style={{maxWidth: 280}}>
              <AppInput
                isRequired
                name='first_name'
                label='First Name'
                placeholder={getInputPlaceHolder('first name')}
              />

              <AppInput
                name='last_name'
                label='Last Name'
                isRequired
                placeholder={getInputPlaceHolder('last name')}
              />
              <AppInput
                name='title'
                label='Title'
                isRequired
                placeholder={getInputPlaceHolder('title')}
              />

              <AppInput
                isRequired
                name='email'
                label='Email Address'
                placeholder={getInputPlaceHolder('email address')}
              />
              <AppInput
                type='phone'
                name='phone_number'
                label='Phone Number'
                placeholder={getInputPlaceHolder('phone number')}
              />
            </div>
            <Typography.Paragraph className='form-hint'>
              {`An Account Owner is a person in your organization who would be
                responsible for your company's Sourcer workspace. This person
                would need to accept the Sourcer's Terms of Service agreement on
                behalf of your organization. They will have the highest level of
                permissions in the workplace and will represent your company in
                administering your company's Sourcer workspace.`}
            </Typography.Paragraph>
          </div>

          <Button type='primary' htmlType='submit'>
            Continue
          </Button>
        </Form>
      </FormProvider>
    </AppCard>
  );
};

AccountManagerForm.propTypes = {
  details: PropTypes.object,
  onContinue: PropTypes.func,
  setState: PropTypes.func,
};

export default React.memo(AccountManagerForm);
