import {Button, notification, Space} from 'antd';
import React from 'react';
import {useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';

import {resourceAPI} from '../../../../../../api/resourceApi';
import useStep from '../../../../../../hooks/useStep';
import {
  useCreateApplicant,
  useUpdateApplicant,
} from '../../../../../../services/applicantService';
import {useUploadFile} from '../../../../../../services/globalService';
import {useUser} from '../../../../../../store/features/user/userSlice';
import {formatISO} from '../../../../../../utils/helpers/DataFormatHelper';

const SubmitApplicantForm = () => {
  const {currentStep, isLoading, isUpdated} = useStep();
  const {user} = useUser();

  const {id} = useParams();
  const navigate = useNavigate();

  const {billing, summary, skills, supplemental, histories} = useSelector(
    ({resource}) => resource,
  );

  const createApplicant = useCreateApplicant();
  const updateApplicant = useUpdateApplicant(id);

  const uploadFile = useUploadFile(resourceAPI.getResourceUploadFileUrl);

  const handleCancel = () => {
    navigate(`/applicants/${id}/view-applicant`);
  };

  const handleRequest = (payload) => {
    if (isUpdated) {
      updateApplicant.mutate(payload, {
        onSuccess: () => {
          navigate(`/applicants/${id}/view-applicant`);
        },
        onError: (error) => {
          notification.error({message: error.message});
        },
      });
    } else {
      createApplicant.mutate(payload, {
        onSuccess: ({data}) => {
          navigate(`/applicants/${data.id}/view-applicant`);
        },
        onError: (error) => {
          notification.error({message: error.message});
        },
      });
    }
  };

  const handleSubmitWithFileUploading = (payload) => {
    uploadFile.mutate(payload.resume_key, {
      onSuccess: (response) => {
        payload.resume_key = response.key;

        handleRequest(payload);
      },
      onError: (error) => {
        notification.error({message: error.message});
      },
    });
  };

  const handleSubmit = () => {
    const isUSA = summary.country === 'united_states';

    const payload = {
      ...billing,
      ...summary,
      ...supplemental,
      phone_number: summary.phone_number
        ? summary.phone_number.replace(/\s/g, '')
        : null,
      employment_history: histories,
      us_state: isUSA ? summary.us_state : undefined,
      skill_tags: skills.map(
        ({tag, years_experience, expertise_level, last_used, id, deleted}) => ({
          last_used,
          deleted,
          expertise_level,
          tag: tag?.id,
          years_experience,
          id,
        }),
      ),
      available_to: formatISO(summary.available_to) ?? null,
      available_on: formatISO(summary.available_on) ?? null,
      employed_since: formatISO(summary.employed_since),
      resource_manager: user.id,
    };

    if (payload.resume_key instanceof File) {
      handleSubmitWithFileUploading(payload);
    } else {
      handleRequest(payload);
    }
  };
  return (
    <>
      {currentStep === 6 && (
        <Space>
          <Button
            type='primary'
            loading={isLoading || uploadFile.isLoading}
            onClick={handleSubmit}>
            Submit
          </Button>
          <Button onClick={handleCancel}>Cancel</Button>
        </Space>
      )}
    </>
  );
};

export default SubmitApplicantForm;
