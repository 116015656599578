import AppCard from '@wieldy/components/AppCard';
import AppGridContainer from '@wieldy/components/AppGridContainer';
import {Col} from 'antd';

import Summary from './Summary';
import JobDetails from './Summary/JobDetails';
import Resource from './Summary/Resource';

const MainContent = () => {
  return (
    <AppGridContainer>
      <Col xs={24} lg={24} xxl={24}>
        <AppCard
          title='Summary'
          headStyle={{borderBottom: 0}}
          bodyStyle={{paddingTop: 8}}>
          <Summary />
          <JobDetails />
          <Resource />
        </AppCard>
      </Col>
    </AppGridContainer>
  );
};

export default MainContent;
