import AppCard from '@wieldy/components/AppCard';
import AppLoader from '@wieldy/components/AppLoader';
import AppHeaderPagination from '@wieldy/components/AppPagination/AppHeaderPagination';
import AppTable from '@wieldy/components/AppTable';
import AppTag from '@wieldy/components/AppTag';
import {getTablePagination} from '@wieldy/utils/helpers/TableHelper';
import ResourceModal from 'app/modals/ResourceModal';
import {useGetResources} from 'app/services/resourceService';
import {getItemsData} from 'app/utils/helpers/GetItemsData';
import PropTypes from 'prop-types';
import React, {useState} from 'react';

import {getColumns} from './columns';

const Resources = ({pageQuery}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [sortBy, setSortBy] = useState('created_at');

  const {isLoading, data, isFetching} = useGetResources({
    limit: pageSize,
    page: currentPage - 1,
    ...pageQuery,
  });

  const [resources, totalCount] = getItemsData('resources', data);

  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const onShowSizeChange = (current, pageSize) => {
    setCurrentPage(current);
    setPageSize(pageSize);
  };

  if (isLoading) {
    return <AppLoader />;
  }

  if (!resources?.length) {
    return null;
  }

  return (
    <AppCard
      bordered={false}
      style={{boxShadow: 'none'}}
      bodyStyle={{padding: 0}}
      title={
        <React.Fragment>
          Available Resources <AppTag className={'ml-1'}>{totalCount}</AppTag>
        </React.Fragment>
      }
      headStyle={{borderBottom: 0, padding: 0}}
      extra={
        <AppHeaderPagination
          total={totalCount}
          current={currentPage}
          pageSize={pageSize}
          onChange={onPageChange}
          hideStartBorder
          sortByOptions={[
            {label: 'Alphabetically', value: 'alpha'},
            {label: 'Created Date', value: 'created_at'},
            {label: 'Created By', value: 'owner'},
          ]}
          sortBy={sortBy}
          onSortByChange={setSortBy}
        />
      }>
      <AppTable
        loading={isLoading || isFetching}
        columns={getColumns()}
        dataSource={resources.map((r) => new ResourceModal(r))}
        pagination={getTablePagination(
          pageSize,
          currentPage,
          totalCount,
          onShowSizeChange,
          onPageChange,
        )}
      />
    </AppCard>
  );
};

Resources.propTypes = {
  pageQuery: PropTypes.object,
};

Resources.defaultProps = {
  pageQuery: {},
};

export default Resources;
