import AppPaper from '@wieldy/components/AppCard/AppPaper';
import {getAssetsUrl} from '@wieldy/utils/helpers/UrlHelper';
import {Button, Row, Typography} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import {Link} from 'react-router-dom';

import RatingReviews from '../RatingReviews';

const RevealCardFooter = ({
  title,
  titleLink,
  subTitle,
  reviews,
  rating,
  onClick,
  style,
}) => {
  return (
    <AppPaper style={style}>
      <Row justify='space-between' align='middle'>
        <div className='flex-fill'>
          <Typography.Title level={4} type='secondary' className='sub-title'>
            {subTitle}
          </Typography.Title>
          <Typography.Title level={4} className={'mb-0 mt-1'}>
            <Link to={titleLink} component={Typography.Link}>
              {title}
            </Link>
          </Typography.Title>
          <RatingReviews reviews={reviews} rating={rating} />
        </div>
        <Button shape='circle' size='large' onClick={onClick}>
          <img
            src={getAssetsUrl('images/expand-icon.png')}
            alt='Expand'
            title='Expand'
          />
        </Button>
      </Row>
    </AppPaper>
  );
};

RevealCardFooter.propTypes = {
  title: PropTypes.string,
  titleLink: PropTypes.string,
  subTitle: PropTypes.string,
  reviews: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  rating: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClick: PropTypes.func,
  style: PropTypes.func,
};

export default RevealCardFooter;
