import {Form, Input, InputNumber} from 'antd';
import PhoneInput from 'app/shared/components/PhoneInput';
import cn from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import {Controller, useFormContext} from 'react-hook-form';

const AppFormInput = ({
  label,
  name,
  classes,
  type,
  extra,
  isRequired,
  labelPosition,
  ...props
}) => {
  const {control} = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({field, fieldState}) => (
        <Form.Item
          className={classes?.root}
          extra={extra}
          labelAlign={labelPosition}
          required={isRequired}
          label={label}
          validateStatus={fieldState.error ? 'error' : ''}
          help={fieldState.error ? fieldState.error.message : ''}>
          {type === 'number' ? (
            <InputNumber
              type={type}
              {...props}
              className={cn(classes?.input)}
              {...field}
            />
          ) : type === 'phone' ? (
            <PhoneInput {...props} className={cn(classes?.input)} {...field} />
          ) : (
            <Input {...props} className={cn(classes?.input)} {...field} />
          )}
        </Form.Item>
      )}
    />
  );
};

AppFormInput.propTypes = {
  name: PropTypes.string,
  extra: PropTypes.string,
  labelPosition: PropTypes.oneOf(['left', 'top', 'right']),
  label: PropTypes.string,
  isRequired: PropTypes.bool,
  classes: PropTypes.object,
  type: PropTypes.oneOf(['number', 'phone', 'text']),
};

export default AppFormInput;
