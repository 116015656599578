import AppFormWrapper, {
  AppFormActions,
  AppFormBody,
} from '@wieldy/components/AppFormWrapper';
import AppInput from '@wieldy/components/dataEntry/AppInput';
import AppSelect from '@wieldy/components/dataEntry/AppSelect';
import {
  getInputPlaceHolder,
  getRequiredMessage,
  getSelectPlaceHolder,
} from '@wieldy/utils/helpers/MessageHelper';
import {Button, Form, Space} from 'antd';
import LocationHelper from 'app/utils/LocationHelper';
import PropTypes from 'prop-types';
import React from 'react';

const PrimaryAddressForm = ({
  initialValues,
  onSubmit,
  isLoading = false,
  onCancel,
}) => {
  const [isUsCountry, setIsUsCountry] = React.useState(false);
  const [disabled, setDisabled] = React.useState(true);

  const handleOnSelectCountry = React.useCallback((country) => {
    setIsUsCountry(country === 'united_states');
  }, []);

  React.useEffect(() => {
    if (initialValues?.country === 'united_states') {
      setIsUsCountry(true);
    }
  }, [initialValues?.country]);

  return (
    <AppFormWrapper>
      <Form
        layout={'vertical'}
        colon={false}
        initialValues={initialValues}
        onFinish={onSubmit}
        onFieldsChange={() => setDisabled(false)}>
        <AppFormBody>
          <Form.Item
            name={'line_1'}
            label={'Address Line 1'}
            rules={[
              {
                required: true,
                message: getRequiredMessage('Address line 1'),
              },
            ]}>
            <AppInput placeholder={getInputPlaceHolder('address line 1')} />
          </Form.Item>
          <Form.Item name={'line_2'} label={'Address Line 2'}>
            <AppInput placeholder={getInputPlaceHolder('address line 2')} />
          </Form.Item>
          <Form.Item
            name={'country'}
            label={'Country'}
            rules={[
              {
                required: true,
                message: getRequiredMessage('Country'),
              },
            ]}>
            <AppSelect
              placeholder={getSelectPlaceHolder('country')}
              options={LocationHelper.getCountries()}
              onSelect={handleOnSelectCountry}
              showSearch={true}
            />
          </Form.Item>
          {isUsCountry && (
            <Form.Item
              name={'state'}
              label={'State'}
              colon={false}
              rules={[
                {
                  required: true,
                  message: getRequiredMessage('State'),
                },
              ]}>
              <AppSelect
                options={LocationHelper.getUsStates()}
                showSearch={true}
              />
            </Form.Item>
          )}
          <Form.Item
            name={'city'}
            label={'City'}
            rules={[
              {
                required: true,
                message: getRequiredMessage('City'),
              },
            ]}>
            <AppInput placeholder={getInputPlaceHolder('city')} />
          </Form.Item>
          <Form.Item
            name={'postal_code'}
            label={'Postal / Zip Code'}
            rules={[
              {
                required: true,
                message: getRequiredMessage('Postal / Zip Code'),
              },
            ]}>
            <AppInput placeholder={getInputPlaceHolder('postal / zip code')} />
          </Form.Item>
        </AppFormBody>
        <AppFormActions>
          <Space size={16}>
            <Button
              loading={isLoading}
              type='primary'
              htmlType='submit'
              disabled={disabled}>
              Save
            </Button>

            <Button type='primary' ghost onClick={onCancel}>
              Cancel
            </Button>
          </Space>
        </AppFormActions>
      </Form>
    </AppFormWrapper>
  );
};

PrimaryAddressForm.propTypes = {
  initialValues: PropTypes.object,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default PrimaryAddressForm;
