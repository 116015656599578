import '@cyntler/react-doc-viewer/dist/index.css';
import 'app/pages/common/Terms/styles/reader.less';

import propTypes from 'prop-types';
import React from 'react';

const TermsReader = ({path}) => {
  return <iframe src={path} width={'100%'} height={600} />;
};

TermsReader.propTypes = {
  path: propTypes.string,
  fileName: propTypes.string,
};
export default TermsReader;
