import {yupResolver} from '@hookform/resolvers/yup';
import ParentChildCheckbox from '@register-app/components/additional/multiple-checkbox';
import {companyServices} from '@register-app/constants/company-services';
import {companyTypesOptions} from '@register-app/constants/company-type';
import {isSubjectOptions} from '@register-app/constants/is-subject-options';
import {platformsOptions} from '@register-app/constants/platforms';
import {initialCompanyInfo} from '@register-app/initial-data/initial-company-info';
import AppCard from '@wieldy/components/AppCard';
import {
  getInputPlaceHolder,
  getSelectPlaceHolder,
} from '@wieldy/utils/helpers/MessageHelper';
import {Button, Form, Typography} from 'antd';
import {
  supplierTypes as SUPPLIER_TYPES,
  supplierTypesOptions,
} from 'app/constants/supplier-types';
import PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import {FormProvider, useForm} from 'react-hook-form';

import ClientHelper from '../../../app/utils/ClientHelper';
import {companyInfoSchema} from '../../schemas/company-info.schema';
import CompanyPlacement from '../additional/company-placement';
import Label from '../additional/label';
import AppEditor from '../controles/editor';
import AppInput from '../controles/input';
import AppRadioGroup from '../controles/radio';
import AppSelect from '../controles/select';

const CompanyInfoForm = ({onContinue, details, setState}) => {
  const methods = useForm({
    mode: 'all',
    defaultValues: details,
    resolver: yupResolver(companyInfoSchema),
  });

  const {
    handleSubmit,
    watch,
    setValue,
    formState: {errors},
  } = methods;
  const supplierTypes = watch('type');

  const isAgency =
    supplierTypes?.includes(SUPPLIER_TYPES.recruitingAgency) ||
    supplierTypes?.includes(SUPPLIER_TYPES.staffingAgency);

  useEffect(() => {
    if (!isAgency) {
      setValue(
        'company_recruiting_services',
        initialCompanyInfo.company_recruiting_services,
      );
      setValue('placement_type', initialCompanyInfo.placement_type);
      setValue('number_of_recruiters', initialCompanyInfo.number_of_recruiters);
      setValue('ats_used_by_company', initialCompanyInfo.ats_used_by_company);
    }
  }, [isAgency, setValue]);

  const companyRecruitingError = errors?.company_recruiting_services?.root;

  const handleOnSubmit = (formData) => {
    setState({company_info: formData});
    if (onContinue) onContinue();
  };

  return (
    <AppCard>
      <FormProvider {...methods}>
        <Form onFinish={handleSubmit(handleOnSubmit)} layout='vertical'>
          <AppInput
            isRequired
            name='name'
            label='Company Legal Name'
            placeholder={getInputPlaceHolder('company legal name')}
            style={{minWidth: '280px', width: '100%', maxWidth: '480px'}}
          />
          <AppInput
            name='company_dba_name'
            label='Doing Business As (DBA) Name'
            placeholder={getInputPlaceHolder('company DBA name')}
            style={{minWidth: '280px', width: '100%', maxWidth: '480px'}}
          />
          <AppSelect
            isRequired
            name='type'
            label='Supplier Type'
            placeholder={getSelectPlaceHolder('supplier types')}
            options={supplierTypesOptions}
            allowClear
            mode='multiple'
            style={{minWidth: '280px', width: 'auto', maxWidth: '100%'}}
          />
          <AppSelect
            isRequired
            name='company_type'
            label='Company Type'
            placeholder={getSelectPlaceHolder('company type')}
            options={companyTypesOptions}
            allowClear
            style={{minWidth: '280px', width: 'auto', maxWidth: '100%'}}
          />
          <AppSelect
            isRequired
            name='company_size'
            label='Company Size'
            showSearch
            placeholder={getSelectPlaceHolder('company size')}
            options={ClientHelper.getEmployeeSize()}
            style={{minWidth: '280px', width: 'auto', maxWidth: '100%'}}
          />
          <AppInput
            isRequired
            name='website'
            label='Website'
            placeholder={getInputPlaceHolder('website')}
            style={{minWidth: '280px', width: '100%', maxWidth: '480px'}}
          />
          <AppInput
            isRequired
            name='linkedin'
            label='LinkedIn Company Page'
            placeholder={getInputPlaceHolder('LinkedIn company page')}
            style={{minWidth: '280px', width: '100%', maxWidth: '480px'}}
          />
          <AppInput
            isRequired
            type='number'
            min={0}
            name='years_in_business'
            label='Years in Business'
            style={{minWidth: '280px', width: 'auto', maxWidth: '100%'}}
            placeholder={getInputPlaceHolder('years in business')}
          />
          <div className='form-col'>
            <AppRadioGroup
              isRequired
              label='Company Tax Jurisdiction'
              name='tax_jurisdiction'
              options={isSubjectOptions}
            />
            <Typography.Paragraph className='form-hint'>
              As part of our registration process we will ask you to complete
              the appropriate W9/W8 tax form. We reserve the right to ask for
              supporting tax documentation.
            </Typography.Paragraph>
          </div>

          {isAgency && (
            <>
              <div className='form-box-wrapper'>
                <Label
                  text='Company Recruiting and Staffing Services'
                  isRequired={isAgency}
                  className='mb-2'
                />
                {companyServices.map((item) => (
                  <ParentChildCheckbox
                    name='company_recruiting_services'
                    key={item.value}
                    item={item}
                  />
                ))}
                {companyRecruitingError && (
                  <span className='form-error-help'>
                    {companyRecruitingError.message}
                  </span>
                )}
              </div>

              <div className='form-col'>
                <CompanyPlacement isRequired={isAgency} />
                <Typography.Paragraph className='form-hint'>
                  Use approximate percentage ratios of placement types on an
                  annual basis. The total sum of all percentages needs to add up
                  to 100%.
                </Typography.Paragraph>
              </div>
              <AppInput
                isRequired={isAgency}
                type='number'
                min={0}
                name='number_of_recruiters'
                label='Number of Recruiters'
                placeholder={getInputPlaceHolder('number of recruiters')}
                style={{minWidth: '280px', width: 'auto', maxWidth: '100%'}}
              />
              <AppSelect
                name='ats_used_by_company'
                label='ATS Used by Company'
                showSearch
                placeholder={getSelectPlaceHolder('ATS')}
                style={{minWidth: '280px', width: 'auto', maxWidth: '100%'}}
                options={platformsOptions}
              />
            </>
          )}

          <AppEditor label='About' name='about' isRequired />
          <Button type='primary' htmlType='submit'>
            Continue
          </Button>
        </Form>
      </FormProvider>
    </AppCard>
  );
};

CompanyInfoForm.propTypes = {
  details: PropTypes.object,
  onContinue: PropTypes.func,
  setState: PropTypes.func,
};

export default React.memo(CompanyInfoForm);
