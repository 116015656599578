import AppCard from '@wieldy/components/AppCard';
import AppGridContainer from '@wieldy/components/AppGridContainer';
import {Col} from 'antd';
import usePageQuery from 'app/hooks/usePageQuery';
import {useReduceInfinityResponse} from 'app/hooks/useReduceInfinityResponse';
import resourceModal from 'app/modals/ResourceModal';
import {useGetInfinityResource} from 'app/services/resourceService';
import CreateList from 'app/shared/components/inquiry/CreateList';
import FilterResources from 'app/shared/resource/FilterResources';
import {setInquiryCandidates} from 'app/store/features/inquiry/inquirySlice';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';

import {useFirstRender} from '../../../../../../../hooks/useFirstRender';
import {moveElementToBeginning} from '../../../../../../../utils/helpers/MoveObjectInArray';
import CandidateDetails from '../CandidateDetails';

const ChooseSupplierContainer = () => {
  const [currentCandidate, setCurrentCandidate] = useState(null);

  const {
    suppliers,
    isEditMode,
    existingCandidates,
    requisition,
    statusPage,
    targetResource,
  } = useSelector(({inquiry}) => inquiry);

  const suppliersQuery = suppliers.reduce((result, value, index) => {
    result[`suppliers[${index}]`] = value.id;
    return result;
  }, {});

  const isFirstRender = useFirstRender();

  const currentFormattedCandidate = useMemo(
    () => (currentCandidate ? new resourceModal(currentCandidate) : {}),
    [currentCandidate],
  );

  const handleCurrentCandidate = useCallback((can) => {
    setCurrentCandidate(can);
  }, []);
  const {filterQuery, queryInit} = usePageQuery();

  const {data, isLoading, fetchNextPage, isFetchingNextPage, hasNextPage} =
    useGetInfinityResource({
      ...queryInit,
      include: [
        ...queryInit.include,
        'supplier_inquiries',
        'supplier_submissions',
      ],
      limit: targetResource ? null : queryInit.limit,
      expand: [...queryInit.expand, 'supplier', 'user'],
      ...filterQuery,
      ...suppliersQuery,
    });

  const {data: dataExist} = useGetInfinityResource({
    ...queryInit,
    target_requisition: requisition?.id,
    limit: null,
    page: null,
    expand: [...queryInit.expand, 'supplier', 'user'],
    ...filterQuery,
    ...suppliersQuery,
  });

  const [candidates, totalCount] = useReduceInfinityResponse(data);
  const [availableCandidates, availableTotalCount] =
    useReduceInfinityResponse(dataExist);
  const filteredExistCandidates = availableCandidates?.map((cand) => cand.id);
  const isCompareCandidatesData = availableTotalCount !== totalCount;

  const handleDisableCandidate = useCallback(
    (candidate) =>
      isCompareCandidatesData &&
      !filteredExistCandidates?.includes(candidate?.id),
    [filteredExistCandidates, isCompareCandidatesData],
  );

  const mappedCandidates = useMemo(() => {
    if (isEditMode) {
      return candidates.map((el) => ({
        ...el,
        isExist:
          existingCandidates.includes(el.id) || handleDisableCandidate(el),
        isDisable: handleDisableCandidate(el),
      }));
    }

    return candidates.map((el) => ({
      ...el,
      isExist:
        (existingCandidates.includes(el.id) && statusPage === 'edit') ||
        handleDisableCandidate(el),
      isDisable: handleDisableCandidate(el),
    }));
  }, [
    isEditMode,
    candidates,
    existingCandidates,
    handleDisableCandidate,
    statusPage,
  ]);

  useEffect(() => {
    if (mappedCandidates.length && !currentCandidate) {
      handleCurrentCandidate(mappedCandidates[0]);
    }

    if (!mappedCandidates.length) {
      handleCurrentCandidate(null);
    }

    if (targetResource && isFirstRender) {
      handleCurrentCandidate(
        mappedCandidates.find((cand) => cand.id === targetResource),
      );
    }
  }, [
    currentCandidate,
    handleCurrentCandidate,
    isFirstRender,
    mappedCandidates,
    targetResource,
  ]);

  return (
    <>
      <AppGridContainer>
        <CreateList
          isLoading={isLoading}
          title='Candidates'
          items={moveElementToBeginning(mappedCandidates, targetResource)}
          fetchNextPage={fetchNextPage}
          isFetchingNextPage={isFetchingNextPage}
          hasNextPage={
            targetResource
              ? mappedCandidates.length !== totalCount
              : hasNextPage
          }
          totalCount={totalCount}
          filter={<FilterResources />}
          actionCreator={setInquiryCandidates}
          handleCurrentItem={handleCurrentCandidate}
          currentItem={currentCandidate}
          isCalcItems
          limitError='Max 10 candidates per inquiry'
        />
        <Col xs={24} lg={16}>
          <AppCard style={{height: 'fit-content', minHeight: 300}}>
            {currentCandidate && (
              <CandidateDetails candidate={currentFormattedCandidate} />
            )}
          </AppCard>
        </Col>
      </AppGridContainer>
    </>
  );
};

export default ChooseSupplierContainer;
