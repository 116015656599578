import AppGridView from '@wieldy/components/AppGridView';
import AppLoader from '@wieldy/components/AppLoader';
import AppPageSection from '@wieldy/components/pageComponents/AppPageSection';
import {capitalizedAllWords} from '@wieldy/utils/helpers/StringHelper';
import {Empty} from 'antd';
import ResourceModal from 'app/modals/ResourceModal';
import {useGetAllResources} from 'app/services/resourceService';
import ViewAllButton from 'app/shared/buttons/ViewAllButton';
import CandidateCard from 'app/shared/cards/CandidateCard';
import React from 'react';

import {queryParams, statuses} from './constants';

const All = () => {
  const {isLoading, data: allData} = useGetAllResources(queryParams, statuses);

  if (isLoading) {
    return <AppLoader />;
  }

  if (allData?.every(({resources}) => !resources.length)) {
    return <Empty description='No Resources' />;
  }

  return (
    !!allData?.length &&
    allData.map(
      ({resources, total_count, status}) =>
        !!resources.length && (
          <AppPageSection
            key={status}
            title={`${capitalizedAllWords(status.replace('_', ' '))} Resources`}
            count={total_count}
            extra={
              total_count > 6 && (
                <ViewAllButton category={status} path='resources' />
              )
            }>
            <AppGridView
              isAllPages={true}
              data={resources.slice(0, 6)}
              renderItem={(resource) => (
                <CandidateCard
                  key={resource.id}
                  isResource
                  candidate={new ResourceModal(resource)}
                />
              )}
            />
          </AppPageSection>
        ),
    )
  );
};

export default All;
