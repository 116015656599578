import {
  ALL_USER_TYPES,
  USER_STATUS,
  USERS_PREFERRED_CONTACT_EMAIL,
  USERS_PREFERRED_CONTACT_PHONE,
} from 'app/constants';

import BaseModal from './BaseModal';

class UserModal extends BaseModal {
  constructor(props) {
    super(props);
    this.id = props?.id ?? null;
    this.firstName = props?.first_name ?? '';
    this.lastName = props?.last_name ?? '';
    this.name = this.firstName + ' ' + this.lastName;
    this.email = props?.email ?? '';
    this.title = props?.title ?? '';
    this.city = props?.city ?? '';
    this.country = props?.country ?? '';
    this.avatarUrl = props?.avatar_url ?? '';
    this.timezone = props?.timezone ?? '';
    this.alternativeEmail = props?.alternative_email ?? '';
    this.phoneNumber = this.getPhoneNumber(props?.phone_number);
    this.workPhoneNumber = this.getPhoneNumber(props?.phone_number);
    this.preferredContactEmail = props?.preferred_contact_email
      ? USERS_PREFERRED_CONTACT_EMAIL[props.preferred_contact_email]
      : 'N/A';
    this.preferredContactPhone = props?.preferred_contact_phone
      ? USERS_PREFERRED_CONTACT_PHONE[props?.preferred_contact_phone]
      : 'N/A';
    const disable_time_notification =
      props?.notifications?.disable_time_and_expense_notifications;
    const disable_email_notifications =
      props?.notifications?.disable_email_notifications;
    this.disableTimeExpenseNotifications =
      disable_time_notification === true ? 'Yes' : 'No';
    this.disableEmailNotifications =
      disable_email_notifications === true ? 'Yes' : 'No';
    this.role = props?.userRole ? ALL_USER_TYPES[props?.userRole] : null;

    this.status = props?.userStatus ? USER_STATUS[props?.userStatus] : null;
  }
}

export default UserModal;
