import {Button, Space} from 'antd';
import PropTypes from 'prop-types';
import {useNavigate} from 'react-router-dom';

const CreateAccount = ({onFinish, accountType}) => {
  const navigate = useNavigate();

  const onCancelCreate = () => {
    navigate(-1);
  };

  return (
    <Space size={'large'}>
      <Button type='primary' onClick={onFinish}>
        Create {accountType} Account
      </Button>
      <Button onClick={onCancelCreate}>Cancel</Button>
    </Space>
  );
};

CreateAccount.propTypes = {
  onFinish: PropTypes.func,
  accountType: PropTypes.string,
};

export default CreateAccount;
