import AppCard from '@wieldy/components/AppCard';
import {Empty} from 'antd';
import React from 'react';
import {useSelector} from 'react-redux';

import SupplementalTable from './Tables';
import TableWrapper from './TableWrapper';

const SupplementalInfoDetails = () => {
  const {supplemental} = useSelector(({resource}) => resource);
  const {educations, certifications, courses, languages} = supplemental;

  if (!educations.length && !courses.length && !languages.length) {
    return (
      <AppCard>
        <Empty description={'No Data'} />
      </AppCard>
    );
  }
  return (
    <AppCard style={{maxWidth: 990}}>
      <TableWrapper title='Education' isShowTable={!!educations.length}>
        <SupplementalTable data={educations} tableType='educations' />
      </TableWrapper>
      <TableWrapper title='Courses' isShowTable={!!courses.length}>
        <SupplementalTable data={courses} tableType='courses' />
      </TableWrapper>
      <TableWrapper
        title='Certifications'
        isShowTable={!!certifications.length}>
        <SupplementalTable data={certifications} tableType='certifications' />
      </TableWrapper>
      <TableWrapper title='Languages' isShowTable={!!languages.length}>
        <SupplementalTable data={languages} tableType='languages' />
      </TableWrapper>
    </AppCard>
  );
};

export default SupplementalInfoDetails;
