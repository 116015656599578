import {useMemo} from 'react';
import {useSelector} from 'react-redux';

import {USER_CREDENTIALS} from '../constants/user-creds';

export const useAccountType = () => {
  const {accountType, isMarketplace} = useSelector(({user}) => user);

  return useMemo(
    () => ({
      isSupplier: accountType === USER_CREDENTIALS.USER_TYPES.supplier,
      isClient: accountType === USER_CREDENTIALS.USER_TYPES.client,
      isMarketplace,
    }),
    [accountType, isMarketplace],
  );
};
