import AppCard from '@wieldy/components/AppCard';
import {AppFormActions, AppFormBody} from '@wieldy/components/AppFormWrapper';
import AppFormWrapper from '@wieldy/components/AppFormWrapper/AppFormWrapper';
import AppDatePicker from '@wieldy/components/dataEntry/AppDatePicker';
import {getRequiredMessage} from '@wieldy/utils/helpers/MessageHelper';
import {Button, Form, Select, Space, Typography} from 'antd';
import {timecardAPI} from 'app/api/timecardApi';
import {USER_CREDENTIALS} from 'app/constants/user-creds';
import {resetTimeCardState} from 'app/store/features/timecards/timecardsSlice';
import {disableTimeDates} from 'app/utils/helpers/DisableTimeDates';
import PropTypes from 'prop-types';
import React, {useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';

const NewBlankTimeForm = ({onHandleChangeEngagement, options}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {role} = useSelector((state) => state.user);
  const {payload, temp} = useSelector(({timecards}) => timecards);
  const {engagement} = payload;
  const {disabledDates, engagementStart, engagementEnd} = temp;
  const isResource = role === USER_CREDENTIALS.USER_ROLES.resource;

  const disabledDate = useMemo(
    () => disableTimeDates(disabledDates, engagementStart, engagementEnd),
    [disabledDates, engagementEnd, engagementStart],
  );

  const handleSubmit = async (formData) => {
    const payload = {
      ...formData,
      status: 'submitted',
      engagement: formData.engagement || engagement,
      end_date: formData.end_date.format('YYYY-MM-DD'),
      items: [],
    };
    await timecardAPI.postTimeCard(payload);
    navigate('/timecards/submitted');
    dispatch(resetTimeCardState());
  };

  const handleCancel = () => {
    navigate('/timecards');
    dispatch(resetTimeCardState());
  };

  return (
    <AppCard
      title='New Blank Timecard'
      headStyle={{border: 0}}
      bodyStyle={{paddingTop: 0}}>
      <AppFormWrapper>
        <Form layout='vertical' onFinish={handleSubmit} autoComplete='off'>
          {isResource || (
            <AppFormBody style={{maxWidth: 420}}>
              <Form.Item
                name='engagement'
                label='Engagement'
                rules={[
                  {
                    required: true,
                    message: getRequiredMessage('Engagement'),
                  },
                ]}>
                <Select
                  onChange={onHandleChangeEngagement}
                  options={options}
                  placeholder='Select Engagement'
                />
              </Form.Item>
            </AppFormBody>
          )}
          <AppFormBody style={{maxWidth: 280}}>
            <Form.Item
              name='end_date'
              label='Select Week End Date'
              rules={[
                {
                  required: true,
                  message: getRequiredMessage('Week End Date'),
                },
              ]}>
              <AppDatePicker
                style={{width: '100%'}}
                disabledDate={disabledDate}
              />
            </Form.Item>
            <Typography>Hours Worked During the Week</Typography>
            <Typography className='font-semibold'>0 hours</Typography>
          </AppFormBody>
          <AppFormActions className='mt-4'>
            <Space>
              <Button type='primary' htmlType='submit'>
                Submit Blank Timecard
              </Button>
              <Button onClick={handleCancel}>Cancel</Button>
            </Space>
          </AppFormActions>
        </Form>
      </AppFormWrapper>
    </AppCard>
  );
};

NewBlankTimeForm.propTypes = {
  onHandleChangeEngagement: PropTypes.func,
  options: PropTypes.array,
};

export default NewBlankTimeForm;
