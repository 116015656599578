import {useMemo} from 'react';

export const useStatus = (status) => {
  return useMemo(
    () => ({
      isRejected: status === 'rejected',
      isApproved: status === 'approved',
      isPending: status === 'submitted',
      isNew: status === 'new',
      isSent: status === 'sent',
      isResponded: status === 'responded',
      isWithdrawn: status === 'withdrawn',
      isClosed: status === 'closed',
      isEngaged: status === 'engaged',
      isDeclined: status === 'declined',
    }),
    [status],
  );
};
