import AppCard from '@wieldy/components/AppCard';
import AppFormWrapper, {
  AppFormActions,
  AppFormBody,
} from '@wieldy/components/AppFormWrapper';
import AppInput from '@wieldy/components/dataEntry/AppInput';
import AppSelect from '@wieldy/components/dataEntry/AppSelect';
import {
  getInputPlaceHolder,
  getRequiredMessage,
  getSelectPlaceHolder,
} from '@wieldy/utils/helpers/MessageHelper';
import {Button, Form} from 'antd';
import {COUNTRIES} from 'app/constants';
import PhoneInput from 'app/shared/components/PhoneInput';
import {validatePhoneNumber} from 'app/utils/helpers/AppHelper';
import LocationHelper from 'app/utils/LocationHelper';
import PropTypes from 'prop-types';
import React from 'react';

const BillingAddressForm = ({
  onContinue,
  details,
  setState,
  isRequiredPhone = true,
  isClient = false,
}) => {
  const [country, setCountry] = React.useState();
  const handleOnSubmit = (formData) => {
    setState({billing_address: formData});
    if (onContinue) {
      onContinue();
    }
  };

  return (
    <AppCard>
      <AppFormWrapper>
        <Form
          layout='vertical'
          autoComplete='off'
          onFinish={handleOnSubmit}
          initialValues={details}>
          <AppFormBody>
            <Form.Item
              name='company_legal_name'
              label='Company Legal Name'
              rules={[
                {
                  required: true,
                  message: getRequiredMessage('Company Legal name'),
                },
              ]}>
              <AppInput
                placeholder={getInputPlaceHolder('company legal name')}
                style={{minWidth: '280px', width: 'auto', maxWidth: '100%'}}
              />
            </Form.Item>

            <Form.Item
              name='contact'
              label='Billing Contact'
              rules={[
                {
                  required: true,
                  message: getRequiredMessage('Billing contact'),
                },
              ]}>
              <AppInput
                placeholder={getInputPlaceHolder('name')}
                style={{minWidth: '280px', width: 'auto', maxWidth: '100%'}}
              />
            </Form.Item>

            <Form.Item
              name='email_address'
              label='Billing Email Address'
              rules={[
                {
                  required: true,
                  message: getRequiredMessage('Billing email address'),
                },
                {
                  type: 'email',
                  message: 'Enter correct email address',
                },
              ]}>
              <AppInput
                placeholder={getInputPlaceHolder('email address')}
                style={{minWidth: '280px', width: 'auto', maxWidth: '100%'}}
              />
            </Form.Item>

            <Form.Item
              name='phone_number'
              label='Billing Phone Number'
              rules={[
                {
                  required: isRequiredPhone,
                  message: getRequiredMessage('Billing phone number'),
                },
                {
                  validator: validatePhoneNumber,
                },
              ]}>
              <PhoneInput
                style={{minWidth: '280px', width: '280px', maxWidth: '100%'}}
              />
            </Form.Item>

            {isClient && (
              <Form.Item
                name='invoicing_period'
                label='Invoicing Period'
                rules={[
                  {
                    required: true,
                    message: getRequiredMessage('Invoicing Period'),
                  },
                ]}>
                <AppSelect
                  showSearch
                  placeholder={getSelectPlaceHolder('period')}
                  style={{minWidth: '280px', width: 'auto', maxWidth: '100%'}}
                  options={[
                    {value: 'weekly', label: 'Weekly'},
                    {value: 'monthly', label: 'Monthly'},
                  ]}
                />
              </Form.Item>
            )}

            <Form.Item
              name='country'
              label='Country'
              rules={[
                {
                  required: true,
                  message: getRequiredMessage('Country'),
                },
              ]}>
              <AppSelect
                showSearch
                placeholder={getSelectPlaceHolder('country')}
                style={{minWidth: '280px', width: 'auto', maxWidth: '100%'}}
                options={LocationHelper.getCountries()}
                onChange={setCountry}
              />
            </Form.Item>

            <Form.Item
              name='line_1'
              label='Address Line 1'
              rules={[
                {
                  required: true,
                  message: getRequiredMessage('Address line 1'),
                },
              ]}>
              <AppInput
                placeholder={getInputPlaceHolder('address line 1')}
                style={{minWidth: '280px', width: 'auto', maxWidth: '100%'}}
              />
            </Form.Item>

            <Form.Item name='line_2' label='Address Line 2'>
              <AppInput
                placeholder={getInputPlaceHolder('address line 2')}
                style={{minWidth: '280px', width: 'auto', maxWidth: '100%'}}
              />
            </Form.Item>

            <Form.Item
              name='city'
              label='City'
              rules={[
                {
                  required: true,
                  message: getRequiredMessage('City'),
                },
              ]}>
              <AppInput
                placeholder={getInputPlaceHolder('city')}
                style={{minWidth: '280px', width: 'auto', maxWidth: '100%'}}
              />
            </Form.Item>

            <Form.Item
              name='state'
              label='State / Province'
              rules={[
                {
                  required:
                    country && COUNTRIES[country] === COUNTRIES.united_states,
                  message: getRequiredMessage('State / Province'),
                },
              ]}>
              <AppSelect
                showSearch
                placeholder={getInputPlaceHolder('state/province')}
                style={{minWidth: '280px', width: 'auto', maxWidth: '100%'}}
                options={LocationHelper.getUsStates()}
                disabled={
                  !(country && COUNTRIES[country] === COUNTRIES.united_states)
                }
              />
            </Form.Item>

            <Form.Item
              name='postal_code'
              label='Postal / Zip Code'
              rules={[
                {
                  required: true,
                  message: getRequiredMessage('Postal / Zip Code'),
                },
              ]}>
              <AppInput
                placeholder={getSelectPlaceHolder('postal/zip code')}
                style={{minWidth: '280px', width: 'auto', maxWidth: '100%'}}
              />
            </Form.Item>
          </AppFormBody>

          <AppFormActions>
            <Button type='primary' htmlType='submit'>
              Continue
            </Button>
          </AppFormActions>
        </Form>
      </AppFormWrapper>
    </AppCard>
  );
};

BillingAddressForm.propTypes = {
  details: PropTypes.object,
  onContinue: PropTypes.func,
  setState: PropTypes.func,
  isClient: PropTypes.bool,
  isRequiredPhone: PropTypes.bool,
};

export default React.memo(BillingAddressForm);
