import {
  CreateInvoice,
  default as Financials,
  ViewInvoice,
} from 'app/pages/main/financials';

import CreatePlacementInvoice from '../../pages/main/financials/CreatePlacementInvoice';
// import {CreateInvoice} from 'app/pages/client/financials';

export const supplier_FinancialRoutes = [
  {
    path: 'financials',
    element: <Financials />,
  },
  {
    path: 'financials/:id/view-invoice',
    element: <ViewInvoice />,
  },
  {
    path: 'financials/add-new-invoice',
    element: <CreateInvoice />,
  },
  {
    path: 'financials/add-placement-invoice',
    element: <CreatePlacementInvoice />,
  },
];

export const client_FinancialRoutes = [
  {
    path: 'financials',
    element: <Financials />,
  },
  {
    path: 'financials/:id/view-invoice',
    element: <ViewInvoice />,
  },
  {
    path: 'financials/add-new-invoice',
    element: <CreateInvoice />,
  },
  {
    path: 'financials/add-placement-invoice',
    element: <CreatePlacementInvoice />,
  },
];

export const manager_FinancialRoutes = [
  {
    path: 'financials',
    element: <Financials />,
  },
  {
    path: 'financials/:id/view-invoice',
    element: <ViewInvoice />,
  },
  {
    path: 'financials/add-new-invoice',
    element: <CreateInvoice />,
  },
  {
    path: 'financials/add-placement-invoice',
    element: <CreatePlacementInvoice />,
  },
];
