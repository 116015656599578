import {Descriptions, Typography} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

const BasicInformation = ({user}) => {
  return (
    <div className='mb-6'>
      <Typography.Title level={4} className={'mb-6'}>
        Basic Information
      </Typography.Title>
      <Descriptions
        colon={false}
        labelStyle={{width: '50%'}}
        className='description-root mb-4'
        column={{xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1}}>
        <Descriptions.Item label='First Name'>
          {user?.firstName}
        </Descriptions.Item>
        <Descriptions.Item label='Last Name'>
          {user?.lastName}
        </Descriptions.Item>
        <Descriptions.Item label='Title'>{user?.title}</Descriptions.Item>
        <Descriptions.Item label='User Type'>{user?.role}</Descriptions.Item>
        <Descriptions.Item label='User Status' span={1}>
          {user?.status}
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
};

BasicInformation.propTypes = {
  user: PropTypes.object,
};

export default BasicInformation;
