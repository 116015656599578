import AppGridView from '@wieldy/components/AppGridView';
import AppLoader from '@wieldy/components/AppLoader';
import AppPageSection from '@wieldy/components/pageComponents/AppPageSection';
import {Empty} from 'antd';
import {PAGINATION} from 'app/config/app';
import ClientModal from 'app/modals/ClientModal';
import {useGetAllClients} from 'app/services/clientService';
import ViewAllButton from 'app/shared/buttons/ViewAllButton';
import {ClientCard} from 'app/shared/client';
import {capitalizeFirstLetter} from 'app/utils/helpers/Capitalize';
import React from 'react';

import {clientQueryParams, statuses} from '../constants';

const All = () => {
  const {data: allData, isLoading} = useGetAllClients(
    {...clientQueryParams, limit: PAGINATION.ALL_ITEMS_LIMIT},
    statuses,
  );

  if (isLoading) {
    return <AppLoader />;
  }

  if (allData?.every(({clients}) => !clients.length)) {
    return <Empty description='No Clients' />;
  }

  return (
    !!allData?.length &&
    allData.map(
      ({clients, total_count, status}) =>
        !!clients.length && (
          <AppPageSection
            key={status}
            title={`${capitalizeFirstLetter(status)} Clients`}
            count={total_count}
            extra={
              total_count > 6 && (
                <ViewAllButton category={status} path='clients' />
              )
            }>
            <AppGridView
              isAllPages={true}
              data={clients.slice(0, 6)}
              renderItem={(client) => (
                <ClientCard
                  key={client.id}
                  client={new ClientModal(client)}
                  showBookmark={true}
                />
              )}
            />
          </AppPageSection>
        ),
    )
  );
};

export default All;
