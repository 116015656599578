import AppBorderBox from '@wieldy/components/AppBorderBox';
import AppCard from '@wieldy/components/AppCard';
import AppLoader from '@wieldy/components/AppLoader';
import AppSelect from '@wieldy/components/dataEntry/AppSelect';
import {getSelectPlaceHolder} from '@wieldy/utils/helpers/MessageHelper';
import {Button, Form, Select, Typography} from 'antd';
import useStep from 'app/hooks/useStep';
import {useGetTimeCards2} from 'app/services/timecardsService';
import {setInvoiceTimecards} from 'app/store/features/invoice/invoiceSlice';
import {getItemsData} from 'app/utils/helpers/GetItemsData';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';

const SelectTimecards = () => {
  const dispatch = useDispatch();
  const {currentStep, nextStep} = useStep();
  const {engagement, timecards, endDate} = useSelector(({invoice}) => invoice);

  const {isLoading, data} = useGetTimeCards2(
    {
      limit: 100,
      engagement: engagement.id,
      status: 'approved',
      exclude_invoiced: true,
      ...endDate,
      sort: ['+end_date'],
    },
    {
      enabled: currentStep === 3,
    },
  );
  const [timecardsList] = getItemsData('items', data);

  const onFormSubmit = (formData) => {
    dispatch(setInvoiceTimecards(formData));

    if (nextStep) nextStep();
  };

  if (isLoading) {
    return <AppLoader />;
  }

  if (currentStep < 3) {
    return null;
  }

  if (currentStep > 3) {
    return (
      <AppBorderBox
        className='d-inline-flex flex-column px-6 mt-3'
        style={{backgroundColor: '#FFF'}}>
        <Typography.Title level={4} className={'mb-0'}>
          {timecards?.timecards?.map((tm) => {
            const selectedTm = timecardsList.find((tmL) => tmL.id === tm);
            return (
              selectedTm && (
                <Typography.Title
                  level={4}
                  className={'mb-0'}
                  key={selectedTm.hid}>
                  {selectedTm.hid} :: {selectedTm.end_date}
                </Typography.Title>
              )
            );
          })}
        </Typography.Title>
      </AppBorderBox>
    );
  }

  return (
    <AppCard
      title='Select Timecards'
      headStyle={{border: 0}}
      bodyStyle={{paddingTop: 0}}>
      <Form layout='vertical' autoComplete='off' onFinish={onFormSubmit}>
        <Form.Item name='timecards' label='Timecard(s)'>
          <AppSelect
            showSearch
            mode={'multiple'}
            placeholder={getSelectPlaceHolder('Timecard(s)')}
            style={{width: '350px'}}>
            {timecardsList?.map((item) => (
              <Select.Option key={item.id} value={item.id}>
                {item.hid} :: {item.end_date}
              </Select.Option>
            ))}
          </AppSelect>
        </Form.Item>
        <Button type='primary' htmlType='submit'>
          Continue
        </Button>
      </Form>
    </AppCard>
  );
};

export default React.memo(SelectTimecards);
