import moment from 'moment';

export const disableTimeDates = (disabledDates, start, end) => {
  return (current) =>
    (current && current.day() !== 0) ||
    disabledDates.includes(current.format('YYYY-MM-DD')) ||
    current < moment(start).startOf('week') ||
    current > moment(end).endOf('week') ||
    current > moment().endOf('week');
};
