const supplierIncludeDetails = [
  'supplier_active_clients',
  'supplier_completed_engagements',
  'supplier_available_resources',
  'relation_status',
  'supplier_inquiries',
  'supplier_submissions',
  'supplier_engagements',
];
const clientIncludeDetails = [
  'relation_status',
  'client_active_suppliers',
  'client_engaged_resources',
  'client_open_requisitions',
  'client_inquiries',
  'client_submissions',
  'client_engagements',
];

export default {supplierIncludeDetails, clientIncludeDetails};
