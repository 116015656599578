import AppCard from '@wieldy/components/AppCard';
import PropTypes from 'prop-types';
import React from 'react';

import BasicInformation from './BasicInformation';
import ContactInformation from './ContactInformation';
import NotificationSettings from './NotificationSettings';

const UserProfile = ({user, children}) => {
  return (
    <AppCard
      title='User Information'
      headStyle={{borderBottom: 0}}
      bodyStyle={{paddingTop: 8}}>
      <BasicInformation user={user} />
      <ContactInformation user={user} />
      <NotificationSettings user={user} />
      {children}
    </AppCard>
  );
};

UserProfile.propTypes = {
  user: PropTypes.object,
  children: PropTypes.node,
};

export default UserProfile;
