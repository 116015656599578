import {Typography} from 'antd';
import {useRole} from 'app/hooks/useRole';
import {useStatus} from 'app/hooks/useStatus';
import {
  formatAmount,
  formatDate,
  formatPayUnits,
  formatSecondsToDate,
  getFullName,
} from 'app/utils/helpers/DataFormatHelper';
import React from 'react';
import {useNavigate} from 'react-router-dom';

import {DATE_FORMATS} from '../../../../../config/app';

const getSimilarColumn = (title, dataIndex, key, by, at) => ({
  title,
  dataIndex,
  key,
  render: (_, obj) => {
    const data_by = obj[by];
    const data_at = obj[at];
    return (
      <Typography.Paragraph>
        <Typography.Paragraph className={'mb-0'}>
          {getFullName(data_by)}
        </Typography.Paragraph>
        <Typography.Text type={'secondary'} className={'text-xs'}>
          {data_at ? formatSecondsToDate(data_at, DATE_FORMATS.md) : ''}
        </Typography.Text>
      </Typography.Paragraph>
    );
  },
});

export const useGetColumns = (status, isEmpty) => {
  const navigate = useNavigate();
  const {isApproved, isRejected, isPending} = useStatus(status);
  const {isResource} = useRole();

  const handleIDClick = (entity, id) => {
    return () => {
      if (entity === 'timecards') {
        navigate(`/${entity}/${id}/view-timecard`);
      }
      if (entity === 'engagements') {
        navigate(`/${entity}/${id}/view-engagement`);
      }
      if (entity === 'resources') {
        navigate(`/${entity}/${id}/view-resource`);
      }
    };
  };

  const defaultArray = [
    {
      title: 'Timecard ID',
      dataIndex: 'id',
      key: 'id',
      render: (id, {hid}) => (
        <Typography.Link onClick={handleIDClick('timecards', id)}>
          {hid}
        </Typography.Link>
      ),
    },
    {
      title: 'Engagement ID',
      dataIndex: 'engagement',
      key: 'engagement',
      render: (_, {engagement}) =>
        isResource ? (
          <span>{engagement.hid}</span>
        ) : (
          <Typography.Link
            onClick={handleIDClick('engagements', engagement.id)}>
            {engagement?.hid}
          </Typography.Link>
        ),
    },
    {
      title: 'Resource Name',
      dataIndex: 'resource',
      key: 'resource',
      render: (_, {resource}) => (
        <>
          {isResource ? (
            getFullName(resource)
          ) : (
            <Typography.Link onClick={handleIDClick('resources', resource.id)}>
              {getFullName(resource)}
            </Typography.Link>
          )}
        </>
      ),
      sorter: !isEmpty,
      showSorterTooltip: false,
    },
    {
      title: 'Week End Date',
      dataIndex: 'end_date',
      key: 'end_date',
      render: (date) => formatDate(date, DATE_FORMATS.md),
      sorter: !isEmpty,
      defaultSortOrder: 'descend',
      showSorterTooltip: false,
    },
    {
      title: 'Pay Units',
      dataIndex: 'pay_units',
      key: 'pay_units',
      render: (payUnits) => formatPayUnits(payUnits),
    },
    {
      title: 'Net Total',
      dataIndex: 'net_total',
      key: 'net_total',
      render: (netTotal) => formatAmount(netTotal),
    },
  ];

  const submitted = [
    getSimilarColumn(
      'Submitted',
      'submitted',
      'submitted',
      'submitted_by',
      'submitted_at',
    ),
  ];

  const approved = [
    getSimilarColumn(
      'Approved',
      'approved',
      'approved',
      'approved_by',
      'approved_at',
    ),
    {
      title: 'Invoiced',
      dataIndex: 'invoice',
      key: 'invoiced',
      render: (_, {invoice, invoice_author}) => (
        <Typography.Paragraph>
          <Typography.Paragraph className={'mb-0'}>
            {getFullName(invoice_author)}
          </Typography.Paragraph>
          <Typography.Text type={'secondary'} className={'text-xs'}>
            {invoice
              ? formatSecondsToDate(invoice.created_at, 'MMM D, YYYY')
              : ''}
          </Typography.Text>
        </Typography.Paragraph>
      ),
    },
  ];

  const rejected = [
    getSimilarColumn(
      'Rejected',
      'rejected',
      'rejected',
      'rejected_by',
      'rejected_at',
    ),
  ];

  const finalArray = isApproved
    ? defaultArray.concat(submitted, approved)
    : isRejected
    ? defaultArray.concat(submitted, rejected)
    : isPending
    ? defaultArray.concat(submitted)
    : defaultArray;

  const resourceArray = finalArray.filter((el) => el.key !== 'net_total');

  return isResource ? resourceArray : finalArray;
};
