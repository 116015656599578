import AppCard from '@wieldy/components/AppCard';
import AppTable from '@wieldy/components/AppTable';
import AppAmountView from '@wieldy/components/helperComponents/AppAmountView';
import PropTypes from 'prop-types';

const columns = [
  {
    title: 'From Amount',
    key: 'start',
    dataIndex: 'start',
    render: (amount) =>
      amount !== undefined && <AppAmountView amount={amount} operator='USD' />,
  },
  {
    title: 'To Amount',
    key: 'end',
    dataIndex: 'end',
    render: (amount) =>
      amount && amount === -1 ? (
        'Infinity'
      ) : (
        <AppAmountView amount={amount} operator='USD' />
      ),
  },
  {
    title: 'MSP Fees',
    key: 'percentage',
    dataIndex: 'percentage',
    render: (amount) => amount && <span>{amount}%</span>,
  },
];

const MarketPlaceView = ({details}) => {
  return (
    <AppCard style={{width: '810px'}}>
      <AppTable columns={columns} dataSource={details} pagination={false} />
    </AppCard>
  );
};

MarketPlaceView.propTypes = {
  details: PropTypes.array,
};

export default MarketPlaceView;
