import {useCallback, useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';

import {useGetEngagementList} from '../services/engagementsService';
import {
  setDisabledExpensesDates,
  setEngagementExpenses,
  setEngagementStartExpenses,
  setTitlesExpenses,
} from '../store/features/expenses/expensesSlice';
import {
  setDisabledDates,
  setEngagement as setEngagementGlobal,
  setTitles,
} from '../store/features/timecards/timecardsSlice';
import {getFullName} from '../utils/helpers/DataFormatHelper';
import {useRole} from './useRole';

export const useEngagement = () => {
  const {pathname} = useLocation();
  const isExpensesPage = pathname.startsWith('/expenses');
  const select = isExpensesPage ? 'expenses' : 'timecards';
  const {currentAccount, userRole} = useSelector((state) => state.user);
  const {engagement} = useSelector((state) => state[select].payload);
  const resource_include = isExpensesPage
    ? 'resource_expenses'
    : 'resource_timecards';

  const {isResource} = useRole();
  const dispatch = useDispatch();

  const payload = isResource
    ? {resource: currentAccount?.resource?.id}
    : {supplier: currentAccount?.supplier?.id};

  const {data, isLoading} = useGetEngagementList(payload, resource_include, {
    enabled: !!userRole,
  });

  const onHandleChangeEngagement = useCallback(
    (value) => {
      const element = data.find((el) => el.id === value);
      if (isExpensesPage) {
        dispatch(setEngagementExpenses(value));
        dispatch(setEngagementStartExpenses(element || null));
      }
      dispatch(setEngagementGlobal(element));
    },
    [data, dispatch, isExpensesPage],
  );

  useEffect(() => {
    if (isResource && !isLoading && data && data.length) {
      const activeEngagement = data[0];
      if (activeEngagement) {
        onHandleChangeEngagement(activeEngagement.id);
      }
    }
  }, [isLoading, isResource, data, onHandleChangeEngagement]);

  const options =
    data && data.length
      ? data.map((el) => ({
          value: el.id,
          label: isResource
            ? `${el.hid} :: ${el.requisition?.job_title}`
            : `${el.hid} :: ${el.requisition?.job_title} :: ${getFullName(
                el.resource,
              )}`,
        }))
      : [];

  const client = useMemo(() => {
    if (data && data.length && engagement) {
      const current = data.find((el) => el.id === engagement);
      return current ? current.client?.name : 'Client';
    }
    return '';
  }, [engagement, data]);

  const resourceName = useMemo(() => {
    if (data && data.length && engagement) {
      const option = data.find((el) => el.id === engagement);

      return option
        ? isResource
          ? `${option.hid} :: ${option.requisition.job_title}`
          : `${option.hid} :: ${
              option.requisition.job_title || ''
            } :: ${getFullName(option.resource)}`
        : '';
    } else {
      return '';
    }
  }, [data, engagement, isResource]);

  const handleTitles = useCallback(
    (fn) => {
      if (resourceName) {
        dispatch(fn({resource: resourceName, client: client}));
      } else {
        dispatch(fn({resource: 'Resource', client: 'Client'}));
      }
    },
    [resourceName, dispatch, client],
  );

  useEffect(() => {
    if (isExpensesPage) {
      handleTitles(setTitlesExpenses);
    } else {
      handleTitles(setTitles);
    }
  }, [handleTitles, isExpensesPage]);

  const disableDates = useMemo(() => {
    if (data && data.length && engagement) {
      const option = data.find((el) => el.id === engagement);
      const allDates =
        option?.[resource_include]?.map(({end_date}) => end_date) || [];
      return [...new Set(allDates)];
    } else {
      return [];
    }
  }, [engagement, data, resource_include]);

  useEffect(() => {
    if (isExpensesPage) {
      dispatch(setDisabledExpensesDates(disableDates));
    } else {
      dispatch(setDisabledDates(disableDates));
    }
  }, [disableDates, dispatch, isExpensesPage]);

  return {
    data,
    options,
    isLoading,
    resourceName,
    onHandleChangeEngagement,
  };
};
