import {Input} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

const AppPassword = (props) => {
  return <Input.Password {...props} />;
};

AppPassword.propTypes = {
  onChange: PropTypes.func,
  visibilityToggle: PropTypes.bool,
  placeholder: PropTypes.string,
};

export default AppPassword;
