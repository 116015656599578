import {Rate, Typography} from 'antd';
import React from 'react';

export const getColumns = () => {
  return [
    {
      title: 'Skill',
      dataIndex: 'skill',
      key: 'skill',
      render: (skill) => <Typography.Text>{skill}</Typography.Text>,
    },
    {
      title: 'Level of Expertise',
      dataIndex: 'expertise_level',
      key: 'expertise_level',
      render: (rating) => (
        <Rate
          className='text-base'
          allowHalf
          disabled
          value={rating}
          count={5}
        />
      ),
    },
    {
      title: 'Years of Experience',
      dataIndex: 'years_experience',
      key: 'years_experience',
      align: 'center',
    },
    {
      title: 'Last Used',
      dataIndex: 'last_used',
      key: 'last_used',
      align: 'center',
    },
  ];
};
