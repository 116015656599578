import AppDynamicTabs from '@wieldy/components/AppTabs/AppDynamicTabs';
import AppPageContainer from '@wieldy/components/pageComponents/AppPageContainer';
import AppPageHeader from '@wieldy/components/pageComponents/AppPageHeader';
import {Button} from 'antd';
import {CATEGORIES} from 'app/constants';
import {useAccountType} from 'app/hooks/useAccountType';
import {useRole} from 'app/hooks/useRole';
import PageQueryProviderNew from 'app/providers/PageQueryProvider/PageQueryProviderNew';
import {resetPageState} from 'app/store/features/common/commonSlice';
import React, {useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';

import {categories, getPageParams, tabsData} from './data';
import All from './MainContent/All';
import Browse from './MainContent/Browse';

const Engagements = () => {
  const {category} = useParams();
  const dispatch = useDispatch();
  const {isSupplier, isClient, isMarketplace} = useAccountType();
  const navigate = useNavigate();

  const {isMarketManager, isAdmin} = useRole();
  const isShowCreateButton = isMarketplace && (isMarketManager || isAdmin);

  useEffect(() => {
    if (isMarketplace && !isSupplier && !isClient) {
      navigate('/');
    }
  }, [isClient, isMarketplace, isSupplier, navigate]);

  const handleCreate = () => {
    navigate('/engagements/add-new-engagement');
  };

  const activeKey = React.useMemo(() => {
    return !categories.includes(category) ? categories[0] : category;
  }, [category]);

  const pageParams = useMemo(() => getPageParams(isSupplier), [isSupplier]);
  const currentPageParams = pageParams[category];
  const {title} = currentPageParams;

  const isAll = useMemo(() => category === CATEGORIES.ALL, [category]);

  const userState = useSelector(({common}) => common.engagements);

  const {name, ...rest} = userState || {name: null};
  const {requisition} = rest;
  const pageTitle = `${title}${
    name ? (requisition ? ' for ' : ' with ') + name : ''
  }`;

  useEffect(() => {
    return () => {
      dispatch(resetPageState('engagements'));
    };
  }, [dispatch]);

  return (
    <PageQueryProviderNew
      category={category}
      pageParams={pageParams}
      filterConfig={{}}>
      <AppPageContainer
        header={
          <AppPageHeader
            title={pageTitle}
            category='Engagements'
            tabs={
              <AppDynamicTabs
                className='tabs-seconadary'
                tabsData={tabsData}
                activeKey={activeKey}
              />
            }
            extra={
              isShowCreateButton &&
              isClient && (
                <Button type='primary' shape='round' onClick={handleCreate}>
                  Create New Engagement
                </Button>
              )
            }
          />
        }>
        {isAll ? <All userQuery={rest} /> : <Browse userQuery={rest} />}
      </AppPageContainer>
    </PageQueryProviderNew>
  );
};

export default Engagements;
