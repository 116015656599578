import {Typography} from 'antd';
import {DATE_FORMATS} from 'app/config/app';
import {
  formatMinutes,
  formatSecondsToDate,
  getFullName,
  getYesOrNo,
} from 'app/utils/helpers/DataFormatHelper';

export const getColumns = () => {
  return [
    {
      title: 'Task Id',
      dataIndex: 'hid',
      key: 'hid',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      align: 'left',
    },
    {
      title: 'Rate Id',
      dataIndex: 'engagement_rate',
      key: 'rateId',
      render: (engagement_rate) => (
        <Typography.Text>{engagement_rate.hid}</Typography.Text>
      ),
    },
    {
      title: 'Total Hours',
      dataIndex: 'minutes_worked',
      key: 'totalHours',
      render: (mins) => formatMinutes(mins),
    },
    {
      title: 'Billable',
      dataIndex: 'billable',
      key: 'billable',
      render: (bool) => getYesOrNo(bool),
    },
    {
      title: 'Active',
      dataIndex: 'active',
      key: 'active',
      render: (bool) => getYesOrNo(bool),
    },
    {
      title: 'Updated',
      dataIndex: 'updated',
      key: 'updated',
      render: (_, {updated_at, updated_by, created_at, created_by}) => {
        return (
          <Typography.Paragraph>
            <Typography.Paragraph className={'mb-0'}>
              {getFullName(updated_by || created_by)}
            </Typography.Paragraph>
            <Typography.Text type={'secondary'} className={'text-xs'}>
              {formatSecondsToDate(updated_at || created_at, DATE_FORMATS.md)}
            </Typography.Text>
          </Typography.Paragraph>
        );
      },
    },
  ];
};
