import {Button} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import {MdArrowRightAlt} from 'react-icons/md';
import {useNavigate} from 'react-router-dom';

const ViewAllButton = ({category, path}) => {
  const navigate = useNavigate();

  const onViewAllClick = () => {
    if (category) {
      navigate(`/${path}/${category}`);
    }
  };

  return (
    <Button
      type='primary'
      shape='round'
      size={'small'}
      onClick={onViewAllClick}
      ghost>
      View All <MdArrowRightAlt fontSize={20} className='ml-2' />
    </Button>
  );
};

ViewAllButton.propTypes = {
  category: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
};

export default ViewAllButton;
