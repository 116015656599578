import {Typography} from 'antd';
import {DATE_FORMATS} from 'app/config/app';
import {COUNTRIES} from 'app/constants';
import {capitalizeFirstLetter} from 'app/utils/helpers/Capitalize.js';
import {
  daysDifference,
  formatDate,
  getFullName,
} from 'app/utils/helpers/DataFormatHelper.js';
import React from 'react';
import {Link} from 'react-router-dom';

export const getColumns = () => {
  return [
    {
      title: 'Engagement',
      dataIndex: 'id',
      key: 'id',
      render: (_, {id, hid, requisition}) => (
        <Typography.Paragraph>
          <Typography.Paragraph className={'mb-0'}>
            <Link
              to={`/engagements/${id}/view-engagement`}
              component={Typography.Link}>
              {requisition.job_title}
            </Link>
          </Typography.Paragraph>
          <Typography.Text type={'secondary'} className={'text-xs'}>
            {hid}
          </Typography.Text>
        </Typography.Paragraph>
      ),
    },
    {
      title: 'Engagement Status',
      dataIndex: 'status',
      key: 'status',
      render: (value) => (
        <Typography.Text>{capitalizeFirstLetter(value)}</Typography.Text>
      ),
    },
    {
      title: 'Resource Name / Job Location',
      dataIndex: 'resource',
      key: 'resource',
      render: (_, {resource, job_worksite_location}) => (
        <Typography.Paragraph>
          <Typography.Paragraph className={'mb-0'}>
            <Link
              to={`/resources/${resource?.id}/view-resource`}
              component={Typography.Link}>
              {getFullName(resource)}
            </Link>
          </Typography.Paragraph>
          <Typography.Text type={'secondary'} className={'text-xs'}>
            {COUNTRIES[job_worksite_location]}
          </Typography.Text>
        </Typography.Paragraph>
      ),
    },
    {
      title: 'Start Date',
      dataIndex: 'start_date',
      key: 'start_date',
      sorter: true,
      defaultSortOrder: 'ascend',
      showSorterTooltip: false,
      render: (value) => (
        <Typography.Text>{formatDate(value, DATE_FORMATS.md)}</Typography.Text>
      ),
    },
    {
      title: 'End Date',
      dataIndex: 'end_date',
      key: 'end_date',
      sorter: true,
      showSorterTooltip: false,
      render: (value) => (
        <Typography.Text>{formatDate(value, DATE_FORMATS.md)}</Typography.Text>
      ),
    },
    {
      title: 'Duration',
      dataIndex: 'start_date',
      key: 'start_date2',
      render: (start_date, {end_date}) => {
        return (
          <Typography.Text>{`${daysDifference(
            start_date,
            end_date,
          )} days`}</Typography.Text>
        );
      },
    },
  ];
};
