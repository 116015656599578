import {notificationAPI} from 'app/api/notificationApi';
import useReactQuery, {
  useInfiniteQuery,
  useQueryClient,
  useReactMutation,
} from 'app/hooks/useReactQuery';

export const useGetNotification = (query = {}) => {
  return useReactQuery(
    ['notifications'],
    async () => {
      const {data} = await notificationAPI.getList(query);
      return data?.data ?? [];
    },
    {
      refetchInterval: 60000,
      refetchIntervalInBackground: true,
    },
  );
};

export const useReadNotification = () => {
  const client = useQueryClient();
  return useReactMutation(async (notifications) => {
    await notificationAPI.setRead(notifications);
    await client.invalidateQueries(['notifications']);
  });
};

export const useGetInfinityNotifications = (queryParams = {}) => {
  return useInfiniteQuery(
    ['notifications', queryParams],
    async ({pageParam = 0}) => {
      const {data} = await notificationAPI.getList({
        page: pageParam,
        ...queryParams,
      });
      return data;
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        const totalCount = lastPage.total_count;
        const currentPageCount = allPages.length;
        const maxPageCount = totalCount / (queryParams?.limit || 10);
        return currentPageCount < maxPageCount ? currentPageCount : undefined;
      },
    },
  );
};
