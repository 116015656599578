import AppCard from '@wieldy/components/AppCard';
import AppGridContainer from '@wieldy/components/AppGridContainer';
import AppTable from '@wieldy/components/AppTable';
import {Button, Col, Form, Modal, Space} from 'antd';
import {timecardAPI} from 'app/api/timecardApi';
import {useRemove} from 'app/hooks/useRemove';
import {useResetState} from 'app/hooks/useResetState';
import {useToggle} from 'app/hooks/useToggle';
import {resetLogForm} from 'app/store/features/timecards/timecardsSlice';
import PropTypes from 'prop-types';
import React, {useCallback} from 'react';
import {BiPlusCircle} from 'react-icons/bi';
import {RiCloseLine} from 'react-icons/ri';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';

import TimeCardsConfirmModal from '../../../MainContent/TimeCardsConfirmModal';
import LogTimeForm from '../LogTimeCard/LogTimeForm';
import {useGetColumns} from './columns';
import {getSummary} from './summary';

const TimeCardTable = () => {
  let {id} = useParams();
  const dispatch = useDispatch();

  const [logTimeVisible, setLogTimeVisible] = useToggle(false);
  const [prompt, handlePrompt] = useToggle(false);

  const {payload, temp} = useSelector(({timecards}) => timecards);

  const {isEditMode} = temp;

  const [modalComponent, handleDelete] = useRemove(
    id,
    timecardAPI.deleteTimeCard,
  );

  const onReset = useResetState();

  const [form] = Form.useForm();

  const handleCancelPopup = () => {
    setLogTimeVisible(false);
    dispatch(resetLogForm());
    form.resetFields();
  };

  const handleSaveTimecard = (status) => async () => {
    const clearPayload = {
      ...payload,
      status,
      items: payload.items.map(
        ({engagement_task, work_date, minutes, type, notes, id, deleted}) => ({
          engagement_task,
          work_date,
          minutes,
          type,
          notes,
          id,
          deleted,
        }),
      ),
    };

    if (isEditMode) {
      await timecardAPI.updateTimeCard(clearPayload, id);
    } else {
      await timecardAPI.postTimeCard(clearPayload);
    }

    onReset();
  };

  const handleCancel = () => {
    onReset();
  };

  const handleResetLogForm = useCallback(() => {
    if (prompt) {
      handlePrompt(false);
    }

    if (logTimeVisible) {
      setLogTimeVisible(false);
    }
    dispatch(resetLogForm());
  }, [prompt, logTimeVisible, dispatch, handlePrompt, setLogTimeVisible]);

  const handleSummary = useCallback(
    (pageData) => {
      return temp.hours.length ? getSummary(pageData, payload.end_date) : false;
    },
    [temp.hours, payload],
  );
  const columns = useGetColumns(payload.end_date, handlePrompt);

  return (
    <AppCard headStyle={{border: 0}}>
      <AppTable
        columns={columns}
        scroll={{x: 'max-content'}}
        summary={handleSummary}
        dataSource={temp.hours}
        pagination={false}
      />
      <Button
        ghost
        type='primary'
        onClick={() => setLogTimeVisible(true)}
        className={'mt-2'}>
        <BiPlusCircle className={'mr-2'} size={16} /> Log Time
      </Button>
      <AppGridContainer className={'mt-4'}>
        <Col xs={24} md={16} lg={16}>
          <Space wrap={'wrap'} size={'middle'}>
            <Button type={'primary'} onClick={handleSaveTimecard('submitted')}>
              Submit Timecard
            </Button>
            <Button onClick={handleSaveTimecard('new')}>Save for Later</Button>
            <Button onClick={handleCancel}>Cancel</Button>
          </Space>
        </Col>
        {isEditMode && (
          <Col xs={24} md={8} lg={8} className='text-right'>
            <Button onClick={handleDelete}>Delete Timecard</Button>
          </Col>
        )}
      </AppGridContainer>
      <TimeCardsConfirmModal
        open={prompt}
        handleShow={handlePrompt}
        handleReset={handleResetLogForm}
        handleLogForm={setLogTimeVisible}
      />
      {modalComponent}
      <Modal
        destroyOnClose={true}
        title='Log Time'
        visible={logTimeVisible}
        footer={null}
        onCancel={handleResetLogForm}
        mask={true}
        closeIcon={
          <RiCloseLine
            fontSize={24}
            onClick={() => setLogTimeVisible(!logTimeVisible)}
          />
        }>
        <LogTimeForm
          form={form}
          logTimeVisible={logTimeVisible}
          setLogTimeVisible={setLogTimeVisible}
          handleCancelPopup={handleCancelPopup}
        />
      </Modal>
    </AppCard>
  );
};

TimeCardTable.propTypes = {
  currentStep: PropTypes.number,
  onContinue: PropTypes.func,
};
export default React.memo(TimeCardTable);
