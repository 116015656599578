import PropTypes from 'prop-types';
import React from 'react';

import AppTag from '../AppTag';

const AppLabel = ({label}) => {
  return (
    <AppTag shape={'square'} className={'border-none mr-1 mb-1'}>
      {label}
    </AppTag>
  );
};

AppLabel.propTypes = {
  label: PropTypes.string,
};

export default AppLabel;
