import AppCard from '@wieldy/components/AppCard';
import EmploymentHistory from 'app/shared/resource/EmploymentHistory';
import React from 'react';
import {useSelector} from 'react-redux';

const EmploymentDetails = () => {
  const {histories} = useSelector(({resource}) => resource);
  return (
    <AppCard>
      <EmploymentHistory history={histories} isCard={false} />
    </AppCard>
  );
};

export default EmploymentDetails;
