import AppBorderBox from '@wieldy/components/AppBorderBox';
import AppCard from '@wieldy/components/AppCard';
import AppLoader from '@wieldy/components/AppLoader';
import AppSelect from '@wieldy/components/dataEntry/AppSelect';
import {getRequiredMessage} from '@wieldy/utils/helpers/MessageHelper';
import {Button, Form, Select, Typography} from 'antd';
import useStep from 'app/hooks/useStep';
import {useGetPlacements} from 'app/services/placementService';
import {setInvoicePlacement} from 'app/store/features/invoice/invoicePlacementSlice';
import {getFullName} from 'app/utils/helpers/DataFormatHelper';
import {getItemsData} from 'app/utils/helpers/GetItemsData';
import moment from 'moment';
import propTypes from 'prop-types';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';

const getEngagementLabel = (placement) => {
  const resourceFullName = getFullName(placement.applicant);
  return `${placement.hid} :: ${placement.requisition.job_title} :: ${resourceFullName}`;
};

const SelectPlacement = ({userParams = {}}) => {
  const dispatch = useDispatch();
  const {currentStep, nextStep} = useStep();
  const {placement} = useSelector(({invoicePlacement}) => invoicePlacement);

  const {data, isLoading} = useGetPlacements({
    expand: ['candidate', 'requisition'],
    exclude_invoiced: true,
    ...userParams,
  });

  const [placementsDirty] = getItemsData('placements', data);
  const placements = placementsDirty.filter(({start_date}) =>
    moment(start_date).isSameOrBefore(moment(), 'day'),
  );
  const onFormSubmit = (formData) => {
    const item = placements.find((item) => item.id === formData?.placement);
    const {id, hid} = item;

    dispatch(
      setInvoicePlacement({
        id,
        hid,
        applicantFullName: getFullName(item.applicant),
        jobTitle: item.requisition.job_title,
      }),
    );

    if (nextStep) nextStep();
  };

  if (isLoading) {
    return <AppLoader />;
  }

  return currentStep > 1 ? (
    <AppBorderBox
      className='d-inline-flex px-6 mt-3'
      style={{backgroundColor: '#FFF'}}>
      <Typography.Title level={4} className={'mb-0'}>
        {placement.hid} :: {placement.jobTitle} :: {placement.applicantFullName}
      </Typography.Title>
    </AppBorderBox>
  ) : (
    <AppCard
      title='Select Placement'
      headStyle={{border: 0}}
      bodyStyle={{paddingTop: 0}}>
      <Form
        layout='vertical'
        onFinish={onFormSubmit}
        autoComplete='on'
        initialValues={{engagement: placement.id}}>
        <Form.Item
          name='placement'
          label='Placement'
          rules={[
            {
              required: true,
              message: getRequiredMessage('Placement'),
            },
          ]}>
          <AppSelect
            showSearch
            loading={isLoading}
            placeholder='Select placement'
            style={{minWidth: '340px', width: 'auto', maxWidth: '100%'}}>
            {placements.map((eng) => (
              <Select.Option key={eng.id} value={eng.id}>
                {getEngagementLabel(eng)}
              </Select.Option>
            ))}
          </AppSelect>
        </Form.Item>

        <Button type='primary' htmlType='submit' disabled={!placements?.length}>
          Continue
        </Button>
      </Form>
    </AppCard>
  );
};

SelectPlacement.propTypes = {
  userParams: propTypes.object,
};

export default React.memo(SelectPlacement);
