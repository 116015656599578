import AppLoader from '@wieldy/components/AppLoader';
import AppDynamicTabs from '@wieldy/components/AppTabs/AppDynamicTabs';
import AppTag from '@wieldy/components/AppTag';
import AppPageContainer from '@wieldy/components/pageComponents/AppPageContainer';
import AppPageHeader from '@wieldy/components/pageComponents/AppPageHeader';
import {Button, Divider, Row, Tooltip, Typography} from 'antd';
import {USER_INCLUDES} from 'app/constants';
import MESSAGE_QUERIES from 'app/constants/query-params/message-queries';
import {useAccountType} from 'app/hooks/useAccountType';
import {useNotes} from 'app/hooks/useNotes';
import EngagementModal from 'app/modals/EngagementModal';
import {useGetEngagement} from 'app/services/engagementService';
import {
  useCreateInquiryChannel,
  useCreateSubmissionChannel,
} from 'app/services/messages';
import NotesBtnIcon from 'assets/icons/NotesBtn';
import React, {useEffect, useMemo, useState} from 'react';
import {RiArrowLeftRightLine, RiMessageLine} from 'react-icons/ri';
import {useNavigate, useParams} from 'react-router-dom';

import {getPageTabsData} from '../data';
import MainContent from './MainContent';

const ViewEngagement = () => {
  const {id} = useParams();
  const navigate = useNavigate();

  const {isSupplier, isClient, isMarketplace} = useAccountType();
  useEffect(() => {
    if (isMarketplace && !isSupplier && !isClient) {
      navigate('/');
    }
  }, [isClient, isMarketplace, isSupplier, navigate]);

  const notes = useNotes('engagement_notes', 'engagement', id);

  const tabsData = useMemo(
    () => getPageTabsData(notes.isNotes),
    [notes.isNotes],
  );

  const [activeTab, setActiveTab] = useState(tabsData[0]);

  const include = useMemo(
    () =>
      isSupplier
        ? USER_INCLUDES.clientIncludeDetails
        : USER_INCLUDES.supplierIncludeDetails,
    [isSupplier],
  );

  const {isLoading, data} = useGetEngagement(id, {
    include,
    expand: [
      'client',
      'supplier',
      'resource',
      'requisition',
      'hiring_manager',
      'account_manager',
      'resource_manager',
    ],
  });

  const createInquiryChannel = useCreateInquiryChannel();
  const createSubmissionChannel = useCreateSubmissionChannel();

  const handleSendMessage = () => {
    if (isMarketplace) {
      navigate('/messages');
    } else {
      if (data.inquiry) {
        createInquiryChannel.mutate(data.inquiry, {
          onSuccess: ({data}) => {
            const channelId = data.id;
            navigate(`/messages?${MESSAGE_QUERIES.channelId}=${channelId}`);
          },
        });
      } else {
        createSubmissionChannel.mutate(data.submission, {
          onSuccess: ({data}) => {
            const channelId = data.id;
            navigate(`/messages?${MESSAGE_QUERIES.channelId}=${channelId}`);
          },
        });
      }
    }
  };

  const engagement = useMemo(
    () => new EngagementModal(data, isSupplier),
    [data, isSupplier],
  );

  if (isLoading) {
    return <AppLoader />;
  }

  return (
    <AppPageContainer
      header={
        <AppPageHeader
          backHeader
          header='inner'
          category='Engagement'
          tabs={
            <AppDynamicTabs
              tabsData={tabsData}
              activeKey={activeTab?.key}
              setActiveTab={setActiveTab}
              scroll
            />
          }
          extra={
            <>
              {!isMarketplace && (
                <>
                  <Tooltip
                    overlayStyle={{fontSize: '12px'}}
                    title={'Send message'}>
                    <Button
                      type='primary'
                      shape='circle'
                      onClick={handleSendMessage}
                      icon={<RiMessageLine fontSize={16} />}
                    />
                    <Divider type='vertical' style={{height: 28}} />
                  </Tooltip>
                </>
              )}
              {notes?.isNotes || (
                <Tooltip
                  overlayStyle={{fontSize: '12px'}}
                  title={'Add Internal Note'}>
                  <Button
                    type='primary'
                    shape='circle'
                    onClick={notes.handleShowNotes}
                    icon={<NotesBtnIcon />}
                  />
                </Tooltip>
              )}
            </>
          }>
          <Row className='page-header-paper text-uppercase mb-5' align='middle'>
            <div className={'paper paper-sm'}>
              <div className={'paper-body'}>
                <Typography.Title
                  level={4}
                  type='secondary'
                  className='text-xs mb-1'>
                  Supplier
                </Typography.Title>
                <Typography.Title level={5} className={'text-sm my-0'}>
                  {engagement.supplier.name}
                </Typography.Title>
              </div>
            </div>
            <RiArrowLeftRightLine fontSize={16} className='mx-4' />
            <div className={'paper paper-sm'}>
              <div className={'paper-body'}>
                <Typography.Title
                  level={4}
                  type='secondary'
                  className='text-xs mb-1'>
                  Client
                </Typography.Title>
                <Typography.Title level={5} className={'text-sm my-0'}>
                  {engagement.client.name}
                </Typography.Title>
              </div>
            </div>
          </Row>
          <Typography.Title
            level={5}
            type='secondary'
            className='text-uppercase text-xs tracking-normal mb-3'>
            resource
          </Typography.Title>
          <Typography.Title
            level={2}
            className={'d-flex flex-wrap align-items-center'}>
            {engagement?.resource?.name}
            <Divider type='vertical' style={{height: 20}} />
            <Typography.Text type='secondary' className='text-md mr-2'>
              {engagement?.requisition?.jobTitle}
            </Typography.Text>
            <AppTag
              label={engagement.status}
              shape={'circle'}
              color={engagement?.isOpen ? 'success' : 'default'}
              className={'tracking-normal'}
            />
          </Typography.Title>
        </AppPageHeader>
      }>
      <MainContent initData={data} engagement={engagement} notes={notes} />
    </AppPageContainer>
  );
};

export default ViewEngagement;
