export const isValidURL = (field, value, callback) => {
  // Regular expression pattern for URL validation
  const urlPattern =
    /^(http|https):\/\/|[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,6}(:[0-9]{1,5})?(\/.*)?$/;

  if (value && !urlPattern.test(value)) {
    callback('Must be a valid url');
  } else {
    callback();
  }
};
