import {Descriptions, Typography} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import {useNavigate} from 'react-router-dom';

const Resource = ({resource}) => {
  const navigate = useNavigate();
  const onLink = () => {
    navigate(`/resources/${resource.id}/view-resource`);
  };
  return (
    <React.Fragment>
      <Typography.Title level={4} className={'mb-4'}>
        Resource
      </Typography.Title>
      <Descriptions
        colon={false}
        labelStyle={{width: '50%'}}
        className='description-root mb-4'
        column={{xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1}}>
        <Descriptions.Item label='Resource Profile'>
          <Typography.Link onClick={onLink} className={'font-semibold'}>
            {resource.name}
          </Typography.Link>
        </Descriptions.Item>
        <Descriptions.Item label='Title'>{resource.title}</Descriptions.Item>
        <Descriptions.Item label='Location'>
          {resource.location}
        </Descriptions.Item>
        <Descriptions.Item label='Local Time Zone'>
          {resource.timezone}
        </Descriptions.Item>
      </Descriptions>
    </React.Fragment>
  );
};

Resource.propTypes = {
  resource: PropTypes.object,
};

export default Resource;
