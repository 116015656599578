import cn from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import {useFormContext} from 'react-hook-form';

import Label from './label';
import MultipleCheckboxRegion from './multiple-checkbox-region';

const RegionMultipleField = ({data, label, name}) => {
  const {
    formState: {errors},
  } = useFormContext();

  const error = errors?.[name];

  return (
    <div className='relative pb-5'>
      <Label text={label} isRequired />
      <div className={cn('form-check-area', !!error && 'form-error')}>
        {data.map(([key, data]) => (
          <MultipleCheckboxRegion
            key={key}
            parentKey={name}
            name={`${name}.${key}`}
            job_functions
            item={data}
          />
        ))}
      </div>
      {!!error && (
        <span className='form-error-help'>{error.root?.message}</span>
      )}
    </div>
  );
};

RegionMultipleField.propTypes = {
  data: PropTypes.object,
  label: PropTypes.string,
  name: PropTypes.string,
};

export default RegionMultipleField;
