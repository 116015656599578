import AppLoader from '@wieldy/components/AppLoader';
import AppDynamicTabs from '@wieldy/components/AppTabs/AppDynamicTabs';
import AppTag from '@wieldy/components/AppTag/AppTag';
import AppPageContainer from '@wieldy/components/pageComponents/AppPageContainer';
import AppPageHeader from '@wieldy/components/pageComponents/AppPageHeader';
import {useGetTimeCard} from 'app/services/timecardsService';
import ActionButtons from 'app/shared/components/ActionButtons/ActionButtons';
import {getFullName} from 'app/utils/helpers/DataFormatHelper';
import clsx from 'clsx';
import React, {useMemo, useState} from 'react';
import {useParams} from 'react-router-dom';

import {timecardStatuses} from '../MainContent/data';
import MainContent from './MainContent';

const tabsData = [
  {key: 'summary', title: 'Summary'},
  {key: 'timecard-entries-by-type', title: 'Timecard Entries By Type'},
  {key: 'individual-time-entries', title: 'Individual Time Entries'},
];

const ViewTimecard = () => {
  const [activeTab, setActiveTab] = useState(tabsData[0]);
  const {id} = useParams();

  const {data, isLoading} = useGetTimeCard(id);

  const {timecard} = data || {timecard: null};

  const {supplierName, resourceName, end_date, status} = useMemo(() => {
    if (!timecard) {
      return {
        end_date: '',
        resourceName: '',
        supplierName: '',
        status: '',
      };
    }
    const {supplier, resource, end_date, status} = timecard;

    return {
      end_date,
      resourceName: getFullName(resource),
      supplierName: supplier?.name || '',
      status,
    };
  }, [timecard]);

  const tagClassName = clsx(
    'tracking-normal',
    status === 'approved' && 'ant-tag-approved',
    status === 'pending' && 'ant-tag-pending',
    status === 'rejected' && 'ant-tag-rejected',
  );

  if (isLoading) {
    return <AppLoader />;
  }

  return (
    <AppPageContainer
      header={
        <AppPageHeader
          backHeader
          header={'inner'}
          category='Timecard'
          title={supplierName}
          subTitle={[resourceName, end_date]}
          extra={
            <ActionButtons
              id={id}
              timeDate={{
                created_at: timecard.created_at,
                updated_at: timecard.updated_at,
                submitted_at: timecard.submitted_at,
                invoiced_at: timecard.invoice && timecard.invoice?.created_at,
                approved_at: timecard.approved_at,
              }}
              status={status}
              hid={timecard?.hid}
              resource={timecard?.resource}
              date={timecard?.end_date}
            />
          }
          count={
            <AppTag
              shape={'circle'}
              className={tagClassName}
              label={timecardStatuses[status]}
            />
          }
          tabs={
            <AppDynamicTabs
              tabsData={tabsData}
              activeKey={activeTab?.key}
              setActiveTab={setActiveTab}
              scroll
            />
          }
        />
      }>
      {!!data && <MainContent data={data} />}
    </AppPageContainer>
  );
};

export default ViewTimecard;
