import {Steps} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

const AppSteps = ({children, ...rest}) => {
  return (
    <Steps initial={1} progressDot {...rest}>
      {children}
    </Steps>
  );
};

AppSteps.propTypes = {
  direction: PropTypes.oneOf(['horizontal', 'vertical']),
  current: PropTypes.number,
  children: PropTypes.node,
};

AppSteps.defaultProps = {
  direction: 'vertical',
};

export default AppSteps;
