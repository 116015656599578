import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  requisition: {
    id: undefined,
    job_title: undefined,
  },
  suppliers: [],
  candidates: [],
  existingCandidates: [],
  isEditMode: false,
  accompanying_message: null,
  targetResource: null,
  statusPage: null,
  targetId: null,
};

export const inquirySlice = createSlice({
  name: 'inquiry',
  initialState,
  reducers: {
    setInquiryRequisition: (state, action) => {
      state.requisition = action.payload;
    },
    setInquirySuppliers: (state, action) => {
      state.suppliers = action.payload;
    },
    setInquiryCandidates: (state, action) => {
      state.candidates = action.payload;
    },
    setInquiryMessage: (state, action) => {
      state.accompanying_message = action.payload;
    },
    resetInquiryState: () => initialState,
    setInquiryEditMode: (state, action) => {
      state.isEditMode = action.payload;
    },
    setFromRequisitionData: (state, action) => {
      const {payload} = action;
      state.requisition = {
        id: payload.requisition.id,
        job_title: payload.requisition.job_title,
      };
    },
    setInquiryEditData: (state, action) => {
      const {payload} = action;
      state.requisition = {
        id: payload.requisition.id,
        job_title: payload.requisition.job_title,
      };
      state.suppliers = [payload.supplier];
      state.existingCandidates = payload.candidates.map(
        ({resource}) => resource.id,
      );
      state.accompanying_message = payload.accompanying_message;
    },
    setInquiryTargetResource: (state, action) => {
      state.targetResource = action.payload;
    },
    setInquiryStatusPage: (state, action) => {
      state.statusPage = action.payload.statusPage;
      state.existingCandidates = action.payload.existingCandidates;
      state.targetId = action.payload.targetId;
    },
  },
});

export const {
  setInquiryRequisition,
  setInquirySuppliers,
  setInquiryCandidates,
  resetInquiryState,
  setInquiryEditMode,
  setInquiryEditData,
  setInquiryMessage,
  setInquiryTargetResource,
  setInquiryStatusPage,
} = inquirySlice.actions;

export default inquirySlice.reducer;
