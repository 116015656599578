import AppUserProfile from '@wieldy/components/AppUserProfile';
import PropTypes from 'prop-types';
import React from 'react';
import {RiMapPinLine} from 'react-icons/ri';

import RatingReviews from '../RatingReviews/RatingReviews';

const UserMiniProfile = ({
  name,
  avatarUrl,
  location,
  rating,
  reviews,
  onClick,
  showDummyAvatar = false,
  ...restProps
}) => {
  const userProfileProps = {
    title: name,
    avatarUrl: avatarUrl ? avatarUrl : showDummyAvatar && null,
    className: 'd-flex flex-fill align-items-center',
  };

  if (typeof onClick === 'function') {
    userProfileProps.titleProps = {onClick};
  }

  if (location) {
    userProfileProps.items = [
      {
        icon: <RiMapPinLine fontSize={16} />,
        content: location,
      },
    ];
  }

  return (
    <AppUserProfile {...userProfileProps} {...restProps}>
      <RatingReviews rating={rating} reviews={reviews} />
    </AppUserProfile>
  );
};

UserMiniProfile.propTypes = {
  name: PropTypes.string,
  avatarUrl: PropTypes.string,
  location: PropTypes.string,
  rating: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  reviews: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClick: PropTypes.func,
  showDummyAvatar: PropTypes.bool,
};

export default React.memo(UserMiniProfile);
