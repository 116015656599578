import {CATEGORIES} from 'app/constants';
import React from 'react';
import {RiBookmark3Fill, RiSearchLine} from 'react-icons/ri';

export const statuses = [
  CATEGORIES.AVAILABLE,
  CATEGORIES.AVAILABLE_SOON,
  CATEGORIES.UNAVAILABLE,
];

export const queryParams = {
  include: [
    'engagements',
    'skill_tags',
    'completed_engagements',
    'supplier_active_clients',
    'supplier_completed_engagements',
    'supplier_available_resources',
    'supplier_relationship_status',
    'supplier_inquiries',
    'supplier_submissions',
    'supplier_engagements',
    'total_count',
    'bookmarked',
    'bookmarked_on',
  ],
  expand: ['supplier', 'user'],
};
export const supplierTabs = [
  {key: CATEGORIES.ALL, title: 'All', route: '/resources/all'},
  {
    key: CATEGORIES.AVAILABLE,
    title: 'Available',
    route: '/resources/available',
  },
  {
    key: CATEGORIES.AVAILABLE_SOON,
    title: 'Available Soon',
    route: '/resources/available_soon',
  },
  {
    key: CATEGORIES.UNAVAILABLE,
    title: 'Unavailable',
    route: '/resources/unavailable',
  },
];

export const extraTabs = [
  {
    key: 'bookmarked',
    title: 'Bookmarked',
    icon: <RiBookmark3Fill />,
    route: '/resources/bookmarked',
  },
  {
    key: 'browse',
    title: 'Browse Resources',
    icon: <RiSearchLine />,
    route: '/resources/browse',
  },
];

export const pageQueriesSupplier = {
  all: {
    queryParams,
    title: 'All Resources',
  },
  available: {
    queryParams: {
      ...queryParams,
      relation_status: 'available',
    },
    title: 'Available Resources',
    label: 'Available',
    rowTitle: 'Available Resources',
    empty: 'No Available Resources',
  },
  available_soon: {
    queryParams: {
      ...queryParams,
      relation_status: 'available_soon',
    },
    title: 'Available Soon Resources',
    label: 'Available',
    rowTitle: 'Available Soon Resources',
    empty: 'No Available Soon Resources',
  },
  unavailable: {
    queryParams: {
      ...queryParams,
      relation_status: 'unavailable',
    },
    title: 'Unavailable Resources',
    label: 'Available',
    rowTitle: 'Unavailable Resources',
    empty: 'No Unavailable Resources',
  },
};

export const clientPageQueries = {
  ...pageQueriesSupplier,
  bookmarked: {
    queryParams: {
      ...queryParams,
      bookmarked: true,
    },
    title: 'Bookmarked Resources',
    label: 'Bookmarked',
    rowTitle: 'Bookmarked Resources',
    empty: 'No Bookmarked Resources',
  },
  browse: {
    queryParams: {
      ...queryParams,
    },
    title: 'Browse Resources',
    label: 'Available',
    rowTitle: 'Resources',
    empty: 'No Resources',
  },
};
