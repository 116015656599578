import AppFormWrapper, {
  AppFormActions,
  AppFormBody,
} from '@wieldy/components/AppFormWrapper';
import AppSelect from '@wieldy/components/dataEntry/AppSelect';
import {
  getRequiredMessage,
  getSelectPlaceHolder,
} from '@wieldy/utils/helpers/MessageHelper';
import {Button, Form, Typography} from 'antd';
import PropTypes from 'prop-types';
import React, {useEffect, useMemo} from 'react';

const defaultOptions = [
  {value: 'prepared', label: 'Prepared'},
  {value: 'submitted_to_client', label: 'Submitted to Client'},
  {value: 'paid_by_client', label: 'Paid by Client'},
  {value: 'paid_to_supplier', label: 'Paid to Supplier'},
  {value: 'disputed', label: 'Disputed'},
];

const ChangeInvoiceStatus = ({onCancel, onFinish, status}) => {
  const options = useMemo(() => {
    if (status === 'disputed') {
      return defaultOptions.slice(1, -1);
    }
    const index = defaultOptions.findIndex((el) => el.value === status);
    return defaultOptions.slice(index + 1);
  }, [status]);

  const [form] = Form.useForm();
  useEffect(() => {
    return () => {
      form.resetFields();
    };
  }, [form]);

  return (
    <React.Fragment>
      <Typography.Paragraph className='mb-4'>
        If you would like to change the status of this invoice, select the
        status below and then click Change Status button. If you would like to
        reconsider this action, click Cancel.
      </Typography.Paragraph>
      <AppFormWrapper>
        <Form layout='vertical' onFinish={onFinish} form={form}>
          <AppFormBody>
            <Form.Item
              name='status'
              label='Change Invoice Status To'
              rules={[{required: true, message: getRequiredMessage('Status')}]}>
              <AppSelect
                style={{width: '350px'}}
                options={options}
                placeholder={getSelectPlaceHolder('status')}
              />
            </Form.Item>
          </AppFormBody>
          <AppFormActions>
            <Button type='primary' htmlType='submit'>
              Change Status
            </Button>
            <Button type='link' onClick={onCancel}>
              Cancel
            </Button>
          </AppFormActions>
        </Form>
      </AppFormWrapper>
    </React.Fragment>
  );
};

ChangeInvoiceStatus.propTypes = {
  onCancel: PropTypes.func,
  onFinish: PropTypes.func,
  status: PropTypes.string,
};

export default ChangeInvoiceStatus;
