import AppCard from '@wieldy/components/AppCard';
import AppGridContainer from '@wieldy/components/AppGridContainer';
import AppTable from '@wieldy/components/AppTable';
import {Button, Col, Empty, Space} from 'antd';
import {useForm} from 'antd/es/form/Form';
import Modal from 'antd/es/modal/Modal';
import {expenseAPI} from 'app/api/expenseApi';
import {useRemove} from 'app/hooks/useRemove';
import {useResetState} from 'app/hooks/useResetState';
import {
  deleteItemExpenses,
  resetExpensesForm,
  setChangeModeExpenses,
  setInitialExpensesForm,
} from 'app/store/features/expenses/expensesSlice';
import PropTypes from 'prop-types';
import React, {useCallback} from 'react';
import {BiPlusCircle} from 'react-icons/bi';
import {RiCloseLine} from 'react-icons/ri';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';

import ExpenseSpendForm from '../ExpenseSpendCard/ExpenseSpendForm';
import {getColumns} from './columns';

const ExpenseTable = () => {
  const [expenseVisible, setExpenseVisible] = React.useState(false);
  const dispatch = useDispatch();
  const {id} = useParams();
  const {payload, temp} = useSelector(({expenses}) => expenses);
  const {items: dirtyItems} = payload;
  const {isEditMode} = temp;
  const items = dirtyItems.filter((el) => !el.deleted);

  const handleExpenseSpendForm = () => {
    setExpenseVisible(!expenseVisible);
  };
  const onReset = useResetState();

  const handleSubmit = (status) => {
    const data = {
      ...payload,
      items: payload.items.map((el) => ({...el, tempId: undefined})),
      status,
    };
    return async () => {
      if (isEditMode) {
        await expenseAPI.updateExpense(data, id);
      } else {
        await expenseAPI.postExpense(data);
      }
      onReset();
    };
  };

  const [modalComponent, handleDelete] = useRemove(
    id,
    expenseAPI.deleteExpense,
    'expense',
  );

  const handleCancel = () => {
    onReset();
  };

  const handleUpdateItem = useCallback(
    (item) => {
      dispatch(setInitialExpensesForm(item));
      dispatch(setChangeModeExpenses());
      setExpenseVisible(true);
    },
    [dispatch],
  );

  const handleDeleteItem = useCallback(
    (item) => {
      dispatch(deleteItemExpenses(item));
    },
    [dispatch],
  );
  const bulkActions = useCallback(
    (item) => {
      return [
        {
          title: 'Edit',
          onClick: () => handleUpdateItem(item),
        },
        {
          title: 'Delete',
          onClick: () => handleDeleteItem(item),
        },
      ];
    },
    [handleDeleteItem, handleUpdateItem],
  );
  const [form] = useForm();

  const handleCancelPopup = useCallback(() => {
    setExpenseVisible(false);
    dispatch(resetExpensesForm());
    form.resetFields();
  }, [dispatch, form]);

  return (
    <AppCard headStyle={{border: 0, paddingTop: 8}}>
      <AppTable
        rowKey={({id, tempId}) => id || tempId}
        bulkActions={bulkActions}
        locale={{
          emptyText: <Empty description='No Available Expenses' />,
        }}
        columns={getColumns()}
        dataSource={items}
        pagination={false}
      />
      <Button
        ghost
        type='primary'
        onClick={handleExpenseSpendForm}
        className={'mt-2'}>
        <BiPlusCircle className={'mr-2'} size={16} /> Add Expense
      </Button>
      <AppGridContainer className={'mt-8'}>
        <Col xs={24} md={16} lg={16}>
          <Space wrap={'wrap'} size={'middle'}>
            <Button type={'primary'} onClick={handleSubmit('submitted')}>
              Submit Expense
            </Button>
            <Button onClick={handleSubmit('new')}>Save for Later</Button>
            <Button onClick={handleCancel}>Cancel</Button>
          </Space>
        </Col>
        {isEditMode && (
          <Col xs={24} md={8} lg={8} className='text-right'>
            <Button onClick={handleDelete}>Delete Expense</Button>
          </Col>
        )}
      </AppGridContainer>
      {modalComponent}
      <Modal
        destroyOnClose={true}
        onCancel={handleCancelPopup}
        title='Add Expense'
        visible={expenseVisible}
        footer={null}
        closeIcon={
          <RiCloseLine
            fontSize={24}
            onClick={() => setExpenseVisible(!expenseVisible)}
          />
        }>
        <ExpenseSpendForm
          form={form}
          handleCancelPopup={handleCancelPopup}
          setExpenseVisible={setExpenseVisible}
        />
      </Modal>
    </AppCard>
  );
};

ExpenseTable.propTypes = {
  currentStep: PropTypes.number,
  onContinue: PropTypes.func,
  expense: PropTypes.object,
  setExpense: PropTypes.func,
};
export default React.memo(ExpenseTable);
