import Label from '@register-app/components/additional/label';
import {getInputPlaceHolder} from '@wieldy/utils/helpers/MessageHelper';
import PropTypes from 'prop-types';
import React from 'react';
import {useFormContext} from 'react-hook-form';

import AppInput from '../controles/input';

const data = [
  {
    name: 'placement_contingent_placement_fee',
    label: 'Permanent/direct hires - contingent placement fee',
    message: '',
  },
  {
    name: 'placement_retained_fee',
    label:
      'Permanent/direct hires - retained fee (up-front, milestone, success payments)',
    message: '',
  },
  {
    name: 'placement_temporary_hires',
    label: 'Contingent/temporary hires (temp/staffing/contractors)',
    message: '',
  },
  {
    name: 'placement_temp_to_perm',
    label: 'Temporary to permanent hires (temp-to-perm)',
    message: 'The total sum must be equal to 100',
  },
];

const CompanyPlacement = ({isRequired}) => {
  const {formState} = useFormContext();

  const error = formState?.errors?.placement_type?.root;

  return (
    <div>
      <Label isRequired text='Company Placement Type Ratios' />
      {data.map((item) => (
        <div className='flex-row d-flex flex-gap-4' key={item.name}>
          <AppInput
            isRequired={isRequired}
            type='number'
            min={0}
            pattern='[0-9]+([\.,][0-9]+)?'
            name={`placement_type.${item.name}`}
            classes={{root: 'flex-shrink-0'}}
            addonAfter='%'
            placeholder={getInputPlaceHolder('value')}
          />
          {!!item.label && <span>{item.label}</span>}
        </div>
      ))}
      {!!error && <span className='form-error-help'>{error.message}</span>}
    </div>
  );
};

CompanyPlacement.propTypes = {
  isRequired: PropTypes.bool,
};
export default CompanyPlacement;
