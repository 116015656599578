import {Steps} from 'antd';
import PropTypes from 'prop-types';
import {useCallback, useMemo, useState} from 'react';

const STEP_STATUS = {
  WAIT: 'wait',
  FINISH: 'finish',
  PROCESS: 'process',
};

const StepWizard = ({
  items,
  current,
  showActiveStep,
  bindProps = {},
  ...restProps
}) => {
  const [currentStep, setCurrentStep] = useState(current);

  const goToStep = useCallback((step) => {
    return () => {
      setCurrentStep(step);
    };
  }, []);

  const stepItems = useMemo(() => {
    if (!Array.isArray(items)) {
      throw new Error('Items must by array');
    }
    const func_args = {
      goToStep,
      current: currentStep,
      ...bindProps,
    };
    const renderStepItems = [];
    for (let index = 0; index < items.length; index++) {
      let item = {...items[index]};
      if (typeof item !== 'object') {
        continue;
      }
      item.status = STEP_STATUS.WAIT;
      if (currentStep === index) {
        item.status = STEP_STATUS.PROCESS;
      } else if (currentStep > index) {
        item.status = STEP_STATUS.FINISH;
      }
      func_args.status = item.status;
      func_args.step = index;

      item = Object.keys(item).reduce((obj, key) => {
        obj[key] = item[key];
        if (typeof item[key] === 'function') {
          obj[key] = item[key](func_args);
        }
        return obj;
      }, {});

      let {preview, description, ...rest} = item;

      if (showActiveStep === true && item.status === STEP_STATUS.WAIT) {
        description = '';
      }
      if (preview && item.status === STEP_STATUS.FINISH) {
        description = preview;
      }
      const component = (
        <Steps.Step
          key={item?.key || index}
          description={description}
          {...rest}
        />
      );
      renderStepItems.push(component);
    }
    return renderStepItems;
  }, [bindProps, currentStep, goToStep, items, showActiveStep]);

  return (
    <Steps current={current} progressDot {...restProps}>
      {stepItems}
    </Steps>
  );
};

StepWizard.propTypes = {
  items: PropTypes.array,
  current: PropTypes.number,
  showActiveStep: PropTypes.bool,
  bindProps: PropTypes.object,
};

StepWizard.defaultProps = {
  showActiveStep: true,
  direction: 'vertical',
};

export default StepWizard;
