import {
  useInfiniteQuery,
  useQueryClient,
  useReactMutation,
} from 'app/hooks/useReactQuery';
import React from 'react';

import axios from '../config/axios';

const defaultQueryParams = {
  include: ['total_count'],
  sort: ['-created_at'],
  expand: ['user'],
  limit: 5,
};

/** Fetch notes for specific module **/
export const useGetNotes = (
  {entity, primaryKey, primaryId},
  total,
  options,
) => {
  React.useEffect(() => {
    if (!entity && !primaryKey && !primaryId) {
      throw new Error(
        'Please pass entity of following details:entity, primaryKey & primaryId',
      );
    }
  }, [entity, primaryKey, primaryId]);

  return useInfiniteQuery(
    [entity, primaryId],
    async ({pageParam = 0}) => {
      const params = {
        ...defaultQueryParams,
        [primaryKey]: primaryId,
        page: pageParam,
      };

      const {data} = await axios.get(entity, {params});
      return {
        items: data?.data ?? [],
        total_count: data?.total_count ?? 0,
      };
    },
    {
      ...options,
      getNextPageParam: (lastPage, allPages) => {
        const currentPages = allPages.length;
        const maxPages = total / 5;
        return currentPages < maxPages ? currentPages : undefined;
      },
    },
  );
};

/** Create a note for specific module */
export const useCreateNote = ({entity, primaryKey, primaryId}) => {
  if (!entity && !primaryKey && !primaryId) {
    throw new Error(
      'Please pass entity of following details:entity, primaryKey & primaryId',
    );
  }
  const client = useQueryClient();
  return useReactMutation(async (message) => {
    const {data} = await axios.post(entity, {
      [primaryKey]: primaryId,
      text: message,
    });
    await client.invalidateQueries([entity, primaryId]);
    return data;
  });
};

/** Update a note for specific module */
export const useUpdateNote = (entity, primaryId) => {
  const client = useQueryClient();
  return useReactMutation(async ({id, message}) => {
    const {data} = await axios.patch(`${entity}/${id}`, {text: message});
    await client.invalidateQueries([entity, primaryId]);
    return data;
  });
};

/** Delete a note for specific module */
export const useDeleteNote = (entity, primaryId) => {
  const client = useQueryClient();
  return useReactMutation(async (noteId) => {
    const {data} = await axios.delete(`${entity}/${noteId}`);
    await client.invalidateQueries([entity, primaryId]);
    return data;
  });
};
