export default {
  application_management_and_support: 'Application Management & Support',
  application_testing: 'Application Testing',
  ar_vr_development: 'AR/VR Development',
  artificial_intelligence: 'Artificial Intelligence',
  bi_and_big_data_consulting_and_si: 'BI & Big Data Consulting & SI',
  blockchain: 'Blockchain',
  business_process_outsourcing: 'Business Process Outsourcing',
  cloud_consulting_and_si: 'Cloud Consulting & SI',
  consulting: 'Consulting',
  content_marketing: 'Content Marketing',
  creative_design: 'Creative Design',
  crm_consulting_and_si: 'CRM Consulting and SI',
  custom_software_development: 'Custom Software Development',
  cybersecurity: 'Cybersecurity',
  data_analytics: 'Data Analytics',
  digital_design: 'Digital Design',
  digital_marketing: 'Digital Marketing',
  digital_strategy: 'Digital Strategy',
  direct_marketing: 'Direct Marketing',
  document_digitization_and_management: 'Document Digitization & Management',
  e_commerce_development: 'E-Commerce Development',
  ecm_consulting_and_si: 'ECM Consulting and SI',
  email_marketing: 'Email Marketing',
  engineering: 'Engineering',
  enterprise_app_modernization: 'Enterprise App Modernization',
  erp_consulting_and_si: 'ERP Consulting and SI',
  graphic_design: 'Graphic Design',
  hr_services: 'HR Services',
  iot_development: 'IoT Development',
  it_services: 'IT Services',
  it_staff_augmentation: 'IT Staff Augmentation',
  it_strategy_consulting: 'IT Strategy Consulting',
  managed_it_services: 'Managed IT Services',
  market_research: 'Market Research',
  marketing_strategy: 'Marketing Strategy',
  mobile_and_app_marketing: 'Mobile & App Marketing',
  mobile_app_development: 'Mobile App Development',
  other_application_development: 'Other Application Development',
  other_design: 'Other Design',
  other_digital_marketing: 'Other Digital Marketing',
  other_it_consulting_and_si: 'Other IT Consulting and SI',
  other_marketing: 'Other Marketing',
  print_design: 'Print Design',
  product_design: 'Product Design',
  search_engine_optimization: 'Search Engine Optimization',
  search_marketing: 'Search Marketing',
  social_media_marketing: 'Social Media Marketing',
  software_development: 'Software Development',
  unified_communications_consulting_and_si:
    'Unified Communications Consulting & SI',
  ux_ui_design: 'UX/UI Design',
  video_production: 'Video Production',
  wearable_app_development: 'Wearable App Development',
  web_design: 'Web Design',
  web_development: 'Web Development',
};
