import AppFormWrapper, {
  AppFormActions,
  AppFormBody,
} from '@wieldy/components/AppFormWrapper';
import AppSelect from '@wieldy/components/dataEntry/AppSelect';
import {
  getRequiredMessage,
  getSelectPlaceHolder,
} from '@wieldy/utils/helpers/MessageHelper';
import {Button, Col, DatePicker, Form, Row, Space} from 'antd';
import ResourceHelper from 'app/utils/ResourceHelper';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

const RangePicker = DatePicker.RangePicker;

const OverviewForm = ({onCancel}) => {
  const [disabled, setDisabled] = React.useState(true);
  const [showAvailable, setShowAvailable] = React.useState(false);

  const handleChangeAvailability = React.useCallback((value) => {
    setShowAvailable(value === 'available_soon');
  }, []);
  return (
    <AppFormWrapper>
      <Form
        layout='vertical'
        autoComplete='off'
        onFieldsChange={() => setDisabled(false)}>
        <AppFormBody>
          <Row gutter={[30, 10]}>
            <Col md={12}>
              <Form.Item
                label='Assignment Duration (min)'
                name='assignment_duration_min'
                rules={[
                  {
                    required: true,
                    message: getRequiredMessage('Assignment Duration (min)'),
                  },
                ]}>
                <AppSelect
                  placeholder={getSelectPlaceHolder(
                    'Assignment duration (min)',
                  )}
                  options={ResourceHelper.assignmentDurations()}
                />
              </Form.Item>
            </Col>
            <Col md={12}>
              <Form.Item
                label='Assignment Duration (max)'
                name='assignment_duration_max'
                rules={[
                  {
                    required: true,
                    message: getRequiredMessage('Assignment Duration (max)'),
                  },
                ]}>
                <AppSelect
                  placeholder={getSelectPlaceHolder(
                    'Assignment duration (max)',
                  )}
                  options={ResourceHelper.assignmentDurations()}
                />
              </Form.Item>
            </Col>
            <Col md={12}>
              <Form.Item
                label='Work Status'
                name='work_status'
                rules={[
                  {
                    required: true,
                    message: getRequiredMessage('Work Status'),
                  },
                ]}>
                <AppSelect
                  placeholder={getSelectPlaceHolder('Work Status')}
                  options={ResourceHelper.jobStatus()}
                />
              </Form.Item>
            </Col>
            <Col md={12}>
              <Form.Item
                label='Part Time Assignment'
                name='part_time_assignment'
                rules={[
                  {
                    required: true,
                    message: getRequiredMessage('Part time assignment'),
                  },
                ]}>
                <AppSelect
                  placeholder={getSelectPlaceHolder('Part Time Assignment')}
                  options={ResourceHelper.yesNoOptions()}
                />
              </Form.Item>
            </Col>
            <Col md={12}>
              <Form.Item
                label='Available for Overtime'
                name='overtime_availability'
                rules={[
                  {
                    required: true,
                    message: getRequiredMessage('Available for overtime'),
                  },
                ]}>
                <AppSelect
                  placeholder={getSelectPlaceHolder('Available for Overtime')}
                  options={ResourceHelper.availabilityOvertime()}
                />
              </Form.Item>
            </Col>
            <Col md={12}>
              <Form.Item
                label='Local Time Zone'
                name='timezone'
                rules={[
                  {
                    required: true,
                    message: getRequiredMessage('Local time zone'),
                  },
                ]}>
                <AppSelect
                  placeholder={getSelectPlaceHolder('Local Time Zone')}
                  options={ResourceHelper.timezones()}
                />
              </Form.Item>
            </Col>
            <Col md={12}>
              <Form.Item
                label='Able to Travel'
                name='travel_availability'
                rules={[
                  {
                    required: true,
                    message: getRequiredMessage('Able to travel'),
                  },
                ]}>
                <AppSelect
                  placeholder={getSelectPlaceHolder('Able to Travel')}
                  options={ResourceHelper.travelAvailability()}
                />
              </Form.Item>
            </Col>
            <Col md={12}>
              <Form.Item
                label='Visa Status'
                name='visa_status'
                rules={[
                  {
                    required: true,
                    message: getRequiredMessage('Visa status'),
                  },
                ]}>
                <AppSelect
                  placeholder={getSelectPlaceHolder('Visa Status')}
                  options={ResourceHelper.visaStatus()}></AppSelect>
              </Form.Item>
            </Col>
            <Col md={12}>
              <Form.Item
                name='availability'
                label='Availability Status'
                rules={[
                  {
                    required: true,
                    message: getRequiredMessage('Availability status'),
                  },
                ]}>
                <AppSelect
                  placeholder={getSelectPlaceHolder('Availability Status')}
                  options={ResourceHelper.availabilityStatus()}
                  onSelect={handleChangeAvailability}
                />
              </Form.Item>
            </Col>
            {showAvailable && (
              <Col md={12}>
                <Form.Item
                  name='available'
                  label='Available On'
                  rules={[
                    {
                      required: true,
                      message: getRequiredMessage(
                        'Available from and available to',
                      ),
                    },
                  ]}>
                  <RangePicker
                    format='MM-DD-YYYY'
                    disabledDate={(current) =>
                      current && current < moment().startOf('day')
                    }
                    placeholder={['Available From', 'Available To']}
                    style={{width: '350px'}}
                  />
                </Form.Item>
              </Col>
            )}
          </Row>
        </AppFormBody>
        <AppFormActions>
          <Space size={16} className={'mt-2'}>
            <Button type='primary' htmlType='submit' disabled={disabled}>
              Save
            </Button>
            <Button type='primary' onClick={onCancel} ghost={true}>
              Cancel
            </Button>
          </Space>
        </AppFormActions>
      </Form>
    </AppFormWrapper>
  );
};

OverviewForm.propTypes = {
  onCancel: PropTypes.func,
};

export default OverviewForm;
