export const PER_PAGE_ITEMS = 15;

export const DATE_FORMATS = {
  sm: 'MMM YYYY',
  md: 'MMM DD, YYYY',
  lg: 'MMM DD, YYYY [at] hh:mma',
  full: 'MMMM DD, YYYY',
  '/': 'MM/DD/YYYY',
  '//': 'MM/DD/YYYY hh:mma',
};

export const PAGINATION = {
  ITEM_LIMIT: 15,
  TABLE_ITEM_LIMIT: 5,
  ALL_ITEMS_LIMIT: 6,
};
