import {DatePicker, Form} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import {Controller, useFormContext} from 'react-hook-form';

const {RangePicker} = DatePicker;
const AppFormRangePicker = ({
  label,
  name,
  isRequired,
  classes,
  labelPosition = 'top',
  ...rest
}) => {
  const {control} = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({field: {onChange, onBlur, value}, fieldState}) => (
        <Form.Item
          className={classes?.root}
          labelAlign={labelPosition}
          required={isRequired}
          label={label}
          validateStatus={fieldState.error ? 'error' : ''}
          help={fieldState.error ? fieldState.error.message : ''}>
          <RangePicker
            {...rest}
            onBlur={onBlur}
            onChange={(dates) => onChange(dates)} // update form state
            value={value}
          />
        </Form.Item>
      )}
    />
  );
};

AppFormRangePicker.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  isRequired: PropTypes.bool,
  classes: PropTypes.object,
  labelPosition: PropTypes.oneOf(['left', 'top', 'right']),
};

export default AppFormRangePicker;
