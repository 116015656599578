import {yupResolver} from '@hookform/resolvers/yup';
import DiversityInformationFields from '@register-app/components/form-fields/diversity-information-fields';
import AppCard from '@wieldy/components/AppCard';
import {Button, Form} from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import {FormProvider, useForm} from 'react-hook-form';

import {diversityInfoInitial} from '../../initial-data/diversity-info';
import {diversityInfoSchema} from '../../schemas/diversity-info.schema';

const DiversityInformationForm = ({onContinue, details, setState}) => {
  const methods = useForm({
    defaultValues: diversityInfoInitial,
    resolver: yupResolver(diversityInfoSchema),
  });

  const {handleSubmit, watch, reset} = methods;

  const isSomeChecked = Object.values(watch('diversity_statuses')).some(
    (el) => el.check,
  );
  const isConfirm = watch('confirm_info');

  useEffect(() => {
    if (details) {
      const initValue = {
        ...details,
        diversity_statuses: details.diversity_statuses.reduce((acc, value) => {
          const {key, ...obj} = value;
          acc[key] = {
            check: true,
            values: {
              ...obj,
              dates_of_certification: obj.dates_of_certification.map((el) =>
                moment(el),
              ),
            },
          };
          return acc;
        }, {}),
      };
      reset(initValue);
    }
  }, [details, reset]);

  const onSubmit = (formData) => {
    const payload = {
      ...formData,
      diversity_statuses: Object.entries(formData.diversity_statuses)
        .filter(([, value]) => value.check)
        .map(([key, value]) => ({...value.values, key})),
    };
    setState({diversity_information: payload});
    if (onContinue) onContinue();
  };

  return (
    <AppCard>
      <FormProvider {...methods}>
        <Form onFinish={handleSubmit(onSubmit)}>
          <DiversityInformationFields isSomeChecked={isSomeChecked} />
          <Button
            type='primary'
            htmlType='submit'
            disabled={isSomeChecked && !isConfirm}>
            Continue
          </Button>
        </Form>
      </FormProvider>
    </AppCard>
  );
};

DiversityInformationForm.propTypes = {
  details: PropTypes.object,
  onContinue: PropTypes.func,
  setState: PropTypes.func,
};

export default DiversityInformationForm;
