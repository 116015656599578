import './index.style.less';

import AppDynamicTabs from '@wieldy/components/AppTabs/AppDynamicTabs';
import CandidateHeader from 'app/shared/components/Headers/CandidateHeader';
import {insert} from 'app/utils/helpers/Insert';
import PropTypes from 'prop-types';
import React, {useMemo} from 'react';

import OtherContent from './TabContents/OtherContent';
import TabsContent from './TabContents/OverviewContent';
import SkillsContent from './TabContents/SkillsContent';

const CandidateDetails = ({candidate}) => {
  const tabsData = useMemo(() => {
    let array = [
      {
        key: 'overview',
        title: 'Overview',
        content: <TabsContent candidate={candidate} />,
      },
    ];
    const skills = {
      key: 'skills-rating',
      title: 'Skills & Performance Rating',
      content: <SkillsContent resource={candidate} />,
    };
    const education = {
      key: 'education_lang_info',
      title: 'Education | Languages | More Info',
      content: <OtherContent resource={candidate} />,
    };

    if (candidate?.skills?.length || candidate?.rating_count) {
      array = insert(array, array.length, skills);
    }

    if (
      candidate?.educations?.length ||
      candidate?.languages?.length ||
      candidate?.certifications?.length ||
      candidate?.courses?.length
    ) {
      array = insert(array, array.length, education);
    }

    return array;
  }, [candidate]);

  return (
    <React.Fragment>
      <CandidateHeader candidate={candidate} />
      <div className={'candidate-details'}>
        <AppDynamicTabs tabsData={tabsData} defaultActiveKey='overview' />
      </div>
    </React.Fragment>
  );
};

CandidateDetails.propTypes = {
  candidate: PropTypes.object,
};

export default React.memo(CandidateDetails);
