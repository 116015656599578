import PropTypes from 'prop-types';
import React from 'react';
import {Area, AreaChart, ResponsiveContainer} from 'recharts';

const BaseAreaChart = (props) => {
  let {dataSource, height, children, ...rest} = props;

  return (
    <ResponsiveContainer width='100%' height={height ?? 230}>
      <AreaChart
        data={dataSource}
        margin={{top: 0, right: 0, left: 0, bottom: 0}}>
        {children}
        <Area {...rest} />
      </AreaChart>
    </ResponsiveContainer>
  );
};

BaseAreaChart.propTypes = {
  dataSource: PropTypes.array,
  height: PropTypes.number,
  areaProps: PropTypes.object,
  children: PropTypes.node,
};

export default React.memo(BaseAreaChart);
