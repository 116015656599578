import {Button, Space, Typography} from 'antd';
import useStep from 'app/hooks/useStep';
import PropTypes from 'prop-types';
import {useNavigate} from 'react-router-dom';

const ActivePlacement = ({onFinish, isEdit}) => {
  const navigate = useNavigate();
  const {currentStep, isLoading} = useStep();

  if (currentStep < 5) {
    return (
      <Typography.Text type='secondary'>
        {isEdit ? 'Edit' : 'Create'} new placement
      </Typography.Text>
    );
  }

  if (currentStep === 5) {
    return (
      <Space>
        <Button type='primary' onClick={onFinish} loading={isLoading}>
          {isEdit ? 'Edit' : 'Create'} Placement
        </Button>
        {/*<Button type='primary' ghost onClick={onPreview}>*/}
        {/*  Preview*/}
        {/*</Button>*/}
        <Button onClick={() => navigate(-1)}>Cancel</Button>
      </Space>
    );
  }
};

ActivePlacement.propTypes = {
  onFinish: PropTypes.func,
  onPreview: PropTypes.func,
  isEdit: PropTypes.bool,
};

export default ActivePlacement;
