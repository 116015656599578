import PropTypes from 'prop-types';
import React from 'react';

import Notes from '../../../../../shared/components/Notes/Notes';
import Engagements from '../../../../../shared/components/Tables/Engagements';
import Inquiries from '../../../../../shared/components/Tables/Inquiries';
import Resources from '../../../../../shared/components/Tables/Resources';
import Submissions from '../../../../../shared/components/Tables/Submissions';
import {getQueryParams, TABS} from '../constants';
import AboutSupplier from './AboutSupplier';

const MainContent = ({
  supplier,
  tabView,
  handleSetTabView,
  isShowNotes,
  notesResponse,
  notesTotal,
}) => {
  const {id} = supplier;
  const {isInquiries, isSubmissions, isEngagements, isResource} = tabView;
  return (
    <React.Fragment>
      <div className='mb-6' id='about'>
        <AboutSupplier supplier={supplier} />
      </div>

      <div className={isResource ? 'mb-6' : ''} id='resources'>
        <Resources
          defaultQueryParams={getQueryParams(TABS.RESOURCES, id)}
          handleSetTabView={handleSetTabView}
          isShow={isResource}
          title='Available Resources'
        />
      </div>
      <div className={isInquiries ? 'mb-6' : ''} id='inquiries'>
        <Inquiries
          defaultQueryParams={getQueryParams(TABS.INQUIRIES, id)}
          handleSetTabView={handleSetTabView}
          isShow={isInquiries}
          isHideSupplier={true}
          isHideBilling={true}
        />
      </div>
      <div className={isSubmissions ? 'mb-6' : ''} id='submissions'>
        <Submissions
          defaultQueryParams={getQueryParams(TABS.SUBMISSIONS, id)}
          handleSetTabView={handleSetTabView}
          isShow={isSubmissions}
          isHideSupplier={true}
          isHideBilling={true}
        />
      </div>

      <div className={isEngagements ? 'mb-6' : ''} id='engagements'>
        <Engagements
          defaultQueryParams={getQueryParams(TABS.ENGAGEMENTS, id)}
          handleSetTabView={handleSetTabView}
          isShow={isEngagements}
        />
      </div>
      {isShowNotes && (
        <div className='mb-6' id='notes'>
          <Notes
            entity='supplier_notes'
            primaryId={supplier?.id}
            primaryKey={'supplier'}
            notesResponse={notesResponse}
            notesTotal={notesTotal}
          />
        </div>
      )}
    </React.Fragment>
  );
};

MainContent.propTypes = {
  supplier: PropTypes.object,
  handleSetTabView: PropTypes.func,
  tabView: PropTypes.object,
  isShowNotes: PropTypes.bool,
  notesResponse: PropTypes.object,
  notesTotal: PropTypes.number,
};

export default React.memo(MainContent);
