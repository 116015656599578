import {Descriptions} from 'antd';
import UserPopover from 'app/shared/components/UserPopover';
import PropTypes from 'prop-types';
import React from 'react';

const Overview = ({engagement}) => {
  const {
    hiring_manager,
    account_manager,
    resource_manager,
    hid,
    start_date,
    end_date,
    original_end_date,
    updated_at,
    job_duration,
  } = engagement;

  return (
    <div className='mb-6'>
      <Descriptions
        colon={false}
        labelStyle={{width: '50%'}}
        className='description-root mb-4'
        column={{xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1}}>
        <Descriptions.Item label='Hiring Manager'>
          <UserPopover user={hiring_manager} />
        </Descriptions.Item>
        <Descriptions.Item label='Supplier Contact'>
          <UserPopover user={resource_manager} />
        </Descriptions.Item>
        <Descriptions.Item label='Account Manager'>
          <UserPopover user={account_manager} />
        </Descriptions.Item>
        <Descriptions.Item label='Start Date'>{start_date}</Descriptions.Item>
        <Descriptions.Item label='End Date'>{end_date}</Descriptions.Item>
        <Descriptions.Item label='Duration'>{job_duration}</Descriptions.Item>
        <Descriptions.Item label='Original End Date'>
          {original_end_date}
        </Descriptions.Item>
        <Descriptions.Item label='Engagement ID'>{hid}</Descriptions.Item>
        <Descriptions.Item label='Updated' span={1}>
          {updated_at}
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
};

Overview.propTypes = {
  engagement: PropTypes.object,
};

export default Overview;
