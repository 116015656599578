import {Typography} from 'antd';
import {isJson} from 'app/utils/helpers/isJson';
import PropTypes from 'prop-types';
import React from 'react';

import Editor from '../../components/Editor/Editor';

const About = ({text}) => {
  return (
    <div className='mb-8'>
      <Typography.Title level={4} className={'mb-3'}>
        About
      </Typography.Title>
      <Editor
        readOnly
        initValue={
          text
            ? isJson(text)
              ? JSON.parse(text)
              : [{type: 'paragraph', data: {text}}]
            : []
        }
      />
    </div>
  );
};

About.propTypes = {
  text: PropTypes.object,
};

export default About;
