import AppTable from '@wieldy/components/AppTable';
import {uniqueId} from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import {useGetColumns} from './columns';

const EntriesByTypeTable = ({entriesByType}) => {
  const columns = useGetColumns();
  return (
    <AppTable
      columns={columns}
      rowKey={(record) => record.work_date + uniqueId()}
      dataSource={entriesByType}
      pagination={false}
    />
  );
};

EntriesByTypeTable.propTypes = {
  entriesByType: PropTypes.array,
};

export default EntriesByTypeTable;
