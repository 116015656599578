export const getInputPlaceHolder = (content) => {
  return `Enter ${content}`;
};

export const getSelectPlaceHolder = (content) => {
  return `Select ${content}`;
};

export const getRequiredMessage = (fieldName) => {
  return `${fieldName} is required field`;
};

export const getAddedMessage = (title) => {
  return `ADDED : ${title} added Successfully`;
};

export const getUpdatedMessage = (title) => {
  return `UPDATED : ${title} updated Successfully`;
};

export const getDeletedMessage = (title) => {
  return `DELETED : ${title} deleted Successfully`;
};

export const getUploadMessage = (title) => {
  return `UPLOADED : ${title} uploaded Successfully`;
};

export const getGeneratedMessage = (title) => {
  return `GENERATED : ${title} generated Successfully`;
};

export const getCanceledMessage = (title) => {
  return `UPLOADED : ${title} canceled Successfully`;
};
