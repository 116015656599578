import 'react-slidedown/lib/slidedown.css';
import './index.style.less';

import PropTypes from 'prop-types';
import React from 'react';
import {SlideDown} from 'react-slidedown';

const AppSlideDown = ({open, ...restProps}) => {
  return <SlideDown className='app-slide-down' closed={!open} {...restProps} />;
};

AppSlideDown.propTypes = {
  open: PropTypes.bool,
};

export default AppSlideDown;
