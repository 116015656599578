import './index.style.less';

import {useUser} from 'app/store/features/user/userSlice';
import PropTypes from 'prop-types';
import {ChannelList} from 'stream-chat-react';

import AppChannelList from '../../components/AppChannelList';
import AppChannelListItem from '../../components/AppChannelListItem';
import {ChannelContainer} from '../../components/ChannelContainer/ChannelContainer';
import MessagingChannelListHeader from '../../components/MessagingChannelListHeader/MessagingChannelListHeader';
import {useMobileView} from '../../hooks/useMobileView';

const AppChat = ({channelListConfig, renderChannels}) => {
  const {user} = useUser();

  const toggleMobile = useMobileView();

  const config = {
    ...channelListConfig,
    filters: {
      ...channelListConfig.filters,
      type: 'team',
      members: {$in: [user.id]},
    },
  };

  return (
    <div className='chatContainer'>
      <div className='str-chat messaging__sidebar' id='mobile-channel-list'>
        <MessagingChannelListHeader />
        <ChannelList
          sendChannelsToList
          List={AppChannelList}
          Preview={(props) => (
            <AppChannelListItem {...props} toggleMobile={toggleMobile} />
          )}
          renderChannels={renderChannels}
          {...config}
        />
      </div>
      <div className='chatChannel'>
        <ChannelContainer toggleMobile={toggleMobile} />
      </div>
    </div>
  );
};

AppChat.propTypes = {
  renderChannels: PropTypes.func,
  channelListConfig: PropTypes.object.isRequired,
};

export default AppChat;
