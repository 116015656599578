import {Descriptions, Typography} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

import {BASE_SALARY_PERIOD} from '../../../constants';
import {
  formatBillingRateRage,
  getYesOrNo,
} from '../../../utils/helpers/DataFormatHelper';

const BillingRates = ({resource}) => {
  const amount = resource.isPermanent
    ? formatBillingRateRage(
        resource.base_salary,
        resource.base_salary_min,
        resource.base_salary_max,
      )
    : `${resource.billingRate} USD`;
  return (
    <div className='mb-6'>
      <Typography.Title level={4} className={'mb-3'}>
        {resource.isPermanent ? 'Salary Expectations' : 'Billing Rates'}
      </Typography.Title>
      <Descriptions
        colon={false}
        labelStyle={{width: '50%'}}
        className='description-root'
        column={{xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1}}>
        <Descriptions.Item
          label={resource.isPermanent ? 'Base Salary' : 'Billing Rate'}>
          {amount}
        </Descriptions.Item>
        {resource.isPermanent ? (
          <Descriptions.Item label='Salary Period'>
            {BASE_SALARY_PERIOD[resource.base_salary_period]}
          </Descriptions.Item>
        ) : (
          <Descriptions.Item label='Billing Rate Period'>
            {resource.billing_rate_period}
          </Descriptions.Item>
        )}

        <Descriptions.Item
          label={`Open to ${
            resource.isPermanent ? 'Salary' : 'Rate'
          } Negotiation`}>
          {getYesOrNo(resource.billing_rate_negotiations)}
        </Descriptions.Item>
        {resource.isPermanent && <Descriptions.Item>{}</Descriptions.Item>}
        {!resource.isPermanent && (
          <Descriptions.Item label='Open to Resource Buyout'>
            {getYesOrNo(resource.billing_rate_buy_out)}
          </Descriptions.Item>
        )}
      </Descriptions>
    </div>
  );
};

BillingRates.propTypes = {
  resource: PropTypes.object,
};

export default BillingRates;
