import {yupResolver} from '@hookform/resolvers/yup';
import ServiceRegionsFields from '@register-app/components/form-fields/service-regions-fields';
import {getRegionArgs} from '@register-app/utils/get-region-args';
import AppCard from '@wieldy/components/AppCard';
import {Button, Form} from 'antd';
import PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import {FormProvider, useForm} from 'react-hook-form';

import {getServiceRegionSchema} from '../../schemas/service-region.schema';

const ServiceRegionsForm = ({setState, details, onContinue, supplierType}) => {
  const schemaArgs = getRegionArgs(supplierType);

  const methods = useForm({
    mode: 'all',
    resolver: yupResolver(getServiceRegionSchema(schemaArgs)),
  });
  const {handleSubmit, reset} = methods;

  useEffect(() => {
    if (details) {
      reset(details);
    }
  }, [details, reset]);
  const onSubmit = (formData) => {
    setState({service_regions: formData});
    onContinue && onContinue();
  };

  return (
    <AppCard>
      <FormProvider {...methods}>
        <Form layout='vertical' onFinish={handleSubmit(onSubmit)}>
          <ServiceRegionsFields schemaArgs={schemaArgs} />
          <Button type='primary' htmlType='submit' className='mt-5'>
            Continue
          </Button>
        </Form>
      </FormProvider>
    </AppCard>
  );
};

ServiceRegionsForm.propTypes = {
  details: PropTypes.object,
  setState: PropTypes.func,
  onContinue: PropTypes.func,
  supplierType: PropTypes.string,
};

export default ServiceRegionsForm;
