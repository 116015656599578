import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  id_placement: null,
  requisition: {
    id: undefined,
    job_title: undefined,
  },
  candidate: {
    id: undefined,
    name: undefined,
  },
  details: {},
  jobDetails: {},
};

export const placementSlice = createSlice({
  name: 'placement',
  initialState,
  reducers: {
    setPlacementId: (state, action) => {
      state.id_placement = action.payload;
    },
    setPlacementRequisition: (state, action) => {
      state.requisition = action.payload;
    },
    setPlacementCandidate: (state, action) => {
      state.candidate = action.payload;
    },
    setPlacementDetails: (state, action) => {
      state.details = action.payload;
    },
    setPlacementJobDetails: (state, action) => {
      state.jobDetails = action.payload;
    },
    resetPlacementState: () => initialState,
  },
});

// Action creators are generated for each case reducer function
export const {
  setPlacementRequisition,
  setPlacementCandidate,
  setPlacementDetails,
  setPlacementJobDetails,
  resetPlacementState,
  setPlacementId,
} = placementSlice.actions;

export default placementSlice.reducer;
