import {PAGE_SIZE_OPTIONS} from '@wieldy/constants/PaginationConsts';
import {Button, Divider, Pagination, Select, Typography} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import {RiArrowLeftSLine, RiArrowRightSLine} from 'react-icons/ri';

import AppSelect from '../../dataEntry/AppSelect';

const defaultSortByOptions = [
  {label: 'Alphabetically', value: '+name'},
  {label: 'Sort by latest', value: '-created_at'},
  {label: 'Sort by oldest', value: '+created_at'},
];

const AppHeaderPagination = ({
  total,
  current,
  pageSize,
  onShowSizeChange,
  showSizeChanger,
  sortBy,
  showSortBy,
  hideStartBorder,
  hideDisplayBorder,
  sortByOptions,
  onSortByChange,
  ...props
}) => {
  sortByOptions = (showSortBy && sortByOptions) || defaultSortByOptions;
  const itemRender = (page, type, originalElement) => {
    if (type === 'prev') {
      return (
        <Button className='ant-pagination-item-link'>
          <RiArrowLeftSLine fontSize={24} />
        </Button>
      );
    }
    if (type === 'next') {
      return (
        <Button className='ant-pagination-item-link'>
          <RiArrowRightSLine fontSize={24} />
        </Button>
      );
    }
    return originalElement;
  };

  return (
    <div className='ant-row ant-row-middle'>
      {showSortBy && sortByOptions.length > 0 && (
        <React.Fragment>
          {!hideStartBorder && (
            <Divider
              type={'vertical'}
              style={{margin: '0 16px', height: '28px'}}
            />
          )}
          <Typography.Text className='mr-3 text-sm font-semibold'>
            Sort by
          </Typography.Text>
          <AppSelect
            size={'small'}
            className='select-standard'
            value={sortBy}
            onSelect={onSortByChange}
            style={{minWidth: 120, width: 250}}
            disabled={!total}>
            {sortByOptions.map((option) => (
              <Select.Option
                key={option?.value}
                value={option?.value}
                className={'text-sm'}>
                {option?.label}
              </Select.Option>
            ))}
          </AppSelect>
        </React.Fragment>
      )}

      {showSizeChanger && total > 15 && (
        <React.Fragment>
          {!hideDisplayBorder && (
            <Divider
              type={'vertical'}
              style={{margin: '0 16px', height: '28px'}}
            />
          )}

          <Typography.Text className='mr-3 text-sm font-semibold'>
            Display
          </Typography.Text>
          <AppSelect
            size={'small'}
            className='select-standard'
            value={pageSize}
            onSelect={(pageSize) => onShowSizeChange(current, pageSize)}
            style={{minWidth: 110}}>
            {PAGE_SIZE_OPTIONS.map((option) => (
              <Select.Option key={option} value={option} className={'text-sm'}>
                {option} Per Page
              </Select.Option>
            ))}
          </AppSelect>
        </React.Fragment>
      )}
      <Pagination
        responsive
        className='ml-4'
        itemRender={itemRender}
        total={total}
        current={current}
        pageSize={pageSize}
        {...props}
        simple
      />
    </div>
  );
};

AppHeaderPagination.propTypes = {
  total: PropTypes.number,
  current: PropTypes.number,
  showSizeChanger: PropTypes.bool,
  pageSize: PropTypes.number,
  onShowSizeChange: PropTypes.func,
  sortBy: PropTypes.string,
  showSortBy: PropTypes.bool,
  hideStartBorder: PropTypes.bool,
  hideDisplayBorder: PropTypes.bool,
  sortByOptions: PropTypes.array,
  onSortByChange: PropTypes.func,
};

export default AppHeaderPagination;
