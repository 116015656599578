import AppDynamicTabs from '@wieldy/components/AppTabs/AppDynamicTabs';
import AppPageContainer from '@wieldy/components/pageComponents/AppPageContainer';
import AppPageHeader from '@wieldy/components/pageComponents/AppPageHeader';
import {Button} from 'antd';
import {USER_CREDENTIALS} from 'app/constants/user-creds';
import {useAccountType} from 'app/hooks/useAccountType';
import {setAccountType} from 'app/store/features/user/userSlice';
import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';

import MainContent from './MainContent';

const tabsData = [
  {
    key: 'clients',
    title: 'Clients',
    route: '/accounts/clients',
  },
  {
    key: 'suppliers',
    title: 'Suppliers',
    route: '/accounts/suppliers',
  },
];

const Dashboard = () => {
  const {category} = useParams();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState();
  const dispatch = useDispatch();
  const {isMarketplace} = useAccountType();

  useEffect(() => {
    if (!isMarketplace) {
      dispatch(setAccountType(USER_CREDENTIALS.USER_TYPES.marketplace));
    }
  }, [dispatch, isMarketplace]);

  const activeKey = React.useMemo(() => {
    return category ?? 'clients';
  }, [category]);

  const handleOnCreateAccount = (slug) => navigate(`/accounts/${slug}`);

  return (
    <AppPageContainer
      header={
        <AppPageHeader
          title={`${activeTab?.title}`}
          count={activeTab?.counts}
          category={'Accounts'}
          tabs={
            <AppDynamicTabs
              tabsData={tabsData}
              activeKey={activeKey}
              setActiveTab={setActiveTab}
            />
          }
          extra={
            <React.Fragment>
              {activeKey === 'clients' && (
                <Button
                  type='primary'
                  shape='round'
                  onClick={() => handleOnCreateAccount('add-new-client')}>
                  Add New Client Account
                </Button>
              )}
              {activeKey === 'suppliers' && (
                <Button
                  type='primary'
                  shape='round'
                  onClick={() => handleOnCreateAccount('add-new-supplier')}>
                  Add New Supplier Account
                </Button>
              )}
            </React.Fragment>
          }
        />
      }>
      <MainContent />
    </AppPageContainer>
  );
};

export default Dashboard;
