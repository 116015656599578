import AppLoader from '@wieldy/components/AppLoader';
import {Col, Empty} from 'antd';
import PropTypes from 'prop-types';
import React, {memo, useEffect} from 'react';

import AppGridContainer from '../AppGridContainer';

const defaultColConfig = {
  xs: 24,
  md: 12,
  lg: 12,
  xl: 12,
  xxl: 8,
};

const AppGridView = ({
  data = [],
  renderItem,
  columns,
  loading = false,
  emptyTitle = 'No data',
  isAllPages = false,
}) => {
  useEffect(() => {
    !isAllPages && window.scrollY > 50 && window.scrollTo(0, 0);
  }, [data, isAllPages]);

  if (loading) {
    return <AppLoader style={{minHeight: '30vh'}} />;
  }
  if (Array.isArray(data) && data.length > 0) {
    return (
      <AppGridContainer>
        {data.map((item, index) => (
          <Col key={index} {...defaultColConfig} {...columns}>
            {renderItem(item, index)}
          </Col>
        ))}
      </AppGridContainer>
    );
  } else {
    return (
      <div className='mt-10'>
        <Empty description={emptyTitle} />
      </div>
    );
  }
};

AppGridView.propTypes = {
  data: PropTypes.array.isRequired,
  renderItem: PropTypes.func.isRequired,
  columns: PropTypes.object,
  loading: PropTypes.bool,
  emptyTitle: PropTypes.string,
  isAllPages: PropTypes.bool,
};

export default memo(AppGridView);
