import AppGridView from '@wieldy/components/AppGridView';
import AppLoader from '@wieldy/components/AppLoader';
import AppPageSection from '@wieldy/components/pageComponents/AppPageSection';
import {capitalizedAllWords} from '@wieldy/utils/helpers/StringHelper';
import {Empty} from 'antd';
import {USER_INCLUDES} from 'app/constants';
import {useAccountType} from 'app/hooks/useAccountType';
import SubmissionModal from 'app/modals/SubmissionModal';
import {useGetAllSubmissions} from 'app/services/submissionService';
import ViewAllButton from 'app/shared/buttons/ViewAllButton';
import InquirySubmissionCard from 'app/shared/cards/InquirySubmissionCard';
import PropTypes from 'prop-types';
import React, {useMemo} from 'react';

import {queryParamsList, statuses} from '../constants';

const All = ({userQuery}) => {
  const {isClient} = useAccountType();

  const commonQuery = useMemo(() => {
    const includes = isClient
      ? USER_INCLUDES.supplierIncludeDetails
      : USER_INCLUDES.clientIncludeDetails;
    return {
      ...queryParamsList,
      ...userQuery,
      include: [...queryParamsList.include, ...includes],
    };
  }, [isClient, userQuery]);

  const {isLoading, data: allData} = useGetAllSubmissions(
    {...commonQuery},
    statuses,
  );

  if (isLoading) {
    return <AppLoader />;
  }

  if (allData?.every(({submissions}) => !submissions.length)) {
    return <Empty description='No Submissions' />;
  }

  return (
    !!allData?.length &&
    allData.map(
      ({submissions, total_count, status}) =>
        !!submissions.length && (
          <AppPageSection
            key={status}
            title={`${capitalizedAllWords(
              status.replace('_', ' '),
            )} Submissions`}
            count={total_count}
            extra={
              total_count > 6 && (
                <ViewAllButton category={status} path='submissions' />
              )
            }>
            <AppGridView
              isAllPages={true}
              data={submissions.slice(0, 6)}
              renderItem={(requisition) => (
                <InquirySubmissionCard
                  key={requisition.id}
                  item={new SubmissionModal(requisition)}
                  type='submission'
                />
              )}
            />
          </AppPageSection>
        ),
    )
  );
};

All.propTypes = {
  userQuery: PropTypes.object,
};

All.defaultProps = {
  userQuery: {},
};

export default All;
