import {Menu, Popover, Typography} from 'antd';
import PropTypes from 'prop-types';
import React, {useMemo} from 'react';
import {RiMailLine, RiPhoneLine} from 'react-icons/ri';

const UserPopover = ({user}) => {
  const popoverItems = useMemo(() => {
    const menuItems = [];
    if (user?.email) {
      menuItems.push(
        <Menu.Item key='email' icon={<RiMailLine className={'text-xl'} />}>
          {user.email}
        </Menu.Item>,
      );
    }
    if (user?.phoneNumber) {
      menuItems.push(
        <Menu.Item
          key='phone_number'
          icon={<RiPhoneLine className={'text-xl'} />}>
          {user.phoneNumber}
        </Menu.Item>,
      );
    }
    return menuItems.length > 0 ? <Menu>{menuItems}</Menu> : null;
  }, [user?.email, user?.phoneNumber]);

  if (!user || typeof user !== 'object') {
    return null;
  }

  if (!popoverItems) {
    return null;
  }

  return (
    <Popover content={popoverItems} placement='bottomLeft'>
      <Typography.Link className={'font-semibold'}>
        {user?.name}
      </Typography.Link>
    </Popover>
  );
};

UserPopover.propTypes = {
  user: PropTypes.object,
};

export default UserPopover;
