import {getAssetsUrl} from '@wieldy/utils/helpers/UrlHelper.js';
import {useRole} from 'app/hooks/useRole';
import PropTypes from 'prop-types';
import React from 'react';
import {Link} from 'react-router-dom';

const Logo = ({isMobileView}) => {
  const {isResource} = useRole();
  const renderLogo = () => {
    return (
      <>
        {isMobileView ? (
          <img
            src={getAssetsUrl('images/brand/dark/logo-icon.png')}
            alt='Logo'
            title='Sourcer'
            width={32}
            style={{marginTop: '3px'}}
          />
        ) : (
          <span className='site-name'>
            <img
              src={getAssetsUrl('images/brand/dark/logo.png')}
              alt='Logo'
              title='Sourcer'
              width={'106'}
              style={{marginTop: '-5px'}}
            />
          </span>
        )}
      </>
    );
  };
  return isResource ? (
    <div className='brand-logo'>{renderLogo()}</div>
  ) : (
    <Link to='/' className={'brand-logo'}>
      {renderLogo()}
    </Link>
  );
};
Logo.propTypes = {
  isMobileView: PropTypes.bool,
};
export default React.memo(Logo);
