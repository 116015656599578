import AppCard from '@wieldy/components/AppCard';
import AppTag from '@wieldy/components/AppTag';
import {Row, Typography} from 'antd';
import {useAddBookmarkToSupplier} from 'app/services/clientService';
import {Blocks} from 'app/shared/components';
import Bookmark from 'app/shared/components/Bookmark';
import UserMiniProfile from 'app/shared/components/UserMiniProfile';
import {getUserName} from 'app/utils/helpers/ConvertHID';
import {getCardBottomContent} from 'app/utils/helpers/GetCardBottomContent';
import PropTypes from 'prop-types';
import React from 'react';
import {useNavigate} from 'react-router-dom';

import {getCardItems} from '../constants';

const SupplierCard = ({supplier, showBookmark = false}) => {
  const navigate = useNavigate();
  const mutation = useAddBookmarkToSupplier();

  const clientName = getUserName(supplier?.name, 'SUP-', 'Supplier');

  const handleOnBookmark = React.useCallback(() => {
    mutation.mutate({
      id: supplier?.id,
      isBookmarked: supplier?.bookmarked,
    });
  }, [mutation, supplier?.id, supplier?.bookmarked]);

  const rowItems = React.useMemo(() => getCardItems(supplier), [supplier]);

  const bottomCounts = React.useMemo(
    () =>
      getCardBottomContent(supplier, {
        name: clientName,
        supplier: supplier?.id,
      }),
    [clientName, supplier],
  );

  return (
    <AppCard>
      {showBookmark && (
        <Bookmark
          isBookmarked={supplier?.bookmarked}
          onClick={handleOnBookmark}
          loading={mutation.isLoading}
        />
      )}
      <Row justify={'space-between'} wrap={false} className='mt-3 mb-7'>
        <UserMiniProfile
          name={clientName}
          avatarUrl={supplier?.logo}
          location={supplier?.location}
          rating={supplier?.ratingTotal}
          reviews={supplier?.ratingCount}
          onClick={() => navigate(`/suppliers/${supplier?.id}/view-supplier`)}
        />
      </Row>
      <Blocks.TopCounterBlock items={rowItems.top} />
      <Blocks.ListIconBlock items={rowItems.content} />
      <Row justify={'space-between'} align={'middle'} className='mb-6'>
        <Typography.Title level={5} type={'secondary'} className={'title'}>
          Relationship
          <span className='title-divider' style={{maxWidth: 60}} />
        </Typography.Title>
        <AppTag color={'blue'} shape={'circle'} className={'text-xs'}>
          {supplier?.relationStatus ?? 'N/A'}
        </AppTag>
      </Row>
      <Blocks.BottomCounterBlock items={bottomCounts} />
    </AppCard>
  );
};

SupplierCard.propTypes = {
  supplier: PropTypes.object,
  showBookmark: PropTypes.bool,
  includes: PropTypes.object,
};

export default SupplierCard;
