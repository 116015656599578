import AppTable from '@wieldy/components/AppTable';
import PropTypes from 'prop-types';
import React from 'react';

import {useGetColumns} from './columns';

const FeeTable = ({invoice}) => {
  const columns = useGetColumns();
  const {engagement, placement, ...rest} = invoice;
  const isEngagement = !!engagement;
  const data = [{start_date: placement?.start_date, ...rest}];

  return !isEngagement ? (
    <AppTable columns={columns} dataSource={data} pagination={false} />
  ) : (
    <></>
  );
};

FeeTable.propTypes = {
  invoice: PropTypes.object,
};

export default FeeTable;
