import {
  formatAmount,
  formatTableDate,
} from 'app/utils/helpers/DataFormatHelper';
import React from 'react';

export const getColumns = () => {
  return [
    {
      title: 'Date of Spend',
      dataIndex: 'date_of_spend',
      key: 'date_of_spend',
      render: (spend_of_date) => <span>{formatTableDate(spend_of_date)}</span>,
    },
    {
      title: 'Purpose',
      dataIndex: 'purpose',
      key: 'purpose',
      align: 'left',
      render: (purpose) => <span>{purpose}</span>,
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      render: (amount) => <span>{formatAmount(amount)}</span>,
    },
    {
      title: 'Expense Notes',
      dataIndex: 'notes',
      key: 'notes',
      align: 'left',
      render: (notes) => <span>{notes}</span>,
    },
  ];
};
