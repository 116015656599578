import * as yup from 'yup';
import {array, boolean, object, string} from 'yup';

import {companyDiversityStatuses} from '../constants/company-diversity-statuses';

const diversityStatusSchema = yup.object({
  granting_authority: yup.string().required('Granting authority is required'),
  certificate_number: yup.string().required('Certificate number is required'),
  dates_of_certification: yup
    .array()
    .of(yup.date().required('Each date is required'))
    .length(2, 'There must be a start and end date')
    .required('Certification dates are required'),
});

// Create fields dynamically using context to determine validation
const fields = () => {
  const obj = {};
  companyDiversityStatuses.forEach((el) => {
    obj[el] = yup.object().shape({
      check: yup.boolean(), // Internal `check` boolean field within each status
      values: yup.mixed().when('check', {
        is: true,
        then: () => diversityStatusSchema, // Apply full schema if `check` is true
        otherwise: () => yup.mixed().notRequired(), // Skip validation if `check` is false
      }),
    });
  });
  return obj;
};

// Main schema for the form
export const diversityInfoSchema = yup.object({
  diversity_statuses: yup.object().shape(fields()),
  confirm_info: boolean(),
  diversity_sourcing_practices: object({
    description: array(string()),
    value: string().required('Field is required'),
  }),
});
