export const moveElementToBeginning = (array, id) => {
  if (!id) {
    return array;
  }

  const index = array.findIndex((item) => item.id === id);
  if (index !== -1) {
    const foundElement = array.splice(index, 1)[0];
    array.unshift(foundElement);
  }
  return array;
};
