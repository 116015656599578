import {getSelectPlaceHolder} from '@wieldy/utils/helpers/MessageHelper';

import ApplicantHelper from '../../../utils/ApplicantHelper';
import ClientHelper from '../../../utils/ClientHelper';
import CompanyHelper from '../../../utils/CompanyHelper';
import ResourceHelper from '../../../utils/ResourceHelper';
import SupplierHelper from '../../../utils/SupplierHelper';

export const advancedFilterConfig = {
  supplier_status: {
    label: 'Engagement Status',
    options: SupplierHelper.getEngagementStatus(),
    placeholder: getSelectPlaceHolder('status'),
  },
  supplier_relationship_status: {
    label: 'Supplier Relationship Status',
    options: SupplierHelper.getRelationshipStatus(),
    placeholder: getSelectPlaceHolder('status'),
  },
  supplier_country: {
    label: 'Based In',
    options: ClientHelper.getCountries(),
    placeholder: getSelectPlaceHolder('countries'),
    mode: 'multiple',
  },
  supplier_company_size: {
    label: 'Company Size',
    options: ClientHelper.getEmployeeSize(),
    placeholder: getSelectPlaceHolder('size(s)'),
    mode: 'multiple',
  },
  supplier_diversity_status: {
    label: 'Diversity Status',
    options: SupplierHelper.getDiversityStatus(),
    placeholder: getSelectPlaceHolder('status(es)'),
    mode: 'multiple',
  },
  supplier_completed_engagements: {
    label: '# of Completed Engagements',
    options: CompanyHelper.completedEngagements(
      undefined,
      undefined,
      'supplier_completed_engagements',
    ),
    placeholder: getSelectPlaceHolder('range(s)'),
    hasQuery: true,
    mode: 'multiple',
  },
  supplier_service_focus: {
    label: 'Service Focus',
    options: SupplierHelper.serviceFocuses(),
    placeholder: getSelectPlaceHolder('service(s)'),
    mode: 'multiple',
  },
  supplier_active_clients: {
    label: '# of Active Clients',
    options: SupplierHelper.getActiveClients('supplier_active_clients'),
    placeholder: getSelectPlaceHolder('range(s)'),
    hasQuery: true,
    mode: 'multiple',
  },
  supplier_rating_total: {
    label: 'Performance Rating',
    options: CompanyHelper.getPerformanceRating('supplier_rating_total'),
    placeholder: getSelectPlaceHolder('rating'),
    hasQuery: true,
  },
  supplier_industry: {
    label: 'Industry Focus',
    options: SupplierHelper.getIndustries(),
    placeholder: getSelectPlaceHolder('industry(ies)'),
    mode: 'multiple',
  },
  membership_duration: {
    label: 'Membership Duration',
    options: CompanyHelper.getMembershipDuration('supplier_created_at'),
    placeholder: getSelectPlaceHolder('duration'),
    hasQuery: true,
    mode: 'multiple',
  },
  supplier_available_resources: {
    label: '# of Available Resources',
    options: SupplierHelper.availableResouces('supplier_available_resources'),
    placeholder: getSelectPlaceHolder('range(s)'),
    hasQuery: true,
    mode: 'multiple',
  },
  supplier_engaged_resources: {
    label: '# of Deployed Resources',
    options: CompanyHelper.getDeployedResouces('supplier_engaged_resources'),
    placeholder: getSelectPlaceHolder('range(s)'),
    hasQuery: true,
    mode: 'multiple',
  },
};

export default {
  title: {
    label: 'Title',
    filter: (value) => {
      return {
        query: {
          title: value,
        },
        label: value,
      };
    },
  },
  country: {
    label: 'Location',
    options: ResourceHelper.countries(),
  },
  billing_rate: {
    label: 'Billing Rate',
    options: ResourceHelper.billingRates(),
    hasQuery: true,
  },
  availability: {
    label: 'Availability Status',
    options: ResourceHelper.availabilityStatus(true),
  },
  available_on: {
    label: 'Available On',
    filter(value) {
      return {
        query: {
          'available_on[lte]': value,
        },
        format: value.format('MM/DD/YYYY'),
      };
    },
  },
  years_experience: {
    label: 'Minimum Experience',
    options: ResourceHelper.experience(),
    hasQuery: true,
  },
  education_degree_level: {
    label: 'Minimum Education',
    options: ResourceHelper.degreeLevel(),
    filter: (value) => {
      return {
        query: {
          min_education: value,
        },
        label: value,
      };
    },
  },
  skill_tags: {
    label: 'Skills',
    filter: (value) => {
      const labels = value.map((item) => item.label);
      const tagIds = value.map((item) => item.value);
      return {
        query: {
          skill_tags: tagIds,
        },
        label: labels,
      };
    },
  },
  rating_total: {
    label: 'Performance Rating',
    options: CompanyHelper.getPerformanceRating(),
    hasQuery: true,
  },
  visa_status: {
    label: 'Visa Status',
    options: ResourceHelper.visaStatus(),
  },
  travel_availability: {
    label: 'Travel Availability',
    options: ResourceHelper.travelAvailability(),
  },
  completed_engagements: {
    label: '# of Past Assignments',
    options: CompanyHelper.completedEngagements('assignments', 'At least '),
    hasQuery: true,
  },
  type: {
    label: 'Applicant Type',
    options: ApplicantHelper.applicationType(),
  },
  ...advancedFilterConfig,
};
