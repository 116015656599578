import moment from 'moment/moment';

export const getCurrentWeek = (date) => {
  const startOfWeek = moment(date).locale('en').startOf('isoWeek'); // get the start of the week
  const days = [];
  for (let i = 0; i <= 6; i++) {
    const day = moment(startOfWeek).add(i, 'days');
    days.push({
      [[day.format('dddd')]]: day.format('MMM D, YYYY'),
    });
  }
  return days.map((el) => {
    return Object.entries(el)[0];
  });
};
