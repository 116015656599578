import AppSelect from '@wieldy/components/dataEntry/AppSelect';
import {
  getRequiredMessage,
  getSelectPlaceHolder,
} from '@wieldy/utils/helpers/MessageHelper';
import {Button, Empty, Form, Modal, Space, Typography} from 'antd';
import {useSendInvoice, useUpdateInvoice} from 'app/services/invoiceService';
import {formatUnixDate, getFullName} from 'app/utils/helpers/DataFormatHelper';
import PropTypes from 'prop-types';
import React, {useCallback, useEffect, useMemo} from 'react';
import {RiCloseLine} from 'react-icons/ri';
import {useParams} from 'react-router-dom';

import {DATE_FORMATS} from '../../../../../config/app';

const ModalContent = ({isResend, form, handleSubmit, users}) => {
  useEffect(() => {
    return () => {
      form.resetFields();
    };
  }, [form]);
  return (
    <div className='modal-reason-dropdown mt-5'>
      <Space direction={'vertical'}>
        <Typography.Text type={'secondary'}>
          If you are ready to submit this invoice to the client, select the
          recipient name(s) below and then click{' '}
          {isResend ? 'Resend' : 'Submit'} Invoice button. If you would like to
          reconsider this action, click Cancel.
        </Typography.Text>
        <Form
          layout='vertical'
          className='mt-2'
          form={form}
          onFinish={handleSubmit}>
          <Form.Item
            label={`${isResend ? 'Resend' : 'Submit'} Invoice to:`}
            name='user'
            rules={[{required: true, message: getRequiredMessage('Name(s)')}]}>
            <AppSelect
              notFoundContent={
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description='No name(s)'
                />
              }
              mode='multiple'
              placeholder={getSelectPlaceHolder('name(s)')}
              size={'middle'}
              style={{width: '260px'}}
              options={users}
              labelInValue={true}
            />
          </Form.Item>
        </Form>
      </Space>
    </div>
  );
};

ModalContent.propTypes = {
  handleSubmit: PropTypes.func,
  users: PropTypes.array,
  form: PropTypes.object,
  isResend: PropTypes.bool,
};

const SubmitModal = ({isOpen, handleClose, invoice, isResend}) => {
  const {id} = useParams();
  const {client_billing_address, hiring_manager} = invoice || {
    client_billing_address: null,
    hiring_manager: null,
  };

  const {mutate, isLoading: isMutating} = useUpdateInvoice();
  const {mutate: mutateSend, isLoading: isSending} = useSendInvoice();
  const [form] = Form.useForm();

  const onSend = () => {
    form.submit();
  };

  const params = useMemo(
    () => ({
      invoice_date: formatUnixDate(
        invoice?.created_at,
        DATE_FORMATS['/'],
      ).toString(),
      due_date: invoice?.due_date?.toString(),
    }),
    [invoice?.created_at, invoice?.due_date],
  );

  const handleSubmit = useCallback(
    (values) => {
      const payload = {
        emails: values?.user?.map(({value}) => value),
        ...params,
      };
      if (!isResend) {
        mutate(
          {id: id || invoice.id, status: 'submitted_to_client'},
          {
            onSuccess: () => {
              mutateSend(
                {id, payload},
                {
                  onSuccess: () => {
                    handleClose();
                  },
                },
              );
            },
          },
        );
      } else {
        mutateSend(
          {id: id || invoice.id, payload},
          {
            onSuccess: () => {
              handleClose();
            },
          },
        );
      }
    },
    [params, isResend, mutate, id, invoice.id, mutateSend, handleClose],
  );

  const users = useMemo(() => {
    const allOptions = [
      {
        value: client_billing_address?.email_address,
        label: `${client_billing_address?.contact} (Billing Contact)`,
      },
      {
        value: hiring_manager?.email,
        label: `${getFullName(hiring_manager)} (Hiring Manager)`,
      },
    ];
    if (client_billing_address?.email_address !== hiring_manager?.email) {
      return allOptions;
    }
    return [allOptions[0]];
  }, [
    client_billing_address?.contact,
    client_billing_address?.email_address,
    hiring_manager,
  ]);

  return (
    <Modal
      title={`${isResend ? 'Resend' : 'Submit'} Invoice to Client`}
      open={isOpen}
      onCancel={handleClose}
      closeIcon={<RiCloseLine fontSize={24} />}
      width={720}
      destroyOnClose={true}
      footer={[
        <Button
          type='primary'
          key='submit'
          onClick={onSend}
          loading={isMutating || isSending}>
          {isResend ? 'Resend' : 'Submit'} Invoice
        </Button>,
        <Button
          type='primary'
          ghost
          key='cancel'
          onClick={handleClose}
          loading={isMutating}>
          Cancel
        </Button>,
      ]}>
      <ModalContent
        users={users}
        handleSubmit={handleSubmit}
        form={form}
        isResend={isResend}
      />
    </Modal>
  );
};

SubmitModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  invoice: PropTypes.oneOfType([PropTypes.object, null]),
  isResend: PropTypes.bool,
  title: PropTypes.string,
};

export default SubmitModal;
