import {Descriptions, Typography} from 'antd';
import React from 'react';

const Resource = () => {
  return (
    <React.Fragment>
      <Typography.Title level={4} className={'mb-4'}>
        Resource
      </Typography.Title>
      <Descriptions
        colon={false}
        labelStyle={{width: '50%'}}
        className='description-root mb-4'
        column={{xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1}}>
        <Descriptions.Item label='Resource Profile'>
          <Typography.Link className={'font-semibold'}>
            Julia Gonzalez
          </Typography.Link>
        </Descriptions.Item>
        <Descriptions.Item label='Title'>
          Sr. JavaScript Developer
        </Descriptions.Item>
        <Descriptions.Item label='Location'>Bogota, Columbia</Descriptions.Item>
        <Descriptions.Item label='Local Time Zone'>
          Colombia Standard Time (UTC-05:00)
        </Descriptions.Item>
      </Descriptions>
    </React.Fragment>
  );
};

export default Resource;
