import {Typography} from 'antd';
import useStep from 'app/hooks/useStep';
import EngagementDetailsForm from 'app/pages/main/engagements/CreateEngagement/MainContent/EngagementDetails/EngagementDetailsForm';
import EngagementDetailsView from 'app/pages/main/engagements/CreateEngagement/MainContent/EngagementDetails/EngagementDetailsView';
import React from 'react';

const EngagementDetails = () => {
  const {currentStep} = useStep();
  if (currentStep > 3) {
    return <EngagementDetailsView />;
  }
  if (currentStep === 3) {
    return <EngagementDetailsForm />;
  }
  return (
    <Typography.Text type='secondary'>
      Provide additional information about this engagement
    </Typography.Text>
  );
};

export default EngagementDetails;
