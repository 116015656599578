import 'app/shared/components/WeekEndTimeCard/style.less';

import AppCard from '@wieldy/components/AppCard';
import {AppFormActions, AppFormBody} from '@wieldy/components/AppFormWrapper';
import AppFormWrapper from '@wieldy/components/AppFormWrapper/AppFormWrapper';
//import AppSelect from '@wieldy/components/dataEntry/AppSelect';
import AppDatePicker from '@wieldy/components/dataEntry/AppDatePicker';
import {getRequiredMessage} from '@wieldy/utils/helpers/MessageHelper';
import {Button, Form} from 'antd';
import {setEndDateExpenses} from 'app/store/features/expenses/expensesSlice.js';
import {setEndDate} from 'app/store/features/timecards/timecardsSlice.js';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, {useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';

import {disableTimeDates} from '../../../utils/helpers/DisableTimeDates';

const WeekEndSelectForm = ({onContinue}) => {
  const {pathname} = useLocation();
  const isExpensesPage = pathname.startsWith('/expenses');
  const dispatch = useDispatch();

  const slice = isExpensesPage ? 'expenses' : 'timecards';

  const {disabledDates, engagementStart, engagementEnd} = useSelector(
    (state) => state[slice].temp,
  );

  const onFormSubmit = (formData) => {
    const date = formData.end_date.format('YYYY-MM-DD');
    isExpensesPage
      ? dispatch(setEndDateExpenses(date))
      : dispatch(setEndDate(date));

    if (onContinue) {
      onContinue();
    }
  };

  const currentWeeksDays = React.useMemo(() => {
    const weekDays = [];

    const startWeek = moment().startOf('week');
    for (let i = 1; i <= 7; i++) {
      weekDays.push(moment(startWeek).add(i, 'd'));
    }
    return weekDays;
  }, []);

  const disabledDate = useMemo(
    () => disableTimeDates(disabledDates, engagementStart, engagementEnd),
    [disabledDates, engagementEnd, engagementStart],
  );

  return (
    <AppCard
      current={currentWeeksDays}
      title='Select Week End Date'
      headStyle={{border: 0}}
      bodyStyle={{paddingTop: 0}}>
      <AppFormWrapper>
        <Form layout='vertical' onFinish={onFormSubmit} autoComplete='off'>
          <AppFormBody style={{maxWidth: 280}}>
            <Form.Item
              name='end_date'
              label='Week End Date'
              rules={[
                {
                  required: true,
                  message: getRequiredMessage('Date'),
                },
              ]}
              required>
              <AppDatePicker
                style={{width: '150px'}}
                disabledDate={disabledDate}
              />
            </Form.Item>
          </AppFormBody>
          <AppFormActions>
            <Button type='primary' htmlType='submit' className='mt-1'>
              Continue
            </Button>
          </AppFormActions>
        </Form>
      </AppFormWrapper>
    </AppCard>
  );
};
WeekEndSelectForm.propTypes = {
  onContinue: PropTypes.func,
};
export default WeekEndSelectForm;
