import {createSlice} from '@reduxjs/toolkit';
import {cloneDeep} from 'lodash';
import moment from 'moment';

const initialState = {
  payload: {
    engagement: '',
    end_date: '',
    items: [],
  },
  temp: {
    engagementStart: null,
    isEditMode: false,
    isChangeMode: false,
    titles: {
      company: 'Company',
      resource: 'Resource',
    },
    disabledDates: [],
    initialLogForm: {},
  },
};

export const expensesSlide = createSlice({
  name: 'expenses',
  initialState,
  reducers: {
    setEngagementExpenses: (state, action) => {
      state.payload.engagement = action.payload;
    },
    setEngagementStartExpenses: (state, action) => {
      state.temp.engagementStart = action.payload.start_date;
      state.temp.engagementEnd = action.payload.end_date;
    },
    setEndDateExpenses: (state, action) => {
      state.payload.end_date = action.payload;
    },
    setItemsExpenses: (state, action) => {
      state.payload.items = action.payload;
    },
    addItemExpenses: (state, action) => {
      state.payload.items = [...state.payload.items, action.payload];
    },
    updateItemExpenses: (state, action) => {
      const updatedItem = action.payload;
      const {id, tempId} = updatedItem;
      const items = cloneDeep(state.payload.items);
      state.payload.items = items.map((elem) => {
        if (id) {
          return elem.id === id ? updatedItem : elem;
        } else if (tempId) {
          return elem.tempId === tempId ? updatedItem : elem;
        }
        return elem;
      });
    },
    deleteItemExpenses: (state, action) => {
      const {id, tempId} = action.payload;
      const items = cloneDeep(state.payload.items);
      state.payload.items = items
        .filter((element) =>
          element?.tempId ? element?.tempId !== tempId : true,
        )
        .map((el) => (el?.id !== id ? el : {...el, deleted: true}));
    },
    setTitlesExpenses: (state, action) => {
      state.temp.titles.company = action.payload.client;
      state.temp.titles.resource = action.payload.resource;
    },
    setDisabledExpensesDates: (state, action) => {
      state.temp.disabledDates = action.payload;
    },
    setEditModeExpenses: (state) => {
      state.temp.isEditMode = true;
    },
    setChangeModeExpenses: (state) => {
      state.temp.isChangeMode = true;
    },
    setInitialExpensesForm: (state, action) => {
      const {tempId, id} = action.payload;
      const items = cloneDeep(state.payload.items);
      const init = items.find((element) =>
        id ? element.id === id : element.tempId === tempId,
      );
      if (init) {
        state.temp.initialLogForm = {
          ...init,
          date_of_spend: moment(init.date_of_spend),
        };
      }
    },
    resetExpensesForm: (state) => {
      state.temp.initialLogForm = initialState.temp.initialLogForm;
      state.temp.isChangeMode = false;
    },
    resetExpensesState: () => initialState,
  },
});

// Action creators are generated for each case reducer function
export const {
  setEngagementExpenses,
  setTitlesExpenses,
  setItemsExpenses,
  deleteItemExpenses,
  updateItemExpenses,
  resetExpensesForm,
  setChangeModeExpenses,
  setEndDateExpenses,
  addItemExpenses,
  resetExpensesState,
  setInitialExpensesForm,
  setEditModeExpenses,
  setDisabledExpensesDates,
  setEngagementStartExpenses,
} = expensesSlide.actions;

export default expensesSlide.reducer;
