import InvitationsTable from 'app/pages/manager/administartion/MainContent/Invitations/InvitationsTable';
import UsersTable from 'app/pages/manager/administartion/MainContent/Users/UsersTable';
import React from 'react';
import {useParams} from 'react-router-dom';

const MainContent = () => {
  const {category} = useParams();

  if (category === 'users') {
    return <UsersTable />;
  }
  return <InvitationsTable />;
};

export default MainContent;
