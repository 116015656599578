import AppPaper from '@wieldy/components/AppCard/AppPaper';
import {Col, Row, Typography} from 'antd';
import {splitString} from 'app/utils/helpers/DataFormatHelper';
import PropTypes from 'prop-types';
import React from 'react';

const TopCounterBlock = ({items = [], ...restProps}) => {
  if (!Array.isArray(items) || items.length === 0) {
    return null;
  }
  return (
    <Row gutter={16} className='mb-6' {...restProps}>
      {items.map((item, index) => (
        <Col span={8} key={index} className='text-center'>
          <AppPaper>
            <Typography.Title level={5} className='text-color text-sm'>
              {item?.count}
            </Typography.Title>
            <Typography.Title
              level={5}
              ellipsis
              style={{textTransform: 'capitalize'}}
              className='text-color text-sm mb-0'>
              <span
                dangerouslySetInnerHTML={{
                  __html: splitString(item?.label, 10).join('<br>'),
                }}
              />
            </Typography.Title>
          </AppPaper>
        </Col>
      ))}
    </Row>
  );
};

TopCounterBlock.propTypes = {
  items: PropTypes.array,
};

export default TopCounterBlock;
