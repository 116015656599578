import {Button, Space, Typography} from 'antd';
import {useStatus} from 'app/hooks/useStatus';
import useStep from 'app/hooks/useStep';
import {useCreateInquiry, useUpdateInquiry} from 'app/services/inquiryService';
import {resetInquiryState} from 'app/store/features/inquiry/inquirySlice';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';

const PostInquiry = () => {
  const {inquiryId, inquiryStatus} = useParams();
  const {isResponded} = useStatus(inquiryStatus);
  const {currentStep} = useStep();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    candidates,
    requisition,
    accompanying_message,
    isEditMode,
    statusPage,
    targetId,
  } = useSelector(({inquiry}) => inquiry);
  const isEdit = statusPage !== 'create';

  const {mutate, isLoading} = (
    isEditMode || isEdit ? useUpdateInquiry : useCreateInquiry
  )(isEditMode ? inquiryId : isEdit ? targetId : undefined);
  const onCancelCreate = () => {
    dispatch(resetInquiryState());
    if (isResponded) {
      navigate('/inquiries/in_progress');
    } else {
      navigate('/inquiries/new');
    }
  };
  const onSubmit = () => {
    const mapSupplierResources = candidates.reduce((acc, value) => {
      const supplier = value.supplier.id;
      acc[supplier] = [...(acc[supplier] || []), value];
      return acc;
    }, []);

    const createPayload = Object.entries(mapSupplierResources).map(
      ([, candidates]) => {
        return {
          candidates: candidates.map((candidate) => ({resource: candidate.id})),
          requisition: requisition.id,
          accompanying_message,
        };
      },
    );

    const editPayload = {
      candidates: candidates.map((candidate) => ({resource: candidate.id})),
      accompanying_message,
    };

    const payload = isEditMode || isEdit ? editPayload : createPayload;

    mutate(payload, {
      onSuccess: () => {
        dispatch(resetInquiryState());
        if (isResponded) {
          navigate('/inquiries/in_progress');
        } else {
          navigate('/inquiries/new');
        }
      },
    });
  };

  return (
    <div>
      <Typography.Paragraph>Post the inquiry</Typography.Paragraph>
      {currentStep === 5 && (
        <Space>
          <Button type='primary' onClick={onSubmit} loading={isLoading}>
            {isEditMode ? 'Add Candidates' : 'Submit Inquiry'}
          </Button>
          <Button onClick={onCancelCreate} disabled={isLoading}>
            Cancel
          </Button>
        </Space>
      )}
    </div>
  );
};

export default React.memo(PostInquiry);
