import AppLoader from '@wieldy/components/AppLoader';
import AppPageContainer from '@wieldy/components/pageComponents/AppPageContainer';
import AppPageHeader from '@wieldy/components/pageComponents/AppPageHeader';
import {useEngagement} from 'app/hooks/useEngagement';
import {useRole} from 'app/hooks/useRole';
import NoteEngagement from 'app/shared/components/NotEngagement';
import React, {useMemo} from 'react';
import {useSelector} from 'react-redux';

import NewBlankTimeForm from './MainContent/NewBlankTimeForm';

const CreateBlankTimecard = () => {
  const {temp} = useSelector(({timecards}) => timecards);
  const {titles} = temp;
  const {isResource} = useRole();
  const {data, isLoading, options, onHandleChangeEngagement} = useEngagement();
  const isEmpty = !data?.length;

  const notEngagement = useMemo(
    () => ({
      title: 'Blank timecard cannot be created',
      subtitle: isResource
        ? 'You are not currently assigned to an active engagement'
        : 'There are no resources in your team who are currently assigned to an active engagement',
    }),
    [isResource],
  );

  return (
    <AppPageContainer
      header={
        <AppPageHeader
          backHeader={isEmpty}
          header='main'
          category='Timecard'
          title={titles.company}
          subTitle={titles.resource}
        />
      }>
      {isLoading ? (
        <AppLoader />
      ) : isEmpty ? (
        <NoteEngagement {...notEngagement} />
      ) : (
        <NewBlankTimeForm
          options={options}
          onHandleChangeEngagement={onHandleChangeEngagement}
        />
      )}
    </AppPageContainer>
  );
};

export default CreateBlankTimecard;
