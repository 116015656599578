import {Typography} from 'antd';
import useStep from 'app/hooks/useStep';
import InformationForm from 'app/shared/requisitions/RequisitionSteps/WorksiteTimeline/InformationForm';
import InformationView from 'app/shared/requisitions/RequisitionSteps/WorksiteTimeline/InformationView';
import PropTypes from 'prop-types';
import React, {useMemo} from 'react';

import {getProcessedDate} from '../../../../utils/helpers/DataFormatHelper';

const WorksiteTimings = ({requisitionModal}) => {
  const {currentStep, setStepData, stepData, nextStep} = useStep();
  const startDate = getProcessedDate(stepData?.start_date);

  const endDate = getProcessedDate(stepData?.end_date);

  const initialValuesByType = useMemo(() => {
    if (requisitionModal.isDirectHire) {
      return {
        job_worksite: stepData?.job_worksite,
        job_country: stepData?.job_country?.[0] || undefined,
        city: stepData?.city,
        us_state: stepData?.us_state || undefined,
        travel_requirements: stepData?.travel_requirements,
        start_date: startDate,
        base_salary: stepData?.base_salary,
        base_salary_min: stepData?.base_salary_min,
        base_salary_max: stepData?.base_salary_max,
      };
    }

    return {
      job_worksite: stepData?.job_worksite,
      job_country: stepData?.job_country?.[0] || undefined,
      city: stepData?.city,
      us_state: stepData?.us_state || undefined,
      job_duration: stepData?.job_duration,
      travel_requirements: stepData?.travel_requirements,
      start_end_date: [startDate, endDate],
    };
  }, [stepData, startDate, endDate, requisitionModal]);

  return (
    <React.Fragment>
      <Typography.Paragraph>
        Specify worksite info, job duration and timeline
      </Typography.Paragraph>

      {currentStep > 3 && stepData ? (
        <InformationView requisitionModal={requisitionModal} />
      ) : (
        currentStep === 3 && (
          <InformationForm
            initialValues={initialValuesByType}
            onSave={setStepData}
            onContinue={nextStep}
          />
        )
      )}
    </React.Fragment>
  );
};

WorksiteTimings.propTypes = {
  requisitionModal: PropTypes.object,
};

export default React.memo(WorksiteTimings);
