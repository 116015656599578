import {createSlice} from '@reduxjs/toolkit';
import {getHoursFromItems} from 'app/utils/helpers/GetHoursFromItems.js';
import {cloneDeep} from 'lodash';
import moment from 'moment';

const initialState = {
  payload: {
    engagement: '',
    end_date: '',
    items: [],
  },
  temp: {
    hours: [],
    isEditMode: false,
    isChangeTime: false,
    overtime_eligible: false,
    titles: {
      company: 'Company',
      resource: 'Resource',
    },
    engagementStart: null,
    disabledDates: [],
    groupedItems: {},
    currentGroupedItems: [],
    initialLogForm: {
      engagement_task: undefined,
      work_date: '',
      minutes: '',
      type: undefined,
      notes: '',
    },
  },
};

const arrayMutation = (array, item) => {
  const {id, tempId} = item;
  return array.map((elem) => {
    if (id) {
      return elem.id === id ? item : elem;
    } else if (tempId) {
      return elem.tempId === tempId ? item : elem;
    }
    return elem;
  });
};

export const timecardSlice = createSlice({
  name: 'timecard',
  initialState,
  reducers: {
    setEngagement: (state, action) => {
      const {id, start_date, end_date, job_overtime_eligible} = action.payload;
      state.payload.engagement = id;
      state.temp.engagementStart = start_date;
      state.temp.engagementEnd = end_date;
      state.temp.overtime_eligible = job_overtime_eligible;
    },
    setEndDate: (state, action) => {
      state.payload.end_date = action.payload;
    },
    setItems: (state, action) => {
      const items = action.payload;
      state.payload.items = items;
      const [hours, grouped] = getHoursFromItems(items);
      state.temp.groupedItems = grouped;
      state.temp.hours = hours;
    },
    addItems: (state, action) => {
      const items = [...state.payload.items, ...action.payload];
      state.payload.items = items;
      const [hours, grouped] = getHoursFromItems(items);
      state.temp.groupedItems = grouped;
      state.temp.hours = hours;
    },
    updateItems: (state, action) => {
      const newArray = cloneDeep(state.payload.items);
      const item = action.payload;
      const updatedItems = arrayMutation(
        newArray,
        item,
        state.temp.isChangeTime,
      );
      state.payload.items = updatedItems;
      const [hours, grouped] = getHoursFromItems(updatedItems);
      state.temp.hours = hours;
      state.temp.groupedItems = grouped;
      state.temp.isChangeTime = false;
      state.temp.initialLogForm = initialState.temp.initialLogForm;
    },
    deleteItems: (state, action) => {
      const {id, tempId} = action.payload;
      const items = cloneDeep(state.payload.items);
      const clearItems = items
        .map((item) => {
          if (item.id) {
            return item.id === id ? {...item, deleted: true} : item;
          }
          return item.tempId !== tempId ? item : undefined;
        })
        .filter((el) => !!el);

      state.payload.items = clearItems;
      const [hours, grouped] = getHoursFromItems(clearItems);
      state.temp.hours = hours;
      state.temp.groupedItems = grouped;
      state.temp.isChangeTime = false;
      state.temp.initialLogForm = initialState.temp.initialLogForm;
    },
    setTitles: (state, action) => {
      state.temp.titles.company = action.payload.client;
      state.temp.titles.resource = action.payload.resource;
    },
    setDisabledDates: (state, action) => {
      state.temp.disabledDates = action.payload;
    },
    setEditMode: (state) => {
      state.temp.isEditMode = true;
    },
    setChangeTime: (state) => {
      state.temp.isChangeTime = true;
    },
    setCurrentGroup: (state, action) => {
      state.temp.currentGroupedItems = state.temp.groupedItems[action.payload];
    },
    setInitialLogForm: (state, action) => {
      const item = action.payload;
      if (item) {
        state.temp.initialLogForm = {
          ...item,
          work_date: moment(item.work_date),
          minutes: moment().startOf('day').add(item.minutes, 'minutes'),
        };
      }
    },
    resetLogForm: (state) => {
      state.temp.initialLogForm = initialState.temp.initialLogForm;
      state.temp.isChangeTime = false;
    },
    resetTimeCardState: () => initialState,
  },
});

// Action creators are generated for each case reducer function
export const {
  setEngagement,
  setEndDate,
  setItems,
  updateItems,
  setTitles,
  setDisabledDates,
  setEditMode,
  setChangeTime,
  setInitialLogForm,
  resetTimeCardState,
  resetLogForm,
  deleteItems,
  setCurrentGroup,
  addItems,
} = timecardSlice.actions;

export default timecardSlice.reducer;
