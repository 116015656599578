import {Checkbox} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

const ShowRegion = ({name, items}) => {
  return (
    <div className='d-flex flex-column form-service-view'>
      <Checkbox checked disabled>
        <span style={{color: 'black'}}>{name}</span>
      </Checkbox>
      {items?.length && (
        <Checkbox.Group
          disabled
          style={{
            paddingLeft: 24,
          }}
          className='d-flex flex-column'
          options={items}
          value={items}
        />
      )}
    </div>
  );
};

ShowRegion.propTypes = {
  items: PropTypes.array,
  name: PropTypes.string,
};

export default ShowRegion;
