import {Avatar, Badge, List, Skeleton, Typography} from 'antd';
import {
  getNotificationData,
  ICONS_LIST,
} from 'app/pages/common/Notifications/MainContent/data';
import {useReadNotification} from 'app/services/notificatonService';
import {formatRelativeDate} from 'app/utils/helpers/DataFormatHelper';
import PropTypes from 'prop-types';
import React, {useCallback} from 'react';
import {MdArrowRightAlt} from 'react-icons/md';
import {useNavigate} from 'react-router-dom';

const NotificationItem = ({notification, loading}) => {
  const navigate = useNavigate();
  const {entity, entity_id, text, created_at, read, id} = notification;
  const IconComponent = ICONS_LIST[entity];
  const {buttonName, path} = getNotificationData(entity, entity_id);

  const {mutate, isLoading} = useReadNotification();

  const onClick = useCallback(() => {
    if (read) {
      navigate(path);
    } else {
      mutate([id], {
        onSuccess: () => {
          navigate(path);
        },
      });
    }
  }, [id, mutate, navigate, path, read]);

  return (
    <List.Item
      actions={[
        <Typography.Link
          disabled={isLoading}
          onClick={onClick}
          key='info-link'
          className={'font-semibold text-xs'}>
          {buttonName}
          <MdArrowRightAlt fontSize={18} className='ml-2' />
        </Typography.Link>,
      ]}>
      <Skeleton avatar title={false} loading={loading} active>
        <List.Item.Meta
          onClick={onClick}
          avatar={
            <Badge color='blue' offset={[0, 2]} dot={!read}>
              <Avatar
                className='avatar'
                shape='square'
                size={48}
                icon={<IconComponent fontSize={22} />}
              />
            </Badge>
          }
          title={text}
          description={
            <div className='text-sm'>{formatRelativeDate(created_at)}</div>
          }
        />
      </Skeleton>
    </List.Item>
  );
};

NotificationItem.propTypes = {
  notification: PropTypes.object,
  loading: PropTypes.bool,
};

export default React.memo(NotificationItem);
