import moment from 'moment';

export const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

/**
 * Get Custom Date Time with provided format
 * @param dateObject
 * @param value
 * @param unit
 * @param format
 * @param returnType string
 * @returns {moment.Moment|string|moment.Moment}
 */
export const getCustomDateTime = (
  dateObject,
  value = 0,
  unit = 'days',
  format = 'YYYY-MM-DD',
  returnType = 'date',
) => {
  if (returnType === 'date') {
    if (value === 0) {
      return moment(dateObject).format(format);
    } else {
      return moment(dateObject).add(value, unit).format(format);
    }
  } else {
    if (value === 0) {
      return moment(dateObject);
    } else {
      return moment(dateObject).add(value, unit);
    }
  }
};

/**
 * Change Date string format
 * @param dateString string
 * @param currentFormat string
 * @param newFormat string
 * @returns {string} string
 */
export const changeDateStringFormat = (
  dateString,
  currentFormat = 'YYYY-MM-DD',
  newFormat = 'DD-MM-YYYY',
) => {
  if (dateString) {
    const dateObject = moment(dateString, currentFormat);
    return moment(dateObject).format(newFormat);
  }

  return '--';
};

/**
 * Get formatted date
 * @param dateObject Date Object
 * @param format string
 * @returns {string} string
 */
export const getFormattedDate = (dateObject, format = 'DD-MM-YYYY') => {
  if (dateObject) {
    return moment(dateObject).format(format);
  }
};

/**
 * Get Date object of Date string
 * @param dateString string
 * @param format string
 * @returns {moment.Moment} Date Object
 */
export const getDateObject = (dateString = '', format = '') => {
  if (dateString) {
    return moment(dateString, format);
  }

  return moment();
};

export const getTimeFromNow = (date) => {
  const timestamp = moment(date).format('X');
  const newDate = moment.unix(timestamp);
  return moment(newDate).fromNow();
};

export const getFinancialYearsList = () => {
  const today = new Date();
  const currentMonth = today.getMonth() + 1;
  const currentYear = today.getFullYear();
  const max = currentMonth < 4 ? currentYear - 1 : currentYear;
  const min = 2017;
  const years = [];

  for (let y = max; y >= min; y--) {
    years.push({
      key: `${y}-${(y + 1).toString().substring(2)}`,
      value: `${y} - ${(y + 1).toString().substring(2)}`,
    });
  }
  return years;
};

export const getCurrentFY = () => {
  const today = new Date();
  const currentYear = today.getFullYear();
  const currentMonth = today.getMonth() + 1;
  const nextYear = (currentYear + 1).toString().substring(2);
  const previousYear = currentYear - 1;

  if (currentMonth < 4) {
    return `${previousYear}-${currentYear.toString().substring(2)}`;
  }

  return `${currentYear}-${nextYear}`;
};

/**
 * @returns date as string. e.g '06/26/2023'
 */
export const dateToString = (date) => {
  return date.toLocaleDateString('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
  });
};

/**
 * @returns today date as string. e.g '06/26/2023'
 */
export const getTodayString = () => {
  const today = new Date();
  return dateToString(today);
};

export const subtractDays = (date, days) => {
  const result = new Date(date);
  result.setDate(result.getDate() - days);
  return result;
};

export const addDays = (date, days) => {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

export const beautifyDateString = (dateString) => {
  const date = new Date(dateString);
  const month = monthNames[date.getMonth()];
  const day = date.getDate();
  const year = date.getFullYear();
  return `${month} ${day}, ${year}`;
};
