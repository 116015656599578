import AppGridContainer from '@wieldy/components/AppGridContainer';
import {Col} from 'antd';
import {useAccountType} from 'app/hooks/useAccountType';
import RequisitionModal from 'app/modals/RequisitionModal';
import Notes from 'app/shared/components/Notes/Notes';
import {RequisitionCard} from 'app/shared/requisitions';
import Certifications from 'app/shared/resource/Certifications';
import Courses from 'app/shared/resource/Courses';
import Education from 'app/shared/resource/Education';
import EmploymentHistory from 'app/shared/resource/EmploymentHistory';
import Languages from 'app/shared/resource/Languages';
import PerformanceRating from 'app/shared/resource/PerformanceRating';
import Skills from 'app/shared/resource/Skills';
import Summary from 'app/shared/resource/Summary';
import PropTypes from 'prop-types';
import React from 'react';

const MainContent = ({
  candidate,
  requisition,
  supplier,
  tabView,
  isShowNotes,
  notesResponse,
  notesTotal,
}) => {
  const {isClient, isSupplier} = useAccountType();

  const {
    isSkills,
    isRating,
    isEducation,
    isCourses,
    isCertifications,
    isLanguages,
    isHistory,
  } = tabView;

  return (
    <AppGridContainer>
      <Col xs={24} lg={15} xxl={16}>
        <AppGridContainer>
          <Col sm={24}>
            <Summary
              resource={candidate}
              isCandidate={true}
              isApplicant={!!candidate.applicant}
            />
          </Col>
          {isHistory && (
            <Col sm={24} id='employment'>
              <EmploymentHistory
                history={candidate.resource.employment_history}
              />
            </Col>
          )}
          {isSkills && (
            <Col sm={24} id='skills'>
              <Skills resource={candidate} />
            </Col>
          )}
          {isShowNotes && (
            <Col sm={24} id='notes'>
              <Notes
                entity={'candidate_notes'}
                primaryKey={'candidate'}
                primaryId={candidate.id}
                notesResponse={notesResponse}
                notesTotal={notesTotal}
              />
            </Col>
          )}
        </AppGridContainer>
      </Col>
      <Col xs={24} lg={9} xxl={8}>
        <AppGridContainer>
          {isRating && (
            <Col xs={24} sm={24} md={24} id='performance-rating'>
              <PerformanceRating resource={candidate} />
            </Col>
          )}
          <Col xs={24} sm={24} md={24} id='requisition'>
            <RequisitionCard
              requisition={new RequisitionModal(requisition, supplier)}
              showClient={isSupplier}
              showSupplier={isClient}
              isBookmarked={false}
            />
          </Col>
          {isEducation && (
            <Col xs={24} sm={24} md={24} id='eduction'>
              <Education educations={candidate.educations} />
            </Col>
          )}
          {isCourses && (
            <Col xs={24} sm={24} md={24} id='courses'>
              <Courses courses={candidate.courses} />
            </Col>
          )}
          {isCertifications && (
            <Col xs={24} sm={24} md={24} id='certifications'>
              <Certifications certifications={candidate.certifications} />
            </Col>
          )}
          {isLanguages && (
            <Col xs={24} sm={24} md={24} id='languages'>
              <Languages languages={candidate.languages} />
            </Col>
          )}
        </AppGridContainer>
      </Col>
    </AppGridContainer>
  );
};

MainContent.propTypes = {
  supplier: PropTypes.object,
  requisition: PropTypes.object,
  candidate: PropTypes.object,
  tabView: PropTypes.object,
  isShowNotes: PropTypes.bool,
  notesResponse: PropTypes.object,
  notesTotal: PropTypes.number,
};

export default MainContent;
