import {Modal} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

import ResourceContext from './ResourceContext';

const ResourceProvider = ({children, editable = true}) => {
  const [modalProps, setModalProps] = React.useState({
    visible: false,
    footer: null,
    destroyOnClose: true,
  });

  const updateResource = React.useCallback(() => {}, []);

  const modal = React.useMemo(() => {
    return {
      open: (props) => {
        setModalProps((prev) => ({
          ...prev,
          ...props,
          visible: true,
        }));
      },
      close: (props) => {
        setModalProps((prev) => ({
          ...prev,
          ...props,
          visible: false,
        }));
      },
    };
  }, [setModalProps]);

  const contextValues = React.useMemo(() => {
    return {
      editable,
      modal,
      updateResource,
    };
  }, [editable, modal, updateResource]);

  const {content, ...restModalProps} = modalProps;

  return (
    <ResourceContext.Provider value={contextValues}>
      <Modal {...restModalProps} onCancel={modal.close}>
        {content}
      </Modal>
      {children}
    </ResourceContext.Provider>
  );
};

ResourceProvider.propTypes = {
  children: PropTypes.node,
  editable: PropTypes.bool,
};
export default ResourceProvider;

export const useResource = () => {
  return React.useContext(ResourceContext);
};
