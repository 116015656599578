import {Typography} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import {RiEditLine} from 'react-icons/ri';

const AppStepTitle = ({title, isEdit, onEditClick, ...restProps}) => {
  return (
    <div {...restProps}>
      <Typography.Text>{title}</Typography.Text>
      {isEdit && (
        <Typography.Link className='ml-2' onClick={onEditClick}>
          <RiEditLine fontSize={16} />
        </Typography.Link>
      )}
    </div>
  );
};

AppStepTitle.propTypes = {
  title: PropTypes.string,
  isEdit: PropTypes.bool,
  onEditClick: PropTypes.func,
};

export default AppStepTitle;
