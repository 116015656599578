import AppGridView from '@wieldy/components/AppGridView';
import AppLoader from '@wieldy/components/AppLoader';
import AppPageSection from '@wieldy/components/pageComponents/AppPageSection';
import {Empty} from 'antd';
import SupplierModal from 'app/modals/SupplierModal';
import {useGetAllSuppliers} from 'app/services/supplierService';
import ViewAllButton from 'app/shared/buttons/ViewAllButton';
import {SupplierCard} from 'app/shared/supplier';
import {capitalizeFirstLetter} from 'app/utils/helpers/Capitalize';
import React from 'react';

import {statuses, supplierQueryParams} from '../constants';

const All = () => {
  const {data: allData, isLoading} = useGetAllSuppliers(
    {...supplierQueryParams, sort: ['-created_at']},
    statuses,
  );

  if (isLoading) {
    return <AppLoader />;
  }

  if (allData?.every(({suppliers}) => !suppliers.length)) {
    return <Empty description='No Suppliers' />;
  }

  return (
    !!allData?.length &&
    allData.map(
      ({suppliers, total_count, status}) =>
        !!suppliers.length && (
          <AppPageSection
            key={status}
            title={`${capitalizeFirstLetter(status)} Suppliers`}
            count={total_count}
            extra={
              total_count > 6 && (
                <ViewAllButton category={status} path='suppliers' />
              )
            }>
            <AppGridView
              isAllPages={true}
              data={suppliers.slice(0, 6)}
              renderItem={(supplier) => (
                <SupplierCard
                  key={supplier.id}
                  supplier={new SupplierModal(supplier)}
                  showBookmark={true}
                />
              )}
            />
          </AppPageSection>
        ),
    )
  );
};

export default All;
