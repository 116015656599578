import AppCard from '@wieldy/components/AppCard';
import AppHeaderPagination from '@wieldy/components/AppPagination/AppHeaderPagination';
import AppTable from '@wieldy/components/AppTable';
import AppTag from '@wieldy/components/AppTag/AppTag';
import {getTablePagination} from '@wieldy/utils/helpers/TableHelper';
import {Modal} from 'antd';
import {PAGINATION} from 'app/config/app';
import {useBrowse} from 'app/hooks/useBrowse';
import {getColumns} from 'app/pages/manager/administartion/MainContent/Invitations/InvitationsTable/columns';
import {
  useGetMarketplaceInvitations,
  useMarketplaceAdministrationBulkActions,
} from 'app/services/maketplaceService';
import {getItemsData} from 'app/utils/helpers/GetItemsData';
import React from 'react';

const Invitations = () => {
  const {
    currentPage,
    pageSize,
    sortBy,
    onChangePage,
    onShowSizeChange,
    onSortBy,
  } = useBrowse({
    initPageSize: PAGINATION.TABLE_ITEM_LIMIT,
    initSort: '-created_at',
  });
  const {data, isLoading} = useGetMarketplaceInvitations({
    page: currentPage - 1,
    limit: pageSize,
    include: ['total_count'],
    sort: [sortBy],
  });
  const [items, totalCount] = getItemsData('items', data);

  const [modal, modalContext] = Modal.useModal();

  const mutation = useMarketplaceAdministrationBulkActions();

  const bulkActions = React.useCallback(
    (item) => {
      return [
        {
          title: 'Resend Invitation',
          onClick: () =>
            mutation.mutate({
              action: 'resendInvitation',
              payload: item,
            }),
        },
        {
          title: 'Withdraw Invitation',
          onClick: () =>
            modal.confirm({
              title: 'Withdraw this invitation?',
              onOk: () =>
                mutation.mutate({
                  action: 'deleteMarketplaceInvitation',
                  payload: item,
                }),
              okText: 'Withdraw',
            }),
        },
      ];
    },
    [modal, mutation],
  );

  return (
    <AppCard
      title={
        <React.Fragment>
          Invitations{' '}
          {totalCount > 0 && <AppTag className='ml-1'>{totalCount}</AppTag>}
        </React.Fragment>
      }
      headStyle={{borderBottom: 0}}
      bodyStyle={{paddingTop: 8}}
      extra={
        <AppHeaderPagination
          total={totalCount}
          current={currentPage}
          pageSize={pageSize}
          onChange={onChangePage}
        />
      }>
      {modalContext}
      <AppTable
        loading={isLoading}
        columns={getColumns()}
        bulkActions={bulkActions}
        dataSource={items}
        pagination={getTablePagination(
          pageSize,
          currentPage,
          totalCount,
          onShowSizeChange,
          onChangePage,
        )}
        onSortByChange={onSortBy}
      />
    </AppCard>
  );
};

export default React.memo(Invitations);
