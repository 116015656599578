import React from 'react';
import {Link} from 'react-router-dom';

const PrivacyCenter = () => {
  return (
    <div id='privacy-center'>
      <article className='post'>
        <div className='post-wrapper'>
          <div className='post-header'>
            <h2 className='post-title'>Privacy Center</h2>
            <ul className='post-meta'>
              <li>Effective April 22, 2024</li>
            </ul>
          </div>
          <div className='post-content'>
            <h3>Privacy At Sourcer</h3>

            <p>
              Sourcer understands the importance of data privacy and takes the
              responsibility of handling and securing personal data seriously.
              We focus on incorporating data protection principles throughout
              our platform, program, and services that provide effective data
              privacy measures for Sourcer, its workforce, partners, and users.
            </p>

            <p>
              Sourcer’s Privacy and Information Security teams have carefully
              analyzed applicable privacy laws and regulations and undertaken
              the necessary steps for our compliance with their requirements. We
              provide detailed information about the personal data we collect
              and how we use it in our agreements, in our help articles, and in
              our <Link to='/privacy-policy'>Privacy Policy</Link>.
            </p>

            <p>
              Depending on where you are located, you may have certain rights
              with respect to your personal data, which you can learn about
              below. Regardless of where you call home, you may close your
              account or request the deletion of all personal information we
              have about you at any time.
            </p>

            <p>
              Learn more about how Sourcer is complying with GDPR and the CCPA
              in our <Link to='/privacy-policy'>Privacy Policy</Link>.
            </p>

            <h3>Europe</h3>

            <p>
              The{' '}
              <a
                href='https://gdpr.eu/what-is-gdpr/'
                rel='nofollow noreferrer'
                target='_blank'>
                General Data Protection Regulation (GDPR)
              </a>{' '}
              is a data privacy law that gives residents of the European Union
              (“EU”) more clarity and control over how their personal data is
              used. Personal data is anything that can directly or indirectly
              identify a person, such as a photo, name, bank details, medical
              information, computer IP address, and so on.
            </p>

            <p>
              Under the GDPR, companies are required to be transparent about
              what types of personal data they collect and how they use it, be
              responsible for secure data processing practices, and provide
              notification to customers or data subjects when breaches occur.
            </p>

            <p>
              The{' '}
              <a
                href='https://ico.org.uk/for-organisations/guide-to-data-protection/guide-to-the-general-data-protection-regulation-gdpr/'
                rel='nofollow noreferrer'
                target='_blank'>
                United Kingdom General Data Protection Regulation (UK GDPR)
              </a>{' '}
              is a UK law that is largely based on the GDPR, but went into
              effect in 2021 as a result of the UK’s withdrawal from the EU. In
              conjunction with the{' '}
              <a
                href='https://ico.org.uk/for-organisations/'
                rel='nofollow noreferrer'
                target='_blank'>
                Data Protection Act 2018
              </a>
              , it sets out the key principles, rights and obligations for most
              processing of personal data in the UK.
            </p>

            <p>
              The{' '}
              <a
                href='https://commission.europa.eu/strategy-and-policy/priorities-2019-2024/europe-fit-digital-age/digital-services-act-ensuring-safe-and-accountable-online-environment_en'
                rel='nofollow noreferrer'
                target='_blank'>
                Digital Services Act (DSA)
              </a>{' '}
              is a European law that aims to ensure a safe and accountable
              online environment that went into effect November 16, 2022. For
              the purposes of the DSA, Sourcer has 528 average monthly users in
              the EU as of January 31, 2024.
            </p>

            <h4>Transfer of Data</h4>

            <p>
              With respect to transfers that involve personal data that is
              within the scope of European data protection laws, Sourcer relies
              on{' '}
              <a
                href='https://eur-lex.europa.eu/eli/dec_impl/2021/914/'
                rel='nofollow noreferrer'
                target='_blank'>
                standard contractual clauses
              </a>{' '}
              as a transfer mechanism to reflect relevant compliance
              requirements.
            </p>

            <p>
              We have posted a{' '}
              <Link to='/global-data-processing-agreement'>
                Data Processing Agreement (“DPA”)
              </Link>
              , governing the relationship between the Customer (as defined in
              the DPA) and Sourcer with respect to personal data. Unless
              otherwise agreed to in writing by you and Sourcer, the DPA applies
              to the extent Sourcer processes any personal data for you as a
              controller in your role as a Customer.
            </p>

            <h3>United States</h3>

            <p>
              The data protection landscape in the U.S. is a patchwork of
              regulations, state laws, and other requirements that are currently
              in flux. Sourcer’s Legal team performs ongoing monitoring and
              analysis to determine their application to the personal data we
              handle and conform to their requirements.
            </p>

            <h4>
              {/* eslint-disable-next-line react/no-unescaped-entities */}
              "Sharing" and "Selling" Personal Information
            </h4>

            <p>
              Certain state laws provide rights for individuals to prevent the
              “sharing” and “sale” of their personal information. Sourcer does
              not sell your personal information as the term is commonly
              understood. But we do allow some advertising vendors to use your
              personal information for internet-based marketing that may be
              considered “selling” or “sharing” under those definitions. The
              only means by which Sourcer may “sell” or “share” your personal
              information is with our third-party marketing partners.
            </p>

            <p>
              If you are located in a state that provides this right, you may
              opt out of the “sale” and “sharing” of your personal information
              by emailing us at{' '}
              <a href='mailto:privacyrequests@sourcer.com'>
                privacyrequests@sourcer.com
              </a>
              . This opt-out is specific to the browser on the device, so you
              will need to opt out again if you: 1) later clear your cookies, or
              2) visit this site from a different browser or device.
            </p>

            <h4>Sensitive Personal Information</h4>

            <p>
              State laws have different definitions for personal information
              that is inherently more sensitive or would pose a greater risk of
              harm to the individual if mishandled. Sourcer identifies and
              appropriately handles the data classified as “sensitive personal
              information” or other elevated classification.
            </p>

            <p>
              Some state laws allow individuals to limit the use of their
              sensitive personal information to purposes necessary to perform
              the services. Sourcer imposes this limitation upon itself
              inherently, and only uses the limited sensitive personal
              information it collects to provide, maintain, improve, and secure
              our services.
            </p>

            <h4>How Do I Submit a Data Request?</h4>

            <p>
              Depending on where you are located, you may have certain rights
              with regard to your personal data. These rights may be limited,
              for example, if fulfilling a request would reveal personal data
              about another person, or if you ask us to delete data which we are
              required by law to keep or have compelling legitimate interests in
              keeping (such as fraud prevention purposes or record retention
              requirements under applicable laws). In addition, we typically
              will not remove data you posted publicly or shared with others
              through or on the Service, as neither you nor Sourcer can delete
              all copies of data that have been previously shared with others.
            </p>

            <p>
              If you would like to request to close your account in our system,
              you can do so through our platform. In addition, you can access,
              correct, or delete your personal data by making updates to that
              data through your account. You can also submit a request to us
              regarding your personal data by emailing{' '}
              <a href='mailto:privacyrequests@sourcer.com'>
                privacyrequests@sourcer.com
              </a>
              . Please note that if your data is deleted, then your account may
              become deactivated. If your account is deactivated or you ask to
              close your account, you will no longer be able to use the
              platform.
            </p>

            <h3>Additional Resources</h3>

            <p>
              <Link to='/privacy-policy'>Sourcer Privacy Policy</Link>
            </p>

            <p>
              <Link to='/cookie-policy'>Sourcer Cookie Policy</Link>
            </p>
          </div>
        </div>
      </article>
    </div>
  );
};

export default PrivacyCenter;
