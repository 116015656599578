import {SUPPLIER_RELATIONSHIP_STATUSES} from '../constants';
import BaseModal from './BaseModal';

class SupplierReviewModal extends BaseModal {
  constructor(props) {
    super(props);
    this.id = props?.id ?? null;
    this.name = props?.name;
    this.activeEngagements = props?.client_common_active_engagements ?? null;
    this.totalEngagements = props?.client_common_total_engagements ?? null;
    this.relationshipStatus = props?.relation_status
      ? SUPPLIER_RELATIONSHIP_STATUSES[props?.relation_status]
      : null;
    this.relationshipDuration =
      props?.relation_duration && props?.relation_duration > 0
        ? this.getDuration(props?.relation_duration, 'seconds')
        : 'N/A';
    this.supplierReview = props?.supplier_review
      ? props?.supplier_review
      : null;
    this.reviewBy = props?.review_author
      ? this.getName(props?.review_author)
      : null;
    this.updatedAt = this.supplierReview?.updated_at
      ? this.formatRelativeTime(this.supplierReview?.updated_at)
      : null;
  }

  getName(obj) {
    const firstName = obj?.first_name ?? '';
    const lastName = obj?.last_name ?? '';
    return firstName + ' ' + lastName;
  }
}

export default SupplierReviewModal;
