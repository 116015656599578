import {CATEGORIES} from 'app/constants';

export const statuses = [
  {status: CATEGORIES.NEW, sort: ['-created_at']},
  {status: CATEGORIES.IN_PROGRESS, sort: ['-status_changed_at']},
  {status: CATEGORIES.CLOSED, sort: ['-status_changed_at']},
];
export const queryParamsList = {
  expand: ['requisition', 'client', 'supplier'],
  include: ['total_count', 'candidates'],
};

export const pageParams = {
  all: {
    queryParams: {
      ...queryParamsList,
    },
    title: 'All Candidates',
  },
  new: {
    queryParams: {
      ...queryParamsList,
      status: 'new',
    },
    title: 'New Inquiries',
    label: 'New',
    rowTitle: 'New Inquiries',
    empty: 'No New Inquiries',
  },
  in_progress: {
    queryParams: {
      ...queryParamsList,
      status: 'in_progress',
    },
    title: 'In Progress Inquiries',
    label: 'In Progress',
    rowTitle: 'In Progress Inquiries',
    empty: 'No In Progress Inquiries',
  },
  closed: {
    queryParams: {
      ...queryParamsList,
      status: 'closed',
    },
    title: 'Closed Inquiries',
    label: 'Closed',
    rowTitle: 'Closed Inquiries',
    empty: 'No Closed Inquiries',
  },
};

export const tabsData = [
  {key: 'all', title: 'All', route: '/inquiries/all'},
  {key: 'new', title: 'New', route: '/inquiries/new'},
  {key: 'in_progress', title: 'In Progress', route: '/inquiries/in_progress'},
  {key: 'closed', title: 'Closed', route: '/inquiries/closed'},
];

const defaultViewTabs = [
  {key: 'inquiry', title: 'Inquiry'},
  {key: 'client', title: 'Client'},
  {key: 'requisition', title: 'Requisition'},
];
export const getViewTabsData = (isNotes) => {
  return !isNotes
    ? defaultViewTabs
    : [...defaultViewTabs, {key: 'notes', title: 'Notes'}];
};

export const mapFilterToCategory = {
  new: [
    {label: 'Created date (newest first)', value: '-created_at'},
    {label: 'Created date (oldest first)', value: '+created_at'},
  ],
  in_progress: [
    {
      label: 'Status changed date (newest first)',
      value: '-status_changed_at',
    },
    {
      label: 'Status changed date (oldest first)',
      value: '+status_changed_at',
    },
  ],
  closed: [
    {label: 'Closed date (newest first)', value: '-status_changed_at'},
    {label: 'Closed date (oldest first)', value: '+status_changed_at'},
  ],
};

export const candidateStatus = [
  {
    value: 'interested',
    label: 'Interested',
  },
  {
    value: 'not_interested',
    label: 'Not Interested',
  },
  {
    value: 'to_be_interviewed',
    label: 'To Be Interviewed',
  },
  {
    value: 'interviewed_success',
    label: 'Interviewed - Success',
  },
  {
    value: 'interviewed_fail',
    label: 'Interviewed - Fail',
  },
  {
    value: 'interviewed_on_hold',
    label: 'Interviewed - On Hold',
  },
  {
    value: 'to_be_engaged',
    label: 'To Be Engaged',
  },
];

export const existTableStatus = {
  available: 'Available',
  not_available: 'Not Available',
  engaged: 'Engaged',
  not_engaged: 'Not Engaged',
  proposed: 'Proposed',
  to_be_interviewed: 'To Be Interviewed',
  interviewed_success: 'Interviewed - Success',
  interviewed_fail: 'Interviewed - Fail',
  interviewed_on_hold: 'Interviewed - On Hold',
  to_be_engaged: 'To Be Engaged',
  interested: 'Interested',
  not_interested: 'Not Interested',
};
