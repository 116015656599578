import {Typography} from 'antd';
import useStep from 'app/hooks/useStep';
import React from 'react';

import PlacementDetailForm from './PlacementDetailsForm';
import PlacementDetailView from './PlacementDetailsView';

const PlacementDetails = () => {
  const {currentStep} = useStep();
  if (currentStep > 3) {
    return <PlacementDetailView />;
  }
  if (currentStep === 3) {
    return <PlacementDetailForm />;
  }
  return (
    <Typography.Text type='secondary'>
      Provide additional information about this placement
    </Typography.Text>
  );
};

export default PlacementDetails;
