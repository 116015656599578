import {useRole} from 'app/hooks/useRole';
import {convertHID} from 'app/utils/helpers/ConvertHID';
import {
  formatAmount,
  formatBillingRate,
  formatMinutes,
  formatPayUnits,
  formatTimeType,
} from 'app/utils/helpers/DataFormatHelper';

export const useGetColumns = () => {
  //todo task_id integer
  const {isResource} = useRole();
  const defaultArray = [
    {
      title: 'Work Date',
      dataIndex: 'work_date',
      key: 'work_date',
      render: (work_date) => <span>{work_date}</span>,
      sorter: (a, b) => new Date(a.work_date) - new Date(b.work_date),
      defaultSortOrder: 'ascend',
      showSorterTooltip: false,
    },
    {
      title: 'Hours',
      dataIndex: 'hours',
      key: 'hours',
      render: (_, {minutes}) => {
        return formatMinutes(minutes);
      },
    },
    {
      title: 'Pay Units',
      dataIndex: 'pay_units',
      key: 'pay_units',
      render: (_, {pay_units, billable_task}) =>
        billable_task ? formatPayUnits(pay_units) : formatPayUnits(0),
    },
    {
      title: 'Rate',
      dataIndex: 'rate',
      key: 'rate',
      render: (_, {rate, billable_task}) =>
        billable_task ? formatBillingRate(rate) : formatBillingRate(0),
    },
    {
      title: 'Net Total',
      dataIndex: 'net_total',
      key: 'net_total',
      render: (_, {net_total, billable_task}) =>
        billable_task ? formatAmount(net_total) : formatAmount(0),
    },
    {
      title: 'Time Type',
      dataIndex: 'time_type',
      key: 'time_type',
      render: (timeType) => formatTimeType(timeType),
    },
    {
      title: 'Task ID',
      dataIndex: 'task_id',
      key: 'task_id',
      render: (task_id) => <span>{convertHID(task_id)}</span>,
    },
    {
      title: 'Task Description',
      dataIndex: 'description',
      key: 'description',
      align: 'left',
      width: '25%',
      render: (description) => <span>{description}</span>,
    },
  ];
  return isResource
    ? defaultArray.filter((_, index) => index !== 3 && index !== 4)
    : defaultArray;
};
