import AppLoader from '@wieldy/components/AppLoader';
import AppDynamicTabs from '@wieldy/components/AppTabs/AppDynamicTabs';
import AppPageContainer from '@wieldy/components/pageComponents/AppPageContainer';
import AppPageHeader from '@wieldy/components/pageComponents/AppPageHeader';
import {Button, Tooltip} from 'antd';
import {useNotes} from 'app/hooks/useNotes';
import SupplierModal from 'app/modals/SupplierModal';
import {
  useAddBookmarkToSupplier,
  useGetSupplier,
} from 'app/services/clientService';
import {ViewSupplierHeader} from 'app/shared/supplier';
import NotesBtnIcon from 'assets/icons/NotesBtn';
import clsx from 'clsx';
import React, {useCallback, useMemo, useState} from 'react';
import {RiStarLine, RiStarSFill} from 'react-icons/ri';
import {useParams} from 'react-router-dom';

import {getPrimaryTabs, viewSupplierQuery} from './constants';
import MainContent from './MainContent';

const ViewSupplier = () => {
  const {id} = useParams();
  const {isLoading, data} = useGetSupplier(id, viewSupplierQuery);
  const mutation = useAddBookmarkToSupplier(true);
  const supplier = useMemo(() => new SupplierModal(data), [data]);

  const [tabView, setTabView] = useState({
    isInquiries: false,
    isSubmissions: false,
    isEngagements: false,
    isResource: false,
  });

  const {isInquiries, isSubmissions, isEngagements, isResource} = tabView;

  const handleSetTabView = useCallback((type, value) => {
    setTabView((prev) => ({...prev, [type]: value}));
  }, []);

  const handleOnBookmark = React.useCallback(() => {
    mutation.mutate({
      id: supplier?.id,
      isBookmarked: supplier?.bookmarked,
    });
  }, [mutation, supplier?.id, supplier?.bookmarked]);

  const {
    handleShowNotes,
    isNotes,
    isShowNotes,
    total,
    response: notesResponse,
  } = useNotes('supplier_notes', 'supplier', id);

  if (isLoading) {
    return <AppLoader />;
  }
  return (
    <AppPageContainer
      header={
        <AppPageHeader
          backHeader
          backHeaderActions={
            <Button
              type='text'
              shape='circle'
              loading={mutation.isLoading}
              className={clsx({bookmarked: supplier?.bookmarked ?? false})}
              onClick={handleOnBookmark}
              icon={
                supplier?.bookmarked ? (
                  <RiStarSFill className='text-xxl' />
                ) : (
                  <RiStarLine className='text-xxl' />
                )
              }
            />
          }
          header='inner'
          category='Supplier'
          tabs={
            <AppDynamicTabs
              tabsData={getPrimaryTabs(
                isResource,
                isInquiries,
                isSubmissions,
                isEngagements,
                isNotes,
              )}
              scroll={true}
            />
          }
          extra={
            isNotes || (
              <Tooltip
                overlayStyle={{fontSize: '12px'}}
                title={'Add Internal Note'}>
                <Button
                  type='primary'
                  shape='circle'
                  onClick={handleShowNotes}
                  icon={<NotesBtnIcon />}
                />
              </Tooltip>
            )
          }>
          <ViewSupplierHeader supplier={supplier} />
        </AppPageHeader>
      }>
      <MainContent
        supplier={supplier}
        handleSetTabView={handleSetTabView}
        tabView={tabView}
        isShowNotes={isShowNotes}
        notesResponse={notesResponse}
        notesTotal={total}
      />
    </AppPageContainer>
  );
};

export default ViewSupplier;
