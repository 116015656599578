import AppCard from '@wieldy/components/AppCard';
import AppHeaderPagination from '@wieldy/components/AppPagination/AppHeaderPagination';
import AppTable from '@wieldy/components/AppTable';
import AppTag from '@wieldy/components/AppTag/AppTag';
import {getTablePagination} from '@wieldy/utils/helpers/TableHelper';
import {PAGINATION} from 'app/config/app';
import SupplierReviewModal from 'app/modals/SupplierReviewModal';
import {useGetSupplierReviews} from 'app/services/clientService';
import PropTypes from 'prop-types';
import React, {useCallback} from 'react';
import {useNavigate} from 'react-router-dom';

import {useTableSort} from '../../../../../../hooks/useTableSort';
import {getColumns} from './columns';

const SupplierReviews = ({className}) => {
  const [currentPage, setCurrentPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(PAGINATION.TABLE_ITEM_LIMIT);
  const navigate = useNavigate();

  const {sort, handleSort} = useTableSort({
    ['resource.first_name']: null,
  });

  const onChangeTable = useCallback(
    (a, b, sorter) => {
      if (sorter.columnKey && sorter?.order) {
        handleSort(sorter.columnKey, sorter?.order);
      }
    },
    [handleSort],
  );

  const {isLoading, data} = useGetSupplierReviews({
    page: currentPage - 1,
    limit: pageSize,
    sort,
  });

  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const onShowSizeChange = (current, pageSize) => {
    setCurrentPage(current);
    setPageSize(pageSize);
  };

  const bulkActions = React.useCallback(
    (item) => {
      return [
        {
          title: item?.supplierReview?.id ? 'Manage Review' : 'Add New Review',
          onClick: () =>
            navigate('/administration/supplier/' + item.id + '/review'),
        },
      ];
    },
    [navigate],
  );

  return (
    <AppCard
      title={
        <React.Fragment>
          Supplier Reviews{' '}
          {data?.totalCount > 0 && (
            <AppTag className={'ml-1'}>{data.totalCount}</AppTag>
          )}
        </React.Fragment>
      }
      headStyle={{borderBottom: 0}}
      bodyStyle={{paddingTop: 8}}
      className={className}
      extra={
        <React.Fragment>
          {data?.totalCount > 0 && (
            <AppHeaderPagination
              total={data?.totalCount}
              current={currentPage}
              pageSize={pageSize}
              onChange={onPageChange}
            />
          )}
        </React.Fragment>
      }>
      <AppTable
        bulkActions={bulkActions}
        loading={isLoading}
        columns={getColumns(navigate)}
        onChange={onChangeTable}
        dataSource={
          data?.items
            ? data.items.map((item) => new SupplierReviewModal(item))
            : []
        }
        style={{background: 'none'}}
        pagination={getTablePagination(
          pageSize,
          currentPage,
          data?.totalCount,
          onShowSizeChange,
          onPageChange,
        )}
      />
    </AppCard>
  );
};

SupplierReviews.propTypes = {
  className: PropTypes.string,
};

export default SupplierReviews;
