import AppCard from '@wieldy/components/AppCard';
import {Button, Form, List, Typography} from 'antd';
import useStep from 'app/hooks/useStep';
import Editor from 'app/shared/components/Editor/Editor';
import {setSubmissionMessage} from 'app/store/features/submission/inquirySlice';
import {handleGetLength} from 'app/utils/helpers/GetStringLength';
import PropTypes from 'prop-types';
import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import NoteView from '../NoteView';

const CreateNote = ({title = null}) => {
  const {currentStep} = useStep();

  const [message, setMessage] = useState(null);
  const [form] = Form.useForm();
  const {nextStep} = useStep();
  const dispatch = useDispatch();
  const ref = useRef();

  const {accompanying_message} = useSelector(({submission}) => submission);

  const onFinish = React.useCallback(
    (formdata) => {
      const existData = !!handleGetLength(formdata.text);

      if (formdata?.text) {
        setMessage(existData ? formdata.text : null);
        dispatch(setSubmissionMessage(existData ? formdata.text : null));
      }
      if (nextStep) {
        nextStep();
      }
    },
    [dispatch, nextStep],
  );

  useEffect(() => {
    if (accompanying_message || (message && !accompanying_message)) {
      setMessage(accompanying_message);
    }
  }, [accompanying_message, message]);

  return (
    <React.Fragment>
      {currentStep < 3 && (
        <Typography.Paragraph>
          Optional note to client to accompany this submission
        </Typography.Paragraph>
      )}
      {currentStep > 3 && <NoteView message={message} isCard={true} />}
      {currentStep === 3 && (
        <AppCard>
          {title && (
            <Typography.Title level={5} className={'mb-5'}>
              {title}
            </Typography.Title>
          )}
          <List.Item className={'w-full block p-0'}>
            <Form
              layout='horizontal'
              form={form}
              onFinish={onFinish}
              autoComplete='off'
              className={'mb-2'}>
              <Form.Item
                name='text'
                rules={[
                  {
                    required: false,
                    message: '',
                  },
                ]}
                className={'w-full mb-0'}>
                <Editor
                  isUseAntdform
                  ref={ref}
                  showCounter
                  placeholder={'Type your note here'}
                  initValue={message}
                  maxLength={2000}
                />
              </Form.Item>
              <Button type='primary' htmlType='submit'>
                Continue
              </Button>
            </Form>
          </List.Item>
        </AppCard>
      )}
    </React.Fragment>
  );
};

CreateNote.propTypes = {
  title: PropTypes.string,
};

export default CreateNote;
