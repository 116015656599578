import {createSlice} from '@reduxjs/toolkit';
import {resetRequestHeader, setRequestHeader} from 'app/config/axios';
import {USER_CREDENTIALS} from 'app/constants/user-creds';
import ClientModal from 'app/modals/ClientModal';
import SupplierModal from 'app/modals/SupplierModal';
import {useSelector} from 'react-redux';

import {acceptTerms, fetchUser} from './userThunks';

const initialState = {
  isLoading: true,
  isAccepted: false,
  isAuthenticated: false,
  isMarketplace: false,
  marketplaceTempId: null,
  error: null,
  user: null,
  accounts: [],
  companyModal: {},
  currentAccount: {},
  accountType: null,
  userRole: null,
  userStatus: null,
  chatToken: null,
  clients: [],
  resources: [],
  suppliers: [],
};

export const userSlice = createSlice({
  name: 'user/fetchUser',
  initialState,
  reducers: {
    setIsAccepted: (state) => {
      state.isAccepted = true;
    },
    setAccountType: (state, action) => {
      if (action.payload === USER_CREDENTIALS.USER_TYPES.marketplace) {
        resetRequestHeader('sourcer-supplier');
        resetRequestHeader('sourcer-client');
        resetRequestHeader('sourcer-resource');
        localStorage.removeItem('currentUser');
        const data = state.accounts.find(
          (el) => el.accountType === USER_CREDENTIALS.USER_TYPES.marketplace,
        );
        if (data?.data?.marketplace) {
          const {role, status, permissions, ...rest} = data.data.marketplace;
          state.currentAccount = {
            marketplace: {
              ...rest,
            },
            permissions,
            role,
            status,
          };
        }
        state.accountType = USER_CREDENTIALS.USER_TYPES.marketplace;
      }
    },
    updateUserProfile: (state, action) => {
      state.user = action.payload;
    },
    updateCurrentUser: (state, action) => {
      const Modal =
        state.accountType === 'supplier'
          ? SupplierModal
          : state.accountType === 'client'
          ? ClientModal
          : null;
      state.currentAccount = {
        ...state.currentAccount,
        [state.accountType]: action.payload,
      };
      state.companyModal = new Modal({
        ...state.currentAccount[state.accountType],
        ...action.payload,
      });
    },
    setUserAccount: (state, action) => {
      const {header, id, accountType} = action.payload;
      const headers = [
        'sourcer-supplier',
        'sourcer-client',
        'sourcer-resource',
      ];
      const headersToReset = headers.filter((head) => head !== header);
      headersToReset.forEach((head) => {
        resetRequestHeader(head);
      });
      const data = JSON.stringify({[state.user.id]: {header, id}});
      localStorage.setItem('currentUser', data);
      setRequestHeader(header, id);
      state.accountType = accountType;
    },
    updateCurrentAccount: (state, action) => {
      const Modal =
        state.accountType === 'supplier'
          ? SupplierModal
          : state.accountType === 'client'
          ? ClientModal
          : null;
      state.currentAccount = action.payload;
      state.companyModal = new Modal(action.payload[state.accountType]);
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(acceptTerms.fulfilled, (state, action) => {
      const {data, type} = action.payload;
      state.currentAccount[type] = data;
    });
    builder.addCase(fetchUser.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchUser.rejected, (state, action) => {
      localStorage.removeItem('currentUser');
      resetRequestHeader('sourcer-supplier');
      resetRequestHeader('sourcer-client');
      resetRequestHeader('sourcer-resource');
      state.error = action.error;
      state.isLoading = false;
    });
    builder.addCase(fetchUser.fulfilled, (state, action) => {
      const {
        currentAccount,
        user,
        accounts,
        accountType,
        marketplace,
        userRole,
        userStatus,
        chatToken,
      } = action.payload;
      const {clients, resources, suppliers} = accounts;
      const Modal =
        accountType === 'supplier'
          ? SupplierModal
          : accountType === 'client'
          ? ClientModal
          : null;
      state.isMarketplace = !!marketplace;
      state.marketplaceTempId = marketplace?.id;
      state.user = user;
      state.clients = clients;
      state.resources = resources;
      state.suppliers = suppliers;
      state.userRole = userRole;
      state.userStatus = userStatus;
      state.accountType = accountType;
      state.currentAccount = currentAccount;
      state.accounts = accounts;
      state.isAuthenticated = true;
      state.companyModal = Modal ? new Modal(currentAccount[accountType]) : {};
      state.isLoading = false;
      state.chatToken = chatToken;
    });
  },
});

export const useUser = () => useSelector(({user}) => user);

export const useCurrentUser = () =>
  useSelector(({user}) => {
    const {currentAccount, accountType, userRole} = user;
    if (
      userRole === USER_CREDENTIALS.USER_ROLES.resource &&
      'resource' in currentAccount
    ) {
      return currentAccount[userRole];
    }
    return currentAccount[accountType];
  });

export const useCurrentPermissions = () =>
  useSelector(({user}) => {
    const {currentAccount} = user;
    return currentAccount?.permissions;
  });

// Action creators are generated for each case reducer function
export const {
  updateUserProfile,
  setAccountType,
  setUserAccount,
  setError,
  updateCurrentUser,
  updateCurrentAccount,
} = userSlice.actions;

export default userSlice.reducer;
