import AppCard from '@wieldy/components/AppCard';
import AppHeaderPagination from '@wieldy/components/AppPagination/AppHeaderPagination';
import AppTable from '@wieldy/components/AppTable';
import AppTag from '@wieldy/components/AppTag/AppTag';
import {getTablePagination} from '@wieldy/utils/helpers/TableHelper';
import {PAGINATION} from 'app/config/app';
import {useTableSort} from 'app/hooks/useTableSort';
import RequisitionModal from 'app/modals/RequisitionModal';
import {useGetRequisitions} from 'app/services/requisitionService';
import {getColumns} from 'app/shared/components/Tables/Requisitions/columns';
import PropTypes from 'prop-types';
import React, {useCallback, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';

const Requisitions = ({defaultQueryParams, handleSetTabView, isShow}) => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(PAGINATION.TABLE_ITEM_LIMIT);
  const [sortBy, setSortBy] = useState('alpha');

  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const {sort, handleSort} = useTableSort({
    ['start_date']: 'ascend',
  });

  const onChangeTable = useCallback(
    (a, b, sorter) => {
      if (sorter.columnKey && sorter?.order) {
        handleSort(sorter.columnKey, sorter?.order);
      }
    },
    [handleSort],
  );

  const {isLoading, data} = useGetRequisitions({
    page: currentPage - 1,
    limit: pageSize,
    sort,
    ...defaultQueryParams,
  });
  const {requisitions, total_count} = data || {
    requisitions: [],
    total_count: 0,
  };

  useEffect(() => {
    if (!isLoading && handleSetTabView) {
      handleSetTabView('isRequisition', !!requisitions.length);
    }
  }, [requisitions, isLoading, handleSetTabView]);

  const handleChangePagination = React.useCallback((pageNumber, pageSize) => {
    setCurrentPage(pageNumber);
    if (pageSize) setPageSize(pageSize);
  }, []);

  if (!isShow) {
    return <></>;
  }

  return (
    <AppCard
      title={
        <React.Fragment>
          Requisitions{' '}
          {total_count > 0 && <AppTag className={'ml-1'}>{total_count}</AppTag>}
        </React.Fragment>
      }
      headStyle={{borderBottom: 0}}
      bodyStyle={{paddingTop: 8}}
      extra={
        <AppHeaderPagination
          total={total_count}
          current={currentPage}
          pageSize={pageSize}
          onChange={onPageChange}
          hideStartBorder
          sortByOptions={[
            {label: 'Alphabetically', value: 'alpha'},
            {label: 'Created Date', value: 'date'},
            {label: 'Created By', value: 'owner'},
          ]}
          sortBy={sortBy}
          onSortByChange={setSortBy}
        />
      }>
      <AppTable
        loading={isLoading}
        onChange={onChangeTable}
        columns={getColumns(navigate)}
        dataSource={requisitions.map((r) => new RequisitionModal(r))}
        pagination={getTablePagination(
          pageSize,
          currentPage,
          total_count,
          handleChangePagination,
          handleChangePagination,
        )}
      />
    </AppCard>
  );
};

Requisitions.propTypes = {
  defaultQueryParams: PropTypes.object,
  handleSetTabView: PropTypes.func,
  isShow: PropTypes.bool.isRequired,
};

Requisitions.defaultProps = {
  defaultQueryParams: {},
  isShow: false,
};

export default Requisitions;
