import {message, Modal, Select, Space, Typography} from 'antd';
import propTypes from 'prop-types';
import {useState} from 'react';

const ExpenseRejectModel = ({
  title,
  visible,
  reasons,
  children,
  onOk,
  ...rest
}) => {
  const [reason, setReason] = useState('');

  const handleReasonChange = (reason) => {
    setReason(reason);
  };

  const handleRejectAction = () => {
    const reasonValue = reason?.value ? reason.value : reason;
    if (!reasonValue) {
      message.error('Please select at least one reason');
      return;
    }
    setReason('');
    if (onOk) {
      onOk(reason);
    }
  };

  return (
    <Modal
      title={title}
      visible={visible}
      cancelButtonProps={{type: 'text'}}
      okText='Reject Reason'
      width={720}
      onOk={handleRejectAction}
      destroyOnClose={true}
      {...rest}>
      {children}
      {reasons.length > 0 && (
        <div className='modal-reason-dropdown mt-5 mb-5'>
          <Space direction={'vertical'}>
            <Typography.Text>Reason for Rejecting</Typography.Text>
            <Select
              size={'middle'}
              style={{width: '200px'}}
              value={reason}
              options={reasons}
              labelInValue={true}
              onChange={handleReasonChange}
            />
          </Space>
        </div>
      )}
    </Modal>
  );
};

ExpenseRejectModel.propTypes = {
  title: propTypes.string,
  visible: propTypes.number,
  reasons: propTypes.array,
  children: propTypes.node,
  onOk: propTypes.func,
};

export default ExpenseRejectModel;
