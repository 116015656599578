import AppCard from '@wieldy/components/AppCard';
import AppHeaderPagination from '@wieldy/components/AppPagination/AppHeaderPagination';
import AppTable from '@wieldy/components/AppTable';
import AppTag from '@wieldy/components/AppTag/AppTag';
import {getTablePagination} from '@wieldy/utils/helpers/TableHelper';
import {Modal} from 'antd';
import {PAGINATION} from 'app/config/app';
import {useTableSort} from 'app/hooks/useTableSort';
import ResourceModal from 'app/modals/ResourceModal';
import {useGetResources} from 'app/services/resourceService';
import {useSupplierAdministrationBulkActions} from 'app/services/supplierService';
import React, {useCallback} from 'react';
import {useNavigate} from 'react-router-dom';

import {defEditState, getColumns} from './columns';

const ResourcesTable = () => {
  const navigate = useNavigate();
  const [modal, contextHolder] = Modal.useModal();
  const [currentPage, setCurrentPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(PAGINATION.TABLE_ITEM_LIMIT);

  const {sort, handleSort} = useTableSort({
    ['created_at']: 'descend',
  });

  const onChangeTable = useCallback(
    (a, b, sorter) => {
      if (sorter?.columnKey && sorter?.order) {
        handleSort(sorter.columnKey, sorter.order);
      }
    },
    [handleSort],
  );

  const [editing, setEditing] = React.useState(defEditState);

  const {isLoading, data} = useGetResources({
    expand: ['resource_manager'],
    page: currentPage - 1,
    limit: pageSize,
    sort,
  });
  const {resources, total_count} = data || {
    resources: [],
    total_count: 0,
  };
  const mutation = useSupplierAdministrationBulkActions();

  const handleChangePagination = React.useCallback((pageNumber, pageSize) => {
    setCurrentPage(pageNumber);
    if (pageSize) setPageSize(pageSize);
  }, []);

  const bulkActions = React.useCallback(
    (item) => {
      const isEngaged = item.availability === 'engaged';
      const arr = [
        {
          title: 'View Resource',
          onClick: () => navigate(`/resources/${item.id}/view-resource`),
        },
      ];
      !isEngaged &&
        arr.push({
          title: 'Delete Resource',
          onClick: () =>
            modal.confirm({
              title: `Delete this resource?`,
              onOk: () =>
                mutation.mutate({
                  action: 'deleteResource',
                  payload: {id: item?.id},
                }),
              okText: 'Delete',
            }),
        });
      return arr;
    },
    [modal, mutation, navigate],
  );

  return (
    <AppCard
      title={
        <React.Fragment>
          Resources{' '}
          {total_count > 0 && <AppTag className='ml-1'>{total_count}</AppTag>}
        </React.Fragment>
      }
      headStyle={{borderBottom: 0}}
      bodyStyle={{paddingTop: 0}}
      extra={
        <AppHeaderPagination
          total={total_count}
          current={currentPage}
          pageSize={pageSize}
          onChange={handleChangePagination}
        />
      }>
      {contextHolder}

      <AppTable
        loading={isLoading}
        className={'mb-6'}
        columns={getColumns({
          navigate,
          editing,
          setEditing,
          mutation,
        })}
        bulkActions={bulkActions}
        dataSource={resources.map((item) => new ResourceModal(item))}
        pagination={getTablePagination(
          pageSize,
          currentPage,
          total_count,
          handleChangePagination,
          handleChangePagination,
        )}
        onChange={onChangeTable}
      />
    </AppCard>
  );
};

export default React.memo(ResourcesTable);
