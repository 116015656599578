import {Typography} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

const {Title} = Typography;

const AppPageSection = ({title, count, extra, children, ...restProps}) => {
  return (
    <div className='section-root'>
      <div className='section-header'>
        <Title level={3} type='secondary' className='title'>
          {title}{' '}
          {count && count > 0 ? <span className='ml-2'>({count})</span> : null}
          <span className='title-divider' />
        </Title>
        <div className='section-action'> {extra}</div>
      </div>
      <div {...restProps} className='section-body'>
        {children}
      </div>
    </div>
  );
};

AppPageSection.propTypes = {
  title: PropTypes.string.isRequired,
  count: PropTypes.number,
  extra: PropTypes.node,
  children: PropTypes.node,
};

export default React.memo(AppPageSection);
