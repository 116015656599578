import AppAmountView from '@wieldy/components/helperComponents/AppAmountView';
import {DATE_FORMATS} from 'app/config/app';
import {useAccountType} from 'app/hooks/useAccountType';
import {formatDate} from 'app/utils/helpers/DataFormatHelper';

export const useGetColumns = () => {
  const {isClient} = useAccountType();

  return [
    {
      title: 'Date of Spend',
      dataIndex: 'expense_item',
      key: 'expense_item',
      width: 200,
      align: 'left',
      render: (expenseItem) =>
        formatDate(expenseItem.date_of_spend, DATE_FORMATS['/']),
    },
    {
      title: 'Purpose',
      dataIndex: 'expense_item',
      key: 'expense_item',
      align: 'left',
      render: (expenseItem) => expenseItem.purpose,
    },
    {
      title: 'Total Amount',
      dataIndex: 'total',
      key: 'total',
      width: 140,
      render: (amount) => <AppAmountView amount={amount} />,
    },
    {
      title: 'Tax',
      dataIndex: 'total_tax',
      key: 'total_tax',
      width: 120,
      render: (amount) => <AppAmountView amount={amount} />,
    },
    {
      title: 'Final Billable Amount',
      dataIndex: isClient ? 'client_total_billable' : 'supplier_total_billable',
      key: 'finalAmount',
      align: 'right',
      width: 200,
      render: (amount) => <AppAmountView amount={amount} />,
    },
  ];
};
