import PropTypes from 'prop-types';
import React from 'react';
import {MessageInput, MessageList, Thread, Window} from 'stream-chat-react';

import {TeamChannelHeader} from '../TeamChannelHeader/TeamChannelHeader';
import {ThreadMessageInput} from '../TeamMessageInput/ThreadMessageInput';

export const ChannelInner = ({toggleMobile}) => {
  return (
    <>
      <Window>
        <TeamChannelHeader toggleMobile={toggleMobile} />
        <MessageList
          disableQuotedMessages={true}
          messageActions={['edit', 'delete', 'react', 'reply']}
        />
        <MessageInput grow />
      </Window>
      <Thread
        additionalMessageInputProps={{
          grow: true,
          Input: ThreadMessageInput,
        }}
      />
    </>
  );
};

ChannelInner.propTypes = {
  toggleMobile: PropTypes.func,
};
