import AppSteps from '@wieldy/components/AppSteps';
import AppStep from '@wieldy/components/AppSteps/AppStep';
import AppStepTitle from '@wieldy/components/AppSteps/AppStep/AppStepTitle';
import useStep from 'app/hooks/useStep';
import {resetInvoiceActions} from 'app/store/features/invoice/invoiceSlice';
import {useEffect} from 'react';
import {useDispatch} from 'react-redux';

import SelectPlacement from './SelectPlacement';
import SubmitInvoice from './SubmitInvoice';

const MainContent = () => {
  const {currentStep, goToStep} = useStep();
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(resetInvoiceActions());
    };
  }, [dispatch]);

  return (
    <AppSteps current={currentStep}>
      <AppStep
        title={
          <AppStepTitle
            title='Placement'
            isEdit={currentStep > 1}
            onEditClick={() => goToStep(1)}
          />
        }
        step={1}
        description={<SelectPlacement />}
      />
      <AppStep
        title={<AppStepTitle title='Create Placement invoice' />}
        step={2}
        description={<SubmitInvoice />}
      />
    </AppSteps>
  );
};

export default MainContent;
