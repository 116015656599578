import AppLoader from '@wieldy/components/AppLoader';
import AppPageContainer from '@wieldy/components/pageComponents/AppPageContainer';
import AppPageHeader from '@wieldy/components/pageComponents/AppPageHeader';
import SupplierReviewModal from 'app/modals/SupplierReviewModal';
import {useGetSupplier} from 'app/services/clientService';
import React from 'react';
import {useParams} from 'react-router-dom';

import MainContent from './MainContent';

const AddEditSupplierReview = () => {
  const {id} = useParams();
  const {isFetching, data} = useGetSupplier(id, {
    include: ['supplier_review', 'review_author'],
  });

  if (isFetching) {
    return <AppLoader />;
  }

  const review = new SupplierReviewModal(data);
  return (
    <AppPageContainer
      header={
        <AppPageHeader
          backHeader
          category='Administration / Reviews'
          title={review?.name}
        />
      }>
      <MainContent review={review} />
    </AppPageContainer>
  );
};

export default AddEditSupplierReview;
