import {inquiryAPI} from '../api/inquiryApi';
import useReactQuery, {
  useQueryClient,
  useReactMutation,
} from '../hooks/useReactQuery';

const useGetAllInquiries = (params = {}, statuses = []) => {
  return useReactQuery(
    ['inquiries', params],
    async () => {
      const allPromise = statuses.map(({status, sort}) =>
        inquiryAPI.getInquiries({...params, status, sort}),
      );
      const response = await Promise.all(allPromise);
      return response.map(({data, config}) => ({
        inquiries: data?.data || [],
        total_count: data?.total_count || 0,
        status: config.params.status,
      }));
    },
    {
      enabled: !!statuses.length,
    },
  );
};

const useGetInquiries = (queryParams = {}, options = {}) => {
  return useReactQuery(
    ['inquiry-list', queryParams],
    async () => {
      const {data} = await inquiryAPI.getInquiries(queryParams);
      return {
        inquiries: data?.data || [],
        total_count: data.total_count,
      };
    },
    options,
  );
};

const useGetInquiry = (id, queryParams = {}, options = {}) =>
  useReactQuery(
    ['inquiry', id, queryParams],
    async () => {
      const {data} = await inquiryAPI.getInquiry(id, queryParams);
      return data;
    },
    options,
  );

const useCreateInquiry = () => {
  const client = useQueryClient();
  return useReactMutation(async (payloads) => {
    const promises = payloads.map((payload) => inquiryAPI.postInquiry(payload));
    const data = await Promise.allSettled(promises);
    await client.invalidateQueries();
    return {
      data,
      message: 'Inquiry created successfully',
    };
  });
};

const useUpdateInquiry = (id) => {
  const client = useQueryClient();
  return useReactMutation(async (payload) => {
    const {data} = await inquiryAPI.updateInquiry(id, payload);
    await client.invalidateQueries(['inquiry', id]);
    return data;
  });
};

export {
  useCreateInquiry,
  useGetAllInquiries,
  useGetInquiries,
  useGetInquiry,
  useUpdateInquiry,
};
