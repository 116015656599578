export default {
  english: 'English',
  spanish: 'Spanish',
  arabic: 'Arabic',
  hindi: 'Hindi',
  bengali: 'Bengali',
  portuguese: 'Portuguese',
  russian: 'Russian',
  japanese: 'Japanese',
  punjabi: 'Punjabi',
  german: 'German',
  javanese: 'Javanese',
  wu: 'Wu',
  malay: 'Malay',
  telugu: 'Telugu',
  vietnamese: 'Vietnamese',
  korean: 'Korean',
  french: 'French',
  marathi: 'Marathi',
  tamil: 'Tamil',
  urdu: 'Urdu',
  turkish: 'Turkish',
  italian: 'Italian',
  yue: 'Yue',
  thai: 'Thai',
  gujarati: 'Gujarati',
  jin: 'Jin',
  persian: 'Persian',
  polish: 'Polish',
  hakka: 'Hakka',
  kannada: 'Kannada',
  min_nan: 'Min Nan',
  xiang: 'Xiang',
  malayalam: 'Malayalam',
  sundanese: 'Sundanese',
  hausa: 'Hausa',
  odia: 'Odia',
  burmese: 'Burmese',
  ukrainian: 'Ukrainian',
  bhojpuri: 'Bhojpuri',
  tagalog: 'Tagalog',
  yoruba: 'Yoruba',
  maithili: 'Maithili',
  swahili: 'Swahili',
  uzbek: 'Uzbek',
  sindhi: 'Sindhi',
  amharic: 'Amharic',
  fula: 'Fula',
  romanian: 'Romanian',
  oromo: 'Oromo',
  igbo: 'Igbo',
  azerbaijani: 'Azerbaijani',
  awadhi: 'Awadhi',
  gan_chinese: 'Gan Chinese',
  cebuano: 'Cebuano',
  dutch: 'Dutch',
  kurdish: 'Kurdish',
  serbo_croatian: 'Serbo-Croatian',
  malagasy: 'Malagasy',
  saraiki: 'Saraiki',
  nepali: 'Nepali',
  sinhalese: 'Sinhalese',
  chittagonian: 'Chittagonian',
  zhuang: 'Zhuang',
  khmer: 'Khmer',
  turkmen: 'Turkmen',
  assamese: 'Assamese',
  madurese: 'Madurese',
  somali: 'Somali',
  marwari: 'Marwari',
  magahi: 'Magahi',
  haryanvi: 'Haryanvi',
  hungarian: 'Hungarian',
  chhattisgarhi: 'Chhattisgarhi',
};
