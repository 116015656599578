import {Steps} from 'antd';
import useStep from 'app/hooks/useStep';
import PropTypes from 'prop-types';
import React from 'react';

import StepTitle from './AppStepTitle';

const AppStep = ({
  title,
  step,
  // currentStep,
  // goToStep,
  last,
  ...restProps
}) => {
  const {currentStep, goToStep} = useStep();

  const stepTitle = React.useMemo(() => {
    if (last) {
      return title;
    }
    return (
      <StepTitle
        title={title}
        isEdit={currentStep > step}
        onEditClick={() => goToStep(step)}
      />
    );
  }, [last, title, currentStep, step, goToStep]);

  return <Steps.Step title={stepTitle} {...restProps} />;
};

AppStep.propTypes = {
  icon: PropTypes.node,
  step: PropTypes.number,
  title: PropTypes.node,
  subTitle: PropTypes.node,
  description: PropTypes.node,
  last: PropTypes.bool,
  // goToStep: PropTypes.func,
  currentStep: PropTypes.number,
};

AppStep.defaultProps = {
  last: false,
};

export default AppStep;
