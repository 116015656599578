import AppBorderBox from '@wieldy/components/AppBorderBox';
import {Typography} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

const EngagementStepDetail = ({resourceName}) => {
  return (
    <AppBorderBox
      className='d-inline-flex px-6 mt-3'
      style={{backgroundColor: '#FFF'}}>
      <Typography.Title level={4} className={'mb-0'}>
        {resourceName}
      </Typography.Title>
    </AppBorderBox>
  );
};

EngagementStepDetail.propTypes = {
  resourceName: PropTypes.string,
};

export default EngagementStepDetail;
