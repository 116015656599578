import AppTag from '@wieldy/components/AppTag/AppTag';
import {Col, Row} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

const AppTags = ({items}) => {
  return (
    <Row wrap='wrap' gutter={[10, 10]}>
      {(items || []).map((name, index) => (
        <Col key={index}>
          <AppTag className={'border-none'} shape={'square'} label={name} />
        </Col>
      ))}
    </Row>
  );
};

AppTags.propTypes = {
  items: PropTypes.array,
};

export default AppTags;
