import AppCard from '@wieldy/components/AppCard';
import {Empty} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

const NoteView = ({title, message, isCard}) => {
  if (!isCard) {
    return (
      <>
        <AppCard className='wrapper-read'>
          {message?.length ? (
            <div dangerouslySetInnerHTML={{__html: message}} />
          ) : (
            <Empty description={'No data'} />
          )}
        </AppCard>
      </>
    );
  }

  return (
    <AppCard className='wrapper-read'>
      {title}
      {message?.length ? (
        <div dangerouslySetInnerHTML={{__html: message}} />
      ) : (
        <Empty description={'No data'} />
      )}
    </AppCard>
  );
};

NoteView.propTypes = {
  isCard: PropTypes.bool,
  title: PropTypes.string,
  message: PropTypes.bool,
};
export default NoteView;
