import AppDynamicTabs from '@wieldy/components/AppTabs/AppDynamicTabs';
import AppPageContainer from '@wieldy/components/pageComponents/AppPageContainer';
import AppPageHeader from '@wieldy/components/pageComponents/AppPageHeader';
import {Button} from 'antd';
import {USER_CREDENTIALS} from 'app/constants/user-creds';
import {useAccountType} from 'app/hooks/useAccountType';
import MainContent from 'app/pages/manager/administartion/MainContent';
import {setAccountType} from 'app/store/features/user/userSlice';
import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';

const tabsData = [
  {
    key: 'users',
    title: 'Users',
    route: '/administration/users',
  },
  {
    key: 'invitations',
    title: 'Invitations',
    route: '/administration/invitations',
  },
];

const Admin = () => {
  const {category} = useParams();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState();
  const dispatch = useDispatch();
  const {isMarketplace} = useAccountType();

  useEffect(() => {
    if (!isMarketplace) {
      dispatch(setAccountType(USER_CREDENTIALS.USER_TYPES.marketplace));
    }
  }, [dispatch, isMarketplace]);

  const activeKey = React.useMemo(() => {
    return category ?? 'users';
  }, [category]);

  const handleOnCreateAccount = () => {
    navigate('/administration/users/invite-user');
  };

  return (
    <AppPageContainer
      header={
        <AppPageHeader
          title={`${activeTab?.title}`}
          count={activeTab?.counts}
          category={'Administration'}
          tabs={
            <AppDynamicTabs
              tabsData={tabsData}
              activeKey={activeKey}
              setActiveTab={setActiveTab}
            />
          }
          extra={
            <React.Fragment>
              {category === 'invitations' && (
                <Button
                  type='primary'
                  shape='round'
                  onClick={handleOnCreateAccount}>
                  Invite New User
                </Button>
              )}
            </React.Fragment>
          }
        />
      }>
      <MainContent />
    </AppPageContainer>
  );
};

export default Admin;
