import {Divider, Row, Typography} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import {useNavigate} from 'react-router-dom';

const EngagementCardHeader = ({engagement}) => {
  const navigate = useNavigate();
  const handleLink = (path) => {
    return () => navigate(path);
  };

  return (
    <Row justify='space-between' wrap={false}>
      <div className={'d-flex flex-fill flex-column mb-3'}>
        <Typography.Title
          level={3}
          type='secondary'
          className='title-secondary'>
          Engagement With {engagement.name}
        </Typography.Title>
        <Typography.Title level={4} className={'mt-1 mb-1'} ellipsis>
          <Typography.Link
            onClick={handleLink(
              `/engagements/${engagement?.id}/view-engagement`,
            )}>
            <span>{engagement.resource.name}</span> ::{' '}
            <span>{engagement.requisition.jobTitle}</span>
          </Typography.Link>
        </Typography.Title>
        <Typography.Paragraph className={'text-sm'}>
          <Typography.Text type={'secondary'}>
            Created on {engagement.created_at}
          </Typography.Text>
          <Divider type='vertical' />
          <Typography.Text type={'secondary'}>
            Updated {engagement.updated_relative} ago
          </Typography.Text>
        </Typography.Paragraph>
      </div>
    </Row>
  );
};

EngagementCardHeader.propTypes = {
  engagement: PropTypes.object,
};

export default EngagementCardHeader;
