import AppAmountView from '@wieldy/components/helperComponents/AppAmountView';
import {Typography} from 'antd';
import {DATE_FORMATS} from 'app/config/app';
import {useAccountType} from 'app/hooks/useAccountType';
import {
  formatAmount,
  formatDate,
  formatPayUnits,
  formatTimeType,
} from 'app/utils/helpers/DataFormatHelper';

export const useGetColumns = (title, date) => {
  const {isClient, isSupplier} = useAccountType();

  return [
    {
      title,
      dataIndex: 'timecard',
      key: 'timecard',
      width: 200,
      align: 'left',
      render: () => formatDate(date, DATE_FORMATS['/']),
    },
    {
      title: 'Pay Units',
      dataIndex: 'pay_units',
      key: 'pay_units',
      align: 'left',
      render: (payUnits) => payUnits && formatPayUnits(payUnits),
    },
    {
      title: 'Rate',
      dataIndex: 'rate',
      key: 'rate',
      render: (amount) => amount && <AppAmountView amount={amount} />,
    },
    {
      title: 'Time Type',
      dataIndex: 'type',
      key: 'type',
      render: (type) => (
        <Typography.Text>{formatTimeType(type)}</Typography.Text>
      ),
    },
    {
      title: 'Total Amount',
      dataIndex: 'total',
      key: 'total',
      width: 140,
      render: (amount) => <AppAmountView amount={amount} />,
    },
    ...(isSupplier
      ? [
          {
            title: 'MSP Fee',
            dataIndex: 'supplier_msp_fee',
            key: 'supplier_msp_fee',
            render: (fee) => (
              <Typography.Text>{formatAmount(fee)}</Typography.Text>
            ),
          },
          {
            title: 'Total - MSP',
            dataIndex: 'supplier_subtotal',
            key: 'supplier_subtotal',
            width: 140,
            render: (subtotal) => (
              <Typography.Text>{formatAmount(subtotal)}</Typography.Text>
            ),
          },
        ]
      : []),
    {
      title: 'Tax',
      dataIndex: 'total_tax',
      key: 'total_tax',
      width: 120,
      render: (amount) => <AppAmountView amount={amount} />,
    },
    {
      title: 'Final Billable Amount',
      dataIndex: isClient ? 'client_total_billable' : 'supplier_total_billable',
      key: 'finalAmount',
      align: 'right',
      width: 200,
      render: (amount) => <AppAmountView amount={amount} />,
    },
  ];
};
