import AppGridContainer from '@wieldy/components/AppGridContainer';
import {Col} from 'antd';
import React from 'react';

import BillingAddress from './BillingAddress';
import CompanyInfo from './CompanyInfo';
import FeesPanel from './FeesPanel';
import PrimaryAddress from './PrimaryAddress';

const Company = () => {
  return (
    <AppGridContainer>
      <Col xs={24}>
        <CompanyInfo />
      </Col>

      <Col xs={24} sm={24} md={12}>
        <BillingAddress />
      </Col>

      <Col xs={24} sm={24} md={12}>
        <PrimaryAddress />
        <FeesPanel />
      </Col>
    </AppGridContainer>
  );
};

export default React.memo(Company);
