export default {
  afghanistan: 'Afghanistan',
  aland_islands: 'Åland Islands',
  albania: 'Albania',
  algeria: 'Algeria',
  american_samoa: 'American Samoa',
  andorra: 'Andorra',
  angola: 'Angola',
  anguilla: 'Anguilla',
  antarctica: 'Antarctica',
  antigua_and_barbuda: 'Antigua and Barbuda',
  argentina: 'Argentina',
  armenia: 'Armenia',
  aruba: 'Aruba',
  australia: 'Australia',
  austria: 'Austria',
  azerbaijan: 'Azerbaijan',
  bahamas: 'Bahamas',
  bahrain: 'Bahrain',
  bangladesh: 'Bangladesh',
  barbados: 'Barbados',
  belarus: 'Belarus',
  belgium: 'Belgium',
  belize: 'Belize',
  benin: 'Benin',
  bermuda: 'Bermuda',
  bhutan: 'Bhutan',
  bolivia: 'Bolivia',
  bosnia_and_herzegovina: 'Bosnia and Herzegovina',
  botswana: 'Botswana',
  bouvet_island: 'Bouvet Island',
  brazil: 'Brazil',
  british_indian_ocean_territory: 'British Indian Ocean Territory',
  brunei_darussalam: 'Brunei Darussalam',
  bulgaria: 'Bulgaria',
  burkina_faso: 'Burkina Faso',
  burundi: 'Burundi',
  cambodia: 'Cambodia',
  cameroon: 'Cameroon',
  canada: 'Canada',
  cape_verde: 'Cape Verde',
  cayman_islands: 'Cayman Islands',
  central_ffrican_republic: 'Central African Republic',
  chad: 'Chad',
  chile: 'Chile',
  china: 'China',
  christmas_island: 'Christmas Island',
  cocos_keeling_islands: 'Cocos (Keeling) Islands',
  colombia: 'Colombia',
  comoros: 'Comoros',
  congo: 'Congo',
  congo_the_democratic_republic_of_the: 'Congo, The Democratic Republic of The',
  cook_islands: 'Cook Islands',
  costa_rica: 'Costa Rica',
  cote_divoire: "Cote D'ivoire",
  croatia: 'Croatia',
  cuba: 'Cuba',
  cyprus: 'Cyprus',
  czech_republic: 'Czech Republic',
  denmark: 'Denmark',
  djibouti: 'Djibouti',
  dominica: 'Dominica',
  dominican_republic: 'Dominican Republic',
  ccuador: 'Ecuador',
  egypt: 'Egypt',
  el_salvador: 'El Salvador',
  equatorial_guinea: 'Equatorial Guinea',
  eritrea: 'Eritrea',
  estonia: 'Estonia',
  ethiopia: 'Ethiopia',
  falkland_islands_malvinas: 'Falkland Islands (Malvinas)',
  faroe_islands: 'Faroe Islands',
  fiji: 'Fiji',
  finland: 'Finland',
  france: 'France',
  french_guiana: 'French Guiana',
  french_polynesia: 'French Polynesia',
  french_southern_territories: 'French Southern Territories',
  gabon: 'Gabon',
  gambia: 'Gambia',
  georgia: 'Georgia',
  germany: 'Germany',
  ghana: 'Ghana',
  gibraltar: 'Gibraltar',
  greece: 'Greece',
  greenland: 'Greenland',
  grenada: 'Grenada',
  guadeloupe: 'Guadeloupe',
  guam: 'Guam',
  guatemala: 'Guatemala',
  guernsey: 'Guernsey',
  guinea: 'Guinea',
  guinea_bissau: 'Guinea-bissau',
  guyana: 'Guyana',
  haiti: 'Haiti',
  heard_island_and_mcdonald_islands: 'Heard Island and Mcdonald Islands',
  holy_see_vatican_city_state: 'Holy See (Vatican City State)',
  honduras: 'Honduras',
  hong_kong: 'Hong Kong',
  hungary: 'Hungary',
  iceland: 'Iceland',
  india: 'India',
  indonesia: 'Indonesia',
  iran_islamic_republic_of: 'Iran, Islamic Republic of',
  iraq: 'Iraq',
  ireland: 'Ireland',
  isle_of_man: 'Isle of Man',
  israel: 'Israel',
  italy: 'Italy',
  jamaica: 'Jamaica',
  japan: 'Japan',
  jersey: 'Jersey',
  jordan: 'Jordan',
  kazakhstan: 'Kazakhstan',
  kenya: 'Kenya',
  kiribati: 'Kiribati',
  korea_democratic_peoples_republic_of:
    "Korea, Democratic People's Republic of",
  korea_republic_of: 'Korea, Republic of',
  kuwait: 'Kuwait',
  kyrgyzstan: 'Kyrgyzstan',
  lao_peoples_democratic_republic: "Lao People's Democratic Republic",
  latvia: 'Latvia',
  lebanon: 'Lebanon',
  lesotho: 'Lesotho',
  liberia: 'Liberia',
  libyan_arab_jamahiriya: 'Libyan Arab Jamahiriya',
  liechtenstein: 'Liechtenstein',
  lithuania: 'Lithuania',
  luxembourg: 'Luxembourg',
  macao: 'Macao   ',
  macedonia_the_former_yugoslav_republic_of:
    'Macedonia, The Former Yugoslav Republic of',
  madagascar: 'Madagascar',
  malawi: 'Malawi',
  malaysia: 'Malaysia',
  maldives: 'Maldives',
  mali: 'Mali',
  malta: 'Malta',
  marshall_islands: 'Marshall Islands',
  martinique: 'Martinique',
  mauritania: 'Mauritania',
  mauritius: 'Mauritius',
  mayotte: 'Mayotte',
  mexico: 'Mexico',
  micronesia_federated_states_of: 'Micronesia, Federated States of',
  moldova_republic_of: 'Moldova, Republic of',
  monaco: 'Monaco',
  mongolia: 'Mongolia',
  montenegro: 'Montenegro',
  montserrat: 'Montserrat',
  morocco: 'Morocco',
  mozambique: 'Mozambique',
  myanmar: 'Myanmar',
  namibia: 'Namibia',
  nauru: 'Nauru',
  nepal: 'Nepal',
  netherlands: 'Netherlands',
  netherlands_antilles: 'Netherlands Antilles',
  new_caledonia: 'New Caledonia',
  new_zealand: 'New Zealand',
  nicaragua: 'Nicaragua',
  niger: 'Niger',
  nigeria: 'Nigeria',
  niue: 'Niue',
  norfolk_island: 'Norfolk Island',
  northern_mariana_islands: 'Northern Mariana Islands',
  norway: 'Norway',
  oman: 'Oman',
  pakistan: 'Pakistan',
  palau: 'Palau',
  palestinian_territory_occupied: 'Palestinian Territory, Occupied',
  panama: 'Panama',
  papua_new_guinea: 'Papua New Guinea',
  paraguay: 'Paraguay',
  peru: 'Peru',
  philippines: 'Philippines',
  pitcairn: 'Pitcairn',
  poland: 'Poland',
  portugal: 'Portugal',
  puerto_rico: 'Puerto Rico',
  qatar: 'Qatar',
  reunion: 'Reunion',
  romania: 'Romania',
  russian_federation: 'Russian Federation',
  rwanda: 'Rwanda',
  saint_helena: 'Saint Helena',
  saint_kitts_and_nevis: 'Saint Kitts and Nevis',
  saint_lucia: 'Saint Lucia',
  saint_pierre_and_miquelon: 'Saint Pierre and Miquelon',
  saint_vincent_and_the_grenadines: 'Saint Vincent and The Grenadines',
  samoa: 'Samoa',
  san_marino: 'San Marino',
  sao_tome_and_principe: 'Sao Tome and Principe',
  saudi_arabia: 'Saudi Arabia',
  senegal: 'Senegal',
  serbia: 'Serbia',
  seychelles: 'Seychelles',
  sierra_leone: 'Sierra Leone',
  singapore: 'Singapore',
  slovakia: 'Slovakia',
  slovenia: 'Slovenia',
  solomon_islands: 'Solomon Islands',
  somalia: 'Somalia',
  south_africa: 'South Africa',
  south_georgia_and_the_south_sandwich_islands:
    'South Georgia and The South Sandwich Islands',
  spain: 'Spain',
  sri_lanka: 'Sri Lanka',
  sudan: 'Sudan',
  suriname: 'Suriname',
  svalbard_and_jan_mayen: 'Svalbard and Jan Mayen',
  swaziland: 'Swaziland',
  sweden: 'Sweden',
  switzerland: 'Switzerland',
  syrian_arab_republic: 'Syrian Arab Republic',
  taiwan_roc: 'Taiwan (ROC)',
  tajikistan: 'Tajikistan',
  tanzania_united_republic_of: 'Tanzania, United Republic of',
  thailand: 'Thailand',
  timor_leste: 'Timor-leste',
  togo: 'Togo',
  tokelau: 'Tokelau',
  tonga: 'Tonga',
  trinidad_and_tobago: 'Trinidad and Tobago',
  tunisia: 'Tunisia',
  turkey: 'Turkey',
  turkmenistan: 'Turkmenistan',
  turks_and_caicos_islands: 'Turks and Caicos Islands',
  tuvalu: 'Tuvalu',
  uganda: 'Uganda',
  ukraine: 'Ukraine',
  united_arab_emirates: 'United Arab Emirates',
  united_kingdom: 'United Kingdom',
  united_states: 'United States',
  united_states_minor_outlying_islands: 'United States Minor Outlying Islands',
  uruguay: 'Uruguay',
  uzbekistan: 'Uzbekistan',
  vanuatu: 'Vanuatu',
  venezuela: 'Venezuela',
  viet_nam: 'Viet Nam',
  virgin_islands_british: 'Virgin Islands, British',
  virgin_islands_us: 'Virgin Islands, U.S.',
  wallis_and_futuna: 'Wallis and Futuna',
  western_sahara: 'Western Sahara',
  yemen: 'Yemen',
  zambia: 'Zambia',
  zimbabwe: 'Zimbabwe',
};
