import AppCard from '@wieldy/components/AppCard';
import {AppFormActions, AppFormBody} from '@wieldy/components/AppFormWrapper';
import AppFormWrapper from '@wieldy/components/AppFormWrapper/AppFormWrapper';
import AppGridContainer from '@wieldy/components/AppGridContainer';
import AppDatePicker from '@wieldy/components/dataEntry/AppDatePicker';
import AppInput from '@wieldy/components/dataEntry/AppInput';
import AppInputNumber from '@wieldy/components/dataEntry/AppInputNumber';
import AppSelect from '@wieldy/components/dataEntry/AppSelect';
import AppPageContainer from '@wieldy/components/pageComponents/AppPageContainer';
import AppPageHeader from '@wieldy/components/pageComponents/AppPageHeader';
import {NAME_REGX} from '@wieldy/constants/ValidationConsts';
import {
  getInputPlaceHolder,
  getRequiredMessage,
  getSelectPlaceHolder,
} from '@wieldy/utils/helpers/MessageHelper';
import {Button, Checkbox, Col, Form, Radio, Row, Space} from 'antd';
import {useCreateSupplierResource} from 'app/services/resourceService';
import {CompanyMemberDropdown} from 'app/shared/components/dropdowns';
import {allowPositiveExactNumber} from 'app/utils/helpers/preventNegativeNumber';
import LocationHelper from 'app/utils/LocationHelper';
import ResourceHelper from 'app/utils/ResourceHelper';
import moment from 'moment';
import React, {useCallback, useState} from 'react';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';

const radioOptions = [
  {label: 'Billing Rate', value: 'exact'},
  {label: 'Billing Rate Range', value: 'range'},
];

const CreateResource = () => {
  const [showAvailable, setShowAvailable] = React.useState(false);
  const [isUsCountry, setIsUsCountry] = React.useState(false);
  const [isExistingUser, setIsExistingUser] = React.useState();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const mutation = useCreateSupplierResource();
  const {billing} = useSelector(({resource}) => resource);

  React.useEffect(() => {
    if (billing.billing_rate_min || billing.billing_rate_max) {
      setBillingType('range');
    } else {
      setBillingType('exact');
    }
  }, [billing]);

  const [billingType, setBillingType] = React.useState('exact');

  const handleOnSelectCountry = React.useCallback((country) => {
    setIsUsCountry(country === 'united_states');
  }, []);

  const handleChangeAvailability = React.useCallback((value) => {
    setShowAvailable(value === 'available_soon');
  }, []);

  const handleChangeBillingType = React.useCallback(
    (e) => {
      setBillingType(e.target.value);
      if (e.target.value === 'range') {
        form.setFieldValue('billing_rate', undefined);
      } else {
        form.setFieldValue('billing_rate_min', undefined);
        form.setFieldValue('billing_rate_max', undefined);
      }
    },
    [form],
  );

  const isRange = billingType === 'range';

  const handleOnSubmit = React.useCallback(
    (formdata) => {
      const {existing_user, ...payload} = formdata;
      if (isExistingUser === true && existing_user) {
        payload.user = existing_user;
      }
      mutation.mutate(payload, {
        onSuccess() {
          navigate(-1);
        },
      });
    },
    [isExistingUser, mutation, navigate],
  );

  const [existUsers, setExistUsers] = useState([]);
  const getUsers = useCallback((users) => {
    setExistUsers(users);
  }, []);

  const handleSelectCurrent = useCallback(
    ({key}) => {
      const user = existUsers.find((u) => u.id === key);
      if (user) {
        form.setFieldsValue(user);
      }
    },
    [existUsers, form],
  );

  return (
    <AppPageContainer
      header={
        <AppPageHeader
          title='Add New Resource'
          category='Administration / Resources'
          backHeader
        />
      }>
      <AppCard
        title='Resource Information'
        headStyle={{borderBottom: 0}}
        bodyStyle={{paddingTop: 8}}>
        <AppFormWrapper>
          <Form
            layout='vertical'
            onFinish={handleOnSubmit}
            autoComplete='false'
            form={form}>
            <AppFormBody>
              <AppGridContainer>
                <Col sm={24}>
                  <Space direction={'vertical'}>
                    <Checkbox
                      value={true}
                      onChange={(e) => setIsExistingUser(e.target.checked)}>
                      Is an existing user?
                    </Checkbox>
                    <Form.Item
                      name='existing_user'
                      rules={[
                        {
                          required: isExistingUser,
                          message: getRequiredMessage('Existing user'),
                        },
                      ]}
                      hidden={!isExistingUser}>
                      <CompanyMemberDropdown
                        getValue={getUsers}
                        variant={'supplier'}
                        initialFetch={true}
                        queryParams={{limit: 50}}
                        style={{width: '350px'}}
                        showArrow={true}
                        onSelect={(_, option) => handleSelectCurrent(option)}
                        placeholder={'Select existing user'}
                        filterMembers={(m) => m.role === 'resource'}
                      />
                    </Form.Item>
                  </Space>
                </Col>
                <Col xs={24} sm={12} md={8} lg={6}>
                  <Form.Item
                    name='first_name'
                    label='First Name'
                    autoComplete='off'
                    rules={[
                      {
                        required: true,
                        message: getRequiredMessage('First Name'),
                      },
                      () => ({
                        validator(_, value) {
                          if (!value) {
                            return Promise.resolve();
                          }

                          if (!NAME_REGX.test(value)) {
                            return Promise.reject('Enter a valid First Name!');
                          }

                          return Promise.resolve();
                        },
                      }),
                    ]}>
                    <AppInput placeholder={getInputPlaceHolder('first name')} />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={12} md={8} lg={6}>
                  <Form.Item
                    name='last_name'
                    label='Last Name'
                    autoComplete='off'
                    rules={[
                      {
                        required: true,
                        message: getRequiredMessage('Last Name'),
                      },
                      () => ({
                        validator(_, value) {
                          if (!value) {
                            return Promise.resolve();
                          }

                          if (!NAME_REGX.test(value)) {
                            return Promise.reject('Enter a valid Last Name!');
                          }

                          return Promise.resolve();
                        },
                      }),
                    ]}
                    required>
                    <AppInput placeholder={getInputPlaceHolder('last name')} />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={12} md={8} lg={6}>
                  <Form.Item
                    name='title'
                    label='Title'
                    rules={[
                      {
                        required: true,
                        message: getRequiredMessage('Title'),
                      },
                    ]}
                    required>
                    <AppInput placeholder={getInputPlaceHolder('title')} />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={12} md={8} lg={6}>
                  <Form.Item
                    name='country'
                    label='Country'
                    rules={[
                      {
                        required: true,
                        message: getRequiredMessage('Country'),
                      },
                    ]}
                    required>
                    <AppSelect
                      placeholder={getSelectPlaceHolder('country')}
                      options={LocationHelper.getCountries()}
                      onSelect={handleOnSelectCountry}
                      showSearch={true}
                    />
                  </Form.Item>
                </Col>

                {isUsCountry && (
                  <Col xs={24} sm={12} md={8} lg={6}>
                    <Form.Item
                      name={'us_state'}
                      label={'State'}
                      colon={false}
                      rules={[
                        {
                          required: true,
                          message: getRequiredMessage('State'),
                        },
                      ]}>
                      <AppSelect
                        placeholder={getSelectPlaceHolder('state')}
                        options={LocationHelper.getUsStates()}
                        showSearch={true}
                      />
                    </Form.Item>
                  </Col>
                )}

                <Col xs={24} sm={12} md={8} lg={6}>
                  <Form.Item
                    name={'city'}
                    label={'City'}
                    rules={[
                      {
                        required: true,
                        message: getRequiredMessage('City'),
                      },
                    ]}>
                    <AppInput placeholder={getInputPlaceHolder('city')} />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={12} md={8} lg={6}>
                  <Form.Item
                    name={'resource_manager'}
                    label={'Resource Manager'}
                    rules={[
                      {
                        required: true,
                        message: getRequiredMessage('Resource Manager'),
                      },
                    ]}>
                    <CompanyMemberDropdown
                      variant={'supplier'}
                      placeholder={getSelectPlaceHolder('resource manager')}
                      initialFetch={true}
                      allowClear={true}
                      showArrow={true}
                      filterMembers={(m) => m.role !== 'resource'}
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={12} md={8} lg={6}>
                  <Form.Item
                    name='availability'
                    label='Availability Status'
                    rules={[
                      {
                        required: true,
                        message: getRequiredMessage('Availability status'),
                      },
                    ]}>
                    <AppSelect
                      placeholder={getSelectPlaceHolder('availability status')}
                      options={ResourceHelper.availabilityStatus()}
                      onSelect={handleChangeAvailability}
                    />
                  </Form.Item>
                </Col>

                {showAvailable && (
                  <Col xs={24} sm={12} md={8} lg={6}>
                    <Form.Item
                      name='available_on'
                      label='Available On'
                      rules={[
                        {
                          required: true,
                          message: getRequiredMessage('Available on'),
                        },
                      ]}>
                      <AppDatePicker
                        format='MM-DD-YYYY'
                        disabledDate={(current) =>
                          current && current < moment().startOf('day')
                        }
                        placeholder={'MM-DD-YYYY'}
                        style={{width: '100%'}}
                      />
                    </Form.Item>
                  </Col>
                )}
              </AppGridContainer>
              <Form.Item
                name='billing_rates'
                label='Billing Rates'
                rules={[
                  {
                    required: true,
                    message: getRequiredMessage('Billing Rate'),
                  },
                ]}>
                <Radio.Group
                  options={radioOptions}
                  onChange={handleChangeBillingType}
                  value={billingType}
                />
              </Form.Item>
              <Row align='bottom'>
                {isRange || (
                  <Col className='mr-2'>
                    <Form.Item
                      name='billing_rate'
                      rules={[
                        {
                          required: true,
                          message: getRequiredMessage('Rate'),
                        },
                      ]}>
                      <AppInputNumber
                        placeholder='Enter exact rate'
                        prefix='$'
                        addonAfter='USD'
                        type='number'
                        pattern='[0-9]+([\.,][0-9]+)?'
                        step='any'
                        style={{width: '200px'}}
                        controls={false}
                      />
                    </Form.Item>
                  </Col>
                )}
                {isRange && (
                  <>
                    <Col className='mr-2'>
                      <Form.Item
                        name='billing_rate_min'
                        rules={[
                          {
                            required: true,
                            message: getRequiredMessage('Rate'),
                          },
                        ]}>
                        <AppInputNumber
                          onKeyDown={allowPositiveExactNumber}
                          placeholder='From rate'
                          prefix='$'
                          addonAfter='USD'
                          type='number'
                          pattern='[0-9]+([\.,][0-9]+)?'
                          step='any'
                          style={{width: '200px'}}
                          controls={false}
                        />
                      </Form.Item>
                    </Col>
                    <Col className='mr-2'>
                      <Form.Item
                        name='billing_rate_max'
                        rules={[
                          {
                            required: true,
                            message: getRequiredMessage('Rate'),
                          },
                        ]}>
                        <AppInputNumber
                          onKeyDown={allowPositiveExactNumber}
                          placeholder='To rate'
                          prefix='$'
                          addonAfter='USD'
                          type='number'
                          pattern='[0-9]+([\.,][0-9]+)?'
                          step='any'
                          style={{width: '200px'}}
                          controls={false}
                        />
                      </Form.Item>
                    </Col>
                  </>
                )}
              </Row>
              <AppGridContainer>
                <Col xs={24} sm={12} md={8} lg={6}>
                  <Form.Item
                    name='billing_rate_period'
                    label='Billing Rate Period'
                    rules={[
                      {
                        required: true,
                        message: getRequiredMessage('Billing Rate Period'),
                      },
                    ]}>
                    <AppSelect
                      placeholder={getSelectPlaceHolder('period')}
                      options={ResourceHelper.billingRatePeriods()}
                    />
                  </Form.Item>
                </Col>
              </AppGridContainer>
            </AppFormBody>
            <AppFormActions>
              <Space size={16} className={'mt-5'}>
                <Button
                  type='primary'
                  htmlType='submit'
                  loading={mutation.isLoading}>
                  Add New Resource
                </Button>
                <Button type='primary' onClick={() => navigate(-1)} ghost>
                  Cancel
                </Button>
              </Space>
            </AppFormActions>
          </Form>
        </AppFormWrapper>
      </AppCard>
    </AppPageContainer>
  );
};

export default CreateResource;
