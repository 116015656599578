import AppLoader from '@wieldy/components/AppLoader';
import AppDynamicTabs from '@wieldy/components/AppTabs/AppDynamicTabs';
import AppTag from '@wieldy/components/AppTag';
import AppPageContainer from '@wieldy/components/pageComponents/AppPageContainer';
import AppPageHeader from '@wieldy/components/pageComponents/AppPageHeader';
import {Button, Divider, Row, Tooltip, Typography} from 'antd';
import {USER_INCLUDES} from 'app/constants';
import MESSAGE_QUERIES from 'app/constants/query-params/message-queries';
import {useAccountType} from 'app/hooks/useAccountType';
import PlacementsModal from 'app/modals/PlacementsModal';
import {
  useCreateInquiryChannel,
  useCreateSubmissionChannel,
} from 'app/services/messages';
import {useGetPlacement} from 'app/services/placementService';
import React, {useEffect, useMemo, useState} from 'react';
import {RiArrowLeftRightLine, RiMessageLine} from 'react-icons/ri';
import {useNavigate, useParams} from 'react-router-dom';

import {getPageTabsData} from '../data';
import MainContent from './MainContent';

const ViewPlacement = () => {
  const {id} = useParams();
  const navigate = useNavigate();

  const {isSupplier, isMarketplace, isClient} = useAccountType();
  useEffect(() => {
    if (isMarketplace && !isSupplier && !isClient) {
      navigate('/');
    }
  }, [isSupplier, navigate, isClient, isMarketplace]);

  const tabsData = useMemo(() => getPageTabsData(), []);

  const [activeTab, setActiveTab] = useState(tabsData[0]);

  const {isLoading, data} = useGetPlacement(id, {
    include: [...USER_INCLUDES.supplierIncludeDetails, 'invoice'],
    expand: [
      'client',
      'supplier',
      'applicant',
      'candidate',
      'requisition',
      'hiring_manager',
      'account_manager',
      'resource_manager',
      'hiring_manager',
    ],
  });

  const createInquiryChannel = useCreateInquiryChannel();
  const createSubmissionChannel = useCreateSubmissionChannel();

  const handleSendMessage = () => {
    if (isMarketplace) {
      navigate('/messages');
    } else {
      if (data.inquiry) {
        createInquiryChannel.mutate(data.inquiry, {
          onSuccess: ({data}) => {
            const channelId = data.id;
            navigate(`/messages?${MESSAGE_QUERIES.channelId}=${channelId}`);
          },
        });
      } else {
        createSubmissionChannel.mutate(data.submission, {
          onSuccess: ({data}) => {
            const channelId = data.id;
            navigate(`/messages?${MESSAGE_QUERIES.channelId}=${channelId}`);
          },
        });
      }
    }
  };

  const placement = useMemo(() => new PlacementsModal(data), [data]);

  if (isLoading) {
    return <AppLoader />;
  }

  return (
    <AppPageContainer
      header={
        <AppPageHeader
          backHeader
          header='inner'
          category='Placement'
          tabs={
            <AppDynamicTabs
              tabsData={tabsData}
              activeKey={activeTab?.key}
              setActiveTab={setActiveTab}
              scroll
            />
          }
          extra={
            !isMarketplace && (
              <Tooltip overlayStyle={{fontSize: '12px'}} title={'Send message'}>
                <Button
                  type='primary'
                  shape='circle'
                  onClick={handleSendMessage}
                  icon={<RiMessageLine fontSize={16} />}
                />
                <Divider type='vertical' style={{height: 28}} />
              </Tooltip>
            )
          }>
          <Row className='page-header-paper text-uppercase mb-5' align='middle'>
            <div className={'paper paper-sm'}>
              <div className={'paper-body'}>
                <Typography.Title
                  level={4}
                  type='secondary'
                  className='text-xs mb-1'>
                  Supplier
                </Typography.Title>
                <Typography.Title level={5} className={'text-sm my-0'}>
                  {placement.supplier.name}
                </Typography.Title>
              </div>
            </div>
            <RiArrowLeftRightLine fontSize={16} className='mx-4' />
            <div className={'paper paper-sm'}>
              <div className={'paper-body'}>
                <Typography.Title
                  level={4}
                  type='secondary'
                  className='text-xs mb-1'>
                  Client
                </Typography.Title>
                <Typography.Title level={5} className={'text-sm my-0'}>
                  {placement.client.name}
                </Typography.Title>
              </div>
            </div>
          </Row>
          <Typography.Title
            level={5}
            type='secondary'
            className='text-uppercase text-xs tracking-normal mb-3'>
            candidate
          </Typography.Title>
          <Typography.Title
            level={2}
            className={'d-flex flex-wrap align-items-center'}>
            {placement?.applicant?.name}
            <Divider type='vertical' style={{height: 20}} />
            <Typography.Text type='secondary' className='text-md mr-2'>
              {placement?.requisition?.jobTitle}
            </Typography.Text>
            <AppTag
              label={placement.status}
              shape={'circle'}
              color={placement?.isOpen ? 'success' : 'default'}
              className={'tracking-normal'}
            />
          </Typography.Title>
        </AppPageHeader>
      }>
      <MainContent placement={placement} />
    </AppPageContainer>
  );
};

export default ViewPlacement;
