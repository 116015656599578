import {Typography} from 'antd';
import React from 'react';

export const getColumns = () => {
  return [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'first_name',
      render: (_, item) => (
        <Typography.Text className={'font-semibold'}>
          {item?.name}
        </Typography.Text>
      ),
      sorter: true,
      showSorterTooltip: false,
    },
    {
      title: 'Email Address',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Sent On',
      dataIndex: 'sentOn',
      key: 'created_at',
      sorter: true,
      showSorterTooltip: false,
    },
    {
      title: 'User Type',
      dataIndex: 'userType',
      key: 'role',
      sorter: true,
      showSorterTooltip: false,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: () => 'Invitation Sent',
    },
  ];
};
