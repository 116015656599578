import AppCard from '@wieldy/components/AppCard';
import AppGridContainer from '@wieldy/components/AppGridContainer';
import {Button, Col} from 'antd';
import usePageQuery from 'app/hooks/usePageQuery';
import useStep from 'app/hooks/useStep';
import supplierModal from 'app/modals/SupplierModal';
import {useGetInfinitySuppliers} from 'app/services/supplierService';
import CreateList from 'app/shared/components/inquiry/CreateList';
import {FilterSuppliers} from 'app/shared/supplier';
import {setInquirySuppliers} from 'app/store/features/inquiry/inquirySlice';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useDispatch} from 'react-redux';

import Resources from '../Resources';
import SupplierDetail from '../SupplierDetails';

const ChooseSupplierContainer = () => {
  const dispatch = useDispatch();
  const {nextStep} = useStep();
  const [currentSupplier, setCurrentSupplier] = useState(null);

  const currentFormattedSupplier = useMemo(
    () => new supplierModal(currentSupplier),
    [currentSupplier],
  );

  const handleCurrentSupplier = useCallback((sup) => {
    setCurrentSupplier(sup);
  }, []);

  const {filterQuery, queryInit} = usePageQuery();

  const {data, isLoading, fetchNextPage, isFetchingNextPage, hasNextPage} =
    useGetInfinitySuppliers({
      ...queryInit,
      ...filterQuery,
    });

  const {totalCount, suppliers} = useMemo(() => {
    return {
      totalCount: data?.pages[0].total_count || 0,
      suppliers:
        data?.pages?.reduce((acc, page) => acc.concat(page.data), []) || [],
    };
  }, [data]);

  useEffect(() => {
    if (suppliers.length && !currentSupplier) {
      handleCurrentSupplier(suppliers[0]);
    }
    if (!suppliers.length) {
      handleCurrentSupplier(null);
    }
  }, [currentSupplier, handleCurrentSupplier, suppliers]);

  const handleReviewAll = () => {
    dispatch(setInquirySuppliers([]));
    nextStep();
  };

  return (
    <>
      <Button
        type='primary'
        onClick={handleReviewAll}
        ghost
        className={'text-uppercase border-2 shadow mb-4'}>
        Review Candidates from all suppliers
      </Button>
      <AppGridContainer>
        <CreateList
          isLoading={isLoading}
          title='Suppliers'
          items={suppliers}
          fetchNextPage={fetchNextPage}
          isFetchingNextPage={isFetchingNextPage}
          hasNextPage={hasNextPage}
          totalCount={totalCount}
          filter={<FilterSuppliers />}
          actionCreator={setInquirySuppliers}
          handleCurrentItem={handleCurrentSupplier}
          currentItem={currentSupplier}
        />
        <Col xs={24} lg={16}>
          <AppCard style={{height: 'fit-content', minHeight: 300}}>
            {currentSupplier && (
              <SupplierDetail supplier={currentFormattedSupplier} />
            )}
            {currentSupplier && (
              <Resources pageQuery={{supplier: currentSupplier.id}} />
            )}
          </AppCard>
        </Col>
      </AppGridContainer>
    </>
  );
};

export default ChooseSupplierContainer;
