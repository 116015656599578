import './index.style.less';

import {useWindowSize} from '@wieldy/hooks/useWindowSize';
import {Layout} from 'antd';
import {USER_CREDENTIALS} from 'app/constants/user-creds';
import {useAccountType} from 'app/hooks/useAccountType';
import {useRole} from 'app/hooks/useRole';
import {setAccountType} from 'app/store/features/user/userSlice';
import PropTypes from 'prop-types';
import React, {useCallback, useState} from 'react';
import {RiSearchLine} from 'react-icons/ri';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';

import {useOutsideClick} from '../../../hooks/useClickOutside';
import CollapseMenuHandle from '../partials/CollapseMenuHandle';
import Logo from '../partials/Logo';
import GlobalSearch from './GlobalSearch';
import Messages from './Messages';
import Notifications from './Notifications';
import UserInfo from './UserInfo';

const {Header} = Layout;

const AppHeader = ({isCollapsed, toggleCollapsed}) => {
  const dispatch = useDispatch();
  const {width} = useWindowSize();
  const [showSearch, setShowSearch] = useState(false);
  const navigate = useNavigate();
  const handleToggleSearch = useCallback(() => {
    setShowSearch((prev) => !prev);
  }, []);

  const {isMarketplace, isSupplier, isClient} = useAccountType();
  const {isResource} = useRole();
  const ref = useOutsideClick(() => setShowSearch(false));

  const handleOpenMassages = useCallback(() => {
    if (isMarketplace) {
      dispatch(setAccountType(USER_CREDENTIALS.USER_TYPES.marketplace));
    }
    navigate('/messages');
  }, [dispatch, isMarketplace, navigate]);

  return (
    <Header className='app-header'>
      {isMarketplace && !isSupplier && !isClient ? (
        <div className='mr-3'>
          <Logo isMobileView={width <= 400} />
        </div>
      ) : width < 1200 ? (
        <div className={'d-flex align-items-center mr-3'}>
          <CollapseMenuHandle
            isCollapsed={isCollapsed}
            toggleCollapsed={toggleCollapsed}
          />

          <Logo isMobileView={width <= 400} />
        </div>
      ) : null}
      {!isResource && (isSupplier || isClient) && (
        <GlobalSearch
          ref={ref}
          showSearch={showSearch}
          handleToggleSearch={handleToggleSearch}
        />
      )}
      <ul className='nav-vertical ml-auto'>
        {(isSupplier || isClient) && (
          <li className='nav-item nav-item-search' onClick={handleToggleSearch}>
            <RiSearchLine className={'cursor-pointer text-xxl'} />
          </li>
        )}
        <li className='nav-item'>
          <Notifications />
        </li>
        {!isResource && (
          <li className='nav-item' onClick={handleOpenMassages}>
            <Messages />
          </li>
        )}
        <li className='nav-item'>
          <UserInfo />
        </li>
      </ul>
    </Header>
  );
};

export default React.memo(AppHeader);

AppHeader.propTypes = {
  isCollapsed: PropTypes.bool,
  toggleCollapsed: PropTypes.func,
};
