import {Checkbox} from 'antd';
import PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import {Controller, useFormContext, useWatch} from 'react-hook-form';

import AppCheckbox from '../controles/checkbox';

const MultipleCheckboxRegion = ({item, name, parentKey}) => {
  const {control, setValue, clearErrors} = useFormContext();

  const parentName = `${name}.value`;
  const childrenName = `${name}.items`;

  // Watch children checkboxes' values
  const childrenValues = useWatch({
    control,
    name: childrenName,
    disabled: !item.subItems,
  });

  const isAnyChildChecked = Object.values(childrenValues || {}).some(Boolean);
  const checkAll = childrenValues?.length === item.subItems?.length;
  const onChangeParent = (value) => {
    setValue(childrenName, value ? item.subItems : []);
  };

  useEffect(() => {
    if (isAnyChildChecked) {
      clearErrors(parentKey);
      setValue(parentName, true);
    }
  }, [setValue, isAnyChildChecked, clearErrors, parentKey, parentName]);

  const renderParent = () => (
    <>
      <AppCheckbox
        name={parentName}
        label={item.value}
        handleChange={onChangeParent}
        indeterminate={isAnyChildChecked && !checkAll}
      />
    </>
  );

  if (!item.subItems) {
    return renderParent();
  }

  return (
    <>
      {renderParent()}
      {item.subItems && (
        <div style={{paddingLeft: 24}}>
          <Controller
            render={({field}) => (
              <Checkbox.Group
                className='d-flex flex-column'
                options={item.subItems}
                value={field.value}
                onChange={field.onChange}
              />
            )}
            name={childrenName}
          />
        </div>
      )}
    </>
  );
};

MultipleCheckboxRegion.propTypes = {
  parentKey: PropTypes.string,
  name: PropTypes.string.isRequired,
  item: PropTypes.objectOf({
    value: PropTypes.string.isRequired,
    subItems: PropTypes.arrayOf(PropTypes.string),
  }),
};

export default MultipleCheckboxRegion;
