import AppCard from '@wieldy/components/AppCard';
import AppTable from '@wieldy/components/AppTable';
import {getTablePagination} from '@wieldy/utils/helpers/TableHelper';
import {Typography} from 'antd';
import PropTypes from 'prop-types';
import React, {useState} from 'react';

import {getColumns} from './columns';

const Skills = ({resource, isCard = true}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const onShowSizeChange = (current, pageSize) => {
    setCurrentPage(current);
    setPageSize(pageSize);
  };

  const renderContent = () => (
    <AppTable
      emptyText='No Skills'
      columns={getColumns()}
      dataSource={resource.skills}
      pagination={getTablePagination(
        pageSize,
        currentPage,
        resource.skills.length,
        onShowSizeChange,
        onPageChange,
      )}
    />
  );

  if (!resource?.skills.length) {
    return null;
  }

  if (isCard) {
    return (
      <AppCard
        title='Skills'
        headStyle={{borderBottom: 0}}
        bodyStyle={{paddingTop: 8}}>
        {renderContent()}
      </AppCard>
    );
  }

  return (
    <>
      <Typography.Title level={4}>Skills</Typography.Title>
      {renderContent()}
    </>
  );
};

Skills.propTypes = {
  resource: PropTypes.object,
  isCard: PropTypes.bool,
};

export default Skills;
