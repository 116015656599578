import PropTypes from 'prop-types';
import React from 'react';

import {RECRUITMENT_FEE_STRUCTURE_KEYS} from '../../../constants';
import {calcAmountByPercent} from '../../../utils/helpers/CalcAmountByPercent';
import {formatBillingRateRage} from '../../../utils/helpers/DataFormatHelper';

const ItemInfo = ({label, content}) => {
  return (
    <tr>
      <td width='40%' className={'text-secondary'}>
        {label}
      </td>
      <td>{content}</td>
    </tr>
  );
};

ItemInfo.propTypes = {
  label: PropTypes.string,
  content: PropTypes.node,
};

const PlacementCardContent = ({placement}) => {
  const isFixed =
    placement.recruitment_fee_structure ===
    RECRUITMENT_FEE_STRUCTURE_KEYS.fixed_fee;
  return (
    <table className='table' style={{margin: '0 -8px 24px'}}>
      <tbody>
        <ItemInfo label='Job Start Date' content={placement?.start_date} />
        <ItemInfo
          label='Guarantee End Date'
          content={placement?.guarantee_end_date}
        />
        <ItemInfo
          label='Base Salary'
          content={formatBillingRateRage(placement?.base_salary, null, null)}
        />
        <ItemInfo
          label='Recruitment Fee'
          content={
            isFixed
              ? formatBillingRateRage(placement?.recruitment_fee, null, null)
              : formatBillingRateRage(
                  calcAmountByPercent(
                    placement?.base_salary,
                    placement?.recruitment_fee,
                  ),
                  null,
                  null,
                )
          }
        />
      </tbody>
    </table>
  );
};

PlacementCardContent.propTypes = {
  placement: PropTypes.object,
};

export default PlacementCardContent;
