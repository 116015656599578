import {List} from 'antd';
import PropTypes from 'prop-types';

const ListIconBlock = ({items = []}) => {
  if (!Array.isArray(items) || items.length === 0) {
    return null;
  }
  return (
    <List
      className='supplier-list mb-6'
      itemLayout='horizontal'
      dataSource={items}
      renderItem={(item) => (
        <List.Item className='py-1 border-none'>
          <List.Item.Meta avatar={item?.icon} title={item.content} />
        </List.Item>
      )}
    />
  );
};

ListIconBlock.propTypes = {
  items: PropTypes.array,
};

export default ListIconBlock;
