import AppLabels from '@wieldy/components/AppLabels';
import {Col, Row, Typography} from 'antd';
import {useAccountType} from 'app/hooks/useAccountType';
import PropTypes from 'prop-types';
import React from 'react';

import {INSURANCE} from '../../../requisitions/ViewRequisition/constatns';

const SettingsDetail = ({company, children}) => {
  const {isSupplier} = useAccountType();

  return (
    <React.Fragment>
      <div className='mb-7'>
        <Typography.Title level={4} className={'mb-3'}>
          Visibility
        </Typography.Title>
        <Row gutter={[20, 16]}>
          <Col xs={12} md={5}>
            <Typography.Text type={'secondary'}>
              Company Profile
            </Typography.Text>
          </Col>
          <Col xs={12} md={6}>
            <Typography.Text>{company?.browsingVisible}</Typography.Text>
          </Col>
          <Col xs={12} md={5}>
            <Typography.Text type={'secondary'}>
              {company?.locationProfileVisibility.length
                ? 'Visible to Suppliers Based In'
                : ''}
            </Typography.Text>
          </Col>
          <Col xs={12} md={8}>
            <AppLabels items={company?.locationProfileVisibility || []} />
          </Col>
        </Row>
      </div>

      <div className='mb-7'>
        <Typography.Title level={4} className={'mb-3'}>
          Communications
        </Typography.Title>
        <Row gutter={[20, 16]}>
          <Col xs={12} md={5}>
            <Typography.Text type={'secondary'}>
              Receive {isSupplier ? 'Inquiries' : 'Submissions'}
            </Typography.Text>
          </Col>
          <Col xs={12} md={6}>
            <Typography.Text>
              {isSupplier
                ? company?.receiveInquiry
                : company?.receiveSubmission}
            </Typography.Text>
          </Col>
          <Col xs={12} md={5}>
            <Typography.Text type={'secondary'}>
              {(isSupplier
                ? company?.locationInquiriesPreference
                : company?.locationSubmissionsPreference
              )?.length
                ? `Allowed From ${
                    isSupplier ? 'Clients' : 'Suppliers'
                  } Based In`
                : ''}
            </Typography.Text>
          </Col>
          <Col xs={12} md={8}>
            <AppLabels
              items={
                (isSupplier
                  ? company?.locationInquiriesPreference
                  : company?.locationSubmissionsPreference) || []
              }
            />
          </Col>
        </Row>
      </div>
      {!isSupplier && (
        <div className='mb-7'>
          <Typography.Title level={4} className={'mb-3'}>
            Insurance
          </Typography.Title>
          <Row gutter={[20, 16]}>
            <Col xs={12} md={5}>
              <Typography.Text type={'secondary'}>
                Supplier Insurance
              </Typography.Text>
            </Col>
            <Col xs={12} md={6}>
              <Typography.Text>
                {company?.insurance_required
                  ? INSURANCE.REQUIRED
                  : INSURANCE.DEFAULT}
              </Typography.Text>
            </Col>
          </Row>
        </div>
      )}
      {children}
    </React.Fragment>
  );
};

SettingsDetail.propTypes = {
  company: PropTypes.object,
  children: PropTypes.node,
};

export default SettingsDetail;
