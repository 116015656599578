import {useMutation} from 'react-query';

import {applicantApi} from '../api/applicantApi';
import useReactQuery, {
  useQueryClient,
  useReactMutation,
} from '../hooks/useReactQuery';

export const useGetApplicants = (queryParams = {}, options = {}) => {
  const params = {
    include: ['total_count'],
    ...queryParams,
  };
  return useReactQuery(
    ['applicants', queryParams],
    async () => {
      const {data} = await applicantApi.getApplicants(params);

      return {
        applicants: data?.data ?? [],
        total_count: data?.total_count ?? 0,
      };
    },
    options,
  );
};

export const useGetAllApplicants = (params = {}, statuses = []) => {
  return useReactQuery(
    ['applicants', params],
    async () => {
      const allPromise = statuses.map((relation_status) => {
        return applicantApi.getApplicants({...params, relation_status});
      });
      const response = await Promise.all(allPromise);
      return response.map(({data, config}) => ({
        resources: data?.data || [],
        total_count: data?.total_count || 0,
        status: config.params.relation_status,
      }));
    },
    {
      enabled: !!statuses.length,
    },
  );
};

export const useGetApplicant = (applicantId, params = {}) => {
  return useReactQuery(
    ['applicants/id', applicantId],
    async () => {
      const {data} = await applicantApi.getApplicant(applicantId, params);
      return data;
    },
    {enabled: !!applicantId},
  );
};

export const useCreateApplicant = () => {
  const client = useQueryClient();
  return useReactMutation(async (formData) => {
    const response = await applicantApi.createApplicant(formData);
    await client.invalidateQueries(['applicants']);
    return {
      data: response.data,
      message: 'Applicant created successfully',
    };
  });
};

export const useUpdateApplicant = (id) => {
  const client = useQueryClient();
  return useReactMutation(async (payload) => {
    const response = await applicantApi.updateApplicant(id, payload);
    await client.invalidateQueries(['applicants']);
    return {data: response.data, message: 'Applicant updated successfully'};
  });
};

export const useDownloadApplicant = () => {
  return useMutation({
    mutationFn: async ({id}) => {
      const data = await applicantApi.getApplicantPdf({
        id,
        options: {
          responseType: 'blob',
          headers: {
            'Content-Type': 'application/pdf',
          },
        },
      });

      return data;
    },
  });
};
