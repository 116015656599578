import {Typography} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

import ExpenseTable from '../ExpensesTable';

const ExpenseSpendCard = ({isShowDetails, isShowForm}) => {
  return (
    <React.Fragment>
      {isShowDetails ? (
        <Typography>Not Available</Typography>
      ) : (
        isShowForm && <ExpenseTable />
      )}
    </React.Fragment>
  );
};

ExpenseSpendCard.propTypes = {
  isShowDetails: PropTypes.bool,
  isShowForm: PropTypes.bool,
};

export default ExpenseSpendCard;
