import {useDispatch} from 'react-redux';
import {useLocation, useNavigate} from 'react-router-dom';

import {resetExpensesState} from '../store/features/expenses/expensesSlice';
import {resetTimeCardState} from '../store/features/timecards/timecardsSlice';

export const useResetState = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {pathname} = useLocation();
  const isExpensesPage = pathname.startsWith('/expenses');

  return () => {
    if (isExpensesPage) {
      navigate('/expenses');
      dispatch(resetExpensesState());
    } else {
      navigate('/timecards');
      dispatch(resetTimeCardState());
    }
  };
};
