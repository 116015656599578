import {Col, Descriptions, Row} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

const Summary = ({placement}) => {
  return (
    <Row gutter={30} justify={'space-between'}>
      <Col span={2} xs={24} md={24} sp>
        <Descriptions colon={false} labelStyle={{width: '50%'}} column={1}>
          <Descriptions.Item label='Total Recruitment Fee'>
            {placement.recruitment_fee_amount}
          </Descriptions.Item>
          <Descriptions.Item label='Adjusted Recruitment Fee'>
            {placement.ajusted_recruitment_fee}
          </Descriptions.Item>
          <Descriptions.Item label='Invoiced Recruitment Fee'>
            {placement.invoiced_recruitment_fee}
          </Descriptions.Item>
        </Descriptions>
      </Col>
    </Row>
  );
};
Summary.propTypes = {
  placement: PropTypes.object,
};
export default Summary;
