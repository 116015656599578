import AppPageContainer from '@wieldy/components/pageComponents/AppPageContainer';
import AppPageHeader from '@wieldy/components/pageComponents/AppPageHeader';
import {AppStepsWrapper} from 'app/providers/AppStepProvider';
import {useGetSubmission} from 'app/services/submissionService';
import {
  resetSubmissionState,
  setSubmissionEditData,
  setSubmissionEditMode,
} from 'app/store/features/submission/inquirySlice';
import PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';

import {queryParamsList} from '../constants';
import MainContent from './MainContent';

const CreateSubmission = ({isEdit = false}) => {
  const dispatch = useDispatch();
  const {submissionId, requisitionId} = useParams();
  const title = isEdit ? 'Update Submission' : 'New Submission';
  const currentStep = isEdit || requisitionId ? 2 : 1;

  useEffect(() => {
    dispatch(setSubmissionEditMode(isEdit));
  }, [dispatch, isEdit]);

  useEffect(() => {
    return () => {
      dispatch(resetSubmissionState());
    };
  }, [dispatch]);

  const {data} = useGetSubmission(submissionId, queryParamsList, {
    enabled: isEdit,
  });

  useEffect(() => {
    if (data && isEdit) {
      dispatch(setSubmissionEditData(data));
    }
  }, [data, dispatch, isEdit]);

  return (
    <AppPageContainer
      header={
        <AppPageHeader header='main' category='Submission' title={title} />
      }>
      <AppStepsWrapper
        isUpdated={false}
        isLoading={false}
        currentStep={currentStep}>
        <MainContent />
      </AppStepsWrapper>
    </AppPageContainer>
  );
};

CreateSubmission.propTypes = {
  isEdit: PropTypes.bool,
};

export default CreateSubmission;
