import {Col, Divider, Row, Typography} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

const Calculation = ({placement}) => {
  return (
    <React.Fragment>
      <Row gutter={30} className={'description-summary flex-row-reverse'}>
        <Col xs={24} md={12}>
          <div style={{margin: '0 -8px'}}>
            <table className={'table table-xs'} style={{margin: 0}}>
              <tr>
                <td>
                  <Typography.Text>Total Spend</Typography.Text>
                </td>
                <td align={'right'}>
                  <Typography.Text>
                    {placement.invoiced_recruitment_fee}
                  </Typography.Text>
                </td>
              </tr>
              <tr>
                <td>
                  <Typography.Text>Credits/Adjustments</Typography.Text>
                </td>
                <td align={'right'}>
                  <Typography.Text>
                    {placement.invoice_total_adjustment}
                  </Typography.Text>
                </td>
              </tr>
            </table>
          </div>
        </Col>
      </Row>
      <Divider className='my-2' />
      <Row gutter={30} className={'description-summary flex-row-reverse'}>
        <Col xs={24} md={12}>
          <div style={{margin: '0 -8px'}}>
            <table className={'table table-xs'} style={{margin: 0}}>
              <tr>
                <td>
                  <Typography.Text className={'font-semibold'}>
                    Net Amount
                  </Typography.Text>
                </td>
                <td align={'right'}>
                  <Typography.Text className={'font-semibold'}>
                    {placement.invoiced_recruitment_fee}
                  </Typography.Text>
                </td>
              </tr>
            </table>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

Calculation.propTypes = {
  placement: PropTypes.object,
};

export default Calculation;
