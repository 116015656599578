const preventNegativeNumber = (event) => {
  if (/![0-9]+([.,][0-9]+)?/.test(event.key)) {
    event.preventDefault();
  }
};

const allowPositiveExactNumber = (event) => {
  if (!/[0-9]/.test(event.key) && event.keyCode !== 8 && event.keyCode !== 13) {
    event.preventDefault();
  }
};

// A positive integer representing how much to charge in the smallest currency unit (e.g., 100 cents to charge $1.00)
const moneySchema = (e) => {
  return /-?^\\d*(.\\d{0,2})?$/.test(e.target.value);
};

const fileNameSchema = (name, isVisibleProfile) => {
  if (isVisibleProfile) return name?.replaceAll(/(?:, |\. | - |\s| {2})/g, '-');

  return name?.match(/\d+/g)?.join(' ');
};

export {
  allowPositiveExactNumber,
  fileNameSchema,
  moneySchema,
  preventNegativeNumber,
};
