import AppGridView from '@wieldy/components/AppGridView';
import AppLoader from '@wieldy/components/AppLoader';
import AppPageSection from '@wieldy/components/pageComponents/AppPageSection';
import {capitalizedAllWords} from '@wieldy/utils/helpers/StringHelper';
import {Empty} from 'antd';
import {PAGINATION} from 'app/config/app';
import {useAccountType} from 'app/hooks/useAccountType';
import RequisitionModal from 'app/modals/RequisitionModal';
import {
  requisitionClientQueries,
  requisitionSupplierQueries,
  statusesClient,
  statusesSupplier,
} from 'app/pages/main/requisitions/data.js';
import {useGetAllRequisitions} from 'app/services/requisitionService';
import ViewAllButton from 'app/shared/buttons/ViewAllButton';
import {RequisitionCard} from 'app/shared/requisitions';
import React, {useMemo} from 'react';

const All = () => {
  const {isClient, isSupplier} = useAccountType();

  const pageQueries = useMemo(
    () => (isClient ? requisitionClientQueries : requisitionSupplierQueries),
    [isClient],
  );

  const statuses = useMemo(
    () => (isClient ? statusesClient : statusesSupplier),
    [isClient],
  );

  const {isLoading, data: allData} = useGetAllRequisitions(
    {...pageQueries, limit: PAGINATION.ALL_ITEMS_LIMIT},
    statuses,
    isSupplier,
  );

  if (isLoading) {
    return <AppLoader />;
  }

  if (allData?.every(({requisitions}) => !requisitions.length)) {
    return <Empty description='No Requisitions' />;
  }

  return (
    !!allData?.length &&
    allData.map(
      ({requisitions, total_count, status}) =>
        !!requisitions.length && (
          <AppPageSection
            key={status}
            title={`${capitalizedAllWords(
              status?.replace('_', ' '),
            )} Requisitions`}
            count={total_count}
            extra={
              total_count > 6 && (
                <ViewAllButton category={status} path='requisitions' />
              )
            }>
            <AppGridView
              isAllPages={true}
              data={requisitions.slice(0, 6)}
              renderItem={(requisition) => (
                <RequisitionCard
                  key={requisition?.id}
                  requisition={new RequisitionModal(requisition)}
                  showClient={isSupplier}
                />
              )}
            />
          </AppPageSection>
        ),
    )
  );
};

export default All;
