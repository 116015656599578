import {INVOICE_STATUS} from '@wieldy/constants/AppDataConsts';
import {Typography} from 'antd';
import {DATE_FORMATS} from 'app/config/app';
import {
  formatAmount,
  formatDate,
  formatSecondsToDate,
  getFullName,
} from 'app/utils/helpers/DataFormatHelper';

export const getColumns = ({isSupplier, navigation}) => {
  const onLink = (path) => {
    return () => navigation(path);
  };
  return [
    {
      title: 'Invoice ID',
      dataIndex: 'hid',
      key: 'hid',
      render: (invoiceId, record) => (
        <Typography.Link
          onClick={onLink(`/financials/${record?.id}/view-invoice`)}>
          {invoiceId}
        </Typography.Link>
      ),
    },
    {
      title: 'Engagement/Placement ID',
      dataIndex: 'engagement',
      key: 'engagement',
      render: (_, {placement, engagement}) => {
        if (engagement) {
          return (
            <Typography.Link
              onClick={onLink(`/engagements/${engagement.id}/view-engagement`)}>
              {engagement.hid}
            </Typography.Link>
          );
        }
        if (placement) {
          return (
            <Typography.Link
              onClick={onLink(`/placements/${placement.id}/view-placement`)}>
              {placement.hid}
            </Typography.Link>
          );
        }
      },
    },
    {
      title: 'Invoice Date',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (date) => (
        <Typography.Text>{formatDate(date, DATE_FORMATS.md)}</Typography.Text>
      ),
      sorter: true,
      defaultSortOrder: 'descend',
      showSorterTooltip: false,
    },
    {
      title: 'Total Amount',
      dataIndex: 'total',
      key: 'total',
      render: (total) => (
        <Typography.Text>{formatAmount(total)}</Typography.Text>
      ),
    },
    ...(isSupplier
      ? [
          {
            title: 'MSP Fee',
            dataIndex: 'supplier_msp_fee',
            key: 'supplier_msp_fee',
            render: (fee) => (
              <Typography.Text>{formatAmount(fee)}</Typography.Text>
            ),
          },
          {
            title: 'Total - MSP',
            dataIndex: 'supplier_subtotal',
            key: 'supplier_subtotal',
            render: (subtotal) => (
              <Typography.Text>{formatAmount(subtotal)}</Typography.Text>
            ),
          },
        ]
      : []),
    {
      title: 'Tax',
      dataIndex: 'total_tax',
      key: 'total_tax',
      render: (totalTax) => (
        <Typography.Text>{formatAmount(totalTax)}</Typography.Text>
      ),
    },
    {
      title: (
        <span
          dangerouslySetInnerHTML={{__html: 'Final Billable <br/> Amount'}}
        />
      ),
      render: (record) => (
        <Typography.Text>
          {formatAmount(
            isSupplier
              ? record.supplier_total_billable
              : record.client_total_billable,
          )}
        </Typography.Text>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => INVOICE_STATUS[status],
      sorter: true,
      showSorterTooltip: false,
    },
    {
      title: 'Last Updated',
      dataIndex: 'updated_by',
      key: 'updated_by',
      render: (updatedBy, {updated_at}) => (
        <>
          <Typography.Paragraph className={'mb-0'}>
            {getFullName(updatedBy)}
          </Typography.Paragraph>
          <Typography.Text className={'text-secondary text-xs'}>
            {formatSecondsToDate(updated_at)}
          </Typography.Text>
        </>
      ),
    },
  ];
};
