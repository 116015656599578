import Reports from 'app/pages/main/reports/Reports';

export const supplier_ReportRoutes = [
  {
    path: '/reports',
    element: <Reports />,
  },
];

export const client_ReportRoutes = [
  {
    path: '/reports',
    element: <Reports />,
  },
];
