// eslint-disable-next-line no-unused-vars
export const FormatTableFormValue = (values, isSubmission = false) => {
  const formValues = Object.keys(values).reduce((result, key) => {
    const [resource, field] = key.split('-');
    if (!result[resource]) {
      result[resource] = {id: resource};
      // isSubmission
      //   ? (result[resource] = {id: resource})
      //   : (result[resource] = {resource});
    }
    result[resource][field] = values[key];
    return result;
  }, {});
  return Object.values(formValues);
};
