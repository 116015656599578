import {Form, Radio, Space} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import {Controller, useFormContext} from 'react-hook-form';

const AppFormRadioGroup = ({label, name, options, isRequired, ...props}) => {
  const {control} = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({field, fieldState}) => (
        <Form.Item
          label={label}
          required={isRequired}
          validateStatus={fieldState.error ? 'error' : ''}
          help={fieldState.error ? fieldState.error.message : ''}>
          <Radio.Group {...field} {...props}>
            <Space direction='vertical'>
              {options.map(({value, label}) => (
                <Radio key={value} value={value}>
                  {label}
                </Radio>
              ))}
            </Space>
          </Radio.Group>
        </Form.Item>
      )}
    />
  );
};

AppFormRadioGroup.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  isRequired: PropTypes.bool,
  options: PropTypes.array,
};

export default AppFormRadioGroup;
