import AppBorderBox from '@wieldy/components/AppBorderBox';
import AppCard from '@wieldy/components/AppCard';
import AppLoader from '@wieldy/components/AppLoader';
import AppSelect from '@wieldy/components/dataEntry/AppSelect';
import {getRequiredMessage} from '@wieldy/utils/helpers/MessageHelper';
import {Button, Form, Select, Typography} from 'antd';
import useStep from 'app/hooks/useStep';
import {useGetEngagements} from 'app/services/engagementsService';
import {setInvoiceEngagement} from 'app/store/features/invoice/invoiceSlice';
import {getFullName} from 'app/utils/helpers/DataFormatHelper';
import {getItemsData} from 'app/utils/helpers/GetItemsData';
import propTypes from 'prop-types';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';

const getEngagementLabel = (engagement) => {
  const resourceFullName = getFullName(engagement.resource);
  return `${engagement.hid} :: ${engagement.requisition.job_title} :: ${resourceFullName}`;
};

const SelectEngagement = ({userParams = {}}) => {
  const dispatch = useDispatch();
  const {currentStep, nextStep} = useStep();
  const {engagement, isEditMode} = useSelector(({invoice}) => invoice);

  const {data, isLoading} = useGetEngagements(
    {
      expand: ['resource', 'requisition'],
      ...userParams,
    },
    {
      enabled: !isEditMode,
    },
  );
  const [engagements] = getItemsData('engagements', data);

  const onFormSubmit = (formData) => {
    const item = engagements.find((item) => item.id === formData?.engagement);
    const {id, hid} = item;

    dispatch(
      setInvoiceEngagement({
        id,
        hid,
        resourceFullName: getFullName(item.resource),
        job_title: item.requisition.job_title,
        engagementStart: item.start_date,
      }),
    );

    if (nextStep) nextStep();
  };

  if (isLoading) {
    return <AppLoader />;
  }

  return currentStep > 1 ? (
    <AppBorderBox
      className='d-inline-flex px-6 mt-3'
      style={{backgroundColor: '#FFF'}}>
      <Typography.Title level={4} className={'mb-0'}>
        {engagement.hid} :: {engagement.job_title} ::{' '}
        {engagement.resourceFullName}
      </Typography.Title>
    </AppBorderBox>
  ) : (
    <AppCard
      title='Select Engagement'
      headStyle={{border: 0}}
      bodyStyle={{paddingTop: 0}}>
      <Form
        layout='vertical'
        onFinish={onFormSubmit}
        autoComplete='on'
        initialValues={{engagement: engagement.id}}>
        <Form.Item
          name='engagement'
          label='Engagement'
          rules={[
            {
              required: true,
              message: getRequiredMessage('Engagement'),
            },
          ]}>
          <AppSelect
            showSearch
            loading={isLoading}
            placeholder='Select engagement'
            style={{minWidth: '340px', width: 'auto', maxWidth: '100%'}}>
            {engagements.map((eng) => (
              <Select.Option key={eng.id} value={eng.id}>
                {getEngagementLabel(eng)}
              </Select.Option>
            ))}
          </AppSelect>
        </Form.Item>

        <Button type='primary' htmlType='submit'>
          Continue
        </Button>
      </Form>
    </AppCard>
  );
};

SelectEngagement.propTypes = {
  userParams: propTypes.object,
};

export default React.memo(SelectEngagement);
