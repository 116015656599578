import AppCard from '@wieldy/components/AppCard';
import {Descriptions, Typography} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

import {INSURANCE} from '../../../pages/main/requisitions/ViewRequisition/constatns';

const OverviewCard = ({client}) => {
  return (
    <AppCard
      title='Overview'
      headStyle={{borderBottom: 0}}
      bodyStyle={{paddingTop: 8}}>
      <Descriptions
        colon={false}
        labelStyle={{width: '50%'}}
        className='description-root mb-4'
        column={{xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1}}>
        <Descriptions.Item label='Relationship Status'>
          {client?.relationStatus ?? 'N/A'}
        </Descriptions.Item>
        <Descriptions.Item label='Industry'>
          {client?.industry}
        </Descriptions.Item>
        <Descriptions.Item label='Company Size'>
          {client?.companySize}
        </Descriptions.Item>
        <Descriptions.Item label='Joined'>
          {client?.createdAt}
        </Descriptions.Item>
        {client?.website && (
          <Descriptions.Item label='Website'>
            <Typography.Link
              href={client?.website}
              className={'font-semibold'}
              target='_blank'>
              {client.website.replace(/(^\w+:|^)\/\//, '')}
            </Typography.Link>
          </Descriptions.Item>
        )}
        {client?.linkedin && (
          <Descriptions.Item label='Company LinkedIn Page'>
            <Typography.Link
              href={client.linkedin}
              target='_blank'
              className={'font-semibold'}>
              {client.linkedin.replace(/(^\w+:|^)\/\//, '')}
            </Typography.Link>
          </Descriptions.Item>
        )}
        <Descriptions.Item label='Insurance Required'>
          {client?.insurance_required ? INSURANCE.REQUIRED : INSURANCE.DEFAULT}
        </Descriptions.Item>
        <Descriptions.Item label='Company ID'>{client?.hid}</Descriptions.Item>
        <Descriptions.Item label='Updated' span={1}>
          {client?.updatedAt}
        </Descriptions.Item>
      </Descriptions>
      {client?.about && (
        <Typography.Paragraph>
          <Typography.Title level={4}>About</Typography.Title>
          <Typography.Paragraph>
            <div dangerouslySetInnerHTML={{__html: client?.about}} />
          </Typography.Paragraph>
        </Typography.Paragraph>
      )}
    </AppCard>
  );
};

OverviewCard.propTypes = {
  client: PropTypes.object,
};

export default React.memo(OverviewCard);
