export const DIVERSITY_ENTITY = {
  MBE: 'Minority Business Enterprise (MBE)',
  VBE: "Veteran's Business Enterprise (VBE)",
  SDVBE: "Service-Disabled Veteran's Business Enterprise (SDVBE)",
  VOSB: 'Veteran-Owned Small Business (VOSB)',
  SDVOSB: 'Service-Disabled Veteran-Owned Small Business (SDVOSB)',
  WBE: 'Women Business Enterprise (WBE)',
  MWBE: 'Minority Women Business Enterprise (MWBE)',
  DOBE: 'Disability-Owned Business Enterprise (DOBE)',
  LGBTBE: 'LGBT Business Enterprise (LGBTBE)',
  DBE: 'Disadvantaged Business Enterprise (DBE)',
  WOSB: 'Native American Women-Owned Small Business (WOSB)',
  EDWOSB: 'Economically Disadvantaged Women-Owned Small Business (EDWOSB)',
  HUBZone: 'Historically Underutilized Business Zone (HUBZone)',
  SDB: 'Small Disadvantaged Business Certification (SDB)',
  other: 'Other',
};

export const companyDiversityStatuses = Object.keys(DIVERSITY_ENTITY);

export const DIVERSITY_PRACTICES = {
  specific_group:
    "This company's stated mission or focus is the placement of\n specific underserved group(s)",
  diverse_pools:
    "This company's stated mission or focus is the creation of highly\n diverse candidate pools",
  training_methods:
    "This company's specific training or methods ensure that diversity\n candidates are not disadvantaged",
  not_a_focus: 'Diversity sourcing is not a particular focus',
};

export const SPECIFIC_GROUP = {
  minority: 'Minority',
  women: 'Women',
  veterans: 'Veterans',
  disabled: 'Disabled',
  LGBTQ: 'LGBTQ',
};
export const specificGroupOptions = Object.entries(SPECIFIC_GROUP).map(
  ([key, value]) => ({value: key, label: value}),
);
