import {Space} from 'antd';
import PerformanceRating from 'app/shared/resource/PerformanceRating';
import Skills from 'app/shared/resource/Skills';
import PropTypes from 'prop-types';
import React from 'react';

const SkillsContent = ({resource}) => {
  return (
    <Space direction='vertical' size='large' style={{display: 'flex'}}>
      <Skills resource={resource} isCard={false} />
      <PerformanceRating resource={resource} isCard={false} />
    </Space>
  );
};
SkillsContent.propTypes = {
  resource: PropTypes.object,
};
export default SkillsContent;
