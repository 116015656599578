import {Rate, Typography} from 'antd';
import propTypes from 'prop-types';
import React from 'react';

const RatingReviews = ({rating, reviews, className = 'rate-root'}) => {
  let userReviews = parseInt(reviews);
  if (isNaN(userReviews)) {
    userReviews = 0;
  }
  let rate = <Rate className='text-sm' disabled />;
  if (rating > 0) {
    rate = (
      <Rate
        className='text-sm'
        disabled
        value={rating}
        count={5}
        allowHalf={true}
      />
    );
  }
  return (
    <div className={className}>
      {rate}
      <Typography.Text className='font-semibold text-xs ml-2'>
        ({userReviews})
      </Typography.Text>
    </div>
  );
};

RatingReviews.propTypes = {
  rating: propTypes.oneOfType([propTypes.string, propTypes.number]),
  reviews: propTypes.oneOfType([propTypes.string, propTypes.number]),
  className: propTypes.string,
};

export default React.memo(RatingReviews);
