import AppCard from '@wieldy/components/AppCard';
import AppTable from '@wieldy/components/AppTable';
import {Typography} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import {useSelector} from 'react-redux';

import ResourceModal from '../../../../../../../modals/ResourceModal';
import {getColumns} from './columns';

const SelectedList = () => {
  const {candidates} = useSelector(({inquiry}) => inquiry);
  const data = candidates.map((el) => new ResourceModal(el));

  return (
    <>
      <Typography.Paragraph>
        {candidates.length} candidates selected
      </Typography.Paragraph>
      <AppCard>
        <AppTable columns={getColumns()} dataSource={data} pagination={false} />
      </AppCard>
    </>
  );
};

SelectedList.propTypes = {
  candidates: PropTypes.array,
};

export default SelectedList;
