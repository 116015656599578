import {createAsyncThunk} from '@reduxjs/toolkit';
import {userAPI} from 'app/api/userApi.js';
import {setRequestHeader} from 'app/config/axios';
import {USER_CREDENTIALS} from 'app/constants/user-creds';
import {getFetcher, mapUserAccountProps} from 'app/services/authService';

import {clientAPI} from '../../../api/clientApi';
import {supplierAPI} from '../../../api/supplierApi';

export const acceptTerms = createAsyncThunk(
  'user/update',
  async ({id, type}) => {
    const api =
      type === 'supplier' ? supplierAPI.updateSupplier : clientAPI.updateClient;
    const {data} = await api(id, {accepted_terms_and_conditions: true});
    return {data, type};
  },
);
export const fetchUser = createAsyncThunk('user/fetchUser', async () => {
  const {data} = await userAPI.getCurrentUserAccounts();
  const {user, accounts, marketplace} = mapUserAccountProps(data);
  const currentUser = JSON.parse(localStorage.getItem('currentUser'));

  const initUser = accounts[0];
  let header;
  let id;
  if (currentUser && user.id in currentUser) {
    if (
      !marketplace &&
      !accounts.find((a) => a.id === currentUser[user.id].id)
    ) {
      localStorage.removeItem('currentUser');
      header = initUser?.header;
      id = initUser?.id;
    } else {
      header = currentUser[user.id].header;
      id = currentUser[user.id].id;
    }
  } else {
    header = initUser?.header;
    id = initUser?.id;
  }

  if (header) {
    localStorage.setItem(
      'currentUser',
      JSON.stringify({[user.id]: {header, id}}),
    );
    setRequestHeader(header, id);
  }

  const {fetcher, accountType} = getFetcher[header] || {
    fetcher: null,
    accountType: null,
  };
  const isOurAccount = accounts.find((data) => id === data.id);

  const common = {
    user,
    accounts,
    chatToken: data.chat_token,
  };

  if (fetcher && isOurAccount) {
    const data = await fetcher();
    return {
      ...common,
      currentAccount: data,
      marketplace: null,
      accountType,
      userRole: isOurAccount.role,
      userStatus:
        isOurAccount.role === USER_CREDENTIALS.USER_ROLES.resource &&
        USER_CREDENTIALS.USER_ROLES.resource in data
          ? data?.resource?.status
          : data?.status,
    };
  }

  if (fetcher && !isOurAccount && marketplace) {
    const data = await fetcher();
    return {
      ...common,
      currentAccount: data,
      marketplace,
      accountType,
      userRole: marketplace?.role,
      userStatus: marketplace?.status,
    };
  }

  const {role, status, permissions, ...rest} = marketplace;
  return {
    ...common,
    accountType: USER_CREDENTIALS.USER_TYPES.marketplace,
    marketplace,
    currentAccount: {
      marketplace: {
        ...rest,
      },
      permissions,
      role,
      status,
    },
    userRole: role,
    userStatus: status,
  };
});
