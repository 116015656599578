import AppCard from '@wieldy/components/AppCard';
import AppTag from '@wieldy/components/AppTag';
import {Col, Descriptions, Row} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

const InformationView = ({requisitionModal}) => {
  const renderLocation = () => {
    if (requisitionModal.jobLocation) {
      return requisitionModal.jobLocation;
    }
    if (Array.isArray(requisitionModal?.jobCountry)) {
      return requisitionModal?.jobCountry.map((country) => (
        <Col key={country}>
          <AppTag
            key={country}
            className={'border-none'}
            shape={'square'}
            label={country}
          />
        </Col>
      ));
    }
    return 'Any country';
  };
  return (
    <AppCard style={{maxWidth: 820}}>
      <Descriptions
        colon={false}
        labelStyle={{width: '50%'}}
        className='description-root table-layout-auto'
        column={{xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1}}>
        <Descriptions.Item label='Work Model'>
          {requisitionModal?.jobWorkSite}
        </Descriptions.Item>
        <Descriptions.Item label='Job Location'>
          <Row wrap='wrap' gutter={[10, 10]}>
            {renderLocation()}
          </Row>
        </Descriptions.Item>
        <Descriptions.Item label='Travel Required'>
          {requisitionModal?.travelRequirements}
        </Descriptions.Item>

        {!requisitionModal.isDirectHire && (
          <Descriptions.Item label='Duration'>
            {requisitionModal?.jobDuration}
          </Descriptions.Item>
        )}
        <Descriptions.Item
          label={
            requisitionModal.isDirectHire ? 'Job Start Date' : 'Start Date'
          }>
          {requisitionModal.startDate}
        </Descriptions.Item>
        {!requisitionModal.isDirectHire && (
          <Descriptions.Item label='End Date'>
            {requisitionModal.endDate}
          </Descriptions.Item>
        )}
      </Descriptions>
    </AppCard>
  );
};

InformationView.propTypes = {
  requisitionModal: PropTypes.object,
};

export default InformationView;
