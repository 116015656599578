import React from 'react';

const PageQueryContext = React.createContext({
  handleSetFilter: () => {},
  resetFilter: () => {},
  removeFilter: () => {},
  filterState: {},
  filterQuery: {},
  uiData: [],
  category: '',
  queryInit: {},
  currentPageParams: {},
  allPagesParams: {},
});
export default PageQueryContext;
