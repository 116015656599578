import {useAuth0} from '@auth0/auth0-react';
import {Button, Typography} from 'antd';
import React, {useCallback} from 'react';

const Error403 = () => {
  const {logout} = useAuth0();
  const onLogoutUser = useCallback(() => {
    localStorage.removeItem('invitationToken');
    localStorage.removeItem('invitationTarget');
    logout({logoutParams: {returnTo: window.location.origin}});
  }, [logout]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <h1 style={{padding: 50, fontSize: 30}}>
        You don&apos;t have permissions.
      </h1>
      <h2>
        Please contact Sourcer user support at{' '}
        <Typography.Link href={'mailto:support@sourcer.com'}>
          support@sourcer.com
        </Typography.Link>
      </h2>
      <Button onClick={onLogoutUser}>Logout</Button>
    </div>
  );
};

export default Error403;
