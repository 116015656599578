import AppCard from '@wieldy/components/AppCard';
import AppHeaderPagination from '@wieldy/components/AppPagination/AppHeaderPagination';
import AppTable from '@wieldy/components/AppTable';
import AppTag from '@wieldy/components/AppTag';
import {getTablePagination} from '@wieldy/utils/helpers/TableHelper';
import {useBrowse} from 'app/hooks/useBrowse';
import {getColumns} from 'app/pages/manager/administartion/MainContent/Users/UsersTable/columns';
import {useGetMarketplaceUsers} from 'app/services/maketplaceService';
import {getItemsData} from 'app/utils/helpers/GetItemsData';
import React, {useCallback} from 'react';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';

const UsersTable = () => {
  const {
    pageSize,
    onSortBy,
    sortBy,
    onShowSizeChange,
    onChangePage,
    currentPage,
  } = useBrowse({
    initSort: '-created_at',
    initPageSize: 5,
  });
  const {user: authUser} = useSelector(({user}) => user);
  const navigate = useNavigate();

  const {isLoading, data} = useGetMarketplaceUsers({
    page: currentPage - 1,
    limit: pageSize,
    include: ['total_count'],
    expand: ['user'],
    sort: [sortBy],
  });

  const [items, totalCount] = getItemsData('items', data);

  const viewUser = useCallback(
    (item) => {
      if (item?.user?.id === authUser.id) {
        navigate(`/my-account`);
        return;
      }
      navigate(`/administration/users/${item.id}/view-user`);
    },
    [authUser.id, navigate],
  );

  return (
    <AppCard
      title={
        <React.Fragment>
          Users{' '}
          {totalCount > 0 && <AppTag className='ml-1'>{totalCount}</AppTag>}
        </React.Fragment>
      }
      headStyle={{borderBottom: 0}}
      bodyStyle={{paddingTop: 8}}
      extra={
        <AppHeaderPagination
          total={totalCount}
          current={currentPage}
          pageSize={pageSize}
          onChange={onChangePage}
          onSortByChange={onSortBy}
        />
      }>
      <AppTable
        loading={isLoading}
        columns={getColumns(viewUser)}
        dataSource={items}
        style={{background: 'none'}}
        pagination={getTablePagination(
          pageSize,
          currentPage,
          totalCount,
          onShowSizeChange,
          onChangePage,
          onSortBy,
        )}
      />
    </AppCard>
  );
};

export default React.memo(UsersTable);
