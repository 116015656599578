import {
  addDays,
  dateToString,
  subtractDays,
} from '@wieldy/utils/helpers/DateHelper';
import moment from 'moment/moment';

export {default} from './FilterReportDropdown';

const startPrevYear = dateToString(
  new Date(moment().subtract(1, 'year').startOf('year').format()),
);
const endOfYear = new Date(moment().subtract(1, 'year').endOf('year').format());

const today = new Date();
const startYear = `01/01/${today.getFullYear()}`;
const thirtyDays = dateToString(subtractDays(today, 30));
const sevenDays = dateToString(subtractDays(today, 7));
const ninetyDays = dateToString(subtractDays(today, 91));
const halfYear = dateToString(subtractDays(today, 183));
const year = startPrevYear;

export const getOptions = (allTime) => [
  {key: 'last_week', label: 'Last week', date: sevenDays},
  {key: 'last_30', label: 'Last 30 days', date: thirtyDays},
  {key: 'last3_months', label: 'Last 3 months', date: ninetyDays},
  {key: 'last6_months', label: 'Last 6 months', date: halfYear},
  {key: 'current_year', label: 'This year', date: startYear},
  {key: 'last_year', label: 'Last year', date: year},
  {key: 'all_time', label: 'All time', date: allTime},
];

export const chartData = [
  {date: '', value: 0},
  {date: '', value: 0},
  {date: '', value: 0},
  {date: '', value: 0},
  {date: '', value: 0},
  {date: '', value: 0},
  {date: '', value: 0},
  {date: '', value: 0},
  {date: '', value: 0},
  {date: '', value: 0},
  {date: '', value: 0},
  {date: '', value: 0},
];

const getDateLabel = (dateString) => {
  const [m, d] = dateString.split('/');
  return `${m}/${d}`;
};

export const chartFromDate = (dateString) => {
  const date = startPrevYear === dateString ? endOfYear : today;
  const results = [];
  let currentDate = new Date(dateString);
  while (currentDate < date) {
    const date = dateToString(currentDate);
    results.push({date, dateLabel: getDateLabel(date), value: 0});
    currentDate = addDays(currentDate, 1);
  }
  return results;
};

export const mergeChartToData = (chart, data) => {
  chart.forEach((item) => {
    const date = new Date(item.date);
    data.forEach(({start_date, end_date}) => {
      const startDate = new Date(start_date);
      if (end_date) {
        const endDate = new Date(end_date);
        if (date >= startDate && date <= endDate) {
          item.value++;
        }
      } else {
        if (date >= startDate) {
          item.value++;
        }
      }
    });
  });
};

export const mergeActiveEngagementsToData = (chart, data) => {
  chart.forEach((item) => {
    const date = new Date(item.date);
    data.forEach(({start_date, end_date}) => {
      const startDate = new Date(start_date);
      if (end_date) {
        const endDate = new Date(end_date);
        if (date >= startDate && date < endDate) {
          item.value++;
        }
      } else {
        if (date >= startDate) {
          item.value++;
        }
      }
    });
  });
};

export const mergeAvailableResourcesToData = (chart, data) => {
  chart.forEach((item) => {
    const date = new Date(item.date);
    data.forEach(({start_date, end_date}) => {
      const startDate = new Date(start_date);
      if (end_date) {
        const endDate = new Date(end_date);
        if (date >= startDate && date < endDate) {
          item.value++;
        }
      } else {
        if (date >= startDate) {
          item.value++;
        }
      }
    });
  });
};

export const mergeActiveSuppliersChartToData = (chart, data) => {
  chart.forEach((item) => {
    const date = new Date(item.date);
    item.suppliers = [];
    data.forEach(({start_date, end_date, supplier}) => {
      const startDate = new Date(start_date);
      if (end_date) {
        const endDate = new Date(end_date);
        if (date >= startDate && date <= endDate) {
          if (!item.suppliers.includes(supplier)) {
            item.value++;
            item.suppliers.push(supplier);
          }
        }
      } else {
        if (date >= startDate) {
          if (!item.suppliers.includes(supplier)) {
            item.value++;
            item.suppliers.push(supplier);
          }
        }
      }
    });
  });
};

export const mergeActiveClientsChartToData = (chart, data) => {
  chart.forEach((item) => {
    const date = new Date(item.date);
    item.clients = [];
    data.forEach(({start_date, end_date, client}) => {
      const startDate = new Date(start_date);
      if (end_date) {
        const endDate = new Date(end_date);
        if (date >= startDate && date <= endDate) {
          if (!item.clients.includes(client)) {
            item.value++;
            item.clients.push(client);
          }
        }
      } else {
        if (date >= startDate) {
          if (!item.clients.includes(client)) {
            item.value++;
            item.clients.push(client);
          }
        }
      }
    });
  });
};

export const mergeMoneyChartToData = (chart, data, field) => {
  chart.forEach((item) => {
    data.forEach((money) => {
      if (item.date === money.paid_date) {
        item.value += money[field];
      }
    });
  });
};

export const mergeTimeChartToData = (chart, data) => {
  chart.forEach((item) => {
    data.forEach((time) => {
      if (item.date === time.work_date) {
        item.value += time.minutes;
      }
    });
  });
};

export const mergeExpensesChartToData = (chart, data) => {
  chart.forEach((item) => {
    data.forEach((time) => {
      if (item.date === time.date_of_spend) {
        item.value += time.amount;
      }
    });
  });
};
