import ClientModal from 'app/modals/ClientModal';
import {clientQuery} from 'app/pages/main/requisitions/ViewRequisition/constatns.js';
import {useGetClient} from 'app/services/supplierService';
import {ClientCard} from 'app/shared/client';
import PropTypes from 'prop-types';
import React from 'react';

const ClientDetailCard = ({client}) => {
  const {isLoading, data} = useGetClient(client?.id, clientQuery);
  return (
    <ClientCard
      client={new ClientModal(data)}
      showBookmark={true}
      loading={isLoading}
    />
  );
};

ClientDetailCard.propTypes = {
  client: PropTypes.string,
};

export default ClientDetailCard;
