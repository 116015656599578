import AppLoader from '@wieldy/components/AppLoader';
import AppPageContainer from '@wieldy/components/pageComponents/AppPageContainer';
import AppPageHeader from '@wieldy/components/pageComponents/AppPageHeader';
import ResourceReviewModal from 'app/modals/ResourceReviewModal';
import {useGetEngagement} from 'app/services/engagementService';
import React from 'react';
import {useParams} from 'react-router-dom';

import MainContent from './MainContent';

const AddEditResourceReview = () => {
  const {id} = useParams();

  const {isFetching, data} = useGetEngagement(id, {
    include: ['resource_review', 'review_author'],
    expand: ['supplier', 'resource'],
  });

  if (isFetching) {
    return <AppLoader />;
  }

  const review = new ResourceReviewModal(data);

  return (
    <AppPageContainer
      header={
        <AppPageHeader
          backHeader
          category='Administration / Reviews'
          title={review.resourceName}
          subTitle={review.supplierName}
        />
      }>
      <MainContent review={review} />
    </AppPageContainer>
  );
};

export default AddEditResourceReview;
