import {Typography} from 'antd';
import useStep from 'app/hooks/useStep';
import PageQueryProvider from 'app/providers/PageQueryProvider/PageQueryProviderNew';
import {filterConfig} from 'app/shared/resource/FilterResources';
import React, {forwardRef, useEffect, useState} from 'react';

import ChooseContainer from './ChooseContainer';
import SelectedList from './SelectedList';

const ChooseCandidates = forwardRef((props, ref) => {
  const {currentStep} = useStep();
  const [isShowPreview, setIsShowPreview] = useState(false);

  const handleContinue = () => {
    setIsShowPreview(true);
  };

  useEffect(() => {
    if (currentStep > 2) {
      setIsShowPreview(false);
    }
  }, [currentStep]);

  return (
    <React.Fragment>
      {currentStep < 2 && (
        <Typography.Paragraph>Select candidates</Typography.Paragraph>
      )}

      {(currentStep > 2 || isShowPreview) && (
        <SelectedList ref={ref} isShowPreview={isShowPreview} />
      )}

      {currentStep === 2 && !isShowPreview && (
        <PageQueryProvider
          initialSimpleQueries={{
            include: ['total_count', 'skill_tags'],
            expand: ['supplier'],
            available_only: true,
            limit: 8,
          }}
          filterConfig={filterConfig}>
          <ChooseContainer onContinue={handleContinue} />
        </PageQueryProvider>
      )}
    </React.Fragment>
  );
});

export default ChooseCandidates;
