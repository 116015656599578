import {WarningOutlined} from '@ant-design/icons';
import {useAuth0} from '@auth0/auth0-react';
import AppLoader from '@wieldy/components/AppLoader';
import {Button} from 'antd';
import {DEFAULT_AUTH} from 'app/config/auth';
import PropTypes from 'prop-types';
import React from 'react';
import {useSelector} from 'react-redux';

import MainLayout from './MainLayout';

const AppLayout = ({children}) => {
  const {loginWithRedirect} = useAuth0();
  const {isLoading, isAuthenticated, error} = useSelector(({user}) => user);

  if (isLoading) {
    return <AppLoader />;
  }

  if (isAuthenticated && !isLoading) {
    return <MainLayout>{children}</MainLayout>;
  }

  const refreshPage = () => {
    if (DEFAULT_AUTH === 'auth0') {
      loginWithRedirect().then();
      return;
    }
    window.location.reload();
  };

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        padding: 30,
        flexDirection: 'column',
        justifyContent: 'center',
        flex: 1,
      }}>
      <WarningOutlined
        style={{fontSize: 70, marginBottom: 30, color: 'orange'}}
      />
      <div style={{fontSize: 30, marginTop: 4}}>Ah! Something went wrong.</div>
      <div style={{fontSize: 18, textAlign: 'center', margin: '10px 0'}}>
        {error?.message}
      </div>
      <div style={{fontSize: 18, textAlign: 'center'}}>
        You may also refresh the page or try again latter
      </div>

      <Button style={{marginTop: 30}} onClick={refreshPage}>
        Try Again
      </Button>
    </div>
  );
};

AppLayout.propTypes = {
  children: PropTypes.node,
};

export default AppLayout;
