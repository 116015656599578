import {PAGINATION_SIMPLIFY_WIDTH} from '@wieldy/constants/AppConfigConsts';
import {useWindowSize} from '@wieldy/hooks/useWindowSize';
import {Button, Pagination, Row, Select} from 'antd';
import PropTypes from 'prop-types';
import React, {useMemo} from 'react';
import {RiArrowLeftSLine, RiArrowRightSLine} from 'react-icons/ri';

import {PAGE_SIZE_OPTIONS} from '../../constants/PaginationConsts';
import AppSelect from '../dataEntry/AppSelect';

const AppPagination = ({
  total,
  current,
  pageSize,
  onShowSizeChange,
  showSizeChanger,
  ...restProps
}) => {
  const {width} = useWindowSize();

  const responsiveProps = useMemo(() => {
    if (width <= PAGINATION_SIMPLIFY_WIDTH) {
      return {
        showSizeChanger: false,
        showQuickJumper: false,
        showTotal: null,
      };
    }

    return null;
  }, [width]);
  const itemRender = (page, type, originalElement) => {
    if (type === 'prev') {
      return (
        <Button className='ant-pagination-item-link'>
          <RiArrowLeftSLine fontSize={24} />
        </Button>
      );
    }
    if (type === 'next') {
      return (
        <Button className='ant-pagination-item-link'>
          <RiArrowRightSLine fontSize={24} />
        </Button>
      );
    }
    return originalElement;
  };

  return (
    <Row justify='center' align='middle' className='mt-12'>
      <Pagination
        showSizeChanger={false}
        total={total}
        current={current}
        hideOnSinglePage
        showTitle={false}
        showTotal={(total, range) => `${range[0]}-${range[1]} of ${total}`}
        itemRender={itemRender}
        pageSize={pageSize}
        onShowSizeChange={onShowSizeChange}
        responsive
        {...responsiveProps}
        {...restProps}
      />
      {showSizeChanger &&
        total > pageSize &&
        width > PAGINATION_SIMPLIFY_WIDTH && (
          <AppSelect
            className='ml-3 select-standard'
            size={'small'}
            value={pageSize}
            onSelect={(pageSize) => onShowSizeChange(current, pageSize)}
            style={{minWidth: 110}}>
            {PAGE_SIZE_OPTIONS.map((option) => (
              <Select.Option key={option} value={option} className={'text-sm'}>
                {option} Per Page
              </Select.Option>
            ))}
          </AppSelect>
        )}
    </Row>
  );
};

AppPagination.propTypes = {
  total: PropTypes.number,
  current: PropTypes.number,
  showSizeChanger: PropTypes.bool,
  pageSize: PropTypes.number,
  onShowSizeChange: PropTypes.func,
};

AppPagination.defaultProps = {
  showSizeChanger: true,
};

export default AppPagination;
