import AppBorderBox from '@wieldy/components/AppBorderBox';
import AppUserProfile from '@wieldy/components/AppUserProfile';
import {Typography} from 'antd';
import PropTypes from 'prop-types';
import React, {useMemo} from 'react';
import {RiMapPinLine, RiProfileLine} from 'react-icons/ri';
import {useNavigate} from 'react-router-dom';

const CandidateCardDetail = ({candidateId, applicant}) => {
  const navigate = useNavigate();
  const items = useMemo(() => {
    return [
      {
        icon: <RiProfileLine className={'text-color'} fontSize={18} />,
        content: applicant?.title,
      },
      {
        icon: <RiMapPinLine className={'text-color'} fontSize={18} />,
        content: applicant?.location,
      },
    ];
  }, [applicant?.location, applicant?.title]);

  const onClick = () => {
    navigate(`/candidates/${candidateId}/view-candidate`);
  };

  return (
    <React.Fragment>
      <Typography.Title type={'secondary'} level={5} className={'title mb-4'}>
        Candidate <span className='title-divider' style={{maxWidth: 60}} />
      </Typography.Title>
      <AppBorderBox className='mb-6'>
        <AppUserProfile
          className={'card-meta-root-lg'}
          avatarUrl={applicant?.resource?.avatar_url}
          avatarProps={{size: 80, lineHeight: '72px'}}
          title={applicant.name}
          isCompany={false}
          titleProps={{
            onClick,
          }}
          items={items}
        />
      </AppBorderBox>
    </React.Fragment>
  );
};

CandidateCardDetail.propTypes = {
  applicant: PropTypes.object,
  candidateId: PropTypes.string,
};

export default CandidateCardDetail;
