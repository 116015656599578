import AppCard from '@wieldy/components/AppCard';
import {Typography} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

const Education = ({educations, isCard = true}) => {
  const renderContent = () => (
    <table className='table' style={{margin: '0 -8px'}}>
      <tbody>
        {!!educations &&
          educations.map((el, index) => (
            <tr key={el.specialty + index}>
              <td>
                <Typography.Text>{el.degree}</Typography.Text>
                <br />
                <Typography.Text type='secondary'>
                  {el.city ? `${el.city}, ${el.university}` : el.university}
                </Typography.Text>
              </td>
              <td align='right'>{el.year}</td>
            </tr>
          ))}
      </tbody>
    </table>
  );

  if (!educations?.length) {
    return null;
  }

  if (isCard) {
    return (
      <AppCard
        title='Education'
        headStyle={{borderBottom: 0}}
        bodyStyle={{paddingTop: 0}}>
        {renderContent()}
      </AppCard>
    );
  }

  return (
    <>
      <Typography.Title level={4}>Education</Typography.Title>
      {renderContent()}
    </>
  );
};

Education.propTypes = {
  educations: PropTypes.array,
  isCard: PropTypes.bool,
};

export default Education;
