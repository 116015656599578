/**
 * Clean String
 * @param string
 * @returns {string|*}
 */
export const cleanString = (string) => {
  if (string) return string.replace(/"/g, '').replace(/,/g, '');

  return '';
};

/**
 * Get Avatar Placeholder String
 * @param string
 * @returns {string}
 */
export const getAvatarPlaceholder = (string) => {
  if (string) return string.charAt(0).toUpperCase();

  return '';
};

/**
 * Truncate String value
 * @param str
 * @param num
 * @param ellipsis
 * @returns {string|*}
 */
export const truncateString = (str, num = 16, ellipsis = true) => {
  if (!str || str.length <= num) {
    return str;
  }

  return str.slice(0, num - 2) + (ellipsis && '...');
};

export const formatColumnName = (text) => {
  if (text) return capitalizedString(text.replaceAll('_', ' '));
  return '-';
};

export const upperCaseString = (text) => {
  if (text) return text.toUpperCase();
  return '-';
};
export const capitalizedString = (text) => {
  if (text) return text.charAt(0).toUpperCase() + text.slice(1);
  return '-';
};

export const capitalizedAllWords = (words) => {
  if (!words) {
    return '';
  }
  const wordsArray = words.split(' ');
  let outputString = '';

  for (let word of wordsArray) {
    outputString += `${capitalizedString(word)} `;
  }

  return outputString;
};

export const formatString = (text) => {
  if (text) return text.replaceAll('_', ' & ').toUpperCase();
  return '-';
};

export const removeSpaceFromString = (string) => {
  return string.replace(/ /g, '');
};

export const formatPhoneNumber = (phoneNumberString) => {
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const intlCode = match[1] ? '+1 ' : '';
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }
  return phoneNumberString;
};
