import AppDynamicTabs from '@wieldy/components/AppTabs/AppDynamicTabs';
import AppPageContainer from '@wieldy/components/pageComponents/AppPageContainer';
import AppPageHeader from '@wieldy/components/pageComponents/AppPageHeader';
import {capitalizedString} from '@wieldy/utils/helpers/StringHelper';
import {CATEGORIES} from 'app/constants/index.js';
import {useAccountType} from 'app/hooks/useAccountType';
import All from 'app/pages/main/resources/MainContent/All.js';
import Browse from 'app/pages/main/resources/MainContent/Browse.js';
import PageQueryProviderNew from 'app/providers/PageQueryProvider/PageQueryProviderNew';
import {filterConfig} from 'app/shared/resource/FilterResources';
import React, {useEffect, useMemo} from 'react';
import {useNavigate, useParams} from 'react-router-dom';

import {
  clientPageQueries,
  extraTabs,
  pageQueriesSupplier,
  supplierTabs,
} from './MainContent/constants';

const Resources = () => {
  const {category} = useParams();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = React.useState();
  const {isClient, isSupplier} = useAccountType();
  const isAll = useMemo(() => category === CATEGORIES.ALL, [category]);

  useEffect(() => {
    if (
      isSupplier &&
      (!category ||
        category === CATEGORIES.BROWSE ||
        category === CATEGORIES.BOOKMARKED)
    ) {
      navigate('/resources/all');
    }
    if (
      !isSupplier &&
      category !== CATEGORIES.BROWSE &&
      category !== CATEGORIES.BOOKMARKED
    ) {
      navigate('/resources/browse');
    }
  }, [isSupplier, category, navigate]);

  const activeKey = React.useMemo(() => {
    return category ?? (isSupplier ? CATEGORIES.ALL : CATEGORIES.BROWSE);
  }, [category, isSupplier]);

  const pageQueries = useMemo(
    () => (isClient ? clientPageQueries : pageQueriesSupplier),
    [isClient],
  );

  return (
    <PageQueryProviderNew
      category={category}
      pageParams={pageQueries}
      filterConfig={filterConfig}>
      <AppPageContainer
        header={
          <AppPageHeader
            title={`${capitalizedString(activeTab?.key)} Resources`}
            category={'Resources'}
            count={activeTab?.counts}
            tabs={
              isSupplier ? (
                <AppDynamicTabs
                  tabsData={supplierTabs}
                  activeKey={activeKey}
                  setActiveTab={setActiveTab}
                />
              ) : (
                []
              )
            }
            extra={
              isClient ? (
                <AppDynamicTabs
                  className='tabs-secondary'
                  tabsData={extraTabs}
                  activeKey={activeKey}
                  setActiveTab={setActiveTab}
                />
              ) : null
            }
          />
        }>
        {isAll ? <All /> : <Browse />}
      </AppPageContainer>
    </PageQueryProviderNew>
  );
};

export default Resources;
