import AppLoader from '@wieldy/components/AppLoader';
import {useGetTimeCard} from 'app/services/timecardsService';
import {
  setEditMode,
  setEndDate,
  setEngagement,
  setItems,
} from 'app/store/features/timecards/timecardsSlice';
import moment from 'moment';
import React, {useEffect, useMemo} from 'react';
import {useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';

import CreateTimecard from '../CreateTimecard';

const EditTimeCard = () => {
  let {id} = useParams();
  const dispatch = useDispatch();
  const {data, isLoading} = useGetTimeCard(id);
  const {timecard, entries} = data || {timecard: null, entries: null};

  const items = useMemo(() => {
    if (!entries) {
      return [];
    }
    return entries.map((el) => {
      return {
        id: el.id,
        engagement_task: el.engagement_task.id,
        taskHid: el.engagement_task.hid,
        taskName: el.engagement_task.description,
        work_date: moment(el.work_date).format('YYYY-MM-DD'),
        minutes: +el.minutes,
        type: el.type,
        notes: el.notes,
      };
    });
  }, [entries]);

  useEffect(() => {
    dispatch(setEditMode());
    if (timecard) {
      dispatch(setEngagement(timecard.engagement));
      dispatch(setEndDate(timecard.end_date));
    }
  }, [dispatch, timecard]);

  useEffect(() => {
    if (items) {
      dispatch(setItems(items));
    }
  }, [dispatch, items]);

  if (isLoading) {
    return <AppLoader />;
  }
  return <CreateTimecard isEditMode={true} />;
};

export default EditTimeCard;
