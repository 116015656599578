import {APPLICANT_TYPE_KEYS} from '../constants';
import ResourceModal from './ResourceModal';

class ApplicantModal extends ResourceModal {
  constructor(data = {}) {
    super(data);
    this.type = data?.type || 'temporary';
    this.isPermanent = data?.type === APPLICANT_TYPE_KEYS.permanent;
    this.base_salary = data?.base_salary || null;
    this.base_salary_min = data?.base_salary_min || null;
    this.base_salary_max = data?.base_salary_max || null;
    this.base_salary_period = data?.base_salary_period || null;
  }
}

export default ApplicantModal;
