import {getSelectPlaceHolder} from '@wieldy/utils/helpers/MessageHelper';
import {Button, Modal, Select, Typography} from 'antd';
import {capitalizeFirstLetter} from 'app/utils/helpers/Capitalize.js';
import PropTypes from 'prop-types';
import React, {useState} from 'react';

const RejectPopup = ({isModalOpen, handleReject, toggleOpen, type}) => {
  const title = capitalizeFirstLetter(type);
  const [comment, setComment] = useState(`Invalid ${title}`);

  const handleChange = ({value}) => {
    setComment(value);
  };

  return (
    <Modal
      title={`Reject ${title}`}
      open={isModalOpen}
      width={800}
      onCancel={() => toggleOpen(false)}
      footer={[
        <Button
          type='primary'
          key='submit'
          onClick={() => handleReject(comment)}>{`Reject ${title}`}</Button>,
        <Button
          type='primary'
          ghost
          key='cancel'
          onClick={() => toggleOpen(false)}>
          Cancel
        </Button>,
      ]}>
      <>
        <Typography.Paragraph>
          If you would like to reject this {type}, please provide a reason for
          rejecting and then click Reject {title} button.
        </Typography.Paragraph>
        <Typography.Paragraph>
          If you&apos;d like to reconsider this action, click Cancel.
        </Typography.Paragraph>
        <Select
          labelInValue='Reason for Rejecting'
          placeholder={getSelectPlaceHolder('reason')}
          style={{width: 300}}
          onChange={handleChange}
          options={[
            {value: `Invalid ${title}`, label: `Invalid ${title}`},
            {
              value: 'Need Additional Details',
              label: 'Need Additional Details',
            },
            {value: `Duplicate ${title}`, label: `Duplicate ${title}`},
          ]}
        />
      </>
    </Modal>
  );
};

RejectPopup.propTypes = {
  isModalOpen: PropTypes.bool,
  handleReject: PropTypes.func,
  toggleOpen: PropTypes.func,
  type: PropTypes.string,
};

export default RejectPopup;
