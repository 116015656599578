import {useAccountType} from 'app/hooks/useAccountType';
import React from 'react';

import ClientReviews from './ClientReviewsTable';
import ResourceReviews from './ResourceReviews';
import SupplierReviews from './SupplierReviews';

const ReviewTables = () => {
  const {isSupplier} = useAccountType();

  return (
    <React.Fragment>
      {isSupplier ? (
        <ClientReviews />
      ) : (
        <>
          <SupplierReviews className='mb-8' />
          <ResourceReviews />
        </>
      )}
    </React.Fragment>
  );
};

export default React.memo(ReviewTables);
