export const expenseStatuses = {
  approved: 'Approved',
  new: 'Not Submitted',
  rejected: 'Rejected',
  submitted: 'Pending Approval',
};

export const expenseRejectReasons = [
  {
    label: 'Select Reason',
    value: '',
  },
  {
    label: 'Expense are high',
    value: 'expense-high',
  },
  {
    label: 'Invoice not full filled',
    value: 'invoice-issue',
  },
];
