import {Button, Space, Typography} from 'antd';
import {useAccountType} from 'app/hooks/useAccountType';
import {useStatus} from 'app/hooks/useStatus';
import {capitalizeFirstLetter} from 'app/utils/helpers/Capitalize';
import propTypes from 'prop-types';
import React, {useMemo} from 'react';

import RejectActionModel from './RejectActionModel';

const withdrawReasons = [
  {
    value: 'Not Interested',
  },
  {
    value: 'No Longer Available',
  },
];

const declineReasons = [
  {
    value: 'Requisition Fully Filled',
  },
  {
    value: 'No Longer Needed',
  },
];

const ApproveRejectActions = ({
  handleEditMode,
  onReject,
  isReviewMode,
  handleAdd,
  status,
  isVisibleModal = false,
  handleVisibleModal,
  isShowRespondButton,
}) => {
  const {isSupplier, isClient} = useAccountType();
  const {isResponded} = useStatus(status);
  const isClosed = ['closed', 'engaged', 'withdrawn', 'declined'].includes(
    status,
  );
  const clientButtonName = isResponded ? 'close' : 'withdraw';
  const type = isSupplier ? clientButtonName : 'decline';
  const title = capitalizeFirstLetter(type);

  const reasonFor = isSupplier
    ? isResponded
      ? 'closing'
      : 'withdrawing'
    : 'declining';
  const description = capitalizeFirstLetter(reasonFor);

  const rejectReasons = useMemo(
    () => (isClient ? declineReasons : withdrawReasons),
    [isClient],
  );

  const handleRejectExpense = (reason) => {
    const status = isSupplier
      ? isResponded
        ? 'closed'
        : 'withdrawn'
      : 'declined';
    handleVisibleModal(false);
    if (onReject) {
      onReject(status, reason);
    }
  };

  return (
    <Space
      className='mt-6'
      size={isClient ? 'large' : 50}
      direction={isClient ? 'horizontal' : 'vertical'}>
      {isShowRespondButton &&
        isClient &&
        (isReviewMode ? (
          <Button htmlType='submit' type='primary'>
            Respond to Supplier
          </Button>
        ) : (
          <Button htmlType='button' type='primary' onClick={handleEditMode}>
            Review Submission
          </Button>
        ))}
      {isSupplier && !isClosed && (
        <Button type='primary' htmlType='button' ghost onClick={handleAdd}>
          +Add More Candidates
        </Button>
      )}
      {!isClosed && (
        <div>
          <Button
            htmlType='button'
            type='primary'
            ghost={isClient}
            onClick={() => handleVisibleModal(true)}>
            {`${title} Submission`}
          </Button>
          <RejectActionModel
            title={`${title} Submission`}
            description={`Reason for ${description}`}
            visible={isVisibleModal}
            reasons={rejectReasons}
            onOk={handleRejectExpense}
            onCancel={() => handleVisibleModal(false)}>
            <Typography.Paragraph>
              If you would like to {type} this submission, please provide a
              reason for {reasonFor} and then click {title} Submission button.
              If you&#39;d like to reconsider this action, click Cancel.
            </Typography.Paragraph>
          </RejectActionModel>
        </div>
      )}
    </Space>
  );
};

ApproveRejectActions.propTypes = {
  isReviewMode: propTypes.bool,
  status: propTypes.string,
  onApprove: propTypes.func,
  onReject: propTypes.func,
  handleEditMode: propTypes.func,
  handleAdd: propTypes.func,
  form: propTypes.object,
  isVisibleModal: propTypes.bool,
  handleVisibleModal: propTypes.func,
  isShowRespondButton: propTypes.bool,
};

export default ApproveRejectActions;
