import AppInput from '@wieldy/components/dataEntry/AppInput';
import {getInputPlaceHolder} from '@wieldy/utils/helpers/MessageHelper';
import {Form} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

import {preventNegativeNumber} from '../../../../../../../utils/helpers/preventNegativeNumber';
import {validator} from '../validation';

const CoursesForm = (props) => {
  const {last, children} = props;

  if (last) {
    return (
      <tr {...props}>
        <td className='ant-table-cell'>
          <Form.Item name='course' rules={[]}>
            <AppInput
              placeholder={getInputPlaceHolder('name')}
              controls={false}
            />
          </Form.Item>
        </td>
        <td className='ant-table-cell'>
          <Form.Item name='year' rules={[{validator}]}>
            <AppInput
              onKeyPress={preventNegativeNumber}
              min={0}
              type='number'
              placeholder={getInputPlaceHolder('year')}
              controls={false}
            />
          </Form.Item>
        </td>
        <td
          className='ant-table-cell ant-table-cell-fix-right ant-table-cell-fix-right-first'
          style={{textAlign: 'center', position: 'sticky', right: 0}}
        />
      </tr>
    );
  }
  return <tr {...props}>{children}</tr>;
};

CoursesForm.propTypes = {
  last: PropTypes.bool,
  children: PropTypes.node,
};

export default CoursesForm;
