import AppFilterWrapper from '@wieldy/components/AppFilterWrapper';
import AppGridView from '@wieldy/components/AppGridView';
import AppLoader from '@wieldy/components/AppLoader';
import AppPagination from '@wieldy/components/AppPagination';
import AppHeaderPagination from '@wieldy/components/AppPagination/AppHeaderPagination';
import AppPageSection from '@wieldy/components/pageComponents/AppPageSection';
import {Empty} from 'antd';
import {useAccountType} from 'app/hooks/useAccountType.js';
import {useBrowse} from 'app/hooks/useBrowse.js';
import usePageQuery from 'app/hooks/usePageQuery';
import RequisitionModal from 'app/modals/RequisitionModal';
import {sortParams} from 'app/pages/main/requisitions/data';
import {useGetRequisitions} from 'app/services/requisitionService';
import {FilterButton} from 'app/shared/components';
import FilterResults from 'app/shared/components/FIlter/FilterResults';
import {FilterRequisitions, RequisitionCard} from 'app/shared/requisitions';
import {getItemsData} from 'app/utils/helpers/GetItemsData.js';
import React, {useMemo} from 'react';
import {useLocation, useParams} from 'react-router-dom';

const Browse = () => {
  const {isSupplier} = useAccountType();
  const {category} = useParams();
  const {pathname} = useLocation();
  const isBrowse = pathname.endsWith('browse');

  const initSort = useMemo(() => sortParams[category][0].value, [category]);

  const {
    pageSize,
    currentPage,
    openFilter,
    onChangePage,
    onShowSizeChange,
    onToggleFilter,
    sortBy,
    onSortBy,
  } = useBrowse({initSort, initPageSize: 15});

  const {
    handleSetFilter,
    resetFilter,
    filterState,
    filterQuery,
    queryInit,
    currentPageParams,
  } = usePageQuery();

  const {rowTitle, empty} = currentPageParams;

  const {isLoading, data} = useGetRequisitions({
    page: currentPage - 1,
    limit: pageSize,
    sort: [sortBy],
    ...queryInit,
    ...filterQuery,
  });

  const [requisitions, totalCount] = getItemsData('requisitions', data);

  const isEmpty = !requisitions?.length;
  const isFilter = Object.values(filterState).some((filter) => !!filter);

  const handleOnApplyFilter = React.useCallback(
    (form) => {
      handleSetFilter(form);
      onToggleFilter(false);
    },
    [handleSetFilter, onToggleFilter],
  );

  if (isLoading) {
    return <AppLoader />;
  }

  return (
    <>
      {isEmpty && !isFilter ? (
        <Empty description={empty} />
      ) : (
        <AppPageSection
          title={rowTitle}
          count={totalCount}
          extra={
            <React.Fragment>
              {isSupplier && isBrowse && (
                <FilterButton
                  onClick={() => onToggleFilter(!openFilter)}
                  enabled={data?.requisitions?.length > 0 || isFilter}
                />
              )}
              <AppHeaderPagination
                total={totalCount}
                current={currentPage}
                pageSize={pageSize}
                onChange={onChangePage}
                onShowSizeChange={onShowSizeChange}
                showSizeChanger
                showSortBy
                sortByOptions={[
                  {label: 'Alphabetically', value: '+job_title'},
                  ...sortParams[category],
                ]}
                sortBy={sortBy}
                onSortByChange={onSortBy}
                hideStartBorder={true}
              />
            </React.Fragment>
          }>
          {isSupplier && isBrowse && (
            <>
              <AppFilterWrapper
                title='Filter Requisitions'
                initialValues={filterState}
                open={openFilter}
                onApply={handleOnApplyFilter}
                onReset={resetFilter}
                onClose={() => onToggleFilter(false)}>
                <FilterRequisitions />
              </AppFilterWrapper>
              <FilterResults />
            </>
          )}
          <AppGridView
            data={requisitions}
            emptyTitle={empty}
            loading={isLoading}
            renderItem={(requisition) => (
              <RequisitionCard
                key={requisition.id}
                requisition={new RequisitionModal(requisition)}
                showClient={isSupplier}
              />
            )}
          />

          <AppPagination
            total={data?.total_count}
            current={currentPage}
            pageSize={pageSize}
            onChange={onChangePage}
            onShowSizeChange={onShowSizeChange}
          />
        </AppPageSection>
      )}
    </>
  );
};

export default Browse;
