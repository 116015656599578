import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  task: {},
  rate: {},
  isEditMode: false,
};

export const engagementTablesSlice = createSlice({
  name: 'engagement-tables',
  initialState,
  reducers: {
    setEngagementRate: (state, action) => {
      state.rate = action.payload;
      state.isEditMode = true;
    },
    setEngagementTask: (state, action) => {
      state.task = action.payload;
      state.isEditMode = true;
    },
    resetEngagementTasksState: () => initialState,
  },
});

export const {resetEngagementTasksState, setEngagementTask, setEngagementRate} =
  engagementTablesSlice.actions;

export default engagementTablesSlice.reducer;
