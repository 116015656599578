import {Row} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

const AppCountersList = ({children, ...restProps}) => {
  return (
    <Row gutter={16} className='mb-6' {...restProps}>
      {children}
    </Row>
  );
};

AppCountersList.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  noGap: PropTypes.bool,
};

export default AppCountersList;
