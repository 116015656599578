import {getRecruitingServices} from '@register-app/utils/get-recruiting-services';

export const initialCompanyInfo = {
  name: '',
  company_dba_name: '',
  type: [],
  company_type: undefined,
  company_size: undefined,
  website: '',
  linkedin: '',
  years_in_business: undefined,
  tax_jurisdiction: undefined,
  company_recruiting_services: getRecruitingServices(),
  placement_type: {
    placement_contingent_placement_fee: undefined,
    placement_retained_fee: undefined,
    placement_temporary_hires: undefined,
    placement_temp_to_perm: undefined,
  },
  number_of_recruiters: undefined,
  ats_used_by_company: undefined,
  about: '',
};
