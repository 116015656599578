import {WORK_TYPE_KEYS} from '../../constants/work-types';

export const getCardBottomContent = (item, state) => {
  return [
    {
      label: item?.inquiries === 1 ? 'Inquiry' : 'Inquiries',
      count: item?.inquiries,
      path: 'inquiries',
      state,
    },
    {
      label: item?.submissions === 1 ? 'Submission' : 'Submissions',
      count: item?.submissions,
      path: 'submissions',
      state,
    },
    {
      label:
        item.type === WORK_TYPE_KEYS.direct_hire
          ? item?.placements === 1
            ? 'Placement'
            : 'Placements'
          : item?.engagements === 1
          ? 'Engagement'
          : 'Engagements',
      count:
        item.type === WORK_TYPE_KEYS.direct_hire
          ? item?.placements
          : item?.engagements,
      path: item.type === 'direct_hire' ? 'placements' : 'engagements',
      state,
    },
  ];
};

export const getDirectCardBottomContent = (item, state) => {
  return [
    {
      label: item?.submissions === 1 ? 'Submission' : 'Submissions',
      count: item?.submissions,
      path: 'submissions',
      state,
    },
    {
      label: item?.placements === 1 ? 'Placement' : 'Placements',
      count: item?.placements,
      path: 'placements',
      state,
    },
  ];
};
