import {useForm, useWatch} from 'antd/es/form/Form';
import {isBoolean} from 'lodash';
import React, {useCallback, useState} from 'react';
import {useDispatch} from 'react-redux';
import {v4 as uuidv1} from 'uuid';

export const useSubmitTableForm = (
  fields = [],
  data = [],
  getMappedEl = (el) => el,
  removeItem,
  key = null,
) => {
  const dispatch = useDispatch();

  const mappedData = data.map((el) => getMappedEl(el));

  const [form] = useForm();

  const watcher = useWatch;

  const getFieldValue = (fieldName, form) => {
    return watcher(fieldName, form);
  };
  const values = fields.map((el) => getFieldValue(el, form));

  const isEnableAddButton = values
    .filter((el) => !isBoolean(el))
    .every((el) => {
      if (Array.isArray(el)) {
        return el.some((el) => !!el);
      }
      return !!el;
    });

  const isSomeValue = values.filter((el) => !isBoolean(el)).some((el) => !!el);

  const [editId, setEditId] = useState(null);

  const handleEditId = useCallback((id) => {
    setEditId(id);
  }, []);

  const isEdit = !!editId;

  const handleSubmit = (commonPayload, addAction, editAction) => {
    const addPayload = key
      ? {key, data: {...commonPayload, tempId: uuidv1()}}
      : {...commonPayload, tempId: uuidv1()};

    const editPayload = key
      ? {key, data: {...commonPayload, id: editId}}
      : {...commonPayload, id: editId};

    if (isEdit) {
      dispatch(editAction(editPayload));
      setEditId(null);
    } else {
      dispatch(addAction(addPayload));
    }
    form.resetFields();
  };

  const handleResetForm = () => {
    form.resetFields();
    setEditId(null);
  };

  const bulkActions = React.useCallback(
    (some) => [
      {
        title: 'Edit',
        onClick: () => {
          if (Object.keys(some).includes('tagId')) {
            form.setFieldsValue({
              ...some,
              tagId: {label: some?.name, value: some?.tagId},
            });
          } else {
            form.setFieldsValue(some);
          }

          handleEditId(some.id || some.tempId);
        },
      },
      {
        title: 'Delete',
        onClick: () => {
          dispatch(
            removeItem(
              key
                ? {key, data: some.id || some.tempId}
                : some.id || some.tempId,
            ),
          );
        },
      },
    ],
    [form, handleEditId, dispatch, removeItem, key],
  );

  return {
    form,
    data: mappedData,
    isEnableAddButton,
    isSomeValue,
    isEdit,
    handleResetForm,
    handleSubmit,
    bulkActions,
    watcher,
  };
};
