import AppCard from '@wieldy/components/AppCard';
import AppFormWrapper, {
  AppFormActions,
  AppFormBody,
} from '@wieldy/components/AppFormWrapper';
import AppInput from '@wieldy/components/dataEntry/AppInput';
import AppInputNumber from '@wieldy/components/dataEntry/AppInputNumber';
import AppSelect from '@wieldy/components/dataEntry/AppSelect';
import {
  getInputPlaceHolder,
  getRequiredMessage,
  getSelectPlaceHolder,
} from '@wieldy/utils/helpers/MessageHelper';
import {Button, Col, Form, Row} from 'antd';
import {CompanyUserDropdown} from 'app/shared/components/dropdowns';
import RequisitionHelper from 'app/utils/RequisitionHelper';
import PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import {useLocation} from 'react-router-dom';

import {ACCEPT_CANDIDATES_FROM_MAP} from '../../../../constants';
import {usePrevious} from '../../../../hooks/usePrevious';

const SelectionForm = ({onContinue, onSave, initialValues}) => {
  const [manager, setManager] = React.useState(initialValues?.hiringManager);
  const [recruiter, setRecruiter] = React.useState(
    initialValues?.recruiterOption,
  );
  const location = useLocation();

  const isEditMode = !location.pathname.includes('new-requisition');

  const [form] = Form.useForm();

  const type = Form.useWatch('type', form);

  const prevType = usePrevious(type);

  const handleChangeManager = React.useCallback((_, option) => {
    setManager(option);
  }, []);

  const handleChangeRecruiter = React.useCallback((_, option) => {
    setRecruiter(option);
  }, []);

  const onFormSubmit = React.useCallback(
    (formdata) => {
      const payload = {
        ...formdata,
        hiring_manager: manager,
        recruiter,
      };

      onSave(payload);
      onContinue();
    },
    [manager, onContinue, onSave, recruiter],
  );

  useEffect(() => {
    if (!type) return;
    if (isEditMode && !prevType) return;

    if (type !== prevType) {
      const initialCandidateFromValue =
        Object.keys(ACCEPT_CANDIDATES_FROM_MAP[type]) || [];

      form.setFieldValue('accept_candidates_from', initialCandidateFromValue);
      form.validateFields(['accept_candidates_from']);
    }
  }, [type, initialValues, form, prevType, isEditMode]);

  return (
    <AppCard>
      <AppFormWrapper>
        <Form
          form={form}
          layout='vertical'
          onFinish={onFormSubmit}
          autoComplete='off'
          initialValues={initialValues}>
          <AppFormBody>
            <Form.Item
              name='job_title'
              label='Job Title'
              style={{width: '280px'}}
              rules={[
                {
                  required: true,
                  message: getRequiredMessage('Job Title'),
                },
              ]}>
              <AppInput
                placeholder={getInputPlaceHolder('requisition job title')}
              />
            </Form.Item>
            <Form.Item
              name='openings'
              label='# of Openings'
              rules={[
                {
                  required: true,
                  message: getRequiredMessage('Openings'),
                },
                {
                  type: 'number',
                },
              ]}>
              <AppInputNumber
                min={1}
                style={{width: '280px'}}
                placeholder={getInputPlaceHolder('# of openings')}
              />
            </Form.Item>

            <Row>
              <Col style={{maxWidth: 280, width: '100%'}} className='mr-4'>
                <Form.Item
                  name='type'
                  label='Type'
                  style={{width: '280px'}}
                  rules={[
                    {
                      required: true,
                      message: getRequiredMessage('Type'),
                    },
                  ]}>
                  <AppSelect
                    disabled={isEditMode}
                    placeholder={getInputPlaceHolder('job type')}
                    options={RequisitionHelper.workTypes()}
                  />
                </Form.Item>
              </Col>

              {type && (
                <Col style={{maxWidth: 280, width: '100%'}}>
                  <Form.Item
                    name='accept_candidates_from'
                    label='Accept Candidates From'
                    style={{width: '280px'}}
                    rules={[
                      {
                        required: true,
                        message: getRequiredMessage('Type'),
                      },
                    ]}>
                    <AppSelect
                      placeholder={getInputPlaceHolder('sourcing channel')}
                      options={RequisitionHelper.acceptCandidatesFrom(type)}
                      allowClear
                      defaultValues={
                        type
                          ? Object.keys(ACCEPT_CANDIDATES_FROM_MAP[type])
                          : []
                      }
                      mode='multiple'
                    />
                  </Form.Item>
                </Col>
              )}
            </Row>

            <Form.Item
              name='priority'
              label='Priority'
              style={{width: '280px'}}
              rules={[
                {
                  required: true,
                  message: getRequiredMessage('Priority'),
                },
              ]}>
              <AppSelect
                placeholder={getInputPlaceHolder('requisition priority')}
                options={RequisitionHelper.jobPriorities()}
              />
            </Form.Item>

            <Form.Item
              name='job_status'
              label='Job Status'
              style={{width: '280px'}}
              rules={[
                {
                  required: true,
                  message: getRequiredMessage('Job Status'),
                },
              ]}>
              <AppSelect
                placeholder={getSelectPlaceHolder('job status')}
                options={RequisitionHelper.jobStatus()}
              />
            </Form.Item>

            <Form.Item
              name='recruiter'
              label='Recruiter'
              style={{width: '280px'}}
              rules={[
                {
                  required: true,
                  message: getRequiredMessage('Recruiter'),
                },
              ]}>
              <CompanyUserDropdown
                placeholder='Search for recruiter'
                variant='client'
                role='recruiter'
                manager={recruiter}
                initialFetch
                onChange={handleChangeRecruiter}
              />
            </Form.Item>

            <Form.Item
              name='hiring_manager'
              label='Hiring Manager'
              style={{width: '280px'}}
              rules={[
                {
                  required: false,
                  message: getRequiredMessage('Hiring Manager'),
                },
              ]}>
              <CompanyUserDropdown
                placeholder='Search for hiring manager'
                variant='client'
                role='hiring_manager'
                manager={manager}
                onChange={handleChangeManager}
              />
            </Form.Item>
          </AppFormBody>

          <AppFormActions>
            <Button type='primary' htmlType='submit'>
              Continue
            </Button>
          </AppFormActions>
        </Form>
      </AppFormWrapper>
    </AppCard>
  );
};

SelectionForm.propTypes = {
  initialValues: PropTypes.object,
  onSave: PropTypes.func,
  onContinue: PropTypes.func,
};

export default SelectionForm;
