import React, {useCallback, useEffect, useMemo, useState} from 'react';

import * as noteService from '../services/noteService';
import {scrollToElement} from '../utils/helpers/AppHelper';

export const useNotes = (entity, primaryKey, primaryId, enabled = true) => {
  const [total, setTotal] = React.useState(0);

  const response = noteService.useGetNotes(
    {
      entity,
      primaryKey,
      primaryId,
    },
    total,
    {enabled},
  );

  const {data, isLoading} = response;

  const isNotes = useMemo(
    () => !!data?.pages?.length && data?.pages.some((el) => el.items.length),
    [data],
  );

  const [isShowNotes, setIsShowNotes] = useState(isNotes);

  useEffect(() => {
    setIsShowNotes(isNotes);
  }, [isNotes, setIsShowNotes]);

  const handleTotalNotes = useCallback((value) => {
    setTotal(value);
  }, []);

  useEffect(() => {
    if (data?.pages && data.pages[0]?.total_count) {
      return handleTotalNotes(data.pages[0]?.total_count);
    }
    handleTotalNotes(0);
  }, [data, handleTotalNotes]);

  const handleShowNotes = useCallback(() => {
    if (isShowNotes) {
      scrollToElement('notes');
      return;
    }
    setIsShowNotes(true);
  }, [isShowNotes]);

  useEffect(() => {
    if (!isNotes && isShowNotes && !isLoading) {
      scrollToElement('notes');
    }
  }, [isNotes, isShowNotes, isLoading]);

  if (!enabled) {
    return null;
  }
  return {
    handleShowNotes,
    isNotes,
    isShowNotes,
    total,
    response,
  };
};
