import React from 'react';

import iconAdd from './addButton.png';

const NotesBtnIcon = () => {
  return (
    <span style={{position: 'relative', width: 16, height: 16}}>
      <img
        style={{
          width: 16,
          height: 16,
          position: 'absolute',
          left: 2,
          top: 2,
        }}
        src={iconAdd}
        alt='icon'
      />
    </span>
  );
};

export default NotesBtnIcon;
