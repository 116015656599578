import AppLoader from '@wieldy/components/AppLoader';
import AppTable from '@wieldy/components/AppTable';
import {getTablePagination} from '@wieldy/utils/helpers/TableHelper';
import {Empty, Typography} from 'antd';
import {useBrowse} from 'app/hooks/useBrowse';
import {useGetInvoices} from 'app/services/invoicesService';
import {getItemsData} from 'app/utils/helpers/GetItemsData';
import React from 'react';
import {useNavigate, useParams} from 'react-router-dom';

import {getColumns} from './columns';

const Invoices = () => {
  const {id} = useParams();
  const {pageSize, onChangePage, currentPage, onShowSizeChange} = useBrowse({
    initPageSize: 5,
  });
  const navigate = useNavigate();

  const {isLoading, data, isFetching} = useGetInvoices({
    engagement: id,
    include: ['total_count'],
    sort: ['-created_at'],
    page: currentPage - 1,
    limit: pageSize,
  });

  const [invoices, totalCount] = getItemsData('invoices', data);

  const onInvoiceView = (invoice_id) => {
    navigate(`/financials/${invoice_id}/view-invoice`);
  };

  if (isLoading) {
    return <AppLoader />;
  }
  if (!invoices.length) {
    return null;
  }

  return (
    <div className='mt-6'>
      <Typography.Title level={4} className={'mb-5'}>
        Invoices
      </Typography.Title>
      <AppTable
        loading={isFetching}
        emptyText={<Empty description='No invoices' />}
        className={'column-valign-top'}
        columns={getColumns(onInvoiceView)}
        dataSource={invoices}
        pagination={getTablePagination(
          pageSize,
          currentPage,
          totalCount,
          onShowSizeChange,
          onChangePage,
        )}
      />
    </div>
  );
};

export default Invoices;
