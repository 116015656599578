import AppCard from '@wieldy/components/AppCard';
import AppHeaderPagination from '@wieldy/components/AppPagination/AppHeaderPagination';
import AppTable from '@wieldy/components/AppTable';
import AppTag from '@wieldy/components/AppTag/AppTag';
import TimePeriodFilterControl from '@wieldy/components/TimePeriodFilterControl';
import {getTablePagination} from '@wieldy/utils/helpers/TableHelper';
import {Empty, Space} from 'antd';
import {useStatus} from 'app/hooks/useStatus';
import {useTableDateFilter} from 'app/hooks/useTableDateFilter';
import {useTableSort} from 'app/hooks/useTableSort';
import {useGetExpenseList} from 'app/services/expenseService';
import PropTypes from 'prop-types';
import React, {useCallback, useMemo, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import {useGetColumns} from './columns';

const ExpenseCardsTable = ({title, status}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const navigate = useNavigate();
  const {isNew} = useStatus(status);

  const {sort, handleSort} = useTableSort({
    ['resource.first_name']: null,
    ['end_date']: 'descend',
  });

  const [filter, onChangeFilter] = useTableDateFilter();

  const {data} = useGetExpenseList({
    sort,
    filter,
    status,
    limit: pageSize,
    page: currentPage - 1,
  });

  const {totalCount, items} = data ?? {totalCount: 0, items: []};
  const isEmpty = useMemo(() => !data?.items?.length, [data]);

  const onChangeTable = useCallback(
    (a, b, sorter) => {
      if (sorter.columnKey && sorter?.order) {
        handleSort(sorter?.columnKey, sorter?.order);
      }
    },
    [handleSort],
  );

  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const onShowSizeChange = (current, pageSize) => {
    setCurrentPage(current);
    setPageSize(pageSize);
  };

  const bulkActions = React.useCallback(
    ({id}) => {
      return [
        {
          title: 'Edit',
          onClick: () => navigate(`/expenses/edit-expense/${id}`),
        },
      ];
    },
    [navigate],
  );

  const columns = useGetColumns(status);

  return (
    <AppCard
      title={
        <React.Fragment>
          {title}{' '}
          {!!totalCount && <AppTag className='ml-1'>{totalCount}</AppTag>}
        </React.Fragment>
      }
      headStyle={{borderBottom: 0}}
      bodyStyle={{paddingTop: '8px'}}
      extra={
        isEmpty ? null : (
          <Space>
            <TimePeriodFilterControl onChange={onChangeFilter} />
            <AppHeaderPagination
              total={totalCount || 1}
              current={currentPage}
              pageSize={pageSize}
              onChange={onPageChange}
              hideStartBorder
            />
          </Space>
        )
      }>
      <AppTable
        onChange={onChangeTable}
        className={'column-valign-top'}
        dataSource={items}
        columns={columns}
        bulkActions={isNew ? bulkActions : null}
        locale={{
          emptyText: <Empty description='No Available Expenses' />,
        }}
        pagination={getTablePagination(
          pageSize,
          currentPage,
          totalCount,
          onShowSizeChange,
          onPageChange,
        )}
      />
    </AppCard>
  );
};

ExpenseCardsTable.propTypes = {
  title: PropTypes.string,
  status: PropTypes.oneOf([
    'new',
    'submitted',
    'approved',
    'rejected',
    'invoiced',
  ]),
};

export default ExpenseCardsTable;
