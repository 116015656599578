import {Button, Space, Typography} from 'antd';
import useStep from 'app/hooks/useStep';
import PropTypes from 'prop-types';
import React from 'react';
import {useNavigate} from 'react-router-dom';

const SubmitRequisition = ({requisitionModal}) => {
  const navigate = useNavigate();
  const {currentStep, setIsPreview, finishSteps, isUpdated} = useStep();

  const step =
    requisitionModal.isDirectHire && !requisitionModal.isOnlyDirectSourcing
      ? 6
      : 5;

  const onCancelCreate = () => {
    navigate(-1);
  };

  return (
    <div>
      <Typography.Paragraph>
        Take the final action to post the requisition
      </Typography.Paragraph>
      {currentStep === step && (
        <Space>
          <Button type='primary' onClick={finishSteps}>
            {isUpdated ? 'Update Requisition' : 'Post Requisition'}
          </Button>
          <Button type='primary' onClick={() => setIsPreview(true)} ghost>
            Preview
          </Button>
          <Button onClick={onCancelCreate}>Cancel</Button>
        </Space>
      )}
    </div>
  );
};

SubmitRequisition.propTypes = {
  requisitionModal: PropTypes.object,
};

export default React.memo(SubmitRequisition);
