import AppSteps from '@wieldy/components/AppSteps';
import AppStep from '@wieldy/components/AppSteps/AppStep';
import AppStepTitle from '@wieldy/components/AppSteps/AppStep/AppStepTitle';
import useStep from 'app/hooks/useStep';
import React, {useRef} from 'react';
import {useSelector} from 'react-redux';

import CreateNote from './../AccompanyingMessage/CreateNote';
import ChooseCandidates from './ChooseCandidates';
import PostSubmission from './PostSubmission';
import SelectRequisition from './SelectRequisition';

const MainContent = () => {
  const {currentStep, goToStep} = useStep();
  const {isEditMode} = useSelector(({submission}) => submission);
  const refForm = useRef();
  return (
    <AppSteps current={currentStep}>
      <AppStep
        title={
          <AppStepTitle
            title='Requisition'
            isEdit={!isEditMode && currentStep > 1}
            onEditClick={() => goToStep(1)}
          />
        }
        step={1}
        description={<SelectRequisition />}
      />
      <AppStep
        step={2}
        title={
          <AppStepTitle
            title='Candidates'
            isEdit={currentStep > 2}
            onEditClick={() => goToStep(2)}
          />
        }
        description={<ChooseCandidates ref={refForm} />}
      />
      <AppStep
        step={3}
        title={
          <AppStepTitle
            title='Note to Client(s)'
            isEdit={currentStep > 3}
            onEditClick={() => goToStep(3)}
          />
        }
        description={<CreateNote />}
      />
      <AppStep
        step={4}
        title='Submit'
        description={<PostSubmission ref={refForm} />}
      />
    </AppSteps>
  );
};
export default React.memo(MainContent);
