import PropTypes from 'prop-types';
import React, {useCallback} from 'react';
import {useNavigate} from 'react-router-dom';

import AppTabPane from '../AppTabPane';
import AppTabs from '../AppTabs';
import AppTabTitle from '../AppTabTitle';

const AppDynamicTabs = ({
  tabsData,
  setActiveTab,
  scroll,
  scrollOffset,
  activeKey,
  defaultActiveKey,
  ...restProps
}) => {
  const navigate = useNavigate();
  const onTabChange = useCallback(
    (activeKey) => {
      const activeTab = tabsData.find((tab) => tab.key === activeKey);
      if (!activeTab) return;

      if (activeTab.route) {
        navigate(activeTab.route);
      }

      if (setActiveTab) setActiveTab(activeTab);
    },
    [navigate, setActiveTab, tabsData],
  );

  const tabs = React.useMemo(() => {
    return tabsData ? tabsData.filter((tab) => !tab.hasPermission) : [];
  }, [tabsData]);

  React.useEffect(() => {
    const activeTab = tabs.find((tab) => tab.key === activeKey);
    onTabChange(!activeTab ? defaultActiveKey : activeKey);
  }, [activeKey, defaultActiveKey, onTabChange, tabs]);

  const scrollToElement = (selectedKey) => {
    if (scroll === true) {
      const element = document.getElementById(selectedKey);
      if (element) {
        const elementPosition = element.getBoundingClientRect().top;
        const offsetPosition =
          elementPosition + window.scrollY - (scrollOffset || 80);
        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth',
        });
      }
    }
  };

  return (
    <AppTabs
      onTabClick={scrollToElement}
      onChange={onTabChange}
      activeKey={activeKey}
      {...restProps}>
      {tabs.map((tab) => {
        return (
          <AppTabPane
            tab={
              <AppTabTitle icon={tab.icon} tab={tab.title} count={tab.count} />
            }
            key={tab.key}>
            {tab?.content}
          </AppTabPane>
        );
      })}
    </AppTabs>
  );
};

AppDynamicTabs.propTypes = {
  tabsData: PropTypes.array,
  activeKey: PropTypes.string,
  setActiveTab: PropTypes.func,
  scroll: PropTypes.bool,
  scrollOffset: PropTypes.number,
  defaultActiveKey: PropTypes.string,
};

AppDynamicTabs.defaultProps = {
  tabsData: [],
};

export default AppDynamicTabs;
