import {UserOutlined} from '@ant-design/icons';
import AppDynamicTabs from '@wieldy/components/AppTabs/AppDynamicTabs';
import AppPageContainer from '@wieldy/components/pageComponents/AppPageContainer';
import AppPageHeader from '@wieldy/components/pageComponents/AppPageHeader';
import {Avatar, Row, Tag, Typography} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

const tabsData = [
  {
    key: 'user-information',
    title: 'User Information',
  },
  {
    key: 'reset-password',
    title: 'Reset Password',
  },
];

const User = ({
  user,
  title,
  back = false,
  extra,
  showTabs = false,
  children,
}) => {
  const [activeTab, setActiveTab] = React.useState('user-information');
  const userAvatar = user?.avatarUrl || user?.resource?.avatar_url;

  return (
    <AppPageContainer
      header={
        <AppPageHeader
          header='inner'
          category={title}
          extra={extra}
          tabs={
            showTabs && (
              <AppDynamicTabs
                tabsData={tabsData}
                activeKey={activeTab}
                setActiveTab={setActiveTab}
              />
            )
          }
          backHeader={back}>
          <Row align='middle'>
            <Avatar
              src={userAvatar}
              size={70}
              alt={user.name}
              icon={<UserOutlined />}
            />
            <Typography.Paragraph className='mb-0 mx-4'>
              <Typography.Title level={3}>
                {user.name} <Tag bordered={false}>{user.status}</Tag>
              </Typography.Title>
              <Typography.Text>{`${user.title} ${
                user.role ? `[${user.role}]` : ''
              }`}</Typography.Text>
            </Typography.Paragraph>
          </Row>
        </AppPageHeader>
      }>
      {children}
    </AppPageContainer>
  );
};

User.propTypes = {
  title: PropTypes.string,
  user: PropTypes.object,
  back: PropTypes.bool,
  extra: PropTypes.node,
  showTabs: PropTypes.bool,
  children: PropTypes.node,
};

export default User;
