import {getAssetsUrl} from '@wieldy/utils/helpers/UrlHelper';
import {useRole} from 'app/hooks/useRole';
import PropTypes from 'prop-types';
import React from 'react';
import {Link} from 'react-router-dom';

import CollapseMenuHandle from '../partials/CollapseMenuHandle';

const SidebarHeader = ({isCollapsed, toggleCollapsed, isMobileView}) => {
  const {isResource} = useRole();
  const renderLogo = () => {
    return (
      <>
        {isCollapsed ? (
          <img
            src={getAssetsUrl('images/brand/light/logo-icon.svg')}
            alt='Logo'
            title='Sourcer'
            width={'32'}
          />
        ) : (
          <span className='brand-name'>
            <img
              src={getAssetsUrl('images/brand/light/logo-light.png')}
              alt='Logo'
              title='Sourcer'
              width={'106'}
              style={{marginTop: '-8px', marginLeft: 14}}
            />
          </span>
        )}
      </>
    );
  };
  return (
    <div
      className='sidebar-header-container'
      onClick={isMobileView ? toggleCollapsed : undefined}>
      <div className='sidebar-header-logo'>
        {isResource ? (
          <div className='brand-logo'>{renderLogo()}</div>
        ) : (
          <Link to='/' className='brand-logo'>
            {renderLogo()}
          </Link>
        )}
      </div>

      <CollapseMenuHandle
        className='btn-icon'
        isCollapsed={isCollapsed}
        toggleCollapsed={toggleCollapsed}
      />
    </div>
  );
};

export default React.memo(SidebarHeader);

SidebarHeader.propTypes = {
  isCollapsed: PropTypes.bool,
  toggleCollapsed: PropTypes.func,
  isMobileView: PropTypes.bool,
};
