import AppFormWrapper, {
  AppFormActions,
  AppFormBody,
} from '@wieldy/components/AppFormWrapper';
import AppInput from '@wieldy/components/dataEntry/AppInput';
import AppSelect from '@wieldy/components/dataEntry/AppSelect';
import {
  getInputPlaceHolder,
  getRequiredMessage,
  getSelectPlaceHolder,
} from '@wieldy/utils/helpers/MessageHelper';
import {Button, DatePicker, Form, Modal, notification, Space} from 'antd';
import {
  useCreateEngagementRate,
  useUpdateEngagementRate,
} from 'app/services/engagementsService';
import {resetEngagementTasksState} from 'app/store/features/engagement/engaementTablesSlice';
import CommonHelper from 'app/utils/CommonHelper';
import moment from 'moment/moment';
import PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';

const RangePicker = DatePicker.RangePicker;

const NewEngagementRates = ({visiable, onCancel}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const {id} = useParams();
  const {rate, isEditMode} = useSelector(
    ({engagementTables}) => engagementTables,
  );
  const {mutate, isLoading} = (
    isEditMode ? useUpdateEngagementRate : useCreateEngagementRate
  )(isEditMode ? rate.id : undefined);

  useEffect(() => {
    if (rate && isEditMode) {
      form.setFieldsValue(rate);
    }
  }, [form, isEditMode, rate]);

  const handleClose = () => {
    dispatch(resetEngagementTasksState());
    form.resetFields();
    if (onCancel) {
      onCancel();
    }
  };

  const handleOnSubmit = (formData) => {
    const {start_end_date, billing_rate, ...rest} = formData;

    const payload = {
      engagement: id,
      billing_rate: billing_rate ? parseFloat(billing_rate) : undefined,
      start_date: start_end_date[0].unix(),
      end_date: start_end_date[1].unix(),
      ...rest,
    };

    mutate(payload, {
      onSuccess: () => {
        handleClose();
      },
      onError: () => {
        notification.error({message: 'Rate has not created'});
      },
    });
  };

  return (
    <Modal
      title={`${isEditMode ? 'Edit' : 'Add New'} Engagement Rate`}
      visible={visiable}
      onCancel={handleClose}
      footer={null}
      width={650}>
      <AppFormWrapper>
        <AppFormBody>
          <Form
            layout='vertical'
            form={form}
            onFinish={handleOnSubmit}
            style={{
              maxWidth: '300px',
            }}
            autoComplete='false'>
            <Form.Item
              label={'Start Date - End Date'}
              name={'start_end_date'}
              rules={[
                {
                  required: true,
                  message: getRequiredMessage('Start & end date'),
                },
              ]}>
              <RangePicker
                format='MM-DD-YYYY'
                disabledDate={(current) =>
                  current && current < moment().startOf('day')
                }
              />
            </Form.Item>

            <Form.Item
              name='billing_rate'
              label='Billing Rate'
              rules={[
                {
                  required: true,
                  message: getRequiredMessage('Billing rate'),
                },
              ]}>
              <AppInput
                prefix='$'
                suffix='USD'
                type='number'
                pattern='[0-9]+([\.,][0-9]+)?'
                step='any'
                min={0}
                placeholder={getInputPlaceHolder('billing rate')}
              />
            </Form.Item>

            <Form.Item
              name='billing_rate_period'
              label='Billing Rate Period'
              rules={[
                {
                  required: true,
                  message: getRequiredMessage('Pay period'),
                },
              ]}>
              <AppSelect
                showSearch
                placeholder={getSelectPlaceHolder('billing rate period')}
                options={CommonHelper.billingRatePeriods()}
              />
            </Form.Item>

            <AppFormActions>
              <Space size={'middle'} className='mt-2'>
                <Button type='primary' htmlType='submit' loading={isLoading}>
                  {isEditMode ? 'Update' : 'Create'}
                </Button>
                <Button type='link' onClick={onCancel}>
                  Cancel
                </Button>
              </Space>
            </AppFormActions>
          </Form>
        </AppFormBody>
      </AppFormWrapper>
    </Modal>
  );
};

NewEngagementRates.propTypes = {
  onCancel: PropTypes.func,
  setEngagementTasks: PropTypes.func,
  visiable: PropTypes.bool,
};

export default NewEngagementRates;
