import AppCard from '@wieldy/components/AppCard';
import AppFormWrapper, {
  AppFormActions,
  AppFormBody,
} from '@wieldy/components/AppFormWrapper';
import {
  getRequiredMessage,
  getSelectPlaceHolder,
} from '@wieldy/utils/helpers/MessageHelper';
import {Button, Form} from 'antd';
import {CompanyMemberDropdown} from 'app/shared/components/dropdowns';
import PropTypes from 'prop-types';
import React from 'react';

const AccountManagerForm = ({onContinue, account_manager, setState}) => {
  const [managers, setManagers] = React.useState({});
  const handleGetManagers = React.useCallback(
    (value) => setManagers(value),
    [],
  );
  const handleOnSubmit = (formData) => {
    const account_manager = managers.find((m) => m.id === formData.manager);
    setState({account_manager});
    if (onContinue) {
      onContinue();
    }
  };

  return (
    <AppCard>
      <AppFormWrapper>
        <Form
          layout='vertical'
          autoComplete='off'
          onFinish={handleOnSubmit}
          initialValues={{account_manager: account_manager?.id}}>
          <AppFormBody>
            <Form.Item
              name='manager'
              label='Account Manager'
              rules={[
                {
                  required: true,
                  message: getRequiredMessage('Account manager'),
                },
              ]}>
              <CompanyMemberDropdown
                variant={'marketplaceAll'}
                placeholder={getSelectPlaceHolder('account manager')}
                initialFetch={true}
                showArrow={false}
                allowClear={true}
                style={{minWidth: '280px', width: 'auto', maxWidth: '100%'}}
                getValue={handleGetManagers}
              />
            </Form.Item>
          </AppFormBody>

          <AppFormActions>
            <Button type='primary' htmlType='submit'>
              Continue
            </Button>
          </AppFormActions>
        </Form>
      </AppFormWrapper>
    </AppCard>
  );
};

AccountManagerForm.propTypes = {
  account_manager: PropTypes.string,
  onContinue: PropTypes.func,
  setState: PropTypes.func,
};

export default AccountManagerForm;
