import {Typography} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

const TableWrapper = ({children, title, isShowTable = true}) => {
  return (
    <>
      {isShowTable && (
        <div className='mb-10'>
          {!!title && <Typography.Title level={4}>{title}</Typography.Title>}
          {children}
        </div>
      )}
    </>
  );
};

TableWrapper.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  isShowTable: PropTypes.bool,
};

export default TableWrapper;
