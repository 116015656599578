import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  id_engagement: null,
  requisition: {
    id: undefined,
    job_title: undefined,
  },
  candidate: {
    id: undefined,
    name: undefined,
  },
  details: {},
  jobDetails: {},
};

export const engagementSlice = createSlice({
  name: 'engagement',
  initialState,
  reducers: {
    setEngagementId: (state, action) => {
      state.id_engagement = action.payload;
    },
    setEngagementRequisition: (state, action) => {
      state.requisition = action.payload;
    },
    setEngagementCandidate: (state, action) => {
      state.candidate = action.payload;
    },
    setEngagementDetails: (state, action) => {
      state.details = action.payload;
    },
    setEngagementJobDetails: (state, action) => {
      state.jobDetails = action.payload;
    },
    resetEngagementState: () => initialState,
  },
});

// Action creators are generated for each case reducer function
export const {
  setEngagementRequisition,
  setEngagementCandidate,
  setEngagementDetails,
  setEngagementJobDetails,
  resetEngagementState,
  setEngagementId,
} = engagementSlice.actions;

export default engagementSlice.reducer;
