import AppCard from '@wieldy/components/AppCard';
import {Typography} from 'antd';
import {getPhoneNumber} from 'app/utils/helpers/DataFormatHelper';
import PropTypes from 'prop-types';
import React from 'react';

const AccountOwnerView = ({details}) => {
  return (
    <AppCard style={{maxWidth: 820}}>
      <div className={'mb-5'}>
        <Typography.Title level={4}>First Name</Typography.Title>
        <Typography.Paragraph>{details?.first_name}</Typography.Paragraph>
      </div>
      <div className={'mb-5'}>
        <Typography.Title level={4}>Last Name</Typography.Title>
        <Typography.Paragraph>{details?.last_name}</Typography.Paragraph>
      </div>
      <div className={'mb-5'}>
        <Typography.Title level={4}>Title</Typography.Title>
        <Typography.Paragraph>{details?.title}</Typography.Paragraph>
      </div>
      <div className={'mb-5'}>
        <Typography.Title level={4}>Email Address</Typography.Title>
        <Typography.Paragraph>{details?.email}</Typography.Paragraph>
      </div>
      <div className={'mb-5'}>
        <Typography.Title level={4}>Phone Number</Typography.Title>
        <Typography.Paragraph>
          {getPhoneNumber(details?.phone_number)}
        </Typography.Paragraph>
      </div>
    </AppCard>
  );
};

AccountOwnerView.propTypes = {
  details: PropTypes.object,
};

export default AccountOwnerView;
