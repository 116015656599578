import {createSlice} from '@reduxjs/toolkit';
import moment from 'moment';
import {v4 as uuidv1} from 'uuid';

import {APPLICANT_TYPE_KEYS} from '../../../constants';

const initialState = {
  isInit: false,
  summary: {},
  billing: {
    billing_rate: null,
    billing_rate_min: null,
    billing_rate_max: null,
    billing_rate_period: '',
    billing_rate_negotiations: false,
    billing_rate_buy_out: false,
    work_location: '',
    work_computer_type: '',
    work_computer_os_type: '',
    work_conference_room_access: false,
    work_printer_scanner: '',
    work_extra_monitor: false,
  },
  skills: [],
  histories: [],
  supplemental: {
    educations: [],
    courses: [],
    languages: [],
    certifications: [],
  },
};

const editTable = (state, actionPayload) => {
  const {id, ...payload} = actionPayload;
  return state.map((el) => {
    if (el.id) {
      return el.id === id ? actionPayload : el;
    }
    if (el.tempId) {
      return el.tempId === id ? {...payload, tempId: id} : el;
    }
    return el;
  });
};

const deleteElementFromTable = (state, elementId) => {
  return state.filter(({id, tempId}) => {
    if (id) {
      return elementId !== id;
    }
    if (tempId) {
      return elementId !== tempId;
    }
    return true;
  });
};

const deleteSkillFromTable = (state, elementId) => {
  return state.map((element) => {
    if (element.id === elementId) {
      return {id: elementId, deleted: true};
    } else {
      return element;
    }
  });
};

export const resourceSlice = createSlice({
  name: 'resource',
  initialState,
  reducers: {
    resetResource: () => initialState,
    setInitial: (state, action) => {
      const {payload} = action;
      state.summary = {
        first_name: payload.first_name,
        last_name: payload.last_name,
        title: payload.title,
        country: payload.country,
        us_state: payload.us_state,
        city: payload.city,
        resume_key: payload.resume_key,
        phone_number: payload.phone_number,
        email: payload.email,
        temporary_resume_key: payload.temporary_resume_key,
        years_experience: payload.years_experience,
        availability: payload.availability,
        available_on: payload.available_on && moment(payload.available_on),
        available_to: payload.available_to && moment(payload.available_to),
        assignment_duration_min: payload.assignment_duration_min,
        assignment_duration_max: payload.assignment_duration_max,
        work_status: payload.work_status,
        timezone: payload.timezone,
        overtime_availability: payload.overtime_availability,
        travel_availability: payload.travel_availability,
        willing_to_relocate: payload.willing_to_relocate,
        work_arrangement: payload.work_arrangement,
        employed_since:
          payload.employed_since && moment(payload.employed_since),
        part_time_assignment: payload.part_time_assignment,
        education_degree_level: payload.education_degree_level,
        visa_status: payload.visa_status,
        type: payload.type,
        about: payload.about,
        isPermanent: payload.type === APPLICANT_TYPE_KEYS.permanent,
      };
      state.billing = {
        billing_rate: payload.billing_rate,
        billing_rate_min: payload.billing_rate_min,
        billing_rate_max: payload.billing_rate_max,
        billing_rate_period: payload.billing_rate_period,
        billing_rate_negotiations: payload.billing_rate_negotiations,
        billing_rate_buy_out: payload.billing_rate_buy_out,
        base_salary: payload.base_salary,
        base_salary_min: payload.base_salary_min,
        base_salary_max: payload.base_salary_max,
        base_salary_period: payload.base_salary_period,
        work_location: payload.work_location,
        work_computer_type: payload.work_computer_type,
        work_computer_os_type: payload.work_computer_os_type,
        work_conference_room_access: payload.work_conference_room_access,
        work_printer_scanner: payload.work_printer_scanner,
        work_extra_monitor: payload.work_extra_monitor,
      };
      state.skills = payload.skill_tags?.data || [];
      state.histories =
        payload.employment_history?.map((el) => ({
          ...el,
          tempId: uuidv1(),
        })) ?? [];
      state.supplemental = {
        educations: payload.educations.map((el) => ({...el, tempId: uuidv1()})),
        courses: payload.courses.map((el) => ({...el, tempId: uuidv1()})),
        languages: payload.languages.map((el) => ({...el, tempId: uuidv1()})),
        certifications: payload.certifications.map((el) => ({
          ...el,
          tempId: uuidv1(),
        })),
      };
      state.isInit = true;
    },
    setSummary: (state, action) => {
      state.summary = {
        ...action.payload,
        isPermanent: action.payload.type === APPLICANT_TYPE_KEYS.permanent,
      };
    },
    setBilling: (state, action) => {
      state.billing = action.payload;
    },
    addSkill: (state, action) => {
      state.skills = [...state.skills, action.payload];
    },
    removeSkill: (state, action) => {
      state.skills = deleteSkillFromTable(state.skills, action.payload);
    },
    editSkill: (state, action) => {
      state.skills = editTable(state.skills, action.payload);
    },
    addHistory: (state, action) => {
      state.histories = [...state.histories, action.payload];
    },
    removeHistory: (state, action) => {
      state.histories = deleteElementFromTable(state.histories, action.payload);
    },
    editHistory: (state, action) => {
      state.histories = editTable(state.histories, action.payload);
    },
    addSupplemental: (state, action) => {
      const {key, data} = action.payload;
      state.supplemental[key] = [...state.supplemental[key], data];
    },
    removeSupplemental: (state, action) => {
      const {key, data} = action.payload;
      state.supplemental[key] = deleteElementFromTable(
        state.supplemental[key],
        data,
      );
    },
    editSupplemental: (state, action) => {
      const {key, data} = action.payload;
      state.supplemental[key] = editTable(state.supplemental[key], data);
    },
  },
});

export const {
  setSummary,
  setBilling,
  setInitial,
  addSkill,
  editSkill,
  removeSkill,
  addSupplemental,
  removeSupplemental,
  editSupplemental,
  resetResource,
  addHistory,
  removeHistory,
  editHistory,
} = resourceSlice.actions;

export default resourceSlice.reducer;
