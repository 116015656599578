import {useRoutes} from 'react-router-dom';

import {buildRoutes} from '../utils/helpers/RouteHelper';

let isRouteTreeGenerated = false;
let generatedRoutes = [];

const useAppRoutes = (routes, is_static = true) => {
  if (is_static === false) {
    generatedRoutes = buildRoutes([...routes]);
  } else {
    if (!isRouteTreeGenerated) {
      generatedRoutes = buildRoutes([...routes]);
      isRouteTreeGenerated = true;
    }
  }
  return useRoutes(generatedRoutes);
};

export default useAppRoutes;
