import AppGridContainer from '@wieldy/components/AppGridContainer';
import {Button, Col} from 'antd';
import {PERMISSIONS} from 'app/config/auth';
import {USER_CREDENTIALS} from 'app/constants/user-creds';
import {useHasPermission} from 'app/hooks/useHasPermission';
import {EditMarketplaceUserForm} from 'app/pages/manager/administartion/index';
import {UserProfile} from 'app/shared/user';
import ResetPassword from 'app/shared/user/ResetAnotherUserPassword';
import PropTypes from 'prop-types';
import React, {useMemo} from 'react';

const MainContent = ({user, formProps}) => {
  const [editProfile, setEditProfile] = React.useState(false);

  const permission = useMemo(() => {
    switch (formProps?.role) {
      case USER_CREDENTIALS.USER_ROLES.owner:
        return 'no';
      case USER_CREDENTIALS.USER_ROLES.admin:
      case USER_CREDENTIALS.USER_ROLES.marketplace_manager:
        return PERMISSIONS.CAN_UPDATE_MARKETPLACE_MEMBER;
      default:
        return 'no';
    }
  }, [formProps?.role]);

  const canUpdate = useHasPermission(permission);

  return (
    <AppGridContainer>
      <Col xs={24} lg={15} xxl={16}>
        {editProfile ? (
          <EditMarketplaceUserForm
            onCancel={() => setEditProfile(false)}
            onSave={() => setEditProfile(false)}
            formProps={formProps}
          />
        ) : (
          <UserProfile user={user}>
            {canUpdate && (
              <Button
                type='primary'
                className={'mt-6'}
                onClick={() => setEditProfile(true)}>
                Edit User Profile
              </Button>
            )}
          </UserProfile>
        )}
      </Col>
      <Col xs={24} lg={9} xxl={8}>
        <ResetPassword email={user?.email} />
      </Col>
    </AppGridContainer>
  );
};

MainContent.propTypes = {
  user: PropTypes.object,
  formProps: PropTypes.object,
};

export default MainContent;
