import {capitalizedString} from '@wieldy/utils/helpers/StringHelper';
import {ALL_USER_TYPES} from 'app/constants/user-types';

import BaseModal from './BaseModal';

class CompanyUserModal extends BaseModal {
  constructor(user) {
    super(user);
    this.id = user?.id ?? null;
    this.firstName = user?.first_name ?? '';
    this.lastName = user?.last_name ?? '';
    this.name = this.firstName + ' ' + this.lastName;
    this.email = user?.email ?? '';
    this.title = user?.title ?? '';
    this.avatarUrl = user?.avatar_url ?? '';
    this.userType = user?.role ? ALL_USER_TYPES[user?.role] : null;
    this.status = user?.status ? capitalizedString(user?.status) : null;
    this.createdAt = user?.created_at ? user?.created_at : null;
    this.sentOn = this.createdAt
      ? this.formatDate(this.createdAt, 'MMM DD, YYYY hh:mma')
      : null;
  }
}

export default CompanyUserModal;
