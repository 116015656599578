export const getChannelFilterByType = (type) => {
  if (type === 'marketplace') {
    return {marketplace_channel: true};
  }

  return {requisition_channel: true};
};

export const groupChannelsByFields = (loadedChannels, idFields) => {
  const group = [];

  loadedChannels.forEach((channel) => {
    const isExistChannel = group.find((groupChannel) => {
      return idFields.every((field) => {
        return groupChannel.data[field] === channel.data[field];
      });
    });

    if (isExistChannel) return;

    if (channel.data.marketplace_channel) {
      group.unshift(channel);
    } else {
      group.push(channel);
    }
  });

  return group;
};

export const getRequisitionChannelsByUserIds = (loadedChannels, usersIds) => {
  return loadedChannels.filter(
    (loadedChannel) =>
      usersIds.supplier &&
      loadedChannel.data.supplier === usersIds.supplier &&
      usersIds.client &&
      loadedChannel.data.client === usersIds.client &&
      loadedChannel.data.requisition_channel,
  );
};
