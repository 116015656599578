import AppCard from '@wieldy/components/AppCard';
import {Button, notification} from 'antd';
import {useAccountType} from 'app/hooks/useAccountType';
import {useUpdateClient} from 'app/services/clientService';
import {useUpdateSupplier} from 'app/services/supplierService';
import {
  updateCurrentUser,
  useCurrentUser,
} from 'app/store/features/user/userSlice';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';

import ClientSettingsForm from './ClientSettingsForm';
import SettingsDetail from './SettingsDetail';
import SupplierSettingsForm from './SupplierSettingsForm';

const Settings = () => {
  const [edit, setEdit] = React.useState(false);
  const dispatch = useDispatch();
  const {companyModal} = useSelector(({user}) => user);
  const companyProfile = useCurrentUser();
  const {isSupplier} = useAccountType();
  const mutation = (isSupplier ? useUpdateSupplier : useUpdateClient)();

  const handleUpdateSettings = React.useCallback(
    (formdata) => {
      mutation.mutate(
        {
          payload: formdata,
          [isSupplier ? 'supplierId' : 'clientId']: companyModal.id,
        },
        {
          onSuccess: () => {
            dispatch(
              updateCurrentUser({
                ...companyProfile,
                ...formdata,
              }),
            );
            notification.success({message: 'Settings updated successfully'});
            setEdit(false);
          },
        },
      );
    },
    [companyModal.id, companyProfile, dispatch, isSupplier, mutation],
  );

  return (
    <AppCard
      title='General Settings'
      headStyle={{
        borderBottom: 'none',
      }}
      bodyStyle={{paddingTop: 8}}>
      {edit === true ? (
        isSupplier ? (
          <SupplierSettingsForm
            onCancel={() => setEdit(false)}
            initialValues={companyProfile}
            onSubmit={handleUpdateSettings}
            loading={mutation.isLoading}
          />
        ) : (
          <ClientSettingsForm
            onCancel={() => setEdit(false)}
            initialValues={companyProfile}
            onSubmit={handleUpdateSettings}
            loading={mutation.isLoading}
          />
        )
      ) : (
        <SettingsDetail company={companyModal}>
          <Button type='primary' onClick={() => setEdit(true)}>
            Edit General Settings
          </Button>
        </SettingsDetail>
      )}
    </AppCard>
  );
};

export default React.memo(Settings);
