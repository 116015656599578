import {
  AttachmentPreviewList,
  ChatAutoComplete,
  SendButton,
  useComponentContext,
  useMessageInputContext,
} from 'stream-chat-react';

import {AttachmentUploadButton} from './AttachmentUploadButton';
import {EmojiPicker} from './EmojiPicker';
import {useMessageInputCompositionControls} from './hooks/useMessageInputCompositionControls';
import {MessageInputControlButton} from './MessageInputControls';

export const TeamMessageInput = () => {
  const {TypingIndicator} = useComponentContext();

  const {handleSubmit, text, fileUploads, imageUploads} =
    useMessageInputContext();
  const {
    formatting,
    handleBoldButtonClick,
    handleCodeButtonClick,
    handleItalicsButtonClick,
    handleStrikeThroughButtonClick,
    onChange,
    placeholder,
  } = useMessageInputCompositionControls();

  const hasFiles =
    Object.keys(fileUploads).length > 0 || Object.keys(imageUploads).length > 0;

  return (
    <div className='team-message-input__wrapper'>
      <div className='team-message-input__input'>
        <div className='team-message-input__top'>
          {hasFiles && <AttachmentPreviewList />}
          <div className='team-message-input__form'>
            <ChatAutoComplete onChange={onChange} placeholder={placeholder} />

            <SendButton
              disabled={!hasFiles && !text.length}
              sendMessage={handleSubmit}
            />
          </div>
        </div>

        <div className='team-message-input__bottom'>
          <EmojiPicker />
          <MessageInputControlButton
            type='bold'
            active={formatting === 'bold'}
            onClick={handleBoldButtonClick}
          />
          <MessageInputControlButton
            type='italics'
            active={formatting === 'italics'}
            onClick={handleItalicsButtonClick}
          />
          <MessageInputControlButton
            type='strike-through'
            active={formatting === 'strike-through'}
            onClick={handleStrikeThroughButtonClick}
          />
          <MessageInputControlButton
            type='code'
            active={formatting === 'code'}
            onClick={handleCodeButtonClick}
          />

          <AttachmentUploadButton />
        </div>
      </div>
      {TypingIndicator && <TypingIndicator />}
    </div>
  );
};
