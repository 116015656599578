import AppDynamicTabs from '@wieldy/components/AppTabs/AppDynamicTabs';
import AppPageContainer from '@wieldy/components/pageComponents/AppPageContainer';
import AppPageHeader from '@wieldy/components/pageComponents/AppPageHeader';
import {Button, Divider} from 'antd';
import {useAccountType} from 'app/hooks/useAccountType';
import TimeCardsTable from 'app/pages/main/timecards/MainContent/TimeCardsTable';
import React, {useEffect, useMemo, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';

const baseData = [
  {
    key: 'submitted',
    title: 'Pending Approval',
    route: '/timecards/submitted',
  },
  {
    key: 'approved',
    title: 'Approved',
    route: '/timecards/approved',
  },
  {
    key: 'rejected',
    title: 'Rejected',
    route: '/timecards/rejected',
  },
];

const supplierData = [
  {
    key: 'new',
    title: 'Not Submitted',
    route: '/timecards/new',
  },
];

const Timecards = () => {
  const navigate = useNavigate();
  const {category} = useParams();
  const [activeTab, setActiveTab] = useState();

  const {isSupplier, isClient} = useAccountType();

  const isShowButtons = isSupplier;

  const tabsData = useMemo(
    () => (isSupplier ? supplierData.concat(baseData) : baseData),
    [isSupplier],
  );

  useEffect(() => {
    if (!activeTab && !category) {
      !isSupplier
        ? navigate('/timecards/submitted')
        : navigate('/timecards/new');
    } else if (isClient && category === 'new') {
      navigate('/timecards/submitted');
    }
  }, [activeTab, isSupplier, category, isClient, navigate]);

  const activeKey = React.useMemo(() => {
    return category ?? 'submitted';
  }, [category]);

  return (
    <AppPageContainer
      header={
        <AppPageHeader
          title={`${activeTab?.title}`}
          category={'Timecards'}
          tabs={
            <AppDynamicTabs
              tabsData={tabsData}
              activeKey={activeKey}
              setActiveTab={setActiveTab}
            />
          }
          extra={
            isShowButtons ? (
              <React.Fragment>
                <Button
                  type='primary'
                  shape='round'
                  onClick={() => navigate('/timecards/add-timecard')}>
                  Add New Timecard
                </Button>
                <Divider type={'vertical'} style={{height: '18px'}} />
                <Button
                  type='primary'
                  ghost
                  shape='round'
                  onClick={() => navigate('/timecards/add-blank-timecard')}>
                  Add New Blank Timecard
                </Button>
              </React.Fragment>
            ) : null
          }
        />
      }>
      <TimeCardsTable title={activeTab?.title} status={activeKey} />
    </AppPageContainer>
  );
};

export default Timecards;
