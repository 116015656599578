import AppTable from '@wieldy/components/AppTable';
import PropTypes from 'prop-types';
import React from 'react';

import {getColumns} from './columns';

const EntriesIndividualTable = ({entries}) => {
  return (
    <AppTable columns={getColumns()} dataSource={entries} pagination={false} />
  );
};

EntriesIndividualTable.propTypes = {
  entries: PropTypes.array,
};

export default EntriesIndividualTable;
