import {
  CreatePlacement,
  default as Placements,
  ViewPlacement,
} from 'app/pages/main/placement';

export const supplier_EngagementRoutes = [
  {
    path: 'placements/add-new-placement',
    element: <CreatePlacement />,
  },
  {
    path: 'placements/manage-placement',
    element: <CreatePlacement isEdit={true} />,
  },
  {
    path: 'placements/:category',
    element: <Placements />,
  },
  {
    path: 'placements/:id/view-placement',
    element: <ViewPlacement />,
  },
];
