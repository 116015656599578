import {useInfoViewActionsContext} from '@wieldy/components/AppInfoView/InfoViewContext';
import {useEffect} from 'react';
import {
  useInfiniteQuery,
  useIsMutating,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';

const useReactQuery = (queryKey, queryFn, options = {}) => {
  const {fetchStart, fetchSuccess, fetchError, clearInfoView} =
    useInfoViewActionsContext();
  const {isLoading, data, isError, error, isFetching} = useQuery({
    queryKey,
    queryFn,
    onError: (error) => {
      if (error?.response) {
        const statusCode = error?.response?.status;
        const response = error?.response?.data;
        if (statusCode === 401) {
          window.location.reload();
          return;
        }
        if (response && response?.message) {
          fetchError(response?.message);
        }
      }
    },
    onSuccess: () => {
      fetchSuccess();
    },
    onSettled: () => {
      clearInfoView();
    },

    ...options,
  });

  useEffect(() => {
    if (isLoading) {
      fetchStart();
    }
  }, [fetchStart, isLoading]);

  return {
    isLoading,
    isError,
    error,
    data,
    isFetching,
  };
};

export const useReactMutation = (mutationFn, queryArgs = {}) => {
  const {showMessage, fetchError} = useInfoViewActionsContext();
  return useMutation({
    mutationFn: mutationFn,
    onSuccess: (data) => {
      if (data?.message) {
        showMessage(data.message);
      }
    },
    onError: (error) => {
      if (error?.response) {
        const statusCode = error?.response?.status;
        const response = error?.response?.data;
        if (statusCode === 401) {
          window.location.reload();
          return;
        }
        if (response && response?.message) {
          fetchError(response?.message);
        }
      } else {
        fetchError(error?.message);
      }
    },

    ...queryArgs,
  });
};

export {useInfiniteQuery, useIsMutating, useMutation, useQuery, useQueryClient};

export default useReactQuery;
