import AppBorderBox from '@wieldy/components/AppBorderBox';
import AppCard from '@wieldy/components/AppCard';
import AppLoader from '@wieldy/components/AppLoader';
import AppSelect from '@wieldy/components/dataEntry/AppSelect';
import {getSelectPlaceHolder} from '@wieldy/utils/helpers/MessageHelper';
import {Button, Form, Select, Typography} from 'antd';
import useStep from 'app/hooks/useStep';
import {useGetExpenses2} from 'app/services/expenseService';
import {setInvoiceExpenses} from 'app/store/features/invoice/invoiceSlice';
import {getItemsData} from 'app/utils/helpers/GetItemsData';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';

const SelectExpenses = () => {
  const dispatch = useDispatch();
  const {currentStep, nextStep} = useStep();
  const {engagement, expenses, endDate} = useSelector(({invoice}) => invoice);

  const {isLoading, data} = useGetExpenses2(
    {
      limit: 100,
      status: 'approved',
      engagement: engagement.id,
      exclude_invoiced: true,
      ...endDate,
      sort: ['+end_date'],
    },
    {
      enabled: currentStep === 4,
    },
  );
  const [expensesList] = getItemsData('items', data);

  const onFormSubmit = (formData) => {
    dispatch(setInvoiceExpenses(formData));

    if (nextStep) nextStep();
  };

  if (isLoading) {
    return <AppLoader />;
  }

  if (currentStep < 4) {
    return null;
  }
  if (currentStep > 4) {
    return (
      <AppBorderBox
        className='d-inline-flex flex-column px-6 mt-3'
        style={{backgroundColor: '#FFF'}}>
        {expenses?.expenses?.map((exp) => {
          const selectedExp = expensesList.find((expL) => expL.id === exp);
          return (
            <Typography.Title
              level={4}
              className={'mb-0'}
              key={selectedExp?.hid}>
              {selectedExp?.hid} :: {selectedExp?.end_date}
            </Typography.Title>
          );
        })}
      </AppBorderBox>
    );
  }

  return (
    <AppCard
      title='Select Expenses'
      headStyle={{border: 0}}
      bodyStyle={{paddingTop: 0}}>
      <Form layout='vertical' autoComplete='off' onFinish={onFormSubmit}>
        <Form.Item name='expenses' label='Expense(s)'>
          <AppSelect
            showSearch
            mode={'multiple'}
            placeholder={getSelectPlaceHolder('Expense(s)')}
            style={{width: '350px'}}>
            {expensesList.map((item, index) => (
              <Select.Option key={index} value={item.id}>
                {item?.hid} :: {item?.end_date}
              </Select.Option>
            ))}
          </AppSelect>
        </Form.Item>
        <Button type='primary' htmlType='submit'>
          Continue
        </Button>
      </Form>
    </AppCard>
  );
};
export default React.memo(SelectExpenses);
