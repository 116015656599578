import {Checkbox, Typography} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

import {COMPANY_SERVICE_VALUES} from '../../constants/company-services';

const getChildren = (item, sub) => {
  if (!item) {
    return null;
  }
  const keys = Object.keys(item);
  return keys.filter((el) => item[el]).filter((el) => el.startsWith(sub));
};
const MultipleCheckboxView = ({item, hideTitle = false}) => {
  const {
    permanent_hires,
    temporary_hires,
    temporary_to_permanent_hires,
    recruitment_process_outsourcing,
    other_hires,
    ...rest
  } = item;

  const temporaryChildren = getChildren(rest, 'temp');
  const permanentChildren = getChildren(rest, 'perm');

  if (!item) {
    return null;
  }

  return (
    <div className={'mb-5 form-service-view d-flex flex-column'}>
      {!hideTitle && (
        <Typography.Title level={4}>
          Company Recruiting and Staffing Services
        </Typography.Title>
      )}

      {permanent_hires && (
        <div className='d-flex flex-column'>
          <Checkbox disabled checked>
            {COMPANY_SERVICE_VALUES.permanent_hires}
          </Checkbox>
          {permanentChildren && (
            <div style={{paddingLeft: 24}} className='d-flex flex-column'>
              {permanentChildren.map((el) => (
                <Checkbox key={el} disabled checked>
                  {COMPANY_SERVICE_VALUES[el]}
                </Checkbox>
              ))}
            </div>
          )}
        </div>
      )}
      {temporary_hires && (
        <div className='d-flex flex-column'>
          <Checkbox disabled checked>
            {COMPANY_SERVICE_VALUES.temporary_hires}
          </Checkbox>
          {!!temporaryChildren.length && (
            <div style={{paddingLeft: 24}} className='d-flex flex-column'>
              {temporaryChildren.map((el) => (
                <Checkbox key={el} disabled checked>
                  {COMPANY_SERVICE_VALUES[el]}
                </Checkbox>
              ))}
            </div>
          )}
        </div>
      )}
      {temporary_to_permanent_hires && (
        <Checkbox disabled checked>
          {COMPANY_SERVICE_VALUES.temporary_to_permanent}
        </Checkbox>
      )}
      {recruitment_process_outsourcing && (
        <Checkbox disabled checked>
          {COMPANY_SERVICE_VALUES.recruitment_process_outsourcing}
        </Checkbox>
      )}
      {other_hires && (
        <Checkbox disabled checked>
          {COMPANY_SERVICE_VALUES.other_hires}
        </Checkbox>
      )}
    </div>
  );
};

MultipleCheckboxView.propTypes = {
  item: PropTypes.object,
  hideTitle: PropTypes.bool,
};

export default MultipleCheckboxView;
