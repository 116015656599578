import AppCard from '@wieldy/components/AppCard';
import {Typography} from 'antd';
import {getFullName} from 'app/utils/helpers/DataFormatHelper';
import PropTypes from 'prop-types';
import React from 'react';

const AccountManagerView = ({account_manager}) => {
  return (
    <AppCard style={{maxWidth: 820}}>
      <Typography.Title level={4}>Account Manager</Typography.Title>
      <Typography.Paragraph>
        {getFullName(account_manager)}
      </Typography.Paragraph>
    </AppCard>
  );
};

AccountManagerView.propTypes = {
  account_manager: PropTypes.string,
};

export default AccountManagerView;
