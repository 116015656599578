import {Typography} from 'antd';
import useStep from 'app/hooks/useStep';
import PropTypes from 'prop-types';
import React from 'react';

import InformationForm from './InformationForm';
import InformationView from './InformationView';

const RecruitmentTerms = ({requisitionModal}) => {
  const {currentStep, nextStep, setStepData, stepData} = useStep();
  return (
    <React.Fragment>
      <Typography.Paragraph>
        Specify the recruitment terms is case a successful placement is made
      </Typography.Paragraph>

      {currentStep > 5 && stepData ? (
        <InformationView requisitionModal={requisitionModal} />
      ) : (
        currentStep === 5 && (
          <InformationForm
            initialValues={{
              base_salary: stepData?.base_salary,
              base_salary_min: stepData?.base_salary_min,
              base_salary_max: stepData?.base_salary_max,
              recruitment_fee_structure: stepData?.recruitment_fee_structure,
              recruitment_fee: stepData?.recruitment_fee,
              guarantee_period: stepData?.guarantee_period,
              guarantee_period_action: stepData?.guarantee_period_action,
            }}
            onSave={setStepData}
            onContinue={nextStep}
          />
        )
      )}
    </React.Fragment>
  );
};

RecruitmentTerms.propTypes = {
  requisitionModal: PropTypes.object,
};
export default React.memo(RecruitmentTerms);
