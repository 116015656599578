import AppPageContainer from '@wieldy/components/pageComponents/AppPageContainer';
import AppPageHeader from '@wieldy/components/pageComponents/AppPageHeader';
import {AppStepsWrapper} from 'app/providers/AppStepProvider';
import {useGetInquiry} from 'app/services/inquiryService';
import {
  resetInquiryState,
  setInquiryEditData,
  setInquiryEditMode,
} from 'app/store/features/inquiry/inquirySlice';
import PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';

import {queryParamsList} from '../constants';
import MainContent from './MainContent';

const CreateInquiry = ({isEdit = false}) => {
  const dispatch = useDispatch();
  const {inquiryId, requisitionId} = useParams();
  const title = isEdit ? 'Update Inquiry' : 'New Inquiry';
  const currentStep = requisitionId ? 2 : isEdit ? 3 : 1;

  useEffect(() => {
    dispatch(setInquiryEditMode(isEdit));
  }, [dispatch, isEdit]);

  useEffect(() => {
    return () => {
      dispatch(resetInquiryState());
    };
  }, [dispatch]);

  const {data} = useGetInquiry(inquiryId, queryParamsList, {
    enabled: isEdit,
  });

  useEffect(() => {
    if (data && isEdit) {
      dispatch(setInquiryEditData(data));
    }
  }, [data, dispatch, isEdit]);

  return (
    <AppPageContainer
      header={<AppPageHeader header='main' category='Inquiry' title={title} />}>
      <AppStepsWrapper
        isUpdated={false}
        isLoading={false}
        currentStep={currentStep}>
        <MainContent />
      </AppStepsWrapper>
    </AppPageContainer>
  );
};

CreateInquiry.propTypes = {
  isEdit: PropTypes.bool,
};

export default CreateInquiry;
