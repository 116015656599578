import './AvatarGroup.scss';

import PropTypes from 'prop-types';
import React from 'react';
import {RiBuildingLine} from 'react-icons/ri';
import {Avatar} from 'stream-chat-react';

export const AvatarGroup = ({images}) => {
  let content = <Avatar shape='square' size={40} />;

  if (images.length === 1) {
    content = images[0] ? (
      <Avatar image={images[0]} size={40} />
    ) : (
      <RiBuildingLine fontSize={40} />
    );
  }

  if (images.length === 2) {
    content = (
      <>
        {images[0] ? (
          <Avatar image={images[0]} shape='square' size={20} />
        ) : (
          <RiBuildingLine fontSize={20} />
        )}
        {images[1] ? (
          <Avatar image={images[1]} shape='square' size={20} />
        ) : (
          <RiBuildingLine fontSize={20} />
        )}
      </>
    );
  }

  return <div className='avatar-group'>{content}</div>;
};

AvatarGroup.propTypes = {
  images: PropTypes.array,
};

export default AvatarGroup;
