import AppCard from '@wieldy/components/AppCard';
import AppHeaderPagination from '@wieldy/components/AppPagination/AppHeaderPagination';
import AppTable from '@wieldy/components/AppTable';
import AppTag from '@wieldy/components/AppTag/AppTag';
import {getTablePagination} from '@wieldy/utils/helpers/TableHelper';
import {useGetResources} from 'app/services/resourceService.js';
import {getColumns} from 'app/shared/components/Tables/Resources/columns';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';

const Resources = ({defaultQueryParams, isShow, handleSetTabView, title}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [sortBy, setSortBy] = useState('alpha');

  const {isLoading, data, isFetching} = useGetResources({
    limit: pageSize,
    page: currentPage - 1,
    ...defaultQueryParams,
    include: ['total_count', 'completed_engagements'],
  });

  const {resources, total_count} = data || {resources: [], total_count: 0};

  useEffect(() => {
    if (!isLoading && handleSetTabView) {
      handleSetTabView('isResource', !!resources.length);
    }
  }, [resources, isLoading, handleSetTabView]);

  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const onShowSizeChange = (current, pageSize) => {
    setCurrentPage(current);
    setPageSize(pageSize);
  };

  if (!isShow) {
    return <></>;
  }

  return (
    <AppCard
      title={
        <React.Fragment>
          {title} <AppTag className={'ml-1'}>{total_count}</AppTag>
        </React.Fragment>
      }
      headStyle={{borderBottom: 0}}
      bodyStyle={{paddingTop: 8}}
      extra={
        <AppHeaderPagination
          total={total_count}
          current={currentPage}
          pageSize={pageSize}
          onChange={onPageChange}
          hideStartBorder
          sortByOptions={[
            {label: 'Alphabetically', value: 'alpha'},
            {label: 'Created Date', value: 'date'},
            {label: 'Created By', value: 'owner'},
          ]}
          sortBy={sortBy}
          onSortByChange={setSortBy}
        />
      }>
      <AppTable
        loading={isLoading || isFetching}
        columns={getColumns()}
        dataSource={resources}
        rowClassName={(record) => {
          if (record.rowClassNames) {
            if (Array.isArray(record.rowClassNames)) {
              return record.rowClassNames.join(' ');
            }
            return record.rowClassNames;
          }
        }}
        pagination={getTablePagination(
          pageSize,
          currentPage,
          total_count,
          onShowSizeChange,
          onPageChange,
        )}
      />
    </AppCard>
  );
};

Resources.propTypes = {
  defaultQueryParams: PropTypes.object,
  handleSetTabView: PropTypes.func,
  isShow: PropTypes.bool.isRequired,
  title: PropTypes.string,
};

Resources.defaultProps = {
  defaultQueryParams: {},
  isShow: false,
  title: 'Resources',
};

export default Resources;
