import {Rate, Typography} from 'antd';
import PropTypes from 'prop-types';

const RatingItem = ({label, rating}) => {
  return (
    <div className='d-flex align-items-center justify-content-between py-1'>
      <Typography.Paragraph type={'secondary'} className='mb-0 mr-2'>
        {label}
      </Typography.Paragraph>
      <Rate allowHalf disabled value={rating} count={5} />
    </div>
  );
};

RatingItem.propTypes = {
  label: PropTypes.string,
  rating: PropTypes.number,
};

export default RatingItem;
