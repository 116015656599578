import AppPageContainer from '@wieldy/components/pageComponents/AppPageContainer';
import AppPageHeader from '@wieldy/components/pageComponents/AppPageHeader';
import {notification} from 'antd';
import {AppStepsWrapper} from 'app/providers/AppStepProvider';
import {useCreateRequisition} from 'app/services/requisitionService';
import RequisitionSteps, {
  Preview,
} from 'app/shared/requisitions/RequisitionSteps';
import React from 'react';
import {useNavigate} from 'react-router-dom';

import RequisitionModal from '../../../../modals/RequisitionModal';

const CreateRequisition = () => {
  const mutation = useCreateRequisition();
  const navigate = useNavigate();

  const handleSubmit = React.useCallback(
    (postData) => {
      if (!postData) {
        notification.error({description: 'You have not completed all steps'});
      }
      const {
        hiring_manager,
        recruiter,
        skill_tags,
        billing_rate_max,
        ...formData
      } = postData;
      formData.hiring_manager = hiring_manager?.id;
      formData.recruiter = recruiter?.id;
      formData.skill_tags =
        skill_tags && skill_tags.length > 0
          ? skill_tags.map((tagItem) => ({tag: tagItem?.id}))
          : null;
      formData.billing_rate_max =
        +billing_rate_max > 0 ? billing_rate_max : null;
      formData.flsa_eligible = formData.flsa_eligible ?? null;
      formData.job_country = formData.job_country || null;
      mutation.mutate(formData, {
        onSuccess: () => {
          navigate('/requisitions/open');
        },
      });
    },
    [mutation, navigate],
  );

  return (
    <AppStepsWrapper
      onFinish={handleSubmit}
      isLoading={mutation.isLoading}
      dataSource={new RequisitionModal()}
      preview={<Preview />}>
      <AppPageContainer
        header={
          <AppPageHeader category={'Requisition'} title={'New Requisition'} />
        }>
        <RequisitionSteps />
      </AppPageContainer>
    </AppStepsWrapper>
  );
};

export default CreateRequisition;
