import {Button, Space, Typography} from 'antd';
import propTypes from 'prop-types';
import React, {useState} from 'react';

import {expenseRejectReasons} from '../../../MainContent/data';
import ExpenseRejectModel from './ExpenseRejectModel';

const ApproveRejectActions = ({onApprove, onReject}) => {
  const [modelVisible, setModelVisible] = useState(false);

  const handleApproveExpense = () => {
    if (onApprove) {
      onApprove();
    }
  };

  const handleRejectExpense = (reason) => {
    setModelVisible(false);
    if (onReject) {
      onReject(reason);
    }
  };

  return (
    <Space size={'middle'}>
      <Button type='primary' onClick={handleApproveExpense}>
        Approve Expense
      </Button>
      <Button type='primary' ghost onClick={() => setModelVisible(true)}>
        Reject Expense
      </Button>
      <ExpenseRejectModel
        title='Reject Expense'
        visible={modelVisible}
        reasons={expenseRejectReasons}
        onOk={handleRejectExpense}
        onCancel={() => setModelVisible(false)}>
        <Typography.Paragraph>
          If you would like to reject this expense, please provide a reason for
          rejecting and then click Reject Expense button, if you&#39;d like to
          reconsider this action, click Cancel
        </Typography.Paragraph>
      </ExpenseRejectModel>
    </Space>
  );
};

ApproveRejectActions.propTypes = {
  onApprove: propTypes.func,
  onReject: propTypes.func,
};

export default ApproveRejectActions;
