import {Steps} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

const AppSteps = (props) => {
  return <Steps progressDot {...props} />;
};

AppSteps.propTypes = {
  direction: PropTypes.oneOf(['horizontal', 'vertical']),
  current: PropTypes.number,
  initial: PropTypes.number,
};

AppSteps.defaultProps = {
  direction: 'vertical',
};

export default AppSteps;
