export const RELATIONSHIP_ALL_STATUSES = {
  potential: 'Potential',
  pending: 'Pending',
  active: 'Active',
  former: 'Former',
};

export const CLIENT_RELATIONSHIP_STATUSES = {
  potential: 'Potential Client',
  pending: 'Pending Client',
  active: 'Active Client',
  former: 'Former Client',
  not: 'No Relationship',
};

export const SUPPLIER_RELATIONSHIP_STATUSES = {
  potential: 'Potential Supplier',
  pending: 'Pending Supplier',
  active: 'Active Supplier',
  former: 'Former Supplier',
};
