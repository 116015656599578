import axios from '../config/axios';
import useReactQuery from '../hooks/useReactQuery';

/** Retrieve engagement */
export const useGetEngagement = (id, params = {}) => {
  return useReactQuery(['engagement', id], async () => {
    const {data} = await axios.get(`engagements/${id}`, {params});
    return data;
  });
};
