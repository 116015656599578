import {Button, Space, Typography} from 'antd';
import useStep from 'app/hooks/useStep';
import {useCreateInvoice, useUpdateInvoice} from 'app/services/invoiceService';
import {resetInvoiceState} from 'app/store/features/invoice/invoiceSlice';
import React, {useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';

const SubmitInvoice = () => {
  const {invoiceId} = useParams();
  const {currentStep} = useStep();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {engagement, timecards, expenses, isEditMode, endDate} = useSelector(
    ({invoice}) => invoice,
  );
  const {mutate, isLoading: createIsLoading} = useCreateInvoice();

  const {mutate: editMutate, isLoading: editIsLoading} =
    useUpdateInvoice(invoiceId);
  const isLoading = isEditMode ? editIsLoading : createIsLoading;

  const onCancelCreate = () => {
    dispatch(resetInvoiceState());
    navigate('/financials');
  };

  const onMutate = useMemo(
    () => (isEditMode ? editMutate : mutate),
    [editMutate, isEditMode, mutate],
  );

  const onSubmit = () => {
    const invoiceTimecards = timecards.timecards ?? [];
    const invoiceExpenses = expenses.expenses ?? [];
    const payload = {
      engagement: engagement.id,
      timecards: invoiceTimecards.map((id) => ({id})),
      expenses: invoiceExpenses.map((id) => ({id})),
      ...endDate,
    };

    onMutate(payload, {
      onSuccess: () => {
        dispatch(resetInvoiceState());
        navigate('/financials');
      },
    });
  };

  return (
    <div>
      <Typography.Paragraph>Post the invoice</Typography.Paragraph>
      {currentStep === 5 && (
        <Space>
          <Button type='primary' onClick={onSubmit} loading={isLoading}>
            Create Invoice
          </Button>
          <Button onClick={onCancelCreate} disabled={isLoading}>
            Cancel
          </Button>
        </Space>
      )}
    </div>
  );
};

export default SubmitInvoice;
