import {getSelectPlaceHolder} from '@wieldy/utils/helpers/MessageHelper';
import {Typography} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

import {candidateStatus} from '../../../constants';
import RejectActionModel from './RejectActionModel';

const ChangeCandidateActions = ({
  reasonFor,
  actionType,
  actionLabel,
  isOpenModal,
  handleClose,
  handleSuccess,
}) => {
  return (
    <RejectActionModel
      title={`Change Candidate ${actionLabel}`}
      okText={`Change ${actionLabel}`}
      visible={isOpenModal}
      placeholder={getSelectPlaceHolder('status')}
      description={'Change Candidate Status To:'}
      reasons={candidateStatus}
      onOk={handleSuccess}
      onCancel={handleClose}>
      <Typography.Paragraph>
        {`If you would like to change the ${actionType} of this candidate select
          the
        ${reasonFor} below and then click Change ${actionLabel} button. If you
          would like to reconsider this action, click Cancel.`}
      </Typography.Paragraph>
    </RejectActionModel>
  );
};

ChangeCandidateActions.propTypes = {
  reasonFor: PropTypes.string,
  actionType: PropTypes.string,
  actionLabel: PropTypes.string,
  isOpenModal: PropTypes.bool,
  handleSuccess: PropTypes.func,
  handleClose: PropTypes.func,
};

export default ChangeCandidateActions;
