import AppAmountView from '@wieldy/components/helperComponents/AppAmountView';
import {Typography} from 'antd';
import {DATE_FORMATS} from 'app/config/app';
import {PERIODS} from 'app/constants';
import {
  formatSecondsToDate,
  getFullName,
} from 'app/utils/helpers/DataFormatHelper';
import {Fragment} from 'react';

export const getColumns = () => {
  return [
    {
      title: 'Rate Id',
      dataIndex: 'hid',
      key: 'rateId',
      render: (id) => (
        <Typography.Paragraph className={'mb-0'}>{id}</Typography.Paragraph>
      ),
    },
    {
      title: 'Start Date',
      dataIndex: 'start_date',
      key: 'start_date',
      render: (start_date) => formatSecondsToDate(start_date, DATE_FORMATS.md),
    },
    {
      title: 'End Date',
      dataIndex: 'end_date',
      key: 'end_date',
      render: (end_date) => formatSecondsToDate(end_date, DATE_FORMATS.md),
    },
    {
      title: 'Billing Rate',
      dataIndex: 'billing_rate',
      key: 'billing_rate',
      render: (rate, {billing_rate_period}) =>
        rate && (
          <Fragment>
            <AppAmountView amount={rate} />
            {billing_rate_period && ` / ${PERIODS[billing_rate_period]}`}
          </Fragment>
        ),
    },
    {
      title: 'Updated',
      dataIndex: 'updated',
      key: 'updated',
      render: (_, {updated_at, updated_by, created_at, created_by}) => {
        return (
          <Typography.Paragraph>
            <Typography.Paragraph className={'mb-0'}>
              {getFullName(updated_by || created_by)}
            </Typography.Paragraph>
            <Typography.Text type={'secondary'} className={'text-xs'}>
              {formatSecondsToDate(updated_at || created_at, DATE_FORMATS.md)}
            </Typography.Text>
          </Typography.Paragraph>
        );
      },
    },
  ];
};
