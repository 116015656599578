import {manager_admin} from 'app/routes/user-routes/administration';

import commonRoutes from './common';
import {manager_DashboardRoutes as dashboard} from './user-routes/dashboard';
import {manager_EngagementRoutes as engagements} from './user-routes/engagements';
import {manager_FinancialRoutes as financials} from './user-routes/financials';

const routes = [
  ...dashboard,
  ...engagements,
  ...financials,
  ...manager_admin,
  ...commonRoutes,
];

export default routes;
