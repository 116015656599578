export const accountStatusesOptions = [
  {
    key: 'pending',
    label: 'Pending',
    value: 'pending',
  },
  {
    key: 'active',
    label: 'Active',
    value: 'active',
  },
  {
    key: 'suspended',
    label: 'Suspended',
    value: 'suspended',
  },
  {
    key: 'closed',
    label: 'Closed',
    value: 'closed',
  },
];
