import {CATEGORIES} from 'app/constants';
import {RiBookmark3Fill, RiSearchLine} from 'react-icons/ri';

export const statuses = [
  CATEGORIES.POTENTIAL,
  CATEGORIES.PENDING,
  CATEGORIES.ACTIVE,
  CATEGORIES.FORMER,
];

export const pagePrimaryTabs = [
  {key: 'all', title: 'All', route: '/suppliers/all'},
  {key: 'potential', title: 'Potential', route: '/suppliers/potential'},
  {key: 'pending', title: 'Pending', route: '/suppliers/pending'},
  {key: 'active', title: 'Active', route: '/suppliers/active'},
  {key: 'former', title: 'Former', route: '/suppliers/former'},
];

export const pageSecondaryTabs = [
  {
    key: 'bookmarked',
    title: 'Bookmarked',
    icon: <RiBookmark3Fill />,
    route: '/suppliers/bookmarked',
  },
  {
    key: 'browse',
    title: 'Browse Suppliers',
    icon: <RiSearchLine />,
    route: '/suppliers/browse',
  },
];

export const supplierQueryParams = {
  include: [
    'total_count',
    'bookmarked',
    'bookmarked_on',
    'engagements',
    'submissions',
    'inquiries',
    'available_resources',
    'completed_engagements',
    'active_clients',
    'relation_status',
  ],
};

export const pageQueries = {
  all: {
    queryParams: {},
    title: 'All Suppliers',
  },
  potential: {
    queryParams: {
      ...supplierQueryParams,
      relation_status: 'potential',
    },
    title: 'Potential Suppliers',
    rowTitle: 'Potential Suppliers',
    label: 'Status changed',
    empty: 'No Potential Suppliers',
  },
  former: {
    queryParams: {
      ...supplierQueryParams,
      relation_status: 'former',
    },
    title: 'Former Suppliers',
    rowTitle: 'Former Suppliers',
    label: 'Status changed',
    empty: 'No Former Suppliers',
  },
  active: {
    queryParams: {
      ...supplierQueryParams,
      relation_status: 'active',
    },
    title: 'Active Suppliers',
    rowTitle: 'Active Suppliers',
    label: 'Status changed',
    empty: 'No Active Suppliers',
  },
  pending: {
    queryParams: {
      ...supplierQueryParams,
      relation_status: 'pending',
    },
    title: 'Pending Suppliers',
    rowTitle: 'Pending Suppliers',
    label: 'Status changed',
    empty: 'No Pending Suppliers',
  },
  bookmarked: {
    queryParams: {
      ...supplierQueryParams,
      bookmarked: true,
    },
    title: 'Bookmarked Suppliers',
    rowTitle: 'Bookmarked Suppliers',
    label: 'Bookmarked',
    empty: 'No Bookmarked Suppliers',
  },
  browse: {
    queryParams: {
      ...supplierQueryParams,
    },
    title: 'Browse Suppliers',
    rowTitle: 'Suppliers',
    label: 'Created',
    empty: 'No Suppliers',
  },
};
