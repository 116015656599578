import {getInputPlaceHolder} from '@wieldy/utils/helpers/MessageHelper';
import {Form} from 'antd';
import Editor from 'app/shared/components/Editor/Editor';
import PropTypes from 'prop-types';
import React from 'react';
import {Controller, useFormContext} from 'react-hook-form';

const AppFormEditor = ({label, name, isRequired}) => {
  const {control} = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({field, fieldState}) => (
        <Form.Item
          label={label}
          required={isRequired}
          validateStatus={fieldState.error ? 'error' : ''}
          help={fieldState.error ? fieldState.error.message : ''}>
          <Editor
            isError={!!fieldState.error}
            value={field.value}
            key={name}
            isCompanyProfile
            placeholder={getInputPlaceHolder('company description')}
            onChange={field.onChange}
          />
        </Form.Item>
      )}
    />
  );
};

AppFormEditor.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  isRequired: PropTypes.bool,
};

export default AppFormEditor;
