import {Typography} from 'antd';
import useStep from 'app/hooks/useStep';
import PageQueryProvider from 'app/providers/PageQueryProvider/PageQueryProviderNew';
import {filterConfig} from 'app/shared/resource/FilterResources';
import React from 'react';

import ChooseContainer from './ChooseContainer';
import SelectedList from './SelectedList';

const ChooseCandidates = () => {
  const {currentStep} = useStep();

  return (
    <React.Fragment>
      {currentStep < 3 && (
        <Typography.Paragraph>Select candidates</Typography.Paragraph>
      )}

      {currentStep > 3 && <SelectedList />}

      {currentStep === 3 && (
        <PageQueryProvider
          initialSimpleQueries={{
            include: ['total_count', 'skill_tags'],
            expand: ['supplier'],
            limit: 8,
          }}
          filterConfig={filterConfig}>
          <ChooseContainer />
        </PageQueryProvider>
      )}
    </React.Fragment>
  );
};

export default React.memo(ChooseCandidates);
