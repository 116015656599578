import PropTypes from 'prop-types';

const MultipleValuePreview = ({type = [], source}) => {
  return (
    <div className='d-flex flex-column'>
      {type.map((t) => {
        return <span key={t}>{source[t]}</span>;
      })}
    </div>
  );
};

MultipleValuePreview.propTypes = {
  type: PropTypes.array,
  source: PropTypes.object,
};

export default MultipleValuePreview;
