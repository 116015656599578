import {Typography} from 'antd';
import {DATE_FORMATS} from 'app/config/app';
import {ALL_USER_TYPES} from 'app/constants';
import {
  formatDate,
  getFullName,
  getPhoneNumber,
} from 'app/utils/helpers/DataFormatHelper';
import React from 'react';

export const getColumns = () => {
  return [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'first_name',
      render: (_, item) => (
        <Typography.Text className={'font-semibold'}>
          {getFullName(item)}
        </Typography.Text>
      ),
    },
    {
      title: 'Email Address',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Phone Number',
      dataIndex: 'phone_number',
      key: 'phone_number',
      render: (_, item) => getPhoneNumber(item?.phone_number),
    },
    {
      title: 'Sent On',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (date) => (
        <Typography.Text>{formatDate(date, DATE_FORMATS.lg)}</Typography.Text>
      ),
    },
    {
      title: 'User Type',
      dataIndex: 'role',
      key: 'role',
      render: (role) => (
        <Typography.Text>{role ? ALL_USER_TYPES[role] : null}</Typography.Text>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: () => 'Invitation Sent',
    },
  ];
};
