import 'simplebar/dist/simplebar.min.css';
import './index.style.less';

import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import SimpleBar from 'simplebar-react';

const AppScrollbar = ({children, className, ...others}) => {
  return (
    <SimpleBar className={clsx('app-scrollbar', className)} {...others}>
      {children}
    </SimpleBar>
  );
};

export default AppScrollbar;

AppScrollbar.defaultProps = {
  className: '',
};

AppScrollbar.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};
