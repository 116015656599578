import {
  USER_STATUS,
  USERS_PREFERRED_CONTACT_EMAIL,
  USERS_PREFERRED_CONTACT_PHONE,
} from 'app/constants';

class GeneralHelper {
  static getPreferContactEmails() {
    return Object.keys(USERS_PREFERRED_CONTACT_EMAIL).map((key) => ({
      key: key,
      value: key,
      label: USERS_PREFERRED_CONTACT_EMAIL[key],
    }));
  }

  static getPreferContactPhones() {
    return Object.keys(USERS_PREFERRED_CONTACT_PHONE).map((key) => ({
      key: key,
      value: key,
      label: USERS_PREFERRED_CONTACT_PHONE[key],
    }));
  }

  static userStatus() {
    return Object.keys(USER_STATUS).map((key) => ({
      key: key,
      value: key,
      label: USER_STATUS[key],
    }));
  }
}

export default GeneralHelper;
