import {MenuFoldOutlined, MenuUnfoldOutlined} from '@ant-design/icons';
import {Button} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

const CollapseMenuHandle = ({isCollapsed, toggleCollapsed, ...restProps}) => {
  return (
    <Button
      type='text'
      icon={
        isCollapsed ? (
          <MenuUnfoldOutlined className={'text-xxl'} />
        ) : (
          <MenuFoldOutlined className={'text-xxl'} />
        )
      }
      onClick={toggleCollapsed}
      {...restProps}
    />
  );
};

CollapseMenuHandle.propTypes = {
  isCollapsed: PropTypes.bool,
  toggleCollapsed: PropTypes.func,
};

export default React.memo(CollapseMenuHandle);
