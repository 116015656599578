import AppPageContainer from '@wieldy/components/pageComponents/AppPageContainer';
import AppPageHeader from '@wieldy/components/pageComponents/AppPageHeader';
import {Button} from 'antd';
import {useAccountType} from 'app/hooks/useAccountType';
import React from 'react';
import {useNavigate} from 'react-router-dom';

import MainContent from './MainContent';

const Financials = () => {
  const {isMarketplace, isClient} = useAccountType();
  const navigate = useNavigate();

  return (
    <AppPageContainer
      header={
        <AppPageHeader
          title='Invoices'
          category='financials'
          footer={
            isMarketplace &&
            isClient && (
              <div className='text-right'>
                <Button
                  type='primary'
                  shape='round'
                  className='mb-2 mr-2'
                  onClick={() => navigate('/financials/add-new-invoice')}>
                  Create Engagement Invoice
                </Button>
                <Button
                  type='primary'
                  shape='round'
                  className='mb-2'
                  onClick={() => navigate('/financials/add-placement-invoice')}>
                  Create Placement Invoice
                </Button>
              </div>
            )
          }
        />
      }>
      <MainContent />
    </AppPageContainer>
  );
};

export default Financials;
