import PropTypes from 'prop-types';
import React from 'react';

import CreateReview from './CreateReview';
import UpdateReview from './UpdateReview';

const MainContent = ({review}) => {
  if (!review?.supplierReview) {
    return <CreateReview review={review} />;
  }
  return <UpdateReview review={review} />;
};

MainContent.propTypes = {
  review: PropTypes.object,
};

export default MainContent;
