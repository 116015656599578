import AppFormWrapper, {
  AppFormActions,
  AppFormBody,
} from '@wieldy/components/AppFormWrapper';
import AppLoader from '@wieldy/components/AppLoader';
import AppDatePicker from '@wieldy/components/dataEntry/AppDatePicker';
import AppSelect from '@wieldy/components/dataEntry/AppSelect';
import AppTextArea from '@wieldy/components/dataEntry/AppTextArea';
import {
  getInputPlaceHolder,
  getRequiredMessage,
  getSelectPlaceHolder,
} from '@wieldy/utils/helpers/MessageHelper';
import {Button, Form, Select, Space, TimePicker} from 'antd';
import {useGetEngagementTaskList} from 'app/services/engagementsService';
import {
  addItems,
  resetLogForm,
  updateItems,
} from 'app/store/features/timecards/timecardsSlice';
import {getCurrentWeek} from 'app/utils/helpers/GetCurrentWeek';
import {getItemsData} from 'app/utils/helpers/GetItemsData';
import {uniqueId} from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

const LogTimeForm = ({
  logTimeVisible,
  setLogTimeVisible,
  form,
  handleCancelPopup,
}) => {
  const dispatch = useDispatch();
  const {payload, temp} = useSelector(({timecards}) => timecards);
  const {engagement, end_date} = payload;
  const {data, isLoading} = useGetEngagementTaskList({
    engagement,
    active: true,
    include: ['total_count'],
  });

  const [tasks] = getItemsData('tasks', data);
  const {initialLogForm, isChangeTime} = temp;

  useEffect(() => {
    form.setFieldsValue(initialLogForm);
  }, [form, initialLogForm]);

  function disabledDate(current) {
    const currentWeekEntries = getCurrentWeek(end_date);
    const currentWeek = currentWeekEntries.map(([, value]) => value);
    return !currentWeek.some((allowedDate) =>
      moment(allowedDate).isSame(current, 'day'),
    );
  }

  const getMinutes = (time) => {
    const selectedTime = moment(time, 'HH:mm');
    let hours = selectedTime.format('HH');
    let minutes = selectedTime.format('mm');
    if (hours.charAt(0) === '0') {
      hours = hours.substr(1);
    }
    if (minutes.charAt(0) === '0') {
      minutes = minutes.substr(1);
    }
    return Number(hours) * 60 + Number(minutes);
  };

  const onFormSubmit = (formData) => {
    const dateFromForm = formData.work_date.format('YYYY-MM-DD');
    const currentTask = tasks.find(
      (task) => task.id === formData.engagement_task,
    );

    const idGenerator = initialLogForm?.id
      ? {id: initialLogForm?.id}
      : {tempId: isChangeTime ? initialLogForm.tempId : uniqueId('temp')};

    const item = {
      ...formData,
      work_date: dateFromForm,
      taskHid: formData.taskId || currentTask.hid,
      taskName: formData.taskName || currentTask.description,
      minutes: getMinutes(formData.minutes),
      ...idGenerator,
    };

    if (isChangeTime) {
      dispatch(updateItems(item));
    } else {
      dispatch(addItems([item]));
    }

    dispatch(resetLogForm());
    setLogTimeVisible(!logTimeVisible);
    form.resetFields();
  };

  if (isLoading) {
    return <AppLoader />;
  }

  return (
    <AppFormWrapper>
      <Form
        form={form}
        layout='vertical'
        onFinish={onFormSubmit}
        autoComplete='off'>
        <AppFormBody>
          <Form.Item
            name='engagement_task'
            label='Task'
            rules={[
              {
                required: true,
                message: getRequiredMessage('Engagement Task'),
              },
            ]}>
            <AppSelect
              disabled={temp.isChangeTime}
              showSearch
              placeholder={getSelectPlaceHolder('task')}>
              {!!tasks &&
                tasks.length &&
                tasks.map((el) => (
                  <Select.Option key={el.id} value={el.id}>
                    {el.description}
                  </Select.Option>
                ))}
            </AppSelect>
          </Form.Item>
          <Form.Item
            name='work_date'
            label='Date'
            rules={[
              {
                required: true,
                message: getRequiredMessage('Work date'),
              },
            ]}>
            <AppDatePicker
              placeholder={getSelectPlaceHolder('date')}
              defaultValue={moment(end_date)}
              disabled={temp.isChangeTime}
              disabledDate={disabledDate}
              style={{
                width: '100%',
              }}
            />
          </Form.Item>
          <Form.Item
            name='minutes'
            label='Time Worked'
            rules={[{required: true, message: getRequiredMessage('Hours')}]}>
            <TimePicker
              placeholder={getSelectPlaceHolder('hours and minutes')}
              style={{
                width: '100%',
              }}
              minuteStep={5}
              format={'HH:mm'}
              showNow={false}
            />
          </Form.Item>
          <Form.Item
            name='type'
            label='Time Type'
            rules={[
              {
                required: true,
                message: getRequiredMessage('Time Type'),
              },
            ]}>
            <AppSelect
              showSearch
              placeholder={getSelectPlaceHolder('time type')}>
              <Select.Option value={'regular'}>
                Regular Time Hours
              </Select.Option>
              {temp?.overtime_eligible && (
                <Select.Option value={'overtime'}>Overtime Hours</Select.Option>
              )}
            </AppSelect>
          </Form.Item>
          <Form.Item name='notes' label='Notes'>
            <AppTextArea placeholder={getInputPlaceHolder('notes')} />
          </Form.Item>
        </AppFormBody>

        <AppFormActions>
          <Space size={'middle'}>
            <Button type='primary' htmlType='submit'>
              Log Time
            </Button>
            <Button onClick={handleCancelPopup}>Cancel</Button>
          </Space>
        </AppFormActions>
      </Form>
    </AppFormWrapper>
  );
};
LogTimeForm.propTypes = {
  logTimeVisible: PropTypes.bool,
  setLogTimeVisible: PropTypes.func,
  form: PropTypes.object,
  handleCancelPopup: PropTypes.func,
};
export default LogTimeForm;
