import './index.style.less';

import PropTypes from 'prop-types';
import React from 'react';

const AppLoader = ({style}) => {
  return (
    <div className='loader-root' style={style}>
      <div className='loader'></div>
    </div>
  );
};

AppLoader.propTypes = {
  style: PropTypes.object,
};

export default AppLoader;
