import AppCard from '@wieldy/components/AppCard';
import {Descriptions} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

import {
  GUARANTEE_PERIOD,
  GUARANTEE_PERIOD_ACTION,
  RECRUITMENT_FEE_STRUCTURE,
  RECRUITMENT_FEE_STRUCTURE_KEYS,
} from '../../../../constants';
import {calcRecruitmentFee} from '../../../../utils/helpers/CalcRecruitmentFee';

const InformationView = ({requisitionModal}) => {
  const isFixed =
    requisitionModal.recruitment_fee_structure ===
    RECRUITMENT_FEE_STRUCTURE_KEYS.fixed_fee;

  return (
    <AppCard style={{maxWidth: 990}}>
      <Descriptions
        colon={false}
        labelStyle={{width: '50%'}}
        className='description-root mb-4'
        column={{xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1}}>
        <Descriptions.Item label='Fee Structure'>
          {isFixed
            ? RECRUITMENT_FEE_STRUCTURE.fixed_fee
            : `${requisitionModal.recruitment_fee}% of salary`}
        </Descriptions.Item>
        <Descriptions.Item label='Recruitment Fee'>
          {calcRecruitmentFee({
            isFixed,
            base: requisitionModal.base_salary,
            min: requisitionModal.base_salary_min,
            max: requisitionModal.base_salary_max,
            fee: requisitionModal.recruitment_fee,
          })}
        </Descriptions.Item>

        <Descriptions.Item label='Guarantee Period'>
          {GUARANTEE_PERIOD[requisitionModal.guarantee_period]}
        </Descriptions.Item>
        <Descriptions.Item label='Guarantee Action'>
          {GUARANTEE_PERIOD_ACTION[requisitionModal.guarantee_period_action]}
        </Descriptions.Item>
      </Descriptions>
    </AppCard>
  );
};

InformationView.propTypes = {
  requisitionModal: PropTypes.object,
};

export default InformationView;
