import {submissionAPI} from '../api/submissionApi';
import useReactQuery, {
  useQueryClient,
  useReactMutation,
} from '../hooks/useReactQuery';

const useGetSubmissions = (queryParams = {}, options = {}) => {
  return useReactQuery(
    ['submissions', queryParams],
    async () => {
      const {data} = await submissionAPI.getSubmissions(queryParams);
      return {
        submissions: data?.data || [],
        total_count: data.total_count,
      };
    },
    options,
  );
};

const useGetAllSubmissions = (params = {}, statuses = []) => {
  return useReactQuery(
    ['submissions', params],
    async () => {
      const allPromise = statuses.map(({status, sort}) => {
        return submissionAPI.getSubmissions({...params, status, sort});
      });
      const response = await Promise.all(allPromise);
      return response.map(({data, config}) => ({
        submissions: data?.data || [],
        total_count: data?.total_count || 0,
        status: config.params.status,
      }));
    },
    {
      enabled: !!statuses.length,
    },
  );
};
const useGetSubmission = (id, queryParams = {}, options = {}) =>
  useReactQuery(
    ['submission', id, queryParams],
    async () => {
      const {data} = await submissionAPI.getSubmission(id, queryParams);
      return data;
    },
    options,
  );

const useCreateSubmission = () => {
  const client = useQueryClient();
  return useReactMutation(async (payload) => {
    const {data} = await submissionAPI.createSubmission(payload);
    await client.invalidateQueries();
    return {
      data,
      message: 'Submission created successfully',
    };
  });
};

const useUpdateSubmission = (id) => {
  const client = useQueryClient();
  return useReactMutation(async (payload) => {
    const {data} = await submissionAPI.updateSubmission(id, payload);
    await client.invalidateQueries(['submission', id]);
    return data;
  });
};

export {
  useCreateSubmission,
  useGetAllSubmissions,
  useGetSubmission,
  useGetSubmissions,
  useUpdateSubmission,
};
