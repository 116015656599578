import AppCard from '@wieldy/components/AppCard';
import AppLoader from '@wieldy/components/AppLoader';
import AppTable from '@wieldy/components/AppTable';
import {getTablePagination} from '@wieldy/utils/helpers/TableHelper';
import {Button} from 'antd';
import {useBrowse} from 'app/hooks/useBrowse';
import {useRole} from 'app/hooks/useRole';
import {useToggle} from 'app/hooks/useToggle';
import NewEngagementRates from 'app/pages/main/engagements/ViewEngagement/MainContent/EngagementRates/NewEngagementRates';
import {setEngagementRate} from 'app/store/features/engagement/engaementTablesSlice';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, {useCallback} from 'react';
import {useDispatch} from 'react-redux';

import {useAccountType} from '../../../../../../hooks/useAccountType';
import {getColumns} from './columns';

const EngagementRates = ({rates, totalCount, isLoading, engagementStatus}) => {
  const dispatch = useDispatch();
  const {pageSize, currentPage, onChangePage, onShowSizeChange} = useBrowse({});
  const {isMarketManager, isAdmin} = useRole();
  const {isMarketplace} = useAccountType();
  const isShowButton =
    isMarketplace &&
    (isMarketManager || isAdmin) &&
    engagementStatus !== 'completed';
  const [openModal, handleOpenModal] = useToggle(false);

  const bulkActions = useCallback(
    ({start_date, end_date, id, billing_rate, billing_rate_period}) => {
      const payload = {
        start_end_date: [moment.unix(start_date), moment.unix(end_date)],
        id,
        billing_rate,
        billing_rate_period,
      };

      return [
        {
          title: 'Edit Rate',
          onClick: () => {
            dispatch(setEngagementRate(payload));
            handleOpenModal(true);
          },
        },
      ];
    },
    [dispatch, handleOpenModal],
  );

  if (isLoading) {
    return <AppLoader />;
  }

  return (
    <AppCard
      title='Engagement Rates'
      headStyle={{borderBottom: 0}}
      bodyStyle={{paddingTop: 8}}
      extra={
        isShowButton && (
          <Button onClick={handleOpenModal} shape='round' type='primary'>
            Add New Engagement Rate
          </Button>
        )
      }>
      <AppTable
        bulkActions={isShowButton && bulkActions}
        className={'column-valign-top'}
        columns={getColumns()}
        dataSource={rates}
        pagination={getTablePagination(
          pageSize,
          currentPage,
          totalCount,
          onShowSizeChange,
          onChangePage,
        )}
      />
      <NewEngagementRates
        visiable={openModal}
        onCancel={() => handleOpenModal(false)}
        rates={rates}
      />
    </AppCard>
  );
};
EngagementRates.propTypes = {
  rates: PropTypes.array,
  totalCount: PropTypes.number,
  isLoading: PropTypes.bool,
  engagementStatus: PropTypes.string,
};

export default EngagementRates;
