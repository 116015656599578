import AppTable from '@wieldy/components/AppTable';
import {Empty, Typography} from 'antd';
import {useAccountType} from 'app/hooks/useAccountType';
import TotalTimeSummary from 'app/pages/main/financials/ViewInvoice/MainContent/TotalTimeSummary';
import {useGetInvoiceExpenseLines as clientUseGetInvoiceExpenseLines} from 'app/services/clientService';
import {useGetInvoiceExpenseLines as supplierUseGetInvoiceExpenseLines} from 'app/services/supplierService';
import PropTypes from 'prop-types';
import React, {useMemo} from 'react';
import {useParams} from 'react-router-dom';

import {useGetColumns} from './columns';

const Expenses = ({invoice}) => {
  const {id} = useParams();
  const {isSupplier} = useAccountType();
  const {data: invoiceExpenseLines} = (
    isSupplier
      ? supplierUseGetInvoiceExpenseLines
      : clientUseGetInvoiceExpenseLines
  )(id, {sort: ['expand_expense_item.date_of_spend']});
  const columns = useGetColumns();

  const total = useMemo(
    () => ({
      amount: invoice.expenses_total,
      tax: invoice.expenses_total_tax,
      billable: isSupplier
        ? invoice.client_expenses_total_billable
        : invoice.supplier_expenses_total_billable,
    }),
    [
      invoice.client_expenses_total_billable,
      invoice.expenses_total,
      invoice.expenses_total_tax,
      invoice.supplier_expenses_total_billable,
      isSupplier,
    ],
  );

  return invoiceExpenseLines?.items?.length ? (
    <>
      <Typography.Title level={4} className={'mb-4'}>
        Expenses
      </Typography.Title>
      <AppTable
        emptyText={
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description='No Expenses'
          />
        }
        columns={columns}
        dataSource={invoiceExpenseLines.items.map((invoiceExpenseLine) => ({
          ...invoiceExpenseLine,
          invoice,
        }))}
        pagination={false}
        summary={() => (
          <TotalTimeSummary
            span={2}
            tax={total.tax}
            amount={total.amount}
            billable={total.billable}
            title='Expense Total:'
          />
        )}
      />
    </>
  ) : (
    <></>
  );
};

Expenses.propTypes = {
  invoice: PropTypes.object,
};

export default Expenses;
