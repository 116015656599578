import AppCard from '@wieldy/components/AppCard';
import PropTypes from 'prop-types';
import React from 'react';

const NoteEngagement = ({title, subtitle}) => {
  return (
    <AppCard title={title}>
      <p>{subtitle}</p>
    </AppCard>
  );
};

NoteEngagement.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

NoteEngagement.defaultProps = {
  title: 'Timecard cannot be created',
  subtitle: 'You need to have some active engagement for creation the timecard',
};

export default NoteEngagement;
