import * as yup from 'yup';
import {array, object, string} from 'yup';

export const getDefRegion = (key) =>
  object().test(
    'at-least-one-true',
    `At least one ${key} must be selected`,
    (industries) =>
      Object.values(industries || {}).some(
        (industry) => industry.value === true,
      ),
  );

export const getServiceRegionSchema = ({isJob, isService, isBoth}) => {
  return yup.object({
    service_region_countries: array()
      .of(string())
      .min(1, 'Service regions required')
      .required('Service regions required'),
    service_region_us_states: array().of(string()),
    specialization_job_functions:
      isJob || isBoth
        ? getDefRegion('job function').required()
        : object().notRequired(),
    specialization_service_focuses:
      isService || isBoth
        ? getDefRegion('service').required()
        : object().notRequired(),
    specialization_industry_focuses: getDefRegion('industry').required(),
  });
};
