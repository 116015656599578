import {Spin} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

import AppStepContext from './AppStepContext';

const AppStepProvider = ({
  children,
  preview,
  onFinish,
  stepData,
  isLoading,
  current,
  isUpdated = false,
}) => {
  const [currentStep, setCurrentStep] = React.useState(current || 1);
  const [isPreview, setIsPreview] = React.useState(false);
  const [stateData, setStateData] = React.useState(stepData || {});

  const nextStep = React.useCallback(() => {
    setCurrentStep(currentStep + 1);
  }, [currentStep]);

  const goToStep = React.useCallback((step) => {
    if (isNaN(step) || step < 0) {
      throw new Error(`Step number ${step} is incorrect format`);
    }
    setCurrentStep(step);
  }, []);

  const setStepData = React.useCallback((data) => {
    if (data) {
      setStateData((prevState) => ({
        ...prevState,
        ...data,
      }));
    }
  }, []);

  const resetSteps = React.useCallback((dataReset = false) => {
    setCurrentStep(0);
    if (dataReset) {
      setStateData({});
    }
  }, []);

  const finishSteps = React.useCallback(() => {
    if (typeof onFinish === 'function') {
      onFinish(stateData);
    }
  }, [onFinish, stateData]);

  const contextValues = React.useMemo(() => {
    return {
      currentStep,
      isPreview,
      setIsPreview,
      nextStep,
      goToStep,
      setStepData,
      finishSteps,
      resetSteps,
      isLoading,
      stepData: stateData,
      isUpdated,
    };
  }, [
    currentStep,
    isPreview,
    nextStep,
    goToStep,
    setStepData,
    finishSteps,
    resetSteps,
    isLoading,
    stateData,
    isUpdated,
  ]);

  return (
    <AppStepContext.Provider value={contextValues}>
      <Spin spinning={isLoading} size={'large'} tip={'Saving....'}>
        {isPreview && preview ? preview : children}
      </Spin>
    </AppStepContext.Provider>
  );
};

AppStepProvider.propTypes = {
  children: PropTypes.node,
  onFinish: PropTypes.func,
  preview: PropTypes.node,
  isLoading: PropTypes.bool,
  stepData: PropTypes.any,
  current: PropTypes.number,
  isUpdated: PropTypes.bool,
};
export default AppStepProvider;
