import {capitalizedAllWords} from '@wieldy/utils/helpers/StringHelper';
import {Typography} from 'antd';
import {DATE_FORMATS} from 'app/config/app';
import {
  formatAmount,
  formatDate,
  formatPayUnits,
  formatSecondsToDate,
  getFullName,
} from 'app/utils/helpers/DataFormatHelper';

export const getColumns = (timecardRedirect) => {
  return [
    {
      title: 'Timecard Id',
      dataIndex: 'id',
      key: 'id',
      render: (id, {hid}) => (
        <Typography.Link onClick={() => timecardRedirect(id)}>
          {hid}
        </Typography.Link>
      ),
    },
    {
      title: 'Week End Date',
      dataIndex: 'end_date',
      key: 'timeCardEnd',
      render: (date) => formatDate(date, DATE_FORMATS.md),
    },
    {
      title: 'Net Total',
      dataIndex: 'net_total',
      key: 'totalHours',
      render: (net_total) => formatAmount(net_total),
    },
    {
      title: 'Pay Units',
      dataIndex: 'pay_units',
      key: 'netAmount',
      render: (pay_units) => formatPayUnits(pay_units),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => capitalizedAllWords(status.replace('_', ' ')),
    },
    {
      title: 'Submitted',
      dataIndex: 'submitted',
      key: 'submitted',
      render: (text, {submitted_by, submitted_at}) => {
        return (
          <Typography.Paragraph>
            <Typography.Paragraph className={'mb-0'}>
              {!!submitted_by && getFullName(submitted_by)}
            </Typography.Paragraph>
            <Typography.Text type={'secondary'} className={'text-xs'}>
              {formatSecondsToDate(submitted_at, DATE_FORMATS.md)}
            </Typography.Text>
          </Typography.Paragraph>
        );
      },
    },
    {
      title: 'Approved',
      dataIndex: 'approved',
      key: 'approved',
      render: (text, {approved_by, approved_at}) => {
        return (
          <Typography.Paragraph>
            <Typography.Paragraph className={'mb-0'}>
              {approved_by && getFullName(approved_by)}
            </Typography.Paragraph>
            <Typography.Text type={'secondary'} className={'text-xs'}>
              {formatSecondsToDate(approved_at, DATE_FORMATS.md)}
            </Typography.Text>
          </Typography.Paragraph>
        );
      },
    },
  ];
};
