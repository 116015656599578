import PropTypes from 'prop-types';
import React from 'react';

import AppErrorBoundary from '../AppErrorBoundary';
import AppInfoView from '../AppInfoView';
import AppSuspense from '../AppSuspense';

const AppContentView = ({children}) => {
  return (
    <React.Fragment>
      <AppSuspense>
        <AppErrorBoundary>{children}</AppErrorBoundary>
      </AppSuspense>
      <AppInfoView />
    </React.Fragment>
  );
};

AppContentView.propTypes = {
  children: PropTypes.node.isRequired,
};

export default React.memo(AppContentView);
