import {Input} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

const AppInput = (props) => {
  return <Input {...props} />;
};

AppInput.propTypes = {
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
};

export default AppInput;
