import AppPageContainer from '@wieldy/components/pageComponents/AppPageContainer';
import AppPageHeader from '@wieldy/components/pageComponents/AppPageHeader';
import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';

import ApplicantModal from '../../../../modals/ApplicantModal';
import {AppStepsWrapper} from '../../../../providers/AppStepProvider';
import {
  resetResource,
  setInitial,
} from '../../../../store/features/resource/resourceSlice';
import ApplicantStepper from '../ApplicantStepper';

const CreateApplicant = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setInitial(new ApplicantModal()));
  }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch(resetResource());
    };
  }, [dispatch]);

  return (
    <AppStepsWrapper>
      <AppPageContainer
        header={
          <AppPageHeader category={'Applicants'} title={'New Applicant'} />
        }>
        <ApplicantStepper />
      </AppPageContainer>
    </AppStepsWrapper>
  );
};

export default CreateApplicant;
