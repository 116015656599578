import './index.style.less';

import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

const AppBorderBox = ({className, padding, ...restProps}) => {
  return (
    <div
      className={clsx('app-border-box', className)}
      style={{padding}}
      {...restProps}
    />
  );
};

AppBorderBox.propTypes = {
  className: PropTypes.string,
  padding: PropTypes.number,
};

export default AppBorderBox;
