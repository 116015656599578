import SupplierModal from 'app/modals/SupplierModal';
import Search from 'app/pages/common/Search/Search';
import {Suppliers, ViewSupplier} from 'app/pages/main/suppliers';
import {SupplierCard} from 'app/shared/supplier';

export const client_SupplierRoutes = [
  {
    path: 'suppliers',
    element: <Suppliers />,
  },
  {
    path: 'suppliers/:category',
    element: <Suppliers />,
  },
  {
    path: '/suppliers/search/:q?',
    element: (
      <Search
        entity={'suppliers'}
        card={(item, loading) => (
          <SupplierCard
            supplier={new SupplierModal(item)}
            loading={loading}
            showBookmark={false}
          />
        )}
      />
    ),
  },
  {
    path: 'suppliers/:id/view-supplier',
    element: <ViewSupplier />,
  },
];
