import {Menu} from 'antd';
import React from 'react';
import {Link} from 'react-router-dom';

import MenuItemContent from '../../components/helperComponents/MenuItemContent';

let routeMenuItems = [];

const renderMenuItemChildren = ({
  icon,
  title,
  path,
  type,
  openInNewTab,
  isComingSoon,
  mixpanelFunc,
}) => {
  const mixpanelData = {icon, title, path, type, openInNewTab, isComingSoon};

  if (path && path.includes('/')) {
    return type === 'url' ? (
      <a
        href={path}
        target={openInNewTab ? '_blank' : ''}
        rel='noreferrer'
        onClick={() => mixpanelFunc && mixpanelFunc(mixpanelData)}>
        <MenuItemContent
          icon={icon}
          title={title}
          isComingSoon={isComingSoon}
        />
      </a>
    ) : (
      <Link
        to={path}
        onClick={() => mixpanelFunc && mixpanelFunc(mixpanelData)}>
        <MenuItemContent
          icon={icon}
          title={title}
          isComingSoon={isComingSoon}
        />
      </Link>
    );
  } else {
    return (
      <MenuItemContent icon={icon} title={title} isComingSoon={isComingSoon} />
    );
  }
};

const renderMenuItem = (item, onClick) => {
  return item.submenu ? (
    <Menu.SubMenu key={item.id} title={renderMenuItemChildren(item)}>
      {item.submenu.map((item) => renderMenuItem(item, onClick))}
    </Menu.SubMenu>
  ) : (
    <Menu.Item key={item.id} onClick={onClick}>
      {item.children ? item.children : renderMenuItemChildren(item)}
    </Menu.Item>
  );
};

const renderMenu = (item, onClick, index) => {
  if (item.isDivider) {
    return <Menu.Divider key={item.id + index} className={item.className} />;
  } else if (item.group) {
    return (
      <Menu.ItemGroup
        key={item.path ? item.path : item.id}
        title={renderMenuItemChildren(item)}>
        {item.group.map((item) => renderMenuItem(item, onClick))}
      </Menu.ItemGroup>
    );
  } else if (item.submenu) {
    return (
      <Menu.SubMenu key={item.id} title={renderMenuItemChildren(item)}>
        {item.submenu.map((item) => renderMenuItem(item, onClick))}
      </Menu.SubMenu>
    );
  } else {
    return (
      <Menu.Item
        key={item.id}
        exact={item.exact}
        className={item.className}
        onClick={onClick}>
        {item.children ? item.children : renderMenuItemChildren(item)}
      </Menu.Item>
    );
  }
};

export const getRouteMenus = (routeMenus, onClick) => {
  routeMenuItems = routeMenus;
  if (!routeMenus || routeMenus.length === 0) {
    throw new Error('Route array of menu items not assigned!');
  }
  return routeMenus.map((item, index) => renderMenu(item, onClick, index));
};

export const findSelectedKeys = (defaultOpenKeys, segments) => {
  const subMenuList = routeMenuItems.find(
    (route) => route.id === defaultOpenKeys,
  );

  if (subMenuList?.submenu) {
    const subMenuIds = subMenuList.submenu.map((item) => item.id);
    const result = segments.filter((item) => subMenuIds.includes(item));
    return result[0] ? result.reverse()[0] : subMenuIds.shift();
  }
  return segments[0];
};
