import AppDatePicker from '@wieldy/components/dataEntry/AppDatePicker';
import AppSelect from '@wieldy/components/dataEntry/AppSelect';
import {
  getRequiredMessage,
  getSelectPlaceHolder,
} from '@wieldy/utils/helpers/MessageHelper';
import {Button, Form, Space, Typography} from 'antd';
import {CompanyMemberDropdown} from 'app/shared/components/dropdowns';
import {getFullName} from 'app/utils/helpers/DataFormatHelper';
import ResourceHelper from 'app/utils/ResourceHelper';
import moment from 'moment';
import propTypes from 'prop-types';
import React from 'react';
import {RiEditLine} from 'react-icons/ri';

export const defEditState = {
  id: '',
  field: '',
};

const Actions = ({setEditing, mutation}) => {
  return (
    <Space size='small'>
      <Button
        type={'primary'}
        size={'small'}
        htmlType={'submit'}
        loading={mutation.isLoading}>
        Save
      </Button>
      <Button
        type={'primary'}
        size={'small'}
        ghost={true}
        onClick={() => setEditing(defEditState)}>
        Cancel
      </Button>
    </Space>
  );
};

Actions.propTypes = {
  setEditing: propTypes.func,
  mutation: propTypes.object,
};

export const getColumns = ({navigate, editing, setEditing, mutation}) => {
  const onFinish = (payload) =>
    mutation.mutate(
      {
        action: 'change_resource',
        payload,
      },
      {
        onSuccess: () => {
          setEditing(defEditState);
        },
      },
    );

  return [
    {
      title: 'Name / Location',
      dataIndex: 'name',
      key: 'first_name',
      render: (name, record) => (
        <Typography.Paragraph>
          <Typography.Paragraph className={'mb-0'}>
            <Typography.Link
              className={'font-semibold'}
              onClick={() => navigate(`/resources/${record.id}/view-resource`)}>
              {name}
            </Typography.Link>
          </Typography.Paragraph>
          <Typography.Text type={'secondary'} className={'text-xs'}>
            {record.location}
          </Typography.Text>
        </Typography.Paragraph>
      ),
      sorter: true,
      showSorterTooltip: false,
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      sorter: true,
      showSorterTooltip: false,
    },
    {
      title: 'Billing Rate',
      dataIndex: 'billingRateLabel',
      key: 'billingRateLabel',
      showSorterTooltip: false,
      render: (rate) => <Typography>{rate}</Typography>,
    },
    {
      title: 'Status',
      dataIndex: 'availabilityName',
      key: 'availability',
      sorter: true,
      showSorterTooltip: false,
      render: (_, item) => {
        if (editing?.id === item?.id && editing.field === 'availability') {
          return (
            <Form
              layout={'vertical'}
              initialValues={{
                availability: item?.availability,
              }}
              onFinish={(data) => {
                if (data.availability === 'not_available') {
                  return onFinish({
                    availability: data.availability,
                    available_on: null,
                    available_to: null,
                    resourceId: item?.id,
                  });
                }

                return onFinish({
                  availability: data.availability,
                  resourceId: item?.id,
                });
              }}>
              <Space direction={'horizontal'} size={'small'}>
                <Form.Item
                  name={'availability'}
                  rules={[
                    {
                      required: true,
                      message: getRequiredMessage('Availability'),
                    },
                  ]}
                  style={{marginBottom: 0}}>
                  <AppSelect
                    size='small'
                    options={ResourceHelper.availabilityStatus(false)}
                    style={{minWidth: '180px'}}
                    defaultValiue={item.availability}
                  />
                </Form.Item>
                <Actions mutation={mutation} setEditing={setEditing} />
              </Space>
            </Form>
          );
        }
        return (
          <Typography.Text>
            {item?.availabilityName}
            {item?.availability !== 'engaged' && (
              <Typography.Link
                className='ml-2'
                onClick={() =>
                  setEditing({id: item?.id, field: 'availability'})
                }>
                <RiEditLine fontSize={16} />
              </Typography.Link>
            )}
          </Typography.Text>
        );
      },
    },
    {
      title: 'Available On',
      dataIndex: 'available_on',
      key: 'available_on',
      sorter: true,
      showSorterTooltip: false,
      render: (_, item) => {
        if (editing?.id === item?.id && editing.field === 'available_on') {
          return (
            <Form
              layout={'vertical'}
              initialValues={{
                resource_manager: item?.available_on,
              }}
              onFinish={(data) =>
                onFinish({
                  available_on: data.available_on,
                  resourceId: item?.id,
                })
              }>
              <Space direction={'horizontal'} size={'small'}>
                <Form.Item
                  name={'available_on'}
                  rules={[
                    {
                      required: true,
                      message: getRequiredMessage('Available On'),
                    },
                  ]}
                  style={{marginBottom: 0}}>
                  <AppDatePicker
                    style={{minWidth: '180px', height: 32}}
                    defaultValiue={
                      item?.available_on ? moment(item.available_on) : undefined
                    }
                  />
                </Form.Item>
                <Actions mutation={mutation} setEditing={setEditing} />
              </Space>
            </Form>
          );
        }
        return (
          <Typography.Text>
            {item?.available_on}
            {item?.availability !== 'not_available' && (
              <Typography.Link
                className='ml-2'
                onClick={() =>
                  setEditing({id: item?.id, field: 'available_on'})
                }>
                <RiEditLine fontSize={16} />
              </Typography.Link>
            )}
          </Typography.Text>
        );
      },
    },
    {
      title: 'Resource Manager',
      dataIndex: 'resourceManager',
      key: 'resource_manager',
      render: (_, item) => {
        if (editing?.id === item?.id && editing.field === 'resource_manager') {
          return (
            <Form
              layout={'vertical'}
              initialValues={{
                resource_manager: item?.resourceManager?.id,
              }}
              onFinish={(data) =>
                onFinish({
                  resource_manager: data.resource_manager,
                  resourceId: item?.id,
                })
              }>
              <Space direction={'horizontal'} size={'small'}>
                <Form.Item
                  name={'resource_manager'}
                  rules={[
                    {
                      required: true,
                      message: getRequiredMessage('Resource Manager'),
                    },
                  ]}
                  style={{marginBottom: 0}}>
                  <CompanyMemberDropdown
                    variant={'supplier'}
                    placeholder={getSelectPlaceHolder('Resource Manager')}
                    defaultValue={getFullName(item?.resource_manager)}
                    filterMembers={(member) => member.role !== 'resource'}
                    initialFetch={true}
                    showArrow={false}
                    allowClear={true}
                    style={{minWidth: '250px'}}
                    size={'small'}
                  />
                </Form.Item>
                <Actions mutation={mutation} setEditing={setEditing} />
              </Space>
            </Form>
          );
        }
        return (
          <Typography.Text>
            {getFullName(item?.resource_manager)}
            <Typography.Link
              className='ml-2'
              onClick={() =>
                setEditing({id: item?.id, field: 'resource_manager'})
              }>
              <RiEditLine fontSize={16} />
            </Typography.Link>
          </Typography.Text>
        );
      },
      sorter: true,
      showSorterTooltip: false,
    },
  ];
};
