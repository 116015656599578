import {Button, List, notification, Typography} from 'antd';
import * as noteService from 'app/services/noteService';
import {formatUnixDate} from 'app/utils/helpers/DataFormatHelper';
import PropTypes from 'prop-types';
import React from 'react';
import {RiDeleteBin6Fill, RiEditFill} from 'react-icons/ri';

import NoteForm from './NoteForm';

const NoteItem = ({note, editable, entity, primaryId, title}) => {
  const [isEdit, setIsEdit] = React.useState(false);
  const [disabled, setDisabled] = React.useState(true);

  const updateMutation = noteService.useUpdateNote(entity, primaryId);
  const deleteMutation = noteService.useDeleteNote(entity, primaryId);

  const handleUpdateNote = React.useCallback(
    (formData) => {
      updateMutation.mutate(
        {
          id: note.id,
          message: formData?.message,
        },
        {
          onSuccess: async () => {
            setIsEdit(false);
            notification.success({message: 'Note updated successfully'});
          },
        },
      );
    },
    [note.id, updateMutation],
  );

  const handleDeleteNote = React.useCallback(() => {
    deleteMutation.mutate(note.id, {
      onSuccess: () => {
        notification.success({message: 'Note deleted successfully'});
      },
    });
  }, [deleteMutation, note.id]);

  const handleChangeMessage = React.useCallback(
    (form) => {
      if (Array.isArray(form)) {
        return setDisabled(form[0].value === note.text);
      }
      setDisabled(false);
    },
    [note.text],
  );

  return (
    <List.Item className={'app-border-box px-6 mb-4'}>
      <List.Item.Meta
        description={
          <div className={'d-flex align-items-start'}>
            <div className={'flex-grow-1'}>
              <Typography.Title level={4} className={'mb-0'}>
                {editable ? 'You' : title}
              </Typography.Title>
              <Typography.Paragraph type='secondary' className={'text-xs mb-0'}>
                {formatUnixDate(note.updated_at, 'MMM DD, YYYY hh:mm:ss')}
              </Typography.Paragraph>
            </div>

            {editable && (
              <div className={'ml-2'}>
                <Button
                  type='text'
                  shape='circle'
                  icon={<RiEditFill className={'text-lg text-secondary'} />}
                  onClick={() => setIsEdit(true)}
                />
                <Button
                  type='text'
                  shape='circle'
                  loading={deleteMutation.isLoading}
                  icon={
                    <RiDeleteBin6Fill className={'text-lg text-secondary'} />
                  }
                  onClick={handleDeleteNote}
                />
              </div>
            )}
          </div>
        }
      />
      {isEdit ? (
        <NoteForm
          onSubmit={handleUpdateNote}
          initialValues={{message: note.text}}
          onFieldsChange={handleChangeMessage}>
          <Button
            type='primary'
            disabled={disabled}
            loading={updateMutation.isLoading}
            htmlType='submit'>
            Save
          </Button>
          <Button type='secondary' onClick={() => setIsEdit(false)}>
            Cancel
          </Button>
        </NoteForm>
      ) : (
        <Typography.Paragraph>{note.text}</Typography.Paragraph>
      )}
    </List.Item>
  );
};

NoteItem.propTypes = {
  note: PropTypes.object,
  editable: PropTypes.bool,
  primaryId: PropTypes.string,
  entity: PropTypes.string,
  title: PropTypes.string,
};

export default NoteItem;
