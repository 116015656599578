import AppSteps from '@wieldy/components/AppSteps';
import AppStep from '@wieldy/components/AppSteps/AppStep';
import AppStepTitle from '@wieldy/components/AppSteps/AppStep/AppStepTitle';
import useStep from 'app/hooks/useStep';
import SelectPeriod from 'app/pages/main/financials/CreateInvoice/MainContent/SelectPeriod';
import {resetInvoiceActions} from 'app/store/features/invoice/invoiceSlice';
import {useCurrentUser} from 'app/store/features/user/userSlice';
import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import SelectEngagement from './SelectEngagement';
import SelectExpenses from './SelectExpenses';
import SelectTimecards from './SelectTimecards';
import SubmitInvoice from './SubmitInvoice';

const MainContent = () => {
  const {currentStep, goToStep} = useStep();
  const dispatch = useDispatch();
  const {isEditMode} = useSelector(({invoice}) => invoice);
  const {invoicing_period} = useCurrentUser();
  const isWeekly = invoicing_period === 'weekly';
  const periodTitle = `Select ${isWeekly ? 'week' : 'month'} end date`;
  // const userParams = useInvoiceHeaders();
  useEffect(() => {
    return () => {
      dispatch(resetInvoiceActions());
    };
  }, [dispatch]);

  return (
    <AppSteps current={currentStep}>
      <AppStep
        title={
          <AppStepTitle
            title='Engagement'
            isEdit={!isEditMode && currentStep > 1}
            onEditClick={() => goToStep(1)}
          />
        }
        step={1}
        description={<SelectEngagement />}
      />
      <AppStep
        title={
          <AppStepTitle
            title={periodTitle}
            isEdit={!isEditMode && currentStep > 2}
            onEditClick={() => goToStep(2)}
          />
        }
        step={2}
        description={<SelectPeriod isWeekly={isWeekly} />}
      />
      <AppStep
        title={
          <AppStepTitle
            title='Select timecard(s) to be included on this invoice'
            isEdit={!isEditMode && currentStep > 3}
            onEditClick={() => goToStep(3)}
          />
        }
        step={3}
        description={<SelectTimecards />}
      />

      <AppStep
        title={
          <AppStepTitle
            title='Select expense report(s) to be included on this invoice'
            isEdit={!isEditMode && currentStep > 4}
            onEditClick={() => goToStep(4)}
          />
        }
        step={4}
        description={<SelectExpenses />}
      />

      <AppStep
        title={<AppStepTitle title='Create new invoice' />}
        step={5}
        description={<SubmitInvoice />}
      />
    </AppSteps>
  );
};

export default MainContent;
