import {CloudDownloadOutlined} from '@ant-design/icons';
import {Button, Divider, Tooltip} from 'antd';
import PropTypes from 'prop-types';
import React, {memo, useCallback} from 'react';

import {downloadBlob} from '../../../pages/common/Terms/components/utils/downloadBlob';

const DownloadResume = ({blob, isShow, name}) => {
  const handleDownloadResume = useCallback(() => {
    downloadBlob(blob, name, blob?.type);
  }, [blob, name]);

  if (!isShow) return null;

  return (
    <>
      <Tooltip overlayStyle={{fontSize: '12px'}} title='Download Resume'>
        <Button
          type='primary'
          shape='circle'
          className='post-header-button items-center d-none lg:flex w-full'
          onClick={handleDownloadResume}
          icon={<CloudDownloadOutlined style={{fontSize: '18px'}} />}
        />
      </Tooltip>
      <Divider type='vertical' style={{height: 28}} />
    </>
  );
};

DownloadResume.propTypes = {
  isShow: PropTypes.bool,
  blob: PropTypes.object,
  name: PropTypes.string,
};

export default memo(DownloadResume);
