import {Button} from 'antd';
import React from 'react';
import {RiArrowLeftSLine, RiArrowRightSLine} from 'react-icons/ri';

export const getListPagination = (
  currentPage,
  dataCount = 0,
  onPageChange,
  pageSize,
) => {
  if (dataCount <= pageSize) {
    return false;
  }

  const itemRender = (current, type, originalElement) => {
    if (type === 'prev') {
      return (
        <Button className='ant-pagination-item-link'>
          <RiArrowLeftSLine fontSize={24} />
        </Button>
      );
    }
    if (type === 'next') {
      return (
        <Button className='ant-pagination-item-link'>
          <RiArrowRightSLine fontSize={24} />
        </Button>
      );
    }
    return originalElement;
  };

  return {
    pageSize: pageSize,
    current: currentPage,
    onChange: onPageChange,
    total: dataCount,
    itemRender: itemRender,
  };
};
