import AppGridContainer from '@wieldy/components/AppGridContainer';
import {Col} from 'antd';
import {useAccountType} from 'app/hooks/useAccountType';
import Notes from 'app/shared/components/Notes/Notes';
import Certifications from 'app/shared/resource/Certifications';
import Courses from 'app/shared/resource/Courses';
import Education from 'app/shared/resource/Education';
import EmploymentHistory from 'app/shared/resource/EmploymentHistory';
import Languages from 'app/shared/resource/Languages';
import PerformanceRating from 'app/shared/resource/PerformanceRating';
import Skills from 'app/shared/resource/Skills';
import Summary from 'app/shared/resource/Summary';
import {SupplierCard} from 'app/shared/supplier';
import PropTypes from 'prop-types';
import React from 'react';

const MainContent = ({
  resource,
  supplier,
  tabView,
  isShowNotes,
  notesResponse,
  notesTotal,
}) => {
  const {isClient} = useAccountType();

  const {
    isSkills,
    isRating,
    isEducation,
    isCourses,
    isCertifications,
    isLanguages,
    isHistory,
  } = tabView;

  return (
    <AppGridContainer>
      <Col xs={24} lg={15} xxl={16}>
        <AppGridContainer>
          <Col sm={24}>
            <Summary resource={resource} />
          </Col>
          {isHistory && (
            <Col sm={24} id='employment'>
              <EmploymentHistory history={resource.employment_history} />
            </Col>
          )}
          {isSkills && (
            <Col sm={24} id='skills'>
              <Skills resource={resource} />
            </Col>
          )}
          {isShowNotes && (
            <Col sm={24} id='notes'>
              <Notes
                entity={'resource_notes'}
                primaryKey={'resource'}
                primaryId={resource.id}
                notesResponse={notesResponse}
                notesTotal={notesTotal}
              />
            </Col>
          )}
        </AppGridContainer>
      </Col>
      <Col xs={24} lg={9} xxl={8}>
        <AppGridContainer>
          {isRating && (
            <Col xs={24} sm={24} md={24} id='performance-rating'>
              <PerformanceRating resource={resource} />
            </Col>
          )}
          {isClient && (
            <Col xs={24} sm={24} md={24} id='supplier'>
              <SupplierCard supplier={supplier} />
            </Col>
          )}
          {isEducation && (
            <Col xs={24} sm={24} md={24} id='eduction'>
              <Education educations={resource.educations} />
            </Col>
          )}
          {isCourses && (
            <Col xs={24} sm={24} md={24} id='courses'>
              <Courses courses={resource.courses} />
            </Col>
          )}
          {isCertifications && (
            <Col xs={24} sm={24} md={24} id='certifications'>
              <Certifications certifications={resource.certifications} />
            </Col>
          )}
          {isLanguages && (
            <Col xs={24} sm={24} md={24} id='languages'>
              <Languages languages={resource.languages} />
            </Col>
          )}
        </AppGridContainer>
      </Col>
    </AppGridContainer>
  );
};

MainContent.propTypes = {
  resource: PropTypes.object,
  supplier: PropTypes.object,
  tabView: PropTypes.object,
  isShowNotes: PropTypes.bool,
  notesResponse: PropTypes.object,
  notesTotal: PropTypes.number,
};

export default MainContent;
