import AppSteps from '@wieldy/components/AppSteps';
import AppStep from '@wieldy/components/AppSteps/AppStep';
import AppStepTitle from '@wieldy/components/AppSteps/AppStep/AppStepTitle';
import EngagementStep from 'app/shared/components/EngagementStep';
import WeekEndTimeCard from 'app/shared/components/WeekEndTimeCard';
import PropTypes from 'prop-types';
import React from 'react';
import {useSelector} from 'react-redux';

import LogTimeCard from '../LogTimeCard';

const StepperGeneral = ({currentStep, setCurrentStep, goToNext}) => {
  const {isEditMode} = useSelector(({timecards}) => timecards.temp);

  return (
    <AppSteps current={currentStep}>
      <AppStep
        title={
          <AppStepTitle
            title='Engagement'
            isEdit={!isEditMode && currentStep > 0}
            onEditClick={() => setCurrentStep(0)}
          />
        }
        description={
          <EngagementStep
            isTimeCards={true}
            isShowDetails={currentStep > 0}
            isShowForm={currentStep === 0}
            onContinue={goToNext}
          />
        }
      />
      <AppStep
        title={
          <AppStepTitle
            title='Timecard Date'
            isEdit={!isEditMode && currentStep > 1}
            onEditClick={() => setCurrentStep(1)}
          />
        }
        description={
          <WeekEndTimeCard
            isShowDetails={currentStep > 1}
            isShowForm={currentStep === 1}
            onContinue={goToNext}
          />
        }
      />
      <AppStep
        title={
          <AppStepTitle
            title='Hours Worked'
            isEdit={!isEditMode && currentStep > 2}
            onEditClick={() => setCurrentStep(2)}
          />
        }
        description={
          <LogTimeCard
            isShowDetails={currentStep > 2}
            isShowForm={currentStep === 2}
            onContinue={goToNext}
          />
        }
      />
    </AppSteps>
  );
};

StepperGeneral.propTypes = {
  currentStep: PropTypes.number,
  setCurrentStep: PropTypes.func,
  goToNext: PropTypes.func,
};

export default StepperGeneral;
