import AppSelect from '@wieldy/components/dataEntry/AppSelect';
import {getSelectPlaceHolder} from '@wieldy/utils/helpers/MessageHelper';
import {Button, Dropdown, Form, Menu, Typography} from 'antd';
import {DATE_FORMATS} from 'app/config/app';
import {useAccountType} from 'app/hooks/useAccountType';
import {
  formatBillingRateRage,
  formatDate,
  getYesOrNo,
} from 'app/utils/helpers/DataFormatHelper';
import React from 'react';
import {RiMore2Fill} from 'react-icons/ri';
import {useNavigate} from 'react-router-dom';

import {PERIODS} from '../../../../../../constants';
import {WORK_TYPE_KEYS} from '../../../../../../constants/work-types';
import {existTableStatus} from '../../../../inquiries/constants';

export const useGetColumns = ({
  isEditTable,
  initialState,
  setInitialState,
  isShowActions,
  handleWithdrawCandidate,
  handleChangeCandidateStatus,
  requisition,
}) => {
  const navigate = useNavigate();
  const {isClient, isSupplier} = useAccountType();

  const handleChangeState = (recordId, value) => {
    const newInitialState = initialState.map((el) => {
      if (el.id === recordId) {
        return {...el, editMode: value};
      } else {
        return el;
      }
    });
    setInitialState(newInitialState);
  };

  const handleSinglePageView = (id) => {
    navigate(`/candidates/${id}/view-candidate`);
  };

  const actions = {
    title: '',
    dataIndex: 'action',
    key: 'action',
    fixed: 'right',
    align: 'center',
    width: 64,
    render: (_, {status_key, id}) => {
      const title = `Withdraw Candidate`;

      const isAvailableStatus = isClient && status_key !== 'proposed';

      const titleStatus = isClient && 'Change Status';
      return (
        <Dropdown
          overlay={
            <Menu>
              {isAvailableStatus && (
                <Menu.Item
                  key='status'
                  title={titleStatus}
                  onClick={() => handleChangeCandidateStatus(id)}>
                  {titleStatus}
                </Menu.Item>
              )}
              {isSupplier && (
                <Menu.Item
                  key='withdraw'
                  title={title}
                  onClick={() => handleWithdrawCandidate(id, status_key)}>
                  {title}
                </Menu.Item>
              )}
            </Menu>
          }
          placement='bottomRight'
          disabled={
            (status_key === 'proposed' && isClient) || status_key === 'engaged'
          }
          trigger='click'>
          <Button
            type='text'
            shape='circle'
            size='small'
            icon={<RiMore2Fill className={'text-xl'} />}
          />
        </Dropdown>
      );
    },
  };

  const base = [
    {
      title: 'Name / Location',
      dataIndex: 'name',
      key: 'name',
      render: (_, item) => {
        return (
          <React.Fragment>
            <Typography.Title level={5} className={'text-sm mb-0'}>
              <Typography.Link onClick={() => handleSinglePageView(item.id)}>
                {item.name}
              </Typography.Link>
            </Typography.Title>
            <Typography.Text>{item.location}</Typography.Text>
          </React.Fragment>
        );
      },
    },
    {
      title: 'Interested?',
      dataIndex: 'interested',
      key: 'interested',
      editable: true,
      render: (text, {id, status_key}) => {
        const interested =
          status_key === 'proposed'
            ? ''
            : getYesOrNo(
                status_key !== 'not_interested' && status_key !== 'not_engaged',
              );
        if (
          isEditTable &&
          status_key !== 'not_engaged' &&
          status_key === 'proposed'
        ) {
          return (
            <Form.Item
              name={`${id}-status`}
              className='mb-0'
              rules={[{required: true, message: ''}]}>
              <AppSelect
                onChange={(value) => handleChangeState(id, value)}
                style={{width: 100}}
                placeholder={getSelectPlaceHolder()}
                options={[
                  {value: 'interested', label: 'Yes'},
                  {value: 'not_interested', label: 'No'},
                ]}
              />
            </Form.Item>
          );
        } else {
          return <Typography.Text>{interested}</Typography.Text>;
        }
      },
    },
    {
      title:
        requisition.type === WORK_TYPE_KEYS.direct_hire
          ? 'BASE SALARY'
          : 'Billing rate',
      dataIndex: 'billingRate',
      key: 'billing_rate',
      editable: true,
      render: (rate, row) => {
        const isPermanent = row?.isPermanent;
        const currentBaseSalary = row?.base_salary;
        const currentBaseSalaryMin = row?.base_salary_min;
        const currentBaseSalaryMax = row?.base_salary_max;

        return (
          <Typography.Text>
            {isPermanent
              ? `${formatBillingRateRage(
                  currentBaseSalary,
                  currentBaseSalaryMin,
                  currentBaseSalaryMax,
                  false,
                )} / year`
              : `${rate}${
                  row?.applicant?.billing_rate_period
                    ? `/ ${PERIODS[row?.billing_rate_period] || 'hour'}`
                    : '/ hour'
                }`}
          </Typography.Text>
        );
      },
    },
    {
      title: 'Available on',
      dataIndex: 'available_on',
      key: 'available_on',
      render: (value) => {
        return (
          <Typography.Text style={{width: 120, display: 'inline-block'}}>
            {formatDate(value, DATE_FORMATS.md) || 'N/A'}
          </Typography.Text>
        );
      },
    },
    {
      title: 'Submitted On',
      dataIndex: 'inquired_on',
      key: 'inquired_on',
      render: (value) => (
        <Typography.Text style={{width: 120, display: 'inline-block'}}>
          {formatDate(value, DATE_FORMATS.md) || 'N/A'}
        </Typography.Text>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => (
        <Typography.Text>
          {isEditTable
            ? 'In Review'
            : existTableStatus[status.toLowerCase()] ?? status}
        </Typography.Text>
      ),
    },
  ];

  return isShowActions ? [...base, actions] : base;
};
