import {useReactMutation} from 'app/hooks/useReactQuery';

import {messagesAPI} from '../api/messages';

export const useCreateSubmissionChannel = () => {
  return useReactMutation(async (id) => {
    const {data} = await messagesAPI.createSubmissionChannel(id);

    return {data, message: ''};
  });
};

export const useCreateInquiryChannel = () => {
  return useReactMutation(async (id) => {
    const {data} = await messagesAPI.createInquiryChannel(id);

    return {data, message: ''};
  });
};
