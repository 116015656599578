import AppLoader from '@wieldy/components/AppLoader';
import AppDynamicTabs from '@wieldy/components/AppTabs/AppDynamicTabs';
import AppTag from '@wieldy/components/AppTag';
import AppPageContainer from '@wieldy/components/pageComponents/AppPageContainer';
import AppPageHeader from '@wieldy/components/pageComponents/AppPageHeader';
import {useGetExpenseDetail} from 'app/services/expenseService';
import ActionButtons from 'app/shared/components/ActionButtons/ActionButtons';
import {getFullName} from 'app/utils/helpers/DataFormatHelper';
import clsx from 'clsx';
import React, {useState} from 'react';
import {useParams} from 'react-router-dom';

import {expenseStatuses} from '../MainContent/data';
import MainContent from './MainContent';

const tabsData = [
  {key: 'summary', title: 'Summary'},
  {key: 'individual-expense', title: 'Individual Expense Entries'},
];

const ViewExpense = () => {
  const [activeTab, setActiveTab] = useState(tabsData[0]);
  const {id} = useParams();

  const {data, isLoading} = useGetExpenseDetail(id);
  const {expense, items} = data || {expense: null, items: null};

  if (isLoading) {
    return <AppLoader />;
  }

  return (
    <AppPageContainer
      header={
        <AppPageHeader
          title={expense?.supplier?.name}
          subTitle={[getFullName(expense?.resource), expense?.end_date]}
          extra={
            <ActionButtons
              id={id}
              timeDate={{
                created_at: expense.created_at,
                updated_at: expense.updated_at,
                invoiced_at: expense.invoice && expense.invoice?.created_at,
                approved_at: expense.approved_at,
                submitted_at: expense.submitted_at,
              }}
              hid={expense.hid}
              status={expense?.status}
              resource={expense?.resource}
              date={expense?.end_date}
            />
          }
          category='Expense'
          header='inner'
          backHeader
          count={
            <AppTag
              shape='circle'
              className={clsx(
                'tracking-normal',
                expense?.status === 'approved' && 'ant-tag-approved',
                expense?.status === 'pending-approval' && 'ant-tag-pending',
              )}
              label={expenseStatuses[expense?.status]}
            />
          }
          tabs={
            <AppDynamicTabs
              tabsData={tabsData}
              setActiveTab={setActiveTab}
              activeKey={activeTab?.key}
              scroll
            />
          }
        />
      }>
      <MainContent expense={expense} items={items} />
    </AppPageContainer>
  );
};

export default ViewExpense;
