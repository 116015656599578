import {Button, Space, Typography} from 'antd';
import {useAccountType} from 'app/hooks/useAccountType';
import {useStatus} from 'app/hooks/useStatus';
import {capitalizeFirstLetter} from 'app/utils/helpers/Capitalize';
import propTypes from 'prop-types';
import React, {useMemo} from 'react';

import RejectActionModel from './RejectActionModel';

const withdrawReasons = [
  {
    value: 'Requisition Fully Filled',
  },
  {
    value: 'Requisition Partially Filled',
  },
  {
    value: 'Unable to Fill',
  },
  {
    value: 'No Longer Needed',
  },
];

const declineReasons = [
  {
    value: 'Not Interested',
  },
  {
    value: 'No Available Resources',
  },
];

const ApproveRejectActions = ({
  handleEditMode,
  onReject,
  isReviewMode,
  handleAdd,
  status,
  form,
  isVisibleModal = false,
  handleVisibleModal,
  isShowRespond,
}) => {
  const {isSupplier, isClient} = useAccountType();
  const {isResponded} = useStatus(status);
  const isClosed = ['closed', 'engaged', 'withdrawn', 'declined'].includes(
    status,
  );
  const clientButtonName = isResponded ? 'close' : 'withdraw';
  const type = isClient ? clientButtonName : 'decline';
  const title = capitalizeFirstLetter(type);

  const reasonFor = isClient
    ? isResponded
      ? 'closing'
      : 'withdrawing'
    : 'declining';
  const description = capitalizeFirstLetter(reasonFor);

  const rejectReasons = useMemo(
    () => (isSupplier ? declineReasons : withdrawReasons),
    [isSupplier],
  );

  const handleRejectExpense = (reason) => {
    const status = isClient
      ? isResponded
        ? 'closed'
        : 'withdrawn'
      : 'declined';
    handleVisibleModal(false);
    if (onReject) {
      onReject(status, reason);
    }
  };

  const isDisabled = useMemo(
    () =>
      !form.isFieldsTouched(true) ||
      !!form.getFieldsError().filter(({errors}) => errors.length)?.length,
    [form],
  );

  return (
    <Space
      className='mt-6'
      size={isClient ? 50 : 'large'}
      direction={isClient ? 'vertical' : 'horizontal'}>
      {isSupplier &&
        isShowRespond &&
        (isReviewMode ? (
          <Button htmlType='submit' type='primary' disabled={!!isDisabled}>
            Respond to Client
          </Button>
        ) : (
          <Button htmlType='button' type='primary' onClick={handleEditMode}>
            Review Inquiry
          </Button>
        ))}
      {isClient && !isClosed && (
        <Button type='primary' htmlType='button' ghost onClick={handleAdd}>
          +Add More Candidates
        </Button>
      )}
      {!isClosed && (
        <div className='w-full'>
          <Button
            htmlType='button'
            type='primary'
            ghost={isSupplier}
            onClick={() => handleVisibleModal(true)}>
            {`${title} Inquiry`}
          </Button>
          <RejectActionModel
            title={`${title} Inquiry`}
            description={`Reason for ${description}`}
            visible={isVisibleModal}
            reasons={rejectReasons}
            onOk={handleRejectExpense}
            onCancel={() => handleVisibleModal(false)}>
            <Typography.Paragraph>
              If you would like to {type} this inquiry, please provide a reason
              for {reasonFor} and then click {title} Inquiry button. If
              you&#39;d like to reconsider this action, click Cancel.
            </Typography.Paragraph>
          </RejectActionModel>
        </div>
      )}
    </Space>
  );
};

ApproveRejectActions.propTypes = {
  isReviewMode: propTypes.bool,
  status: propTypes.string,
  onApprove: propTypes.func,
  onReject: propTypes.func,
  handleEditMode: propTypes.func,
  handleAdd: propTypes.func,
  form: propTypes.object,
  isVisibleModal: propTypes.bool,
  handleVisibleModal: propTypes.func,
  isShowRespond: propTypes.bool,
};

export default ApproveRejectActions;
