import AppFormWrapper, {
  AppFormActions,
  AppFormBody,
} from '@wieldy/components/AppFormWrapper';
import AppSelect from '@wieldy/components/dataEntry/AppSelect';
import {getRequiredMessage} from '@wieldy/utils/helpers/MessageHelper';
import {Button, Col, Form, Row, Select, Space, Typography} from 'antd';
import LocationHelper from 'app/utils/LocationHelper';
import {isNull} from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

const SettingsForm = ({loading, onSubmit, onCancel, initialValues}) => {
  const [disabled, setDisabled] = React.useState(true);
  const [profileVisible, setProfileVisible] = React.useState();
  const [inquiryAllowed, setInquiryAllowed] = React.useState();

  const handleOnFinish = React.useCallback(
    (formdata) => {
      const submittedData = {
        browsing_visible: formdata.browsing_visible === 1,
        location_profile_visibility:
          formdata?.location_profile_visibility ?? [],
        receive_inquiry: formdata.receive_inquiry === 1,
        location_inquiries_preference:
          formdata?.location_inquiries_preference ?? [],
      };
      onSubmit(submittedData);
    },
    [onSubmit],
  );

  const formInitialValues = React.useMemo(() => {
    const initialData = {
      location_profile_visibility:
        initialValues?.location_profile_visibility ?? [],
      location_inquiries_preference:
        initialValues?.location_inquiries_preference ?? [],
    };
    if (
      initialValues?.browsing_visible === true ||
      isNull(initialValues?.browsing_visible)
    ) {
      initialData.browsing_visible = 1;
    } else {
      initialData.browsing_visible =
        initialData.location_profile_visibility.length > 0 ? 2 : 3;
    }
    if (
      initialValues?.receive_inquiry === true ||
      isNull(initialValues?.receive_inquiry)
    ) {
      initialData.receive_inquiry = 1;
    } else {
      initialData.receive_inquiry =
        initialData.location_inquiries_preference.length > 0 ? 2 : 3;
    }
    setProfileVisible(initialData.browsing_visible);
    setInquiryAllowed(initialData.receive_inquiry);
    return initialData;
  }, [initialValues]);

  return (
    <AppFormWrapper>
      <Form
        colon={false}
        initialValues={formInitialValues}
        onFinish={handleOnFinish}
        onFieldsChange={() => setDisabled(false)}
        layout={'horizontal'}
        labelCol={{span: 6}}
        wrapperCol={{span: 20}}
        labelAlign={'left'}>
        <AppFormBody>
          <Typography.Title level={4} className={'mb-3'}>
            Visibility
          </Typography.Title>
          <Row gutter={[30, 5]}>
            <Col sm={24}>
              <Form.Item
                label='Company Profile'
                name={'browsing_visible'}
                rules={[
                  {
                    required: true,
                    message: getRequiredMessage('Company profile'),
                  },
                ]}>
                <AppSelect onChange={setProfileVisible}>
                  <Select.Option value={1}>
                    Visible to all clients
                  </Select.Option>
                  <Select.Option value={2}>
                    Visible to some clients
                  </Select.Option>
                  <Select.Option value={3}>Not visible</Select.Option>
                </AppSelect>
              </Form.Item>
            </Col>
            {profileVisible === 2 && (
              <Col sm={24}>
                <Form.Item
                  label='Visible to Clients Based In'
                  name='location_profile_visibility'
                  rules={[
                    {
                      required: true,
                      message: getRequiredMessage('Clients based in'),
                    },
                  ]}>
                  <AppSelect
                    options={LocationHelper.getCountries()}
                    showSearch
                    mode={'multiple'}
                  />
                </Form.Item>
              </Col>
            )}
          </Row>
          <Typography.Title level={4} className={'mb-3 mt-2'}>
            Communications
          </Typography.Title>
          <Row gutter={[30, 5]}>
            <Col sm={24}>
              <Form.Item
                label='Receive Inquiries'
                name='receive_inquiry'
                rules={[
                  {
                    required: true,
                    message: getRequiredMessage('Receive inquiry'),
                  },
                ]}>
                <AppSelect onChange={setInquiryAllowed}>
                  <Select.Option value={1}>
                    Allowed from all clients
                  </Select.Option>
                  <Select.Option value={2}>
                    Allowed from some clients
                  </Select.Option>
                  <Select.Option value={3}>Not allowed</Select.Option>
                </AppSelect>
              </Form.Item>
            </Col>
            {inquiryAllowed === 2 && (
              <Col sm={24}>
                <Form.Item
                  label='Allowed From Clients Based In'
                  name={'location_inquiries_preference'}
                  rules={[
                    {
                      required: true,
                      message: getRequiredMessage('Clients based in'),
                    },
                  ]}>
                  <AppSelect
                    options={LocationHelper.getCountries()}
                    showSearch
                    mode={'multiple'}
                  />
                </Form.Item>
              </Col>
            )}
          </Row>
        </AppFormBody>
        <AppFormActions>
          <Space size={16}>
            <Button
              type='primary'
              htmlType='submit'
              disabled={disabled}
              loading={loading}>
              Save
            </Button>
            <Button type='primary' onClick={onCancel} ghost>
              Cancel
            </Button>
          </Space>
        </AppFormActions>
      </Form>
    </AppFormWrapper>
  );
};

SettingsForm.propTypes = {
  onCancel: PropTypes.func,
  initialValues: PropTypes.object,
  loading: PropTypes.bool,
  onSubmit: PropTypes.func,
};

export default SettingsForm;
