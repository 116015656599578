import {supplierTypes as SUPPLIER_TYPES} from '../../app/constants/supplier-types';

export const getRegionArgs = (supplierType) => {
  if (!supplierType) {
    return {
      isBoth: false,
      isService: false,
      isJob: false,
    };
  }
  return {
    isBoth:
      (supplierType?.includes(SUPPLIER_TYPES.recruitingAgency) ||
        supplierType?.includes(SUPPLIER_TYPES.staffingAgency)) &&
      supplierType?.includes(SUPPLIER_TYPES.talentVendor),
    isService:
      supplierType?.length === 1 &&
      supplierType?.includes(SUPPLIER_TYPES.talentVendor),
    isJob:
      supplierType?.length &&
      !supplierType?.includes(SUPPLIER_TYPES.talentVendor),
  };
};
