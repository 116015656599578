import {createSlice} from '@reduxjs/toolkit';
import {INVOICE_ACTIONS} from 'app/pages/main/financials/MainContent/data';

const initialState = {
  engagement: {
    id: undefined,
    hid: undefined,
    resourceFullName: undefined,
    job_title: undefined,
  },
  endDate: {},
  timecards: [],
  expenses: [],
  isEditMode: false,
  invoiceActions: {
    title: '',
    type: '',
    id: '',
  },
};

export const invoiceSlice = createSlice({
  name: 'invoice',
  initialState,
  reducers: {
    setInvoiceEngagement: (state, action) => {
      state.engagement = action.payload;
    },
    setInvoiceTimecards: (state, action) => {
      state.timecards = action.payload;
    },
    setInvoiceExpenses: (state, action) => {
      state.expenses = action.payload;
    },
    setInvoiceEditMode: (state, action) => {
      state.isEditMode = action.payload;
    },
    setEndDate: (state, action) => {
      state.endDate = action.payload;
    },
    setInvoiceStatus: (state, action) => {
      state.invoiceActions = {
        title: 'Change Invoice Status',
        type: INVOICE_ACTIONS.CHANGE_STATUS,
        id: action.payload?.id,
        status: action.payload?.status,
      };
    },
    setInvoiceSubmitClient: (state, action) => {
      state.invoiceActions = {
        title: 'Submit Invoice to Client',
        type: INVOICE_ACTIONS.SUBMIT_CLIENT,
        id: action.payload,
      };
    },
    resetInvoiceActions: (state) => {
      state.invoiceActions = initialState.invoiceActions;
    },
    resetInvoiceState: () => initialState,
  },
});

export const {
  setInvoiceEngagement,
  setInvoiceTimecards,
  setInvoiceExpenses,
  setInvoiceEditMode,
  resetInvoiceState,
  setInvoiceStatus,
  setInvoiceSubmitClient,
  resetInvoiceActions,
  setEndDate,
} = invoiceSlice.actions;

export default invoiceSlice.reducer;
