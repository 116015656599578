export const ratingFields = [
  {
    name: 'communication',
    label: 'Communication',
  },
  {
    name: 'productivity',
    label: 'Responsiveness',
  },
  {
    name: 'reliability_and_consistency',
    label: 'Reliability and Consistency',
  },
  {
    name: 'proactiveness',
    label: 'Proactiveness',
  },
  {
    name: 'quality_and_output',
    label: 'Quality of Output',
  },
];
