import ResourceModal from 'app/modals/ResourceModal';
import Search from 'app/pages/common/Search/Search';
import {EditResource, Resources, ViewResource} from 'app/pages/main/resources';
import CandidateCard from 'app/shared/cards/CandidateCard';

export const supplier_ResourceRoutes = [
  {
    path: '/resources/',
    element: <Resources />,
  },
  {
    path: '/resources/:category',
    element: <Resources />,
  },
  {
    path: '/resources/:id/view-resource',
    element: <ViewResource />,
  },
  {
    path: '/resources/:id/edit-resource',
    element: <EditResource />,
  },
];

export const client_ResourceRoutes = [
  {
    path: '/resources/',
    element: <Resources />,
  },
  {
    path: '/resources/:category',
    element: <Resources />,
  },
  {
    path: '/resources/search/:q?',
    element: (
      <Search
        entity={'resources'}
        card={(item, loading) => (
          <CandidateCard
            isResource={true}
            candidate={new ResourceModal(item)}
            isLoading={loading}
            showBookmark={false}
          />
        )}
      />
    ),
  },
  {
    path: '/resources/:id/view-resource',
    element: <ViewResource />,
  },
];
