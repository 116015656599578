import {Col, Row} from 'antd';
import React from 'react';

import usePageQuery from '../../../hooks/usePageQuery';
import SearchResultBox from '../../../shared/components/SearchResultBox/SearchResultBox';

const FilterResults = () => {
  const {uiData, removeFilter} = usePageQuery();
  const onClose = (key) => {
    return () => removeFilter(key);
  };
  if (!uiData?.length) {
    return null;
  }
  return (
    <Row gutter={[10, 20]} className={'mb-6'}>
      {uiData.map((item) => (
        <Col key={item.label}>
          <SearchResultBox
            title={item.label}
            items={item.value}
            onClose={onClose(item.key)}
          />
        </Col>
      ))}
    </Row>
  );
};

export default FilterResults;
