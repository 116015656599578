import axios, {axiosHeaderParams} from 'app/config/axios';
import {useMutation} from 'react-query';

const formatData = (user, role) => {
  const obj = {
    id: user?.id,
    first_name: user?.first_name ?? '',
    last_name: user?.last_name ?? '',
    title: user?.title,
    email: user?.email,
    role,
  };
  obj.name = obj.first_name + ' ' + obj.last_name;
  return obj;
};

const getMembers = (endpoint) => {
  return async (queryParams) => {
    const params = {
      limit: 100,
      expand: ['user'],
      ...queryParams,
    };
    const {data} = await axios.get(endpoint, {params});
    return Array.isArray(data?.data)
      ? data?.data.map((item) => formatData(item?.user, item.role))
      : [];
  };
};

const getResources = () => {
  return async (queryParams) => {
    const params = {
      limit: 100,
      ...queryParams,
    };
    const {data} = await axios.get('resources', {params});
    return Array.isArray(data?.data)
      ? data?.data.map((item) => formatData(item))
      : [];
  };
};

const variants = {
  client: () => getMembers('client_members'),
  supplier: () => getMembers('supplier_members'),
  marketplace: () => getMembers('marketplace_members'),
  marketplaceAll: () => getMembers('marketplace_members/all'),
  resource: getResources,
};

export const useCompanyUsers = (variant) => {
  const mutateFunc = variants[variant];
  if (!mutateFunc) {
    throw new Error('Please select correct variant');
  }
  return useMutation(mutateFunc());
};

const getMembersReplace = (id) => {
  return async (queryParams) => {
    const params = {
      limit: 100,
      expand: ['user'],
      ...queryParams,
    };
    const {data} = await axiosHeaderParams.get(`supplier_members/all/${id}`, {
      params,
    });
    return Array.isArray(data?.data)
      ? data?.data.map((item) => formatData(item?.user, item.role))
      : [];
  };
};

export const useSupplierUsers = (id) => {
  axiosHeaderParams.defaults.headers.common['sourcer-supplier'] = id;

  return useMutation(getMembersReplace(id));
};
