import PropTypes from 'prop-types';
import React from 'react';

import Notes from '../../../../../shared/components/Notes/Notes';
import Engagements from '../../../../../shared/components/Tables/Engagements';
import Inquiries from '../../../../../shared/components/Tables/Inquiries';
import Requisitions from '../../../../../shared/components/Tables/Requisitions';
import Submissions from '../../../../../shared/components/Tables/Submissions';
import {getQueryParams, TABS} from '../constants';
import AboutClient from './AboutClient';

const MainContent = ({
  client,
  handleSetTabView,
  tabView,
  notesResponse,
  notesTotal,
  isShowNotes,
}) => {
  const {isInquiries, isSubmissions, isEngagements, isRequisition} = tabView;
  const {id} = client;
  return (
    <React.Fragment>
      <div className='mb-6' id='about'>
        <AboutClient client={client} />
      </div>
      <div className={isRequisition ? 'mb-6' : ''} id='requisitions'>
        <Requisitions
          defaultQueryParams={getQueryParams(TABS.REQUISITIONS, id)}
          handleSetTabView={handleSetTabView}
          isShow={isRequisition}
        />
      </div>
      <div className={isInquiries ? 'mb-6' : ''} id='inquiries'>
        <Inquiries
          defaultQueryParams={getQueryParams(TABS.INQUIRIES, id)}
          handleSetTabView={handleSetTabView}
          isShow={isInquiries}
          isHideBilling={true}
        />
      </div>
      <div className={isSubmissions ? 'mb-6' : ''} id='submissions'>
        <Submissions
          defaultQueryParams={getQueryParams(TABS.SUBMISSIONS, id)}
          handleSetTabView={handleSetTabView}
          isShow={isSubmissions}
          isHideBilling={true}
        />
      </div>

      <div className={isEngagements ? 'mb-6' : ''} id='engagements'>
        <Engagements
          isPagination={true}
          defaultQueryParams={getQueryParams(TABS.ENGAGEMENTS, id)}
          handleSetTabView={handleSetTabView}
          isShow={isEngagements}
        />
      </div>
      {isShowNotes && (
        <div className='mb-6' id='notes'>
          <Notes
            entity='client_notes'
            primaryId={client?.id}
            primaryKey={'client'}
            notesResponse={notesResponse}
            notesTotal={notesTotal}
          />
        </div>
      )}
    </React.Fragment>
  );
};

MainContent.propTypes = {
  client: PropTypes.object,
  handleSetTabView: PropTypes.func,
  tabView: PropTypes.object,
  isShowNotes: PropTypes.bool,
  notesResponse: PropTypes.object,
  notesTotal: PropTypes.number,
};

export default React.memo(MainContent);
