import {Tabs} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

const {TabPane} = Tabs;

const AppTabPane = (props) => {
  return <TabPane {...props} />;
};

AppTabPane.propTypes = {
  tab: PropTypes.node.isRequired,
};

export default AppTabPane;
