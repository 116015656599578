import {Divider} from 'antd';
import {ViewSupplierHeader} from 'app/shared/supplier';
import PropTypes from 'prop-types';
import React from 'react';

import Overview from './Overview';

const SupplierDetail = ({supplier}) => {
  return (
    <React.Fragment>
      <ViewSupplierHeader supplier={supplier} />
      <Divider style={{marginLeft: -24, marginRight: -24, width: 'auto'}} />
      <Overview supplier={supplier} />
      <Divider style={{marginLeft: -24, marginRight: -24, width: 'auto'}} />
    </React.Fragment>
  );
};

SupplierDetail.propTypes = {
  supplier: PropTypes.object,
};

export default SupplierDetail;
