import AppCard from '@wieldy/components/AppCard';
import {Divider} from 'antd';
import {useRole} from 'app/hooks/useRole';
import PropTypes from 'prop-types';
import React from 'react';

import Calculation from './Calculation';
import Invoices from './Invoices';
import Summary from './Summary';

const SpendingSummary = ({placement}) => {
  const {isClientRecruiter} = useRole();
  return (
    <AppCard
      title='Spending Summary'
      headStyle={{borderBottom: 0}}
      bodyStyle={{paddingTop: 8}}>
      <Summary placement={placement} />
      <Divider className='my-2' />
      <Calculation placement={placement} />
      {isClientRecruiter || <Invoices />}
    </AppCard>
  );
};

SpendingSummary.propTypes = {
  placement: PropTypes.object,
};

export default SpendingSummary;
