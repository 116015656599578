import AppCard from '@wieldy/components/AppCard';
import AppGridContainer from '@wieldy/components/AppGridContainer';
import {Col} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

import EntriesByTypeTable from './EntriesByTypeTable';
import EntriesIndividualTable from './EntriesIndividualTable';
import Overview from './Overview';

const MainContent = ({data}) => {
  const {timecard, entries, entriesByType} = data;

  return (
    <AppGridContainer>
      <Col xs={24}>
        <AppCard
          title='Summary'
          headStyle={{borderBottom: 0}}
          bodyStyle={{paddingTop: 8}}>
          <Overview timecard={timecard} />
        </AppCard>
      </Col>
      <Col xs={24} id='timecard-entries-by-type'>
        <AppCard
          title='Timecard Entries By Type'
          headStyle={{borderBottom: 0}}
          bodyStyle={{paddingTop: 8}}>
          <EntriesByTypeTable entriesByType={entriesByType} />
        </AppCard>
      </Col>
      <Col xs={24} id='individual-time-entries'>
        <AppCard
          title='Individual Time Entries'
          headStyle={{borderBottom: 0}}
          bodyStyle={{paddingTop: 8}}>
          <EntriesIndividualTable entries={entries} />
        </AppCard>
      </Col>
    </AppGridContainer>
  );
};

MainContent.propTypes = {
  data: PropTypes.shape({
    timecard: PropTypes.object,
    entries: PropTypes.array,
    entriesByType: PropTypes.array,
  }),
};

export default MainContent;
