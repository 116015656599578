import axios from '../config/axios';

const path = '/inquiries';
export const inquiryAPI = {
  getInquiries: async (params) => await axios.get(path, {params}),
  getInquiry: async (id, params) => await axios.get(`${path}/${id}`, {params}),
  postInquiry: async (params) => await axios.post(path, params),
  updateInquiry: async (id, params) =>
    await axios.patch(`${path}/${id}`, params),
};
