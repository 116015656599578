import AppCard from '@wieldy/components/AppCard';
import AppLoader from '@wieldy/components/AppLoader';
import AppTable from '@wieldy/components/AppTable';
import {getTablePagination} from '@wieldy/utils/helpers/TableHelper';
import {Button} from 'antd';
import {useBrowse} from 'app/hooks/useBrowse';
import {useRole} from 'app/hooks/useRole';
import {useToggle} from 'app/hooks/useToggle';
import NewEngagementTask from 'app/pages/main/engagements/ViewEngagement/MainContent/EngagementTasks/NewEngagementTask';
import {useGetEngagementTaskList} from 'app/services/engagementsService';
import {setEngagementTask} from 'app/store/features/engagement/engaementTablesSlice';
import {formatMinutes} from 'app/utils/helpers/DataFormatHelper';
import {getItemsData} from 'app/utils/helpers/GetItemsData';
import PropTypes from 'prop-types';
import React, {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';

import {useAccountType} from '../../../../../../hooks/useAccountType';
import {getColumns} from './columns';

const EngagementTasks = ({rates, engagementStatus}) => {
  const {id} = useParams();
  const dispatch = useDispatch();
  const {isClient} = useAccountType();
  const {isMarketManager, isAdmin, isResource} = useRole();
  const isShowButton =
    (isMarketManager || isAdmin || (isClient && !isResource)) &&
    engagementStatus !== 'completed';
  const {pageSize, currentPage, onChangePage, onShowSizeChange} = useBrowse({});

  const {data, isLoading, isFetching} = useGetEngagementTaskList({
    engagement: id,
    include: ['total_count', 'minutes_worked'],
    expand: ['created_by', 'updated_by', 'engagement_rate', 'candidates'],
  });
  const [tasks, totalTasks] = getItemsData('tasks', data);

  const [openModal, handleOpenModal] = useToggle(false);

  const bulkActions = useCallback(
    ({
      active,
      billable,
      engagement,
      engagement_rate,
      description,
      id,
      minutes_worked,
    }) => {
      const payload = {
        active,
        billable,
        engagement,
        description,
        engagement_rate: engagement_rate.id,
        id,
        minutes_worked: formatMinutes(minutes_worked),
      };
      return [
        {
          title: 'Edit Task',
          onClick: () => {
            dispatch(setEngagementTask(payload));
            handleOpenModal(true);
          },
        },
      ];
    },
    [dispatch, handleOpenModal],
  );

  if (isLoading) {
    return <AppLoader />;
  }

  return (
    <AppCard
      title='Engagement Tasks'
      headStyle={{borderBottom: 0}}
      extra={
        isShowButton && (
          <Button onClick={handleOpenModal} shape='round' type='primary'>
            Add New Engagement Task
          </Button>
        )
      }
      bodyStyle={{paddingTop: 8}}>
      <AppTable
        bulkActions={isShowButton && bulkActions}
        loading={isFetching}
        className={'column-valign-top'}
        columns={getColumns()}
        dataSource={tasks}
        pagination={getTablePagination(
          pageSize,
          currentPage,
          totalTasks,
          onShowSizeChange,
          onChangePage,
        )}
      />
      <NewEngagementTask
        rates={rates}
        visiable={openModal}
        onCancel={() => handleOpenModal(false)}
      />
    </AppCard>
  );
};

EngagementTasks.propTypes = {
  rates: PropTypes.array,
  engagementStatus: PropTypes.string,
};

export default EngagementTasks;
