import AppGridContainer from '@wieldy/components/AppGridContainer';
import {Col} from 'antd';
import {OverviewCard, RatingCard} from 'app/shared/supplier';
import PropTypes from 'prop-types';
import React from 'react';

const AboutSupplier = ({supplier}) => {
  return (
    <AppGridContainer>
      <Col xs={24} lg={15} xxl={16}>
        <OverviewCard supplier={supplier} />
      </Col>
      <Col xs={24} lg={9} xxl={8}>
        <RatingCard
          ratings={supplier?.rating}
          count={supplier?.ratingCount}
          total={supplier?.ratingTotal}
        />
      </Col>
    </AppGridContainer>
  );
};

AboutSupplier.propTypes = {
  supplier: PropTypes.object,
};

export default AboutSupplier;
