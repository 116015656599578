import AppPageContainer from '@wieldy/components/pageComponents/AppPageContainer';
import AppPageHeader from '@wieldy/components/pageComponents/AppPageHeader';

import {AppStepsWrapper} from '../../../../providers/AppStepProvider';
import MainContent from './MainContent';

const CreateInvoice = () => {
  return (
    <AppPageContainer
      header={<AppPageHeader category='Financials' title='New Invoice' />}>
      <AppStepsWrapper isUpdated={false} isLoading={false} currentStep={1}>
        <MainContent />
      </AppStepsWrapper>
    </AppPageContainer>
  );
};

export default CreateInvoice;
