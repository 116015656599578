import {Input} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

const AppTextArea = (props) => {
  return <Input.TextArea {...props} />;
};

AppTextArea.propTypes = {
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
};

export default AppTextArea;
