import './index.style.less';

import AppScrollbar from '@wieldy/components/AppScrollbar';
import {Avatar, Badge, Button, Empty, List, Popover, Skeleton} from 'antd';
import {
  getNotificationData,
  ICONS_LIST,
} from 'app/pages/common/Notifications/MainContent/data';
import {
  useGetNotification,
  useReadNotification,
} from 'app/services/notificatonService';
import {formatRelativeDate} from 'app/utils/helpers/DataFormatHelper';
import React, {useCallback, useMemo, useState} from 'react';
import {RiNotification3Line} from 'react-icons/ri';
import {useNavigate} from 'react-router-dom';

const Notifications = () => {
  const navigation = useNavigate();
  const [visible, setVisible] = useState(false);
  const {mutate} = useReadNotification();

  const {data, isFetching} = useGetNotification({sort: ['-created_at']});
  const noRead = useMemo(() => data?.filter(({read}) => !read) || [], [data]);

  const handleReadAll = useCallback(() => {
    const ids = noRead.map(({id}) => id) || [];
    if (!ids.length) {
      return;
    }
    mutate(ids);
  }, [mutate, noRead]);

  const handleOpen = useCallback(
    ({entity, entity_id, id, read}) => {
      const {path} = getNotificationData(entity, entity_id);
      if (read) {
        return () => {
          navigation(path);
          setVisible(false);
        };
      }
      return () => {
        mutate([id], {
          onSuccess: () => {
            navigation(path);
            setVisible(false);
          },
        });
      };
    },
    [mutate, navigation],
  );

  const onClickViewAll = useCallback(() => {
    setVisible(false);
    navigation('/all-notifications');
  }, [navigation]);

  const showNotifyPanelHandler = () => {
    setVisible(true);
  };

  const notificationsContent = useMemo(() => {
    if (!data) {
      return null;
    }

    return (
      <React.Fragment>
        <AppScrollbar>
          <List
            locale={{
              emptyText: (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description='No Notifications'
                />
              ),
            }}
            itemLayout='horizontal'>
            {!!data?.length &&
              data.map((item) => {
                const Icon = ICONS_LIST[item.entity] || ICONS_LIST.user;
                return (
                  <List.Item key={item.id} onClick={handleOpen(item)}>
                    <Skeleton avatar title={false} loading={isFetching} active>
                      <List.Item.Meta
                        avatar={
                          <Badge
                            color='blue'
                            offset={[-28, 2]}
                            dot={!item.read}>
                            <Avatar
                              className='avatar'
                              shape='square'
                              size={30}
                              icon={<Icon fontSize={22} />}
                            />
                          </Badge>
                        }
                        title={
                          <div
                            style={{whiteSpace: 'break-spaces', width: '100%'}}>
                            {item.text}
                          </div>
                        }
                        description={
                          <div className='text-sm'>
                            {formatRelativeDate(item.created_at)}
                          </div>
                        }
                      />
                    </Skeleton>
                  </List.Item>
                );
              })}
          </List>
        </AppScrollbar>
        <div className='notification-footer'>
          <Button type='link' onClick={handleReadAll} disabled={!noRead.length}>
            Mark all as read
          </Button>
          <Button type='link' onClick={onClickViewAll}>
            View All
          </Button>
        </div>
      </React.Fragment>
    );
  }, [
    data,
    handleOpen,
    handleReadAll,
    isFetching,
    noRead.length,
    onClickViewAll,
  ]);

  return (
    <React.Fragment>
      <Popover
        content={notificationsContent}
        overlayClassName='notification-popover'
        placement='bottomRight'
        title='Notifications'
        trigger='click'
        visible={visible}
        onVisibleChange={(visible) => setVisible(visible)}>
        <div className='p-2 cursor-pointer' onClick={showNotifyPanelHandler}>
          <Badge dot={noRead.length} status='warning'>
            <RiNotification3Line fontSize={22} />
          </Badge>
        </div>
      </Popover>
    </React.Fragment>
  );
};

export default React.memo(Notifications);
