import React from 'react';
import {useMessageInputContext} from 'stream-chat-react';

const UploadFile = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='20'
      viewBox='0 0 24 24'
      fill='none'
      stroke='currentColor'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'>
      <circle cx='12' cy='12' r='10' />
      <path d='M8 12h8' />
      <path d='M12 8v8' />
    </svg>
  );
};

export const AttachmentUploadButton = () => {
  const {uploadNewFiles, isUploadEnabled, maxFilesLeft} =
    useMessageInputContext();

  function handleChange(e) {
    const files = e.currentTarget.files;

    if (files && files.length > 0) {
      uploadNewFiles(files);
    }
  }

  if (!isUploadEnabled || maxFilesLeft === 0) {
    return null;
  }

  return (
    <label className='label-custom'>
      <input
        type='file'
        multiple
        style={{opacity: 0, position: 'absolute', zIndex: -1}}
        onChange={handleChange}
      />

      <UploadFile />
    </label>
  );
};
