import AppInput from '@wieldy/components/dataEntry/AppInput';
import {
  getInputPlaceHolder,
  getSelectPlaceHolder,
} from '@wieldy/utils/helpers/MessageHelper';
import {Form, Rate} from 'antd';
import SkillTagDropdown from 'app/shared/components/dropdowns/SkillTagDropdown';
import {preventNegativeNumber} from 'app/utils/helpers/preventNegativeNumber';
import PropTypes from 'prop-types';
import React from 'react';

import {validator} from '../SupplementalInfo/validation';

const RowFields = (props) => {
  const {children, last, handleSetOptions, options, excludeOptions} = props;
  if (last) {
    return (
      <tr {...props}>
        <td className='ant-table-cell'>
          <Form.Item style={{maxWidth: 240}} name='tagId'>
            <SkillTagDropdown
              excludeOptions={excludeOptions}
              options={options}
              handleSetOptions={handleSetOptions}
              mode={null}
              placeholder={getSelectPlaceHolder('skill')}
            />
          </Form.Item>
        </td>
        <td className='ant-table-cell'>
          <Form.Item name='expertise_level' style={{textAlign: 'left'}}>
            <Rate />
          </Form.Item>
        </td>
        <td className='ant-table-cell'>
          <Form.Item name='years_experience' rules={[]}>
            <AppInput
              type='number'
              min={0}
              onKeyPress={preventNegativeNumber}
              placeholder={getInputPlaceHolder('years of experience')}
            />
          </Form.Item>
        </td>
        <td className='ant-table-cell'>
          <Form.Item name='last_used' rules={[{validator}]}>
            <AppInput
              min={0}
              type='number'
              onKeyPress={preventNegativeNumber}
              placeholder={getInputPlaceHolder('year last used')}
            />
          </Form.Item>
        </td>
        <td
          className='ant-table-cell ant-table-cell-fix-right ant-table-cell-fix-right-first'
          style={{textAlign: 'center', position: 'sticky', right: 0}}
        />
      </tr>
    );
  }

  return <tr {...props}>{children}</tr>;
};

RowFields.propTypes = {
  children: PropTypes.node,
  last: PropTypes.bool,
  handleSetOptions: PropTypes.func,
  options: PropTypes.array,
  excludeOptions: PropTypes.array,
};

export default RowFields;
