import './AppTable.style.less';

import {Button, Dropdown, Empty, Menu, Table} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import {RiMore2Fill} from 'react-icons/ri';

const AppTable = ({columns, bulkActions, onSort, emptyText, ...restProps}) => {
  //const [sortByColumns, setSortByColumns] = React.useState([]);

  const handleOnChange = React.useCallback(
    (pagination, filter, sort) => {
      if (typeof onSort !== 'function' || !sort) {
        return;
      }
      const fieldName =
        (sort?.order === 'ascend' ? '+' : '-') + sort?.columnKey;
      // const sortFields = sortByColumns.filter( item => item.slice(1) !== sort?.columnKey );
      // sortFields.push(fieldName);
      // setSortByColumns(sortFields)
      onSort([fieldName]);
    },
    [onSort],
  );

  const tableColumns = React.useMemo(() => {
    if (!bulkActions) {
      return columns;
    }
    const actionColumn = {
      title: '',
      dataIndex: 'action',
      key: 'action',
      fixed: 'right',
      align: 'center',
      width: 64,
      render: (_, item) => {
        if (typeof bulkActions !== 'function') {
          return bulkActions;
        }
        const items = bulkActions(item);
        if (!Array.isArray(items)) {
          return items;
        }
        const menuItems = [];
        for (let item of items) {
          if (!item?.title) continue;
          const {title, ...restProps} = item;
          menuItems.push(
            <Menu.Item key={title} {...restProps}>
              {title}
            </Menu.Item>,
          );
        }
        if (menuItems.length === 0) {
          return null;
        }
        return (
          <Dropdown
            overlay={<Menu>{menuItems}</Menu>}
            placement='bottomRight'
            trigger='click'>
            <Button
              type='text'
              shape='circle'
              size='small'
              icon={<RiMore2Fill className={'text-xl'} />}
            />
          </Dropdown>
        );
      },
    };
    return [...columns, actionColumn];
  }, [columns, bulkActions]);

  return (
    <div className='app-table-container'>
      <Table
        className='table-responsive'
        rowKey='id'
        scroll={{x: 'auto'}}
        locale={{
          emptyText,
        }}
        columns={tableColumns}
        onChange={handleOnChange}
        {...restProps}
      />
    </div>
  );
};
AppTable.propTypes = {
  columns: PropTypes.array,
  onSort: PropTypes.func,
  bulkActions: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  emptyText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};
AppTable.defaultProps = {
  emptyText: (
    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description='No Data' />
  ),
};
export default AppTable;
