import axios from '../config/axios';

export const timecardAPI = {
  getTimeCards: async (params) => await axios.get('timecards', {params}),
  postTimeCard: async (payload) => await axios.post('timecards', payload),
  updateTimeCard: async (payload, id_timecard) =>
    await axios.patch(`timecards/${id_timecard}`, payload),
  deleteTimeCard: async (id_timecard) =>
    await axios.delete(`timecards/${id_timecard}`),
  getTimeCardPdf: async ({id, options}) =>
    await axios.get(`timecards/${id}/download`, {
      ...options,
    }),
};
