import React, {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';

const Invitations = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate('/');
  }, [navigate]);
  return <></>;
};

export default Invitations;
