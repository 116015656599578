import AppFormWrapper, {
  AppFormActions,
  AppFormBody,
} from '@wieldy/components/AppFormWrapper';
import AppInput from '@wieldy/components/dataEntry/AppInput';
import AppSelect from '@wieldy/components/dataEntry/AppSelect';
import {
  getInputPlaceHolder,
  getRequiredMessage,
  getSelectPlaceHolder,
} from '@wieldy/utils/helpers/MessageHelper';
import {Button, Form, Modal, notification, Space, Typography} from 'antd';
import {
  useCreateEngagementTask,
  useUpdateEngagementTask,
} from 'app/services/engagementsService';
import {resetEngagementTasksState} from 'app/store/features/engagement/engaementTablesSlice';
import CommonHelper from 'app/utils/CommonHelper';
import PropTypes from 'prop-types';
import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';

const NewEngagementTask = ({visiable, onCancel, rates}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const {id} = useParams();
  const {task, isEditMode} = useSelector(
    ({engagementTables}) => engagementTables,
  );
  const {mutate, isLoading} = (
    isEditMode ? useUpdateEngagementTask : useCreateEngagementTask
  )(isEditMode ? task.id : undefined);

  useEffect(() => {
    if (task && isEditMode) {
      form.setFieldsValue(task);
    }
  }, [form, isEditMode, task]);

  const handleClose = () => {
    dispatch(resetEngagementTasksState());
    form.resetFields();
    if (onCancel) {
      onCancel();
    }
  };

  const handleOnSubmit = (formData) => {
    const payload = {
      engagement: id,
      ...formData,
      billing_rate: formData.billing_rate
        ? parseFloat(formData.billing_rate)
        : undefined,
    };
    mutate(payload, {
      onSuccess: () => {
        handleClose();
      },
      onError: () => {
        notification.error({message: 'Task has not created'});
      },
    });
  };

  return (
    <Modal
      title={`${isEditMode ? 'Edit' : 'Add New'}  Engagement Task`}
      visible={visiable}
      onCancel={handleClose}
      footer={null}
      width={650}>
      <AppFormWrapper>
        <AppFormBody>
          <Form
            layout='vertical'
            form={form}
            onFinish={handleOnSubmit}
            autoComplete='off'>
            <Form.Item
              name='description'
              label='Task Description'
              rules={[
                {
                  required: true,
                  message: getRequiredMessage('Task description'),
                },
              ]}>
              <AppInput
                maxLength={50}
                placeholder={getInputPlaceHolder('Task Description')}
              />
            </Form.Item>

            <Form.Item
              name='engagement_rate'
              label='Engagement Rate Id'
              rules={[
                {
                  required: true,
                  message: getRequiredMessage('Engagement rate id'),
                },
              ]}>
              <AppSelect
                showSearch
                fieldNames={{label: 'hid', value: 'id'}}
                placeholder={getSelectPlaceHolder('Engagement Rate ID')}
                style={{minWidth: '280px', width: 'auto', maxWidth: '100%'}}
                options={rates}
              />
            </Form.Item>

            <Form.Item label='Total Hours'>
              <Typography.Text className='font-semibold  ml-2'>
                {isEditMode ? task?.minutes_worked : ' 0.00 h'}
              </Typography.Text>
            </Form.Item>

            <Form.Item
              name='billable'
              label='Billable Task'
              rules={[
                {
                  required: true,
                  message: getRequiredMessage('Billable task'),
                },
              ]}>
              <AppSelect
                value='Yes'
                placeholder={'Is billable task'}
                style={{minWidth: '280px', width: 'auto', maxWidth: '100%'}}
                options={CommonHelper.yesNoOptions()}
              />
            </Form.Item>

            <Form.Item
              name='active'
              label='Active Task'
              rules={[
                {
                  required: true,
                  message: getRequiredMessage('Active task'),
                },
              ]}>
              <AppSelect
                value='Yes'
                placeholder={'Is active task'}
                style={{minWidth: '280px', width: 'auto', maxWidth: '100%'}}
                options={CommonHelper.yesNoOptions()}
              />
            </Form.Item>

            <AppFormActions>
              <Space size={'middle'} className='mt-2'>
                <Button type='primary' htmlType='submit' loading={isLoading}>
                  {isEditMode ? 'Update' : 'Create'}
                </Button>
                <Button type='link' onClick={handleClose}>
                  Cancel
                </Button>
              </Space>
            </AppFormActions>
          </Form>
        </AppFormBody>
      </AppFormWrapper>
    </Modal>
  );
};

NewEngagementTask.propTypes = {
  onCancel: PropTypes.func,
  setEngagementTasks: PropTypes.func,
  visiable: PropTypes.bool,
  rates: PropTypes.array,
};

export default NewEngagementTask;
