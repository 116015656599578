import AppGridContainer from '@wieldy/components/AppGridContainer';
import {useWindowSize} from '@wieldy/hooks/useWindowSize';
import {Col} from 'antd';
import {
  useGetActiveClientsReport,
  useGetActiveEngagementsReport,
  useGetAvailableResourcesReport,
  useGetTotalEarningsReport,
} from 'app/services/supplierService';
import AreaChartWidget from 'app/shared/charts/AreaChartWidget';
import {
  chartFromDate,
  getOptions,
  mergeActiveClientsChartToData,
  mergeActiveEngagementsToData,
  mergeAvailableResourcesToData,
  mergeMoneyChartToData,
} from 'app/shared/forms/FilterReportDropdown';
import {useCurrentUser} from 'app/store/features/user/userSlice';
import {formatAmount} from 'app/utils/helpers/DataFormatHelper';
import moment from 'moment/moment';
import React, {useMemo, useState} from 'react';
import {CartesianGrid, XAxis, YAxis} from 'recharts';

const MainContentSupplier = () => {
  const {width} = useWindowSize();
  const user = useCurrentUser();
  const options = getOptions(moment.unix(user?.created_at).format('MM/DD/YY'));
  // Available Resources
  const [availableResourcesDate, setAvailableResourcesDate] = useState(
    options[options.findIndex(({key}) => key === 'last_30')].date,
  );
  const {isLoading: availableResourcesLoading, data: availableResources} =
    useGetAvailableResourcesReport({
      from_date: availableResourcesDate,
    });
  const chartAvailableResources = chartFromDate(availableResourcesDate);
  if (availableResources) {
    mergeAvailableResourcesToData(
      chartAvailableResources,
      availableResources.items,
    );
  }
  // Active Clients
  const [activeClientsDate, setActiveClientsDate] = useState(
    options[options.findIndex(({key}) => key === 'last_30')].date,
  );
  const {isLoading: activeClientsLoading, data: activeClients} =
    useGetActiveClientsReport({
      from_date: activeClientsDate,
    });
  const chartActiveClients = chartFromDate(activeClientsDate);
  if (activeClients) {
    mergeActiveClientsChartToData(chartActiveClients, activeClients.items);
  }
  // Active Engagements
  const [activeEngagementsDate, setActiveEngagementsDate] = useState(
    options[options.findIndex(({key}) => key === 'last_30')].date,
  );
  const {isLoading: activeEngagementsLoading, data: activeEngagements} =
    useGetActiveEngagementsReport({
      from_date: activeEngagementsDate,
    });
  const chartActiveEngagements = chartFromDate(activeEngagementsDate);
  if (activeEngagements) {
    mergeActiveEngagementsToData(
      chartActiveEngagements,
      activeEngagements.items.map((item) => ({
        start_date: item.status_active_date,
        end_date: item.status_active_end_date,
      })),
    );
  }
  // Total Earnings
  let totalEarned = 0;
  const [totalEarningsDate, setTotalEarningsDate] = useState(
    options[options.findIndex(({key}) => key === 'last_30')].date,
  );
  const {isLoading: totalEarningsLoading, data: totalEarnings} =
    useGetTotalEarningsReport({
      from_date: totalEarningsDate,
    });

  const chartTotalEarnings = chartFromDate(totalEarningsDate);
  if (totalEarnings) {
    totalEarned = formatAmount(
      totalEarnings.items.reduce(
        (acc, item) => acc + item.supplier_total_billable,
        0,
      ),
    );
    mergeMoneyChartToData(
      chartTotalEarnings,
      totalEarnings.items,
      'supplier_total_billable',
    );
  }

  const graphHeight = useMemo(() => {
    let height = 220;
    if (width >= 1600) {
      height = 228;
    } else if (width >= 992) {
      height = 284;
    }
    return height;
  }, [width]);

  return (
    <AppGridContainer>
      <Col xs={24}>
        <AppGridContainer>
          <Col xs={24} lg={12}>
            <AreaChartWidget
              title='Total Earnings'
              dataSource={chartTotalEarnings}
              description={`${totalEarned} ${options
                .find(({date}) => date === totalEarningsDate)
                .label.toLowerCase()}`}
              loading={totalEarningsLoading}
              height={graphHeight}
              onChange={(dateKey) =>
                setTotalEarningsDate(
                  options.find(({key}) => key === dateKey).date,
                )
              }
              isDollars>
              <CartesianGrid vertical={false} />
              <YAxis
                allowDecimals={false}
                tickLine={false}
                axisLine={false}
                orientation={'left'}
                tickFormatter={(i) => `$${i}`}
              />
              <XAxis dataKey={'dateLabel'} minTickGap={16} tickSize={8} />
            </AreaChartWidget>
          </Col>
          <Col xs={24} lg={12}>
            <AreaChartWidget
              title='Available Resources'
              dataSource={chartAvailableResources}
              loading={availableResourcesLoading}
              height={graphHeight}
              onChange={(dateKey) =>
                setAvailableResourcesDate(
                  options.find(({key}) => key === dateKey).date,
                )
              }>
              <CartesianGrid vertical={false} />
              <YAxis
                allowDecimals={false}
                tickLine={false}
                axisLine={false}
                orientation={'left'}
              />
              <XAxis dataKey={'dateLabel'} minTickGap={16} tickSize={8} />
            </AreaChartWidget>
          </Col>
        </AppGridContainer>
      </Col>
      <Col xs={24}>
        <AppGridContainer>
          <Col xs={24} lg={12}>
            <AreaChartWidget
              title='Active Engagements'
              dataSource={chartActiveEngagements}
              loading={activeEngagementsLoading}
              height={graphHeight}
              onChange={(dateKey) =>
                setActiveEngagementsDate(
                  options.find(({key}) => key === dateKey).date,
                )
              }>
              <CartesianGrid vertical={false} />
              <YAxis
                allowDecimals={false}
                tickLine={false}
                axisLine={false}
                orientation={'left'}
              />
              <XAxis dataKey={'dateLabel'} minTickGap={16} tickSize={8} />
            </AreaChartWidget>
          </Col>
          <Col xs={24} lg={12}>
            <AreaChartWidget
              title='Active Clients'
              dataSource={chartActiveClients}
              loading={activeClientsLoading}
              height={graphHeight}
              onChange={(dateKey) =>
                setActiveClientsDate(
                  options.find(({key}) => key === dateKey).date,
                )
              }>
              <CartesianGrid vertical={false} />
              <YAxis
                allowDecimals={false}
                tickLine={false}
                axisLine={false}
                orientation={'left'}
              />
              <XAxis dataKey={'dateLabel'} minTickGap={16} tickSize={8} />
            </AreaChartWidget>
          </Col>
        </AppGridContainer>
      </Col>
    </AppGridContainer>
  );
};

export default React.memo(MainContentSupplier);
