import {useQueryClient} from 'app/hooks/useReactQuery';

import {engagementAPI} from '../api/engagementApi';
import axios from '../config/axios';
import useReactQuery, {useReactMutation} from '../hooks/useReactQuery';

const useGetEngagements = (params = {}) => {
  return useReactQuery(['engagements', params], async () => {
    const {data} = await engagementAPI.getEngagements(params);
    return {
      engagements: data?.data || [],
      total_count: data?.total_count || 0,
    };
  });
};

const useGetAllEngagements = (params = {}, statuses = []) => {
  return useReactQuery(
    ['engagements', params],
    async () => {
      const allPromise = statuses.map((status) =>
        engagementAPI.getEngagements({...params, ...status}),
      );
      const response = await Promise.all(allPromise);
      return response.map(({data, config}) => ({
        engagements: data?.data || [],
        total_count: data?.total_count || 0,
        status: config.params.status,
      }));
    },
    {
      enabled: !!statuses.length,
    },
  );
};

const useGetEngagementList = (ids = {}, include, options) => {
  const params = {
    ...ids,
    limit: 100,
    statuses: ['active', 'completed'],
    expand: ['resource', 'client', 'requisition', 'candidates'],
    include: [include],
  };
  return useReactQuery(
    ['engagement-list', ids],
    async () => {
      const {data} = await axios.get('/engagements', {params});
      return data?.data ?? [];
    },
    options,
  );
};

const useGetEngagementTaskList = (params) => {
  return useReactQuery(['engagement_tasks', params], async () => {
    const {data} = await axios.get('/engagement_tasks', {
      params,
    });
    return {
      tasks: data?.data,
      total_count: data?.total_count,
    };
  });
};

const useGetEngagementRates = (queryParams = {}) => {
  return useReactQuery(
    ['engagement_rates', queryParams],
    async () => {
      const {data} = await axios.get('/engagement_rates', {
        params: queryParams,
      });
      return {engagement_rates: data?.data, total_count: data?.total_count};
    },
    {
      enabled: !!queryParams.engagement,
    },
  );
};

const useCreateEngagement = () => {
  const client = useQueryClient();
  return useReactMutation(async (postdata) => {
    const {data} = await engagementAPI.postEngagement(postdata);
    await client.invalidateQueries(['engagement-list']);
    return {
      data,
      message: 'Engagement created successfully',
    };
  });
};

const useUpdateEngagement = () => {
  const client = useQueryClient();
  return useReactMutation(async ({id_engagement, ...payload}) => {
    const {data} = await engagementAPI.updateEngagement(id_engagement, payload);
    await client.invalidateQueries(['engagement-list']);
    return {
      data,
      message: 'Engagement updated successfully',
    };
  });
};

const useCreateEngagementTask = () => {
  const client = useQueryClient();
  return useReactMutation(async (postdata) => {
    const {data} = await engagementAPI.postEngagementTask(postdata);
    await client.invalidateQueries(['engagement_tasks']);
    return {
      data,
      message: 'Engagement task created successfully',
    };
  });
};

const useCreateEngagementRate = () => {
  const client = useQueryClient();
  return useReactMutation(async (postdata) => {
    const {data} = await engagementAPI.postEngagementRates(postdata);
    await client.invalidateQueries(['engagement_rates']);
    return {
      data,
      message: 'Engagement rate created successfully',
    };
  });
};

const useUpdateEngagementTask = (id) => {
  const client = useQueryClient();
  return useReactMutation(async (postdata) => {
    const {data} = await engagementAPI.updateEngagementTask(id, postdata);
    await client.invalidateQueries(['engagement_tasks']);
    return {
      data,
      message: 'Engagement task updated successfully',
    };
  });
};

const useUpdateEngagementRate = (id) => {
  const client = useQueryClient();
  return useReactMutation(async (postdata) => {
    const {data} = await engagementAPI.updateEngagementRates(id, postdata);
    await client.invalidateQueries(['engagement_rates']);
    return {
      data,
      message: 'Engagement rate updated successfully',
    };
  });
};

export {
  useCreateEngagement,
  useCreateEngagementRate,
  useCreateEngagementTask,
  useGetAllEngagements,
  useGetEngagementList,
  useGetEngagementRates,
  useGetEngagements,
  useGetEngagementTaskList,
  useUpdateEngagement,
  useUpdateEngagementRate,
  useUpdateEngagementTask,
};
