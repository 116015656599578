import {invoiceAPI} from '../api/invoiceApi';
import useReactQuery from '../hooks/useReactQuery';

export const useGetInvoices = (params = {}, options = {}) =>
  useReactQuery(
    ['invoice', params],
    async () => {
      const {data} = await invoiceAPI.getInvoices(params);
      return {
        invoices: data?.data,
        total_count: data?.total_count,
      };
    },
    options,
  );
