import AppTable from '@wieldy/components/AppTable';
import {Button, Empty, Form, Modal, Typography} from 'antd';
import {DATE_FORMATS} from 'app/config/app';
import {useAccountType} from 'app/hooks/useAccountType';
import {useStatus} from 'app/hooks/useStatus';
import {
  useDeleteCandidate,
  useUpdateCandidate,
} from 'app/services/candidateService';
import {useUpdateInquiry} from 'app/services/inquiryService';
import {FormatTableFormValue} from 'app/utils/helpers/FormatTableFormValue';
import {isEmpty} from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';

import ApproveRejectActions from '../ApproveRejectActions';
import ChangeCandidateActions from '../ApproveRejectActions/ChangeCandidateActions';
import {getSubmissionCandidateLabel, useGetColumns} from './columns';

const CandidatesTable = ({
  candidates = [],
  inquiryStatus,
  isShowNoteButton,
  handleShowEdit,
}) => {
  const navigate = useNavigate();
  const {id} = useParams();

  const isOneCandidate = candidates.length === 1;
  const [initialState, setInitialState] = useState([]);
  const isClosed = ['closed', 'engaged', 'withdrawn', 'declined'].includes(
    inquiryStatus,
  );

  const [candidateId, setCandidateId] = useState(null);

  useEffect(() => {
    setInitialState(
      candidates.map((el) => ({
        ...el,
        editMode: false,
      })),
    );
  }, [candidates]);

  const {mutate: onDeleteCandidate} = useDeleteCandidate('inquiry', id);
  const {mutate} = useUpdateInquiry(id);
  const {mutate: mutateCandidate} = useUpdateCandidate('inquiry', id);
  const {isSupplier, isClient} = useAccountType();
  const {isResponded, isSent} = useStatus(inquiryStatus);
  const isShowButtons = isResponded || isSent;
  const showBulkAction = isSupplier ? isResponded : isShowButtons;
  const isShowRespondBtn = candidates.some(
    (el) => el.status_key === 'proposed',
  );

  const handleAdd = useCallback(() => {
    navigate(`/inquiries/edit-inquiry/${id}/${inquiryStatus}`);
  }, [id, inquiryStatus, navigate]);

  const handleReject = useCallback(
    (status, reason) => {
      const key_reason = `${status}_reason`;
      mutate(
        {
          status,
          [key_reason]: reason,
        },
        {},
      );
    },
    [mutate],
  );

  const handleRespond = (values) => {
    const data = FormatTableFormValue(values);
    const payload = {
      candidates: data.map((el) => {
        if (el.status) {
          return {
            ...el,
            status: 'available',
            available_on: moment(el.available_on).format(DATE_FORMATS['/']),
          };
        } else {
          return {status: 'not_available', id: el.id};
        }
      }),
      status: 'responded',
    };
    mutate(payload, {
      onSuccess: () => {
        setIsEditMode(false);
      },
    });
  };

  const [isOpenInquiry, setIsOpenInquiry] = useState(false);
  const handleCloseInquiryPopup = useCallback((bool) => {
    setIsOpenInquiry(bool);
  }, []);

  const [isChangeStatus, setIsChangeStatus] = useState(false);

  const handleWithdrawCandidate = useCallback(
    (id) => {
      const candiadate = initialState.find((el) => el.id === id);
      const isClosed = initialState
        .filter((el) => el.id !== id)
        .every((el) => el.status_key === 'not_engaged');
      const reasonKey = isSupplier ? 'declined_reason' : 'closed_reason';

      const status = isClosed
        ? isSupplier
          ? 'declined'
          : 'closed'
        : undefined;

      const reason = isClosed
        ? isSupplier
          ? 'No Available Resources'
          : 'No Longer Needed'
        : undefined;

      if (isSupplier) {
        isOneCandidate &&
        candiadate.status_key !== 'available' &&
        candiadate.status_key !== 'not_available'
          ? setIsOpenInquiry(true)
          : Modal.confirm({
              title: getSubmissionCandidateLabel(candiadate.status_key),
              onOk: () => {
                const payload = {
                  candidates: [
                    {
                      id,
                      status:
                        candiadate.status_key === 'available'
                          ? 'not_available'
                          : 'available',
                    },
                  ],
                };
                mutate(payload);
              },
              okText:
                candiadate.status_key === 'available' ||
                candiadate.status_key === 'not_available'
                  ? 'Update'
                  : 'Withdraw',
            });
      }

      if (isClient) {
        isOneCandidate
          ? setIsOpenInquiry(true)
          : Modal.confirm({
              title: 'Withdraw this candidate?',
              onOk: () => {
                const payload = {
                  status,
                  [reasonKey]: reason,
                  candidates: [
                    {
                      id,
                      status: 'not_engaged',
                    },
                  ],
                };
                mutate(payload, {
                  onSuccess: () => {
                    if (!isOneCandidate) onDeleteCandidate(id);
                  },
                });
              },
              okText: 'Withdraw',
            });
      }
    },
    [
      initialState,
      isClient,
      isOneCandidate,
      isSupplier,
      mutate,
      onDeleteCandidate,
    ],
  );

  const handleChangeCandidateStatus = useCallback((id) => {
    setIsChangeStatus(true);
    setCandidateId(id);
  }, []);

  const handleChangeStatus = (value) => {
    const payload = {
      id: candidateId,
      candidate: {
        status: value,
      },
    };

    setIsChangeStatus(false);
    mutateCandidate(payload);
    setCandidateId(null);
  };

  const [isEditMode, setIsEditMode] = useState(false);

  const handleOnEditMode = useCallback((e) => {
    e.preventDefault();
    setIsEditMode(true);
  }, []);

  const handleChangeState = useCallback((state) => {
    setInitialState(state);
  }, []);

  const columns = useGetColumns(
    isEditMode,
    initialState,
    handleChangeState,
    showBulkAction,
    handleWithdrawCandidate,
    handleChangeCandidateStatus,
    isSent,
    isOneCandidate,
  );

  const initialValues = candidates.reduce(
    (result, {available_on, id, billingRate}) => {
      result[`${id}-available_on`] = available_on
        ? moment(available_on)
        : moment();
      result[`${id}-billing_rate`] = billingRate.includes('-')
        ? ''
        : billingRate.replace('$', '').replace(' ', '');
      return result;
    },
    {},
  );
  const refInitValue = useRef(initialValues);
  const [form] = Form.useForm();
  useEffect(() => {
    if (!isEmpty(refInitValue.current)) {
      form.setFieldsValue(refInitValue.current);
    }
  }, [form]);

  return (
    <Form form={form} onFinish={handleRespond} className='relative'>
      <Typography.Title level={5} className={'mb-5'}>
        Candidates
      </Typography.Title>
      <AppTable
        columns={columns}
        dataSource={initialState}
        pagination={false}
        emptyText={<Empty description='No Candidates' />}
      />
      <ChangeCandidateActions
        actionLabel={'Status'}
        isOpenModal={isChangeStatus}
        handleClose={() => setIsChangeStatus(false)}
        handleSuccess={handleChangeStatus}
        reasonFor={'status'}
        actionType={'status'}
      />
      <ApproveRejectActions
        form={form}
        onReject={handleReject}
        status={inquiryStatus}
        handleAdd={handleAdd}
        isReviewMode={isEditMode}
        handleEditMode={handleOnEditMode}
        isVisibleModal={isOpenInquiry}
        handleVisibleModal={handleCloseInquiryPopup}
        isShowRespond={isShowRespondBtn}
      />
      {isShowNoteButton && isClient && !isClosed && (
        <Button
          type='primary'
          ghost
          className='note-button absolute bottom-0 right-0'
          onClick={handleShowEdit}>
          Add Note to Supplier
        </Button>
      )}
    </Form>
  );
};

CandidatesTable.propTypes = {
  candidates: PropTypes.array,
  inquiryStatus: PropTypes.string,
  isShowNoteButton: PropTypes.bool,
  handleShowEdit: PropTypes.func,
};
export default CandidatesTable;
