import {Typography} from 'antd';
import React from 'react';

import {LANGUAGE_LEVELS, LANGUAGES} from '../../../../../../../../constants';

const education = [
  {
    title: 'Degree',
    dataIndex: 'degree',
    key: 'degree',
    render: (degree) => <Typography.Text>{degree}</Typography.Text>,
    width: 320,
  },
  {
    title: 'School',
    dataIndex: 'university',
    key: 'university',
    align: 'left',
    render: (school) => <Typography.Text>{school}</Typography.Text>,
  },
  {
    title: 'Year',
    dataIndex: 'year',
    key: 'year',
    align: 'left',
    render: (year) => <Typography.Text>{year}</Typography.Text>,
    width: 150,
  },
];

const certifications = [
  {
    title: 'Certification name',
    dataIndex: 'certificate',
    key: 'certificate',
    render: (name) => <Typography.Text>{name}</Typography.Text>,
    width: 320,
  },
  {
    title: 'Organization',
    dataIndex: 'company',
    key: 'company',
    align: 'left',
    render: (organization) => <Typography.Text>{organization}</Typography.Text>,
  },
  {
    title: 'Year',
    dataIndex: 'year',
    key: 'year',
    align: 'left',
    render: (year) => <Typography.Text>{year}</Typography.Text>,
    width: 150,
  },
];

const courses = [
  {
    title: 'Name',
    dataIndex: 'course',
    key: 'course',
    render: (name) => <Typography.Text>{name}</Typography.Text>,
  },
  {
    title: 'Year',
    dataIndex: 'year',
    key: 'year',
    align: 'left',
    render: (year) => <Typography.Text>{year}</Typography.Text>,
    width: 150,
  },
];

const languages = [
  {
    title: 'Language',
    dataIndex: 'language',
    key: 'language',
    render: (language) => (
      <Typography.Text>{LANGUAGES[language] || language}</Typography.Text>
    ),
  },
  {
    title: 'Level',
    dataIndex: 'level',
    key: 'level',
    align: 'left',
    render: (level) => (
      <Typography.Text>{LANGUAGE_LEVELS[level] || level}</Typography.Text>
    ),
    width: 230,
  },
];

export const getColumns = (type) => {
  switch (type) {
    case 'educations':
      return education;
    case 'courses':
      return courses;
    case 'certifications':
      return certifications;
    case 'languages':
      return languages;
    default:
      return [];
  }
};
