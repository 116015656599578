import {useAccountType} from 'app/hooks/useAccountType';
import React from 'react';
import {NavLink} from 'react-router-dom';

const Main = () => {
  const {isSupplier, isClient} = useAccountType();
  return (
    <div id='terms-of-service'>
      <article className='post'>
        <div className='post-wrapper'>
          <div className='post-header'>
            <h2 className='post-title'>Terms of Service</h2>
          </div>
          <div className='post-content'>
            <p>
              You consent and agree to the <b>Sourcer Terms of Service</b>,
              which includes the following:
            </p>

            <ul>
              {isClient && (
                <li>
                  <NavLink
                    to='customer-agreement'
                    className={({isActive}) => (isActive ? 'active' : '')}>
                    Customer Agreement
                  </NavLink>
                </li>
              )}
              {isSupplier && (
                <li>
                  <NavLink
                    to='supplier-agreement'
                    className={({isActive}) => (isActive ? 'active' : '')}>
                    Supplier Agreement
                  </NavLink>
                </li>
              )}
              <li>
                <NavLink
                  to='standard-terms'
                  className={({isActive}) => (isActive ? 'active' : '')}>
                  Standard Terms
                </NavLink>
              </li>
              <li>
                <NavLink
                  to='terms-of-use'
                  className={({isActive}) => (isActive ? 'active' : '')}>
                  Terms of Use
                </NavLink>
              </li>
              <li>
                <NavLink
                  to='privacy-policy'
                  className={({isActive}) => (isActive ? 'active' : '')}>
                  Privacy Policy
                </NavLink>
              </li>
              <li>
                <NavLink
                  to='global-data-processing-agreement'
                  className={({isActive}) => (isActive ? 'active' : '')}>
                  Global Data Processing Agreement
                </NavLink>
              </li>
              <li>
                <NavLink
                  to='cookie-policy'
                  className={({isActive}) => (isActive ? 'active' : '')}>
                  Cookie Policy
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </article>
    </div>
  );
};

export default Main;
