import 'react-phone-number-input/style.css';
import './index.style.less';

import React from 'react';
import PhoneNumberInput from 'react-phone-number-input';

const PhoneInput = (props) => {
  return <PhoneNumberInput defaultCountry={'US'} {...props} />;
};

export default PhoneInput;
