import AppSelect from '@wieldy/components/dataEntry/AppSelect';
import {DatePicker, Select, Space, Typography} from 'antd';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';

const options = [
  {key: 'all', label: 'All', type: 'string'},
  {key: '90', label: 'Past 90 days', type: 'days'},
  {key: 'date-range', label: 'Date Range', type: 'date-range'},
];

const {RangePicker} = DatePicker;

const getOptionValueByKey = (key) => {
  return options.find((option) => option.key === key);
};

const TimePeriodFilterControl = ({onChange}) => {
  const [filterOption, setFilterOption] = useState(options[0]);
  const [range, setRange] = useState(null);

  const handleChange = (key) => {
    setFilterOption(getOptionValueByKey(key));
  };

  const handleDateRangeSelection = (value) => {
    setRange(value);
  };

  useEffect(() => {
    if (filterOption.key !== 'date-range') {
      setRange(null);
    }
    onChange(filterOption, range);
  }, [filterOption, setRange, range, onChange]);

  return (
    <Space>
      <Typography.Text className='mr-3 text-sm font-semibold'>
        Filter
      </Typography.Text>
      <AppSelect
        size={'small'}
        className='select-standard'
        defaultValue={filterOption?.key ?? 'all'}
        style={{minWidth: 120}}
        onChange={handleChange}>
        {options.map((filterOption) => (
          <Select.Option
            className={'text-sm'}
            key={filterOption.key}
            value={filterOption.key}>
            {filterOption.label}
          </Select.Option>
        ))}
      </AppSelect>
      {filterOption?.key === 'date-range' && (
        <RangePicker format={'MM/DD/YY'} onChange={handleDateRangeSelection} />
      )}
    </Space>
  );
};

TimePeriodFilterControl.defaultProps = {
  options: [],
  onChange: () => {},
};

TimePeriodFilterControl.propTypes = {
  options: PropTypes.array,
  onChange: PropTypes.func,
};

export default TimePeriodFilterControl;
