import {marketplaceApi} from 'app/api/marketplaceApi';
import axios from 'app/config/axios';
import useReactQuery, {
  useQueryClient,
  useReactMutation,
} from 'app/hooks/useReactQuery';

export const useGetMarketplaceUsers = (params = {}) => {
  return useReactQuery(
    ['marketplace_members', params],
    async () => {
      const {data} = await marketplaceApi.getMarketplaceMembers(params);
      return {
        items: data?.data ?? [],
        total_count: data?.total_count ?? 0,
      };
    },
    {keepPreviousData: true},
  );
};

export const useGetMarketplaceUser = (id, queryParams = {}) => {
  const params = {
    expand: ['user'],
    ...queryParams,
  };
  return useReactQuery(['marketplace_members', id], async () => {
    const {data} = await axios.get('marketplace_members/' + id, {params});
    return data;
  });
};

export const useDeleteMarketplaceUser = (id) => {
  return useReactMutation(async () => {
    const {data} = await axios.delete('marketplace_members/' + id);
    return {
      message: 'User deleted successfully',
      data,
    };
  });
};

export const useUpdateMarketplaceUser = (id, userId) => {
  const client = useQueryClient();
  return useReactMutation(async (payload) => {
    const {title, first_name, last_name, role, status} = payload;
    await axios.patch('profile/' + userId, {title, first_name, last_name});
    const {data} = await axios.patch('marketplace_members/' + id, {
      role,
      status,
    });
    await client.invalidateQueries(['marketplace_members', id]);
    return {
      message: 'User updated successfully',
      data,
    };
  });
};

export const useGetMarketplaceInvitations = (params = {}) => {
  return useReactQuery(
    ['marketplace_invitations', params],
    async () => {
      const {data} = await marketplaceApi.getMarketplaceInvitations(params);
      return {
        items: data?.data ?? [],
        total_count: data?.total_count ?? 0,
      };
    },
    {keepPreviousData: true},
  );
};

export const useMarketplaceAdministrationBulkActions = () => {
  const client = useQueryClient();
  return useReactMutation(async ({action, payload}) => {
    switch (action) {
      case 'deleteMarketplaceInvitation': {
        await axios.delete(`/marketplace_invitations/${payload?.id}`);
        await client.invalidateQueries('marketplace_invitations');
        return {
          message: `Invitation withdrawn successfully`,
        };
      }
      case 'resendInvitation': {
        await axios.patch(`/marketplace_invitations/${payload?.id}`, {
          resent: true,
        });
        await client.invalidateQueries('marketplace_invitations');
        return {
          message: `Invitation resent successfully`,
        };
      }
    }
  });
};

export const useCreateMarketplaceInviteUser = () => {
  return useReactMutation(async (payload) => {
    const {data} = await axios.post('marketplace_invitations', payload);
    return {
      data,
      message: 'Invitation sent successfully',
    };
  });
};
