import AppPageContainer from '@wieldy/components/pageComponents/AppPageContainer';
import AppPageHeader from '@wieldy/components/pageComponents/AppPageHeader';
import {notification} from 'antd';
import {useAccountType} from 'app/hooks/useAccountType';
import {useCreateInviteUser as useClientCreateInviteUser} from 'app/services/clientService';
import {useCreateSupplierInviteUser} from 'app/services/supplierService';
import {InviteUserForm} from 'app/shared/company';
import ClientHelper from 'app/utils/ClientHelper';
import SupplierHelper from 'app/utils/SupplierHelper';
import React, {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';

const InviteNewUser = () => {
  const {isSupplier} = useAccountType();
  const navigate = useNavigate();
  const supplierType = useSelector(
    ({user}) => user.currentAccount.supplier?.type,
  );
  const mutation = (
    isSupplier ? useCreateSupplierInviteUser : useClientCreateInviteUser
  )();

  const handleOnSubmit = (formData) => {
    mutation.mutate(formData, {
      onSuccess: () => {
        navigate(-1);
      },
      onError: (error) => {
        notification.error({message: error?.message});
      },
    });
  };

  const userTypes = useMemo(() => {
    if (isSupplier) {
      return SupplierHelper.getUserTypes().filter((item) => {
        if (
          item.value === 'agency_recruiter' &&
          !SupplierHelper.hasAccessByType(supplierType)
        ) {
          return false;
        }

        return item.value !== 'owner';
      });
    }

    return ClientHelper.getUserTypes().filter((item) => item.value !== 'owner');
  }, [isSupplier, supplierType]);

  return (
    <AppPageContainer
      header={
        <AppPageHeader
          title='Invite New User'
          category='Administration / Users'
          backHeader
        />
      }>
      <InviteUserForm
        onSubmit={handleOnSubmit}
        onCancel={() => navigate(-1)}
        userTypes={userTypes}
        loading={mutation.isLoading}
        role={isSupplier ? 'supplier' : 'client'}
      />
    </AppPageContainer>
  );
};

export default InviteNewUser;
