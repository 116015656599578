export const primaryTabsSupplier = [
  {key: 'requisition', title: 'Requisition'},
  {key: 'client', title: 'Client'},
];

export const primaryTabsClient = [{key: 'requisition', title: 'Requisition'}];

const notes = {key: 'notes', title: 'Notes'};
const inquiries = (isSupplier) => ({
  key: 'inquiry',
  title: isSupplier ? 'Inquiry' : 'Inquiries',
});
const submission = (isSupplier) => ({
  key: 'submission',
  title: isSupplier ? 'Submission' : 'Submissions',
});
const candidates = () => ({key: 'candidates', title: 'Candidates'});

export const getPrimaryTabs = (
  isSupplier = false,
  isNotes = false,
  isSubmissions = false,
  isInquiries = false,
  isCandidates = false,
) => {
  const tabs = isSupplier ? [...primaryTabsSupplier] : [...primaryTabsClient];
  isInquiries && tabs.push(inquiries(isSupplier));
  isSubmissions && tabs.push(submission(isSupplier));
  isCandidates && tabs.push(candidates());
  isNotes && tabs.push(notes);
  return tabs;
};

export const TABS = {
  CANDIDATES: 'candidates',
  INQUIRIES: 'inquiries',
  SUBMISSIONS: 'submissions',
};

export const getQueryParams = (tab, requisition) => {
  switch (tab) {
    case TABS.INQUIRIES:
    case TABS.SUBMISSIONS:
      return {
        requisition,
        expand: ['supplier', 'client', 'requisition'],
        include: ['candidates', 'total_count'],
        sort: ['-created_at'],
      };
    case TABS.CANDIDATES:
      return {
        requisition,
        include: ['total_count'],
        expand: [
          'resource',
          'client',
          'applicant',
          'supplier',
          'submission',
          'inquiry',
          'requisition',
        ],
        sort: ['+available_on'],
      };
  }
};

export const clientQuery = {
  include: [
    'bookmarked',
    'relation_status',
    'engaged_resources',
    'open_requisitions',
    'active_suppliers',
    'inquiries',
    'submissions',
    'engagements',
  ],
};

export const INSURANCE = {
  REQUIRED: 'Required for all suppliers',
  DEFAULT: 'Not required',
};

export const InsuranceSupplierState = {
  no: 'No',
  yes: 'Yes',
};
