import './../styles/content.less';

import React from 'react';
import {useRoutes} from 'react-router-dom';

import {PolicyFilesPath} from '../constants/policyNames';
import Cookie from './Sections/Cookie';
import Global from './Sections/GlobalData';
import Main from './Sections/Main';
import PrivacyCenter from './Sections/PrivacyCenter';
import PrivacyPolicy from './Sections/PrivacyPolicy';
import TermsOfUse from './Sections/TermsOfUse';
import TermsReader from './TermsReader';

const URL =
  process.env.REACT_APP_S3_URL ??
  'https://sourcer-staging-public-files.s3.amazonaws.com';
const path = '/policy_pdfs/';

const CUSTOMER_PATH = URL + path + PolicyFilesPath['customer-agreement'];
const SUPPLIER_PATH = URL + path + PolicyFilesPath['supplier-agreement'];
const STANDARD_PATH = URL + path + PolicyFilesPath['standard-terms'];

const TermsContent = () => {
  return useRoutes([
    {path: '/', element: <Main />},
    {
      path: '/customer-agreement',
      element: <TermsReader path={CUSTOMER_PATH} />,
    },
    {
      path: '/supplier-agreement',
      element: <TermsReader path={SUPPLIER_PATH} />,
    },
    {
      path: '/standard-terms',
      element: <TermsReader path={STANDARD_PATH} />,
    },
    {path: '/terms-of-use', element: <TermsOfUse />},
    {path: '/privacy-policy', element: <PrivacyPolicy />},
    {path: '/global-data-processing-agreement', element: <Global />},
    {path: '/privacy-center', element: <PrivacyCenter />},
    {path: '/cookie-policy', element: <Cookie />},
  ]);
};

export default TermsContent;
