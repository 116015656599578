import AppSelect from '@wieldy/components/dataEntry/AppSelect';
import {debounce} from 'lodash';
import PropTypes from 'prop-types';
import React, {useEffect} from 'react';

import {useGetSkillTags} from './constants';

const SkillTagDropdown = ({
  fetchDelay = 700,
  options = [],
  excludeOptions = [],
  mode = 'multiple',
  handleSetOptions,
  ...restProps
}) => {
  const [skillOptions, setSkillOptions] = React.useState(options);
  const mutation = useGetSkillTags();
  const fetchRef = React.useRef(0);

  useEffect(() => {
    if (handleSetOptions) {
      handleSetOptions(skillOptions);
    }
  }, [handleSetOptions, skillOptions]);

  const handleOnSearch = React.useMemo(() => {
    return debounce((value) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setSkillOptions([]);
      mutation.mutate(value, {
        onSuccess: (items) => {
          if (fetchId !== fetchRef.current) {
            return;
          }

          const filtered = items.filter(
            (item) => !excludeOptions.map(({id}) => id).includes(item.id),
          );
          setSkillOptions(filtered);
        },
      });
    }, fetchDelay);
  }, [excludeOptions, fetchDelay, mutation]);

  return (
    <AppSelect
      showSearch={true}
      options={skillOptions}
      onSearch={handleOnSearch}
      filterOption={false}
      mode={mode}
      fieldNames={{label: 'name', value: 'id'}}
      notFoundContent={mutation.isLoading ? 'Fetching...' : null}
      {...restProps}
    />
  );
};

SkillTagDropdown.propTypes = {
  fetchDelay: PropTypes.number,
  options: PropTypes.array,
  excludeOptions: PropTypes.array,
  mode: PropTypes.oneOf([PropTypes.string, null]),
  handleSetOptions: PropTypes.func,
};
export default SkillTagDropdown;
