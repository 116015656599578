import AppLoader from '@wieldy/components/AppLoader';
import {useEngagement} from 'app/hooks/useEngagement';
import PropTypes from 'prop-types';
import React, {useMemo} from 'react';

import EngagementStepDetail from './EngagementStepDetail';
import EngagementStepForm from './EngagementStepForm';

const EngagementStep = ({
  isShowDetails,
  isShowForm,
  onContinue,
  isTimeCards = false,
}) => {
  const {isLoading, resourceName, options, onHandleChangeEngagement, data} =
    useEngagement();

  const activeEngagementIds =
    data?.filter((el) => !!el.job_expenses_eligible).map((el) => el.id) || [];

  const activeOptions = options.filter((el) =>
    activeEngagementIds.includes(el.value),
  );
  const formOptions = useMemo(
    () => (isTimeCards ? options : activeOptions),
    [activeOptions, isTimeCards, options],
  );

  if (isLoading) {
    return <AppLoader />;
  }
  return (
    <>
      {isShowDetails ? (
        <EngagementStepDetail resourceName={resourceName} />
      ) : (
        isShowForm && (
          <EngagementStepForm
            onContinue={onContinue}
            options={formOptions}
            onChange={onHandleChangeEngagement}
          />
        )
      )}
    </>
  );
};

EngagementStep.propTypes = {
  isShowDetails: PropTypes.bool,
  isShowForm: PropTypes.bool,
  onContinue: PropTypes.func,
  isTimeCards: PropTypes.bool,
};

export default EngagementStep;
