import AppSelect from '@wieldy/components/dataEntry/AppSelect';
import {getSelectPlaceHolder} from '@wieldy/utils/helpers/MessageHelper';
import {Col, Form} from 'antd';
import React from 'react';

import formConfig from './config';

const AdvanceFilterOptions = () => {
  return (
    <React.Fragment>
      <Col xs={24} sm={12} md={8} lg={6}>
        <Form.Item label='Client Status' name='client_status'>
          <AppSelect
            placeholder={getSelectPlaceHolder('status')}
            options={formConfig.client_status.options}
            allowClear={true}
          />
        </Form.Item>
      </Col>
      <Col xs={24} sm={12} md={8} lg={6}>
        <Form.Item label='Client Based In' name='client_country'>
          <AppSelect
            placeholder={getSelectPlaceHolder('countries')}
            options={formConfig.client_country.options}
            allowClear={true}
            mode={'multiple'}
          />
        </Form.Item>
      </Col>
      <Col xs={24} sm={12} md={8} lg={6}>
        <Form.Item label='# of Active Suppliers' name='client_active_suppliers'>
          <AppSelect
            placeholder={getSelectPlaceHolder('range(s)')}
            options={formConfig.client_active_suppliers.options}
            allowClear={true}
            mode={'multiple'}
          />
        </Form.Item>
      </Col>
      <Col xs={24} sm={12} md={8} lg={6}>
        <Form.Item
          label='# of Deployed Resources'
          name='client_engaged_resources'>
          <AppSelect
            placeholder={getSelectPlaceHolder('range(s)')}
            options={formConfig.client_engaged_resources.options}
            allowClear={true}
            mode={'multiple'}
          />
        </Form.Item>
      </Col>
      <Col xs={24} sm={12} md={8} lg={6}>
        <Form.Item
          label='Client Relationship Status'
          name='client_relationship_status'>
          <AppSelect
            placeholder={getSelectPlaceHolder('status')}
            options={formConfig.client_relationship_status.options}
            allowClear={false}
          />
        </Form.Item>
      </Col>
      <Col xs={24} sm={12} md={8} lg={6}>
        <Form.Item label='Client Performance Rating' name='client_rating_total'>
          <AppSelect
            placeholder={getSelectPlaceHolder('rating')}
            options={formConfig.client_rating_total.options}
            allowClear={true}
          />
        </Form.Item>
      </Col>
      <Col xs={24} sm={12} md={8} lg={6}>
        <Form.Item
          label='# of Open Requisitions'
          name='client_open_requisitions'>
          <AppSelect
            placeholder={getSelectPlaceHolder('range(s)')}
            options={formConfig.client_open_requisitions.options}
            allowClear={true}
            mode={'multiple'}
          />
        </Form.Item>
      </Col>
      <Col xs={24} sm={12} md={8} lg={6}>
        <Form.Item label='Company Size' name='client_company_size'>
          <AppSelect
            placeholder={getSelectPlaceHolder('company size(s)')}
            options={formConfig.client_company_size.options}
            allowClear={true}
            mode={'multiple'}
          />
        </Form.Item>
      </Col>
    </React.Fragment>
  );
};

export default AdvanceFilterOptions;
