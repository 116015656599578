import './index.less';

import {Typography} from 'antd';
import {setCurrentGroup} from 'app/store/features/timecards/timecardsSlice';
import {convertHID} from 'app/utils/helpers/ConvertHID';
import {formatMinutes} from 'app/utils/helpers/DataFormatHelper';
import {getCurrentWeek} from 'app/utils/helpers/GetCurrentWeek';
import cn from 'clsx';
import moment from 'moment';
import React from 'react';
import {useDispatch} from 'react-redux';

export const useGetColumns = (end_date, handleOpenModal) => {
  const dispatch = useDispatch();
  const monthByEndDate = moment(end_date).month();

  const handleModal = (date, task) => {
    const key = `${task}/${date}`;
    dispatch(setCurrentGroup(key));
    handleOpenModal(true);
  };

  const daysKeysAndValues = getCurrentWeek(end_date);
  const weekDays = daysKeysAndValues.map(([, d]) => d);
  const nodeArray = daysKeysAndValues.map(([name, date]) => {
    const key = moment(date).format('YYYY-MM-DD');
    const month = moment(date).month();
    const prevMonth = month !== monthByEndDate;
    return {
      title: (
        <Typography.Paragraph
          key={name.toString()}
          className={cn(['mb-0', prevMonth ? 'table-header-last' : ''])}>
          <Typography.Title
            level={5}
            className={'text-xs mb-0'}
            type={'secondary'}>
            {name}
          </Typography.Title>
          <Typography.Text type={'secondary'}>
            <span>{date}</span>
          </Typography.Text>
        </Typography.Paragraph>
      ),
      dataIndex: key,
      key: key,
      onCell: (record) => {
        return {
          onClick: () => {
            if (!record[key]) {
              return;
            }
            handleModal(key, record.engagement_task);
          },
        };
      },
      render: (hours) => {
        const isHovered = hours ? 'with-value' : '';
        return {
          props: {className: isHovered},
          children: <span>{formatMinutes(hours)}</span>,
        };
      },
    };
  });

  return [
    {
      title: 'Task',
      dataIndex: 'engagement_task',
      key: 'engagement_task',
      fixed: 'left',
      width: 200,
      render: (engagement_task, {taskHid, taskName}) => (
        <span className='font-semibold min-w-300'>{`${convertHID(
          taskHid,
        )} - ${taskName}`}</span>
      ),
    },
    ...nodeArray,
    {
      title: <span dangerouslySetInnerHTML={{__html: 'Total <br> Hours'}} />,
      dataIndex: 'total',
      key: 'total',
      render: (_, hours) => {
        const total = Object.entries(hours)
          .filter(([date]) =>
            weekDays.includes(moment(date).format('MMM D, YYYY')),
          )
          .map(([, value]) => value)
          .filter((num) => typeof num === 'number')
          .reduce((acc, current) => acc + current, 0);
        return <span className='font-semibold'>{formatMinutes(total)}</span>;
      },
    },
  ];
};
