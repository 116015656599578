import clientRoutes from './client';
import managerRoutes from './manager';
import resourceRoutes from './resource-routes';
import supplierRoutes from './supplier';

export {default as routeMenus} from './route-menus';

export default {
  supplier: supplierRoutes,
  resource: resourceRoutes,
  client: clientRoutes,
  manager: managerRoutes,
  admin: managerRoutes,
};
