export const ENGAGEMENT_ALL_STATUSES = {
  open: 'Open for New Engagements',
  existing_clients: 'Existing Clients Only',
  existing_suppliers: 'Existing Suppliers Only',
  existing_engagements: 'Existing Engagements Only',
  closed: 'Account Closed',
};

export const SUPPLIER_ENGAGEMENT_STATUSES = {
  open: 'Open for New Engagements',
  existing_clients: 'Existing Clients Only',
  existing_engagements: 'Existing Engagements Only',
  closed: 'Account Closed',
};

export const FILTER_SUPPLIER_ENGAGEMENT_STATUSES = {
  open: 'Open for New Engagements',
  existing_clients: 'Existing Clients Only',
  existing_engagements: 'Existing Engagements Only',
};

export const CLIENT_ENGAGEMENT_STATUSES = {
  open: 'Open for New Engagements',
  existing_suppliers: 'Existing Suppliers Only',
  existing_engagements: 'Existing Engagements Only',
};

export const ENGAGEMENT_RATE_TYPES = {
  contract: 'Contract',
  permanent: 'Permanent',
};

//Disabled:     hold: "Account On Hold",
//Disabled: closed: "Account Closed"
