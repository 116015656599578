import AppCard from '@wieldy/components/AppCard';
import {Typography} from 'antd';
import {COMPANY_SIZE, ENGAGEMENT_ALL_STATUSES, INDUSTRIES} from 'app/constants';
import Editor from 'app/shared/components/Editor/Editor';
import PropTypes from 'prop-types';
import React from 'react';

const CompanyInfoView = ({details}) => {
  return (
    <AppCard style={{maxWidth: 820}}>
      <div className={'mb-5'}>
        <Typography.Title level={4}>Company Name</Typography.Title>
        <Typography.Paragraph>{details.name}</Typography.Paragraph>
      </div>
      <div className={'mb-5'}>
        <Typography.Title level={4}>Industry</Typography.Title>
        <Typography.Paragraph>
          {INDUSTRIES[details.industry]}
        </Typography.Paragraph>
      </div>
      <div className={'mb-5'}>
        <Typography.Title level={4}>Company Size</Typography.Title>
        <Typography.Paragraph>
          {COMPANY_SIZE[details.company_size]}
        </Typography.Paragraph>
      </div>
      <div className={'mb-5'}>
        <Typography.Title level={4}>Engagement Status</Typography.Title>
        <Typography.Paragraph>
          {ENGAGEMENT_ALL_STATUSES[details.engagement_status]}
        </Typography.Paragraph>
      </div>
      <div className={'mb-5'}>
        <Typography.Title level={4}>Website</Typography.Title>
        <Typography.Paragraph>{details.website}</Typography.Paragraph>
      </div>
      <div className={'mb-5'}>
        <Typography.Title level={4}>Linkedin Company Page</Typography.Title>
        <Typography.Paragraph>{details.linkedin}</Typography.Paragraph>
      </div>
      <div className={'mb-5'}>
        <Typography.Title level={4}>About</Typography.Title>
        <Editor readOnly initValue={details.about} />
      </div>
    </AppCard>
  );
};

CompanyInfoView.propTypes = {
  details: PropTypes.object,
};

export default CompanyInfoView;
