import AppTextArea from '@wieldy/components/dataEntry/AppTextArea';
import {Form, Space} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

const NoteForm = ({onSubmit, children, onFieldsChange, initialValues = {}}) => {
  const [form] = Form.useForm();
  return (
    <Form
      autoComplete='off'
      className={'mb-6'}
      initialValues={initialValues}
      onFinish={(formdata) => onSubmit(formdata, form)}
      form={form}
      onFieldsChange={onFieldsChange}>
      <Form.Item
        name='message'
        rules={[
          {
            required: true,
            message: '',
          },
        ]}
        className={'mb-2'}>
        <AppTextArea
          placeholder='Type your note here'
          maxLength={4000}
          showCount
        />
      </Form.Item>
      <Space>{children}</Space>
    </Form>
  );
};

NoteForm.propTypes = {
  onSubmit: PropTypes.func,
  initialValues: PropTypes.object,
  children: PropTypes.node,
  onFieldsChange: PropTypes.func,
};

export default NoteForm;
