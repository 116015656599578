import {Error, User} from 'app/pages/common';
import Demo from 'app/pages/common/Demo';
import Invitations from 'app/pages/common/Invitations';
import Merge from 'app/pages/common/Merge';
import Messages from 'app/pages/common/Messages';

const commonRoutes = [
  {
    path: '/my-account',
    element: <User.MyAccount />,
  },
  {
    path: '/messages',
    element: <Messages />,
  },
  {
    path: '/all-notifications',
    element: <User.Notifications />,
  },
  {
    path: 'demo',
    element: <Demo />,
  },
  {
    path: '/invitations',
    element: <Invitations />,
  },
  {
    path: '/client_invitations',
    element: <Invitations />,
  },
  {
    path: '/supplier_invitations',
    element: <Invitations />,
  },
  {
    path: '/marketplace_invitations',
    element: <Invitations />,
  },
  {
    path: '/merge',
    element: <Merge />,
  },
  {
    path: '*',
    element: <Error.Error404 />,
  },
];

export default commonRoutes;
