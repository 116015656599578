import AppCard from '@wieldy/components/AppCard';
import {Button} from 'antd';
import PropTypes from 'prop-types';
import React, {useCallback, useState} from 'react';
import {useSelector} from 'react-redux';

import TableContainer from './Containers/TableContainer';
import CertificationForm from './Forms/CertificationForm';
import CoursesForm from './Forms/CoursesForm';
import EducationForm from './Forms/EducationForm';
import LanguagesForm from './Forms/LanguagesForm';

const SupplementalInfoForm = ({onContinue}) => {
  const [forms, setForms] = useState({});

  const handleGetForms = useCallback((form, title) => {
    setForms((prev) => ({...prev, [title]: form}));
  }, []);

  const handleContinue = () => {
    Object.values(forms).forEach((form) => {
      const values = form.getFieldsValue();
      if (Object.values(values).every((el) => !!el)) {
        form.submit();
      }
    });
    onContinue();
  };

  const {supplemental} = useSelector(({resource}) => resource);
  const {educations, certifications, courses, languages} = supplemental;

  return (
    <AppCard>
      <TableContainer
        tableType='educations'
        buttonText='Education'
        title='Education'
        payload={educations}
        fields={['degree', 'university']}
        Component={EducationForm}
        getForm={handleGetForms}
      />
      <TableContainer
        tableType='courses'
        buttonText='Course'
        title='Courses'
        payload={courses}
        fields={['course']}
        Component={CoursesForm}
        getForm={handleGetForms}
      />
      <TableContainer
        tableType='certifications'
        buttonText='Certificate'
        title='Certifications'
        payload={certifications}
        fields={['certificate']}
        Component={CertificationForm}
        getForm={handleGetForms}
      />
      <TableContainer
        tableType='languages'
        buttonText='Language'
        title='Languages'
        payload={languages}
        fields={['language', 'level']}
        Component={LanguagesForm}
        getForm={handleGetForms}
      />
      <Button type='primary' htmlType='button' onClick={handleContinue}>
        Continue
      </Button>
    </AppCard>
  );
};

SupplementalInfoForm.propTypes = {
  onContinue: PropTypes.func,
  onSave: PropTypes.func,
};

export default SupplementalInfoForm;
