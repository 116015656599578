import {options, sort} from 'app/config/chat';
import {groupChannelsByFields} from 'app/utils/helpers/ChatHelpers';
import {useSelector} from 'react-redux';

import AppChat from '../AppChat';

const ClientSupplierChat = () => {
  const currentSupplierId = useSelector(
    ({user}) => user.currentAccount.supplier?.id,
  );
  const currentClientId = useSelector(
    ({user}) => user.currentAccount.client?.id,
  );

  const clientSupplierFilters = {};

  if (currentSupplierId) {
    clientSupplierFilters.supplier = currentSupplierId;
  } else {
    clientSupplierFilters.client = currentClientId;
  }

  const channelListConfig = {
    sort: {...sort, marketplace_channel: -1},
    options,
    filters: {
      ...clientSupplierFilters,
    },
  };

  const renderChannels = (loadedChannels, getChannelPreview) => {
    const group = groupChannelsByFields(loadedChannels, ['supplier', 'client']);

    return group.map((channel) => getChannelPreview(channel));
  };

  return (
    <AppChat
      channelListConfig={channelListConfig}
      renderChannels={renderChannels}
    />
  );
};

export default ClientSupplierChat;
