import AppSelect from '@wieldy/components/dataEntry/AppSelect';
import {
  getRequiredMessage,
  getSelectPlaceHolder,
} from '@wieldy/utils/helpers/MessageHelper';
import {Button, Empty, Form, Modal, Space, Typography} from 'antd';
import PropTypes from 'prop-types';
import React, {useEffect, useMemo} from 'react';
import {RiCloseLine} from 'react-icons/ri';

import {supplierTypes} from '../../../../../constants/supplier-types';
import {useGetSuppliers} from '../../../../../services/clientService';
import {useSendDeleteSupplierRequisitionInvitation} from '../../../../../services/requisitionInvitationService';

const supplierTypesRequestFields = {
  [supplierTypes.staffingAgency]: {
    name: 'staffing_agencies',
    placeholder: 'staffing agencies',
    errorMessage: 'Staffing Agencies',
    label: 'Staffing Agencies',
  },
  [supplierTypes.recruitingAgency]: {
    name: 'recruitment_agencies',
    placeholder: 'recruitment agencies',
    errorMessage: 'Recruitment Agencies',
    label: 'Recruitment Agencies',
  },
};

const ManageSupplierModal = ({
  isOpen,
  onClose,
  requisitionId,
  supplierType,
}) => {
  const sendDeleteSupplierRequisitionInvitation =
    useSendDeleteSupplierRequisitionInvitation();

  const suppliersParams = useMemo(
    () => ({
      types: [supplierType],
      invitations_requisition: requisitionId,
      include: ['requisition_invitation'],
    }),
    [requisitionId, supplierType],
  );

  const getSuppliers = useGetSuppliers(suppliersParams);

  const invitedSuppliers = useMemo(() => {
    if (!getSuppliers.data) return [];

    return getSuppliers.data.suppliers
      .filter((supplier) => !!supplier.supplier_requisition_invitation)
      .map((sup) => ({
        label: sup.name,
        value: sup.id,
        key: sup.id,
        supplier: sup,
      }));
  }, [getSuppliers.data]);

  const [form] = Form.useForm();

  const suppliers = useMemo(() => {
    if (!getSuppliers.data) return [];

    return getSuppliers.data.suppliers.map((sup) => ({
      label: sup.name,
      value: sup.id,
      key: sup.id,
      supplier: sup,
    }));
  }, [getSuppliers]);

  const currentSupplierType = supplierTypesRequestFields[supplierType];

  const prepareInvitationsData = (selectedSuppliers) => {
    const selectedSuppliersIds = selectedSuppliers.map((item) =>
      typeof item === 'object' ? item.value : item,
    );

    const suppliersToDelete = invitedSuppliers
      .filter(
        (item) => !selectedSuppliersIds.some((supId) => item.value === supId),
      )
      .map((item) => ({
        id: item.supplier.supplier_requisition_invitation.id,
        deleted: true,
      }));

    const suppliersToSend = selectedSuppliersIds
      .filter((supId) => !invitedSuppliers.some((item) => item.value === supId))
      .map((supId) => ({supplier: supId, requisition: requisitionId}));

    return [...suppliersToDelete, ...suppliersToSend];
  };

  const handleSubmit = async () => {
    try {
      const fields = await form.validateFields();

      const invitations = prepareInvitationsData(
        fields[currentSupplierType.name],
      );

      sendDeleteSupplierRequisitionInvitation.mutate(
        {invitations},
        {
          onSuccess: () => {
            onClose();
          },
        },
      );
    } catch (e) {
      console.error('Validation error');
    }
  };

  useEffect(() => {
    if (invitedSuppliers && isOpen) {
      form.setFieldValue(currentSupplierType.name, invitedSuppliers);
    }
  }, [currentSupplierType.name, form, invitedSuppliers, isOpen]);

  useEffect(() => {
    if (!isOpen) {
      form.resetFields();
    }
  }, [form, isOpen]);

  return (
    <Modal
      title='Manage Suppliers'
      open={isOpen}
      onCancel={onClose}
      closeIcon={<RiCloseLine fontSize={24} />}
      width={720}
      destroyOnClose={true}
      footer={[
        <Button
          type='primary'
          key='submit'
          loading={sendDeleteSupplierRequisitionInvitation.isLoading}
          onClick={handleSubmit}>
          Submit
        </Button>,
        <Button type='primary' ghost key='cancel' onClick={onClose}>
          Cancel
        </Button>,
      ]}>
      <div className='modal-reason-dropdown'>
        <Space direction={'vertical'}>
          <Typography.Text type={'secondary'}>
            {
              "Add or remove suppliers whom you would like to work on this requisition and then click Submit button. If you'd like to reconsider this action, click Cancel."
            }
          </Typography.Text>
          <Form
            layout='vertical'
            className='mt-2'
            form={form}
            onFinish={handleSubmit}>
            <Form.Item
              label={currentSupplierType.label}
              name={currentSupplierType.name}
              rules={[
                {
                  required: false,
                  message: getRequiredMessage(currentSupplierType.errorMessage),
                },
              ]}>
              <AppSelect
                notFoundContent={
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description='No name(s)'
                  />
                }
                mode='multiple'
                placeholder={getSelectPlaceHolder(
                  currentSupplierType.placeholder,
                )}
                size={'middle'}
                loading={getSuppliers.isLoading}
                style={{width: '260px'}}
                options={suppliers}
                optionFilterProp='label'
              />
            </Form.Item>
          </Form>
        </Space>
      </div>
    </Modal>
  );
};

ManageSupplierModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  requisitionId: PropTypes.string,
  supplierType: PropTypes.string,
};

export default ManageSupplierModal;
