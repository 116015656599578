import '../styles/sidebar.less';

import {useAccountType} from 'app/hooks/useAccountType';
import React from 'react';
import {NavLink} from 'react-router-dom';

const TermsSidebar = () => {
  const {isSupplier, isClient} = useAccountType();
  return (
    <div>
      <div className='sidebar'>
        <aside>
          <ul>
            <li>
              <NavLink
                to='/'
                className={({isActive}) => (isActive ? 'active' : '')}>
                Terms of Service
              </NavLink>
            </li>
            {isClient && (
              <li>
                <NavLink
                  to='customer-agreement'
                  className={({isActive}) => (isActive ? 'active' : '')}>
                  Customer Agreement
                </NavLink>
              </li>
            )}
            {isSupplier && (
              <li>
                <NavLink
                  to='supplier-agreement'
                  className={({isActive}) => (isActive ? 'active' : '')}>
                  Supplier Agreement
                </NavLink>
              </li>
            )}
            <li>
              <NavLink
                to='standard-terms'
                className={({isActive}) => (isActive ? 'active' : '')}>
                Standard Terms
              </NavLink>
            </li>
            <li>
              <NavLink
                to='terms-of-use'
                className={({isActive}) => (isActive ? 'active' : '')}>
                Terms of Use
              </NavLink>
            </li>
            <li>
              <NavLink
                to='privacy-policy'
                className={({isActive}) => (isActive ? 'active' : '')}>
                Privacy Policy
              </NavLink>
            </li>
            <li>
              <NavLink
                to='global-data-processing-agreement'
                className={({isActive}) => (isActive ? 'active' : '')}>
                Global Data Processing Agreement
              </NavLink>
            </li>
            <li>
              <NavLink
                to='privacy-center'
                className={({isActive}) => (isActive ? 'active' : '')}>
                Privacy Center
              </NavLink>
            </li>
            <li>
              <NavLink
                to='cookie-policy'
                className={({isActive}) => (isActive ? 'active' : '')}>
                Cookie Policy
              </NavLink>
            </li>
          </ul>
        </aside>
      </div>
    </div>
  );
};

export default TermsSidebar;
