import {Typography} from 'antd';
import {getUserName} from 'app/utils/helpers/ConvertHID';

export const getColumns = (navigate) => {
  return [
    {
      title: 'Client Name',
      dataIndex: 'name',
      key: 'name',
      showSorterTooltip: false,
      render: (name, item) => (
        <Typography.Link
          className='font-semibold'
          onClick={() => navigate(`/clients/${item?.id}/view-client`)}>
          {getUserName(name, 'CLI-', 'Client')}
        </Typography.Link>
      ),
      sorter: true,
    },
    {
      title: 'Active Engagements',
      dataIndex: 'activeEngagements',
      key: 'supplier_common_active_engagements',
      sorter: true,
      showSorterTooltip: false,
    },
    {
      title: 'Total Engagements',
      dataIndex: 'totalEngagements',
      key: 'supplier_common_total_engagements',
      sorter: true,
      showSorterTooltip: false,
    },
    {
      title: 'Relationship Status',
      dataIndex: 'relationshipStatus',
      key: 'relation_status',
      sorter: true,
      showSorterTooltip: false,
    },
    {
      title: 'Relationship Duration',
      dataIndex: 'relationshipDuration',
      key: 'relationshipDuration',
    },
    {
      title: 'Client Rating',
      dataIndex: 'clientReview',
      key: 'client_review.review_average',
      showSorterTooltip: false,
      render: (rating, item) => {
        if (item?.clientReview?.review_average > 0) {
          return (
            <Typography.Link
              onClick={() =>
                navigate('/administration/client/' + item?.id + '/review')
              }
              className='font-semibold'>
              {item?.clientReview?.review_average}
            </Typography.Link>
          );
        }
      },
      sorter: true,
    },
  ];
};
