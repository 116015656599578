import AppPageContainer from '@wieldy/components/pageComponents/AppPageContainer';
import AppPageHeader from '@wieldy/components/pageComponents/AppPageHeader';
import {DATE_FORMATS} from 'app/config/app';
import {useAccountType} from 'app/hooks/useAccountType';
import {AppStepsWrapper} from 'app/providers/AppStepProvider';
import {
  useCreatePlacement,
  useUpdatePlacement,
} from 'app/services/placementService';
import moment from 'moment';
import PropTypes from 'prop-types';
import {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';

import {resetPlacementState} from '../../../../store/features/placement/placementSlice';
import MainContent from './MainContent';
import Preview from './Preview';

const CreatePlacement = ({isEdit = false}) => {
  const [isPreview, setIsPreview] = useState(false);
  const placement = useSelector(({placement}) => placement);
  const {isSupplier, isMarketplace, isClient} = useAccountType();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {mutate, isLoading} = (
    isEdit ? useUpdatePlacement : useCreatePlacement
  )();

  useEffect(() => {
    if (isMarketplace && !isClient && !isSupplier) {
      navigate('/');
    }
  }, [isClient, isMarketplace, isSupplier, navigate]);

  useEffect(() => {
    return () => {
      dispatch(resetPlacementState());
    };
  }, [dispatch]);

  const handleOnSubmit = () => {
    const {candidate, details, jobDetails, id_placement} = placement;

    const payload = {
      inquiry: candidate.inquiry,
      submission: candidate.submission,
      candidate: candidate.id,
      ...details,
      ...jobDetails,
      start_date: moment(details.start_date).format(DATE_FORMATS['/']),
      original_end_date: moment(details.end_date).format(DATE_FORMATS['/']),

      hiring_manager: details.hiring_manager?.id,
      account_manager: details.account_manager?.id,
      resource_manager: details.resource_manager?.id,
    };

    const payloadEdit = {
      id_placement,
      ...details,
      ...jobDetails,
      start_date: moment(details.start_date).format(DATE_FORMATS['/']),
      hiring_manager: details.hiring_manager?.id || null,
      account_manager: details.account_manager?.id,
      resource_manager: details.resource_manager?.id,
    };

    const data = isEdit ? payloadEdit : payload;

    mutate(data, {
      onSuccess: () => {
        dispatch(resetPlacementState());
        navigate('/placements/all');
      },
    });
  };

  const handlePreview = useCallback((bool) => setIsPreview(bool), []);

  return (
    <AppStepsWrapper currentStep={isEdit ? 3 : 1} isLoading={isLoading}>
      {isPreview ? (
        <Preview
          onCancel={() => setIsPreview(false)}
          onSubmit={handleOnSubmit}
          placement={placement}
        />
      ) : (
        <AppPageContainer
          header={
            <AppPageHeader
              category='Placement'
              title={`${isEdit ? 'Edit' : 'New'} Placement`}
            />
          }>
          <MainContent
            isEdit={isEdit}
            onPreview={handlePreview}
            onFinish={handleOnSubmit}
          />
        </AppPageContainer>
      )}
    </AppStepsWrapper>
  );
};
CreatePlacement.propTypes = {
  isEdit: PropTypes.bool,
};

export default CreatePlacement;
