import PropTypes from 'prop-types';
import React from 'react';

const AppTabTitle = ({tab, icon, count}) => {
  return icon || count ? (
    <span className='tab-title'>
      {icon && <span className='text-xl mr-2'>{icon}</span>}
      <span>{tab}</span>
      {count && <span>{count}</span>}
    </span>
  ) : (
    tab
  );
};

AppTabTitle.propTypes = {
  tab: PropTypes.node.isRequired,
  icon: PropTypes.node,
  count: PropTypes.number,
};

export default AppTabTitle;
