import AppGridContainer from '@wieldy/components/AppGridContainer';
import {Col} from 'antd';
import {OverviewCard, RatingCard} from 'app/shared/client';
import PropTypes from 'prop-types';
import React from 'react';

const AboutClient = ({client}) => {
  return (
    <AppGridContainer>
      <Col xs={24} lg={15} xxl={16}>
        <OverviewCard client={client} />
      </Col>
      <Col xs={24} lg={9} xxl={8} id='client_rating'>
        <RatingCard
          ratings={client?.rating}
          count={client?.ratingCount}
          total={client?.ratingTotal}
        />
      </Col>
    </AppGridContainer>
  );
};

AboutClient.propTypes = {
  client: PropTypes.object,
};

export default AboutClient;
