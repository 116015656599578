import AppAmountView from '@wieldy/components/helperComponents/AppAmountView';
import {Typography} from 'antd';
import {useStatus} from 'app/hooks/useStatus';
import {
  formatDate,
  formatSecondsToDate,
  getFullName,
} from 'app/utils/helpers/DataFormatHelper';
import React from 'react';
import {useNavigate} from 'react-router-dom';

import {DATE_FORMATS} from '../../../../../config/app';

const getSimilarColumn = (title, dataIndex, key, by, at) => ({
  title,
  dataIndex,
  key,
  render: (_, obj) => {
    const data_by = obj[by];
    const data_at = obj[at];
    return (
      <Typography.Paragraph>
        <Typography.Paragraph className={'mb-0'}>
          {getFullName(data_by)}
        </Typography.Paragraph>
        <Typography.Text type={'secondary'} className={'text-xs'}>
          {data_at ? formatSecondsToDate(data_at, DATE_FORMATS.md) : ''}
        </Typography.Text>
      </Typography.Paragraph>
    );
  },
});

export const useGetColumns = (status) => {
  const navigate = useNavigate();
  const {isApproved, isRejected, isPending} = useStatus(status);

  const handleIDClick = (id) => {
    navigate(`/expenses/${id}/view-expense`);
  };

  const handleEngagementView = (id) => {
    navigate(`/engagements/${id}/view-engagement`);
  };

  const handleResourceView = (id) => {
    navigate(`/resources/${id}/view-resource`);
  };

  const defaultArray = [
    {
      title: 'Expense id',
      dataIndex: 'id',
      key: 'id',
      render: (id, {hid}) => (
        <Typography.Link onClick={() => handleIDClick(id)}>
          {hid}
        </Typography.Link>
      ),
    },
    {
      title: 'Engagement id',
      dataIndex: 'engagement',
      key: 'engagement',
      render: (_, {engagement}) => (
        <Typography.Link onClick={() => handleEngagementView(engagement.id)}>
          {engagement.hid}
        </Typography.Link>
      ),
    },
    {
      title: 'Resource name',
      dataIndex: 'resource',
      key: 'resource.first_name',
      render: (_, {resource}) => (
        <Typography.Link onClick={() => handleResourceView(resource.id)}>
          {getFullName(resource)}
        </Typography.Link>
      ),
      sorter: true,
      showSorterTooltip: false,
    },
    {
      title: 'Week end date',
      dataIndex: 'end_date',
      key: 'end_date',
      sorter: true,
      render: (date) => formatDate(date, DATE_FORMATS.md),
      defaultSortOrder: 'descend',
      showSorterTooltip: false,
    },
    {
      title: 'Amount',
      dataIndex: 'total_amount',
      key: 'total_amount',
      render: (amount) => <AppAmountView amount={amount} />,
    },
  ];

  const submitted = [
    getSimilarColumn(
      'Submitted',
      'submitted',
      'submitted',
      'submitted_by',
      'submitted_at',
    ),
  ];

  const approved = [
    getSimilarColumn(
      'Approved',
      'approved',
      'approved',
      'approved_by',
      'approved_at',
    ),
    {
      title: 'Invoiced',
      dataIndex: 'invoice',
      key: 'invoiced',
      render: (_, {invoice, invoice_author}) => (
        <Typography.Paragraph>
          <Typography.Paragraph className={'mb-0'}>
            {getFullName(invoice_author)}
          </Typography.Paragraph>
          <Typography.Text type={'secondary'} className={'text-xs'}>
            {invoice
              ? formatSecondsToDate(invoice.created_at, 'MMM D, YYYY')
              : ''}
          </Typography.Text>
        </Typography.Paragraph>
      ),
    },
  ];

  const rejected = [
    getSimilarColumn(
      'Rejected',
      'rejected',
      'rejected',
      'rejected_by',
      'rejected_at',
    ),
  ];

  return isApproved
    ? defaultArray.concat(submitted, approved)
    : isRejected
    ? defaultArray.concat(submitted, rejected)
    : isPending
    ? defaultArray.concat(submitted)
    : defaultArray;
};
