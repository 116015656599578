import {BILLING_RATE_PERIODS, JOB_STATUSES, VISA_STATUSES} from '../constants';
import LocationHelper from './LocationHelper';

class CommonHelper {
  /** Countries - India, US etc */
  static countries() {
    return LocationHelper.getCountries();
  }

  /** Job status - fulltime, parttime */
  static jobStatus() {
    return Object.keys(JOB_STATUSES).map((key) => ({
      key: key,
      value: key,
      label: JOB_STATUSES[key],
    }));
  }

  /** Billing rate periods - Hourly, Daily and Monthly */
  static billingRatePeriods() {
    return Object.keys(BILLING_RATE_PERIODS).map((key) => ({
      key: key,
      value: key,
      label: BILLING_RATE_PERIODS[key],
    }));
  }

  /** Visa status - US Citizen, Green card etc */
  static visaStatus() {
    return Object.keys(VISA_STATUSES).map((key) => ({
      key: key,
      value: key,
      label: VISA_STATUSES[key],
    }));
  }

  /** Use both for create/filter options */
  static yesNoOptions(options = {yes: 'Yes', no: 'No'}) {
    return ['yes', 'no'].map((item) => {
      return {
        key: item,
        value: item === 'yes',
        label: options[item] ?? item,
      };
    });
  }
}

export default CommonHelper;
