import AppTable from '@wieldy/components/AppTable';
import PropTypes from 'prop-types';
import React from 'react';

import {getColumns} from './columns';

const SupplementalTable = ({data, bulkActions, tableType, ...rest}) => {
  return (
    <AppTable
      locale={{
        emptyText: <div />,
      }}
      bulkActions={bulkActions}
      className={'column-valign-top'}
      columns={getColumns(tableType)}
      dataSource={data}
      pagination={false}
      scroll={{x: 'scroll'}}
      {...rest}
    />
  );
};
SupplementalTable.propTypes = {
  data: PropTypes.array,
  bulkActions: PropTypes.func,
  tableType: PropTypes.oneOf([
    'educations',
    'courses',
    'certifications',
    'languages',
  ]),
};

SupplementalTable.defaultProps = {
  data: [],
};

export default SupplementalTable;
