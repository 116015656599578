import {CATEGORIES} from 'app/constants';

export const statuses = [
  CATEGORIES.AVAILABLE,
  CATEGORIES.AVAILABLE_SOON,
  CATEGORIES.UNAVAILABLE,
];

export const queryParams = {
  include: [
    'skill_tags',
    'engagements',
    'completed_engagements',
    'supplier_active_clients',
    'supplier_completed_engagements',
    'supplier_available_resources',
    'supplier_relationship_status',
    'supplier_inquiries',
    'supplier_submissions',
    'supplier_engagements',
    'total_count',
    'bookmarked',
    'bookmarked_on',
  ],
  expand: ['supplier'],
};
export const applicantsTabs = [
  {key: CATEGORIES.ALL, title: 'All', route: '/applicants/all'},
  {
    key: CATEGORIES.AVAILABLE,
    title: 'Available',
    route: '/applicants/available',
  },
  {
    key: CATEGORIES.AVAILABLE_SOON,
    title: 'Available Soon',
    route: '/applicants/available_soon',
  },
  {
    key: CATEGORIES.UNAVAILABLE,
    title: 'Unavailable',
    route: '/applicants/unavailable',
  },
];

export const pageQueriesApplicants = {
  all: {
    queryParams,
    title: 'All Applicants',
  },
  available: {
    queryParams: {
      ...queryParams,
      relation_status: 'available',
    },
    title: 'Available Applicants',
    label: 'Available',
    rowTitle: 'Available Applicants',
    empty: 'No Available Applicants',
  },
  available_soon: {
    queryParams: {
      ...queryParams,
      relation_status: 'available_soon',
    },
    title: 'Available Soon Applicants',
    label: 'Available',
    rowTitle: 'Available Soon Applicants',
    empty: 'No Available Soon Applicants',
  },
  unavailable: {
    queryParams: {
      ...queryParams,
      relation_status: 'unavailable',
    },
    title: 'Unavailable Applicants',
    label: 'Available',
    rowTitle: 'Unavailable Applicants',
    empty: 'No Unavailable Applicants',
  },
};
