import {UserOutlined} from '@ant-design/icons';
import {Avatar, Typography} from 'antd';
import {getFullName} from 'app/utils/helpers/DataFormatHelper';
import PropTypes from 'prop-types';
import React from 'react';
import {useSelector} from 'react-redux';

const AppUserInfo = ({user}) => {
  const {companyModal} = useSelector(({user}) => user);
  return (
    <div className='user-root'>
      <Avatar
        size={48}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        icon={<UserOutlined fontSize={30} />}
        src={user?.avatar_url}
        alt='avatar'
      />
      <div className='user-detail'>
        <Typography.Title level={4} className='mb-0 font-medium'>
          {getFullName(user)}
        </Typography.Title>
        <Typography.Paragraph className='text-secondary text-sm mb-0'>
          {companyModal?.name}
        </Typography.Paragraph>
      </div>
    </div>
  );
};

AppUserInfo.propTypes = {
  user: PropTypes.object,
};

export default React.memo(AppUserInfo);
