import PropTypes from 'prop-types';
import React from 'react';

import PageContentProvider from './PageContentProvider';

const Page = ({component, ...restProps}) => {
  const Component = component;
  return (
    <PageContentProvider {...restProps}>
      <Component />
    </PageContentProvider>
  );
};

Page.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
};

export default Page;
