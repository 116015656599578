import {Typography} from 'antd';
import {PERIODS} from 'app/constants';
import {getAddress} from 'app/utils/helpers/DataFormatHelper';
import {getName} from 'app/utils/helpers/GetName';

export const getColumns = () => {
  // const onDateSelect = (selectedDate, candidateId, propName) => {
  //   if (selectedDate) {
  //     setCandidates((candidates) => {
  //       candidates.forEach((candidate) => {
  //         if (candidate.id === candidateId) {
  //           candidate[propName] = selectedDate.format('MM/DD/YYYY');
  //         }
  //       });
  //       return candidates;
  //     });
  //   }
  //   setEditing(null);
  // };

  return [
    {
      title: 'Name/Location',
      dataIndex: 'id',
      key: 'id',
      render: (_, item) => {
        return (
          <>
            <Typography.Title level={5} className={'text-sm mb-0'}>
              {item.name}
            </Typography.Title>
            <Typography.Text>{item.location}</Typography.Text>
          </>
        );
      },
    },
    {
      title: 'Available On',
      dataIndex: 'available_on',
      key: 'available_on',
    },
    {
      title: 'Supplier',
      dataIndex: 'supplier',
      key: 'supplier',
      render: (_, {supplier}) => {
        return (
          <Typography.Title level={5} className={'text-sm mb-0'}>
            {getName(supplier)}
          </Typography.Title>
        );
      },
    },
    // {
    //   title: 'Needed By',
    //   dataIndex: 'needed_by',
    //   key: 'needed_by',
    //   render: (neededBy, record) => {
    //     const filedName = record.id + '-neededBy';
    //     if (editing === filedName) {
    //       return (
    //         <DatePicker
    //           size='small'
    //           defaultValue={moment(neededBy)}
    //           format='MM/DD/YYYY'
    //           onChange={(selectedDate) =>
    //             onDateSelect(selectedDate, record.id, 'needed_by')
    //           }
    //           onBlur={() => setEditing(null)}
    //         />
    //       );
    //     }
    //     return (
    //       <Typography.Text onClick={() => setEditing(filedName)}>
    //         {neededBy} <EditOutlined />
    //       </Typography.Text>
    //     );
    //   },
    // },
    {
      title: 'Supplier Based In',
      dataIndex: 'supplier_based_in',
      key: 'supplier_based_in',
      render: (_, {supplier}) => (
        <Typography.Text>{getAddress(supplier)}</Typography.Text>
      ),
    },
    {
      title: 'Billing Rate',
      dataIndex: 'billingRate',
      key: 'billingRate',
      render: (rate, {billing_rate_period}) => (
        <Typography.Text>
          {rate} / {PERIODS[billing_rate_period] || 'hour'}
        </Typography.Text>
      ),
    },
    {
      title: 'Resource Status',
      dataIndex: 'availabilityName',
      key: 'statusName',
    },
  ];
};
