import '../@wieldy/styles/style.less';

import AppContentView from '@wieldy/components/AppContentView';
import InfoViewContextProvider from '@wieldy/components/AppInfoView/InfoViewContext';
import ScrollToTop from '@wieldy/components/ScrollToTop';
import AppRoutes from 'app/AppRoutes';
import InitContainer from 'app/containers/InitContainer';
import React from 'react';
import {Provider as ReduxProvider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';

import AppLayout from './layouts';
import {AppMessages} from './providers/AppMessages';
import AppQueryClientProvider from './providers/AppQueryClientProvider';
import {store} from './store/store';

function App() {
  return (
    <AppQueryClientProvider devTools={process.env.NODE_ENV === 'development'}>
      <BrowserRouter basename='/'>
        <ReduxProvider store={store}>
          <InitContainer>
            <AppMessages>
              <InfoViewContextProvider>
                <ScrollToTop />
                <AppLayout>
                  <AppContentView>
                    <AppRoutes />
                  </AppContentView>
                </AppLayout>
              </InfoViewContextProvider>
            </AppMessages>
          </InitContainer>
        </ReduxProvider>
      </BrowserRouter>
    </AppQueryClientProvider>
  );
}

export default App;
