import AppCard from '@wieldy/components/AppCard';
import AppTag from '@wieldy/components/AppTag';
import {Col, Row, Typography} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

import {isJson} from '../../../../utils/helpers/isJson';
import Editor from '../../../components/Editor/Editor';

const InformationView = ({requisitionModal}) => {
  return (
    <AppCard style={{maxWidth: 820}}>
      <div className={'mb-5'}>
        <Typography.Title level={4}>Overview</Typography.Title>
        <Typography.Paragraph>
          <Editor
            key={requisitionModal?.jobOverview || 'overview'}
            initValue={
              isJson(requisitionModal?.jobOverview)
                ? JSON.parse(requisitionModal?.jobOverview)
                : requisitionModal?.jobOverview
            }
            readOnly={true}
          />
        </Typography.Paragraph>
      </div>
      <div className={'mb-5'}>
        <Typography.Title level={4}>Role Description</Typography.Title>

        <Typography.Paragraph>
          <Editor
            key={requisitionModal?.jobResponsibilities || 'role'}
            initValue={
              isJson(requisitionModal?.jobRoleDescription)
                ? JSON.parse(requisitionModal?.jobRoleDescription)
                : requisitionModal?.jobRoleDescription
            }
            readOnly={true}
          />
        </Typography.Paragraph>
      </div>
      <div className={'mb-5'}>
        <Typography.Title level={4}>Responsibilities</Typography.Title>
        <Typography.Paragraph>
          <Editor
            key={requisitionModal?.jobResponsibilities || 'responsibilities'}
            initValue={
              isJson(requisitionModal?.jobResponsibilities)
                ? JSON.parse(requisitionModal?.jobResponsibilities)
                : requisitionModal?.jobResponsibilities
            }
            readOnly={true}
          />
        </Typography.Paragraph>
      </div>
      <div className={'mb-5'}>
        <Typography.Title level={4}>Skills</Typography.Title>
        <Typography.Paragraph>
          <Editor
            key={requisitionModal?.jobSkillsExperience || 'description'}
            initValue={
              isJson(requisitionModal?.jobSkillsExperience)
                ? JSON.parse(requisitionModal?.jobSkillsExperience)
                : requisitionModal?.jobSkillsExperience
            }
            readOnly={true}
          />
        </Typography.Paragraph>
      </div>
      <Typography.Title level={4}>Tags</Typography.Title>
      <Row wrap='wrap' gutter={[10, 10]}>
        {requisitionModal?.skillTags &&
          requisitionModal.skillTags.map((tag, index) => (
            <Col key={index}>
              <AppTag
                shape={'square'}
                className={'border-none'}
                label={tag?.name}
              />
            </Col>
          ))}
      </Row>
    </AppCard>
  );
};

InformationView.propTypes = {
  requisitionModal: PropTypes.object,
};

export default InformationView;
