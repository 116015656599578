import './index.style.less';

import {useWindowSize} from '@wieldy/hooks/useWindowSize';
import {Drawer, Layout} from 'antd';
import {useAccountType} from 'app/hooks/useAccountType';
import PropTypes from 'prop-types';
import React, {useMemo, useState} from 'react';
import {useLocation} from 'react-router-dom';

import AppHeader from './AppHeader';
import AppSidebar from './AppSidebar';

const {Content} = Layout;
const MainLayout = ({children}) => {
  const {width} = useWindowSize();
  const location = useLocation();
  const [isCollapsed, setCollapsed] = useState(false);

  const {isMarketplace, isSupplier, isClient} = useAccountType();
  const sidebar = useMemo(() => {
    return width > 1199 ? (
      <AppSidebar
        isCollapsed={isCollapsed}
        toggleCollapsed={() => {
          setCollapsed(!isCollapsed);
        }}
        isMobileView={false}
      />
    ) : (
      <Drawer
        placement='left'
        closable={false}
        width={238}
        onClose={() => setCollapsed(false)}
        open={isCollapsed}>
        <AppSidebar
          isMobileView={true}
          toggleCollapsed={() => {
            setCollapsed(!isCollapsed);
          }}
        />
      </Drawer>
    );
  }, [width, isCollapsed, setCollapsed]);

  const isHide = useMemo(() => {
    if (isMarketplace && !isClient && !isSupplier) {
      return true;
    }

    return isMarketplace && location.pathname.includes('/messages');
  }, [isMarketplace, isClient, isSupplier, location]);

  return (
    <Layout hasSider>
      {!isHide && sidebar}
      <Layout className='app-main'>
        <AppHeader
          toggleCollapsed={() => {
            setCollapsed(!isCollapsed);
          }}
          isCollapsed={isCollapsed}
        />
        <Content>{children}</Content>
      </Layout>
    </Layout>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node,
};

export default MainLayout;
