import AppFormWrapper, {
  AppFormActions,
  AppFormBody,
} from '@wieldy/components/AppFormWrapper';
import AppDatePicker from '@wieldy/components/dataEntry/AppDatePicker';
import AppInput from '@wieldy/components/dataEntry/AppInput';
import AppTextArea from '@wieldy/components/dataEntry/AppTextArea';
import {
  getInputPlaceHolder,
  getRequiredMessage,
  getSelectPlaceHolder,
} from '@wieldy/utils/helpers/MessageHelper';
import {Button, Form, Space} from 'antd';
import {
  addItemExpenses,
  resetExpensesForm,
  updateItemExpenses,
} from 'app/store/features/expenses/expensesSlice';
import {getCurrentWeek} from 'app/utils/helpers/GetCurrentWeek';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {v4 as uuidv1} from 'uuid';

import {validate} from './validate';

const ExpenseSpendForm = ({setExpenseVisible, handleCancelPopup, form}) => {
  const dispatch = useDispatch();
  const {temp, payload} = useSelector(({expenses}) => expenses);
  const {end_date} = payload;
  const {initialLogForm, isChangeMode} = temp;

  const disabledDate = (current) => {
    const currentWeekEntries = getCurrentWeek(end_date);
    const currentWeek = currentWeekEntries.map(([, value]) => value);
    return !currentWeek.some((allowedDate) =>
      moment(allowedDate).isSame(current, 'day'),
    );
  };

  useEffect(() => {
    form.setFieldsValue(initialLogForm);
  }, [form, initialLogForm]);

  const idGenerator = () =>
    initialLogForm?.id
      ? {id: initialLogForm?.id}
      : {tempId: isChangeMode ? initialLogForm.tempId : uuidv1()};

  const onFormSubmit = (formData) => {
    const payload = {
      ...formData,
      date_of_spend: moment(formData.date_of_spend).format('YYYY-MM-DD'),
      ...idGenerator(),
    };
    isChangeMode
      ? dispatch(updateItemExpenses(payload))
      : dispatch(addItemExpenses(payload));
    form.resetFields();
    dispatch(resetExpensesForm());
    setExpenseVisible(false);
  };

  return (
    <AppFormWrapper>
      <Form
        form={form}
        layout='vertical'
        onFinish={onFormSubmit}
        autoComplete='off'>
        <AppFormBody>
          <Form.Item
            name='date_of_spend'
            label='Date of Spend'
            rules={[
              {
                required: true,
                message: getRequiredMessage('Date of Spend'),
              },
            ]}>
            <AppDatePicker
              disabledDate={disabledDate}
              disabled={isChangeMode}
              style={{width: '100%'}}
              placeholder={getSelectPlaceHolder('date')}
            />
          </Form.Item>

          <Form.Item
            name='purpose'
            label='Purpose'
            rules={[
              {
                required: true,
                message: getRequiredMessage('Purpose'),
              },
              {validator: validate},
            ]}>
            <AppInput placeholder={getInputPlaceHolder('purpose')} />
          </Form.Item>
          <Form.Item
            name='amount'
            label='Amount'
            rules={[
              {required: true, message: getRequiredMessage('Amount')},
              {validator: validate},
            ]}>
            <AppInput
              type='number'
              pattern='[0-9]+([\.,][0-9]+)?'
              step='any'
              placeholder={getInputPlaceHolder('amount')}
              prefix='$'
              suffix='USD'
            />
          </Form.Item>

          <Form.Item
            name='notes'
            label='Expense Notes'
            rules={[{validator: validate}]}>
            <AppTextArea placeholder={getInputPlaceHolder('expense notes')} />
          </Form.Item>
        </AppFormBody>

        <AppFormActions>
          <Space size={'middle'}>
            <Button type='primary' htmlType='submit'>
              {isChangeMode ? 'Save' : 'Add Expense'}
            </Button>
            <Button onClick={handleCancelPopup}>Cancel</Button>
          </Space>
        </AppFormActions>
      </Form>
    </AppFormWrapper>
  );
};
ExpenseSpendForm.propTypes = {
  setExpenseVisible: PropTypes.func,
  handleCancelPopup: PropTypes.func,
  form: PropTypes.object,
};
export default ExpenseSpendForm;
