import {
  CreateEngagement,
  default as Engagements,
  ViewEngagement,
} from 'app/pages/main/engagements';

export const supplier_EngagementRoutes = [
  {
    path: 'engagements/add-new-engagement',
    element: <CreateEngagement />,
  },
  {
    path: 'engagements/manage-engagement',
    element: <CreateEngagement isEdit={true} />,
  },
  {
    path: 'engagements/:category',
    element: <Engagements />,
  },
  {
    path: 'engagements/:id/view-engagement',
    element: <ViewEngagement />,
  },
];

export const client_EngagementRoutes = [
  {
    path: 'engagements/add-new-engagement',
    element: <CreateEngagement />,
  },
  {
    path: 'engagements/manage-engagement',
    element: <CreateEngagement isEdit={true} />,
  },
  {
    path: 'engagements/:category',
    element: <Engagements />,
  },
  {
    path: 'engagements/:id/view-engagement',
    element: <ViewEngagement />,
  },
];

export const manager_EngagementRoutes = [
  {
    path: 'engagements/add-new-engagement',
    element: <CreateEngagement />,
  },
  {
    path: 'engagements/manage-engagement',
    element: <CreateEngagement isEdit={true} />,
  },
  {
    path: 'engagements/:category',
    element: <Engagements />,
  },
  {
    path: 'engagements/:id/view-engagement',
    element: <ViewEngagement />,
  },
];
