import AppSelect from '@wieldy/components/dataEntry/AppSelect';
import {getSelectPlaceHolder} from '@wieldy/utils/helpers/MessageHelper';
import {message, Modal, Space, Typography} from 'antd';
import propTypes from 'prop-types';
import {useState} from 'react';
import {RiCloseLine} from 'react-icons/ri';

const InquiryRejectModel = ({
  title,
  okText = '',
  visible,
  description = 'Reason',
  reasons,
  children,
  onOk,
  placeholder,
  ...rest
}) => {
  const [reason, setReason] = useState(undefined);

  const handleReasonChange = (reason) => {
    setReason(reason);
  };

  const handleRejectAction = () => {
    const reasonValue = reason?.value ? reason.value : reason;
    if (!reasonValue) {
      message.error('Please select at least one reason');
      return;
    }
    setReason('');
    if (onOk) {
      onOk(reasonValue);
    }
  };

  return (
    <Modal
      className='inquiry-reject-modal'
      title={title}
      visible={visible}
      cancelButtonProps={{type: 'primary', htmlType: 'button', ghost: true}}
      closeIcon={<RiCloseLine fontSize={24} />}
      okText={okText || title}
      width={720}
      onOk={handleRejectAction}
      destroyOnClose={true}
      {...rest}>
      {children}
      {reasons.length > 0 && (
        <div className='modal-reason-dropdown mt-5'>
          <Space direction={'vertical'}>
            <Typography.Text type={'secondary'}>{description}</Typography.Text>
            <AppSelect
              placeholder={placeholder || getSelectPlaceHolder('reason')}
              size={'middle'}
              style={{width: '260px'}}
              value={reason}
              options={reasons}
              labelInValue={true}
              onChange={handleReasonChange}
            />
          </Space>
        </div>
      )}
    </Modal>
  );
};

InquiryRejectModel.propTypes = {
  title: propTypes.string,
  description: propTypes.string,
  visible: propTypes.bool,
  reasons: propTypes.array,
  children: propTypes.node,
  onOk: propTypes.func,
  okText: propTypes.string,
  placeholder: propTypes.string,
};

export default InquiryRejectModel;
