import {UploadOutlined} from '@ant-design/icons';
import {Avatar, Button, notification, Space, Upload} from 'antd';
import axios from 'app/config/axios';
import PropTypes from 'prop-types';
import React, {useCallback} from 'react';
import {useMutation} from 'react-query';

import {getBase64, getUploadUrl} from './constants';

const AvatarUpload = ({variant, id, url, icon, onChange}) => {
  const [logoUrl, setLogoUrl] = React.useState(url);

  const mutation = useMutation(async (payload) => {
    const uploadUrl = getUploadUrl(variant, id);
    if (!uploadUrl) {
      throw new Error('Missing variant or id');
    }
    const {data} = await axios.post(uploadUrl, payload);
    return data;
  });

  const handleBeforeUpload = useCallback(
    (file) => {
      mutation.mutate(
        {
          content_type: file.type,
          content_length: file.size,
        },
        {
          onSuccess: async (signedUrl) => {
            const preview = await getBase64(file);
            setLogoUrl(preview);
            if (onChange) {
              onChange({
                ...signedUrl,
                file: file,
              });
            }
          },
          onError: (error) => {
            if (error?.response?.data) {
              const errorCode = error?.response?.data?.code;
              if (errorCode === 'validation_failed') {
                notification.error({
                  message: 'Invalid file or size. Please select image file.',
                });
                return;
              }
            }
            notification.error({message: error.message});
          },
        },
      );

      return false;
    },
    [mutation, onChange],
  );

  return (
    <Space direction={'vertical'} align={'top'}>
      <Avatar src={logoUrl} size={110} icon={icon} />
      <Upload
        showUploadList={false}
        accept={'image/png, image/jpeg'}
        beforeUpload={handleBeforeUpload}>
        <Button
          loading={mutation.isLoading}
          icon={<UploadOutlined />}
          type={'secondary'}
          size={'small'}>
          {logoUrl ? 'Change Image' : 'Upload Image'}
        </Button>
      </Upload>
    </Space>
  );
};

AvatarUpload.propTypes = {
  url: PropTypes.string,
  variant: PropTypes.oneOf(['client', 'supplier']).isRequired,
  id: PropTypes.string.isRequired,
  icon: PropTypes.node,
  onChange: PropTypes.func,
};

export default AvatarUpload;
