export const PolicyFilesName = {
  CUSTOMER_AGREEMENT: 'customer-agreement',
  SUPPLIER_AGREEMENT: 'supplier-agreement',
  STANDARD_ENGAGEMENT: 'standard-engagement-terms',
  TERMS_OF_USE: 'terms-of-use',
  PRIVACY_POLICY: 'privacy-policy',
  GLOBAL_DATA: 'global-data-processing-agreement',
  COOKIE_POLICY: 'cookie-policy',
};

export const PolicyFilesPath = {
  'customer-agreement': 'Sourcer_Customer_Agreement.pdf',
  'supplier-agreement': 'Sourcer_Supplier_Agreement.pdf',
  'standard-engagement-terms': 'Sourcer_Standard_Engagement_Terms.pdf',
  'standard-terms': 'Sourcer_Standard_Terms.pdf',
  'terms-of-use': 'Sourcer_Terms_Of_Use.pdf',
  'privacy-policy': 'Sourcer_Privacy_Policy.pdf',
  'global-data-processing-agreement':
    'Sourcer_Global_Data_Processing_Agreement.pdf',
  'cookie-policy': 'Sourcer_Cookie_Policy.pdf',
};
