export const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const getUploadUrl = (variant, id) => {
  if (variant === 'client' && id) {
    return `clients/${id}/upload`;
  } else if (variant === 'supplier' && id) {
    return `suppliers/${id}/upload`;
  } else if (variant === 'profile') {
    return '/profile/upload';
  }
  return null;
};
