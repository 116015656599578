import WeekEndDetail from 'app/shared/components/WeekEndTimeCard/WeekEndDetail';
import WeekEndSelectForm from 'app/shared/components/WeekEndTimeCard/WeekEndSelectForm';
import PropTypes from 'prop-types';
import React from 'react';

const WeekEndTimeCard = ({onContinue, isShowDetails, isShowForm}) => {
  return (
    <React.Fragment>
      {isShowDetails ? (
        <WeekEndDetail />
      ) : (
        isShowForm && <WeekEndSelectForm onContinue={onContinue} />
      )}
    </React.Fragment>
  );
};

WeekEndTimeCard.propTypes = {
  currentStep: PropTypes.number,
  onContinue: PropTypes.func,
  isShowDetails: PropTypes.bool,
  isShowForm: PropTypes.bool,
};

export default React.memo(WeekEndTimeCard);
