import AppBorderBox from '@wieldy/components/AppBorderBox';
import AppCard from '@wieldy/components/AppCard';
import {Rate, Typography} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

const RatingRow = ({label, rating}) => {
  return (
    <div className='d-flex align-items-center justify-content-between py-1'>
      <Typography.Paragraph className='mb-0 mr-2 text-secondary'>
        {label}
      </Typography.Paragraph>
      <Rate allowHalf disabled value={rating} count={5} />
    </div>
  );
};

RatingRow.propTypes = {
  label: PropTypes.string,
  rating: PropTypes.number,
};

const PerformanceRating = ({resource, isCard = true}) => {
  const {rating, rating_total, rating_count} = resource;

  const {
    communication,
    proactiveness,
    productivity,
    quality_and_output,
    reliability_and_consistency,
  } = rating;

  const renderContent = () => (
    <>
      <AppBorderBox>
        <div className='text-center'>
          <Typography.Title level={4}>Overall Rating</Typography.Title>
          <div className='rate-root d-flex align-items-center justify-content-center'>
            <Rate
              disabled
              value={rating_total}
              count={5}
              style={{lineHeight: '18px'}}
            />
            <Typography.Text className='ml-2' style={{marginTop: '3px'}}>
              {rating_total} out of 5
            </Typography.Text>
            <Typography.Text
              className='font-semibold text-xs ml-2'
              style={{marginTop: '3px'}}>
              ({rating_count})
            </Typography.Text>
          </div>
        </div>
      </AppBorderBox>
      <div className='mt-3'>
        <RatingRow label='Communication' rating={communication} />
        <RatingRow label='Responsiveness' rating={productivity} />
        <RatingRow
          label='Reliability and Consistency'
          rating={reliability_and_consistency}
        />
        <RatingRow label='Proactiveness' rating={proactiveness} />
        <RatingRow label='Quality of Output' rating={quality_and_output} />
      </div>
    </>
  );

  if (!rating_count) {
    return null;
  }

  if (isCard) {
    return (
      <AppCard
        title='Performance Rating'
        headStyle={{borderBottom: 0}}
        bodyStyle={{paddingTop: 8}}>
        {renderContent()}
      </AppCard>
    );
  }

  return (
    <>
      <Typography.Title level={4}>Performance Rating</Typography.Title>
      {renderContent()}
    </>
  );
};

PerformanceRating.propTypes = {
  resource: PropTypes.object,
  isCard: PropTypes.bool,
};

export default React.memo(PerformanceRating);
