import React from 'react';
import PhoneNumberInput from 'react-phone-number-input/input';

const PhoneInput = (props) => {
  return (
    <PhoneNumberInput
      defaultCountry={'US'}
      className={'ant-input'}
      {...props}
    />
  );
};

export default PhoneInput;
