export const CANDIDATE_STATUSES = {
  proposed: 'Proposed',
  not_available: 'Not Available',
  in_review: 'In Review',
  pending: 'Pending',
  former: 'Former',
  engaged: 'Engaged',
  dismissed: 'Dismissed',
  not_engaged: 'Not Engaged',
  interested: 'Interested',
  not_interested: 'Not Interested',
  to_be_interviewed: 'To Be Interviewed',
  interviewed_success: 'Interviewed - Success',
  interviewed_fail: 'Interviewed - Fail',
  interviewed_on_hold: 'Interviewed - On Hold',
  to_be_engaged: 'To Be Engaged',
  placed: 'Placed',
  not_placed: 'Not Placed',
};
