import {UserOutlined} from '@ant-design/icons';
import AppCard from '@wieldy/components/AppCard';
import AppFormWrapper, {
  AppFormActions,
  AppFormBody,
} from '@wieldy/components/AppFormWrapper';
import AppInput from '@wieldy/components/dataEntry/AppInput';
import {getRequiredMessage} from '@wieldy/utils/helpers/MessageHelper';
import {
  Button,
  Col,
  Form,
  notification,
  Row,
  Space,
  Switch,
  Typography,
} from 'antd';
import {useUploadImageToAws} from 'app/services/globalService';
import AvatarUpload from 'app/shared/components/AvatarUpload';
import PhoneInput from 'app/shared/components/PhoneInput';
import {validatePhoneNumber} from 'app/utils/helpers/AppHelper';
import PropTypes from 'prop-types';
import React from 'react';

const EditProfileForm = ({
  onCancel,
  onSubmit,
  initialValues = {},
  loading = false,
}) => {
  const [disabled, setDisabled] = React.useState(true);
  const [avatarUrl, setAvatarUrl] = React.useState(initialValues?.avatar_url);
  const [uploadImage, setUploadImage] = React.useState();
  const uploadMutation = useUploadImageToAws();

  React.useEffect(() => {
    if (uploadImage) {
      setDisabled(false);
    }
  }, [uploadImage]);

  const formProps = React.useMemo(() => {
    return {
      ...(initialValues ? initialValues : {}),
      disable_time_and_expense_notifications:
        initialValues?.notifications?.disable_time_and_expense_notifications ??
        false,
      disable_email_notifications:
        initialValues?.notifications?.disable_email_notifications ?? false,
    };
  }, [initialValues]);

  const handleOnFinish = (formdata) => {
    if (uploadImage) {
      uploadMutation.mutate(
        {
          uploadUrl: uploadImage.upload_url,
          file: uploadImage.file,
        },
        {
          onSuccess: () => {
            formdata.avatar_url = uploadImage.url;
            onSubmit(formdata);
          },
          onError: () => {
            notification.error({
              message:
                'Timeout to upload a logo expired, please try again to upload file',
            });
            setUploadImage(undefined);
            setAvatarUrl(initialValues?.avatar_url);
          },
        },
      );
    } else {
      onSubmit(formdata);
    }
  };

  return (
    <AppCard
      title='User Information'
      headStyle={{borderBottom: 0}}
      bodyStyle={{paddingTop: 8}}>
      <AppFormWrapper>
        <Form
          layout={'horizontal'}
          labelCol={{xs: 24, xxl: 10}}
          wrapperCol={{xs: 24, xxl: 14}}
          labelAlign={'left'}
          colon={false}
          initialValues={formProps}
          onFinish={handleOnFinish}
          onValuesChange={() => setDisabled(false)}>
          <AppFormBody>
            <Typography.Title level={4} className={'mb-3'}>
              Basic Information
            </Typography.Title>
            <Row gutter={[30, {xs: 0, xxl: 10}]}>
              <Col xs={24} sm={12}>
                <Form.Item
                  label='First Name'
                  name={'first_name'}
                  rules={[
                    {
                      required: true,
                      message: getRequiredMessage('First name'),
                    },
                  ]}>
                  <AppInput />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item
                  label='Last Name'
                  name={'last_name'}
                  rules={[
                    {
                      required: true,
                      message: getRequiredMessage('Last name'),
                    },
                  ]}>
                  <AppInput />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item
                  label='Title'
                  name={'title'}
                  rules={[
                    {
                      required: true,
                      message: getRequiredMessage('Title'),
                    },
                  ]}>
                  <AppInput />
                </Form.Item>
              </Col>
            </Row>
            <Typography.Title level={4} className={'mb-3 mt-5'}>
              Contact Information
            </Typography.Title>
            <Row gutter={[30, {xs: 0, xxl: 10}]}>
              <Col xs={24} sm={12}>
                <Form.Item
                  label='Phone Number'
                  name={'phone_number'}
                  rules={[
                    {
                      required: true,
                      message: getRequiredMessage('Phone number'),
                    },
                    {
                      validator: validatePhoneNumber,
                    },
                  ]}>
                  <PhoneInput />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item
                  label='Email Address'
                  name={'email'}
                  rules={[
                    {
                      required: true,
                      message: getRequiredMessage('Email address'),
                    },
                    {
                      type: 'email',
                      message: 'Enter valid email address',
                    },
                  ]}>
                  <AppInput />
                </Form.Item>
              </Col>
            </Row>
            <Typography.Title level={4} className={'mb-3 mt-5'}>
              Notification Settings
            </Typography.Title>
            <Row gutter={[30, {xs: 0, xxl: 10}]}>
              <Col sm={24}>
                <Form.Item
                  label='Disable Email Notifications'
                  name={'disable_email_notifications'}>
                  <Switch
                    defaultChecked={formProps?.disable_email_notifications}
                  />
                </Form.Item>
              </Col>
              <Col sm={24}>
                <Form.Item
                  label={
                    <span
                      dangerouslySetInnerHTML={{
                        __html: 'Disable Time & Expense <br/> Notifications',
                      }}
                    />
                  }
                  name={'disable_time_and_expense_notifications'}>
                  <Switch
                    defaultChecked={
                      formProps?.disable_time_and_expense_notifications
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            <Typography.Title level={4} className={'mb-3 mt-5'}>
              Profile Photo
            </Typography.Title>
            <Row gutter={[30, 0]} className={'mb-6'}>
              <Col xs={24} sm={24}>
                <AvatarUpload
                  id={avatarUrl}
                  variant='profile'
                  url={avatarUrl}
                  onChange={setUploadImage}
                  icon={<UserOutlined />}
                />
              </Col>
            </Row>
          </AppFormBody>
          <AppFormActions>
            <Space size={16}>
              <Button
                type={'primary'}
                htmlType={'submit'}
                loading={uploadMutation.isLoading || loading}
                disabled={disabled}>
                Save
              </Button>
              <Button type={'primary'} onClick={onCancel} ghost>
                Cancel
              </Button>
            </Space>
          </AppFormActions>
        </Form>
      </AppFormWrapper>
    </AppCard>
  );
};

EditProfileForm.propTypes = {
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  initialValues: PropTypes.object,
  loading: PropTypes.bool,
};

export default EditProfileForm;
