import {cloneDeep} from 'lodash';

export const getHoursFromItems = (items) => {
  const updatedItems = cloneDeep(items).filter((el) => !el.deleted);
  const outputObject = {};

  updatedItems.forEach((item) => {
    const key = `${item.engagement_task}/${item.work_date}`;
    if (!outputObject[key]) {
      outputObject[key] = [];
    }
    outputObject[key].push(item);
  });

  const array = [];

  for (let item of updatedItems) {
    const indexToUpdate = array.findIndex(
      (element) => element.engagement_task === item.engagement_task,
    );
    if (indexToUpdate >= 0) {
      if (!array[indexToUpdate][item.work_date]) {
        array[indexToUpdate][item.work_date] = item.minutes;
      } else {
        array[indexToUpdate][item.work_date] += item.minutes;
      }
    } else {
      array.push({...item, [item.work_date]: item.minutes, minutes: null});
    }
  }
  return [array, outputObject];
};
