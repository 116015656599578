import AppFilterWrapper from '@wieldy/components/AppFilterWrapper';
import AppGridView from '@wieldy/components/AppGridView';
import AppLoader from '@wieldy/components/AppLoader';
import AppPagination from '@wieldy/components/AppPagination';
import AppHeaderPagination from '@wieldy/components/AppPagination/AppHeaderPagination';
import AppPageSection from '@wieldy/components/pageComponents/AppPageSection';
import {Empty} from 'antd';
import {useBrowse} from 'app/hooks/useBrowse.js';
import usePageQuery from 'app/hooks/usePageQuery';
import ClientModal from 'app/modals/ClientModal';
import {useGetClients} from 'app/services/supplierService';
import {ClientCard, FilterClients} from 'app/shared/client';
import FilterResults from 'app/shared/components/FIlter/FilterResults';
import FilterButton from 'app/shared/components/FilterButton';
import React, {useCallback} from 'react';
import {useParams} from 'react-router-dom';

const Browse = () => {
  const {category} = useParams();
  const isBookmarked = category === 'bookmarked';
  const {
    pageSize,
    currentPage,
    openFilter,
    onChangePage,
    onShowSizeChange,
    onToggleFilter,
    sortBy,
    onSortBy,
  } = useBrowse({
    initSort: isBookmarked ? '-bookmarked_on' : '-created_at',
    initPageSize: 15,
  });

  const {
    handleSetFilter,
    resetFilter,
    filterState,
    filterQuery,
    queryInit,
    currentPageParams,
  } = usePageQuery();
  const {title, empty, label} = currentPageParams;

  const handleOnApplyFilter = useCallback(
    (form) => {
      handleSetFilter(form);
      onToggleFilter(false);
    },
    [handleSetFilter, onToggleFilter],
  );

  const {isLoading, data, isFetching} = useGetClients({
    ...queryInit,
    include: [...queryInit.include],
    page: currentPage - 1,
    limit: pageSize,
    sort: [sortBy],
    ...filterQuery,
  });

  const handleApplyReset = React.useCallback(() => {
    resetFilter();
  }, [resetFilter]);

  const isEmpty = !data?.items?.length;
  const isFilter = Object.values(filterState).some((filter) => !!filter);

  if (isLoading) {
    return <AppLoader />;
  }

  return (
    <React.Fragment>
      {isEmpty && !isFilter ? (
        <Empty description={empty} />
      ) : (
        <>
          <AppPageSection
            title={title}
            count={data?.total_count}
            extra={
              <React.Fragment>
                <FilterButton
                  onClick={onToggleFilter}
                  enabled={data?.items?.length > 0 || isFilter}
                />
                <AppHeaderPagination
                  total={data?.total_count}
                  current={currentPage}
                  pageSize={pageSize}
                  onChange={onChangePage}
                  onShowSizeChange={onShowSizeChange}
                  showSizeChanger
                  showSortBy={true}
                  sortByOptions={[
                    {label: 'Alphabetically', value: '+name'},
                    {
                      label: `${label} date (newest first)`,
                      value: isBookmarked ? '-bookmarked_on' : '-created_at',
                    },
                    {
                      label: `${label} date (oldest first)`,
                      value: isBookmarked ? '+bookmarked_on' : '+created_at',
                    },
                  ]}
                  sortBy={sortBy}
                  onSortByChange={onSortBy}
                />
              </React.Fragment>
            }>
            <AppFilterWrapper
              title='Filter Clients'
              open={openFilter}
              onApply={handleOnApplyFilter}
              onReset={handleApplyReset}
              initialValues={filterState}
              onClose={() => onToggleFilter(false)}>
              <FilterClients
                showRelationStatus={['bookmarked', 'browse'].includes(category)}
              />
            </AppFilterWrapper>
            <FilterResults />
            <AppGridView
              emptyTitle={empty}
              loading={isLoading || isFetching}
              data={data?.items ?? []}
              renderItem={(client, index) => (
                <ClientCard
                  key={index}
                  client={new ClientModal(client)}
                  showBookmark={true}
                />
              )}
            />

            <AppPagination
              total={data?.total_count}
              current={currentPage}
              pageSize={pageSize}
              onChange={onChangePage}
              showTotal={(total, range) =>
                `${range[0]}-${range[1]} of ${total}`
              }
              onShowSizeChange={onShowSizeChange}
            />
          </AppPageSection>
        </>
      )}
    </React.Fragment>
  );
};

export default Browse;
