/**
 * Get Assets Url
 * @param fileUrl string
 * @returns fileUrl string
 */
export const getAssetsUrl = (fileUrl) => {
  return `/assets/${fileUrl}`;
};

export const normalizeUrlString = (urlSlug) => {
  return urlSlug?.replaceAll('_', ' ').replaceAll('-', ' ');
};
