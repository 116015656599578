import {DATE_FORMATS} from 'app/config/app';

import BaseModal from './BaseModal';

class ResourceReviewModal extends BaseModal {
  constructor(props) {
    super(props);
    this.id = props?.id ?? null;
    this.hid = props?.hid ?? null;
    this.resource = props?.resource ?? null;
    this.resourceName = this.resource ? this.getName(this?.resource) : '';
    this.resourceId = this.resource ? this.resource?.id : null;
    this.supplier = props?.supplier ?? null;
    this.supplierName = this.supplier ? this.supplier?.name : null;
    this.supplierId = this.supplier ? this.supplier?.id : null;
    this.requisition = props?.requisition ?? null;
    this.jobTitle = this.requisition ? this.requisition?.job_title : null;
    this.startDateTimestamp = props?.start_date ?? null;
    this.endDateTimestamp = props?.end_date ?? null;
    this.startDate = this.startDateTimestamp
      ? this.formatDate(this.startDateTimestamp, DATE_FORMATS['/'])
      : null;
    this.endDate = this.endDateTimestamp
      ? this.formatDate(this.endDateTimestamp, DATE_FORMATS['/'])
      : null;
    this.hiringManager = props.hiring_manager ?? null;
    this.hiringManagerName = this.hiringManager
      ? this.getName(this.hiringManager)
      : '';
    this.resourceReview = props.resource_review ?? null;
    this.reviewBy = props?.review_author
      ? this.getName(props?.review_author)
      : null;
    this.updatedAt = this.resourceReview?.updated_at
      ? this.formatRelativeTime(this.resourceReview?.updated_at)
      : null;
  }

  getName(obj) {
    const firstName = obj?.first_name ?? '';
    const lastName = obj?.last_name ?? '';
    return firstName + ' ' + lastName;
  }
}

export default ResourceReviewModal;
