import AppCard from '@wieldy/components/AppCard';
import {Typography} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

import {LANGUAGE_LEVELS, LANGUAGES} from './../../../constants';

const Languages = ({languages, isCard = true}) => {
  const renderContent = () => (
    <table className='table' style={{margin: '0 -8px'}}>
      <tbody>
        {!!languages &&
          languages.map((el) => (
            <tr key={el.language}>
              <td className={'text-secondary'}>
                {LANGUAGES[el.language] || el.language}
              </td>
              <td align='right'>{LANGUAGE_LEVELS[el.level] || el.level} </td>
            </tr>
          ))}
      </tbody>
    </table>
  );

  if (!languages?.length) {
    return null;
  }

  if (isCard) {
    return (
      <AppCard
        title='Languages'
        headStyle={{borderBottom: 0}}
        bodyStyle={{paddingTop: 0}}>
        {renderContent()}
      </AppCard>
    );
  }

  return (
    <>
      <Typography.Title level={4}>Languages</Typography.Title>
      {renderContent()}
    </>
  );
};

Languages.propTypes = {
  languages: PropTypes.array,
  isCard: PropTypes.bool,
};

export default Languages;
