import AppCard from '@wieldy/components/AppCard';
import AppHeaderPagination from '@wieldy/components/AppPagination/AppHeaderPagination';
import AppTable from '@wieldy/components/AppTable';
import AppTag from '@wieldy/components/AppTag/AppTag';
import {getTablePagination} from '@wieldy/utils/helpers/TableHelper';
import {Modal} from 'antd';
import {PAGINATION} from 'app/config/app';
import {useAccountType} from 'app/hooks/useAccountType';
import {useTableSort} from 'app/hooks/useTableSort';
import {
  useClientAdministrationBulkActions,
  useGetClientInvitations,
} from 'app/services/clientService';
import {
  useGetSupplierInvitations,
  useSupplierAdministrationBulkActions,
} from 'app/services/supplierService';
import React, {useCallback} from 'react';

import {getColumns} from './columns';

const Invitations = () => {
  const {isSupplier} = useAccountType();
  const [currentPage, setCurrentPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(PAGINATION.TABLE_ITEM_LIMIT);

  const {sort, handleSort} = useTableSort({
    ['created_at']: 'descend',
  });

  const onChangeTable = useCallback(
    (a, b, sorter) => {
      if (sorter.columnKey && sorter?.order) {
        handleSort(sorter.columnKey, sorter?.order);
      }
    },
    [handleSort],
  );

  const {data, isLoading} = (
    isSupplier ? useGetSupplierInvitations : useGetClientInvitations
  )({
    page: currentPage - 1,
    limit: pageSize,
    sort,
  });
  const [modal, modalContext] = Modal.useModal();
  const mutation = (
    isSupplier
      ? useSupplierAdministrationBulkActions
      : useClientAdministrationBulkActions
  )();

  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const onShowSizeChange = (current, pageSize) => {
    setCurrentPage(current);
    setPageSize(pageSize);
  };

  const bulkActions = React.useCallback(
    (item) => {
      return [
        {
          title: 'Resend Invitation',
          onClick: () =>
            mutation.mutate({
              action: 'resendInvitation',
              payload: item,
            }),
        },
        {
          title: 'Withdraw Invitation',
          onClick: () =>
            modal.confirm({
              title: 'Withdraw this invitation?',
              onOk: () =>
                mutation.mutate({
                  action: 'deleteInvitation',
                  payload: item,
                }),
              okText: 'Withdraw',
            }),
        },
      ];
    },
    [modal, mutation],
  );

  return (
    <AppCard
      title={
        <React.Fragment>
          Invitations{' '}
          {data?.totalCount > 0 && (
            <AppTag className='ml-1'>{data?.totalCount}</AppTag>
          )}
        </React.Fragment>
      }
      headStyle={{borderBottom: 0}}
      bodyStyle={{paddingTop: 8}}
      extra={
        <AppHeaderPagination
          total={data?.totalCount}
          current={currentPage}
          pageSize={pageSize}
          onChange={onPageChange}
        />
      }>
      {modalContext}
      <AppTable
        loading={isLoading}
        columns={getColumns()}
        bulkActions={bulkActions}
        onChange={onChangeTable}
        dataSource={data?.items}
        pagination={getTablePagination(
          pageSize,
          currentPage,
          data?.totalCount,
          onShowSizeChange,
          onPageChange,
        )}
      />
    </AppCard>
  );
};

export default React.memo(Invitations);
