import {
  getRequiredMessage,
  getSelectPlaceHolder,
} from '@wieldy/utils/helpers/MessageHelper';
import {Button, Form, Space, Typography} from 'antd';
import {resetRequestHeader, setRequestHeader} from 'app/config/axios';
import {useQueryClient} from 'app/hooks/useReactQuery';
import {CompanyMemberDropdown} from 'app/shared/components/dropdowns';
import {getFullName} from 'app/utils/helpers/DataFormatHelper';
import PropTypes from 'prop-types';
import React, {useCallback, useState} from 'react';
import {RiEditLine} from 'react-icons/ri';

const ManagerColumn = ({
  id,
  account_manager,
  mutate,
  loading,
  keyId = 'client',
}) => {
  const [editing, setEditing] = useState(null);
  const queryClient = useQueryClient();
  const handleEditManager = useCallback((value) => {
    setEditing(value);
  }, []);

  const onFinish = useCallback(
    (formdata) => {
      const header = `sourcer-${keyId}`;
      setRequestHeader(header, id);
      mutate(
        {
          [`${keyId}Id`]: id,
          payload: {
            account_manager: formdata.account_manager,
          },
        },
        {
          onSuccess: async () => {
            resetRequestHeader(header);
            await queryClient.invalidateQueries(keyId + 's');
            handleEditManager(null);
          },
        },
      );
    },
    [handleEditManager, id, keyId, mutate, queryClient],
  );
  if (editing === id) {
    return (
      <Form
        layout={'vertical'}
        initialValues={{
          account_manager: account_manager?.id,
        }}
        onFinish={onFinish}>
        <Space direction={'horizontal'} size={'small'}>
          <Form.Item
            name={'account_manager'}
            rules={[
              {
                required: true,
                message: getRequiredMessage('Account Manager'),
              },
            ]}
            style={{marginBottom: 0}}>
            <CompanyMemberDropdown
              variant={'marketplace'}
              placeholder={getSelectPlaceHolder('account manager')}
              initialFetch={true}
              showArrow={false}
              allowClear={true}
              style={{minWidth: '280px', width: 'auto', maxWidth: '100%'}}
              size={'small'}
            />
          </Form.Item>
          <Button
            type={'primary'}
            size={'small'}
            htmlType={'submit'}
            loading={loading}>
            Save
          </Button>
          <Button
            type={'primary'}
            size={'small'}
            ghost={true}
            onClick={() => handleEditManager(null)}>
            Cancel
          </Button>
        </Space>
      </Form>
    );
  }
  return (
    <Typography.Text>
      {getFullName(account_manager)}
      <Typography.Link className='ml-2' onClick={() => handleEditManager(id)}>
        <RiEditLine fontSize={16} />
      </Typography.Link>
    </Typography.Text>
  );
};

ManagerColumn.propTypes = {
  id: PropTypes.string.isRequired,
  account_manager: PropTypes.object.isRequired,
  mutate: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  keyId: PropTypes.oneOf(['client', 'supplier']),
};

export default ManagerColumn;
