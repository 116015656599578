// components/Label.js
import cn from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

const Label = ({text, isRequired = false}) => (
  <div className={cn('ant-form-item-label')}>
    <span
      className={cn(
        'ant-descriptions-item-label ant-descriptions-item-no-colon pl-0',
        isRequired && 'ant-form-item-required',
      )}>
      {text}
    </span>
  </div>
);

Label.propTypes = {
  isRequired: PropTypes.bool,
  text: PropTypes.string,
  icon: PropTypes.string,
  tooltip: PropTypes.string,
  className: PropTypes.string,
};

export default Label;
