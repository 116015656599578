import {baseAPI} from 'app/api/baseApi';
import axios, {axios0} from 'app/config/axios';
import {useReactMutation} from 'app/hooks/useReactQuery';

export const useUpdateUserProfile = () => {
  return useReactMutation(async (postdata) => {
    const {data} = await axios.patch('/profile', postdata);
    return {
      data: data,
      message: 'Profile updated successfully',
    };
  });
};

export const useUpdatePassword = () => {
  return useReactMutation(async (postdata) => {
    const {data} = await baseAPI.post('/auth/change_password', postdata);
    return {
      data: data,
      message: 'Password updated successfully',
    };
  });
};

export const useResetPassword = () => {
  return useReactMutation(async (email) => {
    const payload = {
      email,
      connection: 'sourcer',
      client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
    };
    const {data} = await axios0.post('/dbconnections/change_password', payload);
    return {
      data: data,
      message: 'Password reset successfully',
    };
  });
};
