import AppFilterWrapper from '@wieldy/components/AppFilterWrapper';
import AppGridView from '@wieldy/components/AppGridView';
import AppLoader from '@wieldy/components/AppLoader';
import AppPagination from '@wieldy/components/AppPagination';
import AppHeaderPagination from '@wieldy/components/AppPagination/AppHeaderPagination';
import AppPageSection from '@wieldy/components/pageComponents/AppPageSection';
import {Empty} from 'antd';
import {CATEGORIES} from 'app/constants/index.js';
import {useBrowse} from 'app/hooks/useBrowse.js';
import usePageQuery from 'app/hooks/usePageQuery';
import SupplierModal from 'app/modals/SupplierModal';
import {useGetSuppliers} from 'app/services/clientService';
import FilterResults from 'app/shared/components/FIlter/FilterResults';
import FilterButton from 'app/shared/components/FilterButton';
import {FilterSuppliers, SupplierCard} from 'app/shared/supplier';
import {getItemsData} from 'app/utils/helpers/GetItemsData.js';
import React, {useCallback} from 'react';
import {useParams} from 'react-router-dom';

const Browse = () => {
  const {category} = useParams();
  const isBookmarked = category === CATEGORIES.BOOKMARKED;
  const {
    currentPage,
    onSortBy,
    sortBy,
    onChangePage,
    onShowSizeChange,
    pageSize,
    openFilter,
    onToggleFilter,
  } = useBrowse({
    initSort: isBookmarked ? '-bookmarked_on' : '-created_at',
    initPageSize: 15,
  });

  const {
    handleSetFilter,
    resetFilter,
    filterState,
    filterQuery,
    queryInit,
    currentPageParams,
  } = usePageQuery();

  const {rowTitle, label, empty} = currentPageParams;

  const {isLoading, data, isFetching} = useGetSuppliers({
    ...queryInit,
    ...filterQuery,
    page: currentPage - 1,
    limit: pageSize,
    sort: [sortBy],
  });

  const [suppliers, totalCount] = getItemsData('suppliers', data);

  const handleApplyFilter = useCallback(
    (form) => {
      handleSetFilter(form);
      onToggleFilter(false);
    },
    [handleSetFilter, onToggleFilter],
  );

  const handleApplyReset = useCallback(() => {
    resetFilter();
  }, [resetFilter]);

  const isEmpty = !suppliers?.length;
  const isFilter = Object.values(filterState).some((filter) => !!filter);

  if (isLoading) {
    return <AppLoader />;
  }

  if (isEmpty && !isFilter) {
    return <Empty description={empty} />;
  }

  return (
    <React.Fragment>
      <AppPageSection
        title={rowTitle}
        count={totalCount}
        extra={
          <React.Fragment>
            <FilterButton
              onClick={onToggleFilter}
              enabled={!!suppliers.length || isFilter}
            />
            <AppHeaderPagination
              total={totalCount}
              current={currentPage}
              pageSize={pageSize}
              onChange={onChangePage}
              onShowSizeChange={onShowSizeChange}
              showSizeChanger
              showSortBy={true}
              sortBy={sortBy}
              onSortByChange={onSortBy}
              sortByOptions={[
                {label: 'Alphabetically', value: '+name'},
                {
                  label: `${label} date (newest first)`,
                  value: isBookmarked ? '-bookmarked_on' : '-created_at',
                },
                {
                  label: `${label} date (oldest first)`,
                  value: isBookmarked ? '+bookmarked_on' : '+created_at',
                },
              ]}
            />
          </React.Fragment>
        }>
        <AppFilterWrapper
          title='Filter Suppliers'
          open={openFilter}
          onApply={handleApplyFilter}
          onReset={handleApplyReset}
          initialValues={filterState}
          onClose={() => onToggleFilter(false)}>
          <FilterSuppliers
            showRelationStatus={[
              CATEGORIES.BOOKMARKED,
              CATEGORIES.BROWSE,
            ].includes(category)}
          />
        </AppFilterWrapper>

        <FilterResults />

        <AppGridView
          emptyTitle={empty}
          loading={isLoading || isFetching}
          data={suppliers}
          renderItem={(supplier, index) => (
            <SupplierCard
              key={index}
              supplier={new SupplierModal(supplier)}
              showBookmark={true}
            />
          )}
        />

        <AppPagination
          total={totalCount}
          current={currentPage}
          pageSize={pageSize}
          onChange={onChangePage}
          showTotal={(total, range) => `${range[0]}-${range[1]} of ${total}`}
          onShowSizeChange={onShowSizeChange}
        />
      </AppPageSection>
    </React.Fragment>
  );
};

export default Browse;
