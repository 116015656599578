export const SERVICE_FOCUS = {
  accounting: {
    value: 'Accounting',
    subItems: [
      'Accounting',
      'Bookkeeping',
      'Financial Accounting',
      'Financial Advisory',
      'Mortgage Lending',
      'Personal Tax Planning',
      'Small Business Tax',
      'Tax Preparation',
    ],
  },
  coaching_mentoring: {
    value: 'Coaching & Mentoring',
    subItems: [
      'Career Development Coaching',
      'Change Management',
      'Corporate Training',
      'Diversity & Inclusion',
      'Executive Coaching',
      'Interview Preparation',
      'Leadership Development',
      'Life Coaching',
      'Negotiation',
      'Public Speaking',
      'Resume Review',
      'Resume Writing',
      'Team Building',
      'Training',
    ],
  },
  consulting: {
    value: 'Consulting',
    subItems: [
      'Advertising',
      'Brand Consulting',
      'Business Consulting',
      'Educational Consulting',
      'Email Marketing',
      'Environmental Consulting',
      'Financial Consulting',
      'HR Consulting',
      'Healthcare Consulting',
      'Legal Consulting',
      'Management Consulting',
      'Marketing Consulting',
      'Non-profit Consulting',
      'Political Consulting',
      'Pricing Strategy',
      'Project Management',
      'Public Relations',
      'Search Engine Marketing (SEM)',
      'Search Engine Optimization (SEO)',
      'Tax Advisory',
    ],
  },
  design: {
    value: 'Design',
    subItems: [
      '3D Design',
      'Ad Design',
      'Animation',
      'Brand Design',
      'Engineering Design',
      'Graphic Design',
      'Illustration',
      'Industrial Design',
      'Interaction Design',
      'Interior Design',
      'Logo Design',
      'Packaging Design',
      'Presentation Design',
      'Print Design',
      'UX Research',
      'User Experience Design (UED)',
      'Video Editing',
      'Video Production',
      'Videography',
      'Visual Design',
      'Web Design',
      'WordPress Design',
    ],
  },
  events: {
    value: 'Events',
    subItems: [
      'Audio Engineering',
      'Bartending',
      'Catering',
      'Corporate Events',
      'DJing',
      'Event Coordination',
      'Event Photography',
      'Event Planning',
      'Event Production',
      'Floral Design',
      'Live Events',
      'Photo Booths',
      'Product Launch Events',
      'Sound Design',
      'Trade Shows',
      'Wedding Officiating',
      'Wedding Planning',
    ],
  },
  finance: {
    value: 'Finance',
    subItems: [
      'Budgeting',
      'Financial Accounting',
      'Financial Advisory',
      'Financial Analysis',
      'Financial Planning',
      'Financial Reporting',
      'Insurance',
      'Loans',
      'Retirement Planning',
    ],
  },
  home_improvement: {
    value: 'Home Improvement',
    subItems: ['Architecture', 'Interior Design', 'Landscape Design'],
  },
  information_technology: {
    value: 'Information Technology',
    subItems: [
      'Backup & Recovery Systems',
      'Computer Networking',
      'Computer Repair',
      'Cybersecurity',
      'Data Recovery',
      'Home Networking',
      'IT Consulting',
      'Network Support',
      'Telecommunications',
    ],
  },
  insurance: {
    value: 'Insurance',
    subItems: [
      'Auto Insurance',
      'Commercial Insurance',
      'Health Insurance',
      'Homeowners Insurance',
      'Insurance',
      'Life Insurance',
      'Small Business Insurance',
      'Title Insurance',
    ],
  },
  law: {
    value: 'Law',
    subItems: [
      'Bankruptcy Law',
      'Business Law',
      'Consumer Law',
      'Copyright Law',
      'Corporate Law',
      'Criminal Defense Law',
      'DUI Law',
      'Divorce Law',
      'Entertainment Law',
      'Estate Planning Law',
      'Family Law',
      'IT Law',
      'Immigration Law',
      'Intellectual Property Law',
      'Labor and Employment Law',
      'Legal Consulting',
      'Notary',
      'Patent Law',
      'Personal Injury Law',
      'Property Law',
      'Startup Law',
      'Tax Law',
      'Trademark Law',
      'Trust and Estate Litigation',
      'Wills Planning Law',
    ],
  },
  marketing: {
    value: 'Marketing',
    subItems: [
      'Advertising',
      'Blogging',
      'Brand Marketing',
      'Content Marketing',
      'Content Strategy',
      'Demand Generation',
      'Digital Marketing',
      'Direct Mail Marketing',
      'Email Marketing',
      'Event Marketing',
      'Growth Marketing',
      'Lead Generation',
      'Market Research',
      'Marketing Consulting',
      'Marketing Strategy',
      'Mobile Marketing',
      'Performance Marketing',
      'Product Marketing',
      'Public Relations',
      'Real Estate Marketing',
      'Search Engine Marketing (SEM)',
      'Search Engine Optimization (SEO)',
      'Social Media Marketing',
    ],
  },
  operations: {
    value: 'Operations',
    subItems: [
      'Administrative Assistance',
      'Appointment Scheduling',
      'Customer Service',
      'Customer Support',
      'Data Entry',
      'Data Reporting',
      'Executive Administrative Assistance',
      'File Management',
      'Filing',
      'Human Resources (HR)',
      'Invoice Processing',
      'Online Research',
      'Outsourcing',
      'Payroll Services',
      'Personal Assistance',
      'Program Management',
      'Project Management',
      'Research Skills',
      'Strategic Planning',
      'Technical Support',
      'Transcription',
      'Typing',
      'Virtual Assistance',
    ],
  },
  photography: {
    value: 'Photography',
    subItems: [
      'Commercial Photography',
      'Corporate Photography',
      'Event Photography',
      'Food Photography',
      'Headshot Photography',
      'Nature Photography',
      'Pet Photography',
      'Photo Booths',
      'Portrait Photography',
      'Real Estate Photography',
      'Restaurant Photography',
      'Sports Photography',
      'Stock Photography',
      'Video Animation',
      'Video Editing',
      'Videography',
      'Wedding Photography',
    ],
  },
  real_estate: {
    value: 'Real Estate',
    subItems: [
      'Commercial Lending',
      'Commercial Real Estate',
      'Mortgage Lending',
      'Property Law',
      'Property Management',
      'Real Estate',
      'Real Estate Appraisal',
      'Real Estate Marketing',
      'Relocation',
    ],
  },
  software_development: {
    value: 'Software Development',
    subItems: [
      'Android Development',
      'Application Development',
      'Business Analytics',
      'Cloud Application Development',
      'Cloud Management',
      'Custom Software Development',
      'Database Development',
      'Enterprise Content Management',
      'Information Management',
      'Information Security',
      'Mobile Application Development',
      'SaaS Development',
      'Software Testing',
      'User Experience Design (UED)',
      'Web Design',
      'Web Development',
      'iOS Development',
    ],
  },
  writing: {
    value: 'Writing',
    subItems: [
      'Blogging',
      'Content Strategy',
      'Copywriting',
      'Editing',
      'Ghostwriting',
      'Grant Writing',
    ],
  },
};
