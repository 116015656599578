import AppSelect from '@wieldy/components/dataEntry/AppSelect';
import {Avatar} from 'antd';
import MESSAGE_QUERIES from 'app/constants/query-params/message-queries';
import {useAccountType} from 'app/hooks/useAccountType';
import {getRequisitionChannelsByUserIds} from 'app/utils/helpers/ChatHelpers';
import sourcerLogo from 'assets/images/logo.png';
import PropTypes from 'prop-types';
import {useMemo} from 'react';
import {RiBuildingLine, RiMenu3Line} from 'react-icons/ri';
import {useLocation, useNavigate} from 'react-router-dom';
import {useChatContext} from 'stream-chat-react';

export const TeamChannelHeader = ({toggleMobile}) => {
  const navigate = useNavigate();

  const location = useLocation();
  const {
    channel,
    client: {activeChannels},
  } = useChatContext();

  const searchParams = new URLSearchParams(location.search);

  const channelId = searchParams.get(MESSAGE_QUERIES.channelId);

  const accountType = useAccountType();

  const loadedChannels = Object.values(activeChannels);

  const requisitionChannels = getRequisitionChannelsByUserIds(loadedChannels, {
    supplier: channel.data?.supplier,
    client: channel.data?.client,
  });

  const handleChannelChange = (value) => {
    navigate(`/messages?${MESSAGE_QUERIES.channelId}=${value}`);
  };

  const headerTitle = useMemo(() => {
    if (
      channel.data.client_name &&
      channel.data.supplier_name &&
      accountType.isMarketplace
    ) {
      return (
        <>
          <div className='workspace-header__block-item'>
            {channel.data.client_logo ? (
              <Avatar
                src={channel.data.client_logo}
                name={channel.data.client_name}
                size={32}
              />
            ) : (
              <RiBuildingLine fontSize={32} />
            )}
            <p className='team-channel-header__name user'>
              {channel.data.client_name}
            </p>
          </div>
          <div className='workspace-header__block-item'>
            {channel.data.supplier_logo ? (
              <Avatar
                src={channel.data.supplier_logo}
                name={channel.data.supplier_name}
                size={32}
              />
            ) : (
              <RiBuildingLine fontSize={32} />
            )}
            <p className='team-channel-header__name user'>
              {channel.data.supplier_name}
            </p>
          </div>
        </>
      );
    }

    if (accountType.isMarketplace) {
      const image =
        channel.data.client_logo || channel.data.supplier_logo || null;

      const name = channel.data.client_name || channel.data.supplier_name;

      return (
        <div className='workspace-header__block-item'>
          {image ? (
            <Avatar src={image} name={name} size={32} />
          ) : (
            <RiBuildingLine fontSize={32} />
          )}
          <p className='team-channel-header__name user'>
            {channel.data.client_name || channel.data.supplier_name}
          </p>
        </div>
      );
    }

    if (!accountType.isMarketplace && channel.data.marketplace_channel) {
      return (
        <div className='workspace-header__block-item'>
          <Avatar src={sourcerLogo} name={channel.data.name} size={32} />
          <p className='team-channel-header__name user'>{channel.data.name}</p>
        </div>
      );
    }

    const image = accountType.isClient
      ? channel.data.supplier_logo
      : channel.data.client_logo;

    const name = accountType.isClient
      ? channel.data.supplier_name
      : channel.data.client_name;

    return (
      <div className='workspace-header__block-item'>
        {image ? (
          <Avatar src={image} name={name} size={32} />
        ) : (
          <RiBuildingLine fontSize={32} />
        )}

        <p className='team-channel-header__name user'>{name}</p>
      </div>
    );
  }, [channel, accountType]);

  const requisitionOptions = requisitionChannels.map((channel) => ({
    value: channel.data.id,
    key: channel.data.id,
    label: channel.data.name,
  }));

  const value = useMemo(() => {
    const activeRequisitionChannel = requisitionChannels.find(
      (channel) => channel.id === channelId,
    );

    if (activeRequisitionChannel) {
      return activeRequisitionChannel.data.id;
    }

    if (requisitionOptions[0]) return requisitionOptions[0].value;

    return null;
  }, [requisitionOptions, requisitionChannels, channelId]);

  return (
    <div className='team-channel-header__container'>
      <div className='headerInner'>
        <div className='d-flex align-items-center gap-5'>
          <div id='mobile-nav-icon' className='mr-5' onClick={toggleMobile}>
            <RiMenu3Line size={18} />
          </div>
          <div className='workspace-header__block'>{headerTitle}</div>
        </div>

        {requisitionChannels.length > 0 && (
          <AppSelect
            value={value}
            options={requisitionOptions}
            onChange={handleChannelChange}
          />
        )}
      </div>
    </div>
  );
};

TeamChannelHeader.propTypes = {
  toggleMobile: PropTypes.func,
};
