import AppCard from '@wieldy/components/AppCard';
import AppAmountView from '@wieldy/components/helperComponents/AppAmountView';
import {beautifyDateString} from '@wieldy/utils/helpers/DateHelper';
import {Typography} from 'antd';
import FilterReportDropdown from 'app/shared/forms/FilterReportDropdown';
import {merge} from 'lodash';
import PropTypes from 'prop-types';
import React, {useMemo} from 'react';
import {Tooltip} from 'recharts';

import BaseAreaChart from '../BaseAreaChart';

const defaultAreaProps = {
  type: 'monotone',
  stackId: '2',
  stroke: '#7352C7',
  fill: '#427DF0',
  fillOpacity: 0.2,
};

/**
 * Custom tooltip display on area chart
 *
 * @param {*}
 * @returns
 */
const TooltipContent = ({
  title,
  dataKey,
  active,
  payload,
  label,
  isDollars,
}) => {
  if (active && payload && payload.length) {
    const date = beautifyDateString(payload[0].payload.date);
    return (
      <div
        style={{
          background: '#0F1D39',
          padding: '10px',
          borderRadius: '10px',
        }}>
        <Typography.Title
          level={5}
          style={{
            color: '#FFFFFF',
            padding: 0,
            margin: 0,
          }}>
          {isDollars ? (
            <AppAmountView amount={payload[0][dataKey]} decimalDigits={0} />
          ) : (
            `${payload[0][dataKey]} ${title || label}`
          )}
        </Typography.Title>
        <Typography.Text className='text-sm' style={{color: '#C4C4C4'}}>
          {date}
        </Typography.Text>
      </div>
    );
  }
  return null;
};

TooltipContent.propTypes = {
  title: PropTypes.string,
  dataKey: PropTypes.string,
  active: PropTypes.bool,
  payload: PropTypes.array,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isDollars: PropTypes.bool,
};

const AreaChartWidget = (props) => {
  let {
    title,
    description,
    dataSource,
    dataKey = 'value',
    areaProps = {},
    filter = true,
    extra,
    height = 250,
    onChange,
    defaultValue,
    loading,
    bodyStyle,
    children,
    isDollars,
  } = props;

  const areaInitProps = useMemo(() => {
    return merge({}, defaultAreaProps, areaProps ?? {});
  }, [areaProps]);

  if ((!dataSource && dataSource.length === 0) || !dataKey) {
    return null;
  }

  if (!extra && filter === true) {
    extra = (
      <FilterReportDropdown
        value={defaultValue || 'last_30'}
        onChange={onChange}
      />
    );
  }

  let cardBodyStyle = bodyStyle;
  if (!bodyStyle) {
    cardBodyStyle = {padding: 0, paddingRight: 30};
  }

  if (loading) {
    bodyStyle = {};
  } else {
    bodyStyle = cardBodyStyle;
  }

  return (
    <AppCard
      loading={loading}
      title={
        (title && (
          <React.Fragment>
            <Typography.Title level={3}>{title}</Typography.Title>
            {!loading && (
              <Typography.Title
                level={5}
                className={'text-secondary text-sm mt-2'}>
                {description || <br />}
              </Typography.Title>
            )}
          </React.Fragment>
        )) ||
        title
      }
      bodyStyle={bodyStyle}
      headStyle={{border: 0}}
      extra={extra}
      className={'overflow-hidden h-100'}>
      <BaseAreaChart
        dataSource={dataSource}
        dataKey={dataKey}
        height={height}
        {...areaInitProps}>
        {children}
        <Tooltip
          content={
            <TooltipContent
              title={title}
              dataKey={dataKey}
              isDollars={isDollars}
            />
          }
        />
      </BaseAreaChart>
    </AppCard>
  );
};

AreaChartWidget.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  dataSource: PropTypes.array,
  dataKey: PropTypes.string,
  areaProps: PropTypes.object,
  filter: PropTypes.bool,
  extra: PropTypes.node,
  height: PropTypes.number,
  onChange: PropTypes.func,
  defaultValue: PropTypes.string,
  loading: PropTypes.bool,
  bodyStyle: PropTypes.object,
  children: PropTypes.node,
  isDollars: PropTypes.bool,
};

export default React.memo(AreaChartWidget);
