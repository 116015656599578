import {convertHID} from 'app/utils/helpers/ConvertHID';
import {
  formatMinutes,
  formatTimeType,
} from 'app/utils/helpers/DataFormatHelper';
import React from 'react';

export const getColumns = () => {
  return [
    {
      title: 'Task',
      dataIndex: 'engagement_task',
      key: 'engagement_task',
      fixed: 'left',
      render: (engagement_task, {taskHid, taskName}) => (
        <span className='font-semibold'>{`${convertHID(
          taskHid,
        )} - ${taskName}`}</span>
      ),
    },
    {
      title: 'Hours worked',
      dataIndex: 'minutes',
      key: 'minutes',
      render: (minutes) => <span>{formatMinutes(minutes)}</span>,
    },
    {
      title: 'Time types',
      dataIndex: 'type',
      key: 'type',
      render: (type) => <span>{formatTimeType(type)}</span>,
    },
    {
      title: 'Notes',
      dataIndex: 'notes',
      key: 'notes',
      width: 300,
      render: (type) => <span>{type}</span>,
    },
  ];
};
