import axios from '../config/axios';

export const resourceAPI = {
  getResources: async (params) => await axios.get('/resources', {params}),
  getResource: async (id, params) =>
    await axios.get(`/resources/${id}`, {params}),
  getResourcePdf: async ({id, options}) =>
    await axios.get(`/resources/${id}/download`, {
      ...options,
    }),
  getResourceUploadFileUrl: async (payload) =>
    axios.post('/resources/upload_resource_resume', payload),
};
