import {DownloadOutlined} from '@ant-design/icons';
import {Button, Divider, notification, Tooltip} from 'antd';
import {expenseAPI} from 'app/api/expenseApi.js';
import {timecardAPI} from 'app/api/timecardApi.js';
import {useAccountType} from 'app/hooks/useAccountType.js';
import {useRemove} from 'app/hooks/useRemove.js';
import {useStatus} from 'app/hooks/useStatus.js';
import {
  useDownloadExpenses,
  useUpdateExpense,
} from 'app/services/expenseService.js';
import {
  useDownloadTimeCard,
  useUpdateTimeCard,
} from 'app/services/timecardsService.js';
import {capitalizeFirstLetter} from 'app/utils/helpers/Capitalize.js';
import {sliceLastLetter} from 'app/utils/helpers/sliceLastLetter.js';
import propTypes from 'prop-types';
import React, {useCallback, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';

import {downloadBlob} from '../../../pages/common/Terms/components/utils/downloadBlob';
import {formatSecondsToDate} from '../../../utils/helpers/DataFormatHelper';
import RejectPopup from '../RejectPopup/RejectPopup';

const ActionButtons = ({id, timeDate, hid, status, resource, date}) => {
  const {isClient} = useAccountType();
  const navigate = useNavigate();

  const {pathname} = useLocation();
  const isExpensesPage = pathname.startsWith('/expenses');
  const page = isExpensesPage ? 'expenses' : 'timecards';
  const type = sliceLastLetter(page, 's');
  const title = capitalizeFirstLetter(type);
  const downloadData = useDownloadExpenses();
  const downloadTimecardData = useDownloadTimeCard();
  const tooltipTitle = isExpensesPage
    ? 'Download Expense Report'
    : 'Download Timecard';

  const [modalComponent, handleDelete] = useRemove(
    id,
    isExpensesPage ? expenseAPI.deleteExpense : timecardAPI.deleteTimeCard,
    type,
  );
  const {isNew, isPending, isRejected} = useStatus(status);

  const onSuccess = (_, {status}) => {
    navigate(`/${page}/${status}`);
  };

  const {mutate: onUpdate, isLoading: updateLoading} = useUpdateTimeCard(id);
  const {mutate: onUpdateExpenses, isLoading: updateLoadingExpenses} =
    useUpdateExpense(id);

  const handleApprove = async () => {
    if (isExpensesPage) {
      await onUpdateExpenses({status: 'approved'}, {onSuccess});
    } else {
      await onUpdate({status: 'approved'}, {onSuccess});
    }
  };

  const handleReject = async (rejected_reason) => {
    if (isExpensesPage) {
      await onUpdateExpenses(
        {status: 'rejected', rejected_reason},
        {onSuccess},
      );
    } else {
      await onUpdate({status: 'rejected', rejected_reason}, {onSuccess});
    }
  };

  const [isRejectPopup, setIsRejectPopup] = useState(false);
  const toggleRejectPopup = useCallback((isOpen) => {
    setIsRejectPopup(isOpen);
  }, []);

  const handleCheckName = useCallback(() => {
    const keys = Object.keys(resource);

    if (keys.includes('firstName')) {
      return resource.firstName;
    }

    return resource.first_name;
  }, [resource]);

  const handleCheckLastName = useCallback(() => {
    const keys = Object.keys(resource);

    if (keys.includes('lastName')) {
      return resource.lastName?.replaceAll('.', '');
    }

    return resource.last_name?.replaceAll('.', '');
  }, [resource]);

  const handleDownload = useCallback(() => {
    const fileName = `${hid}_${handleCheckName()}-${handleCheckLastName()}_${date?.replaceAll(
      '/',
      '-',
    )}.pdf`;

    const params = timeDate && {
      created_at: formatSecondsToDate(timeDate.created_at).toString(),
      updated_at: formatSecondsToDate(timeDate?.updated_at).toString(),
      approved_at: !timeDate?.approved_at
        ? null
        : formatSecondsToDate(timeDate?.approved_at).toString(),
      submitted_at: !timeDate?.submitted_at
        ? null
        : formatSecondsToDate(timeDate?.submitted_at).toString(),
      invoiced_at: !timeDate?.invoiced_at
        ? null
        : formatSecondsToDate(timeDate?.invoiced_at).toString(),
    };

    if (isExpensesPage) {
      return downloadData.mutate(
        {
          id,
          params,
        },
        {
          onSuccess: (response) => {
            downloadBlob(response.data, fileName);
          },
          onError: (error) => {
            notification.error({message: error?.message});
          },
        },
      );
    }

    return downloadTimecardData.mutate(
      {
        id,
        params,
      },
      {
        onSuccess: (response) => {
          downloadBlob(response.data, fileName);
        },
        onError: (error) => {
          notification.error({message: error?.message});
        },
      },
    );
  }, [
    date,
    downloadData,
    downloadTimecardData,
    handleCheckLastName,
    handleCheckName,
    hid,
    id,
    isExpensesPage,
    timeDate,
  ]);

  return (
    <>
      <Tooltip overlayStyle={{fontSize: '12px'}} title={tooltipTitle}>
        <Button
          type='primary'
          shape='circle'
          className='post-header-button items-center d-none lg:flex w-full'
          loading={downloadData.isLoading}
          onClick={handleDownload}
          icon={<DownloadOutlined style={{fontSize: '18px'}} />}
        />
      </Tooltip>
      {isClient && isPending && (
        <>
          <Divider type={'vertical'} style={{height: '18px'}} />
          <Button
            loading={updateLoading || updateLoadingExpenses}
            type='primary'
            shape='round'
            onClick={handleApprove}>
            Approve {title}
          </Button>
          <Divider type={'vertical'} style={{height: '18px'}} />
          <Button
            type='primary'
            ghost
            shape='round'
            onClick={() => toggleRejectPopup(true)}>
            Reject {title}
          </Button>
        </>
      )}
      {!isClient && (isNew || isRejected) && (
        <>
          <Divider type={'vertical'} style={{height: '18px'}} />
          <Button
            type='primary'
            shape='round'
            onClick={() => navigate(`/${page}/edit-${type}/${id}`)}>
            Edit {title}
          </Button>
          <Divider type={'vertical'} style={{height: '18px'}} />
          <Button type='primary' ghost shape='round' onClick={handleDelete}>
            Delete {title}
          </Button>
        </>
      )}
      <RejectPopup
        type={type}
        isModalOpen={isRejectPopup}
        handleReject={handleReject}
        toggleOpen={toggleRejectPopup}
      />
      {modalComponent}
    </>
  );
};

ActionButtons.propTypes = {
  id: propTypes.string,
  hid: propTypes.string,
  status: propTypes.string,
  resource: propTypes.object,
  date: propTypes.string,
  timeDate: propTypes.object,
};

export default ActionButtons;
