import AppCard from '@wieldy/components/AppCard';
import {Divider} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

import Calculation from './Calculation';
import Invoices from './Invoices';
import Summary from './Summary';

const SpendingSummary = ({engagement}) => {
  return (
    <AppCard
      title='Spending Summary'
      headStyle={{borderBottom: 0}}
      bodyStyle={{paddingTop: 8}}>
      <Summary engagement={engagement} />
      <Divider className='my-2' />
      <Calculation engagement={engagement} />
      <Invoices />
    </AppCard>
  );
};

SpendingSummary.propTypes = {
  engagement: PropTypes.object,
};

export default SpendingSummary;
