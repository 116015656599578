import AppCard from '@wieldy/components/AppCard';
import AppFormWrapper, {
  AppFormActions,
  AppFormBody,
} from '@wieldy/components/AppFormWrapper';
import AppGridContainer from '@wieldy/components/AppGridContainer';
import AppInput from '@wieldy/components/dataEntry/AppInput';
import AppSelect from '@wieldy/components/dataEntry/AppSelect';
import {EMAIL_REGX, NAME_REGX} from '@wieldy/constants/ValidationConsts';
import {
  getInputPlaceHolder,
  getRequiredMessage,
  getSelectPlaceHolder,
} from '@wieldy/utils/helpers/MessageHelper';
import {Button, Checkbox, Col, Form, Space} from 'antd';
import {CompanyMemberDropdown} from 'app/shared/components/dropdowns';
import PhoneInput from 'app/shared/components/PhoneInput';
import PropTypes from 'prop-types';
import React, {useCallback, useState} from 'react';

import {validatePhoneNumber} from '../../../utils/helpers/AppHelper';

const InviteUserForm = ({
  onSubmit,
  onCancel,
  loading = false,
  role = 'client',
  userTypes = [],
}) => {
  const [isExistingResource, setIsExistingResource] = useState();
  const [form] = Form.useForm();

  const handleOnFinish = (formdata) => {
    const {existing_resource, ...payload} = formdata;
    if (isExistingResource === true && existing_resource) {
      payload.predefined_resource = existing_resource;
    }
    if (payload?.phone_number?.indexOf('+') > -1) {
      payload.phone_number = payload.phone_number.replace('+', '');
    }
    onSubmit(payload);
  };

  const [existUsers, setExistUsers] = useState([]);
  const getUsers = useCallback((users) => {
    setExistUsers(users);
  }, []);

  const handleSelectCurrent = useCallback(
    ({key}) => {
      const user = existUsers.find((u) => u.id === key);
      if (user) {
        form.setFieldsValue({...user, role: 'resource'});
      }
    },
    [existUsers, form],
  );

  return (
    <AppCard
      title='User Information'
      headStyle={{borderBottom: 0}}
      bodyStyle={{paddingTop: 8}}>
      <AppFormWrapper>
        <Form layout='vertical' form={form} onFinish={handleOnFinish}>
          <AppFormBody>
            <AppGridContainer>
              {role === 'supplier' && (
                <Col md={24}>
                  <Space direction={'vertical'}>
                    <Checkbox
                      value={true}
                      onChange={(e) => setIsExistingResource(e.target.checked)}>
                      Is an existing resource?
                    </Checkbox>
                    <Form.Item
                      name='existing_resource'
                      rules={[
                        {
                          required: isExistingResource,
                          message: getRequiredMessage('Existing resource'),
                        },
                      ]}
                      hidden={!isExistingResource}>
                      <CompanyMemberDropdown
                        getValue={getUsers}
                        variant={'resource'}
                        initialFetch={true}
                        queryParams={{limit: 50}}
                        style={{width: '350px'}}
                        showArrow={true}
                        onSelect={(_, option) => handleSelectCurrent(option)}
                        placeholder={'Select existing user'}
                      />
                    </Form.Item>
                  </Space>
                </Col>
              )}
              <Col xs={24} sm={12} md={8} lg={6}>
                <Form.Item
                  name='first_name'
                  label='First Name'
                  rules={[
                    {
                      required: true,
                      message: getRequiredMessage('First Name'),
                    },
                    () => ({
                      validator(_, value) {
                        if (!value) {
                          return Promise.resolve();
                        }

                        if (!NAME_REGX.test(value)) {
                          return Promise.reject('Enter a valid First Name!');
                        }

                        return Promise.resolve();
                      },
                    }),
                  ]}
                  required>
                  <AppInput placeholder={getInputPlaceHolder('first name')} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={8} lg={6}>
                <Form.Item
                  name='last_name'
                  label='Last Name'
                  rules={[
                    {
                      required: true,
                      message: getRequiredMessage('Last Name'),
                    },
                    () => ({
                      validator(_, value) {
                        if (!value) {
                          return Promise.resolve();
                        }

                        if (!NAME_REGX.test(value)) {
                          return Promise.reject('Enter a valid Last Name!');
                        }

                        return Promise.resolve();
                      },
                    }),
                  ]}
                  required>
                  <AppInput placeholder={getInputPlaceHolder('last name')} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={8} lg={6}>
                <Form.Item
                  name='title'
                  label='Title'
                  rules={[
                    {
                      required: true,
                      message: getRequiredMessage('Title'),
                    },
                  ]}
                  required>
                  <AppInput placeholder={getInputPlaceHolder('title')} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={8} lg={6}>
                <Form.Item
                  name='email'
                  label='Email Address'
                  rules={[
                    {
                      required: true,
                      message: getRequiredMessage('Email Address'),
                    },
                    () => ({
                      validator(_, value) {
                        if (!value) {
                          return Promise.resolve();
                        }

                        if (!EMAIL_REGX.test(value)) {
                          return Promise.reject('Enter a valid Email Address!');
                        }

                        return Promise.resolve();
                      },
                    }),
                  ]}
                  required>
                  <AppInput
                    placeholder={getInputPlaceHolder('email address')}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={8} lg={6}>
                <Form.Item
                  name='phone_number'
                  label='Phone Number'
                  rules={[
                    {
                      validator: validatePhoneNumber,
                    },
                  ]}>
                  <PhoneInput />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={8} lg={6}>
                <Form.Item
                  name='role'
                  label='User Type'
                  required={true}
                  rules={[
                    {
                      required: true,
                      message: getRequiredMessage('User Type'),
                    },
                  ]}>
                  <AppSelect
                    placeholder={getSelectPlaceHolder('user type')}
                    options={userTypes}
                  />
                </Form.Item>
              </Col>
            </AppGridContainer>
          </AppFormBody>
          <AppFormActions>
            <Space size={16}>
              <Button type='primary' htmlType='submit' loading={loading}>
                Invite User
              </Button>
              <Button type='primary' onClick={onCancel} ghost>
                Cancel
              </Button>
            </Space>
          </AppFormActions>
        </Form>
      </AppFormWrapper>
    </AppCard>
  );
};

InviteUserForm.propTypes = {
  role: PropTypes.oneOf(['supplier', 'client', 'marketplace']),
  userTypes: PropTypes.array.isRequired,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  loading: PropTypes.bool,
};

export default React.memo(InviteUserForm);
