import {Descriptions, Typography} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

const ContactInformation = ({user}) => {
  return (
    <div className='mb-6'>
      <Typography.Title level={4} className={'mb-6'}>
        Contact Information
      </Typography.Title>
      <Descriptions
        colon={false}
        labelStyle={{width: '50%'}}
        className='description-root mb-4'
        column={{xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1}}>
        <Descriptions.Item label='Phone Number'>
          {user?.workPhoneNumber}
        </Descriptions.Item>
        <Descriptions.Item label='Email Address'>
          {user?.email}
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
};

ContactInformation.propTypes = {
  user: PropTypes.object,
};

export default ContactInformation;
