import AppGridView from '@wieldy/components/AppGridView';
import AppLoader from '@wieldy/components/AppLoader';
import AppPageSection from '@wieldy/components/pageComponents/AppPageSection';
import {Empty} from 'antd';
import {useAccountType} from 'app/hooks/useAccountType';
import usePageQuery from 'app/hooks/usePageQuery';
import EngagementModal from 'app/modals/EngagementModal';
import {useGetAllEngagements} from 'app/services/engagementsService';
import ViewAllButton from 'app/shared/buttons/ViewAllButton';
import EngagementCard from 'app/shared/cards/EngagementCard';
import {capitalizeFirstLetter} from 'app/utils/helpers/Capitalize';
import PropTypes from 'prop-types';
import React from 'react';

import {statuses} from '../data';

const All = ({userQuery}) => {
  const {isSupplier} = useAccountType();
  const {currentPageParams} = usePageQuery();
  const {queryParams} = currentPageParams;
  const {data: allData, isLoading} = useGetAllEngagements(
    {
      include: [...queryParams.include],
      expand: [...queryParams.expand, 'candidate', 'resource', 'resource_user'],
      ...userQuery,
    },
    statuses,
  );

  if (isLoading) {
    return <AppLoader />;
  }

  if (allData?.every(({engagements}) => !engagements.length)) {
    return <Empty description='No Engagements' />;
  }

  return (
    !!allData?.length &&
    allData.map(
      ({engagements, total_count, status}) =>
        !!engagements.length && (
          <AppPageSection
            key={status}
            title={`${capitalizeFirstLetter(status)} Engagements`}
            count={total_count}
            extra={
              total_count > 6 && (
                <ViewAllButton category={status} path='engagements' />
              )
            }>
            <AppGridView
              isAllPages={true}
              data={engagements.slice(0, 6)}
              renderItem={(engagement) => (
                <EngagementCard
                  key={engagement.id}
                  engagement={new EngagementModal(engagement, isSupplier)}
                />
              )}
            />
          </AppPageSection>
        ),
    )
  );
};

All.propTypes = {
  userQuery: PropTypes.object,
};

All.defaultProps = {
  userQuery: {},
};

export default All;
